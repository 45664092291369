import React, {
  useState,
  FunctionComponent,
  cloneElement,
  useEffect,
} from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { Close, Minimize } from "@mui/icons-material";
import ComposeEmail from "./ComposeEmail";
import ActionIconButton from "../ActionIconButton";
import { Deal } from "../../models/index";
import mixpanel from "../../mixpanel";
import { Stack } from "@mui/system";

type ComposeEmailDialogProps = {
  recipients?: Array<any>;
  emailThread?: any;
  onEmailSent?: () => any;
  onClosed?: () => any;
  deal?: Deal;
  contactDeals?: Deal[];
  children?: React.ReactNode;
};
const ComposeEmailDialog: FunctionComponent<ComposeEmailDialogProps> = ({
  recipients,
  emailThread,
  children,
  onEmailSent,
  onClosed,
  deal,
  contactDeals,
}) => {
  const [minimized, setMinimized] = useState(false);
  const [open, setOpen] = useState(!children);

  const handleOpen = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (onClosed) {
      onClosed();
    }
  };

  const handleMinimize = () => {
    setMinimized(!minimized);
  };

  const handleEmailSent = () => {
    setOpen(false);
    if (onEmailSent) {
      onEmailSent();
    }
  };

  useEffect(() => {
    if (open) {
      mixpanel.track("Compose Email", {
        type: deal == null ? "contact" : "deal",
      });
    }
  }, [open, deal]);

  return (
    <>
      {minimized && (
        <Button
          sx={{
            backgroundColor: "#351665",
            color: "white",
            position: "fixed",
            bottom: 0,
            right: 0,
            zIndex: 12,
            marginRight: "4rem",
            borderRadius: "8px 8px 0px 0px",
          }}
          variant="text"
          onClick={handleMinimize}
        >
          Draft
        </Button>
      )}
      {children && cloneElement(children as any, { onClick: handleOpen })}
      <Dialog
        fullWidth
        maxWidth="md"
        open={open && !minimized}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Send Email</Typography>
            <Stack direction="row">
              <ActionIconButton
                icon={Minimize}
                style={{ margin: "4px -5px" }}
                buttonStyle={{ width: "22px", height: "22px" }}
                onClick={handleMinimize}
                aria-label="Minimize"
              />
              <ActionIconButton
                icon={Close}
                style={{ margin: "4px -5px" }}
                buttonStyle={{ width: "22px", height: "22px" }}
                onClick={handleClose}
                aria-label="close"
              />
            </Stack>
          </Box>
        </DialogTitle>
        <DialogContent>
          {open && (
            <ComposeEmail
              recipients={recipients}
              emailThread={emailThread}
              onEmailSent={handleEmailSent}
              deal={deal}
              contactDeals={contactDeals}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ComposeEmailDialog;
