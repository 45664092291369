import React, { FormEvent, useState, useEffect, ChangeEvent } from "react";
import Grid from "@mui/material/Grid";
import {
  TextField,
  Link,
  Box,
  CircularProgress,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation, useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { ApolloError } from "@apollo/client";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { validateString, hasTrueValues } from "../helpers";
import qs from "querystring";
import SideLayout from "../layouts/SideLayout";
import { validateEmail } from "../helpers";
import QueryString from "qs";
import AddIcon from "@mui/icons-material/Add";
import { useAuth } from "../context/auth";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const SIGNUP = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
      }
      token
      checkoutUrl
    }
  }
`;

const SIGNUP_WITH_INVITE = gql`
  mutation createUser($input: CreateUserInput!) {
    createUserFromInvite(input: $input) {
      user {
        id
      }
      token
    }
  }
`;

const GET_INVITE = gql`
  query getUserInvite($id: String!) {
    getUserInvite(id: $id) {
      id
      email
      invitationType
      account {
        name
        logoUrl
      }
    }
  }
`;

function Signup() {
  const [submitting, setSubmitting] = useState(false);
  const [planName, setPlanName] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [invite, setInvite] = useState<any>();
  const [formErrors, setFormErrors] = useState<any>({});
  const [checked, setChecked] = useState(false);

  const [signup] = useMutation(SIGNUP);
  const [signupWithInvite] = useMutation(SIGNUP_WITH_INVITE);
  const apollo = useApolloClient();
  const { login } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const goToNext = (nextUrl?: string | null) => {
    if (nextUrl) {
      window.location.href = nextUrl;
      return;
    }
    let next = "/";
    if (location.search.length) {
      const search = QueryString.parse(location.search.substr(1));
      if (search.next) {
        next = search.next as string;
        delete search.next;
        // Do we have more search params?
        if (Object.keys(search).length) {
          next += `?${QueryString.stringify(search)}`;
        }
      }
    }
    navigate(next);
  };

  useEffect(() => {
    const query = qs.parse(location.search.slice(1));
    if (query.inviteId) {
      apollo
        .query<any>({
          query: GET_INVITE,
          variables: { id: query.inviteId },
        })
        .then((res) => {
          setInvite(res.data.getUserInvite);
          setEmail(res.data.getUserInvite.email);
        })
        .catch((err: ApolloError) => {
          setError("Your invitation was not found or has expired.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (query.planName) {
        setPlanName(query.planName as string);
      }
      setLoading(false);
    }
  }, [apollo, location.search]);

  const handlePrivacyCheck = (e: ChangeEvent<any>, checked: boolean) => {
    setChecked(checked);
  };

  const canSubmit = (): boolean => {
    const [, hasEmailError] = validateEmail(email);
    const [, hasFirstNameError] = validateString(firstName);
    const [, hasLastNameError] = validateString(lastName);
    const [, hasPasswordError] = validateString(password, 12);

    // TODO: Make the validate helper function have typed return annotations
    let hasCompanyError: string | boolean | undefined = false;
    if (!invite) {
      [, hasCompanyError] = validateString(company);
    }
    return (
      !hasEmailError &&
      !hasFirstNameError &&
      !hasLastNameError &&
      !hasPasswordError &&
      !hasCompanyError &&
      checked &&
      !submitting
    );
  };

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    // Validate
    const formErrors: any = {};
    const params: any = {};
    [params.email, formErrors.email] = validateEmail(email);
    [params.firstName, formErrors.firstName] = validateString(firstName);
    [params.lastName, formErrors.lastName] = validateString(lastName);
    [params.password, formErrors.password] = validateString(password);
    formErrors.agreeToPrivacyPolicy = !checked;
    if (!invite) {
      // If we're not using an invite, company is required.
      [params.company, formErrors.company] = validateString(company);
    } else {
      params.inviteId = invite.id;
    }
    if (planName) {
      params.planName = planName;
    }

    setFormErrors(formErrors);
    const hasErrors = hasTrueValues(formErrors);

    if (hasErrors) {
      return;
    }
    setSubmitting(true);

    // Perform signup
    let signupPromise: Promise<any>;
    if (invite) {
      signupPromise = signupWithInvite({ variables: { input: params } });
    } else {
      signupPromise = signup({ variables: { input: params } });
    }
    signupPromise
      .then(async (res) => {
        // HubSpot tracking
        var _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
          "identify",
          {
            email: params.email,
          },
        ]);
        const tokens = await login(params.email, params.password);
        const checkoutUrl = res.data.createUser.checkoutUrl as
          | string
          | undefined
          | null;
        return { tokens, checkoutUrl };
      })
      .then(({ checkoutUrl }) => {
        return goToNext(checkoutUrl);
      })
      .finally(() => {
        setSubmitting(false);
      })
      .catch((err) => {
        if (err instanceof ApolloError) {
          setError(err.message);
        } else {
          setError("An error occurred during signup. Please try again.");
        }
      });
  }

  const useStyles = makeStyles((theme) => ({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    loading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      height: "45px",
      width: "auto",
    },
    p: {
      fontSize: "16px",
      fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      fontWeight: 600,
      textAlign: "center",
    },
    leftSide: {
      height: "100%",
      textAlign: "center",
      padding: "25px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  }));

  const classes = useStyles();
  const headerText = (invite: any) => {
    if (invite.invitationType === "teamMember") {
      return `${invite.account.name} uses Shaker to manage lead generation, client communication, and transactions.`;
    } else {
      return `Join ${invite.account.name} on Shaker to stay connected and up to date on your real estate transactions.`;
    }
  };

  return (
    <SideLayout
      left={
        <>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.leftSide}
          >
            <Typography
              variant="h2"
              style={{
                fontFamily: "RecoletaSemiBold, AvenirNext, Avenir, sans-serif",
              }}
            >
              Better Real Estate Transactions, Period
            </Typography>
          </Box>
        </>
      }
      right={
        loading ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box alignItems="center" justifyContent="center" display="flex">
            <Box
              sx={{
                width: {
                  md: "60%",
                },
              }}
            >
              <Grid
                container
                spacing={4}
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src="/logo-full.svg"
                    alt="Shaker logo"
                    aria-label="Shaker logo"
                    title="Shaker"
                    className={classes.img}
                  />
                  {invite?.account.logoUrl && (
                    <>
                      <AddIcon
                        style={{
                          fontSize: "16px",
                          marginRight: 11,
                          marginLeft: 11,
                        }}
                      />
                      <img
                        src={invite?.account.logoUrl}
                        alt={invite?.account.name + " logo"}
                        aria-label={invite?.account.name + " logo"}
                        title={invite?.account.name}
                        className={classes.img}
                      />
                    </>
                  )}
                </Grid>
                <Grid item>
                  <Box textAlign="center" mb={3} mt={3}>
                    <Typography variant="h5" gutterBottom>
                      Signup
                    </Typography>
                    {invite && (
                      <Typography gutterBottom>{headerText(invite)}</Typography>
                    )}
                  </Box>

                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <Grid container spacing={2}>
                      {error && (
                        <Grid item xs={12}>
                          <Alert severity="error">{error}</Alert>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="fname"
                          value={firstName}
                          name="firstName"
                          variant="outlined"
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          autoFocus
                          error={formErrors.firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          value={lastName}
                          required
                          fullWidth
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          autoComplete="lname"
                          error={formErrors.lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          value={email}
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          type="email"
                          error={formErrors.email}
                          defaultValue={invite?.email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          value={password}
                          error={formErrors.password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <FormHelperText>
                          Password must be at least 12 characters long
                        </FormHelperText>
                      </Grid>
                      {!invite && (
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="company"
                            label="Company Name"
                            name="company"
                            error={formErrors.company}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormControl error={formErrors.agreeToPrivacyPolicy}>
                          <FormControlLabel
                            control={<Checkbox onChange={handlePrivacyCheck} />}
                            label={
                              <div>
                                <span>I agree to Shaker's </span>
                                <Link
                                  href="https://shaker.io/privacy-policy"
                                  target="blank"
                                >
                                  Privacy Policy
                                </Link>
                                <span> and </span>
                                <Link
                                  href="https://shaker.io/terms"
                                  target="blank"
                                >
                                  Terms of Service
                                </Link>
                              </div>
                            }
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <LoadingButton
                      loading={submitting}
                      loadingPosition="start"
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={!canSubmit()}
                    >
                      {!submitting ? (
                        <>Sign Up</>
                      ) : (
                        <>Setting up your account&hellip;</>
                      )}
                    </LoadingButton>
                  </form>
                </Grid>
                <Grid item>
                  <Link to="/login" variant="body2" component={RouterLink}>
                    Already have an account? Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )
      }
    />
  );
}
const mapDispatchToProps = (dispatch: any) => ({});
const mapStateToProps = (state: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
