import React from "react";
import { connect } from "react-redux";
import { Feature } from "../reducers/features";
import TeamLayout from "../layouts/TeamLayout";
import { screenLessThan } from "../helpers";
import Calendar from "../components/calendar/Calendar";
import "./TaskListAccordion.scss";

function CalendarPage({ features }: { features: any }) {
  let calendarView = "dayGridMonth";
  if (screenLessThan(425)) {
    calendarView = "dayGridWeek";
  }
  return (
    <TeamLayout>
      <Calendar calendarView={calendarView} />
    </TeamLayout>
  );
}

const mapStateToProps = ({ features }: { [key: string]: Feature }) => {
  return { features };
};
export default connect(mapStateToProps)(CalendarPage);
