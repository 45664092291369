import React, { FunctionComponent } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Tag } from "../../models";
import { connect } from "react-redux";
import { TextField, Chip } from "@mui/material";

type TagAutocompleteProps = {
  tags: Array<Tag>;
  value: Array<Tag>;
  freeSolo?: boolean;
  autoFocus?: boolean;
  variant?: any;
  filterSelectedOptions?: boolean;
  onChange?: (tags: Array<Tag>) => any;
};

const filter = createFilterOptions<Tag>();

const TagAutocomplete: FunctionComponent<TagAutocompleteProps> = ({
  value = [],
  tags,
  onChange,
  variant = "standard",
  filterSelectedOptions = false,
  autoFocus = false,
  freeSolo = false,
}) => {
  const handleTagChange = (selectedTags: Array<Tag | string>) => {
    const value = selectedTags.map((tag) => {
      if (typeof tag === "string") {
        return {
          name: tag,
        };
      }
      // Cleanup label property if the user clicked the "Add" CTA
      return Object.assign({}, tag, { label: undefined });
    });
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Autocomplete
      multiple
      selectOnFocus
      clearOnBlur
      filterSelectedOptions={filterSelectedOptions}
      freeSolo={freeSolo}
      options={tags}
      value={value}
      onChange={(event, value) => {
        return handleTagChange(value);
      }}
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "" && freeSolo) {
          filtered.push({
            name: params.inputValue,
            label: `Add: "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      isOptionEqualToValue={(value: Tag | string, option: Tag) => {
        return typeof value === "string"
          ? value === option.name
          : option.id
          ? value.id === option.id
          : value.name === option.name;
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.name
      }
      renderTags={(value: Tag[], getTagProps) => {
        return value.map((tag: Tag, index: number) => (
          <Chip
            variant="outlined"
            label={<span>{tag.name}</span>}
            {...getTagProps({ index })}
          />
        ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          variant={variant}
          label="Tags"
          placeholder="Add"
        />
      )}
    />
  );
};

const mapStateToProps = ({ contactTags }: { contactTags: Tag[] }) => ({
  tags: contactTags,
});
export default connect(mapStateToProps)(TagAutocomplete);
