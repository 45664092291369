import React, { useState } from "react";
import {
  Theme,
  MenuItem,
  MenuList,
  ListItemText,
  Divider,
  ListSubheader,
  ListItemIcon,
  Button,
  Menu,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Check, KeyboardArrowDown } from "@mui/icons-material";

interface MenuItemOption {
  value: string;
  label: string;
}

const viewOptions: MenuItemOption[] = [
  { value: "me", label: "My Dashboard" },
  { value: "team", label: "Team Dashboard" },
];

const timeframeOptions: MenuItemOption[] = [
  { value: "thisWeek", label: "This Week" },
  { value: "thisMonth", label: "This Month" },
  { value: "thisYear", label: "This Year" },
];

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    select: {
      height: "40px",
      backgroundColor: "#ffffff",
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "14px",
      paddingTop: "4px",

      "&:hover": {
        borderColor: theme.palette.grey[300],

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.grey[300],
        },
      },

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[300],
      },

      "& .MuiSelect-select:focus": {
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
    },
    label: {
      marginRight: "8px",
      color: theme.palette.grey[600],
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      display: "flex",
      alignItems: "center",
    },
    value: {
      color: theme.palette.grey[900],
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      display: "flex",
      alignItems: "center",
    },
  });
});

export interface DashboardMenuValue {
  view: string;
  timeframe: string;
}
type LabeledSelectProps = {
  allowTeamView?: boolean;
  value?: DashboardMenuValue;
  onChange?: (value: DashboardMenuValue) => void;
};

const DashboardMenu = ({
  value,
  onChange,
  allowTeamView,
}: LabeledSelectProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [state, setState] = useState<DashboardMenuValue>(
    value ?? {
      view: "me",
      timeframe: "thisMonth",
    }
  );

  interface MenuItemOption {
    value: string;
    label: string;
  }

  const renderValue = () => {
    const timeframeLabel = timeframeOptions.find(
      (option) => option.value === state.timeframe
    )?.label;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          textTransform: "none",
        }}
      >
        <div className={classes.label}>Show:</div>
        <div className={classes.value}>{timeframeLabel}</div>
      </div>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MenuOption = ({
    option,
    selected,
    onClick,
    disabled = false,
  }: {
    option: MenuItemOption;
    selected?: boolean;
    onClick?: (option: MenuItemOption) => void;
    disabled?: boolean;
  }) => {
    const handleClick = () => {
      if (onClick) {
        onClick(option);
      }
    };

    return (
      <MenuItem
        key={option.value}
        value={option.value}
        onClick={handleClick}
        disabled={disabled}
      >
        {selected && (
          <ListItemIcon>
            <Check color="primary" />
          </ListItemIcon>
        )}
        <ListItemText inset={!selected}>{option.label}</ListItemText>
      </MenuItem>
    );
  };

  const handleStateChange = (key: keyof DashboardMenuValue, value: string) => {
    const newState = { ...state, [key]: value };
    setState(newState);
    if (onChange) {
      onChange(newState);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        sx={{
          backgroundColor: "#ffffff",
          borderColor: "#E8E2E1",
          "&:hover": {
            borderColor: "#0B8D9B",
            backgroundColor: "#ffffff",
          },
        }}
        endIcon={<KeyboardArrowDown />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {renderValue()}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuList dense>
          <ListSubheader>View</ListSubheader>
          {viewOptions.map((option) => (
            <MenuOption
              key={option.value}
              disabled={!allowTeamView && option.value === "team"}
              option={option}
              selected={state.view === option.value}
              onClick={() => handleStateChange("view", option.value)}
            />
          ))}
          <Divider />
          <ListSubheader>Timeframe</ListSubheader>
          {timeframeOptions.map((option) => (
            <MenuOption
              key={option.value}
              option={option}
              selected={state.timeframe === option.value}
              onClick={() => handleStateChange("timeframe", option.value)}
            />
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

export default DashboardMenu;
