import React, { useState } from "react";
import { IconButton, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  DragIndicator as DragIndicatorIcon,
  RemoveCircle as RemoveCircleIcon,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldItem: (isLeftPane: any) => ({
      margin: isLeftPane ? "0px" : "0 25px",
      paddingBottom: "18px",
    }),
    fieldItemLabel: {
      fontSize: "12px",
      fontWeight: 500,
      color: theme.palette.grey[500],
    },
    fieldItemValue: {
      fontSize: "14px",
      fontWeight: 500,
    },
  })
);

export default function DealData({
  label,
  value,
  className,
  isLeftPane,
  handleRemoveFavorite,
}: {
  label?: string;
  value: any;
  className?: string;
  isLeftPane?: boolean;
  handleRemoveFavorite?: any;
}) {
  const [displayDrag, setDisplayDrag] = useState(false);
  const classes = useStyles(isLeftPane);

  return (
    <div
      className={clsx(classes.fieldItem, className)}
      data-id={label}
      onMouseOver={() => setDisplayDrag(true)}
      onMouseLeave={() => setDisplayDrag(false)}
    >
      <Stack direction="row" alignItems="center">
        {!isLeftPane && (
          <DragIndicatorIcon
            fontSize="small"
            style={{
              color: "#6b7280",
              cursor: "grab",
              visibility: displayDrag ? "visible" : "hidden",
            }}
            className="drag-handle"
          />
        )}
        <Stack
          sx={{
            width: isLeftPane ? "100%" : "auto",
          }}
        >
          {label && <div className={classes.fieldItemLabel}>{label}</div>}
          <div className={classes.fieldItemValue}>{value || "-"}</div>
        </Stack>
        {isLeftPane && (
          <Stack direction="row" alignItems="center">
            <div style={{ flexGrow: 1 }}></div>
            <DragIndicatorIcon
              fontSize="small"
              style={{
                color: "#6b7280",
                cursor: "grab",
                visibility: displayDrag ? "visible" : "hidden",
              }}
              className="drag-handle"
            />

            <Tooltip title="Remove from favorites">
              <IconButton
                size="small"
                onClick={(e) => handleRemoveFavorite(e, label)}
              >
                <RemoveCircleIcon
                  fontSize="small"
                  style={{
                    visibility: displayDrag ? "visible" : "hidden",
                    marginLeft: "5px",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </Stack>
    </div>
  );
}
