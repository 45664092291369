import React, { useState, useRef } from "react";
import {
  Typography,
  List,
  Theme,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Add as AddIcon } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import DetailCard from "../../../components/DetailCard";
import Folder from "../documents/Folder";
import { useAlert } from "../../../context/alert";
import { useAuth } from "../../../context/auth";
import DocumentListItem from "../documents/DocumentListItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noDocuments: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "11px",
      color: "#747b88",
      fontSize: "12px",
    },
  })
);

type DocumentsProps = {
  deal: any;
  documents: any;
  folders: any;
  refetch?: Function;
};

const Documents = ({ deal, documents, folders, refetch }: DocumentsProps) => {
  const classes = useStyles();
  const { showError } = useAlert();
  const { authToken } = useAuth();

  const fileInput = useRef<HTMLInputElement>(null);

  const [uploadingFiles, setUploadingFiles] = useState<
    { filename: string; id: string }[]
  >([]);

  const [{ opacity }, drop] = useDrop(() => ({
    accept: ["document", NativeTypes.FILE],
    drop: (item: any, monitor) => {
      if (monitor.didDrop()) {
        // A nested dropzone (a Folder component) already handled this drop.
        // We'll ignore this one.
        return;
      }
      handleDrop(item);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver({ shallow: true }),
        opacity: monitor.isOver() ? 0.5 : 1,
      };
    },
  }));

  const handleDrop = (item: any, documentFolderId?: string) => {
    if (item.files) {
      performUpload(item.files);
    }
  };

  const performUpload = (files: Array<File>) => {
    const filenames = files.map((file) => ({
      filename: file.name,
      id: uuid(),
    }));
    setUploadingFiles((uploadingFiles) => [...uploadingFiles, ...filenames]);
    const formData = new FormData();
    formData.append("showInPortal", "1");
    formData.append("dealId", deal.id);

    files.forEach((file) => {
      formData.append("dealDocuments", file);
    });

    fetch(`${process.env.REACT_APP_API_URL}/dealDocuments`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(() => {
        if (refetch) {
          refetch();
        }
      })
      .catch((err) => {
        showError("There was an error uploading the documents");
      })
      .finally(() => {
        setUploadingFiles((uploadingFiles) =>
          uploadingFiles.filter(
            (uploadingFile) => !filenames.includes(uploadingFile)
          )
        );
      });
  };

  return (
    <div>
      <DetailCard
        collapsible={false}
        title={<Typography variant="h5">Documents</Typography>}
        action={
          <IconButton onClick={() => fileInput.current?.click()} size="large">
            <AddIcon />
          </IconButton>
        }
      >
        <div style={{ width: "100%", opacity }} ref={drop}>
          <input
            type="file"
            id="file"
            ref={fileInput}
            onChange={(e: any) => performUpload(Array.from(e.target.files))}
            style={{ display: "none" }}
          />
          {(documents && documents?.length > 0) ||
          (folders && folders.length > 0) ? (
            <List style={{ width: "100%" }}>
              {folders?.length > 0 &&
                folders.map((folder: any) => (
                  <Folder
                    key={folder.id}
                    isClient={true}
                    dealId={deal.id}
                    dragAndDrop={false}
                    folderProp={folder}
                    refetch={refetch}
                  />
                ))}
              {documents.map((document: any) => (
                <DocumentListItem
                  key={document.id}
                  isDraggableDocument={true}
                  dealId={deal.id}
                  isClient={true}
                  document={document}
                  refetch={refetch}
                />
              ))}
              {uploadingFiles.length > 0 &&
                uploadingFiles.map(({ filename, id }) => (
                  <ListItem key={id}>
                    <ListItemText primary={filename} />
                    <ListItemSecondaryAction>
                      <div style={{ padding: "12px" }}>
                        <CircularProgress size={24} />
                      </div>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          ) : (
            <p className={classes.noDocuments}>No documents</p>
          )}
        </div>
      </DetailCard>
    </div>
  );
};

export default Documents;
