import React, { useState, FormEvent, useEffect } from "react";
import SideLayout from "../layouts/SideLayout";
import { Grid, Box, TextField, Button, FormHelperText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation } from "react-router-dom";
import { validateEmail, validateString, hasTrueValues } from "../helpers";
import { gql } from "@apollo/client";
import qs from "qs";
import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import { useAuth } from "../context/auth";

export default function ResetPassword() {
  const RESET_PASSWORD = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
      resetPassword(input: $input) {
        token
      }
    }
  `;

  const { login } = useAuth();
  const [error, setError] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [formErrors, setFormErrors] = useState<any>({});
  const location = useLocation();
  useEffect(() => {
    const search = qs.parse(location.search.slice(1));
    if (search.verificationCode) {
      setCode(search.verificationCode as string);
    }
  }, [location]);

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setError(undefined);
    const formErrors: any = {};
    const input: any = {};

    [input.email, formErrors.email] = validateEmail(email);
    [input.password, formErrors.password] = validateString(password, 12);
    [input.code, formErrors.code] = validateString(code);
    setFormErrors(formErrors);
    if (!email || !password || hasTrueValues(formErrors)) {
      return;
    }
    resetPassword({
      variables: {
        input,
      },
    })
      .then(() => {
        login(email, password);
      })
      .catch(() => {
        setError("An error occurred when resetting your password");
      });
  };

  const useStyles = makeStyles((theme) => ({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    img: {
      height: "45px",
      width: "100%",
      paddingBottom: "1em",
    },
    p: {
      fontSize: "16px",
      fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      fontWeight: 600,
      textAlign: "center",
    },
  }));
  const classes = useStyles();

  return (
    <SideLayout
      right={
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ maxHeight: "100vh", maxWidth: "100%" }}
        >
          <Grid item xs={10} sm={6}>
            {
              <img
                src="/logo-full.svg"
                alt="Shaker logo"
                aria-label="Shaker logo"
                title="Shaker"
                className={classes.img}
              />
            }
            <Box textAlign="center">
              <h2>Reset your password</h2>
              <p>You're a few steps away from being back in your account!</p>
              {error ? <Alert severity="error">{error}</Alert> : null}
            </Box>
            <form noValidate onSubmit={handleSubmit} className={classes.form}>
              <TextField
                label="Email"
                fullWidth
                variant="outlined"
                margin="normal"
                type="email"
                error={formErrors.email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Verification Code"
                fullWidth
                variant="outlined"
                margin="normal"
                value={code}
                error={formErrors.code}
                onChange={(e) => setCode(e.target.value)}
              />
              <TextField
                label="Password"
                fullWidth
                variant="outlined"
                margin="normal"
                type="password"
                error={formErrors.password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormHelperText>
                Password must be at least 12 characters long
              </FormHelperText>
              <TextField
                label="Confirm Password"
                fullWidth
                variant="outlined"
                margin="normal"
                type="password"
                error={
                  !!confirmPassword &&
                  confirmPassword.length > 0 &&
                  confirmPassword !== password
                }
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Reset &amp; Login
              </Button>
            </form>
          </Grid>
        </Grid>
      }
    />
  );
}
