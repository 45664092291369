import React from "react";
import { useApolloClient, gql, useMutation } from "@apollo/client";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  CloudDownload,
  Delete,
  OpenInBrowser,
  Visibility,
  VisibilityOff,
  Undo,
} from "@mui/icons-material";
import { useAlert } from "../../../context/alert";
import { useUser } from "../../../context/user";

interface DocumentMenuOptionsProps {
  document: any;
  refetch: any;
  dealId?: string;
  documentType?: any;
  isClient?: boolean;
  setAnchorEl?: any;
  setViewUrl?: any;
}

const DocumentMenuOptions = ({
  document,
  refetch,
  dealId,
  documentType,
  isClient = false,
  setAnchorEl,
  setViewUrl,
}: DocumentMenuOptionsProps) => {
  const apollo = useApolloClient();
  const { showSuccess, showError } = useAlert();
  const { currentUser } = useUser();

  const match = document.name.match("[0-9a-zA-z]+$");
  const fileType = match ? match[0] : "";

  const canView = () => {
    switch (fileType) {
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
      case "svg":
      case "pdf":
        return true;
      default:
        return false;
    }
  };

  const [deleteDocument] = useMutation(gql`
    mutation DeleteDocument($id: ID!) {
      deleteDocument(id: $id)
    }
  `);

  const [updateDocumentMutation] = useMutation(gql`
    mutation updateDocument($input: UpdateDocumentInput!) {
      updateDocument(input: $input) {
        id
        name
        showInPortal
      }
    }
  `);

  const getDownloadUrl = () => {
    const getDownloadUrl = gql`
      query GetSignedDownloadDocumentUrl($id: ID!) {
        getSignedDownloadDocumentUrl(id: $id) {
          downloadUrl
        }
      }
    `;
    return apollo
      .query({
        query: getDownloadUrl,
        variables: {
          id: document.id,
        },
      })
      .then((res) => {
        return res.data.getSignedDownloadDocumentUrl.downloadUrl as string;
      })
      .catch(() => {
        showError(
          `An error occurred when starting to download "${document.name}"`
        );
        return undefined;
      });
  };

  const getClientDownloadUrl = () => {
    const getDownloadUrl = gql`
      query getDocumentDownloadUrl($dealId: ID!, $documentId: ID) {
        deal(id: $dealId) {
          documents(id: $documentId) {
            signedDownloadDocumentUrl
          }
        }
      }
    `;
    return apollo
      .query({
        query: getDownloadUrl,
        variables: {
          dealId: dealId,
          documentId: document.id,
        },
      })
      .then((res) => {
        return res.data.deal.documents[0].signedDownloadDocumentUrl as string;
      })
      .catch(() => {
        showError(
          `An error occurred when starting to download "${document.name}"`
        );
        return undefined;
      });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    handleMenuClose();

    deleteDocument({
      variables: {
        id: document.id,
      },
    })
      .then(() => {
        showSuccess(`The document "${document.name}" was deleted`);
        refetch();
      })
      .catch(() => {
        showError(`An error occurred when deleting "${document.name}"`);
      });
  };

  const handlePortalClick = (document: any) => {
    updateDocumentMutation({
      variables: {
        input: {
          id: document.id,
          showInPortal: !document.showInPortal,
        },
      },
    })
      .then(() => {
        showSuccess(
          document.showInPortal
            ? "Document removed from Client Portal"
            : "Document added to Client Portal"
        );
        refetch();
        setAnchorEl(null);
      })
      .catch(() => {
        showError("There was a problem updating the document");
      });
  };

  const getDocumentDownloadUrl = () =>
    isClient ? getClientDownloadUrl() : getDownloadUrl();

  const handleViewClick = () => {
    getDocumentDownloadUrl().then((downloadUrl) => {
      setViewUrl(downloadUrl ?? null);
      handleMenuClose();
    });
  };

  const handleDownloadClick = () => {
    getDocumentDownloadUrl().then((downloadUrl) => {
      if (downloadUrl) {
        window.location.href = downloadUrl;
        handleMenuClose();
      }
    });
  };

  const handleUnassign = () => {
    updateDocumentMutation({
      variables: {
        input: {
          id: document.id,
          documentTypeId: null,
        },
      },
    })
      .then(() => {
        showSuccess(
          `The document has been dissociated from ${documentType?.name}`
        );
        refetch();
        setAnchorEl(null);
      })
      .catch(() => {
        showError("There was a problem updating the document");
      });
  };

  return (
    <>
      {canView() && (
        <MenuItem onClick={handleViewClick}>
          <ListItemIcon>
            <OpenInBrowser />
          </ListItemIcon>
          <ListItemText primary="View" />
        </MenuItem>
      )}
      <MenuItem onClick={handleDownloadClick}>
        <ListItemIcon>
          <CloudDownload />
        </ListItemIcon>
        <ListItemText primary="Download" />
      </MenuItem>
      {!isClient && (
        <MenuItem onClick={() => handlePortalClick(document)}>
          <ListItemIcon>
            {document.showInPortal ? <VisibilityOff /> : <Visibility />}
          </ListItemIcon>
          <ListItemText
            primary={
              document.showInPortal
                ? "Hide from Client Portal"
                : "Show in Client Portal"
            }
          />
        </MenuItem>
      )}
      {!isClient && documentType && (
        <MenuItem onClick={handleUnassign}>
          <ListItemIcon>
            <Undo />
          </ListItemIcon>
          <ListItemText primary="Unassign Document" />
        </MenuItem>
      )}
      {(!isClient ||
        currentUser.contacts.find(
          (contact: any) => contact.id === document.uploadedBy?.id
        )) && (
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      )}
    </>
  );
};

export default DocumentMenuOptions;
