import React, { useState, FunctionComponent, useEffect, useMemo } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import {
  TextField,
  TextFieldProps,
  InputAdornment,
  Stack,
  IconButton,
} from "@mui/material";
import moment, { Moment, MomentInput } from "moment";
import { Clear, Event } from "@mui/icons-material";
import { Deal } from "../../models";
import DateTimeEditModal from "./DateTimeEditModal";

interface DateTimeFieldValue {
  date: MomentInput | null;
  dateTime: MomentInput | null;
  selectedAgents?: string[];
  meetingLength?: number;
  googleEvent?: boolean;
}

type DateTimeFieldProps = {
  label?: string;
  id?: string;
  deal?: Deal;
  value?: DateTimeFieldValue;
  settings?: { includeTime?: boolean; eventType?: string };
  onChange?: (value: DateTimeFieldValue | undefined) => void;
};

const valueToMoment = (value: DateTimeFieldValue | null) => {
  if (value === null) {
    return null;
  }
  let m: Moment | null = null;
  if (value.dateTime) {
    m = moment(value.dateTime);
  } else if (value.date) {
    m = moment(value.date);
  }
  if (m?.isValid()) {
    return m;
  }
  return null;
};

const DatePickerComponent: FunctionComponent<DateTimeFieldProps> = ({
  label,
  settings,
  onChange,
  value: _value,
}) => {
  const [value, setValue] = useState<DateTimeFieldValue>({
    date: null,
    dateTime: null,
  });
  useEffect(() => {
    if (_value) {
      setValue(_value);
    }
  }, [_value]);

  const dateValue = useMemo(() => {
    return valueToMoment(value);
  }, [value]);

  const handleChange = (date: any) => {
    let value: DateTimeFieldValue;
    if (date === null) {
      value = { date: null, dateTime: null };
    } else {
      date = moment(date);
      if (!date.isValid()) {
        return { date: null, dateTime: null };
      }
      if (date && moment.isMoment(date) && !settings?.includeTime) {
        // We'll truncate the time to midnight
        (date as Moment).startOf("d");
      }
      value = { date: date.format("YYYY-MM-DD"), dateTime: null };
    }
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <DatePicker
      renderInput={(params: TextFieldProps) => (
        <TextField {...params} variant="standard" fullWidth />
      )}
      label={label}
      componentsProps={{
        actionBar: {
          actions: ["clear"],
        },
      }}
      value={dateValue}
      onChange={handleChange}
    ></DatePicker>
  );
};

const DateTimeFieldComponent: FunctionComponent<DateTimeFieldProps> = ({
  label,
  id,
  deal,
  settings,
  value: _value,
  onChange,
}) => {
  const [value, setValue] = useState(_value);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const formattedValue = useMemo(() => {
    let m: Moment | null = null;
    let format = "MM/DD/YYYY";

    if (value?.dateTime) {
      m = moment(value.dateTime);
      format = "MM/DD/YYYY h:mm A";
    } else if (value?.date) {
      m = moment(value?.date);
    }

    if (m && m.isValid()) {
      return m.format(format);
    }
    return "";
  }, [value]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [fieldHover, setFieldHover] = useState(false);

  const handleClear = () => {
    setValue({
      date: null,
      dateTime: null,
    });
    if (onChange) {
      onChange({
        date: null,
        dateTime: null,
      });
    }
  };

  const inputRef = React.useRef<any>(null);
  return (
    <>
      {settings?.includeTime || settings?.eventType ? (
        <>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Stack direction="row">
                    {formattedValue && fieldHover && (
                      <IconButton
                        onClick={() => {
                          handleClear();
                        }}
                      >
                        <Clear />
                      </IconButton>
                    )}
                    <IconButton
                      sx={{ paddingRight: "0px", marginRight: "-3px" }}
                      onClick={() => setAnchorEl(inputRef.current)}
                    >
                      <Event />
                    </IconButton>
                  </Stack>
                </InputAdornment>
              ),
            }}
            onMouseOut={() => setFieldHover(false)}
            onMouseOver={() => setFieldHover(true)}
            fullWidth
            value={formattedValue ?? ""}
            inputRef={inputRef}
            label={label}
            variant="standard"
          />
          <DateTimeEditModal
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            label={label}
            settings={settings}
            value={value}
            onChange={onChange}
            id={id}
            deal={deal}
          />
        </>
      ) : (
        <DatePickerComponent
          label={label}
          value={_value}
          settings={settings}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default DateTimeFieldComponent;
