import React, { FunctionComponent } from "react";
import EmailThreadPanel from "../emails/ThreadPanel";
import { Box, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noMessages: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "11px",
      color: "#747b88",
    },
  })
);

type MessagesProps = {
  contact: any;
  threads: any;
  loading: boolean;
};
const Messages: FunctionComponent<MessagesProps> = ({
  contact,
  threads,
  loading,
}) => {
  const classes = useStyles();

  if (!loading && threads?.length === 0) {
    return <p className={classes.noMessages}>No emails to display</p>;
  }

  return (
    <>
      {threads
        .filter((emailThread: any) => emailThread.lastEmail)
        .map((emailThread: any) => (
          <EmailThreadPanel
            key={emailThread.id}
            emailThread={emailThread}
            contact={contact}
          />
        ))}
      {loading && (
        <Box p={4}>
          <Skeleton variant="rectangular" />
        </Box>
      )}
    </>
  );
};

export default Messages;
