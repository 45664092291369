import React, { FunctionComponent, useState } from "react";
import { Avatar, Box, Link, List, ListItem, Typography } from "@mui/material";
import ComposeSmsDialog from "../sms/ComposeSmsDialog";
import moment from "moment";
import { useUser } from "../../context/user";

type SmsThreadsProps = {
  threads: any;
  loading: boolean;
};
const SmsThreads: FunctionComponent<SmsThreadsProps> = ({
  threads,
  loading,
}) => {
  const [selectedSmsThread, setSelectedSmsThread] = useState<any | null>();
  const { currentUser } = useUser();

  if (!loading && threads.length === 0) {
    return (
      <Box textAlign="center" p={4}>
        {currentUser.teamMember.provisionedPhone && (
          <Typography color="textSecondary" style={{ fontSize: 12 }}>
            No SMS messages to display
          </Typography>
        )}
        {!currentUser.teamMember.provisionedPhone && (
          <Typography color="textSecondary" style={{ fontSize: 12 }}>
            <Link href="mailto:support@shaker.io">Contact support</Link> to
            provision a phone number
          </Typography>
        )}
      </Box>
    );
  }

  const participantName = (participant: any) => {
    if (participant.id === currentUser.teamMember?.id) {
      return "You";
    }
    return participant.name;
  };

  const handleSmsClick = (smsThread: any) => {
    setSelectedSmsThread(smsThread);
  };

  return (
    <>
      <List>
        {threads.map((smsThread: any) => (
          <ListItem
            key={smsThread.id}
            onClick={() => handleSmsClick(smsThread)}
            button
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                marginBottom: "12px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                {smsThread.participants?.map(
                  (participant: any, index: number) => (
                    <div
                      key={participant.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "6px",
                      }}
                    >
                      <Avatar
                        component="span"
                        alt={participant.name}
                        src={participant.avatarUrl}
                        style={{
                          height: "18px",
                          width: "18px",
                          marginRight: "4px",
                        }}
                      />
                      <Typography>{participantName(participant)} </Typography>
                      {index + 1 < smsThread.participants?.length && (
                        <span>,</span>
                      )}
                    </div>
                  )
                )}
                <Typography>{smsThread.lastMessageText}</Typography>
              </div>
              <Typography color="textSecondary">
                {moment(smsThread?.updatedAt).calendar()}
              </Typography>
            </div>
          </ListItem>
        ))}
      </List>

      {selectedSmsThread && (
        <ComposeSmsDialog
          smsThread={selectedSmsThread}
          open={Boolean(selectedSmsThread)}
          disableRecipientSelection={true}
          onClose={() => {
            setSelectedSmsThread(undefined);
          }}
        />
      )}
    </>
  );
};
export default SmsThreads;
