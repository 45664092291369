import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { gql } from "@apollo/client";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Grid } from "@mui/material";
import MaterialTable, { Query, QueryResult } from "@material-table/core";
import TableIcons from "../../components/TableIcons";
import AdminToolbar from "../../components/admin/AdminToolbar";
import { OutlineButton } from "../../components/buttons";
import { useUser } from "../../context/user";
import { useApi } from "../../context/api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: "1440px",
      margin: "0 auto",
      fontFamily: ["AvenirNext-Regular", "Avenir", "sans-serif"].join(","),
    },
    gridContainer: {
      padding: "100px 25px 0",
    },
    leftColumn: {
      [theme.breakpoints.down("lg")]: {
        marginBottom: "50px",
      },
    },
  })
);

const GET_ALL_USERS = gql`
  query GET_USERS($page: Int, $pageSize: Int, $term: String) {
    allUsers(page: $page, pageSize: $pageSize, term: $term) {
      page
      pageSize
      totalCount
      users {
        id
        name
        email
        account {
          id
          name
        }
      }
    }
  }
`;

const UserDashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { handleImpersonate } = useUser();
  const { apolloClient } = useApi();

  const handleRowClick = (e: any, rowData: any) => {
    const accountId = rowData.account ? rowData.account.id : null;
    if (accountId) {
      navigate(`/admin/${accountId}`);
    } else {
      return;
    }
  };

  const queryUsers = (query: Query<any>): Promise<QueryResult<any>> => {
    const params = {
      page: query.page,
      pageSize: query.pageSize,
      term: query.search,
    };

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: GET_ALL_USERS,
          variables: { ...params },
        })
        .then((response) => {
          resolve({
            data: response.data.allUsers.users,
            page: response.data.allUsers.page,
            totalCount: response.data.allUsers.totalCount,
          });
        })
        .catch((error) => reject(error));
    });
  };

  return (
    <div className={classes.root}>
      <AdminToolbar />
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} lg={4} className={classes.leftColumn}>
          <h1>Search by Users</h1>
          <Link to={"/admin"}>Search by Organization</Link>
        </Grid>
        <Grid item xs={12} lg={8}>
          <MaterialTable
            columns={[
              { title: "Email", field: "email" },
              {
                title: "Name",
                field: "name",
              },
              {
                title: "Organization",
                field: "organization",
                render: (rowData: any) => {
                  return (
                    <div>{rowData.account ? rowData.account.name : "N/A"}</div>
                  );
                },
              },
              {
                title: "",
                field: "impersonate",
                render: (rowData: any) => {
                  return (
                    <OutlineButton
                      onClick={() => handleImpersonate(rowData.id)}
                    >
                      Impersonate
                    </OutlineButton>
                  );
                },
              },
            ]}
            data={queryUsers}
            title="Users"
            icons={TableIcons}
            onRowClick={handleRowClick}
            options={{
              selection: false,
              showTitle: true,
              search: true,
              emptyRowsWhenPaging: false,
              pageSize: 10,
              toolbar: true,
              headerStyle: {
                fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(
                  ","
                ),
                fontSize: "14px",
                color: "rgb(107, 114, 128)",
              },
            }}
            style={{
              width: "100%",
              fontSize: "14px",
              fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(
                ","
              ),
            }}
          ></MaterialTable>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserDashboard;
