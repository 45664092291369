import React, { useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Box } from "@mui/material";
import { Mls } from "../models";
import { Typography } from "@mui/material";
import { Link } from "@mui/icons-material";
import { connect } from "react-redux";
import { findMls } from "../reducers/mlses";

type MlsSelectProps = {
  mlses: Mls[];
  findMls: (mlsKey: string | null) => Mls | null;
  value?: string | null;
  onChange: (mlsKey: string | null) => void;
  label?: string;
  style?: any;
};

const MlsSelect = ({
  mlses,
  findMls,
  value = null,
  onChange,
  label = "Select an MLS",
  style,
}: MlsSelectProps) => {
  const handleMlsSelected = useCallback(
    (mls: Mls | null) => {
      if (mls?.mlsKey !== value) {
        onChange(mls?.mlsKey ?? null);
      }
    },
    [onChange, value]
  );

  const selectedMls = useCallback(() => {
    return findMls(value);
  }, [findMls, value]);

  return (
    <Autocomplete
      fullWidth
      onChange={(_, mls) => handleMlsSelected(mls)}
      options={mlses}
      getOptionLabel={(option: Mls) => option.name || ""}
      isOptionEqualToValue={(option, value) => option.mlsKey === value.mlsKey}
      value={selectedMls()}
      renderInput={(params: any) => (
        <TextField {...params} label={label} variant="standard" />
      )}
      renderOption={(props, mls: any) => (
        <Box
          key={mls.mlsKey}
          component="li"
          {...props}
          sx={{ display: "flex", width: "100%" }}
        >
          <Typography>{mls.name}</Typography>
          <div style={{ flexGrow: 1 }}></div>
          {mls.connected && <Link />}
        </Box>
      )}
      style={style}
    />
  );
};

const mapStateToProps = ({ mlses }: { mlses: Mls[] }) => {
  return { mlses, findMls: findMls({ mlses }) };
};
export default connect(mapStateToProps)(MlsSelect);
