import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileWord,
  faFile,
  faFileExcel,
  faFileImage,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: "26px",
      marginLeft: "3px",
    },
    smallIcon: {
      fontSize: "20px",
      marginRight: "3px",
    },
  })
);

type FileTypeIconProps = {
  filename: string;
  smallIcons?: boolean;
};

const FileTypeIcon = ({ filename, smallIcons }: FileTypeIconProps) => {
  const classes = useStyles();

  const fileExtension = filename.split(".").pop();
  switch (fileExtension) {
    case "doc":
    case "docx":
    case "txt":
      return (
        <FontAwesomeIcon
          icon={faFileWord}
          style={{ color: "#0B8D9B" }}
          className={!smallIcons ? classes.icon : classes.smallIcon}
        />
      );
    case "pdf":
      return (
        <FontAwesomeIcon
          icon={faFilePdf}
          style={{ color: "#0B8D9B" }}
          className={!smallIcons ? classes.icon : classes.smallIcon}
        />
      );
    case "xls":
    case "xlsx":
    case "csv":
      return (
        <FontAwesomeIcon
          icon={faFileExcel}
          style={{ color: "#0B8D9B" }}
          className={!smallIcons ? classes.icon : classes.smallIcon}
        />
      );
    case "png":
    case "jpg":
    case "jpeg":
    case "bmp":
      return (
        <FontAwesomeIcon
          icon={faFileImage}
          style={{ color: "#0B8D9B" }}
          className={!smallIcons ? classes.icon : classes.smallIcon}
        />
      );
    case "missing":
      return (
        <FontAwesomeIcon
          icon={faFile}
          style={{ color: "#1A0B31" }}
          className={!smallIcons ? classes.icon : classes.smallIcon}
        />
      );
    default:
      return (
        <FontAwesomeIcon
          icon={faFile}
          style={{ color: "#0B8D9B" }}
          className={!smallIcons ? classes.icon : classes.smallIcon}
        />
      );
  }
};

export default FileTypeIcon;
