import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField, Chip, Typography } from "@mui/material";
import { Tag } from "../../models";

type ServicesAutocompleteProps = {
  setValue: (value: any) => void;
  value: any;
  label: string;
  freeSolo?: boolean;
  vendorTags: Array<Tag>;
};

const filter = createFilterOptions<Tag>();

const ServicesAutocomplete = ({
  label,
  value,
  setValue,
  vendorTags,
  freeSolo = false,
}: ServicesAutocompleteProps) => {
  const handleChange = (e: ChangeEvent<any>, value: any) => {
    const selected = value.map((tag: Tag) => {
      if (typeof tag === "string") {
        return { name: tag };
      }
      // Cleanup label property
      return Object.assign({}, tag, { label: undefined });
    });
    setValue(selected);
  };
  return (
    <Autocomplete
      multiple
      freeSolo={freeSolo}
      id="selected-services-autocomplete"
      options={vendorTags}
      value={value}
      onChange={handleChange}
      getOptionLabel={(option: any) => option.label || option.name}
      filterSelectedOptions
      filterOptions={(options: any, params: any) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "" && freeSolo) {
          filtered.push({
            name: params.inputValue,
            label: `Add: "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      renderTags={(value: any, getOptionSelected) => {
        return value.map((value: any, index: number) => (
          <Chip
            color="primary"
            label={<Typography>{value.name || value}</Typography>}
            variant={"outlined"}
            style={{ margin: "10px 3px" }}
            {...getOptionSelected({ index })}
          />
        ));
      }}
      renderInput={(params: any) => (
        <TextField
          fullWidth
          {...params}
          variant={"standard"}
          label={label}
          style={{ minWidth: "325px" }}
        />
      )}
      style={{ marginBottom: "10px", marginRight: "16px" }}
    />
  );
};

const mapStateToProps = ({ vendorTags }: { vendorTags: Tag[] }) => ({
  vendorTags,
});
export default connect(mapStateToProps)(ServicesAutocomplete);
