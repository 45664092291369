import React, { useContext } from "react";
import { dealViewStore } from "../context";
import DealDocuments from "../../../../components/deals/Documents";

const Documents = () => {
  const {
    state: { deal },
  } = useContext(dealViewStore);
  return <DealDocuments deal={deal} />;
};

export default Documents;
