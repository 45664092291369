import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { gql } from "@apollo/client";
import moment from "moment";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Grid } from "@mui/material";
import MaterialTable, { Query, QueryResult } from "@material-table/core";
import TableIcons from "../../components/TableIcons";
import AdminToolbar from "../../components/admin/AdminToolbar";
import { useApi } from "../../context/api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: "1440px",
      margin: "0 auto",
      fontFamily: ["AvenirNext-Regular", "Avenir", "sans-serif"].join(","),
    },
    gridContainer: {
      padding: "100px 25px 0",
    },
    leftColumn: {
      [theme.breakpoints.down("lg")]: {
        marginBottom: "50px",
      },
    },
  })
);

const GET_ACCOUNTS = gql`
  query GET_ACCOUNTS($page: Int, $pageSize: Int, $term: String) {
    accounts(page: $page, pageSize: $pageSize, term: $term) {
      page
      totalCount
      accounts {
        id
        name
        updatedAt
        createdAt
        lastActiveDate
        availableIntegrations {
          name
          id
          enabled
          added
        }
        users {
          totalCount
        }
      }
    }
  }
`;

const Dashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { apolloClient } = useApi();

  const handleRowClick = (e: any, rowData: any) => {
    navigate(`/admin/${rowData.id}`);
  };

  const queryAccounts = (query: Query<any>): Promise<QueryResult<any>> => {
    const params = {
      page: query.page,
      pageSize: query.pageSize,
      term: query.search,
    };

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: GET_ACCOUNTS,
          variables: { ...params },
        })
        .then((response) => {
          resolve({
            data: response.data.accounts.accounts,
            page: response.data.accounts.page,
            totalCount: response.data.accounts.totalCount,
          });
        })
        .catch((error) => reject(error));
    });
  };

  return (
    <div className={classes.root}>
      <AdminToolbar />
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} lg={4} className={classes.leftColumn}>
          <h1>Search by Organization</h1>
          <Link to={"/admin/users"}>Search by Users</Link>
          <br />
          <Link to={"/admin/user_activity"}>User Activity</Link>
        </Grid>
        <Grid item xs={12} lg={8}>
          <MaterialTable
            columns={[
              { title: "Organization", field: "name" },
              {
                title: "# of Users",
                field: "users",
                render: (rowData) => rowData.users.totalCount,
              },
              {
                title: "Last User Activity",
                field: "lastActiveDate",
                sorting: false,
                render: (rowData) =>
                  rowData.lastActiveDate
                    ? moment(rowData.lastActiveDate).fromNow()
                    : "",
              },
            ]}
            data={queryAccounts}
            title="Organizations"
            icons={TableIcons}
            onRowClick={handleRowClick}
            options={{
              selection: false,
              showTitle: true,
              search: true,
              emptyRowsWhenPaging: false,
              pageSize: 10,
              toolbar: true,
              headerStyle: {
                fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(
                  ","
                ),
                fontSize: "14px",
                color: "rgb(107, 114, 128)",
              },
            }}
            style={{
              width: "100%",
              fontSize: "14px",
              fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(
                ","
              ),
            }}
          ></MaterialTable>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
