import React, { useState } from "react";
import {
  Dialog,
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useAlert } from "../../../context/alert";
import { Document as DocumentView, Page } from "react-pdf";
import { useDrag } from "react-dnd";
import DocumentComp from "./Document";
import DocumentType from "./DocumentType";
import { CloudUploadIcon } from "../../../icons";
import DocumentMenuOptions from "./DocumentMenuOptions";

interface DocumentListItemProps {
  isDraggableDocument: boolean;
  document?: any;
  documentType?: any;
  handleOpenUpload?: any;
  refetch: any;
  reportView?: boolean;
  dealId?: string;
  isClient?: boolean;
  onDrop?: any;
}

const DocumentListItem = ({
  document,
  documentType,
  isDraggableDocument,
  refetch,
  dealId,
  reportView = false,
  handleOpenUpload,
  isClient = false,
  onDrop,
}: DocumentListItemProps) => {
  const { showError } = useAlert();

  const [anchorEl, setAnchorEl] = useState<null | any>(null);
  const [viewUrl, setViewUrl] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const match = document ? document.name.match("[0-9a-zA-z]+$") : "";
  const fileType = match ? match[0] : "";

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: "document",
      item: document,
      canDrag: () => {
        return !isClient;
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    []
  );

  const handleDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const handleDocumentLoadError = (err: any) => {
    showError(`An error occurred when loading ${document.name}`);
    setViewUrl(null);
  };

  const handleUploadClick = () => {
    handleOpenUpload(documentType.id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderDocumentPreview = () => {
    switch (fileType) {
      case "pdf":
        return (
          <DocumentView
            file={viewUrl}
            onLoadSuccess={handleDocumentLoadSuccess}
            onLoadError={handleDocumentLoadError}
            loading={
              <Box p={6}>
                <CircularProgress />
              </Box>
            }
          >
            {Array.from(Array(numPages).keys()).map((i) => (
              <Page
                pageIndex={i}
                loading={<Skeleton variant="rectangular" />}
              />
            ))}
          </DocumentView>
        );
      case "jpeg":
      case "jpg":
      case "png":
      case "gif":
      case "svg":
        return viewUrl && <img alt={"rendered"} src={viewUrl} />;
      default:
        return <></>;
    }
  };

  return (
    <div ref={isDraggableDocument ? dragRef : undefined} style={{ opacity }}>
      {isDraggableDocument ? (
        <DocumentComp
          key={document.id}
          document={document}
          reportView={reportView}
          setAnchorEl={setAnchorEl}
        />
      ) : (
        <DocumentType
          key={documentType.id}
          documentType={documentType}
          document={document ? document : null}
          reportView={reportView}
          setAnchorEl={setAnchorEl}
          onDrop={onDrop}
        />
      )}
      <Menu
        id="documentMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {!isClient && !document ? (
          <MenuItem onClick={handleUploadClick}>
            <ListItemIcon>
              <CloudUploadIcon />
            </ListItemIcon>
            <ListItemText primary={"Add File"} />
          </MenuItem>
        ) : (
          <DocumentMenuOptions
            document={document}
            documentType={documentType ?? null}
            refetch={refetch}
            dealId={dealId}
            setAnchorEl={setAnchorEl}
            setViewUrl={setViewUrl}
            isClient={isClient}
          />
        )}
      </Menu>
      {viewUrl && (
        <Dialog open={true} onClose={(e) => setViewUrl(null)}>
          {renderDocumentPreview()}
        </Dialog>
      )}
    </div>
  );
};

export default DocumentListItem;
