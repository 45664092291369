import React, { useState } from "react";
import {
  Card,
  CardContent,
  Avatar,
  Theme,
  Typography,
  Chip,
  Menu,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CalendarIcon } from "../../icons";
import AvatarGroup from "@mui/material/AvatarGroup";
import { useAlert } from "../../context/alert";
import { DELETE_DEAL } from "../../api/graphql";
import { useMutation } from "@apollo/client";
import { MoreVert, Archive, Delete } from "@mui/icons-material";
import { getCityStateString, getStreetString } from "../../helpers/address";
import DualDealProgress from "./DualDealProgress";
import { LinearProgressWithLabel } from "../LinearProgressWithLabel";
import { ArchiveDialog } from "./ArchiveDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dealCard: {
      "&:hover": {
        "& $moreIcon": {
          display: "initial",
        },
      },
    },

    header: { display: "flex", paddingBottom: "0" },
    flexAlignCenter: {
      display: "flex",
      alignItems: "center",
    },
    contactAvatarGroup: { margin: "0 14px 0 0" },
    contactInfo: { display: "flex", flexDirection: "column" },
    contactName: { lineHeight: "20px" },
    address: { lineHeight: "16px" },
    detail: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    detailLeft: {
      display: "flex",
      flexDirection: "column",
      color: theme.palette.grey[600],
    },
    valueChip: {
      height: "24px",
      backgroundColor: theme.palette.grey[100],
      fontFamily: theme.typography.body2.fontFamily,
      fontSize: theme.typography.body2.fontSize,
    },
    moreIcon: {
      marginLeft: "auto",
      display: "none",
    },
  })
);

const StyledCard = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "12px",
      height: "150px",
      display: "flex",
      flexDirection: "column",
      borderRadius: "8px",
      cursor: "pointer",

      "& .MuiCardContent-root:last-child": {
        paddingBottom: theme.spacing(2),
      },
    },
  })
)(Card);

type DealCardProps = {
  deal: any;
  onDealDelete: (deal: any) => any;
  onDealArchive: (deal: any) => any;
  showDualProgress?: boolean;
};

const DealCard = ({
  deal,
  onDealDelete,
  onDealArchive,
  showDualProgress,
}: DealCardProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState<any>(null);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const { showError, showSuccess } = useAlert();
  const [deleteDeal] = useMutation(DELETE_DEAL);
  //handles menu click
  const handleMenuClick = (e: any, deal: any) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = (e?: any) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setMenuAnchor(null);
  };

  const handleDeleteClick = (e: any) => {
    if (!window.confirm("Are you sure you want to delete this deal?")) {
      return;
    }
    e.stopPropagation();
    deleteDeal({
      variables: {
        id: deal.id,
      },
    })
      .then(() => {
        showSuccess(`Deal "${deal.name}" was deleted`);
        if (onDealDelete) {
          onDealDelete(deal);
        }
      })
      .catch(() => {
        showError("An error occurred when deleting the deal");
      })
      .finally(() => {
        handleMenuClose(e);
      });
  };

  //handles card click
  const handleClick = (e: any) => {
    e.stopPropagation();
    navigate(`/deals/${deal.id}/details`);
  };

  const CardDate = () => {
    let verb = "";
    let m: moment.Moment | undefined;

    if (deal.closeDate) {
      verb = "Closed";
      m = moment(deal.closeDate, "YYYY-MM-DD");
      if (m.isAfter()) {
        verb = "Closing";
      }
    } else if (deal.dealType.name === "Selling" && deal.listingDate) {
      m = moment(deal.listingDate, "YYYY-MM-DD");
      verb = "Listed";
      if (m.isAfter()) {
        verb = "Listing";
      }
    }

    if (m === undefined || !m.isValid() || !verb) {
      return null;
    }

    const formatted = `${verb} ${m.format("ll")}`;
    if (formatted) {
      return (
        <Typography
          variant="body2"
          style={{
            lineHeight: "16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarIcon size="12px" style={{ marginRight: "6px" }} />
          {formatted}
        </Typography>
      );
    }
    return null;
  };

  const formattedValue = () => {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });

    return currencyFormatter.format(deal.value);
  };
  const handleArchiveModal = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setMenuAnchor(null);
    setArchiveOpen(true);
  };

  const handleArchiveClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setArchiveOpen(false);
  };
  return (
    <>
      <StyledCard
        onClick={(e: any) => handleClick(e)}
        className={classes.dealCard}
      >
        <CardContent className={classes.header}>
          <div className={classes.flexAlignCenter}>
            <AvatarGroup max={3} className={classes.contactAvatarGroup}>
              {deal.contacts.map((c: any) => (
                <Avatar key={c.id} src={c.avatarUrl} alt={c.name} />
              ))}
            </AvatarGroup>
            <div className={classes.contactInfo}>
              <Typography variant="h6" className={classes.contactName}>
                {deal.contacts[0]?.name}
              </Typography>
              <Typography variant="subtitle1" className={classes.address}>
                {getStreetString(deal.address)}
              </Typography>
              <Typography variant="subtitle1" className={classes.address}>
                {getCityStateString(deal.address)}
              </Typography>
            </div>
          </div>

          <div className={classes.moreIcon}>
            <IconButton
              edge="end"
              size="small"
              aria-label="Show menu options"
              onClick={(e) => handleMenuClick(e, deal)}
            >
              <MoreVert />
            </IconButton>
          </div>

          <Menu
            id="deal-menu"
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={(e) => handleArchiveModal(e)}>
              <ListItemIcon>
                <Archive />
              </ListItemIcon>
              <ListItemText primary="Archive" />
            </MenuItem>
            <MenuItem onClick={handleDeleteClick}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
          </Menu>
        </CardContent>

        <div style={{ flexGrow: 1 }}></div>
        <CardContent>
          <div className={classes.detail}>
            <div className={classes.detailLeft}>
              {deal.owner && (
                <Typography variant="body2" style={{ lineHeight: "16px" }}>
                  {deal.owner.name}
                </Typography>
              )}
              <CardDate />
            </div>
            <Chip label={formattedValue()} className={classes.valueChip} />
          </div>
          {deal.progress.documents.total > 0 && showDualProgress ? (
            <DualDealProgress deal={deal} />
          ) : (
            <LinearProgressWithLabel percent={deal.progress.percent} />
          )}
        </CardContent>
      </StyledCard>
      <ArchiveDialog
        open={archiveOpen}
        onClose={handleArchiveClose}
        onSubmit={onDealArchive}
        deal={deal}
      />
    </>
  );
};

export default DealCard;
