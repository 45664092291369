import React from "react";
import { AppBar, Toolbar, IconButton } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MenuIcon from "@mui/icons-material/Menu";
import UserNavigationElement from "../UserNavigationElement";
import { Link as RouterLink } from "react-router-dom";
import { PrimaryButton } from "../buttons";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    grow: {
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        flexGrow: 3,
      },
    },
    mr: {
      marginRight: theme.spacing(2),
    },
  })
);

const AdminToolbar = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar style={{ padding: "0 20px" }}>
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          className={classes.menuButton}
          size="large"
        >
          <MenuIcon />
        </IconButton>

        <img
          src="/logo-full.svg"
          alt="Shaker logo"
          title="Shaker"
          style={{
            height: "25px",
          }}
        />

        <div className={classes.grow} />
        <PrimaryButton
          color="primary"
          variant="outlined"
          component={RouterLink}
          to="/dashboard"
          className={classes.mr}
        >
          App Dashboard
        </PrimaryButton>
        <UserNavigationElement />
      </Toolbar>
    </AppBar>
  );
};

export default AdminToolbar;
