import React from "react";
import MuiToggleButtonGroup, {
  ToggleButtonGroupProps,
} from "@mui/material/ToggleButtonGroup";
import MuiToggleButton from "@mui/material/ToggleButton";
import { Theme } from "@mui/material";

import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const ToggleButtonGroup = ({
  buttonWidth = "100px",
  allowDeselect = false,
  fullWidth = false,
  onChange: parentOnChange = () => {},
  children,
  ...toggleButtonGroupProps
}: {
  buttonWidth?: string;
  allowDeselect?: boolean;
  children: any;
  fullWidth?: boolean;
} & ToggleButtonGroupProps) => {
  const ButtonGroup = withStyles((theme: Theme) =>
    createStyles({
      root: {
        height: "24px",
        border: "1px solid rgb(229, 231, 235)",
        borderRadius: "9px",
        backgroundColor: "#ffffff",
        "& .MuiToggleButton-root": {
          width: buttonWidth,
        },
      },
    })
  )(MuiToggleButtonGroup);

  const useStyles = makeStyles({
    toggleGroupWidth: {
      display: (fullWidth) => (fullWidth ? "flex" : "inline-flex"),
      justifyContent: "space-between",
    },
  });

  const classes = useStyles(fullWidth);

  const onChange = (event: any, value: any) => {
    if (value !== null || allowDeselect) {
      parentOnChange(event, value);
    }
  };

  return (
    <ButtonGroup
      {...toggleButtonGroupProps}
      onChange={onChange}
      className={classes.toggleGroupWidth}
    >
      {children}
    </ButtonGroup>
  );
};

export const ToggleButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "none",
      borderRadius: "9px",
      textTransform: "none",
      fontFamily: theme.typography.body2.fontFamily,
      fontSize: theme.typography.subtitle2.fontSize,
      padding: "0 6px",
      backgroundColor: "#ffffff",
      color: theme.palette.grey[900],

      "&:hover": {
        backgroundColor: "transparent",
      },

      "&:first-child": {
        marginLeft: "0",
        marginRight: "-1px",
      },

      "&:last-child": {
        marginLeft: "-1px",
        marginRight: "0",
      },

      "&.Mui-selected": {
        zIndex: "999",
        color: "#ffffff",
        fontFamily: theme.typography.subtitle2.fontFamily,
        backgroundColor: theme.palette.primary.main,
        marginTop: "-1px",
        marginBottom: "-1px",
        border: "none",
        borderTopLeftRadius: "9px",
        borderTopRightRadius: "9px",
        borderBottomLeftRadius: "9px",
        borderBottomRightRadius: "9px",

        "&:first-child": {
          marginLeft: "-1px",
          marginRight: "0",
          paddingLeft: "7px",
        },

        "&:last-child": {
          marginLeft: "0",
          marginRight: "-1px",
          paddingRight: "7px",
        },

        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  })
)(MuiToggleButton);
