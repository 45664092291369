import mixpanel, { Dict } from "mixpanel-browser";
let useMixpanel = !!process.env.REACT_APP_MIXPANEL_TOKEN;
const noop = () => {};
if (useMixpanel) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
    debug: process.env.REACT_APP_MIXPANEL_DEBUG === "true",
  });
}
const wrappedMixpanel = {
  identify: useMixpanel
    ? (userId: string) => {
        mixpanel.identify(userId);
      }
    : noop,
  track: useMixpanel
    ? (eventName: string, props?: Dict) => {
        mixpanel.track(eventName, props);
      }
    : noop,
  people: {
    set: useMixpanel
      ? (props: Dict) => {
          mixpanel.people.set(props);
        }
      : noop,
  },
  addGroup: useMixpanel
    ? (groupKey: string, groupId: string) => {
        mixpanel.add_group(groupKey, groupId);
      }
    : noop,
  getGroup: (groupKey: string, groupId: string) => {
    if (useMixpanel) {
      return mixpanel.get_group(groupKey, groupId);
    }
  },
};

export default wrappedMixpanel;
