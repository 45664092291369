import React from "react";
import { Typography, LinearProgress, Tooltip } from "@mui/material";
import { Box } from "@mui/system";

interface Progress {
  completed: number;
  total: number;
  percent: number | null;
}

interface DealProgress {
  percent: number | null;
  tasks: Progress;
  documents: Progress;
}

const DualDealProgress = ({ deal }: { deal: { progress: DealProgress } }) => {
  if (deal.progress.percent === null) {
    return null;
  }
  const tasks = deal.progress.tasks;
  const documents = deal.progress.documents;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "8px",
      }}
    >
      <Tooltip
        title={
          <Typography>
            Tasks: {tasks.completed}/{tasks.total}
          </Typography>
        }
        id={`Tasks-Progress`}
      >
        <LinearProgress
          value={(tasks.percent && tasks.percent * 100) || 0}
          variant="determinate"
          sx={{ width: "100%" }}
        />
      </Tooltip>
      <Box sx={{ width: "10%" }} />
      <Tooltip
        title={
          <Typography>
            Documents: {documents.completed}/{documents.total}
          </Typography>
        }
        id={`Documents-Progress`}
      >
        <LinearProgress
          value={(documents.percent && documents.percent * 100) || 0}
          variant="determinate"
          sx={{
            width: "100%",
            backgroundColor: "#FFBCA8",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#FF7A51",
            },
          }}
        />
      </Tooltip>
    </Box>
  );
};

export default DualDealProgress;
