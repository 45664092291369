import React, { useState, cloneElement } from "react";
import {
  Dialog,
  TextField,
  DialogTitle,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Theme,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Close as CloseIcon } from "@mui/icons-material";
import ActionIconButton from "../../components/ActionIconButton";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { gql, useMutation } from "@apollo/client";
import { useAlert } from "../../context/alert";

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

const PasswordDialog = withStyles((theme: Theme) =>
  createStyles({
    paperWidthSm: {
      maxWidth: "475px",
    },
  })
)(Dialog);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      color: theme.palette.error.main,
    },
  })
);

const ChangePasswordDialog = ({ children }: { children: any }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const { showSuccess } = useAlert();

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const handleClose = () => {
    setErrors({});
    setOpen(false);
  };

  const handleSubmit = (event: any) => {
    const { currentPassword, newPassword, confirmNewPassword } =
      event.target.elements;

    setErrors({});

    if (newPassword.value === confirmNewPassword.value) {
      changePassword({
        variables: {
          input: {
            currentPassword: currentPassword.value,
            newPassword: newPassword.value,
          },
        },
      })
        .then((response) => {
          showSuccess("Your password has been changed");
          currentPassword.value = "";
          newPassword.value = "";
          confirmNewPassword.value = "";
          setOpen(false);
        })
        .catch(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            var newErrors = {};

            graphQLErrors.forEach(
              (error: any) =>
                (newErrors = {
                  ...newErrors,
                  [error.extensions.field]: error.extensions.message,
                })
            );

            setErrors(newErrors);
          } else if (networkError) {
            setErrors({ network: true });
          }
        });
    } else {
      setErrors({ confirmNewPassword: "Confirmation must match new password" });
    }
  };

  return (
    <>
      {cloneElement(children as any, {
        onClick: (e) => {
          e.stopPropagation();
          setOpen(true);
        },
      })}
      <PasswordDialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Change Password</Typography>

            <ActionIconButton
              icon={CloseIcon}
              style={{ margin: "4px -5px" }}
              buttonStyle={{ width: "22px", height: "22px" }}
              onClick={handleClose}
              aria-label="close"
            />
          </Box>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              label="Current Password"
              name="currentPassword"
              fullWidth
              margin="normal"
              variant="outlined"
              type="password"
              error={!!errors.currentPassword}
              helperText={errors.currentPassword}
            />
            <TextField
              label="New Password"
              name="newPassword"
              fullWidth
              margin="normal"
              variant="outlined"
              type="password"
              error={!!errors.newPassword}
              helperText={errors.newPassword}
            />
            <TextField
              label="Confirm New Password"
              name="confirmNewPassword"
              fullWidth
              margin="normal"
              variant="outlined"
              type="password"
              error={!!errors.confirmNewPassword}
              helperText={errors.confirmNewPassword}
            />

            {errors.network && (
              <Typography variant="caption" className={classes.errorText}>
                There was a problem changing the password. Please make sure you
                are connected to the internet and try again.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={handleClose} style={{ width: "100px" }}>
              Cancel
            </SecondaryButton>
            <PrimaryButton type="submit" style={{ width: "100px" }}>
              Save
            </PrimaryButton>
          </DialogActions>
        </form>
      </PasswordDialog>
    </>
  );
};

export default ChangePasswordDialog;
