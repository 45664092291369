import React from "react";
import { Sync, HorizontalRule } from "@mui/icons-material";
import {
  Link,
  List,
  ListItem,
  ListItemText,
  Theme,
  Tooltip,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import ActionIconButton from "../../../components/ActionIconButton";
import { ConnectionIdType, ConnectionType } from ".";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editButton: {
      opacity: 0,
      transition: "all 0.1s ease-in-out",
    },
    editableContainer: {
      "&:hover": {
        "& $editButton": {
          opacity: 1,
        },
      },
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grey[100],
    },
  })
);

const Connections = ({
  connections,
  onUnlink,
  onResync,
}: {
  connections: Array<ConnectionType>;
  onUnlink: (connectionId: ConnectionIdType) => void;
  onResync: (connectionId: ConnectionIdType) => void;
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.editableContainer}
    >
      <List
        style={{
          padding: 0,
          width: "100%",
          overflow: "hidden",
        }}
      >
        {connections.map((connection: any) => (
          <ListItem
            key={`${connection.id}-connection`}
            style={{ paddingLeft: 0 }}
            secondaryAction={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Tooltip title={`Unlink from ${connection.id}`}>
                  <span>
                    <ActionIconButton
                      onClick={() => onUnlink(connection.id)}
                      icon={HorizontalRule}
                      aria-label="unlink"
                      disabled={!connection.canUnlink}
                    />
                  </span>
                </Tooltip>
                <Tooltip title={`Sync from ${connection.id}`}>
                  <span>
                    <ActionIconButton
                      onClick={() => onResync(connection.id)}
                      icon={Sync}
                      aria-label="sync"
                      disabled={!connection.canResync}
                    />
                  </span>
                </Tooltip>
              </Box>
            }
          >
            <ListItemText>
              <Link
                target="_blank"
                href={connection.href}
                key={`view-in-${connection.id}`}
              >
                <span>View in {connection.id}</span>
              </Link>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Connections;
