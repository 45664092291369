import { Role } from "../models";

export const ROLES = {
  SET: "ROLES/set",
  ADD: "ROLES/add",
  REMOVE: "ROLES/remove",
  UPDATE: "ROLES/update",
};

const initialState: Role[] = [];

export default function rolesReducer(state = initialState, action: any) {
  switch (action.type) {
    case ROLES.SET:
      return [...action.roles];
    case ROLES.ADD:
      return [...state, action.role];
    case ROLES.REMOVE:
      return state.filter((role: Role) => role.id !== action.role.id);
    case ROLES.UPDATE:
      return state.map((role) => {
        if (role.id === action.role.id) {
          return { ...role, ...action.role };
        } else {
          return role;
        }
      });
    default:
      return state;
  }
}
