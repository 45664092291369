import React, { useCallback, useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams, useLocation } from "react-router-dom";
import {
  Backdrop,
  Box,
  CircularProgress,
  Theme,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  FRAGMENT_DEAL_FIELDS,
  FRAGMENT_TASK_FIELDS,
  GET_FIELDS_FULL,
} from "../../api/graphql";
import TimelineList from "../../components/deals/TimelineList";
import TaskList from "../../components/tasks/List";
import NoteList from "../../components/notes/List";
import DealDocuments from "../../components/deals/Documents";
import DetailCard from "../../components/DetailCard";
import DetailPane from "../../components/DetailPane";
import QueryString from "qs";
import "./Report.scss";
import PropertyPhoto from "../../components/deals/PropertyPhoto";
import SubjectData from "../../components/subject-pane/SubjectData";
import moment from "moment";
import { PrimaryButton } from "../../components/buttons";
import { getStreetString, getCityStateString } from "../../helpers/address";

const GET_DEAL = gql`
  query GetDeal($id: ID!) {
    deal(id: $id) {
      ...DealFields
      docusignRoomId
      contacts {
        id
        firstName
        lastName
        name
        email
        phone
        avatarUrl
        userStatus
      }
      dealTeamMembers {
        teamMember {
          id
          name
          avatarUrl
        }
        role {
          id
          name
        }
      }
      notes {
        id
        body
        createdAt
        source
        author {
          id
          name
          avatarUrl
        }
      }
      tasks {
        ...TaskFields
      }
      config {
        clientTimelineView
      }
      customFields {
        id
        customFieldId
        type
        label
        valueText
        settings {
          format
          includeTime
          options
        }
      }
      dotloopLoopUrl
    }
  }
  ${FRAGMENT_DEAL_FIELDS}
  ${FRAGMENT_TASK_FIELDS}
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "1200px",
      margin: "auto",
      "& *": { overflow: "hidden !important" },
      "& .MuiAccordionSummary-root *": { overflow: "visible !important" },
    },
    print: {
      width: "100%",
      textAlign: "right",
      marginBottom: "32px",
      "@media print": {
        display: "none",
      },
    },
    loadingBackdrop: { backgroundColor: theme.palette.background.default },
    timestamp: { marginLeft: "16px" },
    poweredBy: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "-6px 16px 0 0",
    },
    logo: { height: "16px" },
    address: { margin: "auto 24px" },
    dealBasics: {
      padding: "24px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    dealDetails: { margin: "24px", display: "flex", flexWrap: "wrap" },
    detailItem: {
      flex: "1 1 220px",
      maxWidth: "220px",
      minHeight: "36px",
      overflowWrap: "anywhere",
    },
  })
);

const DealReport = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const search = QueryString.parse(location.search.substr(1));

  const [deal, setDeal] = useState<any>();
  const [systemFields, setSystemFields] = useState<Array<any> | null>(null);
  const [customFields, setCustomFields] = useState<Array<any> | null>(null);

  const classes = useStyles();

  const {
    timeline = false,
    notes = false,
    docs = false,
    tasks = false,
  } = search;

  useQuery(GET_DEAL, {
    variables: {
      id,
    },
    onCompleted: (res) => setDeal(res.deal),
  });

  // Fetch system + custom fields that belong to a deal.
  const { data: getFieldsResponse } = useQuery(GET_FIELDS_FULL, {
    variables: { tableName: "deal" },
  });

  useEffect(() => {
    if (!deal || !getFieldsResponse) {
      return;
    }
    setSystemFields(getFieldsResponse.getSystemFields);
    setCustomFields(getFieldsResponse.getCustomFields);
  }, [deal, getFieldsResponse]);

  const isSystemField = (field: any): boolean => {
    return field.__typename === "SystemField";
  };

  const getFieldValue = useCallback(
    (field: any): any => {
      if (isSystemField(field)) {
        return deal[field.id];
      } else {
        const cfv = deal.customFields.find(
          (cfv: any) => cfv.customFieldId === field.id
        );

        return cfv?.valueText;
      }
    },
    [deal]
  );

  const formatAddress = (value: any) => {
    return (
      <>
        {value.street}
        <br />
        {`${value.city}, ${value.state} ${value.zip}`}
      </>
    );
  };

  const formatNumber = (value: number, format: string) => {
    switch (format) {
      case "usd":
        const currencyFormatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return currencyFormatter.format(value);
      case "percentage":
        return `${value}%`;
      default:
        return new Intl.NumberFormat("en-US").format(value);
    }
  };

  const formatDate = (value: string, includeTime: boolean) => {
    if (!value) {
      return null;
    } else if (includeTime) {
      return moment(value).format("lll");
    } else {
      return moment(value, "YYYY-MM-DD").format("ll");
    }
  };

  const formatField = useCallback(
    (field: any) => {
      const fieldValue = getFieldValue(field);

      if (field.type === "address") {
        return formatAddress(fieldValue);
      } else if (field.type === "number") {
        return formatNumber(fieldValue, field.settings.format);
      } else if (field.type === "datetime") {
        return formatDate(fieldValue, field.settings.includeTime);
      } else {
        return fieldValue;
      }
    },
    [getFieldValue]
  );

  const renderField = useCallback(
    (field: any) => {
      return (
        <SubjectData
          key={field.id}
          label={field.label}
          value={formatField(field)}
          className={classes.detailItem}
        />
      );
    },
    [formatField, classes]
  );

  const renderDealDetails = useCallback(() => {
    return (
      <>
        {systemFields?.map(renderField)}
        {customFields?.map(renderField)}
      </>
    );
  }, [systemFields, customFields, renderField]);

  const renderAddress = useCallback(() => {
    const streetAddress = getStreetString(deal.address);
    return streetAddress.length ? (
      <Box className={classes.address}>
        <Typography variant="h5">{streetAddress}</Typography>
        <Typography variant="subtitle2">
          {getCityStateString(deal.address)}
        </Typography>
      </Box>
    ) : (
      <p className={"classes.noAddress"}>No address to display</p>
    );
  }, [deal, classes]);

  const PoweredBy = () => {
    return (
      <div className={classes.poweredBy}>
        <Typography variant="h6">Powered by</Typography>
        <img
          src="/logo-full.svg"
          alt="Shaker logo"
          title="Shaker"
          className={classes.logo}
        />
      </div>
    );
  };

  if (deal) {
    return (
      <div>
        <DetailPane className={classes.root}>
          <Box className={classes.print}>
            <Typography variant="subtitle1">
              Choose "Print to PDF" or "Save as PDF" to download this report
            </Typography>
            <PrimaryButton onClick={() => window.print()}>
              Print this report
            </PrimaryButton>
          </Box>
          <DetailCard
            title={
              <>
                <Typography variant="h5">Deal Report</Typography>
                <Typography variant="subtitle1" className={classes.timestamp}>
                  {moment().format("ddd MMMM Do YYYY, h:mm a")}
                </Typography>
              </>
            }
            action={<PoweredBy />}
          >
            <Box className={classes.dealBasics}>
              <PropertyPhoto deal={deal} />
              {renderAddress()}
            </Box>
          </DetailCard>
          <DetailCard title={<Typography variant="h5">Details</Typography>}>
            <Box className={classes.dealDetails}>{renderDealDetails()}</Box>
          </DetailCard>

          {timeline === "true" && (
            <DetailCard title={<Typography variant="h5">Timeline</Typography>}>
              <TimelineList dealId={id as string} />
            </DetailCard>
          )}
          {notes === "true" && (
            <DetailCard title={<Typography variant="h5">Notes</Typography>}>
              <NoteList notes={deal.notes} reportView={true} />
            </DetailCard>
          )}
          {tasks === "true" && (
            <DetailCard title={<Typography variant="h5">Tasks</Typography>}>
              <TaskList
                reportView
                editable={false}
                tasks={deal.tasks}
                deal={deal}
              />
            </DetailCard>
          )}
          {docs === "true" && <DealDocuments deal={deal} reportView />}
        </DetailPane>
      </div>
    );
  } else {
    return (
      <div>
        <DetailPane>
          <Backdrop open={true} className={classes.loadingBackdrop}>
            <CircularProgress />
          </Backdrop>
        </DetailPane>
      </div>
    );
  }
};

export default DealReport;
