import React, { useState, FunctionComponent, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { gql, useQuery } from "@apollo/client";

type SourceFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const SourceField: FunctionComponent<SourceFieldProps> = ({
  value: _value,
  onChange,
}) => {
  const { data } = useQuery(
    gql`
      {
        getSourceSuggestions
      }
    `
  );
  const [value, setValue] = useState<string>(_value || "");
  useEffect(() => {
    setValue(_value ?? "");
  }, [_value]);
  return (
    <Autocomplete
      fullWidth
      freeSolo
      disableClearable
      id="dealSource"
      options={data?.getSourceSuggestions ?? []}
      value={value}
      onChange={(e, newValue) => {
        setValue(newValue ?? "");
        if (onChange) {
          onChange(newValue ?? "");
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Source"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export default SourceField;
