import React from "react";
import {
  Box,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import "./DetailCard.scss";

export const DetailAccordionSummary = withStyles((theme) => ({
  root: {
    flexDirection: "row-reverse",
    padding: "0px 8px",
    height: "56px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    "&:hover": {
      cursor: "default !important",
    },
  },
  content: {
    alignItems: "center",
  },
}))(AccordionSummary);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: { height: "100%" },
    actionSpacer: { marginRight: "16px" },
    divider: {
      zIndex: 11, // MaterialTable uses 10 for the header. This needs to be on top of that.
    },
    details: { padding: "0", height: "100%" },
  })
);

export default function DetailCard({
  children,
  title,
  action,
  collapsible,
  onChange,
  secondarySection,
  style = {},
  className = "",
  divider = true,
}: {
  children?: any;
  title?: any;
  action?: any;
  secondarySection?: any;
  collapsible?: boolean;
  onChange?: Function;
  style?: React.CSSProperties;
  className?: string;
  divider?: boolean;
}) {
  const classes = useStyles();

  function renderAction() {
    if (action) {
      return (
        <>
          <div style={{ flexGrow: 1 }}></div>
          <div onClick={(e) => e.stopPropagation()}>{action}</div>
        </>
      );
    } else {
      return <></>;
    }
  }

  function renderSecondary() {
    if (secondarySection) {
      return (
        <div
          onClick={(e) => e.stopPropagation()}
          style={{ margin: "20px 10px" }}
        >
          {secondarySection}
        </div>
      );
    } else {
      return;
    }
  }
  const isFavoritesCard = className === "favorites-card";
  return (
    <Box
      marginBottom={isFavoritesCard ? 0 : 4}
      style={style}
      className={className}
    >
      <Accordion
        classes={{
          root: "DetailAccordion",
          expanded: "DetailAccordion-expanded",
        }}
        sx={{
          borderTop: isFavoritesCard ? "1px solid #e0e0e0" : undefined,
          boxShadow: isFavoritesCard ? "none !important" : undefined,
        }}
        onChange={
          onChange ? (e, expanded: Boolean) => onChange(expanded) : undefined
        }
        expanded={collapsible ? undefined : true}
        defaultExpanded={true}
        className={classes.accordion}
      >
        {title && (
          <DetailAccordionSummary
            classes={{ root: "DetailAccordionSummary" }}
            expandIcon={collapsible ? <ExpandMoreIcon /> : undefined}
          >
            {collapsible ? <></> : <div className={classes.actionSpacer}></div>}
            {title}
            {renderAction()}
          </DetailAccordionSummary>
        )}
        {renderSecondary()}

        {divider && <Divider className={classes.divider} />}
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
