import React, { FunctionComponent } from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  Link,
  DialogContent,
  Button,
  Stack,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useUser } from "../context/user";
import { useMutation } from "@apollo/client";
import { ENSURE_STRIPE_CHECKOUT_URL } from "../api/graphql";

type AccountInactiveDialogProps = {
  account?: any;
};

const AccountInactiveDialog: FunctionComponent<AccountInactiveDialogProps> = ({
  account,
}): any => {
  const user = useUser();
  const [ensureStripeCheckoutUrl] = useMutation(ENSURE_STRIPE_CHECKOUT_URL);

  const hasBillingPermission = user.hasPermission("billing", "full");
  let content: JSX.Element;

  const handleCheckoutClick = async () => {
    const res = await ensureStripeCheckoutUrl();
    const url = res.data.ensureStripeCheckoutUrl;
    window.location.href = url;
  };

  if (account.needsBillingInfo) {
    content = (
      <Stack direction="column" spacing={2}>
        <Typography variant="h3">Activate your trial</Typography>
        <Typography>
          We'll need your billing information to activate your trial.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCheckoutClick}
        >
          Enter billing information
        </Button>
      </Stack>
    );
  } else if (account.trialEnded) {
    content = (
      <>
        <Typography variant="h3">Your trial has ended!</Typography>
        <br />
        <Typography>
          You'll need to{" "}
          <Link href="https://shaker.io/pricing">upgrade your account</Link> to
          continue using Shaker.
        </Typography>
      </>
    );
  } else {
    content = (
      <>
        <Typography variant="h3" marginBottom={4}>
          Your account has been deactivated.
        </Typography>
        {hasBillingPermission ? (
          <Typography textAlign="center">
            Please go to the{" "}
            <Link to="/settings/account/billing" component={RouterLink}>
              billing settings
            </Link>{" "}
            to see open invoices and update your billing information.
          </Typography>
        ) : (
          <Typography textAlign="center">
            Please contact your Shaker admin to reactiviate your account.
          </Typography>
        )}
      </>
    );
  }

  return (
    <Dialog
      open={true}
      BackdropProps={{
        style: { backgroundColor: "#1A0B31", opacity: 0.9 },
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        <Grid container spacing={4}>
          <Grid
            item
            sm={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#FEFBF8",
            }}
          >
            <Box p={4}>
              <img
                alt=""
                width="100%"
                src="https://assets.website-files.com/601b3cd8dd73d2c641cb5170/6023ee07077a087f268f9b72_img-cta-juggling%402x.png"
              />
            </Box>
          </Grid>
          <Grid
            item
            sm={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {content}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AccountInactiveDialog;
