import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Add as AddIcon } from "@mui/icons-material";
import CreateDialog from "../../components/stages/CreateDialog";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { ReactSortable, Sortable } from "react-sortablejs";
import SplitLayout from "../../layouts/SplitLayout";
import LeftPane from "../../components/LeftPane";
import SettingsSubNav from "../../components/SettingsSubNav";
import SettingsSelect from "../../components/SettingsSelectMobile";
import DetailPane from "../../components/DetailPane";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PageTitle from "../../components/PageTitle";
import { PrimaryButton } from "../../components/buttons";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import { DealType, Stage } from "../../models";
import { updateStage, UpdateStageInput } from "../../actions/stages";
import TitleSelect from "../../components/TitleSelect";
import { ApiClient, useApi } from "../../context/api";
import { useUser } from "../../context/user";
import SupportLink from "../../components/SupportLink";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageLayout: {
      flexGrow: 1,
      overflowX: "hidden",
      display: "flex",
      flexDirection: "row",
      marginBottom: "0",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
    },
    stageListItem: {
      background: "#fff",
      border: "1px solid #e5e7eb",
      marginBottom: "16px",
    },
    stageName: {
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "14px",
      fontWeight: 500,
    },
    dragHandle: {
      color: "#6b7280",
      marginRight: "11px",
      cursor: "grab",
    },
    hideText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

type StagesListProps = {
  dealTypes: DealType[];
  updateStage: (
    stage: UpdateStageInput,
    apolloClient: ApiClient
  ) => Promise<any>;
};

const StagesList = ({
  dealTypes,
  updateStage,
}: StagesListProps): JSX.Element => {
  const SELECTED_DEAL_TYPE_KEY = "stagesSettingsPageSelectedDealTypeId";

  const { apolloClient } = useApi();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermission } = useUser();
  const [dealType, setDealType] = useState<DealType>();
  const [stages, setStages] = useState<Stage[]>([]);

  const canEditStages = () => hasPermission("settings", "full");

  useEffect(() => {
    let selectedDealType: DealType | undefined;

    if (location.hash.length > 1) {
      selectedDealType = dealTypes.find(
        (type) => type.id === location.hash.substr(1)
      );
    }

    if (!selectedDealType) {
      const value = window.localStorage.getItem(SELECTED_DEAL_TYPE_KEY);
      selectedDealType = dealTypes.find((type) => type.id === value);
    }

    if (!selectedDealType) {
      selectedDealType = dealTypes[0];
    }

    window.localStorage.setItem(SELECTED_DEAL_TYPE_KEY, selectedDealType.id);
    setDealType(selectedDealType);
    setStages(selectedDealType.stages);
  }, [location.hash, dealTypes, setDealType]);

  const handleDealTypeChange = (dealTypeId: string) => {
    navigate("#" + dealTypeId);
  };

  const handleStageClick = (e: any, stage: any) => {
    navigate(`/settings/stages/${stage.id}`);
  };

  const handleSortUpdate = (event: Sortable.SortableEvent) => {
    const { oldIndex, newIndex } = event;
    const copy = [...stagesList];
    const stageToMove = copy.splice(oldIndex as number, 1)[0];
    copy.splice(newIndex as number, 0, stageToMove);

    const promises: Promise<any>[] = [];
    let transitionCount = 0;
    let dealState: string;

    copy.forEach((stage: any, i: number) => {
      const stageIndex = i - transitionCount + 1;

      if (stage.type === "stageTransition") {
        dealState = stage.dealState;
        transitionCount++;
      } else if (stage.sort !== stageIndex || stage.dealState !== dealState) {
        promises.push(
          updateStage(
            {
              id: stage.id,
              sort: stageIndex,
              dealState,
              name: stage.name,
            },
            apolloClient
          )
        );
      }
    });
    Promise.all(promises)
      .then(() => {})
      .catch(() => {});
  };

  const [stagesList, setStagesList] = useState<any[]>([]);

  useEffect(() => {
    const firstClosedIndex = stages.findIndex(
      (stage: any) => stage.dealState === "closed"
    );

    let renderStages: any[];
    const prospectTransition = {
      id: "prospect",
      type: "stageTransition",
      dealState: "prospect",
      title: null,
    };
    const closedTransition = {
      id: "closed",
      type: "stageTransition",
      dealState: "closed",
      title: 'Deals past this point are marked "closed"',
    };
    if (firstClosedIndex >= 0) {
      renderStages = [
        prospectTransition,
        ...stages.slice(0, firstClosedIndex),
        closedTransition,
        ...stages.slice(firstClosedIndex),
      ];
    } else if (stages.length > 0) {
      renderStages = [prospectTransition, ...stages, closedTransition];
    } else {
      renderStages = [];
    }

    setStagesList(renderStages);
  }, [stages]);

  const renderStagesList = () => {
    const renderStageTransition = (stageTransition: any) => {
      if (stageTransition.title) {
        return (
          <li key={stageTransition.id} className="undraggable">
            <h5 className="text-divider">{stageTransition.title}</h5>
          </li>
        );
      } else {
        return <li key={stageTransition.id} style={{ display: "none" }}></li>;
      }
    };

    const renderStage = (stage: any) => {
      return (
        <ListItem
          className={classes.stageListItem}
          key={stage.id}
          button
          onClick={(e) => handleStageClick(e, stage)}
        >
          <ListItemText
            primary={
              <Box style={{ display: "flex", alignItems: "center" }}>
                {canEditStages() && (
                  <DragIndicatorIcon
                    className={clsx("handle", classes.dragHandle)}
                  />
                )}
                <Typography className={classes.stageName}>
                  {stage.name}
                </Typography>
              </Box>
            }
          ></ListItemText>
          <div style={{ flexGrow: 1 }}></div>
          <ChevronRightIcon />
        </ListItem>
      );
    };

    if (canEditStages()) {
      return (
        <ReactSortable
          list={stagesList}
          setList={setStagesList}
          handle=".handle"
          onUpdate={handleSortUpdate}
          filter=".undraggable"
        >
          {stagesList.map((stage: any) =>
            stage.type === "stageTransition"
              ? renderStageTransition(stage)
              : renderStage(stage)
          )}
        </ReactSortable>
      );
    } else {
      return (
        <>
          {stagesList.map((stage: any) =>
            stage.type === "stageTransition"
              ? renderStageTransition(stage)
              : renderStage(stage)
          )}
        </>
      );
    }
  };

  return (
    <SplitLayout>
      <LeftPane width={224}>
        <SettingsSubNav />
        <SettingsSelect label="" defaultValue="/settings/stages" />
      </LeftPane>
      <DetailPane>
        <Grid container className={classes.pageLayout} spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <PageTitle
              title={"Deal Stages"}
              subtitle={
                <>
                  <Typography>
                    Customize the columns shown on your deal boards, setup task
                    lists and message triggers.
                  </Typography>
                  <SupportLink url="https://intercom.help/shakerio/en/articles/7436484-setting-up-deal-stages-and-checklists-for-transaction-templates">
                    Guide to setting up deal stages
                  </SupportLink>
                </>
              }
            />
          </Grid>
          <Grid item xs={6}>
            {dealType && (
              <TitleSelect
                value={dealType.id}
                options={dealTypes.map((dealType) => {
                  return { label: dealType.name, value: dealType.id };
                })}
                onChange={handleDealTypeChange}
              />
            )}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            {dealType && (
              <CreateDialog dealTypeId={dealType.id}>
                <PrimaryButton height="short" disabled={!canEditStages()}>
                  <AddIcon
                    style={{
                      width: "13px",
                      height: "13px",
                      marginRight: "4px",
                    }}
                  />
                  <span className={classes.hideText}>New Stage</span>
                </PrimaryButton>
              </CreateDialog>
            )}
          </Grid>
          <Grid item xs={12}>
            <List> {renderStagesList()}</List>
          </Grid>
          {stages.length === 0 && (
            <Grid item xs={12}>
              <Paper>
                <Box p={4}>
                  <Typography component="span">
                    Stages allow you to define and automate common tasks along
                    the {dealType?.clientNoun.toLowerCase()} journey:
                    <ul>
                      <li>
                        Send timely, relevant emails based on a deal's state
                      </li>
                      <li>
                        Assign tasks to your teammates and{" "}
                        {dealType?.clientNoun.toLowerCase()}
                      </li>
                      <li>Capture data at each of the deal's milestones</li>
                    </ul>
                  </Typography>
                  {dealType && (
                    <CreateDialog dealTypeId={dealType.id}>
                      <Button variant="contained" color="primary">
                        Add a Stage
                      </Button>
                    </CreateDialog>
                  )}
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
      </DetailPane>
    </SplitLayout>
  );
};

const mapStateToProps = ({ dealTypes }: { dealTypes: DealType[] }) => {
  return { dealTypes };
};

export default connect(mapStateToProps, { updateStage })(StagesList);
