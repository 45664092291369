import React, { FunctionComponent } from "react";
import { Box, Typography, IconButton, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ChevronLeftIcon } from "../icons";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitleWrapper: {
      marginBottom: "16px",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        display: (showOnMobile) => (showOnMobile ? "flex" : "none"),
      },
    },
    left: {
      display: "flex",
      alignItems: "baseline",
      flexDirection: "column",
    },
    right: {
      display: "flex",
      alignItems: "flex-end",
    },
    title: {
      fontSize: "22px",
      margin: 0,
      display: "flex",
      alignItems: "center",
    },
    subtitle: {
      margin: 0,
    },
    backButton: {
      display: "flex",
    },
  })
);

type PageTitleProps = {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  backText?: string;
  backUrl?: string;
  actions?: JSX.Element;
  showOnMobile?: boolean;
};

const PageTitle: FunctionComponent<PageTitleProps> = ({
  title,
  subtitle,
  backUrl,
  backText,
  actions,
  showOnMobile = false,
}) => {
  const classes = useStyles(showOnMobile);
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (backUrl) {
      navigate(backUrl);
    }
  };

  return (
    <>
      {backUrl && backText && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            edge="start"
            className={classes.backButton}
            onClick={handleBackClick}
            size="large"
          >
            <ChevronLeftIcon />
          </IconButton>
          {backText}
        </div>
      )}
      <Box className={classes.pageTitleWrapper}>
        <div className={classes.left}>
          <h4 className={classes.title}>
            {backUrl && !backText && (
              <IconButton
                edge="start"
                className={classes.backButton}
                onClick={handleBackClick}
                size="large"
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
            {title}
          </h4>
          {typeof subtitle === "string" ? (
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          ) : (
            <>{subtitle}</>
          )}
        </div>
        <div className={classes.right}>{actions}</div>
      </Box>
    </>
  );
};

export default PageTitle;
