import React, { FunctionComponent, useState } from "react";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import { EmailIcon } from "../../icons";
import moment from "moment";
import { Reply } from "@mui/icons-material";
import ComposeEmailDialog from "./ComposeEmailDialog";
import { useUser } from "../../context/user";
import { HoverActionContainer, HoverActions } from "../HoverActions";
import ActionIconButton from "../ActionIconButton";
import EmailThreadView from "./ThreadView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emailIcon: {
      background: "#FF576D",
      color: "#fff",
      padding: "4px 4.5px",
      borderRadius: "50%",
      height: "22px",
      width: "22px",
      marginRight: "14px",
    },
    bold: {
      color: "#161e2e",
      fontWeight: 600,
    },
    threadDate: {
      fontSize: "12px",
      fontFamily: ["AvenirNext-Regular", "Avenir", "sans-serif"].join(","),
    },
    messageDate: { fontSize: "13px", margin: "24px 0 13px 0" },
    emailDetail: {
      flexDirection: "column",
      padding: "0 64px",
      [theme.breakpoints.down("lg")]: {
        padding: "0 30px",
      },
    },
    email: {
      display: "flex",
      flexDirection: "column",
    },
    attachment: { marginBottom: "16px", marginRight: "6px" },
  })
);

export const MessageSummary = withStyles({
  root: { padding: "30px 22px" },
  content: { alignItems: "center", margin: "0", fontSize: "13px" },
})(AccordionSummary);

export const MessageCountChip = withStyles({
  root: {
    height: "20px",
    borderRadius: "4px",
    fontSize: "10px",
    background: "rgb(244, 245, 247)",
    padding: "0 6px",
  },
  label: { padding: "0" },
})(Chip);

type EmailThreadPanelProps = {
  emailThread: any;
  contact?: any;
};
const EmailThreadPanel: FunctionComponent<EmailThreadPanelProps> = ({
  emailThread,
  contact,
}) => {
  const classes = useStyles();
  const { currentUser } = useUser();
  const [expanded, setExpanded] = useState<boolean>(false);

  const DirectionText = ({ email }: { email: any }): JSX.Element => {
    if (!email) {
      return <></>;
    }

    const toNames = email.recipients.map((recipient: any) => {
      if (recipient.to.id === currentUser.id) {
        return "you";
      } else {
        return recipient.to.name;
      }
    });

    const fromName =
      email.from.id === currentUser.teamMember.id ? "You" : email.from.name;

    return (
      <span>
        <span className={classes.bold}>{fromName}</span> to {toNames.join(", ")}
      </span>
    );
  };

  const formatDateTime = (date: Date): string => {
    let dateFormat: string;
    const m = moment(date);
    const now = moment();

    // Date format
    const diff = now.diff(m, "d");
    const isCurrentYear = m.get("y") === now.get("y");
    if (diff === 0) {
      dateFormat = "[Today]";
    } else if (diff === -1) {
      dateFormat = "[Yesterday]";
    } else if (isCurrentYear) {
      dateFormat = "MMM D";
    } else {
      dateFormat = "MMM D, YYYY";
    }
    return m.format(`${dateFormat} [at] h:mm A`);
  };

  const toggleExpanded = () => {
    setExpanded((expanded) => {
      window.location.hash = expanded ? "" : emailThread.id;
      return !expanded;
    });
  };

  return (
    <Accordion
      id={emailThread.id}
      square
      expanded={expanded}
      onChange={toggleExpanded}
      TransitionProps={{ unmountOnExit: true }}
    >
      <HoverActionContainer>
        <MessageSummary>
          <div style={{ display: "flex", width: "100%" }}>
            <EmailIcon className={classes.emailIcon} />
            <Grid container spacing={0}>
              <Grid item xs={12} style={{ marginBottom: "2px" }}>
                <DirectionText email={emailThread.lastEmail} />
                &nbsp;&nbsp;
                <MessageCountChip label={emailThread.totalCount} />
                <br />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "2px" }}>
                <Typography
                  component="span"
                  color="textSecondary"
                  className={classes.threadDate}
                >
                  {formatDateTime(emailThread.lastEmail.messageDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "2px" }}>
                <span className={classes.bold}>{emailThread.subject}</span>
              </Grid>
            </Grid>
          </div>
        </MessageSummary>
        {contact && (
          <HoverActions>
            <ComposeEmailDialog
              recipients={[contact]}
              emailThread={emailThread}
            >
              <ActionIconButton icon={Reply} />
            </ComposeEmailDialog>
          </HoverActions>
        )}
      </HoverActionContainer>
      <AccordionDetails className={classes.emailDetail}>
        <EmailThreadView emailThread={emailThread} contact={contact} />
      </AccordionDetails>
    </Accordion>
  );
};

export default EmailThreadPanel;
