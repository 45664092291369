import { useState, useEffect } from "react";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import {
  FRAGMENT_DEAL_FIELDS,
  FRAGMENT_DEAL_CUSTOM_FIELDS,
  FRAGMENT_DEAL_TEAM_MEMBERS,
} from "../../api/graphql";
const GET_SKYSLOPE_OFFICES = gql`
  query GetSkySlopeOffices {
    getSkySlopeOffices {
      id
      name
    }
  }
`;

const GET_SKYSLOPE_AGENTS = gql`
  query GetSkySlopeUsers($officeId: String!) {
    getSkySlopeUsers(officeId: $officeId) {
      id
      firstName
      lastName
    }
  }
`;

const GET_SKYSLOPE_PURCHASES = gql`
  query GetSkySlopePurchases($agentGuid: String!) {
    getSkySlopeSales(agentGuid: $agentGuid) {
      id
      address
    }
  }
`;

const GET_SKYSLOPE_LISTINGS = gql`
  query GetSkySlopeListings($agentGuid: String!) {
    getSkySlopeListings(agentGuid: $agentGuid) {
      id
      address
    }
  }
`;

interface SkySlopeOffice {
  id: string;
  name: string;
}

interface SkySlopeUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface SkySlopeChecklistType {
  id: number;
  name: string;
}
export interface SkySlopePurchaseType {
  id: number;
  address?: string;
  name: string;
  loopUrl?: string;
}

type TransactionType = "Purchase" | "Listing";

export const useCreateFromSkySlope = (open: boolean) => {
  const [offices, setOffices] = useState<SkySlopeOffice[]>();
  const [selectedOfficeId, setSelectedOfficeId] = useState<string>();

  const [agents, setAgents] = useState<SkySlopeUser[]>();
  const [selectedAgent, setSelectedAgent] = useState<SkySlopeUser>();

  const [selectedTransactionType, setSelectedTransactionType] =
    useState<TransactionType>("Purchase");

  const [skySlopeTransactions, setSkySlopeTransactions] =
    useState<SkySlopePurchaseType[]>();

  const [selectedTransaction, setSelectedTransaction] =
    useState<SkySlopePurchaseType>();

  useState<SkySlopeChecklistType[]>();
  const [getSkySlopeOffices, { loading: skySlopeOfficeLoading }] =
    useLazyQuery(GET_SKYSLOPE_OFFICES);

  const [getSkySlopeUsers, { loading: selectedAgentLoading }] =
    useLazyQuery(GET_SKYSLOPE_AGENTS);

  const [getSkySlopeSales] = useLazyQuery(GET_SKYSLOPE_PURCHASES);

  const [getSkySlopeListings] = useLazyQuery(GET_SKYSLOPE_LISTINGS);

  const skySlopeSubmit =
    selectedAgent && selectedTransactionType && selectedTransaction;

  const skySlopeLoading = skySlopeOfficeLoading || selectedAgentLoading;
  useEffect(() => {
    if (selectedOfficeId) {
      getSkySlopeUsers({
        variables: { officeId: selectedOfficeId || "" },
      })
        .then((res) => {
          const agents = res.data.getSkySlopeUsers ?? [];
          setAgents(agents);
        })
        .catch(() => {});
    }
  }, [getSkySlopeUsers, selectedOfficeId]);

  const [importSkySlopeSale] = useMutation(gql`
    mutation ImportSkySlopeSale($input: ImportSkySlopeSaleInput!) {
      importSkySlopeSale(input: $input) {
        id
        dealType {
          id
        }
      }
    }
  `);

  const [importSkySlopeListing] = useMutation(gql`
    mutation ImportSkySlopeListing($input: ImportSkySlopeListingInput!) {
      importSkySlopeListing(input: $input) {
        ...DealFields
        ...DealCustomFields
        ...DealTeamMembers
        dealType {
          id
        }
        dealType {
          id
        }
      }
    }
    ${FRAGMENT_DEAL_FIELDS}
    ${FRAGMENT_DEAL_CUSTOM_FIELDS}
    ${FRAGMENT_DEAL_TEAM_MEMBERS}
  `);

  function importDeal() {
    let importPromise: Promise<any>;
    if (selectedTransactionType === "Purchase") {
      importPromise = importSkySlopeSale({
        variables: {
          input: {
            skyslopeSaleId: selectedTransaction?.id,
          },
        },
      }).then((res) => {
        return res.data.importSkySlopeSale;
      });
    } else if (selectedTransactionType === "Listing") {
      importPromise = importSkySlopeListing({
        variables: {
          input: {
            skyslopeListingId: selectedTransaction?.id,
          },
        },
      }).then((res) => {
        return res.data.importSkySlopeListing;
      });
    } else {
      throw new Error("Invalid transaction type");
    }
    return importPromise;
  }

  useEffect(() => {
    if (open) {
      getSkySlopeOffices()
        .then((res) => {
          const offices = res.data.getSkySlopeOffices ?? [];
          setOffices(offices);
          if (offices.length === 1) {
            setSelectedOfficeId(offices[0].id);
          }
        })
        .catch(() => {});
    }
  }, [open, setSelectedOfficeId, getSkySlopeOffices]);

  useEffect(() => {
    if (selectedAgent && selectedTransactionType === "Purchase") {
      getSkySlopeSales({
        variables: {
          agentGuid: selectedAgent.id,
        },
      })
        .then((res) => {
          const sales = res.data.getSkySlopeSales ?? [];
          setSkySlopeTransactions(sales);
        })
        .catch(() => {});
    } else if (selectedAgent && selectedTransactionType === "Listing") {
      getSkySlopeListings({
        variables: {
          agentGuid: selectedAgent.id,
        },
      })
        .then((res) => {
          const listings = res.data.getSkySlopeListings ?? [];
          setSkySlopeTransactions(listings);
        })
        .catch(() => {});
    }
  }, [
    selectedAgent,
    selectedTransactionType,
    setSkySlopeTransactions,
    getSkySlopeListings,
    getSkySlopeSales,
  ]);

  return {
    offices,
    skySlopeLoading,
    selectedOfficeId,
    setSelectedOfficeId,
    agents,
    selectedAgent,
    setSelectedAgent,
    selectedTransactionType,
    setSelectedTransactionType,
    skySlopeTransactions,
    selectedTransaction,
    setSelectedTransaction,
    skySlopeSubmit,
    importDeal,
  };
};
