import { Tag } from "../models";

export const VENDOR_TAGS = {
  SET: "VENDOR_TAGS/set",
};

const initialState: Tag[] = [];

export default function vendorTagsReducer(state = initialState, action: any) {
  switch (action.type) {
    case VENDOR_TAGS.SET:
      return [...action.vendorTags];
    default:
      return state;
  }
}
