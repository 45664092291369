import React from "react";
import { Theme, Link, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    supportLink: {
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
    },
    launchIcon: {
      height: "14px",
      width: "14px",
    },
  })
);

type SupportLinkProps = {
  children: any;
  url: string;
};

const SupportLink = ({ children, url }: SupportLinkProps) => {
  const classes = useStyles();

  return (
    <Link
      target={"_blank"}
      className={classes.supportLink}
      underline="hover"
      href={url}
    >
      <HelpIcon sx={{ fontSize: "14px" }} className={classes.launchIcon} />
      &nbsp;
      <Typography>{children}</Typography>
    </Link>
  );
};

export default SupportLink;
