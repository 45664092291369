import React, { ChangeEvent, FunctionComponent } from "react";
import { useQuery } from "@apollo/client";
import { Grid, InputAdornment, Select } from "@mui/material";
import { TextField, TextFieldProps } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { GET_FIELDS } from "../../api/graphql";
import moment from "moment";

type DelaySettingsProps = {
  step: any;
  setStep: (step: any) => any;
};

enum DelayStartType {
  CalendarDate = "calendarDate",
  ContactDateField = "contactDateField",
  LastActionDate = "lastActionDate",
}

const DelaySettings: FunctionComponent<DelaySettingsProps> = ({
  step,
  setStep,
}) => {
  const { data } = useQuery(GET_FIELDS, {
    variables: {
      tableName: "contact",
    },
  });

  const handleStartTypeChange = (e: any) => {
    setStep({ ...step, delayStartType: e?.currentTarget?.value });
  };

  const handleIntervalChange = (e: ChangeEvent<HTMLInputElement>) => {
    const delayInterval = Number.parseFloat(e.target.value);
    if (isNaN(delayInterval)) {
      return;
    }
    setStep({ ...step, delayInterval });
  };

  const handleDelayDateChange = (date: moment.MomentInput) => {
    setStep({
      ...step,
      delayStartAt: moment(date).startOf("d").format("YYYY-MM-DD"),
    });
  };

  const handleDateFieldChange = (e: any) => {
    const value = e.currentTarget.value;
    const customField = data?.getCustomFields.find(
      (customField: any) => customField.id === value
    );
    let delayStartAtColumnName: string | null = null;
    let delayStartAtCustomFieldId: string | null = null;
    if (customField) {
      delayStartAtCustomFieldId = value;
    } else {
      delayStartAtColumnName = value;
    }
    setStep({ ...step, delayStartAtCustomFieldId, delayStartAtColumnName });
  };

  const handleFrequencyChange = (e: any) => {
    setStep({ ...step, delayFrequency: e.currentTarget.value });
  };

  const handleDelayPrepositionChange = (e: any) => {
    setStep({ ...step, delayPreposition: e.currentTarget.value });
  };

  const contactDateFieldValue = () => {
    return step.delayStartAtColumnName || step.delayStartAtCustomFieldId || "";
  };

  if (!step) {
    return <></>;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          variant="standard"
          type="number"
          value={step.delayInterval}
          onChange={handleIntervalChange}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Select
          native
          fullWidth
          variant="standard"
          value={step.delayFrequency}
          onChange={handleFrequencyChange}
        >
          <option value="daily">
            {step.delayInterval === 1 ? "day" : "days"}
          </option>
          <option value="weekly">
            {step.delayInterval === 1 ? "week" : "weeks"}
          </option>
          <option value="monthly">
            {step.delayInterval === 1 ? "month" : "months"}
          </option>
          <option value="yearly">
            {step.delayInterval === 1 ? "year" : "years"}
          </option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Select
          native
          fullWidth
          variant="standard"
          value={step.delayPreposition}
          onChange={handleDelayPrepositionChange}
        >
          <option value="before">before</option>
          <option value="after">after</option>
        </Select>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Select
          native
          fullWidth
          variant="standard"
          onChange={handleStartTypeChange}
          value={step.delayStartType}
        >
          <option value={DelayStartType.CalendarDate}>a calendar date</option>
          <option value={DelayStartType.ContactDateField}>
            a contact's date field
          </option>
          <option value={DelayStartType.LastActionDate}>the last step</option>
        </Select>
      </Grid>
      {step.delayStartType === DelayStartType.CalendarDate && (
        <Grid item xs={12}>
          <DatePicker
            renderInput={(params: TextFieldProps) => (
              <TextField {...params} variant="standard" fullWidth />
            )}
            onChange={handleDelayDateChange}
            value={moment(step.delayStartAt) || null}
          />
        </Grid>
      )}
      {step.delayStartType === DelayStartType.ContactDateField && (
        <Grid item xs={12}>
          <Select
            native
            fullWidth
            variant="standard"
            onChange={handleDateFieldChange}
            value={contactDateFieldValue()}
          >
            <option></option>
            {data?.getSystemFields.map((field: any) => (
              <option value={field.id} key={field.id}>
                {field.label}
              </option>
            ))}
            {data?.getCustomFields
              .filter((field: any) => field.type === "datetime")
              .map((field: any) => (
                <option value={field.id} key={field.id}>
                  {field.label}
                </option>
              ))}
          </Select>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          id="time"
          type="time"
          variant="standard"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">@</InputAdornment>,
          }}
          value={step.delayStartAtTime}
          onChange={(e) => {
            setStep({ ...step, delayStartAtTime: e.target.value });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DelaySettings;
