import React, { useState, FunctionComponent, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { useAlert } from "../../context/alert";
import ActionIconButton from "../ActionIconButton";
import { Close as CloseIcon } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import DocuSignRoomAutocomplete, {
  DocuSignRoom,
} from "./DocuSignRoomAutocomplete";

type DocuSignRoomRole = {
  id: string;
  name: string;
  isDefault: boolean;
};

type DocuSignRoomTemplate = {
  id: string;
  name: string;
};

type DocuSignRoomsAccount = {
  id: string;
  name: string;
};

const transactionSides = [
  {
    id: "buy",
    name: "Buying",
  },
  {
    id: "sell",
    name: "Selling",
  },
  {
    id: "listbuy",
    name: "Both",
  },
  {
    id: "refi",
    name: "Refinance",
  },
];

const CREATE_DOCUSIGN_ROOM = gql`
  mutation CreateDocuSignRoom($input: CreateDocuSignRoomInput!) {
    createDocuSignRoom(input: $input) {
      url
    }
  }
`;

const LINK_DOCUSIGN_ROOM = gql`
  mutation LinkDocuSignRoom($input: LinkDocuSignRoomInput!) {
    linkDocuSignRoom(input: $input) {
      url
    }
  }
`;

const DocuSignCreateRoomDialog: FunctionComponent<{
  deal: any;
  open: boolean;
  onClose: () => any;
  onRoomCreated: (room: DocuSignRoom, mode: "create" | "link") => any;
}> = ({ deal, open, onClose, onRoomCreated }) => {
  const { showSuccess, showError } = useAlert();
  const [mode, setMode] = useState<"create" | "link">("create");

  const [accounts, setAccounts] = useState<Array<DocuSignRoomsAccount>>();
  const [roomTemplates, setRoomTemplates] =
    useState<Array<DocuSignRoomTemplate>>();
  const [roomRoles, setRoomRoles] = useState<Array<DocuSignRoomRole>>();
  const [accountId, setAccountId] = useState<string>();
  const [roomTemplateId, setRoomTemplateId] = useState<string>();
  const [roomRoleId, setRoomRoleId] = useState<string>();
  const [transactionSide, setTransactionSide] = useState<string>();
  const [syncRoom, setSyncRoom] = useState(true);
  const [room, setRoom] = useState<DocuSignRoom>();

  const [getDocuSignAccounts, { data, loading }] = useLazyQuery(gql`
    query GetDocuSignAccounts {
      getDocuSignAccounts {
        id
        name
        isDefault
      }
    }
  `);

  const [getDocuSignAccount, { data: accountData, loading: loadingAccount }] =
    useLazyQuery(gql`
      query GetDocuSignAccount($id: String!) {
        getDocuSignAccount(id: $id) {
          roomRoles {
            id
            name
            isDefault
          }
          roomTemplates {
            id
            name
          }
        }
      }
    `);

  const [createDocuSignRoom, { loading: creating }] =
    useMutation(CREATE_DOCUSIGN_ROOM);

  const [linkDocuSignRoom, { loading: linking }] =
    useMutation(LINK_DOCUSIGN_ROOM);

  const handleSubmit = async (e: any) => {
    let input: any = {
      dealId: deal.id,
      accountId,
    };

    e.preventDefault();
    if (!canSubmit()) {
      return;
    }
    let promise: Promise<any>;
    if (mode === "link") {
      input.roomId = room?.id;
      input.syncRoom = syncRoom;
      promise = linkDocuSignRoom({ variables: { input } }).then(
        (res) => res.data.linkDocuSignRoom
      );
    } else {
      input = {
        ...input,
        transactionSide,
        roomRoleId,
        roomTemplateId,
      };
      promise = createDocuSignRoom({
        variables: {
          input,
        },
      }).then((res) => res.data.createDocuSignRoom);
    }
    promise
      .then((room) => {
        onRoomCreated(room, mode);
        showSuccess(
          <>
            <Typography>
              A room was {mode === "create" ? "created" : "linked"}
            </Typography>
          </>
        );
      })
      .catch(() => {
        const verb = mode === "create" ? "creating a" : "linking the";
        showError(`An error occurred when ${verb} Room`);
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleClose = () => {
    onClose();
  };

  const canSubmit = () => {
    if (creating || linking) {
      return false;
    } else if (mode === "create") {
      return accountId !== "" && transactionSide !== "" && roomRoleId;
    } else if (mode === "link") {
      return !!room;
    }
    return false;
  };

  useEffect(() => {
    if (open) {
      // If the dialog is opened, fetch the DocuSign accounts
      getDocuSignAccounts();
    }
  }, [open, getDocuSignAccounts]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const accounts = data.getDocuSignAccounts ?? [];
    setAccounts(accounts);
    if (accounts.length === 1) {
      setAccountId(accounts[0].id);
    } else {
      setAccountId(accounts.find((account: any) => account.isDefault)?.id);
    }
  }, [data]);

  useEffect(() => {
    if (!accountId) {
      return;
    }
    getDocuSignAccount({ variables: { id: accountId } });
  }, [accountId, getDocuSignAccount]);

  useEffect(() => {
    if (!accountData) {
      return;
    }
    setRoomRoles(accountData.getDocuSignAccount.roomRoles ?? []);
    setRoomTemplates(accountData.getDocuSignAccount.roomTemplates ?? []);

    // Default the role
    const roomRole =
      accountData.roomRoles?.length === 1
        ? accountData.getDocuSignAccount.roomRoles[0]
        : accountData.getDocuSignAccount.roomRoles.find(
            (roomRole: any) => roomRole.isDefault
          );
    if (roomRole) {
      setRoomRoleId(roomRole.id);
    }
  }, [accountData]);

  useEffect(() => {
    if (deal?.dealType) {
      switch (deal?.dealType?.name?.toLowerCase()) {
        case "buying":
          setTransactionSide("buy");
          break;
        case "selling":
        case "listings":
          setTransactionSide("sell");
          break;
      }
    }
  }, [deal]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">
            {mode === "create" ? "Create" : "Link"} a DocuSign Room
          </Typography>
          <ActionIconButton
            icon={CloseIcon}
            style={{ margin: "4px -5px" }}
            buttonStyle={{ width: "22px", height: "22px" }}
            onClick={handleClose}
            aria-label="close"
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <Box textAlign="center" mb={2}>
            <CircularProgress size="24px" />
          </Box>
        )}
        {!loading && (
          <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="docusignAccount">Account</InputLabel>
                <Select
                  label="Account"
                  native
                  inputProps={{ id: "docusignAccount" }}
                  value={accountId}
                  onChange={(e) => {
                    setAccountId(e.target.value as string);
                  }}
                >
                  {accounts?.map((account) => (
                    <option key={account.id} value={account.id}>
                      {account.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {mode === "link" && accountId && (
              <>
                <Grid item xs={12}>
                  <DocuSignRoomAutocomplete
                    accountId={accountId as string}
                    onRoomSelected={(room) => setRoom(room)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={syncRoom}
                        onChange={(e, checked) => setSyncRoom(checked)}
                        name="syncRoom"
                        color="primary"
                      />
                    }
                    label="Sync room details into this deal"
                  />
                </Grid>
              </>
            )}
            {mode === "create" && (
              <>
                <Grid item xs={12}>
                  <FormControl variant="standard">
                    <InputLabel htmlFor="docusignTransactionSide">
                      Deal Type
                    </InputLabel>
                    <Select
                      label="Deal Type"
                      native
                      inputProps={{
                        id: "docusignTransactionSide",
                      }}
                      value={transactionSide}
                      onChange={(e) => {
                        setTransactionSide(e.target.value as string);
                      }}
                    >
                      <option></option>
                      {transactionSides.map((transactionSide) => (
                        <option
                          key={transactionSide.id}
                          value={transactionSide.id}
                        >
                          {transactionSide.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {roomRoles && roomRoles.length > 0 && (
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="docusignRoomRole">Role</InputLabel>
                      {loadingAccount ? (
                        <Skeleton variant="rectangular" />
                      ) : (
                        <Select
                          label="Role"
                          native
                          inputProps={{ id: "docusignRoomRole" }}
                          value={roomRoleId}
                          onChange={(e) => {
                            setRoomRoleId(e.target.value as string);
                          }}
                        >
                          <option></option>
                          {roomRoles?.map((roomRole) => (
                            <option key={roomRole.id} value={roomRole.id}>
                              {roomRole.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                )}
                {roomTemplates && roomTemplates.length > 0 && (
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="docusignRoomTemplate">
                        Template
                      </InputLabel>
                      {loadingAccount ? (
                        <Skeleton variant="rectangular" />
                      ) : (
                        <Select
                          native
                          label="Template"
                          inputProps={{ id: "docusignRoomTemplate" }}
                          value={roomTemplateId}
                          onChange={(e) => {
                            setRoomTemplateId(e.target.value as string);
                          }}
                        >
                          <option></option>
                          {roomTemplates?.map((roomTemplate) => (
                            <option
                              key={roomTemplate.id}
                              value={roomTemplate.id}
                            >
                              {roomTemplate.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                )}
              </>
            )}

            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                style={{ fontSize: 12, textAlign: "center" }}
              >
                {mode === "create" && (
                  <>
                    Already have a room created for this deal?{" "}
                    <Button
                      size="small"
                      color="primary"
                      style={{ textTransform: "none" }}
                      onClick={() => setMode("link")}
                    >
                      Link an existing room
                    </Button>
                  </>
                )}
                {mode === "link" && (
                  <>
                    Can't find a room for this deal?{" "}
                    <Button
                      size="small"
                      color="primary"
                      style={{ textTransform: "none" }}
                      onClick={() => setMode("create")}
                    >
                      Create a new room
                    </Button>
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          fullWidth
          onClick={handleClose}
          disabled={linking || creating}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={!canSubmit()}
          onClick={handleSubmit}
        >
          {mode === "create" ? "Create" : "Link"} a Room
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
export default DocuSignCreateRoomDialog;
