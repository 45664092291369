import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useAuth } from "../context/auth";
import ForgotPassword from "../pages/ForgotPassword";
import Login from "../pages/Login";
import OAuthAuthorize from "../pages/OAuthAuthorize";
import ResetPassword from "../pages/ResetPassword";
import Signup from "../pages/Signup";

function Clear() {
  const { clearAuthToken } = useAuth();
  clearAuthToken();
  return <Navigate to="/login" />;
}

const routes = (currentUser: any) => {
  let allRoutes = [
    <Route
      key="/oauth/authorize"
      path="/oauth/authorize"
      element={<OAuthAuthorize />}
    />,
    <Route
      key="/login"
      path={"/login"}
      element={currentUser ? <Navigate to="/" /> : <Login />}
    ></Route>,
    <Route key="/clear" path="/clear" element={<Clear />} />,
  ];

  if (!currentUser) {
    allRoutes = [
      ...allRoutes,
      <Route key="/oauth/login" path="/oauth/login" element={<Login />} />,
      <Route
        key="/forgot-password"
        path="/forgot-password"
        element={<ForgotPassword />}
      />,
      <Route
        key="/reset-password"
        path="/reset-password"
        element={<ResetPassword />}
      />,

      <Route key="/signup" path="/signup">
        <Route path=":inviteId" element={<Signup />} />
        <Route path="" element={<Signup />} />
      </Route>,

      <Route key="/portal/signup/" path="/portal/signup/">
        <Route path=":inviteId" element={<Signup />} />
        <Route path="" element={<Signup />} />
      </Route>,
    ];
  }

  return allRoutes;
};

export default routes;
