import React, { useEffect, useCallback, useState } from "react";
import { useUser } from "../context/user";
import ClientPortalLayout from "../layouts/ClientPortalLayout";
import { gql, useLazyQuery } from "@apollo/client";
import { Typography, Theme, Box, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ToggleButton, ToggleButtonGroup } from "../components/ToggleButtons";
import ClientDealCard from "../components/deals/ClientDealCard";

export const GET_DATA = gql`
  query myProperties {
    me {
      contacts {
        id
        name
        avatarUrl
        account {
          id
          name
          logoUrl
        }
        deals {
          id
          name
          closeDate
          closedAt
          dealType {
            name
            clientNoun
          }
          photoUrl
          listPrice
          listDate
          updatedAt
          address {
            street
            street2
            city
            state
            zip
          }
          stage {
            name
          }
          contacts {
            id
            name
            avatarUrl
          }
          dealTeamMembers {
            teamMember {
              id
              name
              avatarUrl
            }
            role {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 200px)",
    },
    root: {
      padding: "16px",
    },
    headerRow: {
      display: "flex",
      marginBottom: "24px",
    },
  })
);

const ClientDashboard = () => {
  const classes = useStyles();
  const { currentUser } = useUser();
  const [activeSelection, setActiveSelection] = useState<"active" | "inactive">(
    "active"
  );

  const [getData, { data, loading }] = useLazyQuery(GET_DATA);

  useEffect(() => {
    if (currentUser) {
      getData();
    }
  }, [currentUser, getData]);

  const deals = useCallback(() => {
    let allDeals: any[] = [];
    const contacts = data?.me?.contacts || [];
    contacts.forEach((contact: any) => {
      allDeals = [
        ...allDeals,
        ...contact.deals.filter((deal: any) =>
          activeSelection === "active" ? !deal.closedAt : !!deal.closedAt
        ),
      ];
    });

    return allDeals;
  }, [data, activeSelection]);

  const handleActiveSelectionChange = (
    _: any,
    value: "active" | "inactive"
  ) => {
    setActiveSelection(value);
  };

  return (
    <ClientPortalLayout>
      <div className={classes.root}>
        <div className={classes.headerRow}>
          <Typography variant="h3">My Properties</Typography>
          <div style={{ flexGrow: 1 }}></div>
          <ToggleButtonGroup
            exclusive
            value={activeSelection}
            onChange={handleActiveSelectionChange}
          >
            <ToggleButton value="active">Active</ToggleButton>
            <ToggleButton value="inactive">Inactive</ToggleButton>
          </ToggleButtonGroup>
        </div>

        {loading ? (
          <Box className={classes.loading}>
            <CircularProgress />
          </Box>
        ) : (
          deals().map((deal) => <ClientDealCard key={deal.id} deal={deal} />)
        )}
      </div>
    </ClientPortalLayout>
  );
};

export default ClientDashboard;
