import React, { useState, FunctionComponent, useEffect } from "react";
import { Select, FormControl, InputLabel } from "@mui/material";

type DropdownFieldProps = {
  label?: any;
  value?: string;
  settings?: any;
  onChange?: (value: string) => void;
};

const DropdownField: FunctionComponent<DropdownFieldProps> = ({
  label,
  settings,
  value: _value,
  onChange,
}) => {
  const [value, setValue] = useState<string>(_value || "");
  const handleChange = (e: any) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  useEffect(() => {
    setValue(_value ?? "");
  }, [_value]);

  return (
    <FormControl fullWidth variant="standard">
      <InputLabel htmlFor="select-field" variant="standard">
        {label}
      </InputLabel>
      <Select
        value={value}
        inputProps={{
          id: "select-field",
          name: "selectedField",
        }}
        fullWidth
        label="Field"
        native
        onChange={handleChange}
        variant="standard"
        disabled={!settings?.options?.length}
      >
        <option value=""></option>
        {settings?.options.map((option: string, index: number) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownField;
