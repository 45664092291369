import React from "react";
import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import AdminToolbar from "../../components/admin/AdminToolbar";
import UserActivityChart from "../../components/admin/UserActivityChart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: "1440px",
      margin: "0 auto",
      fontFamily: ["AvenirNext-Regular", "Avenir", "sans-serif"].join(","),
    },
    gridContainer: {
      padding: "100px 25px 0",
    },
    leftColumn: {
      [theme.breakpoints.down("lg")]: {
        marginBottom: "50px",
      },
    },
  })
);

const UserActivity = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AdminToolbar />
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} lg={4} className={classes.leftColumn}>
          <h1>User Activity</h1>
          <Link to={"/admin"}>Search by Organization</Link>
          <br />
          <Link to={"/admin/users"}>Search by Users</Link>
        </Grid>
        <Grid item xs={12} lg={8}>
          <UserActivityChart />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserActivity;
