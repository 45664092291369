import React, { ChangeEvent, FunctionComponent, useRef, useState } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Box,
  MenuItem,
  Typography,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { useMutation } from "@apollo/client";
import { ARCHIVE_DEAL } from "../../api/graphql";

import { useAlert } from "../../context/alert";
import { Deal } from "../../models";

type ArchiveDialogProps = {
  open: boolean;
  onClose: (e: React.MouseEvent) => void;
  onSubmit: (deal: any) => any;
  deal?: Deal;
};

export const ArchiveDialog: FunctionComponent<ArchiveDialogProps> = ({
  open,
  onClose,
  onSubmit,
  deal,
}) => {
  const { showError, showSuccess } = useAlert();
  const [dealState, setDealState] = useState(
    deal?.dealState === "prospect" ? "fallen_through" : deal?.dealState
  );
  const commentsRef = useRef<any>(null);

  const [updateDeal] = useMutation(ARCHIVE_DEAL);

  const isArchived = deal?.archived;

  const archiveAlertLabel = isArchived ? "Unarchived" : "Archived";
  const archiveLabel = isArchived ? "Unarchive" : "Archive";
  const isClosed = deal?.dealState === "closed";

  const handleDealState = () => {
    const checkArchived = isArchived && !isClosed ? "prospect" : dealState;
    return isClosed && isArchived && dealState !== "closed"
      ? "closed"
      : checkArchived;
  };

  const handleArchiveClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const input = {
      id: deal?.id,
      archived: !isArchived,
      dealState: handleDealState(),
      archivedReason: !isArchived ? commentsRef.current.value : null,
    };
    updateDeal({ variables: { input } })
      .then((res: any) => {
        onClose(event);
        showSuccess(`Deal "${deal?.name}" was ${archiveAlertLabel}`);
        if (onSubmit) {
          onSubmit({
            ...deal,
            archived: res.data.updateDeal.archived,
          });
        }
      })
      .catch(() => {
        showError(`An error occurred when ${archiveAlertLabel} the deal`);
      });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          minWidth: "400px",
        },
      }}
    >
      <form>
        <DialogTitle>{archiveLabel} Deal</DialogTitle>
        <DialogContent>
          {isArchived ? (
            <Typography>
              Are you sure you want to unarchive this deal?
            </Typography>
          ) : (
            <Box p={1}>
              <Grid container direction="column" rowGap={3}>
                <TextField
                  variant="standard"
                  select
                  fullWidth
                  label="Archive Reason"
                  id="dealState"
                  color="primary"
                  value={dealState}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setDealState(e.target.value)
                  }
                >
                  <MenuItem key={1} value="closed">
                    Closed
                  </MenuItem>
                  <MenuItem key={2} value="cancelled">
                    Cancelled
                  </MenuItem>
                  <MenuItem key={3} value="expired">
                    Expired
                  </MenuItem>
                  <MenuItem key={4} value="fallen_through">
                    Fallen Through
                  </MenuItem>
                  <MenuItem key={5} value="withdrawn">
                    Withdrawn
                  </MenuItem>
                </TextField>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="3"
                  color="primary"
                  inputRef={commentsRef}
                  placeholder="e.g. Inspection Issues"
                  type="text"
                  label="Comments"
                  id="comments"
                />
              </Grid>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <SecondaryButton
            autoFocus
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              onClose(e);
            }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            color="primary"
            onClick={handleArchiveClick}
            variant="contained"
          >
            {archiveLabel}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
