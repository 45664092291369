import React, { FunctionComponent, useRef } from "react";

type TemplateIFrameProps = {
  subject: string;
  html: string;
};
const TemplateIFrame: FunctionComponent<TemplateIFrameProps> = ({
  subject,
  html,
}) => {
  const iframe = useRef<any>(null);
  const handleLoad = (e: any) => {
    if (!iframe.current) {
      return;
    }
    const doc = iframe.current.contentWindow.document;
    const body = doc.body;

    // Set the height
    const html = doc.documentElement;
    // Adapted from https://www.dyn-web.com/tutorials/iframes/height/
    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    iframe.current.style.height = height + "px";
  };

  const doc = `<html>
  <head>
    <style>
    body {
      margin: 0;
      font-size: 14px;
      font-family: AvenirNext-Regular, Avenir, sans-serif;
    }
      p { margin: 0; }
      p.subject {
        font-weight: bold;
        margin-bottom: 1em;
      }
    </style>
  </head>
  <body>
    <p class="subject">${subject}</p>
    ${html}
  </body>
</html>`;
  return (
    <iframe
      title="Message Template"
      ref={iframe}
      srcDoc={doc}
      onLoad={handleLoad}
      style={{ width: "100%", border: "none" }}
    />
  );
};
export default TemplateIFrame;
