import React, { useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { gql, useMutation } from "@apollo/client";
import { ArrowCircleRight } from "@mui/icons-material";
import { Menu, MenuItem, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import ActionIconButton from "../../../components/ActionIconButton";
import StageChangeDialog from "../../../components/deals/StageChangeDialog";
import SubjectData from "../../../components/subject-pane/SubjectData";
import { Deal, DealType, Stage } from "../../../models";
import { useAlert } from "../../../context/alert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editButton: {
      opacity: 0,
      transition: "all 0.1s ease-in-out",
    },
    editableContainer: {
      "&:hover": {
        "& $editButton": {
          opacity: 1,
        },
      },
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grey[100],
    },
  })
);

const DealStage = ({
  deal,
  onUpdated,
  dealTypes,
}: {
  deal: Deal;
  onUpdated: () => void;
  dealTypes: DealType[];
}): JSX.Element => {
  const { showSuccess, showError } = useAlert();
  const classes = useStyles();
  const menuIconRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [stageChangeDialogOpen, setStageChangeDialogOpen] = useState(false);
  const [fromStage, setFromStage] = useState<Stage | null>(null);
  const [toStage, setToStage] = useState<Stage | null>(null);

  const [updateDeal] = useMutation(
    gql`
      mutation UpdateDeal($input: UpdateDealInput!) {
        updateDeal(input: $input) {
          id
        }
      }
    `
  );

  const stages = useMemo(
    () => dealTypes?.find((dt) => dt.id === deal.dealType?.id)?.stages,
    [deal, dealTypes]
  );

  const handleChangeStage = (toStage: Stage) => {
    setFromStage(stages?.find((stage) => stage.id === deal.stageId) ?? null);
    setToStage(toStage);
    setMenuOpen(false);

    if (toStage.fields.length || toStage.dealState === "closed") {
      setStageChangeDialogOpen(true);
    } else {
      return updateDeal({
        variables: {
          input: {
            id: deal.id,
            stageId: toStage.id,
          },
        },
      })
        .then(() => showSuccess(`Deal moved to ${toStage.name}`))
        .then(onUpdated)
        .then(() => setStageChangeDialogOpen(false))
        .catch(() => showError("An error occurred when updating the deal"));
    }
  };

  const cancelStageChange = () => {
    setFromStage(null);
    setToStage(null);
    setStageChangeDialogOpen(false);
  };

  const submitStageChange = (stageFormData: any) => {
    if (!toStage || !deal.id) {
      return Promise.resolve();
    }

    // Build a deal object that can be used as the mutation input
    const input = {
      id: deal.id,
      ...stageFormData,
      stageId: toStage.id,
    };

    return updateDeal({
      variables: {
        input,
      },
    })
      .then(() => showSuccess(`Deal moved to ${toStage.name}`))
      .then(onUpdated)
      .then(() => setStageChangeDialogOpen(false))
      .catch(() => showError("An error occurred when updating the deal"));
  };

  return (
    <SubjectData
      label="Stage"
      value={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.editableContainer}
        >
          {deal?.stage?.name ?? <Typography color={"gray"}>None</Typography>}
          <Box
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
            className={classes.editButton}
          >
            <ActionIconButton
              buttonStyle={{ padding: "0" }}
              onClick={() => setMenuOpen(true)}
              icon={ArrowCircleRight}
              ref={menuIconRef}
            />
            <Menu
              anchorEl={menuIconRef.current}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              {stages?.map((stage: any) => (
                <MenuItem
                  key={stage.id}
                  onClick={() => handleChangeStage(stage)}
                  className={
                    deal.stageId === stage.id ? classes.active : undefined
                  }
                  disabled={deal.stageId === stage.id}
                >
                  {stage.name}
                </MenuItem>
              ))}
            </Menu>

            <StageChangeDialog
              open={stageChangeDialogOpen}
              currentStage={fromStage}
              newStage={toStage}
              deal={deal}
              onClose={cancelStageChange}
              onSubmit={submitStageChange}
            />
          </Box>
        </Box>
      }
    />
  );
};

const mapStateToProps = ({ dealTypes }: { dealTypes: DealType[] }) => {
  return { dealTypes };
};

export default connect(mapStateToProps)(DealStage);
