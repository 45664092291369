import React from "react";
import { Route } from "react-router-dom";
import AdminOrgDashboard from "../pages/admin/OrgDashboard";
import AdminUserDashboard from "../pages/admin/UserDashboard";
import AccountDetail from "../pages/admin/AccountDetail";
import UserActivity from "../pages/admin/UserActivity";

const routes = (currentUser: any) => {
  if (currentUser?.internalAdmin) {
    return [
      <Route key="/admin" path="/admin" element={<AdminOrgDashboard />} />,
      <Route
        key="/admin/users"
        path="/admin/users"
        element={<AdminUserDashboard />}
      />,
      <Route
        key="/admin/user_activity"
        path="/admin/user_activity"
        element={<UserActivity />}
      />,
      <Route key="/admin/:id" path="/admin/:id" element={<AccountDetail />} />,
    ];
  } else {
    return [];
  }
};

export default routes;
