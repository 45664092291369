import React from "react";
import { Paper, Box, Typography, Theme, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Tag } from "../../models";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import PersonIcon from "@mui/icons-material/Person";
import StyledChip from "./StyledChip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "325px",
      minHeight: "320px",
    },
    topContainer: {
      marginBottom: theme.spacing(4),
    },
    vendorTagsContainer: {
      margin: `${theme.spacing(2)} 0`,
      "& > *": {
        margin: "5px 5px 5px 0",
      },
    },
    detailsContainer: {
      "& div": {
        display: "flex",
        alignItems: "center",
        margin: `${theme.spacing(1)} 0`,
        color: "#656160",
        "& svg": {
          marginRight: "10px",
          color: "#656160",
        },
      },
    },

    icon: {},
  })
);

interface VendorCardProps {
  vendor: any;
  accountIsSame?: boolean;
}

const VendorCard = ({ vendor, accountIsSame = true }: VendorCardProps) => {
  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.cardContainer}>
      <Box className={classes.topContainer}>
        <div className={classes.vendorTagsContainer}>
          {vendor.tags.length > 0 &&
            vendor.tags.map((tag: Tag) => (
              <StyledChip
                key={tag.name}
                label={tag.name}
                size={"small"}
                colorhex={tag.color || "#2061BE"}
              />
            ))}
        </div>
        <Typography variant={"h3"}>{vendor.company}</Typography>
      </Box>
      <Box className={classes.detailsContainer}>
        <div>
          <PersonIcon />
          <Typography>{vendor.name}</Typography>
        </div>
        <div>
          <EmailIcon />
          <Typography>{vendor.email}</Typography>
        </div>
        <div>
          <PhoneIcon />
          <Typography>{vendor.phone}</Typography>
        </div>
        <div>
          <PhonelinkIcon />
          <Typography>
            {vendor.websiteUrl ? (
              <Link target="_blank" href={`//${vendor.websiteUrl}`}>
                {vendor.websiteUrl.length > 60
                  ? `${vendor.websiteUrl.slice(0, 60)}...`
                  : vendor.websiteUrl}
              </Link>
            ) : (
              "-"
            )}
          </Typography>
        </div>
        {!accountIsSame && (
          <Typography align="right" variant="subtitle1">
            {vendor.account.name}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default VendorCard;
