import React from "react";
import { AccordionSummary } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

export const ActionAccordionSummary = withStyles({
  root: { flexDirection: "row-reverse", padding: "0px 8px" },
  content: { alignItems: "center", margin: "4px 0 4px 6px" },
})(AccordionSummary);

export function AccordionActions({ children }: { children: any }) {
  return (
    <>
      <div style={{ flexGrow: 1 }}></div>
      {children}
    </>
  );
}
