import React, {
  FunctionComponent,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from "react";
import {
  FormGroup,
  Grid,
  Paper,
  Skeleton,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
} from "@mui/material";

import CustomField from "../fields/CustomField";
import AddressField from "../fields/Address";
import FieldHelper from "../../helpers/fields";
import MlsInput from "../MlsInput";
import { NEEDED_FOR_STAGE_ACTIONS } from "../../api/graphql";
import { useLazyQuery } from "@apollo/client";
import { Box } from "@mui/system";
import TeamMemberSelect from "../TeamMemberSelect";
import SourceField from "../fields/Source";

type StageFormProps = {
  deal?: any;
  stage: any;
  onChange?: (value: any) => void;
  onUpdateContact?: (value: any) => void;
  addressSuggestion?: {
    street: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
  };
};

const INITIAL_STATE: {
  [key: string]: any;
  archived?: boolean;
  customFields: any[];
  dealTeamMembers: any[];
  updateContacts?: {
    updateAddress: boolean;
    address?: {
      street: string;
      street2: string;
      city: string;
      state: string;
      zip: string;
    };
  };
} = {
  customFields: [],
  dealTeamMembers: [],
  updateContacts: {
    updateAddress: false,
    address: {
      street: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
    },
  },
};

const StageForm: FunctionComponent<StageFormProps> = ({
  deal,
  stage,
  onChange,
  addressSuggestion,
}) => {
  const [state, setState] = useState({ ...INITIAL_STATE });
  const [fields, setFields] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const [isBuyingType, setIsBuyingType] = useState<boolean>(false);
  const [getNeededItemsForStageChange, { loading }] = useLazyQuery(
    NEEDED_FOR_STAGE_ACTIONS
  );

  useEffect(() => {
    const state = { ...INITIAL_STATE };
    state.customFields = [];
    // Fields
    for (const stageField of fields) {
      if (stageField.columnName) {
        /**
         * System field
         */
        state[stageField.columnName] = deal[stageField.columnName];
        if (state[stageField.columnName] === undefined) {
          state[stageField.columnName] = FieldHelper.getDefaultValue(
            stageField.field
          );
        }
        if (
          stageField.field.type === "datetime" &&
          stageField.columnName.endsWith("Date") &&
          (stageField.field.settings?.includeTime ||
            stageField.field.settings?.eventType)
        ) {
          const dateTimeKey = stageField.columnName.replace("Date", "At");
          state[dateTimeKey] = deal[dateTimeKey] ?? null;
        }
      } else if (stageField.customFieldId) {
        /**
         * Custom field
         */
        let customFieldValue: any;
        if (deal && deal.customFields) {
          customFieldValue = deal.customFields.find(
            (cfv: any) => cfv.customFieldId === stageField.customFieldId
          );
        }

        let cfv: any = {
          customFieldId: stageField.customFieldId,
        };
        if (customFieldValue) {
          // Get the value from the custom field's value
          if (customFieldValue.id) {
            cfv.id = customFieldValue.id;
          }
          cfv.value = FieldHelper.getCustomFieldValue(
            stageField.field,
            customFieldValue
          );
        }
        if (cfv.value === undefined) {
          cfv.value = FieldHelper.getDefaultValue(stageField.field);
        }
        state.customFields.push(cfv);
      }
    }
    const roleTeamMemberMap: { [roleId: string]: string } =
      deal?.dealTeamMembers?.reduce(
        (acc: any, dealTeamMember: any) => ({
          ...acc,
          [dealTeamMember.role.id]: dealTeamMember.teamMember.id,
        }),
        {}
      ) || {};
    state.dealTeamMembers = roles.map((role: any) => ({
      roleId: role.id,
      teamMemberId: roleTeamMemberMap[role.id] ?? null,
    }));
    setState(state);
  }, [deal, fields, roles]);

  const handleChange = (stageField: any, value: any) => {
    if (stageField.customFieldId) {
      const customFields: any[] = [...state.customFields];
      const index = customFields.findIndex(
        (customFieldValue: any) =>
          customFieldValue.customFieldId === stageField.customFieldId
      );

      let transformedValue = value;
      if (stageField.field.type === "datetime") {
        if (stageField.field.settings.includeTime) {
          transformedValue = value?.dateTime ?? value?.date ?? null;
        } else {
          transformedValue = value.date;
        }
      }
      if (index === -1) {
        customFields.push({
          customFieldId: stageField.customFieldId,
          value: transformedValue,
        });
      } else {
        customFields[index].value = transformedValue;
      }
      setState(Object.assign({}, state, { customFields }));
    } else if (stageField.field.type === "datetime") {
      const values: any = {
        [stageField.columnName]: value.date,
      };
      if (
        (stageField.field.type === "datetime" &&
          stageField.field.settings?.includeTime) ||
        stageField.field.settings?.eventType
      ) {
        const dateTimeKey = stageField.columnName.replace("Date", "At");
        values[dateTimeKey] = value.dateTime ?? null;
      }
      setState((state) => ({ ...state, ...values }));
    } else {
      setState((state) => ({ ...state, [stageField.columnName]: value }));
    }
  };

  const getFieldValue = useCallback(
    (stageField: any): any => {
      if (stageField.customFieldId) {
        const field = state.customFields.find(
          (f: any) => f.customFieldId === stageField.customFieldId
        );
        if (field && stageField.field.type === "datetime") {
          // field.value is a moment-like inuput and we need to comvert it to the
          // expected format for the DateTimeField component
          return {
            date: field.value,
            dateTime: field.value,
          };
        }
        return field
          ? field.value
          : FieldHelper.getDefaultValue(stageField.field);
      } else if (stageField.columnName) {
        if (stageField.field?.type === "datetime") {
          let dateTime: string | null = null;
          if (
            stageField.columnName.endsWith("Date") &&
            (stageField.field?.settings?.includeTime ||
              stageField.field?.settings?.eventType)
          ) {
            const timeKey = stageField.columnName.replace("Date", "At");
            dateTime = state[timeKey] ?? null;
          }
          const value: { date: string | null; dateTime: string | null } = {
            dateTime,
            date: state[stageField.columnName],
          };
          return value;
        } else {
          return state[stageField.columnName];
        }
      }
    },
    [state]
  );

  const roleTeamMemberMap = useMemo(() => {
    return state.dealTeamMembers.reduce(
      (acc: any, dealTeamMember: any) => ({
        ...acc,
        [dealTeamMember.roleId]: dealTeamMember.teamMemberId,
      }),
      {}
    );
  }, [state.dealTeamMembers]);

  const handleTeamMemberChange = (role: any, teamMember: any | null) => {
    setState((state) => {
      const dealTeamMembers = [...state.dealTeamMembers];
      const index = dealTeamMembers.findIndex(
        (dealTeamMember: any) => dealTeamMember.roleId === role.id
      );
      if (index === -1 && teamMember !== null) {
        dealTeamMembers.push({
          roleId: role.id,
          teamMemberId: teamMember.id,
        });
      } else {
        dealTeamMembers[index].teamMemberId =
          teamMember === null ? null : teamMember.id;
      }
      return Object.assign({}, state, { dealTeamMembers });
    });
  };

  useEffect(() => {
    if (!stage?.id) {
      return;
    }
    setIsBuyingType(stage.dealType?.name?.toLowerCase() === "buying");
    const variables: { stageId: string; dealId?: string } = {
      stageId: stage.id,
    };
    if (deal?.id) {
      variables.dealId = deal.id;
    }
    getNeededItemsForStageChange({
      variables,
    })
      .then((res) => {
        const { fields, roles } = res.data.getStage.neededForActions;
        setFields(fields);
        setRoles(roles);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [deal.id, stage, getNeededItemsForStageChange]);

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state, onChange]);

  const handlePrefillAddress = (checked: boolean) => {
    setState(() => ({
      ...state,
      address: checked ? { ...addressSuggestion } : {},
    }));
  };

  const renderStageFieldInput = (stageField: any) => {
    if (stageField.columnName === "mlsId") {
      return (
        <MlsInput
          key={stageField.id}
          onChange={(data) => setState({ ...state, ...data })}
          mlsKey={getFieldValue({ columnName: "mlsKey" })}
          mlsId={getFieldValue(stageField)}
        />
      );
    } else if (stageField.field.type === "address") {
      return (
        <Grid item xs={12} key={stageField.id}>
          {addressSuggestion && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(e: any) => handlePrefillAddress(e.target.checked)}
                />
              }
              label={`Use the ${deal?.dealType?.clientNoun}'s address (${addressSuggestion?.street})`}
            ></FormControlLabel>
          )}
          <AddressField
            onChange={(value: any) => handleChange(stageField, value)}
            value={getFieldValue(stageField)}
          />
        </Grid>
      );
    } else if (stageField.columnName === "source") {
      return (
        <Grid item xs={12} key={stageField.id}>
          <SourceField
            key={stageField.id}
            onChange={(value: any) => handleChange(stageField, value)}
            value={getFieldValue(stageField)}
          />
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} key={stageField.id}>
          <CustomField
            {...stageField.field}
            onChange={(value: any) => handleChange(stageField, value)}
            value={getFieldValue(stageField)}
          />
        </Grid>
      );
    }
  };

  if (loading) {
    return <Skeleton />;
  }

  if (fields.length === 0 && roles.length === 0) {
    return null;
  }

  return (
    <>
      {roles.length > 0 && (
        <Paper sx={{ marginBottom: "21px" }}>
          <Box p={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Team</Typography>
            </Grid>
            <Grid container spacing={2}>
              {roles.map((role: any) => (
                <Grid item xs={12} key={role.id}>
                  <TeamMemberSelect
                    label={role.name}
                    value={roleTeamMemberMap[role.id] ?? null}
                    onChange={(value) => {
                      handleTeamMemberChange(role, value);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      )}
      {fields.length > 0 && (
        <Paper sx={{ marginBottom: "21px" }}>
          <Box p={2}>
            <Box marginBottom={2}>
              <Typography variant="h6">Deal Details</Typography>
            </Box>

            <Grid container spacing={2}>
              {fields.map((stageField: any) =>
                renderStageFieldInput(stageField)
              )}
            </Grid>
          </Box>
        </Paper>
      )}

      {stage.dealState === "closed" && (
        <Paper>
          <Box p={2}>
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Another one closed!</Typography>
                <Typography variant="subtitle1">
                  Shaker considers deals in this stage to be closed. Once moved,
                  this deal will show up in reports. You can optionally archive
                  this deal or update the contact's address.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.archived ?? false}
                        color="primary"
                        value={true}
                        onChange={(e, archived) => {
                          setState((state) => ({
                            ...state,
                            archived,
                            dealState: "closed",
                          }));
                        }}
                      />
                    }
                    label="Archive this deal"
                  ></FormControlLabel>
                  {state.archived && (
                    <Box p={2}>
                      <Grid container direction="column" rowGap={3}>
                        <TextField
                          variant="standard"
                          select
                          label="Archive Selection"
                          fullWidth
                          onClick={(e: React.MouseEvent) => e.stopPropagation()}
                          value={state.dealState}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            e.stopPropagation();
                            setState({
                              ...state,
                              dealState: e.target.value,
                            });
                          }}
                        >
                          <MenuItem value="closed">Closed</MenuItem>
                          <MenuItem value="cancelled">Cancelled</MenuItem>
                          <MenuItem value="expired">Expired</MenuItem>
                          <MenuItem value="fallen_through">
                            Fallen Through
                          </MenuItem>
                          <MenuItem value="withdrawn">Withdrawn</MenuItem>
                        </TextField>
                        <TextField
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={3}
                          label="Comments"
                          value={state.archivedReason}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            e.stopPropagation();
                            setState({
                              ...state,
                              archivedReason: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                    </Box>
                  )}
                  {(!isBuyingType || deal.address?.street) && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={state.updateContacts?.updateAddress}
                          value={true}
                          onChange={(e: any, updateAddress) => {
                            stage.dealType.name === "Buying"
                              ? setState((state) => ({
                                  ...state,
                                  updateContacts: {
                                    ...state.updateContacts,
                                    address: { ...deal.address },
                                    updateAddress,
                                  },
                                }))
                              : setState((state) => ({
                                  ...state,
                                  updateContacts: {
                                    ...state.updateContacts,
                                    updateAddress,
                                  },
                                }));
                          }}
                        />
                      }
                      label={
                        isBuyingType
                          ? `Update Contact's address to: ${deal.address.street}`
                          : "Enter a new address for this Contact"
                      }
                    />
                  )}
                </FormGroup>
              </Grid>
              {stage.dealType.name !== "Buying" &&
                state.updateContacts?.updateAddress && (
                  <Grid item xs={12}>
                    <AddressField
                      onChange={(address) => {
                        setState((state) => ({
                          ...state,
                          updateContacts: {
                            ...state.updateContacts,
                            address,
                            updateAddress: true,
                          },
                        }));
                      }}
                      value={state.updateContacts.address}
                    />
                  </Grid>
                )}
            </>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default StageForm;
