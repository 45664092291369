import React, { useState, ChangeEvent } from "react";
import moment from "moment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TextField, Grid, Typography, Box, Link } from "@mui/material";
import { CreateGoalRowsProps, GoalType } from "../../models";

const CreateGoalRows = ({
  rowData,
  i,
  setForm,
  form,
  displayPrefill,
  setDisplayPrefill,
}: CreateGoalRowsProps) => {
  const [hover, setHover] = useState(false);

  const isMonthly = !!rowData.month;
  const monthName =
    rowData.month &&
    moment()
      .month(rowData.month - 1)
      .format("MMMM");

  const rowTitle = isMonthly ? monthName : rowData.year;
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parseCurrency = e.currentTarget.value.replace(/\$|,/g, "");
    const zeroCheckValue =
      parseCurrency === "" ? 0 : Number.parseInt(parseCurrency, 10);
    setDisplayPrefill(false);
    if (!isMonthly) {
      return setForm((prevState: React.ComponentState) => {
        return {
          ...prevState,
          yearly: {
            ...prevState.yearly,
            [e.target.name]: zeroCheckValue,
          },
        };
      });
    } else {
      setForm((prevState: React.ComponentState) => {
        prevState.monthly[i] = {
          ...prevState.monthly[i],
          [e.target.name]: zeroCheckValue,
        };

        return {
          yearly: {
            ...prevState.yearly,
          },
          monthly: [...prevState.monthly],
        };
      });
    }
  };

  const handleGoalCopy = (copyType: string) => {
    if (copyType === "copyToYear") {
      return setForm((prevState: React.ComponentState) => {
        const arr = Array.from(prevState.monthly, (monthGoal: GoalType) => {
          const { month, ...copiedParts } = prevState.monthly[i];
          return { month: monthGoal.month, ...copiedParts };
        });
        return {
          yearly: {
            ...prevState.yearly,
          },
          monthly: [...arr],
        };
      });
    } else {
      setForm((prevState: React.ComponentState) => {
        const nextIndex = i + 1;
        const { month, ...copiedParts } = prevState.monthly[i];
        prevState.monthly[nextIndex] = { month: month + 1, ...copiedParts };
        return {
          yearly: {
            ...prevState.yearly,
          },
          monthly: [...prevState.monthly],
        };
      });
    }
  };

  const formatValue = (amount: number, type: string) => {
    let formattedAmount: any;
    const amountInvalid = amount === undefined || amount === 0 || isNaN(amount);
    if (amountInvalid) {
      formattedAmount = "";
    } else if (!amountInvalid && type === "currency") {
      const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
      formattedAmount = currencyFormatter.format(amount);
    } else if (!amountInvalid && type === "number") {
      const numberFormatter = new Intl.NumberFormat("en-us", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
      formattedAmount = numberFormatter.format(amount);
    }
    return formattedAmount;
  };

  const { commission, volume, units } = rowData;
  const isData = commission !== 0 || volume !== 0 || units !== 0;
  const displayPrefilledNotice = displayPrefill && isData && !isMonthly;

  return (
    <Box>
      <Grid
        container
        spacing={3}
        width="100%"
        key={i}
        sx={{
          marginTop: "10px",
          marginLeft: "5px",
          "&.MuiGrid-container > .MuiGrid-item": {
            marginLeft: "0px",
          },
          "&.MuiGrid-container:first-of-type": {
            "& > .MuiGrid-item:first-of-type": {
              paddingTop: 0,
            },
          },
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        alignItems="end"
        justifyContent="flex-start"
      >
        <Grid
          item
          sx={{
            marginLeft: "0px",
          }}
        >
          <Typography variant="h5">{rowTitle}</Typography>
          <TextField
            variant="standard"
            name="units"
            label="Units"
            value={formatValue(
              isMonthly ? form[i].units : form.units,
              "number"
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e)}
          />
        </Grid>

        <Grid item>
          <TextField
            variant="standard"
            label="Volume"
            name="volume"
            value={formatValue(
              isMonthly ? form[i].volume : form.volume,
              "currency"
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e)}
          />
        </Grid>

        <Grid item>
          <TextField
            variant="standard"
            label="Commission"
            name="commission"
            value={formatValue(
              isMonthly ? form[i].commission : form.commission,
              "currency"
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e)}
          />
        </Grid>
        <Grid item>
          {hover && isMonthly && (
            <>
              {i !== 11 && (
                <Box display="flex">
                  <Typography variant="body1" sx={{ marginRight: "5px" }}>
                    Copy to:
                  </Typography>
                  <Link
                    component="button"
                    onClick={() => handleGoalCopy("copyMonth")}
                    sx={{
                      paddingRight: 0,
                    }}
                  >
                    <Typography variant="body1">Next Month</Typography>
                  </Link>
                  <Typography variant="body1">,</Typography>
                  <Link
                    onClick={() => handleGoalCopy("copyToYear")}
                    component="button"
                    sx={{ paddingLeft: "5px" }}
                  >
                    <Typography variant="body1">All Months</Typography>
                  </Link>
                </Box>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {displayPrefilledNotice && (
        <Grid
          item
          sx={{
            marginLeft: "25px",
            "&.MuiGrid-item": {
              paddingTop: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InfoOutlinedIcon
              color="primary"
              fontSize="small"
              sx={{
                marginRight: "5px",
              }}
            />
            <Typography variant="body1">
              These goals are prefilled based on your Monthly goals
            </Typography>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

export default CreateGoalRows;
