import React from "react";
import { Route } from "react-router-dom";
import ClientDashboard from "../pages/ClientDashboard";
import ClientView from "../pages/deals/ClientView";
import ClientVendors from "../pages/ClientVendors";
import ProfileSettings from "../components/profile/ProfileSettings";
import ClientPortalLayout from "../layouts/ClientPortalLayout";

const routes = (currentUser: any) => {
  if (currentUser) {
    return [
      <Route key="/portal" path="/portal" element={<ClientDashboard />} />,
      <Route
        key="/portal/deal/:id"
        path="/portal/deal/:id"
        element={<ClientView />}
      />,
      <Route
        key="/portal/vendors"
        path="/portal/vendors"
        element={<ClientVendors />}
      />,
      <Route
        key="/settings/profile"
        path="/settings/profile"
        element={
          <ClientPortalLayout>
            <ProfileSettings />
          </ClientPortalLayout>
        }
      />,
    ];
  } else {
    return [];
  }
};

export default routes;
