import { DealType } from "../models";
import { STAGES } from "./stages";

export const DEAL_TYPES = {
  SET: "DEAL_TYPES/set",
};

const initialState: DealType[] = [];

export default function dealTypesReducer(state = initialState, action: any) {
  switch (action.type) {
    case DEAL_TYPES.SET:
      return [...action.dealTypes].map((dealType: DealType) => {
        return {
          ...dealType,
          stages: (dealType?.stages).sort((a, b) => a.sort - b.sort),
        };
      });
    case STAGES.ADD:
      return state.map((dealType) => {
        if (dealType.id === action.stage.dealType.id) {
          const stages = [...dealType.stages, action.stage];
          return { ...dealType, stages };
        } else {
          return dealType;
        }
      });
    case STAGES.COPY_STAGE:
      return state.map((dealType) => {
        if (dealType.id === action.stage.dealType.id) {
          const stages = [...dealType.stages, action.stage].sort(
            (a, b) => a.sort - b.sort
          );
          return { ...dealType, stages };
        } else {
          return dealType;
        }
      });
    case STAGES.REMOVE:
      return state.map((dealType) => {
        if (dealType.id === action.stage.dealType.id) {
          const stages = dealType.stages.filter(
            (stage) => stage.id !== action.stage.id
          );
          return { ...dealType, stages };
        } else {
          return dealType;
        }
      });
    case STAGES.UPDATE:
      return state.map((dealType) => {
        if (dealType.id === action.stage.dealType.id) {
          const stages = dealType.stages
            .map((stage) => {
              if (stage.id === action.stage.id) {
                return { ...stage, ...action.stage };
              } else {
                return stage;
              }
            })
            .sort((a, b) => a.sort - b.sort);

          return { ...dealType, stages };
        } else {
          return dealType;
        }
      });
    case STAGES.ADD_FIELD:
      return state.map((dealType) => {
        return {
          ...dealType,
          stages: dealType.stages.map((stage) => {
            if (stage.id === action.stageId) {
              return {
                ...stage,
                fields: [...stage.fields, action.stageField].sort(
                  (a, b) => a.sort - b.sort
                ),
              };
            }
            return stage;
          }),
        };
      });
    case STAGES.REMOVE_FIELD:
      return state.map((dealType) => {
        return {
          ...dealType,
          stages: dealType.stages.map((stage) => {
            if (stage.id === action.stageId) {
              return {
                ...stage,
                fields: stage.fields.filter((field) => field.id !== action.id),
              };
            }
            return stage;
          }),
        };
      });
    case STAGES.UPDATE_FIELD:
      return state.map((dealType) => {
        return {
          ...dealType,
          stages: dealType.stages.map((stage) => {
            if (stage.id === action.stageId) {
              return {
                ...stage,
                fields: stage.fields
                  .map((field) => {
                    if (field.id === action.stageField.id) {
                      return action.stageField;
                    } else {
                      return field;
                    }
                  })
                  .sort((a, b) => a.sort - b.sort),
              };
            }
            return stage;
          }),
        };
      });
    default:
      return state;
  }
}
