import React, { useState, useReducer, useCallback } from "react";
import { connect } from "react-redux";
import {
  Box,
  Grid,
  Dialog,
  IconButton,
  DialogContentText,
  DialogActions,
  DialogTitle,
  DialogContent,
  Select,
  FormControl,
  InputLabel,
  Typography,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
  List,
  Tooltip,
  SelectChangeEvent,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { PencilIcon, DocumentIcon } from "../../icons";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "../../context/alert";
import { ReactSortable } from "react-sortablejs";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { GET_FIELDS } from "../../api/graphql";
import DetailCard from "../DetailCard";
import AddIcon from "@mui/icons-material/Add";
import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import {
  createStageField,
  updateStageField,
  deleteStageField,
  CreateStageFieldInput,
  UpdateStageFieldInput,
} from "../../actions/stageFields";
import { Stage } from "../../models";
import { ApiClient, useApi } from "../../context/api";

type StageFieldsProps = {
  stage: Stage;
  editable?: boolean;
  createStageField: (
    stageField: CreateStageFieldInput,
    apolloClient: ApiClient
  ) => Promise<any>;
  updateStageField: (
    stageField: UpdateStageFieldInput,
    stageId: string,
    apolloClient: ApiClient
  ) => Promise<any>;
  deleteStageField: (
    id: string,
    stageId: string,
    apolloClient: ApiClient
  ) => Promise<any>;
};

const StageFields = ({
  stage,
  editable = true,
  createStageField,
  updateStageField,
  deleteStageField,
}: StageFieldsProps): JSX.Element => {
  const ACTION_SET_CUSTOM_FIELDS = "SET_CUSTOM_FIELDS";
  const ACTION_SET_SYSTEM_FIELDS = "SET_SYSTEM_FIELDS";
  const ACTION_SET_STAGE_FIELD = "SET_STAGE_FIELD";

  const { apolloClient } = useApi();

  const [open, setOpen] = useState(false);
  const { showSuccess, showError } = useAlert();
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case ACTION_SET_STAGE_FIELD:
        return Object.assign({}, state, {
          stageField: action.stageField,
        });
      case ACTION_SET_CUSTOM_FIELDS:
        return Object.assign({}, state, {
          customFields: action.customFields,
        });
      case ACTION_SET_SYSTEM_FIELDS:
        return Object.assign({}, state, {
          dealFields: action.systemFields,
        });
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    stageField: {},
    dealFields: [],
    customFields: [],
  });

  const onCompleted = useCallback((data: any) => {
    dispatch({
      type: ACTION_SET_CUSTOM_FIELDS,
      customFields: data.getCustomFields,
    });
    dispatch({
      type: ACTION_SET_SYSTEM_FIELDS,
      systemFields: data.getSystemFields,
    });
  }, []);

  useQuery(GET_FIELDS, {
    variables: {
      tableName: "deal",
    },
    onCompleted,
  });

  const handleAdd = (e: any, index?: number) => {
    dispatch({
      type: ACTION_SET_STAGE_FIELD,
      stageField: {
        sort: index === undefined ? stage.fields.length + 1 : index + 1,
      },
    });
    setOpen(true);
  };

  const handleEdit = (e: any, stageField: any) => {
    dispatch({
      type: ACTION_SET_STAGE_FIELD,
      stageField,
    });
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({
      type: ACTION_SET_STAGE_FIELD,
      stageField: {},
    });
    setOpen(false);
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    const input = Object.assign({}, state.stageField);

    // React SortableJS adds the properties `chosen` and `selected` which
    // the mutation doesn't expect.
    delete input.chosen;
    delete input.selected;
    delete input.field;

    let promise: Promise<any>;
    if (state.stageField.id) {
      promise = updateStageField(input, stage.id, apolloClient);
    } else {
      promise = createStageField({ ...input, stageId: stage.id }, apolloClient);
    }

    promise.then(handleClose).catch((err) => {
      showError("An error occurred when saving the field");
    });
  };

  const handleDelete = (e: any, stageField: any) => {
    deleteStageField(stageField.id, stage.id, apolloClient)
      .then(() => showSuccess("Field deleted successfully"))
      .catch(() => showError("An error occurred when deleting the field"));
  };

  const handleFieldChange = (e: SelectChangeEvent) => {
    // Get the field that was selected (either a deal field or custom field)
    const dealField = state.dealFields.find(
      (field: any) => field.id === e.target.value
    );
    const customField = state.customFields.find(
      (field: any) => field.id === e.target.value
    );

    let patch: any = {};
    if (dealField) {
      patch.columnName = dealField.id;
      patch.customFieldId = null;
    } else if (customField) {
      patch.customFieldId = customField.id;
      patch.columnName = null;
    }

    dispatch({
      type: ACTION_SET_STAGE_FIELD,
      stageField: Object.assign({}, state.stageField, patch),
    });
  };

  const setFields = (fields: any) => {
    fields.forEach((stageField: any, index: number) => {
      if (stageField.sort !== index) {
        stageField.sort = index;
        updateStageField(
          { id: stageField.id, sort: index },
          stage.id,
          apolloClient
        ).catch(() => {});
      }
    });
  };

  const isInvalid = (): boolean => {
    return !state.stageField.customFieldId && !state.stageField.columnName;
  };

  const DescribeField = ({ field }: any) => {
    return (
      <Box style={{ display: "flex", alignItems: "center" }}>
        {stage.fields.length > 1 && editable && (
          <DragIndicatorIcon
            className="handle"
            style={{ color: "#6b7280", marginRight: "11px", cursor: "grab" }}
          />
        )}
        <Typography
          style={{
            fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          {field.field.label}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <DetailCard
        collapsible={false}
        title={
          <Typography
            variant="h5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <DocumentIcon
              style={{
                background: "#FFB000",
                color: "#fff",
                padding: "4px 4.5px",
                borderRadius: "50%",
                height: "22px",
                width: "22px",
                marginRight: "14px",
              }}
            />
            Fields
          </Typography>
        }
        action={
          <Tooltip title="add" aria-label="add a field">
            <IconButton onClick={handleAdd} disabled={!editable} size="large">
              <AddIcon />
            </IconButton>
          </Tooltip>
        }
      >
        {stage.fields.length === 0 && (
          <Box textAlign="center" style={{ width: "100%" }} p={4}>
            <Typography style={{ marginBottom: "11px" }}>
              Build out a form to collect data as the deal progresses to this
              stage
            </Typography>
            <PrimaryButton
              onClick={handleAdd}
              style={{ minWidth: "200px" }}
              disabled={!editable}
            >
              Add a field
            </PrimaryButton>
          </Box>
        )}

        {stage.fields.length > 0 && (
          <List style={{ width: "100%" }} className="actions-on-hover">
            <ReactSortable
              list={stage.fields}
              setList={setFields}
              handle=".handle"
            >
              {stage.fields.map((field: any) => (
                <ListItem key={field.id}>
                  <ListItemText primary={DescribeField({ field })} />

                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={(e) => handleEdit(e, field)}
                      disabled={!editable}
                      size="large"
                    >
                      <PencilIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => handleDelete(e, field)}
                      disabled={!editable}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </ReactSortable>
          </List>
        )}
      </DetailCard>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <form onSubmit={handleSave}>
          <DialogTitle>Add a field</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="select-field">Field</InputLabel>
                  <Select
                    value={
                      state.stageField.customFieldId ||
                      state.stageField.columnName ||
                      ""
                    }
                    inputProps={{
                      id: "select-field",
                      name: "selectedField",
                    }}
                    fullWidth
                    label="Field"
                    native
                    onChange={handleFieldChange}
                  >
                    <option value=""></option>
                    {state.dealFields.map((field: any) => (
                      <option key={field.id} value={field.id}>
                        {field.label}
                      </option>
                    ))}
                    {state.customFields.length > 0 && (
                      <optgroup label="Custom Fields">
                        {state.customFields.map((field: any) => (
                          <option key={field.id} value={field.id}>
                            {field.label}
                          </option>
                        ))}
                      </optgroup>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SecondaryButton
              onClick={handleClose}
              color="primary"
              style={{ width: "100px" }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              color="primary"
              disabled={isInvalid()}
              style={{ width: "100px" }}
            >
              Save
            </PrimaryButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default connect(null, {
  createStageField,
  updateStageField,
  deleteStageField,
})(StageFields);
