import React, { FunctionComponent } from "react";

import NumberField from "./NumberField";
import DateTimeField from "./DateTimeField";
import DropdownField from "./Dropdown";
import { TextField } from "@mui/material";
import PhoneField from "./PhoneField";
import EmailField from "./EmailField";
import MlsSelect from "../MlsSelect";
import TimeDropdown from "./TimeDropdown";
import { Deal } from "../../models";

type CustomFieldProps = {
  id?: string;
  deal?: Deal;
  type?: string;
  label?: string;
  settings?: any;
  value: any;
  onChange: (value: string) => any;
};

const CustomField: FunctionComponent<CustomFieldProps> = ({
  id,
  deal,
  type,
  label,
  settings,
  value,
  onChange,
}) => {
  const handleChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };

  const renderField = () => {
    if (id === "mlsKey") {
      return <MlsSelect onChange={handleChange} value={value} />;
    } else if (type === "dropdown") {
      return (
        <DropdownField
          label={label}
          settings={settings}
          onChange={handleChange}
          value={value}
        />
      );
    } else if (type === "number") {
      return (
        <NumberField
          label={label}
          onChange={handleChange}
          settings={settings}
          value={value}
        />
      );
    } else if (type === "datetime") {
      return (
        <DateTimeField
          settings={settings}
          label={label}
          id={id}
          deal={deal}
          onChange={handleChange}
          value={value || null}
        />
      );
    } else if (type === "time") {
      return (
        <TimeDropdown
          settings={settings}
          label={label}
          onChange={handleChange}
          value={value || null}
        />
      );
    } else if (type === "phone") {
      return (
        <PhoneField
          settings={settings}
          label={label}
          value={value}
          onChange={handleChange}
        />
      );
    } else if (type === "email") {
      return (
        <EmailField
          settings={settings}
          label={label}
          value={value}
          onChange={handleChange}
        />
      );
    } else {
      return (
        <TextField
          label={label}
          onChange={(e) => handleChange(e.target.value)}
          fullWidth
          value={value}
          variant="standard"
        />
      );
    }
  };

  return renderField();
};

export default CustomField;
