import React, { FunctionComponent, useRef } from "react";

type EmailBodyIframeProps = {
  html: string;
};
const EmailBodyIframe: FunctionComponent<EmailBodyIframeProps> = ({ html }) => {
  let dom = document.createElement("div");
  dom.innerHTML = html;

  const elementsSet = new Set(dom.getElementsByTagName("*"));
  let elementNameArray: any = [];
  elementsSet.forEach((element) => elementNameArray.push(element.localName));

  const elementsInEmail = new Set(elementNameArray);

  elementsInEmail.forEach((element) => {
    const elToReplace = `<${element}>`;
    html = html.replaceAll(
      elToReplace,
      `<${element} style="margin-block-start: 0px; margin-block-end: 0px">`
    );
  });

  const iframe = useRef<any>(null);
  const handleLoad = (e: any) => {
    if (!iframe.current) {
      return;
    }
    const doc = iframe.current.contentWindow.document;
    const body = doc.body;
    // Set the height
    const docElement = doc.documentElement;
    // Adapted from https://www.dyn-web.com/tutorials/iframes/height/
    // html.clientHeight and html.scrollHeight seems to give an excess height so
    // I've commented them out.
    const height = Math.max(
      body.scrollHeight + 100,
      body.offsetHeight,
      //html.clientHeight,
      //html.scrollHeight,
      docElement.offsetHeight
    );
    iframe.current.style.height = height + "px";

    // Style
    body.style.padding = "0px";
    body.style.margin = "0px";
    body.style.fontSize = "14px";
    body.style.fontFamily = "AvenirNext-Regular, Avenir, sans-serif";
  };
  return (
    <iframe
      title="Email Message"
      sandbox="allow-same-origin"
      ref={iframe}
      srcDoc={html}
      onLoad={handleLoad}
      style={{ width: "100%", border: "none" }}
    />
  );
};
export default EmailBodyIframe;
