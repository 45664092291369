import React, { useState, FunctionComponent, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { useLocation } from "react-router-dom";

import SideLayout from "../layouts/SideLayout";
import "./Login.scss";
import QueryString from "qs";
import { useAuth } from "../context/auth";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  img: {
    height: "45px",
    width: "100%",
    paddingBottom: "1em",
  },
  p: {
    fontSize: "16px",
    fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
    fontWeight: 600,
    textAlign: "center",
  },
}));

const OAuthAuthorize: FunctionComponent<any> = () => {
  const { authToken } = useAuth();
  const classes = useStyles();

  const [client, setClient] = useState<any>();

  const location = useLocation();
  if (location.search.length === 0) {
  }

  const url = `${process.env.REACT_APP_API_URL}/oauth/authorize`;
  const search = QueryString.parse(location.search.substr(1));

  const { data } = useQuery(
    gql`
      query GetOAuthClient($id: ID!) {
        getOAuthClient(id: $id) {
          id
          name
        }
      }
    `,
    {
      variables: {
        id: search.client_id,
      },
    }
  );

  useEffect(() => {
    if (data && data.getOAuthClient) {
      setClient(data.getOAuthClient);
    }
  }, [data]);

  if (!client) {
    return <></>;
  }
  return (
    <SideLayout
      right={
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={6}>
              {
                <img
                  src="/logo-full.svg"
                  alt="Shaker logo"
                  aria-label="Shaker logo"
                  title="Shaker"
                  className={classes.img}
                />
              }

              <form
                noValidate
                className={classes.form}
                method="post"
                action={url}
              >
                <input type="hidden" name="token" value={authToken as string} />

                <input
                  type="hidden"
                  name="client_id"
                  value={search.client_id as string}
                />

                <input
                  type="hidden"
                  name="response_type"
                  value={search.response_type as string}
                />

                {search.state && (
                  <input
                    type="hidden"
                    name="state"
                    value={search.state as string}
                  />
                )}

                {search.scope && (
                  <input
                    type="hidden"
                    name="scope"
                    value={search.scope as string}
                  />
                )}

                {search.state && (
                  <input
                    type="hidden"
                    name="state"
                    value={search.state as string}
                  />
                )}

                {search.redirect_uri && (
                  <input
                    type="hidden"
                    name="redirect_uri"
                    value={search.redirect_uri as string}
                  />
                )}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Authorize {client.name}
                </Button>
              </form>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default OAuthAuthorize;
