import React, {
  useRef,
  useEffect,
  useState,
  CSSProperties,
  useCallback,
} from "react";
import { gql, useApolloClient } from "@apollo/client";
import { Link } from "react-router-dom";
import MaterialTable, { Query, QueryResult } from "@material-table/core";
import TableIcons from "../TableIcons";
import ContactNameWithAvatar from "./ContactNameWithAvatar";
import { ViewColumnIcon } from "../../icons";
import ActionIconButton from "../ActionIconButton";
import { Menu, MenuItem, Checkbox, Avatar, Chip, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Tag } from "../../models";
import {
  ContactQueryFilter,
  ContactSearchParams,
  makeContactQueryParams,
} from "../../pages/contacts/List";
import ContactsFilter from "./ContactsFilter";
import { getStreetString, getCityStateString } from "../../helpers/address";

export const GET_CONTACTS = gql`
  query GetContacts($params: ContactQueryParams!) {
    getContacts(params: $params) {
      contacts {
        id
        name
        combinedName
        email
        phone
        avatarUrl
        address {
          street
          street2
          city
          state
          zip
        }
        linkedContact {
          id
          name
          avatarUrl
        }
        owner {
          id
          name
          avatarUrl
        }
        tags {
          id
          name
        }
      }
      page
      pageSize
      totalCount
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cellLink: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
      padding: "16px",
      textDecoration: "none",
      color: "inherit",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

interface selectedContactInterface {
  checked: boolean;
  pageNumber: number;
}

interface selectedContactsObjInterface {
  [key: string]: selectedContactInterface;
}
export default function ContactTable({
  search,
  setSearch,
  setParams,
  searchParams,
  selectedContacts,
  allContactsSelected,
  setSelectedContacts,
  setTotalContacts,
  setAllContactsSelected,
  filters,
  setFilters,
  filterOpen,
  setFilterOpen,
  dateDispatch,
  dateState,
  selectedAgents,
  setSelectedAgents,
  selectedTags,
  setSelectedTags,
  zipcodes,
  setZipcodes,
  handleFilter,
}: {
  search: ContactSearchParams;
  setSearch: React.Dispatch<React.SetStateAction<ContactSearchParams>>;
  setParams: React.Dispatch<React.SetStateAction<any>>;
  searchParams: any;
  selectedContacts: selectedContactsObjInterface;
  allContactsSelected: boolean;
  setSelectedContacts(selectedContacts: {
    [key: string]: selectedContactInterface;
  }): any;
  setTotalContacts(number: number): any;
  setAllContactsSelected(boolean: boolean): any;
  filters: ContactQueryFilter[];
  setFilters: (filters: ContactQueryFilter[]) => void;
  filterOpen: boolean;
  setFilterOpen(boolean: boolean): any;
  dateDispatch: any;
  dateState: any;
  selectedAgents: any;
  setSelectedAgents: any;
  selectedTags: any;
  setSelectedTags: any;
  zipcodes: any;
  setZipcodes: any;
  handleFilter: any;
}) {
  const classes = useStyles();
  const tableRef = useRef(null);
  const columnsMenuButtonRef = useRef(null);
  const currentPageNumberRef = useRef(0);

  useEffect(() => {
    (tableRef.current as any)?.onQueryChange();
  }, [search, allContactsSelected, filters, searchParams]);

  const apolloClient = useApolloClient();

  const queryContacts = (query: Query<any>): Promise<QueryResult<any>> => {
    const params = makeContactQueryParams(search, searchParams, filters);

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: GET_CONTACTS,
          variables: { params },
        })
        .then((response) => {
          setTotalContacts(response.data.getContacts.totalCount);
          currentPageNumberRef.current = response.data.getContacts.page;
          const renderedContacts = response.data.getContacts.contacts.map(
            (contact: any) => {
              contact.tableData = {
                checked: !!selectedContacts[contact.id],
                pageNumber: response.data.getContacts.page,
              };
              return contact;
            }
          );
          resolve({
            data: renderedContacts,
            page: response.data.getContacts.page,
            totalCount: response.data.getContacts.totalCount,
          });
        })
        .catch((error) => reject(error));
    });
  };

  const renderName = (rowData: any): JSX.Element => {
    return (
      <ContactNameWithAvatar
        name={rowData.combinedName}
        avatarUrls={[rowData.avatarUrl, rowData?.linkedContact?.avatarUrl]}
      />
    );
  };

  const renderColumnSelectHeader = () => {
    return (
      <ActionIconButton
        ref={columnsMenuButtonRef}
        icon={ViewColumnIcon}
        buttonStyle={{ margin: "0", width: "26px", height: "26px" }}
        onClick={(event: any) => {
          event.stopPropagation();
          setColumnMenuOpen(true);
        }}
      />
    );
  };

  const renderMenu = (rowData: any) => {
    // For when we are ready to implement this menu
    // return (
    //   <ActionIconButton
    //     icon={MoreVertIcon}
    //     buttonStyle={{ margin: "0", width: "26px", height: "26px" }}
    //   />
    // );

    return <></>;
  };

  const renderOwner = (rowData: {
    owner: { id: string; avatarUrl: string };
  }) => {
    if (!rowData.owner) {
      return <></>;
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar
          onClick={(e) => {
            setSearch((search) => {
              return { ...search, owner: rowData.owner };
            });
            setParams({ ownerId: rowData.owner.id });
            e.stopPropagation();
            e.preventDefault();
          }}
          src={rowData.owner.avatarUrl}
          style={{
            width: "24px",
            height: "24px",
            margin: "2px 8px 0 0",
          }}
        ></Avatar>
      </div>
    );
  };

  const addSearchTag = useCallback(
    (tag: Tag) => {
      setSearch((search) => {
        if (search.tags.find((searchTag: Tag) => searchTag.id === tag.id)) {
          return search;
        } else {
          return Object.assign({}, search, {
            tags: [...search.tags, tag],
          });
        }
      });
    },
    [setSearch]
  );

  const renderTags = (rowData: any) => {
    const containerWidth = () => {
      const tagsNumber = rowData.tags.length;
      if (tagsNumber > 8) {
        return "550px";
      } else if (tagsNumber > 2) {
        return "350px";
      } else {
        return "auto";
      }
    };

    return (
      <div
        style={{ display: "flex", flexWrap: "wrap", width: containerWidth() }}
      >
        {rowData.tags.map((tag: Tag) => (
          <Chip
            onClick={(e: React.MouseEvent<any, any>) => {
              e.stopPropagation();
              e.preventDefault();
              addSearchTag(tag);
            }}
            key={tag.id}
            variant="outlined"
            label={tag.name}
            style={{ margin: "4px" }}
          />
        ))}
      </div>
    );
  };

  const renderAddress = (rowData: any) => {
    return (
      <>
        {getStreetString(rowData.address)}
        <br />
        {getCityStateString(rowData.address)}
      </>
    );
  };

  const renderValue = (field: string) => (rowData: any) => rowData[field];

  const linkWrap =
    (renderFunction: (rowData: any) => JSX.Element) => (rowData: any) =>
      (
        <Link to={`/contacts/${rowData.id}`} className={classes.cellLink}>
          {renderFunction(rowData)}
        </Link>
      );

  const cellStyle = (): CSSProperties => ({
    whiteSpace: "nowrap",
    padding: "0",
    height: "80px",
    maxWidth: "250px",
  });

  const [columnMenuOpen, setColumnMenuOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState([
    {
      key: "name",
      visible: true,
      title: "Name",
      field: "name",
      render: linkWrap(renderName),
      cellStyle,
    },
    {
      key: "address",
      visible: true,
      title: "Address",
      field: "address",
      sorting: false,
      render: linkWrap(renderAddress),
      cellStyle,
    },
    {
      key: "email",
      visible: true,
      title: "Email",
      field: "email",
      render: linkWrap(renderValue("email")),
      cellStyle,
    },
    {
      key: "phone",
      visible: true,
      title: "Phone",
      field: "phone",
      sorting: false,
      render: linkWrap(renderValue("phone")),
      cellStyle,
    },
    {
      key: "owner",
      visible: true,
      title: "Agent",
      field: "owner",
      sorting: false,
      render: linkWrap(renderOwner),
      cellStyle,
    },
    {
      key: "tags",
      visible: true,
      title: "Tags",
      field: "tags",
      sorting: false,
      render: linkWrap(renderTags),
      cellStyle,
    },
    {
      key: "column-select",
      visible: true,
      title: renderColumnSelectHeader(),
      sorting: false,
      width: "34px",
      headerStyle: {},
      cellStyle: {},
      render: renderMenu,
    },
  ]);

  const toggleColumnVisible = (key: string) => () => {
    setColumns((columns) => {
      const column = columns.find((column) => column.key === key);
      const updatedColumn = Object.assign({}, column, {
        visible: !column?.visible,
      });
      return columns.map((c) => (c.key === key ? updatedColumn : c));
    });
  };

  return (
    <>
      <Menu
        open={columnMenuOpen}
        onClose={() => setColumnMenuOpen(false)}
        anchorEl={columnsMenuButtonRef.current}
      >
        {columns
          .filter((column) => column.key !== "column-select")
          .map((column) => (
            <MenuItem
              key={column.key}
              onClick={toggleColumnVisible(column.key)}
              style={{ paddingLeft: "0", fontSize: "12px" }}
            >
              <Checkbox checked={column.visible} size="small" />
              {column.title}
            </MenuItem>
          ))}
      </Menu>
      <MaterialTable
        tableRef={tableRef}
        icons={TableIcons}
        columns={columns.filter((column) => column.visible)}
        data={queryContacts}
        onRowClick={() => {}}
        onOrderChange={(orderedColumnId, orderDirection) => {
          const idCheck =
            orderedColumnId === -1 ? null : columns[orderedColumnId].key;
          setParams({
            orderBy: idCheck,
            orderDirection: idCheck ? orderDirection : null,
          });
        }}
        onPageChange={(page) => setParams({ page })}
        onRowsPerPageChange={(pageSize) => setParams({ pageSize })}
        onSelectionChange={(data: any, rowData: any) => {
          const filteredContacts = Object.assign({}, selectedContacts);
          if (rowData === undefined) {
            const selectAll = data.reduce((acc: any, c: any) => {
              acc[c.id] = {
                checked: true,
                pageNumber: currentPageNumberRef.current,
              };

              return acc;
            }, {});
            if (Object.keys(selectedContacts).length >= 10) {
              if (data.length === 0) {
                const currentPageRemoved = Object.entries(
                  selectedContacts
                ).filter(
                  (key) => key[1].pageNumber !== currentPageNumberRef.current
                );
                setSelectedContacts(Object.fromEntries(currentPageRemoved));
                return;
              }
              setSelectedContacts({ ...selectedContacts, ...selectAll });
              return;
            }
            setSelectedContacts(selectAll);
            return;
          }

          if (selectedContacts[rowData.id]) {
            delete filteredContacts[rowData.id];
          } else {
            filteredContacts[rowData.id] = {
              checked: !selectedContacts[rowData.id]?.checked,
              pageNumber: currentPageNumberRef.current,
            };
          }
          setSelectedContacts(filteredContacts);
          setAllContactsSelected(false);
        }}
        options={{
          selection: true,
          showTitle: false,
          search: false,
          emptyRowsWhenPaging: false,
          columnsButton: true,
          pageSize: parseInt(searchParams.pageSize || 25, 10),
          pageSizeOptions: [10, 25, 50, 100],
          toolbar: false,
          headerStyle: {
            fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
            fontSize: "14px",
            color: "rgb(107, 114, 128)",
          },
        }}
        style={{
          width: "100%",
          fontSize: "14px",
          fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
        }}
      ></MaterialTable>
      <ContactsFilter
        state={dateState}
        dispatch={dateDispatch}
        zipcodes={zipcodes}
        setZipcodes={setZipcodes}
        selectedAgents={selectedAgents}
        setSelectedAgents={setSelectedAgents}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        search={search}
        setSearch={setSearch}
        filterOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        handleFilter={handleFilter}
      />
    </>
  );
}
