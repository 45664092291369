import React from "react";
import { Drawer, Theme, useTheme, ListItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { List, ListItemIcon, ListItemText, Hidden } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { Help as HelpIcon } from "@mui/icons-material";

export const AppDrawerList = withStyles({
  root: { margin: "25px 0 0 0px" },
})(List);

export const AppDrawerListItemIcon = withStyles({
  root: { minWidth: "0", marginRight: "17px", marginLeft: "9px" },
})(ListItemIcon);

export const AppDrawerText = withStyles({
  primary: {
    fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
    fontSize: "14px",
    fontWeight: 600,
  },
})(ListItemText);

export const drawerWidth = "15%";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        maxWidth: "176px",
      },
      [theme.breakpoints.up("xs")]: {
        width: "25%",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        maxWidth: "176px",
        flexShrink: 0,
      },
      [theme.breakpoints.up("xs")]: {
        width: "25%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
      whiteSpace: "nowrap",
      background: "#1D2D44",
      border: "none",
      boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.36)",
    },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      minHeight: 0,
    },
    // drawerPaper: {
    //   width: drawerWidth,
    // },
  })
);

const AppDrawer = ({
  children,
  open,
  onClose,
}: {
  children: JSX.Element;
  open: boolean;
  onClose?: () => any;
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleHelpClick = () => {
    const w = window as any;
    if (w.Intercom) {
      w.Intercom("show");
    }
  };

  return (
    <div className={classes.drawerContainer}>
      <nav className={classes.drawer} aria-label="Shaker navigation">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={open}
            onClose={handleClose}
            classes={{ paper: classes.drawer }}
            ModalProps={{ keepMounted: true }}
          >
            <div className={classes.toolbar}></div>
            <AppDrawerList>{children}</AppDrawerList>

            <div style={{ flexGrow: 1 }}></div>
            <ListItem
              button
              component="a"
              href="https://intercom.help/shakerio/en/"
              target="support"
              title="Help Center"
            >
              <AppDrawerListItemIcon>
                <HelpIcon style={{ width: "16px", height: "16px" }} />
              </AppDrawerListItemIcon>
              <AppDrawerText>Help Center</AppDrawerText>
            </ListItem>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawer,
            }}
            variant="permanent"
            open={open}
          >
            <div className={classes.toolbar}></div>
            <AppDrawerList>{children}</AppDrawerList>

            <div style={{ flexGrow: 1 }}></div>
            <ListItem
              button
              component="a"
              onClick={handleHelpClick}
              target="support"
              title="Help Center"
            >
              <AppDrawerListItemIcon>
                <HelpIcon style={{ width: "16px", height: "16px" }} />
              </AppDrawerListItemIcon>
              <AppDrawerText>Help Center</AppDrawerText>
            </ListItem>
          </Drawer>
        </Hidden>
      </nav>
      {/* <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.toolbar}></div>
        <AppDrawerList>{children}</AppDrawerList>
      </Drawer> */}
    </div>
  );
};

export default AppDrawer;
