import { createContext, Dispatch } from "react";
import { Deal, Note, Task } from "../../../models";

export enum DealViewActionType {
  SET_DEAL = "SET_DEAL",
  SET_TASKS = "SET_TASKS",
  ADD_TASK_NOTE = "ADD_TASK_NOTE",
  EDIT_TASK_NOTE = "EDIT_TASK_NOTES",
  REMOVE_TASK_NOTE = "REMOVE_TASK_NOTE",
  SET_NOTES = "SET_NOTES",
  REMOVE_DEAL_TEAM_MEMBER = "REMOVE_DEAL_TEAM_MEMBER",
  SET_DEAL_TEAM_MEMBER = "SET_DEAL_TEAM_MEMBER",
  OPEN_ADD_TEAM_MEMBER_DIALOG = "OPEN_ADD_TEAM_MEMBER_DIALOG",
  OPEN_EDIT_DEAL_TYPE_DIALOG = "OPEN_EDIT_DEAL_TYPE_DIALOG",
  CLOSE_EDIT_DEAL_TYPE_DIALOG = "CLOSE_EDIT_DEAL_TYPE_DIALOG",
  CLOSE_ADD_TEAM_MEMBER_DIALOG = "CLOSE_ADD_TEAM_MEMBER_DIALOG",
  ADD_NOTE = "ADD_NOTE",
  EDIT_NOTE = "EDIT_NOTE",
  REMOVE_NOTE = "REMOVE_NOTE",
  ADD_TASK = "ADD_TASK",
  UPDATE_TASK = "UPDATE_TASK",
  REMOVE_TASK = "REMOVE_TASK",
  UPDATE_DEAL_CONFIG = "UPDATE_DEAL_CONFIG",
  SET_EDITING = "SET_EDITING",
  SHOW_COPY_DIALOG = "SHOW_COPY_DIALOG",
  HIDE_COPY_DIALOG = "HIDE_COPY_DIALOG",
  SHOW_DOTLOOP_DIALOG = "SHOW_DOTLOOP_DIALOG",
  HIDE_DOTLOOP_DIALOG = "HIDE_DOTLOOP_DIALOG",
  SHOW_DOCUSIGN_DIALOG = "SHOW_DOCUSIGN_DIALOG",
  HIDE_DOCUSIGN_DIALOG = "HIDE_DOCUSIGN_DIALOG",
  SHOW_SKYSLOPE_DIALOG = "SHOW_SKYSLOPE_DIALOG",
  HIDE_SKYSLOPE_DIALOG = "HIDE_SKYSLOPE_DIALOG",
  SHOW_INVITE_DIALOG = "SHOW_INVITE_DIALOG",
  HIDE_INVITE_DIALOG = "HIDE_INVITE_DIALOG",
  SET_ADDED_INTEGRATIONS = "SET_ADDED_INTEGRATIONS",
  SET_FORM_FIELDS = "SET_FORM_FIELDS",
  SET_EDIT_CONTACTS = "SET_EDIT_CONTACTS",
  UNLINK_DOTLOOP = "UNLINK_DOTLOOP",
  UNLINK_DOCUSIGN_ROOM = "UNLINK_DOCUSIGN_ROOM",
  UNLINK_SKYSLOPE = "UNLINK_SKYSLOPE",
}

export type DealViewState = {
  deal: Deal;
  editingDealTypeProps: any;
  notes: Note[];
  tasks: Task[];
  addedIntegrations: any;
  addingTeamMemberProps: any;
  editing: boolean;
  editContacts?: boolean;
  copyDialog: boolean;
  inviteDialog: boolean;
  dotloopDialog: boolean;
  docusignDialog: boolean;
  skyslopeDialog: boolean;
  formFields?: any;
};

export const dealViewStore = createContext(
  {} as { state: DealViewState; dispatch: Dispatch<any> }
);

export const { Provider } = dealViewStore;

export const reducer = (state: DealViewState, action: any) => {
  switch (action.type) {
    case DealViewActionType.SET_DEAL:
      return Object.assign({}, state, {
        deal: action.deal,
      });
    case DealViewActionType.SET_TASKS:
      return Object.assign({}, state, {
        tasks: action.tasks,
      });
    case DealViewActionType.ADD_TASK_NOTE:
      return Object.assign({}, state, {
        tasks: state.tasks.map((task) => {
          if (task.id === action.note.task.id) {
            task.notes = [...task.notes, action.note];
          }
          return task;
        }),
      });
    case DealViewActionType.EDIT_TASK_NOTE:
      return Object.assign({}, state, {
        tasks: state.tasks.map((task: any) => {
          task.notes.map((note: any) => {
            if (action.note.id === note.id) {
              note.body = action.note.body;
            }
            return note;
          });
          return task;
        }),
      });
    case DealViewActionType.REMOVE_TASK_NOTE:
      return Object.assign({}, state, {
        tasks: state.tasks.map((task: any) => {
          const notes = task.notes.filter(
            (note: any) => action.note.id !== note.id
          );
          task.notes = notes;
          return task;
        }),
      });
    case DealViewActionType.SET_NOTES:
      return Object.assign({}, state, {
        notes: action.notes,
      });
    case DealViewActionType.REMOVE_DEAL_TEAM_MEMBER:
      const deal = state.deal;
      if (deal) {
        return Object.assign({}, state, {
          deal: {
            ...deal,
            dealTeamMembers: (deal.dealTeamMembers ?? []).filter((dtm: any) => {
              const teamMemberMatch =
                dtm.teamMember?.id === action.teamMemberId;
              const roleMatch = dtm.role?.id === action.roleId;
              if (roleMatch && teamMemberMatch) {
                return false;
              } else {
                return true;
              }
            }),
          },
        });
      } else {
        return state;
      }
    case DealViewActionType.SET_DEAL_TEAM_MEMBER:
      state = Object.assign({}, state);
      if (state.deal) {
        state.deal.dealTeamMembers = (state.deal.dealTeamMembers ?? []).filter(
          (dealTeamMember: any) =>
            dealTeamMember.role.id !== action.dealTeamMember.role.id
        );
        state.deal.dealTeamMembers.push(action.dealTeamMember);
      }
      return state;
    case DealViewActionType.OPEN_ADD_TEAM_MEMBER_DIALOG:
      return Object.assign({}, state, {
        addingTeamMemberProps: {
          open: true,
          role: action.role,
          deal: state.deal,
          defaultTeamMember: action.teamMember,
        },
      });
    case DealViewActionType.OPEN_EDIT_DEAL_TYPE_DIALOG:
      return Object.assign({}, state, {
        editingDealTypeProps: {
          open: true,
          deal: state.deal,
          dealTypes: action.dealTypes,
        },
      });
    case DealViewActionType.CLOSE_EDIT_DEAL_TYPE_DIALOG:
      return Object.assign({}, state, {
        editingDealTypeProps: {
          open: false,
        },
      });
    case DealViewActionType.CLOSE_ADD_TEAM_MEMBER_DIALOG:
      return Object.assign({}, state, {
        addingTeamMemberProps: {
          open: false,
        },
      });
    case DealViewActionType.ADD_NOTE:
      state = Object.assign({}, state, {
        notes: [action.note, ...state.notes],
      });
      return state;
    case DealViewActionType.EDIT_NOTE:
      state = Object.assign({}, state, {
        notes: state.notes.map((note) => {
          if (note.id === action.note.id) {
            note.body = action.note.body;
          }
          return note;
        }),
      });
      return state;
    case DealViewActionType.REMOVE_NOTE:
      state = Object.assign({}, state, {
        notes: [...state.notes.filter((note) => note.id !== action.note.id)],
      });
      return state;
    case DealViewActionType.ADD_TASK:
      state = Object.assign({}, state, {
        tasks: [action.task, ...state.tasks],
      });
      return state;
    case DealViewActionType.UPDATE_TASK:
      state = Object.assign({}, state, {
        tasks: state.tasks.map((task) => {
          if (task.id === action.task.id) {
            return action.task;
          } else {
            return task;
          }
        }),
      });
      return state;
    case DealViewActionType.REMOVE_TASK:
      state = Object.assign({}, state, {
        tasks: state.tasks.filter((task) => task.id !== action.task.id),
      });
      return state;
    case DealViewActionType.UPDATE_DEAL_CONFIG:
      let config = state.deal?.config ?? {};
      config = {
        ...config,
        ...action.config,
      };
      state = Object.assign({}, state, {
        deal: Object.assign({}, state.deal, { config }),
      });
      return state;

    case DealViewActionType.SET_EDITING:
      return Object.assign({}, state, { editing: action.editing });

    case DealViewActionType.SHOW_COPY_DIALOG:
      return Object.assign({}, state, { copyDialog: true });

    case DealViewActionType.HIDE_COPY_DIALOG:
      return Object.assign({}, state, { copyDialog: false });

    case DealViewActionType.SHOW_DOTLOOP_DIALOG:
      return Object.assign({}, state, { dotloopDialog: true });

    case DealViewActionType.HIDE_DOTLOOP_DIALOG:
      return Object.assign({}, state, { dotloopDialog: false });

    case DealViewActionType.SHOW_DOCUSIGN_DIALOG:
      return Object.assign({}, state, { docusignDialog: true });

    case DealViewActionType.HIDE_DOCUSIGN_DIALOG:
      return Object.assign({}, state, { docusignDialog: false });

    case DealViewActionType.SHOW_SKYSLOPE_DIALOG:
      return Object.assign({}, state, { skyslopeDialog: true });

    case DealViewActionType.HIDE_SKYSLOPE_DIALOG:
      return Object.assign({}, state, { skyslopeDialog: false });

    case DealViewActionType.SHOW_INVITE_DIALOG:
      return Object.assign({}, state, { inviteDialog: true });

    case DealViewActionType.HIDE_INVITE_DIALOG:
      return Object.assign({}, state, { inviteDialog: false });

    case DealViewActionType.SET_ADDED_INTEGRATIONS:
      return Object.assign({}, state, {
        addedIntegrations: action.integrations,
      });

    case DealViewActionType.SET_FORM_FIELDS:
      return Object.assign({}, state, {
        formFields: action.formFields,
      });
    case DealViewActionType.SET_EDIT_CONTACTS:
      return Object.assign({}, state, {
        editContacts: action.editContacts,
      });

    case DealViewActionType.UNLINK_DOTLOOP:
      return Object.assign({}, state, {
        deal: Object.assign({}, state.deal, {
          dotloopProfileId: null,
          dotloopLoopId: null,
          dotloopLoopUrl: null,
        }),
      });
    case DealViewActionType.UNLINK_DOCUSIGN_ROOM:
      return Object.assign({}, state, {
        deal: Object.assign({}, state.deal, {
          docusignRoomId: null,
          docusignRoomUrl: null,
        }),
      });
    case DealViewActionType.UNLINK_SKYSLOPE:
      return Object.assign({}, state, {
        deal: Object.assign({}, state.deal, {
          skyslopeListingId: null,
          skyslopeSaleId: null,
          skyslopeTransactionId: null,
        }),
      });
    default:
      return state;
  }
};
