import React from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material/";

interface AgentPickerInterface {
  agents: any;
  setAgents: any;
}

const AgentPicker = ({ agents, setAgents }: AgentPickerInterface) => {
  const toggleChecked = (event: any) => {
    const updatedListOfAngets = agents.map((agent: any) => {
      if (agent.name === event.target.name) {
        agent.checked = !agent.checked;
      }
      return agent;
    });
    setAgents(updatedListOfAngets);
  };

  return (
    <div>
      <h3>Agents:</h3>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          {agents.map((agent: any) => (
            <FormControlLabel
              key={agent.id}
              control={
                <Checkbox
                  checked={agent.checked}
                  onChange={toggleChecked}
                  name={`${agent.name}`}
                  color="primary"
                />
              }
              label={`${agent.name}`}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default AgentPicker;
