import React, { useState } from "react";

import { TextField, Typography, Stack, ClickAwayListener } from "@mui/material";
import { Box } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";

const InlineEdit = ({
  initialValue = "",
  saveEdit,
  id,
  multiline = false,
}: {
  id: string;
  initialValue?: string;
  saveEdit: any;
  loading?: boolean;
  multiline?: boolean;
}) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [mouseOver, setMouseOver] = useState(false);

  const handleClickaway = () => {
    saveEdit({ id, value });
    setEdit(false);
    setMouseOver(false);
  };

  return edit ? (
    <>
      <ClickAwayListener onClickAway={handleClickaway}>
        <TextField
          type="text"
          fullWidth
          autoFocus
          onFocus={(e) => {
            let val = e.target.value;
            e.target.value = "";
            return (e.target.value = val);
          }}
          multiline={multiline}
          value={value}
          InputProps={{
            endAdornment: (
              <Box
                sx={{
                  cursor: "pointer",
                }}
              >
                <DoneIcon fontSize="small" color="success" />
              </Box>
            ),
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
        />
      </ClickAwayListener>
    </>
  ) : (
    <Box
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
      sx={{
        border: mouseOver ? "1px solid #E8E2E1" : "none",
        borderRadius: "4px",
        cursor: mouseOver ? "text" : "default",
        padding: "5px",
      }}
    >
      <Typography variant="body1" onClick={() => setEdit(true)}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {initialValue}
          {mouseOver && <EditIcon color="primary" fontSize="small" />}
        </Stack>
      </Typography>
    </Box>
  );
};

export default InlineEdit;
