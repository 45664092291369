import React, { useState } from "react";
import { Box, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CalendarToday from "@mui/icons-material/CalendarToday";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { theme } from "../../App";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      margin: "0 0 3px",
    },
    timelineContent: {
      fontSize: "14px",
    },
    boldText: {
      fontWeight: 600,
    },
    movedToIcon: {
      color: theme.palette.secondary.main,
    },
    movedToIconBorder: {
      borderColor: theme.palette.secondary.main,
    },
    dateTimeIcon: {
      color: theme.palette.secondary.main,
    },
    dateTimeIconBorder: {
      borderColor: theme.palette.secondary.main,
    },
    todayIcon: {
      color: theme.palette.primary.main,
    },
    todayIconBorder: {
      borderColor: theme.palette.primary.main,
    },
    upcomingIcon: {
      color: theme.palette.electricIndigo.main,
    },
    upcomingIconBorder: {
      borderColor: theme.palette.electricIndigo.main,
    },
    itemDate: {
      margin: 0,
      color: theme.palette.grey["500"],
      fontSize: "12px",
    },
  })
);

const GET_TIMELINE = gql`
  query getDeals($id: ID!, $tableName: String!) {
    deal(id: $id) {
      id
      timelineNew {
        items {
          type
          createdAt
          createdBy {
            ... on Contact {
              id
              firstName
              name
              avatarUrl
            }
            ... on TeamMember {
              id
              name
              firstName
              avatarUrl
            }
          }
          name
          verb
          url
          allDay
        }
      }
    }
    getSystemFields(tableName: $tableName) {
      id
      type
      label
      settings {
        format
        includeTime
        options
      }
    }
  }
`;

interface TimelineListProps {
  dealId: string;
  todayRef?: any;
}

const TimelineList = ({ dealId, todayRef }: TimelineListProps) => {
  const classes = useStyles();
  const [timelineData, setTimelineData] = useState<Array<any>>([]);

  const onTimelineCompleted = (data: any) => {
    const timelineData = data.deal.timelineNew.items;
    const mungedTimelineData = timelineData.map((item: any, i: number) => {
      return {
        ...item,
        isBeforeToday: timelineData[i + 1]?.type === "today",
        isPast: item.createdAt ? moment(item.createdAt).isBefore() : null,
      };
    });
    setTimelineData(mungedTimelineData);
  };

  const renderDescription = (rowData: any, i: number) => {
    let formatted = "";
    if (rowData.createdAt) {
      formatted = rowData.allDay
        ? moment.utc(rowData.createdAt).format("MMM Do YYYY")
        : moment(rowData.createdAt).format("MMM Do YYYY, h:mm a");
    } else {
      // A date hasn't been assigned to this item
      formatted = "Upcoming";
    }

    switch (rowData.verb) {
      case "DateTime":
        return (
          <>
            {rowData.isBeforeToday && todayRef && <div ref={todayRef}></div>}
            <TimelineSeparator>
              <TimelineDot
                variant="outlined"
                className={
                  rowData.type === "today"
                    ? classes.todayIconBorder
                    : formatted === "Upcoming"
                    ? classes.upcomingIconBorder
                    : classes.dateTimeIconBorder
                }
              >
                {formatted === "Upcoming" ? (
                  <ScheduleIcon className={classes.upcomingIcon} />
                ) : rowData.type === "today" ? (
                  <CalendarToday className={classes.todayIcon} />
                ) : rowData.isPast ? (
                  <EventAvailableIcon className={classes.dateTimeIcon} />
                ) : (
                  <EventNoteIcon className={classes.dateTimeIcon} />
                )}
              </TimelineDot>
              {i !== timelineData.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              <p className={classes.description}>
                <span
                  className={classes.boldText}
                  style={{
                    color:
                      rowData.type === "today"
                        ? theme.palette.primary.main
                        : "inherit",
                  }}
                >
                  {rowData.name}
                </span>
              </p>
              <p
                className={classes.itemDate}
                style={{
                  color:
                    rowData.type === "today"
                      ? theme.palette.primary.main
                      : "inherit",
                }}
              >
                {formatted}
              </p>
            </TimelineContent>
          </>
        );
      case "Moved to":
        return (
          <>
            <TimelineSeparator>
              <TimelineDot
                variant="outlined"
                className={classes.movedToIconBorder}
              >
                <ArrowForwardIcon className={classes.movedToIcon} />
              </TimelineDot>
              {i !== timelineData.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              <p className={classes.description}>
                <span className={classes.boldText}>Moved to:</span>{" "}
                {rowData.name}
              </p>
              <p className={classes.itemDate}>
                {!rowData.allDay
                  ? moment(rowData.createdAt).format("MMM Do YYYY, h:mm a")
                  : moment(rowData.createdAt).format("MMM Do YYYY")}
              </p>
            </TimelineContent>
          </>
        );
      default:
        return (
          <>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              {i !== timelineData.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              <p className={classes.description}>{rowData.name}</p>
              <p className={classes.itemDate}>
                {!rowData.allDay
                  ? moment(rowData.createdAt).format("MMM Do YYYY, h:mm a")
                  : moment(rowData.createdAt).format("MMM Do YYYY")}
              </p>
            </TimelineContent>
          </>
        );
    }
  };

  useQuery(GET_TIMELINE, {
    onCompleted: onTimelineCompleted,
    variables: {
      id: dealId,
      tableName: "deal",
    },
  });

  if (timelineData.length === 0) {
    return (
      <Box p={2} style={{ display: "flex", justifyContent: "center", flex: 1 }}>
        <Typography color="textSecondary">
          There are no items to display on your timeline
        </Typography>
      </Box>
    );
  }

  return (
    <Timeline position="alternate">
      {timelineData.map((item: any, i: number) => (
        <TimelineItem
          key={i}
          style={{
            opacity: item.isPast && item.type !== "today" ? "35%" : "100%",
          }}
        >
          {renderDescription(item, i)}
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default TimelineList;
