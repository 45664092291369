import { useCallback, useContext } from "react";
import { Box, TextField } from "@mui/material";
import { useMutation } from "@apollo/client";
import { UPDATE_DEAL_TYPE_FIELD_FAVORITE } from "../api/graphql";

import CustomField from "../components/fields/CustomField";
import SourceField from "../components/fields/Source";
import DealData from "../components/deals/DealData";
import moment, { Moment } from "moment";
import { Deal, Field, Mls } from "../models";
import { dealViewStore, DealViewActionType } from "../pages/deals/view/context";
import { createDateTime } from "../pages/deals/view/tabs/details/DealDetailCard";
import { format24HourTime } from "../helpers";

type FieldProps = {
  id: string;
  label: string;
  type: string;
  settings?: any;
  isCustom: boolean;
  favoriteSort?: number;
};

export const useDealDetails = (
  deal: Deal,
  mlses: Mls[],
  classes: any,
  editing: boolean,
  isLeftPane: boolean,
  setFavoriteFields?: (list: any) => void,
  favoriteFields?: Field[]
) => {
  const { dispatch } = useContext(dealViewStore);

  const handleFieldChange = useCallback(
    (field: FieldProps, value: any) => {
      const isCustomField = field.isCustom;
      if (field.type === "datetime" && !isCustomField) {
        if (typeof value === "object" && value !== null) {
          const updatedDeal = createDateTime(field, value, deal, isCustomField);
          return dispatch({
            type: DealViewActionType.SET_DEAL,
            deal: {
              ...updatedDeal.deal,
              ...updatedDeal.updates,
            },
          });
        } else {
          console.warn("Invalid value for datetime field");
        }
      } else if (field.type === "address") {
        const addressType = field.label.includes("Closing")
          ? "closingAddress"
          : "address";
        dispatch({
          type: DealViewActionType.SET_DEAL,
          deal: {
            ...deal,
            [addressType]: { ...deal[addressType], [field.id]: value },
          },
        });
      } else if (field.isCustom) {
        let customFieldsUpdate = deal.customFields;
        if (
          deal?.customFields?.find((cfv: any) => cfv.customFieldId === field.id)
        ) {
          customFieldsUpdate = deal.customFields.map((cfv: any) => {
            if (cfv.customFieldId === field.id) {
              if (field.type === "datetime") {
                if (field.settings.includeTime) {
                  cfv.valueText = value.date;
                  cfv.valueDateTime = value.dateTime;
                } else {
                  cfv.valueText = value.date;
                }
              } else if (moment.isMoment(value)) {
                cfv.valueText = (value as Moment).toISOString();
              } else {
                cfv.valueText = value;
              }
            }
            return cfv;
          });
        } else {
          if (field.type === "datetime") {
            let cfv: any = {};
            const { date, dateTime } = value;
            cfv.customFieldId = field.id;
            cfv.valueText = date;
            cfv.valueDateTime = dateTime;

            customFieldsUpdate?.push(cfv);
          } else {
            let cfv: any = {};
            cfv.customFieldId = field.id;
            cfv.valueText = value;
            customFieldsUpdate?.push(cfv);
          }
        }
        dispatch({
          type: DealViewActionType.SET_DEAL,
          deal: { ...deal, customFields: customFieldsUpdate },
        });
      } else {
        dispatch({
          type: DealViewActionType.SET_DEAL,
          deal: { ...deal, [field.id]: value },
        });
      }
    },
    [deal, dispatch]
  );

  const getFieldValue = useCallback(
    (field: FieldProps) => {
      if (!field.isCustom) {
        if (field?.type === "datetime") {
          let dateTime: string | null = null;
          if (
            field.id.endsWith("Date") &&
            (field.settings?.includeTime || field.settings?.eventType)
          ) {
            const timeKey = field.id.replace("Date", "At");
            dateTime = deal[timeKey] ?? null;
          }
          const value: { date: string | null; dateTime: string | null } = {
            dateTime,
            date: deal[field.id],
          };
          return value;
        } else {
          if (deal && "id" in field) {
            return deal[field.id];
          }
        }
      } else {
        const cfv = deal?.customFields?.find(
          (cfv: any) => cfv.customFieldId === field.id
        );
        if (field?.type === "datetime") {
          if (field.settings.includeTime) {
            const valueCheck = cfv?.valueDateTime ?? cfv?.valueText;

            const value = {
              date: valueCheck,
              dateTime: valueCheck,
            };
            return value;
          } else {
            return {
              date: cfv?.valueText?.split("T")[0],
              dateTime: null,
            };
          }
        } else {
          return cfv?.valueText;
        }
      }
    },
    [deal]
  );

  const formatAddress = useCallback(
    (value: FieldProps) => {
      if (!deal) {
        return;
      } else {
        const addressType = value.label.includes("Closing")
          ? "closingAddress"
          : "address";
        if (deal[addressType]) {
          return deal[addressType][value.id];
        }
      }
    },
    [deal]
  );
  const formatNumber = (value: number, format: string) => {
    if (value == null) {
      return "-";
    }

    switch (format) {
      case "usd":
        const currencyFormatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return currencyFormatter.format(value);
      case "percentage":
        return `${value}%`;
      default:
        return new Intl.NumberFormat("en-US").format(value);
    }
  };

  const formatDate = (value: any, includeTime: boolean) => {
    if (!value) {
      return null;
    }
    if (
      typeof value === "object" &&
      (value.date !== undefined || value.dateTime !== undefined)
    ) {
      let m: Moment | null = null;
      let format = "ll";
      if (value.dateTime) {
        m = moment(value.dateTime);
        format = "lll";
      } else if (value.date) {
        m = moment(value.date);
      } else {
        return null;
      }
      return m?.isValid() ? m.format(format) : null;
    } else {
      const m = moment(value, "YYYY-MM-DD");
      if (!m.isValid()) {
        return null;
      }
      return m.format(includeTime ? "lll" : "ll");
    }
  };

  const formatField = useCallback(
    (field: FieldProps) => {
      const fieldValue = getFieldValue(field);
      if (field.id === "mlsKey") {
        return mlses?.find((mls) => mls.mlsKey === fieldValue)?.name ?? null;
      } else if (field.type === "address") {
        return formatAddress(field);
      } else if (field.type === "number") {
        return formatNumber(fieldValue, field.settings.format);
      } else if (field.type === "datetime") {
        return formatDate(fieldValue, field.settings.includeTime);
      } else if (field.type === "time") {
        return format24HourTime(fieldValue);
      } else {
        return fieldValue;
      }
    },
    [getFieldValue, formatAddress, mlses]
  );

  const [updateDealTypeFieldFavorite] = useMutation(
    UPDATE_DEAL_TYPE_FIELD_FAVORITE
  );

  const renderField = useCallback(
    (field: FieldProps) => {
      if (!editing) {
        const handleRemoveFavorite = (e: React.ChangeEvent, label: string) => {
          const fieldIds = favoriteFields
            ?.filter((field) => field.label !== label)
            .map((field) => field.id);
          const input = {
            dealTypeId: deal.dealType?.id,
            fieldIds,
          };
          updateDealTypeFieldFavorite({
            variables: {
              input,
            },
            optimisticResponse: {
              __typename: "Mutation",
              updateDealTypeFieldFavorite: {
                __typename: "DealType",
                id: deal.dealType?.id,
                favoriteFields: favoriteFields?.filter(
                  (field) => field.label !== label
                ),
              },
            },
          }).then(() => {
            setFavoriteFields &&
              setFavoriteFields((prevState: any) => {
                const newFavoriteFields = prevState.filter(
                  (item: any) => item.id !== field.id
                );
                return newFavoriteFields;
              });
          });
        };
        return (
          <DealData
            data-id={`field${field.id}`}
            key={field.id}
            label={field.label}
            value={formatField(field)}
            className={classes.detailItem + " deal-data"}
            isLeftPane={isLeftPane}
            handleRemoveFavorite={handleRemoveFavorite}
          />
        );
      } else {
        if (field.type === "address") {
          const addressType = field.label.includes("Closing")
            ? "closingAddress"
            : "address";
          return (
            <Box className={classes.detailItem} key={field.id}>
              <TextField
                variant="standard"
                fullWidth
                value={deal[addressType] ? deal[addressType][field.id] : ""}
                label={field.label}
                onChange={(e) => handleFieldChange(field, e.target.value)}
              />
            </Box>
          );
        }
        if (field.id === "source") {
          return (
            <Box className={classes.detailItem} key={field.id}>
              <SourceField
                value={deal.source}
                onChange={(value) => handleFieldChange(field, value)}
              />
            </Box>
          );
        } else {
          return (
            <Box className={classes.detailItem} key={field.id}>
              <CustomField
                key={field.id}
                {...field}
                deal={deal}
                value={getFieldValue(field)}
                onChange={(value) => {
                  handleFieldChange(field, value);
                }}
              />
            </Box>
          );
        }
      }
    },
    [
      deal,
      formatField,
      classes,
      editing,
      handleFieldChange,
      getFieldValue,
      favoriteFields,
      isLeftPane,
      setFavoriteFields,
      updateDealTypeFieldFavorite,
    ]
  );

  return {
    renderField,
  };
};
