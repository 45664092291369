import React from "react";
import { List, ListItem, Theme, Divider, Paper } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { ExpandIcon } from "../../icons";
import moment from "moment";

const DealsList = withStyles({
  root: { padding: "0" },
})(List);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deal: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      padding: "26px 18px",
    },
    noDeal: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "11px",
      color: "#747b88",
    },
    propertyPhoto: {
      width: "90px",
      height: "60px",
      marginRight: "29px",
      display: "flex",
      alignItems: "center",
      alignContent: "center",
    },

    dealSummary: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      height: "auto",
    },
    dealHeader: {
      flexGrow: 1,
      fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      fontSize: "13px",
    },
    dealType: {
      color: theme.palette.grey[700],
    },
    dealAgentAndClosingDate: {
      flexGrow: 1,
      fontFamily: ["AvenirNext-Regular", "Avenir", "sans-serif"].join(","),
      fontSize: "12px",
      color: theme.palette.grey[700],
    },
    dealValueAndStage: {
      flexGrow: 1,
      fontFamily: ["AvenirNext-Regular", "Avenir", "sans-serif"].join(","),
      fontSize: "13px",
    },
    detailsLink: {
      display: "flex",
      alignItems: "center",
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "10px",
      color: theme.palette.grey[900],
      textDecoration: "none",
    },
  })
);

export default function DealList(props: any) {
  const classes = useStyles();

  function noDealsToDisplay() {
    if (!props.deals || props.deals.length === 0) {
      return <p className={classes.noDeal}>No deals to display</p>;
    } else {
      return undefined;
    }
  }

  function dealHeader(deal: any) {
    return (
      <span className={classes.dealType}>
        <span>{deal.dealType.name}</span>
        {deal.name && <span>&nbsp;-&nbsp;{deal.name}</span>}
      </span>
    );
  }

  function agentAndClosingDate(deal: any) {
    let parts = [];
    if (deal.owner) {
      parts.push(deal.owner.name);
    }

    if (deal.closeDate) {
      parts.push(moment(deal.closeDate, "YYYY-MM-DD").format("LL"));
    } else {
      parts.push("No closing date");
    }

    return <>{parts.join(" - ")}</>;
  }

  function dealValueAndStage(deal: any) {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    const formattedValue = currencyFormatter.format(deal.value);

    if (deal.stage) {
      return (
        <>
          {formattedValue} ({deal.stage.name})
        </>
      );
    } else {
      return <>{formattedValue}</>;
    }
  }

  return (
    noDealsToDisplay() || (
      <DealsList>
        {props.deals.map((deal: any, index: number) => (
          <div key={deal.id}>
            {index > 0 ? <Divider key={`${deal.id}-divider`} /> : <></>}
            <ListItem
              key={deal.id}
              component={Paper}
              square
              style={{ border: "none", boxShadow: "none" }}
            >
              <div className={classes.deal}>
                <div className={classes.propertyPhoto}>
                  {deal.photoUrl ? (
                    <img
                      src={deal.photoUrl}
                      alt="Property"
                      width="90"
                      height="60"
                    />
                  ) : (
                    <img
                      src="/images/deal-placeholder-photo.png"
                      alt="Property"
                      width="90"
                      height="60"
                    />
                  )}
                </div>
                <div className={classes.dealSummary}>
                  <div className={classes.dealHeader}>{dealHeader(deal)}</div>
                  <div className={classes.dealAgentAndClosingDate}>
                    {agentAndClosingDate(deal)}
                  </div>
                  <div className={classes.dealValueAndStage}>
                    {dealValueAndStage(deal)}
                  </div>
                </div>
                <Link to={`/deals/${deal.id}`} className={classes.detailsLink}>
                  <ExpandIcon
                    style={{
                      marginTop: "-2px",
                      marginRight: "5px",
                      fontSize: "16px",
                    }}
                  />
                  View Details
                </Link>
              </div>
            </ListItem>
          </div>
        ))}
      </DealsList>
    )
  );
}
