import moment, { Moment } from "moment";

export const getDefaultValue = (field: { id: string; type: string }): any => {
  if (field.id === "mlsKey") return null;

  switch (field.type) {
    case "address":
      return {
        street: "",
        street2: "",
        city: "",
        state: "",
        zip: "",
      };
    case "number":
      return null;
    case "datetime":
      return null;
    default:
      return "";
  }
};

export const getCustomFieldValue = (
  field: { type: string },
  customFieldValue: any
) => {
  let value: any;
  switch (field.type) {
    case "datetime":
      value = moment(
        customFieldValue.valueDateTime ?? customFieldValue.valueText
      );

      break;
    case "number":
      value = Number.parseFloat(customFieldValue.valueText);
      if (isNaN(value)) {
        value = null;
      }
      break;
    default:
      value = customFieldValue.valueText;
      break;
  }
  return value;
};

export const cleanCustomFieldValuesForInput = (customFieldValues: any[]) => {
  return customFieldValues
    .filter((cfv: any) => cfv.value !== null && cfv.value !== undefined)
    .map((cfv: any) => {
      // updateDeal expects all the custom field values be converted to a string
      let value: string;
      if (moment.isMoment(cfv.value)) {
        value = (cfv.value as Moment).toISOString();
      } else if (cfv.value instanceof Date) {
        value = cfv.value.toISOString();
      } else if (typeof cfv.value === "object") {
        // value should currently only be a string, number, or date/moment object
        // so this is just some future proofing for more complex types.
        if ("dateTime" in cfv.value) {
          value = cfv.value.dateTime ? cfv.value.dateTime : "";
        } else {
          value = JSON.stringify(cfv.value);
        }
      } else {
        value = cfv.value.toString();
      }
      return {
        id: cfv.id,
        customFieldId: cfv.customFieldId,
        value,
      };
    });
};

export const cleanFields = (form: any): any => {
  const clean = Object.assign({}, form);

  if (clean.contacts) {
    clean.contacts = clean.contacts.map((c: any) => ({ id: c.id }));
  }

  if (clean.socialProfiles) {
    Object.keys(clean.socialProfiles).forEach((profile) => {
      if (clean.socialProfiles[profile] === "") {
        clean.socialProfiles[profile] = null;
      }
    });
  }

  if (clean.linkedContact || clean.linkedContact === null) {
    clean.linkedContactId = clean.linkedContact?.id || null;
    delete clean.linkedContact;
  }

  if (clean.mlsKey === "") {
    clean.mlsKey = null;
  }

  if (clean.customFields) {
    clean.customFields = cleanCustomFieldValuesForInput(clean.customFields);
  }

  Object.keys(clean).forEach((key: string) => {
    const value: any = clean[key];
    if (moment.isMoment(value)) {
      clean[key] = (value as Moment).toISOString();
    }
  });

  return clean;
};

export const cleanDateValues = (formData: any) => {
  const cleanFormData = { ...formData };
  Object.keys(cleanFormData).forEach((key: string) => {
    const value: any = cleanFormData[key];
    if (moment.isMoment(value)) {
      cleanFormData[key] = (value as Moment).toISOString();
    }
  });

  return cleanFormData;
};

export const cleanCustomFields = (formData: any) => {
  const customFields = cleanCustomFieldValuesForInput(formData.customFields);
  return { ...formData, customFields };
};

const fieldHelpers = {
  getDefaultValue,
  getCustomFieldValue,
  cleanFields,
};
export default fieldHelpers;
