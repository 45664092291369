import React, { cloneElement, useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { REPORTS } from "../../reducers/reports";
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import AgentPicker from "./AgentPicker";
import DatePicker from "./DatePicker";
import { OutlineButton, PrimaryButton } from "../buttons";

type ReportsFilterProps = {
  children: any;
  setDeals: any;
  reports: any;
  setDates: any;
  setAgents: any;
  setFilter: (filter: any) => any;
};

const ReportsFilter = ({
  children,
  reports,
  setDeals,
  setDates,
  setAgents,
  setFilter,
}: ReportsFilterProps) => {
  const { allDeals } = reports;
  const [open, setOpen] = useState<boolean>(false);
  const [listOfAgents, setListOfAgents] = useState([]);
  const [datesToFilter, setDatesToFilter] = useState(reports.dateRange);
  const [includePending, setIncludePending] = useState(
    reports.filter.includePending
  );
  useEffect(() => {
    setListOfAgents(
      reports.agents.filter((tm: any) => tm.user !== null && !tm.preferred)
    );
    setDatesToFilter(reports.dateRange);
    setIncludePending(reports.filter.includePending);
  }, [reports.agents, reports.dateRange, reports.filter]);

  const handleClose = () => {
    setOpen(false);
  };

  const filterDeals = () => {
    const filteredDeals = allDeals.map((deal: any) => {
      listOfAgents.map((agent: any) => {
        if (agent.checked === false && agent.id === deal.agentId) {
          deal.checked = false;
        }
        return deal;
      });
      return deal;
    });
    setDeals(filteredDeals);
  };

  const handleFilter = () => {
    setAgents(listOfAgents);
    setDates(datesToFilter);
    setFilter({ includePending });
    filterDeals();
    setOpen(false);
  };

  return (
    <>
      {cloneElement(children as any, {
        onClick: (e) => {
          e.stopPropagation();
          setOpen(true);
        },
      })}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <h1>Filter Report</h1>
          <p>
            Date Range Selected:{" "}
            <span
              style={{
                fontSize: "15px",
                fontWeight: "bolder",
              }}
            >
              {moment(datesToFilter.from).format("LL")} -{" "}
              {moment(datesToFilter.to).format("LL")}
            </span>
          </p>
          <Grid container>
            <Grid item md={7}>
              <DatePicker range={datesToFilter} setDates={setDatesToFilter} />
            </Grid>
            <Grid item md={5}>
              <AgentPicker agents={listOfAgents} setAgents={setListOfAgents} />
              <h3>Deals</h3>
              <FormControlLabel
                value={includePending}
                onChange={(e: any) => {
                  setIncludePending(e.target.checked);
                }}
                control={<Checkbox color="primary" checked={includePending} />}
                label="Include pending deals"
              />
            </Grid>
          </Grid>

          <DialogActions style={{ padding: "35px 8px 8px" }}>
            <OutlineButton onClick={handleClose}>Cancel</OutlineButton>
            <PrimaryButton onClick={handleFilter}>Apply</PrimaryButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setDeals: (deals: any) => {
      return dispatch({ type: REPORTS.SET_DEALS, deals });
    },
    setDates: (dateRange: any) => {
      return dispatch({ type: REPORTS.SET_DATES, dateRange });
    },
    setAgents: (agents: any) => {
      return dispatch({ type: REPORTS.SET_AGENTS, agents });
    },
    setFilter: (filter: any) => {
      return dispatch({ type: REPORTS.SET_FILTER, filter });
    },
  };
};

export default connect(null, mapDispatchToProps)(ReportsFilter);
