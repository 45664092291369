import React from "react";
import { Avatar, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Box } from "@mui/system";

const defaultAvatarStyle: React.CSSProperties = {
  height: "40px",
  width: "40px",
};

type ContactNameWithAvatarProps = {
  name: string;
  title?: string;
  right?: React.ReactNode;
  avatarUrls: Array<string | undefined>;
  avatarStyle?: typeof defaultAvatarStyle;
  hideName?: boolean;
  role?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
    nameWrapper: {
      display: "flex",
      flexDirection: "column",
      whiteSpace: "nowrap",
    },
    title: {
      color: theme.palette.grey[500],
    },
    avatarWrapper: {
      marginRight: theme.spacing(1),
    },
  })
);

const ContactNameWithAvatar = React.forwardRef<
  HTMLElement,
  ContactNameWithAvatarProps
>(
  (
    {
      name,
      title,
      right,
      avatarUrls,
      avatarStyle = defaultAvatarStyle,
      hideName,
      role,
    }: ContactNameWithAvatarProps,
    ref
  ) => {
    const classes = useStyles();
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span ref={ref} className={classes.wrapper}>
          <AvatarGroup max={4} className={classes.avatarWrapper}>
            {avatarUrls
              .filter((url) => url !== undefined)
              .map((url, index) => {
                return (
                  <Avatar
                    key={index}
                    style={avatarStyle}
                    component="span"
                    alt={name}
                    title={name}
                    src={url}
                  />
                );
              })}
          </AvatarGroup>
          {!hideName && (
            <div className={classes.nameWrapper}>
              <span>{name}</span>
              {role && <span className={classes.title}>{role}</span>}
              {title && <span className={classes.title}>{title}</span>}
            </div>
          )}
        </span>
        {right}
      </Box>
    );
  }
);

export default ContactNameWithAvatar;
