import React, { useState, useCallback, useEffect } from "react";

import { TeamMember, Deal } from "../models";
import { useLazyQuery } from "@apollo/client";
import { GET_TEAM_MEMBERS } from "../api/graphql";

export const useCombineContactAndTeamMembers = (
  deal?: Deal,
  teamMembersProp?: TeamMember[]
) => {
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const dealTeamMembers = React.useMemo(
    () => deal?.dealTeamMembers || [],
    [deal]
  );
  const dealContacts = React.useMemo(() => deal?.contacts ?? [], [deal]);
  const handleGetTeamMembers = useCallback((res: any) => {
    setTeamMembers(res.getTeamMembers);
  }, []);
  const [getTeamMembers] = useLazyQuery(GET_TEAM_MEMBERS, {
    onCompleted: handleGetTeamMembers,
  });

  useEffect(() => {
    if (teamMembersProp) {
      setTeamMembers(teamMembersProp);
    } else {
      getTeamMembers();
    }
  }, [teamMembersProp, getTeamMembers]);

  const combinedOptions = React.useMemo(() => {
    if (teamMembers.length === 0) {
      return;
    }
    if (!deal) {
      return teamMembers;
    }
    if (teamMembers && dealTeamMembers && dealContacts) {
      teamMembers.map((teamMember: any) => {
        let matched = dealTeamMembers.find(
          (dealMember: any) => teamMember.id === dealMember.teamMember.id
        );
        // remove the team member from teamMembers to avoid duplicates
        if (matched?.role?.name) {
          const index = teamMembers.findIndex(
            (tm: any) => tm.id === matched?.teamMember?.id
          );
          teamMembers.splice(index, 1);
        }
        return teamMembers;
      });
      // formatting Deal Team Members to add role
      const formattedTM = dealTeamMembers.map((tm: any) => ({
        ...tm.teamMember,
        role: tm.role.name,
      }));
      return [...dealContacts, ...formattedTM, ...teamMembers];
    }
  }, [teamMembers, dealTeamMembers, dealContacts, deal]);

  return {
    combinedOptions,
  };
};
