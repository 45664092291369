import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { toolbar } from "../helpers/quill";
import "quill-mention";
import { Skeleton } from "@mui/material";
import mixpanel from "../mixpanel";

interface EditorContent {
  html: string;
  text: string;
}

type EditorProps = {
  placeholder?: string;
  value?: string;
  onChange: (content: EditorContent) => any | undefined;
  mergeFields?: any[];
  autoFocus?: boolean;
};

const TrackEditorOpen = () => {
  useEffect(() => {
    mixpanel.track("Editor Opened");
  }, []);
  return null;
};

const Editor: FunctionComponent<EditorProps> = ({
  placeholder,
  onChange,
  value: _value = "",
  mergeFields = [],
  autoFocus = false,
}) => {
  const quill = useRef<ReactQuill | null>(null);
  const [modules, setModules] = useState<any | undefined>(undefined);

  /**
   * Quill modules
   */

  useEffect(() => {
    setModules(() => {
      const modules: any = { toolbar };
      if (mergeFields.length > 0) {
        modules.mention = {
          allowedChars: /^[a-zA-Z0-9_. ]*$/,
          showDenotationChar: false,
          spaceAfterInsert: false,
          mentionDenotationChars: ["{{"],
          source: (searchTerm: any, renderList: any, mentionChar: any) => {
            if (searchTerm.length === 0) {
              renderList(
                mergeFields?.map((field) => ({
                  id: field.id,
                  value: field.placeholder,
                })) ?? [],
                searchTerm
              );
            }
            const matches =
              mergeFields
                ?.filter(
                  (field) =>
                    field.value != null &&
                    field.value !== "" &&
                    field.placeholder
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                )
                .map((field) => ({
                  id: field.id,
                  value: field.placeholder,
                })) ?? [];

            renderList(matches, searchTerm);
          },
          onSelect: (item: any, insertItem: any) => {
            const mergeField = mergeFields?.find(
              (field) => field.id === item.id
            );
            insertItem({ id: item.id, value: mergeField?.value });
          },
        };
      }
      return modules;
    });
  }, [mergeFields, setModules]);

  const handleChange = (html: string) => {
    if (quill.current) {
      const editor = quill.current.getEditor();
      const text = editor.getText();
      if (onChange) {
        onChange({
          html,
          text,
        });
      }
    }
  };

  useEffect(() => {
    if (autoFocus && quill.current) {
      quill.current.getEditor().focus();
    }
  }, [autoFocus]);

  if (!modules) {
    return <Skeleton />;
  }
  return (
    <>
      <TrackEditorOpen />
      <ReactQuill
        placeholder={placeholder}
        ref={(e) => {
          quill.current = e;
        }}
        onChange={handleChange}
        value={_value}
        modules={modules}
      ></ReactQuill>
    </>
  );
};
export default Editor;
