import React, { FunctionComponent, useState } from "react";
import { PrimaryButton, SecondaryButton } from "../buttons";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Link as RouterLink } from "react-router-dom";
import {
  FormControl,
  Grid,
  InputLabel,
  Link,
  Select,
  Typography,
} from "@mui/material";
import { useAlert } from "../../context/alert";

type ContactBatchEnrollIntoWorkflowButtonProps = {
  open: boolean;
  selectedContacts: any;
  onEnrolled?: () => void;
  onClose: () => void;
};

export const ContactBatchEnrollIntoWorkflowButton: FunctionComponent<
  ContactBatchEnrollIntoWorkflowButtonProps
> = ({ selectedContacts, open, onClose, onEnrolled }) => {
  const { showSuccess, showError } = useAlert();
  const [workflowId, setWorkflowId] = useState<string>("");

  const { data, loading } = useQuery(gql`
    query GetWorkflows {
      getWorkflows {
        id
        name
      }
    }
  `);

  const [batchEnrollContactsInWorkflow, { loading: enrolling }] =
    useMutation(gql`
      mutation BatchEnrollContactsInWorkflow(
        $input: BatchEnrollContactsInWorkflowInput!
      ) {
        batchEnrollContactsInWorkflow(input: $input)
      }
    `);

  const handleEnrollment = () => {
    const input = {
      contacts: Object.keys(selectedContacts),
      workflowId,
    };

    batchEnrollContactsInWorkflow({ variables: { input } })
      .then(() => {
        showSuccess("Contacts were enrolled into the workflow successfully");
        if (onEnrolled) {
          onEnrolled();
        }
      })
      .catch(() => {
        showError(
          "An error occurred when enrolling the contacts into the workflow"
        );
      });
  };

  if (!data) {
    return <></>;
  }

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">
          Enroll {Object.keys(selectedContacts).length}{" "}
          {Object.keys(selectedContacts).length === 1 ? "contact" : "contacts"}{" "}
          into a workflow?
        </DialogTitle>
        <DialogContent dividers>
          {data.getWorkflows?.length === 0 && (
            <Typography>
              <strong>You don't have any workflows</strong>
              <br />
              Workflows allow you to automate tasks and emails for leads, and
              past clients.
              <p>
                To get started you'll need to{" "}
                <Link
                  to="/settings/workflows/new"
                  component={RouterLink}
                  color="primary"
                >
                  Create a Workflow
                </Link>
              </p>
            </Typography>
          )}
          {data.getWorkflows.length > 0 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography>
                  Selected contacts will be enrolled into the workflow below.
                  Contacts can only go thorugh a particular workflow once.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="workflow">Select a Workflow</InputLabel>
                  <Select
                    native
                    value={workflowId}
                    onChange={(e) => setWorkflowId(e.target.value as string)}
                    name="workflow"
                    inputProps={{
                      id: "workflow",
                    }}
                  >
                    <option value=""></option>
                    {data.getWorkflows.map((workflow: any) => (
                      <option key={workflow.id} value={workflow.id}>
                        {workflow.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            autoFocus
            onClick={() => onClose()}
            disabled={enrolling}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={handleEnrollment}
            disabled={loading || !workflowId || enrolling}
          >
            Enroll in Workflow
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
