import React, { useState, useReducer, useCallback, useEffect } from "react";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { connect } from "react-redux";
import TeamLayout from "../../layouts/TeamLayout";
import ContactList from "../../components/contacts/ContactTable";
import ContactTableActions from "../../components/contacts/ContactTableActions";
import ContactImport from "../../components/contacts/ContactImport";
import { Theme, Box, Chip, Avatar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { useNavigate } from "react-router-dom";
import DetailCard from "../../components/DetailCard";
import { PrimaryButton } from "../../components/buttons";
import {
  FileUploadIcon,
  UserAddIcon as PersonAddIcon,
  FilterIcon,
} from "../../icons";
import SearchField from "../../components/SearchField";
import ContactTableActionsDialog from "../../components/contacts/ContactTableActionsDialog";
import ContactCreateDialog from "../../components/contacts/CreateDialog";
import { Tag } from "../../models";
import { useAlert } from "../../context/alert";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";
import { GET_TEAM_MEMBERS } from "../../api/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnText: {
      fontWeight: 500,
    },
    btn: {
      marginRight: "16px",
      maxWidth: "128px",
      [theme.breakpoints.down("md")]: {
        marginRight: "5px",
      },
    },
    importBtn: {
      marginRight: "16px",
      maxWidth: "128px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    hideText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    searchBar: {
      position: "relative",
      boxSizing: "content-box",
      maxWidth: "300px",
      width: "100%",
      [theme.breakpoints.down(426)]: {
        maxWidth: "175px",
      },
      "& svg.MuiSvgIcon-root": {
        left: "90%",
      },
    },
    searchTagContainer: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    supportLinkContainer: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      display: "flex",
      alignItems: "flex-end",
    },
  })
);

const FilterChip = withStyles((theme: Theme) => ({
  root: {
    margin: "0 5px",
  },
}))(Chip);

export interface ContactSearchParams {
  term?: string;
  tags: Tag[];
}

export interface ContactQueryFilter {
  customFieldId?: string;
  columnName?: string;
  values?: string[];
  between?: Date[];
  tagId?: string;
}

export interface ContactQueryParams {
  orderBy?: string;
  orderDirection?: string;
  page?: number;
  pageSize?: number;
  term?: string;
  ownerId?: string;
  asIndividuals?: boolean;
  filters?: ContactQueryFilter[];
}

export const makeContactQueryParams = (
  search?: ContactSearchParams,
  searchParams?: ContactSearchParams,
  filters?: ContactQueryFilter[]
): ContactQueryParams => {
  const params: any = {
    term: searchParams?.term,
    filters: [
      ...(filters ?? []),
      ...(search?.tags?.map((tag) => ({ tagId: tag.id })) ?? []),
    ],
    ...searchParams,
  };
  return params;
};

function ContactsList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { showSuccess } = useAlert();

  const { parsedParams: searchParams, setParams } = useCustomSearchParams(
    {},
    true
  );
  const [selectedTeamMember, setSelectedTeamMember] = useState<
    { name: string; avatarUrl: string } | undefined
  >(undefined);

  const [getTeamMembers] = useLazyQuery(GET_TEAM_MEMBERS);

  useEffect(() => {
    let teamMate;
    if (selectedTeamMember) {
      return;
    }
    getTeamMembers().then((res) => {
      teamMate = res.data.getTeamMembers.filter(
        (teamMember: { id: string }) => teamMember.id === searchParams.ownerId
      );
      setSelectedTeamMember(teamMate[0]);
    });
  }, [searchParams?.ownerId, selectedTeamMember, getTeamMembers]);

  const [search, setSearch] = useState<ContactSearchParams>({
    tags: [],
  });

  const initialDateState = {
    anniversaryDates: {
      from: null,
      to: null,
      label: "",
      filterOn: false,
      renderTitle: "Anniversaries",
    },
    birthdayDates: {
      from: null,
      to: null,
      label: "",
      filterOn: false,
      renderTitle: "Birthdays",
    },
  };

  const dateReducer = (state: any, action: any) => {
    switch (action.value) {
      case "today":
        return {
          ...state,
          [action.category]: {
            ...state[action.category],
            label: "today",
            from: moment().toDate(),
            to: moment().toDate(),
            filterOn: true,
          },
        };
      case "thisMonth":
        return {
          ...state,
          [action.category]: {
            ...state[action.category],
            label: "thisMonth",
            from: moment().startOf("month").toDate(),
            to: moment().endOf("month").toDate(),
            filterOn: true,
          },
        };
      case "nextMonth":
        return {
          ...state,
          [action.category]: {
            ...state[action.category],
            label: "nextMonth",
            from: moment().add(1, "M").startOf("month").toDate(),
            to: moment().add(1, "M").endOf("month").toDate(),
            filterOn: true,
          },
        };
      case "customRange":
        if (action.dates?.from) {
          return {
            ...state,
            [action.category]: {
              ...state[action.category],
              label: "customRange",
              from: moment(action.dates.from).toDate(),
              to: state[action.category].to,
              filterOn: true,
            },
          };
        } else if (action.dates?.to) {
          return {
            ...state,
            [action.category]: {
              ...state[action.category],
              label: "customRange",
              from: state[action.category].from,
              to: moment(action.dates.to).toDate(),
              filterOn: true,
            },
          };
        } else {
          return {
            ...state,
            [action.category]: {
              ...state[action.category],
              label: "customRange",
              from: state[action.category].from,
              to: state[action.category].to,
              filterOn: true,
            },
          };
        }
      case "resetCategory":
        return {
          ...state,
          [action.category]: {
            ...state[action.category],
            label: "",
            from: null,
            to: null,
            filterOn: false,
          },
        };
      case "resetFilter":
        return initialDateState;
      default:
        return state;
    }
  };
  const [dateState, dateDispatch] = useReducer(dateReducer, initialDateState);
  const { birthdayDates, anniversaryDates } = dateState;
  const [zipcodes, setZipcodes] = useState<Array<any>>([]);
  const [selectedAgents, setSelectedAgents] = useState<Array<any>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<any>>([]);
  const [filters, setFilters] = useState<any>([]);
  const resetFilter = () => {
    dateDispatch({ value: "resetFilter" });
    setZipcodes([]);
    setSelectedAgents([]);
  };
  const handleFilter = useCallback(() => {
    const filtersForQuery = [];
    if (zipcodes.length > 0) {
      filtersForQuery.push({
        customFieldId: null,
        columnName: "zip",
        values: zipcodes,
      });
    }
    if (selectedAgents.length > 0) {
      const filteredAgents = selectedAgents.map((agent) => agent.id);
      filtersForQuery.push({
        customFieldId: null,
        columnName: "ownerId",
        values: filteredAgents,
      });
    }
    if (birthdayDates.from !== null && birthdayDates.to !== null) {
      filtersForQuery.push({
        customFieldId: null,
        columnName: "birthday",
        between: [birthdayDates.from, birthdayDates.to],
      });
    }
    if (anniversaryDates.from !== null && anniversaryDates.to !== null) {
      filtersForQuery.push({
        customFieldId: null,
        columnName: "homeAnniversaryDate",
        between: [anniversaryDates.from, anniversaryDates.to],
      });
    }
    setFilters(filtersForQuery);
  }, [
    anniversaryDates.from,
    anniversaryDates.to,
    birthdayDates.from,
    birthdayDates.to,
    selectedAgents,
    zipcodes,
  ]);

  const [selectedContacts, setSelectedContacts] = useState<{
    [key: string]: {
      checked: boolean;
      pageNumber: number;
    };
  }>({});
  const [totalContacts, setTotalContacts] = useState<number>(10);
  const [allContactsSelected, setAllContactsSelected] =
    useState<boolean>(false);

  const [openActionsDialog, setOpenActionsDialog] = useState<{
    open: boolean;
    action: string;
  }>({
    open: false,
    action: "",
  });

  const [addingContact, setAddingContact] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAction = () => {
    setSearch((search) => Object.assign({}, search));
  };

  useEffect(() => {
    setSearchTerm(searchParams.term);
  }, [searchParams.term]);

  return (
    <TeamLayout>
      <ContactTableActionsDialog
        setOpenActionsDialog={setOpenActionsDialog}
        openActionsDialog={openActionsDialog}
      />
      <DetailCard
        title={
          <>
            <SearchField
              className={classes.searchBar}
              setSearchTerm={setSearchTerm}
              inputProps={{
                value: searchTerm,
              }}
              onChange={(term: string) => {
                setParams({ term, page: "0" }); //update to page 0 fixes max rerender bug in prod when a page > 0 and searching
              }}
            />
            <div className={classes.searchTagContainer}>
              {selectedTeamMember && (
                <Chip
                  avatar={
                    <Avatar
                      alt={selectedTeamMember.name}
                      src={selectedTeamMember.avatarUrl}
                    />
                  }
                  label={selectedTeamMember.name}
                  onDelete={() => {
                    setSelectedTeamMember(undefined);
                    setParams({ ownerId: "" });
                  }}
                ></Chip>
              )}
            </div>
          </>
        }
        action={
          <>
            {Object.keys(selectedContacts).length > 0 ? (
              <ContactTableActions
                search={search}
                searchParams={searchParams}
                filters={filters}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                setAllContactsSelected={setAllContactsSelected}
                allContactsSelected={allContactsSelected}
                totalContacts={totalContacts}
                onAction={handleAction}
                openActionsDialog={openActionsDialog}
                setOpenActionsDialog={setOpenActionsDialog}
              />
            ) : (
              <div>
                <ContactImport
                  onImportCompleted={() =>
                    setSearch((search) => ({ ...search }))
                  }
                >
                  <PrimaryButton height="short" className={classes.importBtn}>
                    <FileUploadIcon
                      size="12px"
                      style={{ marginRight: "4px" }}
                    />
                    Import
                  </PrimaryButton>
                </ContactImport>

                <PrimaryButton
                  height="short"
                  className={classes.btn}
                  onClick={() => setAddingContact(true)}
                >
                  <PersonAddIcon size="12px" style={{ marginRight: "4px" }} />
                  <span className={classes.hideText}>New Contact</span>
                </PrimaryButton>

                <PrimaryButton
                  height="short"
                  className={classes.btn}
                  onClick={() => setFilterOpen(true)}
                >
                  <FilterIcon size="12px" style={{ marginRight: "4px" }} />
                  <span className={classes.hideText}>Filter</span>
                </PrimaryButton>
              </div>
            )}
          </>
        }
        secondarySection={
          <>
            {search.tags.map((tag, index) => (
              <FilterChip
                color="primary"
                variant="outlined"
                key={index}
                label={
                  <Typography variant="caption">
                    <b>Tag:</b> {tag.name}
                  </Typography>
                }
                onDelete={(e) => {
                  setSearch((search) =>
                    Object.assign({}, search, {
                      tags: search.tags.filter(
                        (searchTag) => tag.id !== searchTag.id
                      ),
                    })
                  );
                }}
              ></FilterChip>
            ))}
            {filters.length > 0 || search.tags.length > 0 ? (
              <>
                {anniversaryDates.filterOn && (
                  <FilterChip
                    label={
                      <Typography variant="caption">
                        <b>Anniversary Date Range:</b>{" "}
                        {moment(anniversaryDates.from).format("MMM Do YYYY")} -{" "}
                        {moment(anniversaryDates.to).format("MMM Do YYYY")}
                      </Typography>
                    }
                    onDelete={() => {
                      dateDispatch({
                        value: "resetCategory",
                        category: "anniversaryDates",
                      });
                    }}
                    color="primary"
                    variant="outlined"
                  />
                )}
                {birthdayDates.filterOn && (
                  <FilterChip
                    label={
                      <Typography variant="caption">
                        <b>Birthday Date Range:</b>{" "}
                        {moment(birthdayDates.from).format("MMM Do YYYY")} -{" "}
                        {moment(birthdayDates.to).format("MMM Do YYYY")}
                      </Typography>
                    }
                    onDelete={() => {
                      dateDispatch({
                        value: "resetCategory",
                        category: "birthdayDates",
                      });
                    }}
                    color="primary"
                    variant="outlined"
                  />
                )}
                {selectedAgents.length > 0 && (
                  <FilterChip
                    label={
                      <Typography variant="caption">
                        <b>Selected Agents:</b>{" "}
                        {selectedAgents.map((agent) => agent.name).join(", ")}
                      </Typography>
                    }
                    onDelete={() => {
                      setSelectedAgents([]);
                    }}
                    color="primary"
                    variant="outlined"
                  />
                )}
                {zipcodes.length > 0 && (
                  <FilterChip
                    label={
                      <Typography variant="caption">
                        <b>Zipcode:</b> {zipcodes.join(", ")}
                      </Typography>
                    }
                    onDelete={() => {
                      setZipcodes([]);
                    }}
                    color="primary"
                    variant="outlined"
                  />
                )}
                <FilterChip
                  label={
                    <Typography variant="caption">Reset All Filters</Typography>
                  }
                  onDelete={() => {
                    resetFilter();
                    setFilters([]);
                    setSelectedTeamMember(undefined);
                    setParams({ term: "", ownerId: "" });
                    setSearch({
                      tags: [],
                    });
                  }}
                  color="primary"
                />
              </>
            ) : (
              <></>
            )}
          </>
        }
      >
        <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <ContactList
            search={search}
            setSearch={setSearch}
            setParams={setParams}
            searchParams={searchParams}
            selectedContacts={selectedContacts}
            allContactsSelected={allContactsSelected}
            setSelectedContacts={setSelectedContacts}
            setTotalContacts={setTotalContacts}
            setAllContactsSelected={setAllContactsSelected}
            dateState={dateState}
            dateDispatch={dateDispatch}
            zipcodes={zipcodes}
            setZipcodes={setZipcodes}
            selectedAgents={selectedAgents}
            setSelectedAgents={setSelectedAgents}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            filters={filters}
            setFilters={setFilters}
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
            handleFilter={handleFilter}
          ></ContactList>
        </Box>
      </DetailCard>
      <ContactCreateDialog
        open={addingContact}
        onCreate={(contact: any) => {
          setAddingContact(false);
          setSearch((search) => ({ ...search }));
          let name = contact.firstName;
          if (contact.lastName) {
            name += ` ${contact.lastName}`;
          }
          showSuccess(`${name} was added as a contact`);
          navigate(`/contacts/${contact.id}`);
        }}
        onClose={() => setAddingContact(false)}
      />
    </TeamLayout>
  );
}

const mapDispatchToProps = (dispatch: any) => ({});
const mapStateToProps = (state: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
