import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import TeamMemberSelect from "../TeamMemberSelect";
import {
  ADD_DEAL_TEAM_MEMBER,
  REMOVE_DEAL_TEAM_MEMBER,
} from "../../api/graphql";
import { useAlert } from "../../context/alert";
import { SecondaryButton, PrimaryButton, OutlineButton } from "../buttons";
import CreateOrEditTeamMemberDialog from "../CreateOrEditTeamMemberDialog";
import { TeamMember } from "../../models";

type AssignTeamMemberDialogProps = {
  open: boolean;
  role: any;
  deal: any;
  defaultTeamMember?: any;
  onClose: CallableFunction;
  onSave: CallableFunction;
};

const AssignTeamMemberDialog = ({
  open,
  role,
  deal,
  defaultTeamMember,
  onClose,
  onSave,
}: AssignTeamMemberDialogProps) => {
  const { showError } = useAlert();
  const [teamMember, setTeamMember] = useState<any>(defaultTeamMember);
  const [addTeamMemberToDeal] = useMutation(ADD_DEAL_TEAM_MEMBER);
  const [removeTeamMemberFromDeal] = useMutation(REMOVE_DEAL_TEAM_MEMBER);
  const [createType, setCreateType] = useState<"team" | "vendors">("team");
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const handleSave = () => {
    if (teamMember === null && defaultTeamMember) {
      // Remove the team member from the deal
      removeTeamMemberFromDeal({
        variables: {
          input: {
            id: defaultTeamMember.id,
            roleId: role.id,
            dealId: deal.id,
          },
        },
      })
        .then(() => onSave(null))
        .catch(() => {
          showError(
            "An error occurred when removing the team member to the deal"
          );
        });
    } else if (teamMember) {
      // Add the team member to the deal
      addTeamMemberToDeal({
        variables: {
          input: {
            id: teamMember.id,
            roleId: role.id,
            dealId: deal.id,
          },
        },
      })
        .then((res) => {
          onSave(res.data.addDealTeamMember);
        })
        .catch((err) => {
          showError(
            "An error occurred when adding the team member to the deal"
          );
        });
    }
  };

  const handleClose = () => {
    setCreateDialogOpen(false);
    onClose();
  };

  const handleTeamMemberChange = (teamMember: any) => {
    setTeamMember(teamMember);
  };

  const openCreateVendorDialog = () => {
    setCreateType("vendors");
    setCreateDialogOpen(true);
  };

  const openCreateTeamMemberDialog = () => {
    setCreateType("team");
    setCreateDialogOpen(true);
  };

  const handleCreated = (teamMember: TeamMember) => {
    handleTeamMemberChange(teamMember);
  };

  useEffect(() => {
    setTeamMember(defaultTeamMember);
  }, [defaultTeamMember]);

  if (!role || !deal) {
    return <></>;
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ minWidth: "368px" }}>
        Assign {role.name}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TeamMemberSelect
              onChange={handleTeamMemberChange}
              value={teamMember ? teamMember.id : null}
              initialTeamMember={teamMember}
            />
          </Grid>
          <Grid item>
            <OutlineButton onClick={openCreateVendorDialog}>
              Add vendor
            </OutlineButton>
          </Grid>
          <Grid item>
            <OutlineButton onClick={openCreateTeamMemberDialog}>
              Add team member
            </OutlineButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={handleClose} style={{ width: "100px" }}>
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={handleSave} style={{ width: "100px" }}>
          Save
        </PrimaryButton>
      </DialogActions>
      <CreateOrEditTeamMemberDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSave={handleCreated}
        type={createType}
        teamMember={{}}
      />
    </Dialog>
  );
};

export default AssignTeamMemberDialog;
