import React, { FunctionComponent, useState } from "react";
import {
  List,
  ListItem,
  Theme,
  Chip,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { EmailIcon, MessageIcon } from "../../icons";
import clsx from "clsx";
import { Search } from "@mui/icons-material";
import { useUser } from "../../context/user";

type EmailThreadListProps = {
  loading?: boolean;
  threads: Array<any>;
  onThreadClick: (email: any) => any;
  onSearch: (term: string) => void;
  isSent?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  emailIcon: {
    background: "#FF576D",
    color: "#fff",
    padding: "4px 4.5px",
    borderRadius: "50%",
    height: "22px",
    width: "22px",
    marginRight: "14px",
  },
  smsIcon: {
    background: theme.palette.electricIndigo.main,
    color: "#fff",
    padding: "4px 4.5px",
    borderRadius: "50%",
    height: "22px",
    width: "22px",
    marginRight: "14px",
  },
  threadButton: {
    width: "100%",
  },

  previewText: {
    fontSize: "12px",
  },

  mediumText: {
    fontSize: "13px",
    fontWeight: 500,
    color: "#161e2e",
  },
  boldText: {
    fontSize: "13px",
    fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
    fontWeight: 600,
  },
  threadDateTime: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#59606d",
    marginRight: "9px;",
  },
  messageCountChip: {
    height: "20px",
    borderRadius: "4px",
    fontSize: "10px",
    background: "rgb(244, 245, 247)",
  },
  threadListItem: {
    borderBottom: "1px solid #e5e7eb",
    borderLeft: "4px solid transparent",
    padding: "12px",
    "&.unread.SmsThread": {
      borderLeft: `4px solid ${theme.palette.electricIndigo.main}`,
    },
    "&.unread.EmailThread": {
      borderLeft: `4px solid ${theme.palette.coral.main}`,
    },
    "&.selected": {
      background: "#F5F5F5",
    },
    "&.selected $messageCountChip, &.unread $messageCountChip": {
      background: "#fff",
    },
  },
}));

const EmailThreadList: FunctionComponent<EmailThreadListProps> = ({
  threads,
  onThreadClick,
  onSearch,
  loading,
  isSent = false,
}) => {
  const [selectedThread, setSelectedThread] = useState<any>();
  const classes = useStyles();
  const { currentUser } = useUser();
  const handleSearch = (e: any) => {
    e.preventDefault();
    onSearch(e.target.query.value);
  };

  const handleThreadClick = (e: any, thread: any) => {
    setSelectedThread(thread);
    onThreadClick(thread);
  };

  const formatThreadTime = (date: string) => {
    let dateFormat: string;
    const m = moment(date);
    const now = moment();
    const diff = now.diff(m, "d");
    const isCurrentYear = m.get("y") === now.get("y");
    if (diff === 0) {
      dateFormat = "h:mm A";
    } else if (diff === -1) {
      dateFormat = "[Yesterday]";
    } else if (isCurrentYear) {
      dateFormat = "MMM D";
    } else {
      dateFormat = "MMM D, YYYY";
    }
    return m.format(dateFormat);
  };

  const participantNames = (smsThread: any) => {
    return smsThread.participants
      .filter((p: any) => p.id !== currentUser.teamMember?.id)
      .map((p: any) => p.name)
      .join(", ");
  };

  return (
    <List>
      <ListItem>
        <form
          onSubmit={handleSearch}
          style={{ width: "100%" }}
          method="post"
          noValidate
        >
          <TextField
            name="query"
            variant="outlined"
            type="search"
            fullWidth
            size="small"
            placeholder="Search Messages..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ color: "#747b88" }} />
                </InputAdornment>
              ),
            }}
          />
        </form>
      </ListItem>
      {threads?.map((thread: any, index: number) => {
        return (
          <ListItem
            key={thread.id}
            button
            className={clsx({
              [classes.threadListItem]: true,
              [thread.__typename]: true,
              unread: thread.unreadCount > 0 || thread.firstUnreadSmsId,
              selected: thread.id === selectedThread?.id,
            })}
          >
            <div
              onClick={(e) => handleThreadClick(e, thread)}
              className={classes.threadButton}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span className={classes.threadDateTime}>
                  {formatThreadTime(
                    thread.lastEmail?.messageDate ?? thread.updatedAt
                  )}
                </span>
                <Chip
                  className={classes.messageCountChip}
                  label={thread.totalCount}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  {thread.__typename === "EmailThread" && (
                    <EmailIcon className={classes.emailIcon} />
                  )}
                  {thread.__typename === "SmsThread" && (
                    <MessageIcon className={classes.smsIcon} />
                  )}
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    className={
                      thread.unreadCount || thread.firstUnreadSmsId
                        ? classes.boldText
                        : classes.mediumText
                    }
                    style={{ marginBottom: "4px" }}
                  >
                    {thread.__typename === "EmailThread" && (
                      <>
                        {isSent
                          ? thread.lastEmail?.recipients[0].to.name
                          : thread.lastEmail?.from.name}
                      </>
                    )}
                    {thread.__typename === "SmsThread" && (
                      <>{participantNames(thread)}</>
                    )}
                  </span>
                  <span
                    className={
                      thread.unreadCount || thread.last
                        ? classes.boldText
                        : classes.mediumText
                    }
                    style={{ marginBottom: "4px" }}
                  >
                    {thread.subject}
                  </span>
                  {thread.lastEmail && (
                    <span className={classes.previewText}>
                      {thread.lastEmail.messagePreview}
                    </span>
                  )}
                  {thread.lastMessageText && (
                    <span className={classes.previewText}>
                      {thread.lastMessageText}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </ListItem>
        );
      })}
      {loading && (
        <ListItem style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={20} />
        </ListItem>
      )}
    </List>
  );
};
export default EmailThreadList;
