import React, {
  useState,
  useRef,
  useEffect,
  MouseEvent,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
  CircularProgress,
  Tab,
  Tabs,
  Paper,
  Alert,
  AlertTitle,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useApolloClient } from "@apollo/client";
import {
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  RENDER_EMAIL_PREVIEW,
} from "../../api/graphql";
import { SecondaryButton, PrimaryButton } from "../buttons";
import { useAlert } from "../../context/alert";
import { Link } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toolbar, makeToolbarWithMergeFields } from "../../helpers/quill";
import Attachments, { Attachment } from "../attachments/Attachments";
import EmailBodyIframe from "../emails/EmailBodyIframe";
import { SendTestEmailModal } from "./SendTestEmailModal";
import "quill-mention";
import { Stack } from "@mui/system";
import mixpanel from "../../mixpanel";

const Delta = Quill.import("delta");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {
      flexGrow: 1,
    },
    dialogPaper: {
      minHeight: "600px",
      maxHeight: "600px",
    },
    fieldLink: {
      fontSize: "small",
      display: "inline-block",
      marginRight: 15,
      textDecoration: "dotted",
    },
    tableHeader: {
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "14px",
      color: "rgb(107, 114, 128)",
    },
    root: {
      flexGrow: 1,
    },
    dialogLoading: {
      zIndex: theme.zIndex.modal + 1,
    },
    buttonProgress: {
      color: theme.palette.primary.main,
    },
    paper: {
      padding: theme.spacing(2),
      borderRadius: "5px",
    },
    cancelButton: {
      width: "110px",
      [theme.breakpoints.down("sm")]: {
        width: "80px",
      },
    },
    sendTestEmailButton: {
      [theme.breakpoints.down("sm")]: {
        padding: "8px",
      },
    },
    saveTemplateButton: {
      width: "110px",
      marginLeft: "5px",
      [theme.breakpoints.down("sm")]: {
        width: "88px",
        padding: "8px",
      },
    },
  })
);

interface MergeField {
  id: string;
  name: string;
  placeholder: string;
}

type AddTemplateDialogProps = {
  template?: any;
  onClose: () => void;
  onTemplateSaved?: Function;
  mergeFields?: Array<MergeField>;
  editable?: boolean;
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const TrackEditorOpen = () => {
  useEffect(() => {
    mixpanel.track("Editor Opened");
  }, []);
  return null;
};

export default function TemplateDialog({
  template,
  onClose,
  onTemplateSaved,
  mergeFields,
  editable = true,
}: AddTemplateDialogProps) {
  const blankEmailTemplate = {
    subject: "",
    name: "",
    body: {
      html: "",
    },
  };

  const classes = useStyles();
  const apollo = useApolloClient();
  const [content, setContent] = useState<string>("");
  const quill = useRef<ReactQuill | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(editable ? 0 : 1);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [uploadedAttachments, setUploadedAttachments] = useState<Attachment[]>(
    []
  );
  const [formatError, setFormatError] = useState<any>();
  const [attachmentsToDelete, setAttachmentsToDelete] = useState<any>([]);
  const { showSuccess, showError } = useAlert();
  const [previewHtml, setPreviewHtml] = useState("");
  /**
   * Quill modules
   */
  const modules: any = { toolbar };
  if (mergeFields && mergeFields.length) {
    modules.toolbar = makeToolbarWithMergeFields(mergeFields);
  }

  modules.mention = {
    allowedChars: /^[a-zA-Z0-9_. ]*$/,
    showDenotationChar: false,
    mentionDenotationChars: ["@", "#", "{{"],
    source: function (searchTerm: any, renderList: any, mentionChar: any) {
      console.log(searchTerm);

      if (searchTerm.length === 0) {
        renderList(
          mergeFields?.map((field) => ({
            id: field.id,
            value: field.placeholder,
          })) ?? [],
          searchTerm
        );
      }

      const matches =
        mergeFields
          ?.filter((field) =>
            field.placeholder.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((field) => ({
            id: field.id,
            value: field.placeholder,
          })) ?? [];

      renderList(matches, searchTerm);
    },
  };

  const quillModules = useRef<any>(modules);

  const emailTemplateReducer = (state: any, action: any) => {
    switch (action.type) {
      case "SET_EMAIL_TEMPLATE":
        return Object.assign({}, action.emailTemplate);
      case "SET_NAME":
        return Object.assign({}, state, { name: action.name });
      case "SET_SUBJECT":
        return Object.assign({}, state, { subject: action.subject });
      case "SET_ID":
        return Object.assign({}, state, { id: action.id });
      case "RESET_DIALOG":
        return blankEmailTemplate;
      default:
        return state;
    }
  };

  const [emailTemplate, dispatch] = useReducer(
    emailTemplateReducer,
    blankEmailTemplate
  );

  function handleClose(e: any) {
    e.stopPropagation();
    setAttachmentsToDelete([]);
    setFormatError(null);
    onClose();
  }

  const setName = (name: string) => {
    dispatch({
      type: "SET_NAME",
      name,
    });
  };

  const setSubject = (subject: string) => {
    dispatch({
      type: "SET_SUBJECT",
      subject,
    });
  };

  const onAttachmentAdded = (addedAttachment: Attachment) => {
    setAttachments((attachments) => [...attachments, addedAttachment]);
  };

  const onAttachmentDeleted = (attachmentId: string) => {
    setAttachmentsToDelete((attachmentsToDelete: any) => [
      ...attachmentsToDelete,
      attachmentId,
    ]);
  };

  const onAttachmentUpdated = (
    attachmentId: string,
    updatedAttachment: Attachment
  ) => {
    setAttachments((attachments) =>
      attachments.map((attachment) => {
        if (attachment.id === attachmentId) {
          return updatedAttachment;
        }

        return attachment;
      })
    );
  };

  const handleFieldClick = (e: MouseEvent, field: MergeField) => {
    if (quill.current) {
      const quillEditor = quill.current.getEditor();
      const range = quillEditor.getSelection();
      const index = range ? range.index : 0;
      quillEditor.insertText(index, field.placeholder);
    }
  };

  const handleSave = async (e: any) => {
    e.stopPropagation();
    if (!quill.current || !editable) {
      return;
    }
    const editor = quill.current.getEditor();
    const plaintext = editor.getText();
    const html = editor.root.innerHTML;
    const delta = JSON.stringify(editor.getContents());
    const input: any = {
      ...emailTemplate,
      body: {
        plaintext,
        html,
        delta,
      },
    };

    delete input.tableData;

    if (input.id) {
      delete input.attachments;
      input.addAttachments = attachments.map(({ id, loading, ...rest }) => ({
        ...rest,
      }));
      input.removeAttachments = attachmentsToDelete;
    } else {
      input.attachments = attachments.map(({ id, loading, ...rest }) => ({
        ...rest,
      }));
    }

    try {
      setSaving(true);
      let res: any = await apollo.mutate({
        mutation: input.id ? UPDATE_EMAIL_TEMPLATE : CREATE_EMAIL_TEMPLATE,
        variables: {
          input,
        },
      });

      // Set the ID so if we just created the template, an update
      // will work.
      res = input.id
        ? res.data.updateEmailTemplate
        : res.data.createEmailTemplate;

      dispatch({
        type: "SET_ID",
        id: res.id,
      });

      showSuccess(
        `The template "${emailTemplate.name}" was saved successfully`
      );
      setSaving(false);
      setAttachments([]);
      onTemplateSaved && onTemplateSaved(res);
      setFormatError(null);
    } catch (e: any) {
      showError("An error occurred when saving the email template");

      const errorMessage = e.message;
      const startOfHTML = errorMessage.indexOf("<");
      const endOfHTML = errorMessage.indexOf("^");

      const cleanedMessage = errorMessage.substring(startOfHTML, endOfHTML);

      setFormatError(cleanedMessage);
      setSaving(false);
    }
  };
  const renderAttachments = uploadedAttachments.filter(
    (uploaded) => !attachmentsToDelete.includes(uploaded.id)
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handlePreview = useCallback(() => {
    const editor = quill?.current?.getEditor();
    const plaintext =
      editor && editable ? editor.getText() : emailTemplate.body.plaintext;
    const html =
      editor && editable ? editor.root.innerHTML : emailTemplate.body.html;
    const subject = emailTemplate.subject;
    apollo
      .query({
        query: RENDER_EMAIL_PREVIEW,
        variables: {
          input: {
            html,
            plaintext,
            subject,
          },
        },
      })
      .then((res) => {
        setPreviewHtml(res.data.renderEmailPreview.messageTextAsHtml);
      })
      .catch(() => {});
  }, [apollo, emailTemplate, editable]);

  useEffect(() => {
    if (template) {
      let content: any;
      if (template.body.delta) {
        const delta = JSON.parse(template.body.delta);
        content = new Delta(delta);
      } else {
        content = template.body.html;
      }
      setContent(content);
      dispatch({
        type: "SET_EMAIL_TEMPLATE",
        emailTemplate: template,
      });
      if (template.attachments?.length > 0) {
        setUploadedAttachments(template.attachments);
      }
    }
  }, [template]);

  const tabs = useMemo(() => {
    const tabs: any[] = [];
    if (editable) {
      tabs.push(<Tab key="editor" value={0} label="Editor" />);
    }
    tabs.push(
      <Tab
        key="preview"
        value={1}
        label="Preview Email"
        onClick={handlePreview}
      />
    );

    return (
      <Tabs value={selectedTab} onChange={handleTabChange}>
        {tabs}
      </Tabs>
    );
  }, [editable, selectedTab, handlePreview]);

  const tabPanels = useMemo(() => {
    const tabPanels: any[] = [];
    if (editable) {
      tabPanels.push(
        <TabPanel key="editor" value={selectedTab} index={0}>
          <TrackEditorOpen />
          <ReactQuill
            ref={(e: any) => {
              quill.current = e;
            }}
            theme="snow"
            value={content}
            onChange={setContent}
            modules={quillModules.current}
            style={{ marginBottom: "10px" }}
          ></ReactQuill>
          <Attachments
            attachments={[...renderAttachments, ...attachments]}
            onAttachmentAdded={onAttachmentAdded}
            onAttachmentDeleted={onAttachmentDeleted}
            onAttachmentUpdated={onAttachmentUpdated}
          />
        </TabPanel>
      );
    } else {
      handlePreview();
    }
    tabPanels.push(
      <TabPanel key="preview" value={selectedTab} index={1}>
        <>
          <p>
            <strong>Note:</strong> We're rendering your email with fake data to
            give you an example of how your email will look.
          </p>
          <Paper className={classes.paper} variant="outlined">
            <EmailBodyIframe html={previewHtml} />
          </Paper>
        </>
      </TabPanel>
    );

    return <>{tabPanels}</>;
  }, [
    editable,
    attachments,
    classes.paper,
    content,
    previewHtml,
    renderAttachments,
    selectedTab,
    handlePreview,
  ]);

  return (
    <Dialog
      open={Boolean(template)}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>Message Template</DialogTitle>
      <DialogContent>
        {formatError && (
          <Alert severity="error">
            <AlertTitle>We found a formatting error</AlertTitle>
            {formatError}
          </Alert>
        )}
        <Stack spacing={1}>
          <TextField
            variant="standard"
            autoFocus={!emailTemplate.id}
            fullWidth
            label="Name"
            value={emailTemplate.name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            variant="standard"
            fullWidth
            label="Subject"
            value={emailTemplate.subject}
            onChange={(e) => setSubject(e.target.value)}
          />

          <div className={classes.tabContainer}>
            {tabs}
            {tabPanels}
          </div>

          <div style={{ display: "none" }}>
            {mergeFields?.map((field: MergeField, i: number) => (
              <Link
                className={classes.fieldLink}
                key={i}
                onClick={(e) => handleFieldClick(e, field)}
                to="#"
              >
                {field.name}
              </Link>
            ))}
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={handleClose} className={classes.cancelButton}>
          Cancel
        </SecondaryButton>

        {emailTemplate.id && (
          <SendTestEmailModal emailTemplate={emailTemplate} />
        )}

        {editable && (
          <PrimaryButton
            onClick={handleSave}
            disabled={saving}
            className={classes.saveTemplateButton}
          >
            {saving ? (
              <CircularProgress size={16} className={classes.buttonProgress} />
            ) : (
              "Save"
            )}
          </PrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
