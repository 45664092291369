import moment from "moment";

export const REPORTS = {
  SET: "REPORTS/set",
  SET_DEALS: "REPORTS/set_deals",
  SET_AGENTS: "REPORTS/set_agents",
  SET_DATES: "REPORTS/set_dates",
  SET_FILTER: "REPORTS/set_filter",
  SET_CHART_INFO: "REPORTS/set_chart_info",
  RESET: "REPORTS/reset",
};

export type ReportsState = {
  name: string;
  allDeals: any[];
  agents: any[];
  agentCommissions: number[];
  chartInfo: {
    labels: string[];
    data: any[];
  };
  dateRange: {
    from: Date;
    to: Date;
  };
  filter: {
    includePending: boolean;
  };
};

const initialState: ReportsState = {
  name: "",
  allDeals: [],
  agents: [],
  agentCommissions: [],
  chartInfo: {
    labels: [],
    data: [],
  },
  dateRange: {
    from: moment().startOf("year").toDate(),
    to: moment().endOf("year").toDate(),
  },
  filter: {
    includePending: false,
  },
};

const reportsReducer = (state: ReportsState = initialState, action: any) => {
  switch (action.type) {
    case REPORTS.SET:
      return { ...state, ...action.report };
    case REPORTS.SET_DEALS:
      return { ...state, allDeals: action.deals };
    case REPORTS.SET_AGENTS:
      return { ...state, agents: action.agents };
    case REPORTS.SET_DATES:
      return { ...state, dateRange: action.dateRange };
    case REPORTS.SET_CHART_INFO:
      return { ...state, chartInfo: action.chartInfo };
    case REPORTS.RESET:
      return initialState;
    case REPORTS.SET_FILTER:
      return { ...state, filter: action.filter };
    default:
      return state;
  }
};

export default reportsReducer;
