import React, { useState, useEffect, useCallback } from "react";
import {
  DataGrid,
  GridSortModel,
  GridToolbarContainer,
  GridRowParams,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowId,
  GridColumns,
  GridColDef,
  GridCellParams,
} from "@mui/x-data-grid";
import {
  Chip,
  Avatar,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { Archive, Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../context/alert";
import { DELETE_DEAL } from "../../api/graphql";
import { useMutation } from "@apollo/client";
import moment from "moment";
import {
  AddressProps,
  getCityStateString,
  getStreetString,
} from "../../helpers/address";
import ContactNameWithAvatar from "../contacts/ContactNameWithAvatar";
import { DealSearch, DealsResponse } from "../../pages/deals/List";
import { ArchiveDialog } from "../../components/deals/ArchiveDialog";
import { Deal } from "../../models";

type DealsTableProps = {
  dealsResponse: DealsResponse;
  onDealDelete: (deal: any) => any;
  onDealArchive: (deal: any) => any;
  dealsLoading: boolean;
  showAllDeals: boolean;
  setParams: (params: any) => void;
  searchParams: DealSearch;
};

const NewDealTable = ({
  dealsResponse,
  onDealDelete,
  onDealArchive,
  dealsLoading,

  showAllDeals,

  searchParams,
  setParams,
}: DealsTableProps) => {
  const [columns, setColumns] = useState<GridColumns | GridColDef[]>([]);
  const { showError, showSuccess } = useAlert();
  const [hoveredRow, setHoveredRow] = useState<GridRowId>("");
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [archivedDeal, setArchiveDeal] = useState<Deal>();
  const [rowCountState, setRowCountState] = React.useState(
    dealsResponse.totalCount
  );
  const [deleteDeal] = useMutation(DELETE_DEAL);
  const navigate = useNavigate();

  const { includeArchived } = searchParams;

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      dealsResponse.totalCount !== undefined
        ? dealsResponse.totalCount
        : prevRowCountState
    );
  }, [dealsResponse.totalCount, setRowCountState]);
  const renderContactName = (rowData: GridCellParams) => {
    if (rowData.row.contacts.length === 0) {
      return (
        <span style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            component="span"
            alt="No Contact Icon"
            title="No Contact Icon"
            src="//www.gravatar.com/avatar/?d=mp"
            style={{ marginRight: "10px" }}
          />
          <Typography color="textSecondary">No Contact Found</Typography>
        </span>
      );
    } else {
      return (
        <ContactNameWithAvatar
          name={rowData.row.contacts[0].combinedName}
          avatarUrls={[
            rowData.row.contacts[0].avatarUrl,
            rowData?.row.contacts[0]?.linkedContact?.avatarUrl,
          ]}
          avatarStyle={{ width: "24px", height: "24px" }}
        />
      );
    }
  };

  const renderArchived = (rowData: GridRenderCellParams<string>) => {
    if (!rowData.row.archived) {
      return "";
    }
    return <Chip label={"Archived"} color="primary" />;
  };

  const renderAddress = (rowData: GridRenderCellParams<AddressProps>) => {
    if (rowData.value) {
      return (
        <>
          {getStreetString(rowData.value)}
          <br />
          {getCityStateString(rowData.value)}
        </>
      );
    } else {
      return "";
    }
  };

  const renderCloseDate = (rowData: GridRenderCellParams<string>) => {
    if (!rowData.row.closeDate) {
      return "";
    } else {
      return moment(rowData.row.closeDate).format("MMM DD, YYYY");
    }
  };

  const renderStage = (rowData: GridRenderCellParams<string>) => {
    if (!rowData.row.stage) {
      return;
    }
    return <Chip label={rowData.row.stage.name} variant="outlined" />;
  };

  const renderAsCurrency =
    (key: string) =>
    (rowData: any): JSX.Element => {
      let value = rowData.row[key];
      if (value) {
        value = value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      } else {
        value = "$0.00";
      }
      return <div>{value}</div>;
    };

  const renderAgent = (rowData: GridRenderCellParams<string>) => {
    if (!rowData.row.owner) {
      return;
    }
    return (
      <Avatar
        src={rowData.row.owner.avatarUrl}
        style={{
          width: "24px",
          height: "24px",
          margin: "2px 8px 0 0",
        }}
      ></Avatar>
    );
  };

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent, selectedDeal: GridRowParams) => {
      if (!window.confirm("Are you sure you want to delete this deal?")) {
        return;
      }
      e.stopPropagation();
      deleteDeal({
        variables: {
          id: selectedDeal.id,
        },
      })
        .then(() => {
          showSuccess(`Deal "${selectedDeal.row.name}" was deleted`);
          if (onDealDelete) {
            onDealDelete(selectedDeal.row);
          }
        })
        .catch(() => {
          showError("An error occurred when deleting the deal");
        });
    },
    [showSuccess, showError, deleteDeal, onDealDelete]
  );

  const handleIncludeArchivedChange = async (
    e: React.FormEvent<HTMLInputElement>,
    includeArchivedValue: boolean
  ) => {
    setParams({ includeArchived: includeArchivedValue });
  };

  const handleArchiveOpen = (e: React.MouseEvent, selectedDeal: any) => {
    setArchiveOpen(true);
    setArchiveDeal(selectedDeal.row);
  };
  const renderDealTypes = useCallback(
    (rowData: GridRenderCellParams<string>) => {
      if (showAllDeals) {
        return rowData?.row.dealType.name;
      }
    },
    [showAllDeals]
  );

  useEffect(() => {
    setColumns([
      {
        headerName: "Address",
        field: "address",
        renderCell: renderAddress,
        flex: 2,
      },
      {
        headerName: "Client",
        field: "client",
        renderCell: renderContactName,
        flex: 2,
      },
      {
        headerName: "Deal Type",
        field: "dealType",
        renderCell: renderDealTypes,
        flex: 1,
        hide: !showAllDeals,
      },
      {
        headerName: "Close Date",
        field: "closeDate",
        renderCell: renderCloseDate,
        flex: 1,
      },
      {
        headerName: "Sale Price",
        field: "salePrice",
        renderCell: renderAsCurrency("salePrice"),
        flex: 1,
      },
      {
        headerName: "Agent",
        field: "agent",
        renderCell: renderAgent,
        flex: 1,
      },
      {
        headerName: "Current Stage",
        field: "stage.name",
        renderCell: renderStage,
        flex: 1,
      },
      {
        hide: !includeArchived,
        headerName: "Archived",
        field: "archived",
        renderCell: renderArchived,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        flex: 1,

        disableColumnMenu: true,
        getActions: (selectedDeal: GridRowParams) => {
          let arr: any[] = [];

          const archivedLabel = selectedDeal.row.archived
            ? "Unarchive"
            : "Archive";
          if (hoveredRow === selectedDeal.id) {
            arr = [
              <GridActionsCellItem
                onClick={(e) => handleArchiveOpen(e, selectedDeal)}
                label={archivedLabel}
                showInMenu
                icon={<Archive />}
              ></GridActionsCellItem>,
              <GridActionsCellItem
                onClick={(e) => handleDeleteClick(e, selectedDeal)}
                label="Delete"
                showInMenu
                icon={<Delete />}
              />,
            ];
          }
          return arr;
        },
      },
    ]);
  }, [
    hoveredRow,
    handleDeleteClick,
    showAllDeals,
    renderDealTypes,
    includeArchived,
  ]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      if (sortModel.length === 0) {
        // on third click the sort will go back to the initial sort
        return setParams({
          orderBy: undefined,
          orderDirection: undefined,
        });
      }
      const { field, sort } = sortModel[0];
      setParams({
        orderBy: field,
        orderDirection: sort || undefined,
      });
    },
    [setParams]
  );

  function CustomToolBar() {
    return (
      <GridToolbarContainer
        sx={{
          justifyContent: "right",
          fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
        }}
      >
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                checked={includeArchived}
                onChange={handleIncludeArchivedChange}
                name="showArchived"
                color="primary"
              />
            }
            label="Show Archived"
          />
        </FormGroup>
      </GridToolbarContainer>
    );
  }

  const onMouseEnterRow = (e: React.MouseEvent) => {
    setHoveredRow(e.currentTarget.getAttribute("data-id") || "");
  };

  const onMouseLeaveRow = (e: React.MouseEvent) => {
    setHoveredRow("");
  };
  return (
    <>
      <DataGrid
        rows={dealsResponse.deals}
        columns={columns}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        loading={dealsLoading}
        rowHeight={75}
        pageSize={Math.min(dealsResponse.pageSize, 20)}
        onPageSizeChange={(newPageSize) =>
          setParams({
            pageSize: newPageSize,
          })
        }
        pagination
        rowsPerPageOptions={[5, 10, 20]}
        componentsProps={{
          row: {
            style: { cursor: "pointer" },
            onMouseEnter: onMouseEnterRow,
            onMouseLeave: onMouseLeaveRow,
          },
        }}
        components={{ Toolbar: CustomToolBar }}
        onRowClick={(selectedDeal) =>
          navigate(`/deals/${selectedDeal.id}/details`)
        }
        disableColumnMenu
        onPageChange={(newPage) => setParams({ page: newPage })}
        page={dealsResponse.page}
        paginationMode="server"
        rowCount={rowCountState}
        autoHeight
        sx={{
          width: "100%",
          fontSize: "14px",
          fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
          backgroundColor: "rgb(255, 255, 255)",
          border: "1px solid rgb(224, 224, 224, 1)",
          borderRadius: "4px",
          elevation: 3,
          boxShadow:
            "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
          "& .MuiDataGrid-columnHeader": {
            fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
            fontSize: "14px",
            fontWeight: "400",
            color: "rgb(107, 114, 128)",
            "& > .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
          },
        }}
      />
      <ArchiveDialog
        open={archiveOpen}
        onClose={() => setArchiveOpen(false)}
        onSubmit={onDealArchive}
        deal={archivedDeal}
      />
    </>
  );
};

export default NewDealTable;
