import {
  forwardRef,
  useState,
  useEffect,
  Fragment,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListSubheader,
  DialogActions,
  Button,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { ReactSortable } from "react-sortablejs";
import { MoreVert } from "@mui/icons-material";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
export interface DashboardItem {
  id: string;
  type: string;
  title: string;
  value: number;
  valueFormatted: string;
  goal: number;
  goalFormatted: string;
  sort: number;
  url?: string | null;
}

interface DashboardItemsProps {
  view?: string;
  timeframe?: string;
  open: boolean;
  allowCustomization?: boolean;
  setOpen: (value: boolean) => void;
}

const DashboardGrid = forwardRef<any, any>((props, ref) => {
  return (
    <Grid container spacing={2} ref={ref}>
      {props.children}
    </Grid>
  );
});

const DashboardItems = ({
  view = "me",
  timeframe = "thisMonth",
  open,
  setOpen,
  allowCustomization = false,
}: DashboardItemsProps) => {
  const [items, setItems] = useState<DashboardItem[]>([]);
  const [availableDashboardItems, setAvailableDashboardItems] = useState<any[]>(
    []
  );
  const [selectedItem, setSelectedItem] = useState<DashboardItem | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const { data, refetch } = useQuery(
    gql`
      query GetDashboardItems($view: String!, $timeframe: String!) {
        me {
          teamMember {
            dashboardItems(view: $view, timeframe: $timeframe) {
              id
              type
              title
              url
              value
              valueFormatted
              goal
              goalFormatted
            }
          }
        }
      }
    `,
    {
      variables: { view, timeframe },
    }
  );

  const [getAvailableDashboardItems] = useLazyQuery(
    gql`
      query DashboardItems {
        getAvailableDashboardItems {
          title
          description
          category
          type
          dealTypeId
        }
      }
    `
  );

  const [addDashboardItem] = useMutation(gql`
    mutation AddDashboardItem($input: AddDashboardItemInput!) {
      addDashboardItem(input: $input) {
        id
      }
    }
  `);

  const [removeDashboardItem] = useMutation(gql`
    mutation RemoveDashboardItem($id: ID!) {
      removeDashboardItem(id: $id)
    }
  `);

  const [updateDashboardItem] = useMutation(gql`
    mutation UpdateDashboardItem($input: UpdateDashboardItemInput!) {
      updateDashboardItem(input: $input)
    }
  `);

  const canRemoveItems = useMemo(() => {
    return items.length > 1;
  }, [items]);

  const handleClick = (e: React.MouseEvent, item: DashboardItem) => {
    if (item.url) {
      navigate(item.url);
    }
  };

  const handleMenuClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: DashboardItem
  ) => {
    e.stopPropagation();
    setSelectedItem(item);
    setAnchorEl(e.currentTarget);
  };

  const handleAdd = useCallback(() => {
    getAvailableDashboardItems().then(({ data }) => {
      const map: { [key: string]: any } = {};
      data.getAvailableDashboardItems.forEach((item: any) => {
        map[item.category ?? ""] ??= [];
        map[item.category ?? ""].push(item);
      });

      const categories = new Set<string>(
        data.getAvailableDashboardItems.map((item: any) => item.category)
      );

      const availableDashboardItems = Array.from(categories).map((category) => {
        return {
          category,
          items: map[category],
        };
      });
      setAvailableDashboardItems(availableDashboardItems);
      setOpen(true);
      handleClose();
    });
  }, [getAvailableDashboardItems, setOpen]);

  const handleAddItem = (item: any) => {
    const input = {
      type: item.type,
      dealTypeId: item.dealTypeId,
    };
    addDashboardItem({
      variables: {
        input,
      },
    }).then(() => {
      refetch();
      setOpen(false);
    });
  };

  const handleRemoveItem = () => {
    if (!selectedItem?.id) {
      return;
    }
    setItems((items) => {
      return items.filter((item) => item.id !== selectedItem.id);
    });
    removeDashboardItem({
      variables: {
        id: selectedItem.id,
      },
    });
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const shortFormat = (value: number) => {
    return Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(value);
  };

  const GoalProgress = ({ item }: { item: DashboardItem }) => {
    const currency = item.goalFormatted[0] === "$" ? "$" : "";
    const progress = !item.goal
      ? 0
      : Math.round((item.value / item.goal) * 100);
    const title = `${progress}% to goal`;
    const visibility = progress > 0 ? "visible" : "hidden";
    return (
      <Tooltip title={title} sx={{ visibility }}>
        <Box sx={{ display: "flex", alignItems: "center", visibility }}>
          <LinearProgress
            sx={{ flexGrow: 1, marginRight: ".5rem" }}
            value={Math.min(progress, 100)}
            variant="determinate"
          />
          <Typography variant="body2">{`${currency}${shortFormat(
            item.goal
          )}`}</Typography>
        </Box>
      </Tooltip>
    );
  };
  useEffect(() => {
    if (!open) {
      return;
    } else handleAdd();
  }, [open, handleAdd]);

  useEffect(() => {
    if (data?.me?.teamMember?.dashboardItems) {
      setItems(data.me.teamMember.dashboardItems);
    }
  }, [data]);

  useEffect(() => {
    const isSorted = items.every(
      (item, i, items) => !i || items[i - 1].sort <= item.sort
    );
    if (!isSorted) {
      setItems((items) => {
        return items.map((item, i) => {
          if (item.sort !== i) {
            // Update the sort order
            updateDashboardItem({
              variables: {
                input: {
                  id: item.id,
                  sort: i,
                },
              },
            }).catch((err) => {
              console.error(err);
            });
          }
          return {
            ...item,
            sort: i,
          };
        });
      });
    }
  }, [items, updateDashboardItem]);

  const showGoals = useMemo(() => {
    return items.some((item) => item.goal && item.goal > 0);
  }, [items]);

  return (
    <>
      <ReactSortable tag={DashboardGrid} list={items} setList={setItems}>
        {items.map((item) => (
          <Grid item xs={12} sm={6} md={3} key={item.id}>
            <Box
              onClick={(e) => handleClick(e, item)}
              sx={{
                bgcolor: "background.paper",
                borderRadius: 1,
                p: 2,
                boxShadow: 1,
                "&:hover": {
                  "& .moreIcon": {
                    visibility: "visible",
                  },
                  cursor: "pointer",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "text.secondary",
                  fontFamily: [
                    "AvenirNext-Medium",
                    "Avenir",
                    "sans-serif",
                  ].join(","),
                }}
              >
                <Typography>{item.title}</Typography>
                {allowCustomization && (
                  <IconButton
                    className="moreIcon"
                    sx={{
                      visibility: "hidden",
                    }}
                    size="small"
                    aria-label="Show menu options"
                    onClick={(e) => handleMenuClick(e, item)}
                  >
                    <MoreVert />
                  </IconButton>
                )}
              </Box>

              <Box
                sx={{
                  color: "text.primary",
                  fontSize: 24,
                  fontWeight: "medium",
                  fontFamily: ["AvenirNext-Bold", "Avenir", "sans-serif"].join(
                    ","
                  ),
                }}
              >
                {item.valueFormatted}
              </Box>
              {showGoals && <GoalProgress item={item} />}
            </Box>
          </Grid>
        ))}
      </ReactSortable>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleAdd}>Add</MenuItem>
        <MenuItem disabled={!canRemoveItems} onClick={handleRemoveItem}>
          Remove
        </MenuItem>
      </Menu>
      <Dialog
        maxWidth="sm"
        sx={{ maxHeight: "75vh" }}
        fullWidth
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          Add to Dashboard
          <Typography>Select a metric to add to your dashboard</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ paddingTop: 0 }}>
          <List>
            {availableDashboardItems.map((item, index) => (
              <Fragment key={index}>
                {item.category && (
                  <ListSubheader>{item.category}</ListSubheader>
                )}
                {item.items.map((item: any, index: number) => (
                  <ListItem key={index} onClick={(e) => handleAddItem(item)}>
                    <ListItemButton>
                      <ListItemText
                        primary={item.title}
                        secondary={item.description}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Fragment>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DashboardItems;
