export const APP_DATA = {
  LOADED: "APP_DATA/loaded",
  NOT_LOADED: "APP_DATA/not_loaded",
};

export type AppDataState = "NOT_LOADED" | "LOADED";
const initialState: AppDataState = "NOT_LOADED";

export default function appDataStateReducer(
  state: AppDataState = initialState,
  action: any
): AppDataState {
  switch (action.type) {
    case APP_DATA.LOADED:
      return "LOADED";
    case APP_DATA.NOT_LOADED:
      return "NOT_LOADED";
    default:
      return state;
  }
}
