import React from "react";
import { Box } from "@mui/material";
import { PencilIcon } from "../../icons";
import ActionIconButton from "../ActionIconButton";

export default function EditButton({
  onClick = () => {},
  disabled,
  className,
}: {
  onClick: Function;
  disabled?: boolean;
  className?: any;
}) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row-reverse",
      }}
      className={className}
    >
      <ActionIconButton
        buttonStyle={{ padding: "0" }}
        onClick={() => onClick()}
        icon={PencilIcon}
        disabled={disabled}
      />
    </Box>
  );
}
