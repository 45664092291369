import React, { useState, FunctionComponent, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import ActionIconButton from "../ActionIconButton";
import { Close as CloseIcon } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { useAlert } from "../../context/alert";
import SkySlopeTransactionAutocomplete from "./SkySlopeTransactionAutocomplete";

const transactionTypes = [
  {
    id: "Listing",
    name: "Listing",
  },
  {
    id: "Purchase",
    name: "Purchase",
  },
];

const GET_SKYSLOPE_OFFICES = gql`
  query GetSkySlopeOffices {
    getSkySlopeOffices {
      id
      name
    }
  }
`;

const GET_SKYSLOPE_USERS_AND_CHECKLIST_TYPES = gql`
  query GetSkySlopeUsersAndChecklistTypes(
    $officeId: String!
    $transactionType: String!
  ) {
    getSkySlopeUsers(officeId: $officeId) {
      id
      firstName
      lastName
      email
    }
    getSkySlopeChecklistTypes(
      officeId: $officeId
      transactionType: $transactionType
    ) {
      id
      name
    }
  }
`;

const CREATE_SKYSLOPE_SALE = gql`
  mutation CreateSkySlopeSale($input: CreateSkySlopeTransactionInput!) {
    createSkySlopeSale(input: $input) {
      id
      url
    }
  }
`;

const CREATE_SKYSLOPE_LISTING = gql`
  mutation CreateSkySlopeListing($input: CreateSkySlopeTransactionInput!) {
    createSkySlopeListing(input: $input) {
      id
      url
    }
  }
`;

const LINK_SALE = gql`
  mutation LinkSkySlopeSale($input: LinkSkySlopeSaleInput!) {
    linkSkySlopeSale(input: $input) {
      id
      url
    }
  }
`;

const LINK_LISTING = gql`
  mutation LinkSkySlopeListing($input: LinkSkySlopeListingInput!) {
    linkSkySlopeListing(input: $input) {
      id
      url
    }
  }
`;

interface SkySlopeOffice {
  id: string;
  name: string;
}

interface SkySlopeUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface SkySlopeChecklistType {
  id: number;
  name: string;
}

interface CreateSkySlopeTransactionInput {
  dealId: string;
  officeId: string | undefined;
  agentId: string | undefined;
  checklistTypeId: number;
}

type TransactionType = "Purchase" | "Listing";

const SkySlopeCreateTransactionDialog: FunctionComponent<{
  deal: any;
  open: boolean;
  transactionType: TransactionType;
  onClose: () => any;
  onTransactionCreated: (
    transaction: { type: TransactionType; id: string; url: string },
    mode: "create" | "link"
  ) => any;
}> = ({ deal, open, transactionType, onClose, onTransactionCreated }) => {
  const { showSuccess, showError } = useAlert();
  const [mode, setMode] = useState<"create" | "link">("create");
  const [offices, setOffices] = useState<SkySlopeOffice[]>();
  const [office, setOffice] = useState<SkySlopeOffice>();
  const [agents, setAgents] = useState<SkySlopeUser[]>();
  const [agent, setAgent] = useState<SkySlopeUser>();

  const [checklistType, setChecklistType] = useState<SkySlopeChecklistType>();
  const [checklistTypes, setChecklistTypes] =
    useState<SkySlopeChecklistType[]>();
  const [selectedTransactionType, setSelectedTransactionType] =
    useState<TransactionType>(transactionType);
  const [transactionToLink, setTransactionToLink] = useState<any>();

  const [getSkySlopeOffices, { loading: loadingOffices }] =
    useLazyQuery(GET_SKYSLOPE_OFFICES);

  const [getSkySlopeUsersAndChecklistTypes, { loading }] = useLazyQuery(
    GET_SKYSLOPE_USERS_AND_CHECKLIST_TYPES
  );

  const [createSkySlopeSale, { loading: saleSubmitting }] =
    useMutation(CREATE_SKYSLOPE_SALE);

  const [createSkySlopeListing, { loading: listingSubmitting }] = useMutation(
    CREATE_SKYSLOPE_LISTING
  );

  const createSkySlopeTransaction = async (
    input: CreateSkySlopeTransactionInput
  ) => {
    return selectedTransactionType === "Purchase"
      ? await createSkySlopeSale({
          variables: { input },
        })
      : await createSkySlopeListing({
          variables: { input },
        });
  };

  const [linkSkySlopeSale, { loading: linkingSale }] = useMutation(LINK_SALE);
  const [linkSkySlopeListing, { loading: linkingListing }] =
    useMutation(LINK_LISTING);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!canSubmit()) {
      return;
    }

    let promise: Promise<{ url: string; id: string }> | undefined;
    if (mode === "create") {
      promise = createSkySlopeTransaction({
        dealId: deal.id,
        officeId: office?.id,
        agentId: agent?.id,
        checklistTypeId: Number(checklistType?.id),
      }).then(
        (res) =>
          res.data[
            selectedTransactionType === "Purchase"
              ? "createSkySlopeSale"
              : "createSkySlopeListing"
          ]
      );
    } else if (mode === "link") {
      promise =
        selectedTransactionType === "Purchase"
          ? linkSkySlopeSale({
              variables: {
                input: {
                  dealId: deal.id,
                  skyslopeSaleId: transactionToLink?.id,
                },
              },
            }).then((res) => res.data.linkSkySlopeSale)
          : linkSkySlopeListing({
              variables: {
                input: {
                  dealId: deal.id,
                  skyslopeListingId: transactionToLink?.id,
                },
              },
            }).then((res) => res.data.linkSkySlopeListing);
    }
    promise
      ?.then(({ url, id }) => {
        onTransactionCreated({ type: selectedTransactionType, id, url }, mode);
        showSuccess(
          <>
            <Typography>
              A {selectedTransactionType} was{" "}
              {mode === "create" ? "created" : "linked"}
              <Button size="small" href={url} target="_blank">
                View in SkySlope
              </Button>
            </Typography>
          </>
        );
      })
      .catch((err) => {
        showError(
          `Error ${
            mode === "create" ? "Creating" : "Linking"
          } SkySlope Transaction: ${err?.message ?? ""}`
        );
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleClose = () => {
    onClose();
  };

  const canSubmit = () => {
    return (
      (office && agent && checklistType) ||
      (mode === "link" && transactionToLink)
    );
  };

  useEffect(() => {
    if (!office || !selectedTransactionType) {
      return;
    }
    getSkySlopeUsersAndChecklistTypes({
      variables: {
        officeId: office.id,
        transactionType:
          selectedTransactionType === "Purchase" ? "Sale" : "Listing",
      },
    }).then((res) => {
      const agents = res.data.getSkySlopeUsers;
      const checklistTypes = res.data.getSkySlopeChecklistTypes;

      setAgents(agents);
      setChecklistTypes(checklistTypes);
    });
  }, [office, selectedTransactionType, getSkySlopeUsersAndChecklistTypes]);

  useEffect(() => {
    if (open) {
      // If the dialog is opened, fetch the SkySlope offices
      getSkySlopeOffices()
        .then((res) => {
          const offices = res.data.getSkySlopeOffices ?? [];
          setOffices(offices);
          if (offices.length > 0) {
            setOffice(offices[0]);
          }
        })
        .catch(() => {});
    }
  }, [open, getSkySlopeOffices]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">
            {mode === "create" ? "Send" : "Link"} to SkySlope
          </Typography>
          <ActionIconButton
            icon={CloseIcon}
            style={{ margin: "4px -5px" }}
            buttonStyle={{ width: "22px", height: "22px" }}
            onClick={handleClose}
            aria-label="close"
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {loadingOffices && (
          <Box textAlign="center" mb={2}>
            <CircularProgress size="24px" />
          </Box>
        )}
        {!loadingOffices && (
          <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="skyslopeOffice">Office</InputLabel>
                <Select
                  native
                  label="Office"
                  inputProps={{ id: "skyslopeOffice" }}
                  value={office?.id ?? ""}
                  onChange={(e) => {
                    setOffice(offices?.find((o) => o.id === e.target.value));
                  }}
                >
                  {offices?.map((office) => (
                    <option key={office.id} value={office.id}>
                      {office.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="skyslopeTransactionType">
                  Transaction Type
                </InputLabel>
                <Select
                  label="Transaction Type"
                  native
                  inputProps={{ id: "skyslopeTransactionType" }}
                  value={selectedTransactionType}
                  onChange={(e) => {
                    setSelectedTransactionType(
                      e.target.value as TransactionType
                    );
                  }}
                >
                  <option></option>
                  {transactionTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {mode === "create" && (
              <>
                {agents && (
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="skyslopeAgent">Agent</InputLabel>
                      {loading ? (
                        <Skeleton variant="rectangular" />
                      ) : (
                        <Select
                          label="Agent"
                          native
                          inputProps={{ id: "skyslopeAgent" }}
                          value={agent?.id ?? ""}
                          onChange={(e) => {
                            setAgent(
                              agents?.find((a) => a.id === e.target.value)
                            );
                          }}
                        >
                          <option></option>
                          {agents.map((agent) => (
                            <option key={agent.id} value={agent.id}>
                              {agent.firstName} {agent.lastName}
                            </option>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="skyslopeChecklistType">
                      Checklist
                    </InputLabel>
                    <Select
                      label="Checklist"
                      native
                      inputProps={{ id: "skyslopeChecklistType" }}
                      value={checklistType?.id ?? ""}
                      onChange={(e) => {
                        setChecklistType(
                          checklistTypes?.find((c) => c.id === e.target.value)
                        );
                      }}
                    >
                      <option></option>
                      {checklistTypes?.map((checklistType) => (
                        <option key={checklistType.id} value={checklistType.id}>
                          {checklistType.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {mode === "link" && (
              <>
                <Grid item xs={12}>
                  <SkySlopeTransactionAutocomplete
                    transactionType={selectedTransactionType}
                    onTransactionSelected={(transaction) =>
                      setTransactionToLink(transaction)
                    }
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                style={{ fontSize: 12, textAlign: "center" }}
              >
                {mode === "create" && (
                  <>
                    Already have a {selectedTransactionType} created for this
                    deal?{" "}
                    <Button
                      size="small"
                      color="primary"
                      style={{ textTransform: "none" }}
                      onClick={() => {
                        setMode("link");
                        setTransactionToLink(null);
                      }}
                    >
                      Link an existing {selectedTransactionType}
                    </Button>
                  </>
                )}
                {mode === "link" && (
                  <>
                    Can't find a {selectedTransactionType} for this deal?{" "}
                    <Button
                      size="small"
                      color="primary"
                      style={{ textTransform: "none" }}
                      onClick={() => setMode("create")}
                    >
                      Create a new {selectedTransactionType}
                    </Button>
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton fullWidth onClick={handleClose}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={
            !canSubmit() ||
            saleSubmitting ||
            listingSubmitting ||
            linkingListing ||
            linkingSale
          }
          onClick={handleSubmit}
        >
          {mode === "create" ? "Create" : "Link"} a {selectedTransactionType}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
export default SkySlopeCreateTransactionDialog;
