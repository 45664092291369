import React, { useState, FormEvent } from "react";
import SideLayout from "../layouts/SideLayout";
import { Grid, Box, TextField, Button, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link as RouterLink } from "react-router-dom";
import { validateEmail, hasTrueValues } from "../helpers";
import { gql, ApolloError } from "@apollo/client";
import { useMutation } from "@apollo/client";

export default function ForgotPassword() {
  const FORGOT_PASSWORD = gql`
    mutation createPasswordReset($input: CreatePasswordResetInput!) {
      createPasswordReset(input: $input)
    }
  `;

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [formErrors, setFormErrors] = useState<any>({});

  const handleCompleted = () => {
    setSubmitted(true);
  };
  const handleError = (err: ApolloError) => {};

  const [requestPasswordReset] = useMutation(FORGOT_PASSWORD, {
    onCompleted: handleCompleted,
    onError: handleError,
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formErrors: any = {};
    const params: any = {};

    [params.email, formErrors.email] = validateEmail(email);
    setFormErrors(formErrors);
    if (hasTrueValues(formErrors)) {
      return;
    }
    requestPasswordReset({ variables: { input: params } });
  };

  const useStyles = makeStyles((theme) => ({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    img: {
      height: "45px",
      width: "100%",
      paddingBottom: "1em",
    },
    p: {
      fontSize: "16px",
      fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      fontWeight: 600,
      textAlign: "center",
    },
  }));

  const classes = useStyles();

  return (
    <SideLayout
      right={
        submitted ? (
          <>
            <h2>Almost there&hellip;</h2>
            <p className="classes.p">
              We sent you an email with instructions to reset your password.
            </p>
            <Grid xs>
              <Link to="/Login" component={RouterLink} variant="body2">
                Back to Login
              </Link>
            </Grid>
          </>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ maxHeight: "100vh", maxWidth: "100%" }}
          >
            <Grid item xs={10} sm={6}>
              {
                <img
                  src="/logo-full.svg"
                  alt="Shaker logo"
                  aria-label="Shaker logo"
                  title="Shaker"
                  className={classes.img}
                />
              }
              <Box textAlign="center">
                <h3>Forgot your password?</h3>
                <p className="classes.p">
                  No worries, we'll send you an email with reset instructions.
                </p>
              </Box>
              <form noValidate onSubmit={handleSubmit} className={classes.form}>
                <TextField
                  label="Email"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  type="email"
                  error={formErrors.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Request Password
                </Button>
              </form>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs className="left">
                  <Link to="/Login" component={RouterLink} variant="body2">
                    Back to Login
                  </Link>
                </Grid>
                <Grid item xs className="right">
                  <Link to="/signup" component={RouterLink} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    />
  );
}
