import { useCallback, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Field, FieldGroup } from "../models";
import { Deal } from "../models";
import { UPDATE_DEAL_TYPE_FIELD_FAVORITE } from "../api/graphql";

export const useDragandDropList = (
  deal: Deal,
  group?: FieldGroup,
  setGroups?: (list: any) => void,
  isLeftPane?: boolean | undefined,
  setFavoriteFields?: (list: any) => void
) => {
  const [fields, setFields] = useState<Array<any>>([]);
  const [updateGroupFields] = useMutation(gql`
    mutation updateGroupFields($input: UpdateGroupFieldsInput!) {
      updateGroupFields(input: $input) {
        id
      }
    }
  `);

  const [updateDealTypeFieldFavorite] = useMutation(
    UPDATE_DEAL_TYPE_FIELD_FAVORITE
  );
  const setList = useCallback(
    (list: Field[], sortable: any) => {
      const needsSaved =
        list.length !== fields.length ||
        list.some((field, index) => field.id !== fields[index].id);
      const fieldGroupId = group?.id;
      const changedField = list.filter(
        (item) => !group?.fields.some((field) => field.id === item.id)
      )[0];
      if (isLeftPane && changedField) {
        let input = {};
        if (changedField.isCustom) {
          input = {
            dealTypeId: deal?.dealType?.id,
            fieldIds: list.map((field) => field.id),
          };
        } else {
          input = {
            dealTypeId: deal?.dealType?.id,
            fieldIds: list.map((field) => field.id),
          };
        }
        updateDealTypeFieldFavorite({
          variables: {
            input,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateDealTypeFieldFavorite: {
              __typename: "DealType",
              id: deal?.dealType?.id,
              fields: list,
            },
          },

          refetchQueries: ["GetGroupsAndFields"],
        }).then(() => {
          setFavoriteFields && setFavoriteFields(list);
        });
      } else if (needsSaved) {
        if (fieldGroupId === "favorites") {
          const input = {
            dealTypeId: deal?.dealType?.id,
            fieldIds: list.map((field) => field.id),
          };
          const removedDuplicatedListFields = list.filter(
            (field, index) =>
              list.findIndex((item) => item.id === field.id) === index
          );
          return updateDealTypeFieldFavorite({
            variables: {
              input,
            },
            optimisticResponse: {
              __typename: "Mutation",
              updateDealTypeFieldFavorite: {
                __typename: "DealType",
                id: deal?.dealType?.id,
                fields: removedDuplicatedListFields,
              },
            },
          }).then(() => {
            setFavoriteFields && setFavoriteFields(removedDuplicatedListFields);
          });
        }
        const fieldIds = list.map((field) => field.id);
        const dealTypeId = deal?.dealType?.id;
        updateGroupFields({
          variables: {
            input: {
              dealTypeId,
              fieldGroupId,
              fieldIds,
            },
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateGroupFields: {
              __typename: "FieldGroup",
              id: fieldGroupId,
              fields: list,
            },
          },
        });
        setFields(list);
        setGroups &&
          setGroups((prevGroups: FieldGroup[]) => {
            const indexOfChangedGroup = prevGroups.findIndex(
              (group) => group.id === fieldGroupId
            );
            return [
              ...prevGroups.slice(0, indexOfChangedGroup),
              { ...prevGroups[indexOfChangedGroup], fields: list },
              ...prevGroups.slice(indexOfChangedGroup + 1, prevGroups.length),
            ];
          });
      }
    },
    [
      fields,
      group,
      setGroups,
      updateGroupFields,
      deal,
      isLeftPane,
      updateDealTypeFieldFavorite,
      setFavoriteFields,
    ]
  );

  return {
    setList,
  };
};
