import React, { useCallback, useEffect, useState } from "react";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { gql } from "@apollo/client";
import { useApi } from "../../context/api";
import { Line as LineGraph } from "react-chartjs-2";
import LabeledSelect from "../../components/LabeledSelect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unitSelect: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
  })
);

const GET_USER_ACTIVITY = gql`
  query GET_USER_ACTIVITY(
    $start: Date
    $end: Date
    $unit: TimeUnit
    $accountId: ID
  ) {
    activeUsers(start: $start, end: $end, unit: $unit, accountId: $accountId) {
      unit
      data {
        start
        activeUserCount
      }
    }
  }
`;

type UserActivityDatum = {
  start: Date;
  activeUserCount: number;
};

const UserActivityChart = ({ accountId }: { accountId?: string }) => {
  const classes = useStyles();
  const { apolloClient } = useApi();
  const [chartData, setChartData] = useState<any>();

  const queryData = useCallback(
    ({ start, end, unit }: { start?: Date; end?: Date; unit?: string }) => {
      const variables = { start, end, unit, accountId };

      return apolloClient
        .query({ query: GET_USER_ACTIVITY, variables })
        .then((response) => response.data.activeUsers.data)
        .then((data: UserActivityDatum[]) => {
          const chartData: any = {
            labels: data.map((datum) => datum.start),
            datasets: [
              {
                label: "Active users",
                data: data.map((datum) => datum.activeUserCount),
                backgroundColor: "rgb(255, 99, 132)",
                borderColor: "rgba(255, 99, 132, 0.2)",
                cubicInterpolationMode: "monotone",
                fill: "none",
              },
            ],
          };

          setChartData(chartData);
        })
        .catch((error) =>
          console.log("There was an error getting user activity data", error)
        );
    },
    [apolloClient, accountId]
  );

  useEffect(() => {
    queryData({ unit: "monthly" });
  }, [queryData]);

  const onUnitSelected = useCallback(
    (event: any) => {
      const unit = event.target.value;
      queryData({ unit });
    },
    [queryData]
  );

  return chartData ? (
    <>
      <div className={classes.unitSelect}>
        <LabeledSelect
          label="Show:"
          selectProps={{
            onChange: onUnitSelected,
            defaultValue: "monthly",
            style: { width: "180px" },
          }}
          options={[
            { value: "daily", label: "Daily" },
            { value: "weekly", label: "Weekly" },
            { value: "monthly", label: "Monthly" },
            { value: "quarterly", label: "Quarterly" },
            { value: "yearly", label: "Yearly" },
          ]}
        />
      </div>

      <LineGraph
        data={chartData}
        options={{
          scales: {
            x: {
              beginAtZero: true,
            },
          },
        }}
      />
    </>
  ) : (
    <></>
  );
};

export default UserActivityChart;
