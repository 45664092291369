import React, { useState, FunctionComponent, useEffect } from "react";
import { TextField, Chip, Avatar, Box } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import { useLazyQuery } from "@apollo/client";
import { GET_RECIPIENT_SUGGESTIONS } from "../api/graphql";
import ContactNameWithAvatar from "./contacts/ContactNameWithAvatar";
import { useCombineContactAndTeamMembers } from "../hooks/useCombineContactAndTeamMembers";
import { Deal } from "../models";

type RecipientAutocompleteProps = {
  value: Array<any>;
  deal?: Deal;
  extraEndAdornment?: any;
  forSms?: boolean;
  onChange?: (value: Array<any>) => any;
  label?: string;
  style?: any;
};
const RecipientAutocomplete: FunctionComponent<RecipientAutocompleteProps> = ({
  value: _value,
  deal,
  forSms,
  onChange,
  label = "To: ",
  style,
  extraEndAdornment,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<Array<any>>([]);
  const [value, setValue] = useState<Array<any>>(_value || []);

  const { combinedOptions } = useCombineContactAndTeamMembers(deal, options);

  const renderToTags = (values: Array<any>, getTagProps: any) => {
    return values.map((value: any, index: number) => {
      let avatar: JSX.Element | undefined;
      if (value.avatarUrl) {
        avatar = <Avatar src={value.avatarUrl} alt={value.name} />;
      }
      return (
        <Chip
          variant="outlined"
          key={index}
          avatar={avatar}
          label={value.name}
          {...getTagProps({ index })}
          onDelete={() => handleDelete(index)}
        />
      );
    });
  };

  const handleDelete = (index: number) => {
    setValue(value.filter((v: any, i: number) => i !== index));
  };

  const handleChange = (e: any, value: any) => {
    setValue(value);
  };

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  const [getRecipientSuggestions] = useLazyQuery(GET_RECIPIENT_SUGGESTIONS);
  useEffect(() => {
    getRecipientSuggestions({
      variables: {
        search: inputValue,
      },
    })
      .then((res) => {
        setOptions(res.data.getRecipientSuggestions);
      })
      .catch(() => {});
  }, [inputValue, getRecipientSuggestions]);

  const renderOption = (props: any, option: any) => {
    if (option.avatarUrl) {
      return (
        <Box component="li" {...props} key={option.id}>
          <ContactNameWithAvatar
            avatarStyle={{ height: "32px", width: "32px" }}
            name={option.name}
            role={option?.role}
            title={forSms ? option.phone : option.email}
            avatarUrls={[option.avatarUrl]}
          />
        </Box>
      );
    } else {
      return option.name;
    }
  };
  const getOptionLabel = (option: { name: string }) => option.name;
  const getOptionSelected = (option: any, value: any) => {
    return option.id === value.id;
  };

  return (
    <Autocomplete
      style={style}
      fullWidth
      multiple
      onInputChange={(e, value) => setInputValue(value)}
      value={value}
      onChange={handleChange}
      renderTags={renderToTags}
      options={combinedOptions || []}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          variant="standard"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <label itemID="email-to">{label}</label>
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {extraEndAdornment}
              </>
            ),
            margin: "dense",
          }}
        />
      )}
    ></Autocomplete>
  );
};

export default RecipientAutocomplete;
