import React, { FunctionComponent, useEffect } from "react";
import { TextField } from "@mui/material";
import { gql, useLazyQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import { SkySlopePurchaseType } from "./useCreateFromSkySlope";
import { DotloopLoop } from "./useCreateFromDotloop";

const CreateFromAutocomplete: FunctionComponent<{
  profileId?: any;
  onSelected: (newDeal: DotloopLoop | SkySlopePurchaseType) => any;
  optionData?: SkySlopePurchaseType[] | DotloopLoop[];
  label?: string;
}> = ({ profileId, onSelected, optionData, label }) => {
  const [getDotloopLoops, { data, loading }] = useLazyQuery(gql`
    query GetDotloopLoops($profileId: Int!) {
      getDotloopLoops(profileId: $profileId) {
        id
        name
        loopUrl
      }
    }
  `);

  useEffect(() => {
    if (profileId) {
      getDotloopLoops({
        variables: {
          profileId: Number.parseInt(profileId, 10),
        },
      });
    }
  }, [profileId, getDotloopLoops]);

  const getOptionLabel = (option: any) => {
    return profileId ? option.name : option.address;
  };

  const loadingText = profileId
    ? "loading Dootloop Loops"
    : "loading SkySlope Transactions";

  const noOptionsText = profileId
    ? "no Dotloop Loops found"
    : "no SkySlope Transactions found";
  return (
    <Autocomplete
      fullWidth
      loading={loading}
      onChange={(event, loop) => {
        onSelected(loop as DotloopLoop | SkySlopePurchaseType);
      }}
      options={data?.getDotloopLoops || optionData || []}
      getOptionLabel={getOptionLabel}
      noOptionsText={noOptionsText}
      loadingText={loadingText}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" />
      )}
    />
  );
};
export default CreateFromAutocomplete;
