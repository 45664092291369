import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  InputAdornment,
  Input,
  Select,
  Typography,
  Button,
  FormControl,
  ListSubheader,
  MenuItem,
  Link,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import { Folder as FolderIcon, KeyboardArrowDown } from "@mui/icons-material";
import Attachments, { Attachment } from "../attachments/Attachments";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { SEND_EMAIL } from "../../api/graphql";
import Editor from "../Editor";
import { gql } from "@apollo/client";
import Skeleton from "@mui/material/Skeleton";
import { PrimaryButton } from "../buttons";
import RecipientAutocomplete from "../../components/RecipientAutocomplete";
import { Deal, EmailTemplate } from "../../models/index";
import { makeStyles } from "@mui/styles";
import FileTypeIcon from "../../components/FileTypeIcon";
import { useAlert } from "../../context/alert";

const GET_EMAIL_TEMPLATE_MERGE_FIELDS = gql`
  query getEmailTemplateMergeFields($dealId: ID) {
    getEmailTemplateMergeFields(dealId: $dealId) {
      id
      name
      placeholder
      value
    }
  }
`;

type ComposeEmailProps = {
  recipients?: Array<any>;
  emailThread?: any;
  onEmailSent?: (res: any) => void;
  deal?: Deal;
  contactDeals?: Deal[];
};

const StyledSelect = withStyles(() =>
  createStyles({
    root: {
      height: "32px",
      padding: "0 16px",
      fontSize: "12px",
    },
  })
)(Select);

const useStyles = makeStyles({
  shakerDocs: {
    backgroundColor: "#f4f5f7",
    border: "1px dashed #e5e7eb",
    padding: "14px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    marginLeft: "4px",
  },
  folderContainer: {
    display: "flex",
    alignItems: "center",
  },
  folderIcon: {
    marginRight: "5px",
  },
  attachShakerDocsButton: {
    height: "14px",
    marginLeft: "3px",
    marginBottom: "2px",
  },
  linkButton: {
    margin: "3px",
  },
});

const ComposeEmail = ({
  recipients: _recipients,
  emailThread,
  onEmailSent,
  deal,
  contactDeals,
}: ComposeEmailProps) => {
  const apollo = useApolloClient();
  const [
    getEmailTemplateMergeFields,
    { data: mergeFieldData, loading: loadingMergeFields },
  ] = useLazyQuery(GET_EMAIL_TEMPLATE_MERGE_FIELDS);
  const classes = useStyles();

  const [sending, setSending] = useState(false);
  const { showSuccess, showError } = useAlert();
  const [emailTemplates, setEmailTemplates] = useState<Array<any>>([]);
  const [recipients, setRecipients] = useState<Array<any>>(_recipients || []);
  const [ccRecipients, setCCRecipients] = useState<Array<any>>([]);
  const [bccRecipients, setBccRecipients] = useState<Array<any>>([]);
  const [subject, setSubject] = useState<string>(emailThread?.subject || "");
  const htmlRef = useRef<string>("");
  const text = useRef<string>("");
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [selectedContactDeal, setSelectedContactDeal] = useState<
    Deal | undefined
  >(contactDeals?.length === 1 ? contactDeals[0] : undefined);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<
    EmailTemplate | undefined
  >(undefined);
  const [shakerDocsOpen, setShakerDocsOpen] = useState<boolean>(false);
  const [shakerDocs, setShakerDocs] = useState<Array<any>>([]);
  const [shakerDocFolders, setShakerDocFolders] = useState<Array<any>>([]);
  const [showBcc, setShowBcc] = useState(false);
  const [showCc, setShowCc] = useState(false);

  const [loadShakerDocs, { data: shakerDocsData }] = useLazyQuery(
    GET_DEAL_DOCUMENTS,
    { variables: { id: deal?.id } }
  );

  useEffect(() => {
    if (selectedContactDeal) {
      setShakerDocFolders(selectedContactDeal.folders);
      setShakerDocs(selectedContactDeal.documents);
    } else if (deal) {
      // Get the latest set of docs
      loadShakerDocs();
      getEmailTemplateMergeFields({ variables: { dealId: deal.id } });
    }
  }, [selectedContactDeal, deal, loadShakerDocs, getEmailTemplateMergeFields]);

  useEffect(() => {
    if (shakerDocsData) {
      setShakerDocs(shakerDocsData.deal.documents);
      setShakerDocFolders(shakerDocsData.deal.folders);
    }
  }, [shakerDocsData]);

  const hasDocs = useCallback(
    () =>
      shakerDocs.length +
        shakerDocFolders.reduce(
          (sum: number, folder) => sum + folder.documents.length,
          0
        ) >
      0,
    [shakerDocs, shakerDocFolders]
  );

  const { data, loading } = useQuery(COMPOSE_EMAIL_COMPONENT_QUERY);
  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.me.emailSignatureHtml) {
      htmlRef.current = `<br /><br />${data.me.emailSignatureHtml}`;
    }
    if (data.me.emailSignaturePlaintext) {
      text.current = `\n\n${data.me.emailSignaturePlaintext}`;
    }
    if (data.getEmailTemplates) {
      setEmailTemplates(data.getEmailTemplates);
    }
  }, [data]);

  const handleEditorChange = ({
    html: _html,
    text: _text,
  }: {
    html: string;
    text: string;
  }) => {
    htmlRef.current = _html;
    text.current = _text;
  };

  const handleSendEmail = () => {
    const mapRecipients = (recipients: any) => {
      return recipients
        .map((recipient: any) => {
          if (recipient.__typename === "Contact") {
            return { contactId: recipient.id };
          } else if (recipient.__typename === "TeamMember") {
            return { teamMemberId: recipient.id };
          }
          return null;
        })
        .filter((r: any) => r !== null);
    };

    const recipientParam = mapRecipients(recipients);
    const ccRecipientParam = mapRecipients(ccRecipients);
    const bccRecipientParam = mapRecipients(bccRecipients);

    const params: any = {
      recipients: recipientParam,
      ccRecipients: ccRecipientParam,
      bccRecipients: bccRecipientParam,
      subject: subject,
      body: {
        html: htmlRef.current,
        plaintext: text.current,
      },
      attachments: attachments.map((attachment) => {
        return {
          id: attachment.id,
          fileName: attachment.fileName,
          objectKey: attachment.objectKey,
          dealDocumentId: attachment.dealDocumentId
            ? attachment.dealDocumentId
            : null,
        };
      }),
    };

    if (emailThread) {
      params.emailThreadId = emailThread.id;
    }

    if (deal) {
      params.dealId = deal.id;
    }

    setSending(true);
    apollo
      .mutate({
        mutation: SEND_EMAIL,
        variables: {
          input: params,
        },
      })
      .then((res) => {
        setSubject("");
        setAttachments([]);
        showSuccess("Your email was sent");
        if (onEmailSent) {
          onEmailSent(res);
        }
      })
      .catch((err) => {
        showError(
          "An error occurred sending your email" +
            (!!err.message ? `: ${err.message}` : "")
        );
      })
      .finally(() => {
        setSending(false);
      });
  };

  const handleRecipientChange = (recipients: Array<any>) => {
    setRecipients(recipients);
  };

  const handleCCRecipientChange = (recipients: Array<any>) => {
    setCCRecipients(recipients);
  };

  const handleBccRecipientChange = (recipients: Array<any>) => {
    setBccRecipients(recipients);
  };

  const onAttachmentAdded = (addedAttachment: Attachment) => {
    setAttachments((attachments) => [...attachments, addedAttachment]);
  };

  const onAttachmentDeleted = (attachmentId: string) => {
    setAttachments((attachments) =>
      attachments.filter((attachment) => attachment.id !== attachmentId)
    );
  };

  const onAttachmentUpdated = (
    attachmentId: string,
    updatedAttachment: Attachment
  ) => {
    setAttachments((attachments) =>
      attachments.map((attachment) => {
        if (attachment.id === attachmentId) {
          return updatedAttachment;
        }

        return attachment;
      })
    );
  };

  const handleTemplateChange = (event: any) => {
    setSelectedEmailTemplate(
      emailTemplates.find(
        (template: EmailTemplate) => template.id === event.target.value
      )
    );
  };

  const handleContactDealChange = (event: any) => {
    setSelectedContactDeal(
      contactDeals?.find((deal: Deal) => deal.id === event.target.value)
    );
  };

  const renderPreview = useCallback(() => {
    const query = gql`
      query RenderEmailPreview($input: EmailPreviewInput!) {
        renderEmailPreview(input: $input) {
          subject
          messageText
          messageTextAsHtml
        }
      }
    `;
    const input: any = {
      emailTemplateId: selectedEmailTemplate?.id,
      contentOnly: true,
    };
    if (recipients.length) {
      input.contactId = recipients[0].id;
    }
    if (deal) {
      input.dealId = deal.id;
    } else if (selectedContactDeal) {
      input.dealId = selectedContactDeal.id;
    }
    apollo
      .query({
        query,
        variables: { input },
      })
      .then((res) => {
        setSubject(res.data.renderEmailPreview.subject);

        let html = res.data.renderEmailPreview.messageTextAsHtml;
        let textRes = res.data.renderEmailPreview.messageText;

        if (data.me.emailSignatureHtml) {
          html += `<br />${data.me.emailSignatureHtml}`;
        }
        if (data.me.emailSignaturePlaintext) {
          textRes += `\n${data.me.emailSignaturePlaintext}`;
        }
        htmlRef.current = html;
        text.current = textRes;

        const emailTemplate = emailTemplates.find(
          (emailTemplate) => emailTemplate.id === input.emailTemplateId
        );
        if (emailTemplate?.attachments) {
          setAttachments((attachments) => [
            // Attachments with an objectKey were uploaded by the user. We'll keep them.
            ...attachments.filter((attachment) => attachment.objectKey),
            // Add attachments from the email template
            ...emailTemplate?.attachments.map((attachment: any) => ({
              fileName: attachment.filename,
              id: attachment.id,
            })),
          ]);
        }
      })
      .catch((e) => {
        console.error(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedContactDeal,
    selectedEmailTemplate,
    apollo,
    data,
    deal,
    emailTemplates,
  ]);

  useEffect(() => {
    if (selectedEmailTemplate) {
      renderPreview();
    }
  }, [selectedEmailTemplate, selectedContactDeal, renderPreview]);

  const handleShakerDocAttachmentsChange = async (document: any) => {
    setAttachments((attachments) => [
      ...attachments,
      {
        id: document.id,
        fileName: document.name,
        loading: false,
        dealDocumentId: document.id,
      },
    ]);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div style={{ margin: "8px 0", display: "flex", alignItems: "center" }}>
          <RecipientAutocomplete
            value={recipients || []}
            deal={deal}
            onChange={handleRecipientChange}
            extraEndAdornment={
              !showBcc &&
              !showCc && (
                <>
                  <Link
                    className={classes.linkButton}
                    underline="hover"
                    component="button"
                    onClick={() => setShowCc(true)}
                  >
                    Cc
                  </Link>
                  <Link
                    className={classes.linkButton}
                    underline="hover"
                    component="button"
                    onClick={() => setShowBcc(true)}
                  >
                    Bcc
                  </Link>
                </>
              )
            }
          />
        </div>
      </Grid>
      {showCc && (
        <Grid item xs={12}>
          <div
            style={{ margin: "8px 0", display: "flex", alignItems: "center" }}
          >
            <RecipientAutocomplete
              value={ccRecipients}
              deal={deal}
              onChange={handleCCRecipientChange}
              label="Cc:"
              extraEndAdornment={
                !showBcc && (
                  <Link
                    className={classes.linkButton}
                    underline="hover"
                    component="button"
                    onClick={() => setShowBcc(true)}
                  >
                    Bcc
                  </Link>
                )
              }
            />
          </div>
        </Grid>
      )}
      {showBcc && (
        <Grid item xs={12}>
          <div
            style={{ margin: "8px 0", display: "flex", alignItems: "center" }}
          >
            <RecipientAutocomplete
              value={bccRecipients}
              deal={deal}
              onChange={handleBccRecipientChange}
              label={"Bcc:"}
              extraEndAdornment={
                !showCc && (
                  <Link
                    className={classes.linkButton}
                    underline="hover"
                    component="button"
                    onClick={() => setShowCc(true)}
                  >
                    Cc
                  </Link>
                )
              }
            />
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <Input
          margin="dense"
          id="email-subject"
          fullWidth
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <label itemID="email-subject">Subject:</label>
            </InputAdornment>
          }
        ></Input>
      </Grid>
      <Grid item xs={12}>
        {!loading && !loadingMergeFields && (
          <Editor
            autoFocus
            mergeFields={mergeFieldData?.getEmailTemplateMergeFields ?? []}
            onChange={handleEditorChange}
            value={htmlRef.current}
          />
        )}
        {loading && <Skeleton variant="rectangular" />}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Attachments
          attachments={attachments}
          onAttachmentAdded={onAttachmentAdded}
          onAttachmentDeleted={onAttachmentDeleted}
          onAttachmentUpdated={onAttachmentUpdated}
        />
        {hasDocs() && (
          <Box className={classes.shakerDocs}>
            <Button
              style={{ textTransform: "none" }}
              onClick={() => setShakerDocsOpen(true)}
            >
              <Typography variant="body2">Attach a deal document</Typography>
            </Button>
            {(selectedContactDeal || deal) && shakerDocsOpen && (
              <FormControl>
                {shakerDocs ? (
                  <Select
                    open={shakerDocsOpen}
                    onOpen={() => setShakerDocsOpen(true)}
                    onClose={() => setShakerDocsOpen(false)}
                  >
                    {shakerDocFolders?.map((folder: any) => {
                      return (
                        <>
                          <ListSubheader className={classes.folderContainer}>
                            <FolderIcon
                              className={classes.folderIcon}
                              color="primary"
                            />
                            <u>{folder.name}</u>
                            <KeyboardArrowDown color="secondary" />
                          </ListSubheader>
                          {folder.documents.map((document: any) => {
                            return (
                              <MenuItem
                                style={{ marginLeft: "15px" }}
                                onClick={async () => {
                                  await handleShakerDocAttachmentsChange(
                                    document
                                  );
                                }}
                                value={document}
                              >
                                <FileTypeIcon
                                  filename={document.name}
                                  smallIcons={true}
                                />
                                {document.name}
                              </MenuItem>
                            );
                          })}
                        </>
                      );
                    })}
                    {shakerDocs?.map((document) => {
                      return (
                        <MenuItem
                          onClick={async () => {
                            await handleShakerDocAttachmentsChange(document);
                          }}
                          value={document}
                        >
                          <FileTypeIcon
                            filename={document.name}
                            smallIcons={true}
                          />
                          {document.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    open={shakerDocsOpen}
                    onOpen={() => setShakerDocsOpen(true)}
                    onClose={() => setShakerDocsOpen(false)}
                  >
                    <MenuItem>No documents attached to this deal.</MenuItem>
                  </Select>
                )}
              </FormControl>
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={8} style={{ display: "flex", alignItems: "flex-end" }}>
        {emailTemplates.length > 0 && (
          <Box>
            <StyledSelect
              native
              variant="outlined"
              onChange={handleTemplateChange}
            >
              <option value="">Use Template</option>
              {emailTemplates.map((template) => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </StyledSelect>
          </Box>
        )}
        {contactDeals && (
          <Box style={{ marginLeft: "8px" }}>
            <StyledSelect
              native
              variant="outlined"
              onChange={handleContactDealChange}
              labelId="deal"
              disabled={contactDeals.length < 2}
              value={selectedContactDeal?.id ?? ""}
            >
              <option value="">Use Deal</option>
              {contactDeals.map((deal) => (
                <option key={deal.id} value={deal.id}>
                  {deal.name || deal.dealType?.name}
                </option>
              ))}
            </StyledSelect>
          </Box>
        )}
      </Grid>
      <Grid item xs={4}>
        <Box textAlign="right" style={{ marginTop: "18px" }}>
          <PrimaryButton disabled={sending} onClick={handleSendEmail}>
            {!sending ? "Send Email" : <>Sending&hellip;</>}
          </PrimaryButton>
        </Box>
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
};

export default ComposeEmail;

// Get an email signature for the current user.
const COMPOSE_EMAIL_COMPONENT_QUERY = gql`
  query ComposeEmailComponentQuery {
    me {
      id
      emailSignatureHtml
      emailSignaturePlaintext
    }
    getEmailTemplates {
      id
      name
      attachments {
        id
        filename
      }
    }
  }
`;

const GET_DEAL_DOCUMENTS = gql`
  query GetDealDocuments($id: ID!) {
    deal(id: $id) {
      folders {
        id
        name
        dealId
        createdAt
        updatedAt
        documents {
          id
          name
          size
          status
          docusignEnvelopeId
          mimeType
          showInPortal
          createdAt
        }
      }
      documents(excludeInFolders: true) {
        id
        name
        size
        status
        docusignEnvelopeId
        mimeType
        showInPortal
        createdAt
      }
    }
  }
`;
