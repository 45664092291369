import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Account } from "../../models";
import { gql, useMutation } from "@apollo/client";
import { ToggleButton, ToggleButtonGroup } from "../ToggleButtons";

const ENABLE_FEATURE = gql`
  mutation ENABLE_FEATURE($accountId: ID!, $featureKey: String!) {
    enableFeature(accountId: $accountId, featureKey: $featureKey) {
      features {
        key
        name
        enabled
        enabledAt
        disabledAt
      }
    }
  }
`;

const DISABLE_FEATURE = gql`
  mutation DISABLE_FEATURE($accountId: ID!, $featureKey: String!) {
    disableFeature(accountId: $accountId, featureKey: $featureKey) {
      features {
        key
        name
        enabled
        enabledAt
        disabledAt
      }
    }
  }
`;

const AccountFeatures = ({
  account,
  setAccount,
}: {
  account: Account;
  setAccount: (setter: (account: Account) => Account) => void;
}) => {
  const [enableFeature] = useMutation(ENABLE_FEATURE, {
    onCompleted: (res) => {
      setAccount((account) => ({
        ...account,
        features: res.enableFeature.features,
      }));
    },
  });

  const [disableFeature] = useMutation(DISABLE_FEATURE, {
    onCompleted: (res) => {
      setAccount((account) => ({
        ...account,
        features: res.disableFeature.features,
      }));
    },
  });

  const handleFeatureToggle = (featureKey: string, enabled: boolean) => {
    const mutation = enabled ? enableFeature : disableFeature;
    mutation({
      variables: {
        accountId: account.id,
        featureKey: featureKey,
      },
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {account.features.map((feature: any) => (
            <TableRow key={feature.key}>
              <TableCell>{feature.name}</TableCell>
              <TableCell>
                <ToggleButtonGroup
                  exclusive
                  buttonWidth="50px"
                  onChange={(event: any, value: any) =>
                    handleFeatureToggle(feature.key, value)
                  }
                  value={feature.enabled}
                >
                  <ToggleButton value={false}>Off</ToggleButton>
                  <ToggleButton value={true}>On</ToggleButton>
                </ToggleButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountFeatures;
