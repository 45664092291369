import React from "react";
import { Grid } from "@mui/material";
import SplitLayout from "../layouts/SplitLayout";
import LeftPane from "../components/LeftPane";
import DetailPane from "../components/DetailPane";
import SettingsSubNav from "../components/SettingsSubNav";
import SettingsSelect from "../components/SettingsSelectMobile";
import PageTitle from "../components/PageTitle";
import GoalSettings from "../components/goals/GoalSettings";

const Goals = () => {
  return (
    <SplitLayout>
      <LeftPane width={224}>
        <SettingsSubNav />
        <SettingsSelect label="" defaultValue="/settings/profile" />
      </LeftPane>
      <DetailPane>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageTitle title="Goals" />
          </Grid>
          <Grid item xs={12}>
            <GoalSettings />
          </Grid>
        </Grid>
      </DetailPane>
    </SplitLayout>
  );
};

export default Goals;
