import React, { ChangeEvent, FunctionComponent, useState } from "react";
import TeamMemberSelect from "../TeamMemberSelect";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Grid, TextField, Checkbox, InputAdornment } from "@mui/material";
enum AssignmentType {
  ContactOwner = "contactOwner",
  TeamMember = "teamMember",
}

type TaskSettingsProps = {
  step: any;
  setStep: (step: any) => any;
};

const TaskSettings: FunctionComponent<TaskSettingsProps> = ({
  step,
  setStep,
}) => {
  const handleTaskNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStep({ ...step, taskName: e.currentTarget.value });
  };
  const [assignmentType, setAssignmentType] = useState<AssignmentType>(
    step?.teamMemberId != null
      ? AssignmentType.TeamMember
      : AssignmentType.ContactOwner
  );

  const handleAssignmentTypeChange = (e: any, value: string) => {
    const assignmentType = value as AssignmentType;
    if (assignmentType === AssignmentType.ContactOwner) {
      setStep({ ...step, teamMemberId: null });
    }
    setAssignmentType(assignmentType);
  };

  const handleTeamMemberChange = (teamMember: any) => {
    setStep({ ...step, teamMemberId: teamMember?.id || null });
  };

  if (!step) {
    return <></>;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Task Name"
          type="text"
          variant="standard"
          placeholder="e.g. Perform title search"
          value={step.taskName}
          onChange={handleTaskNameChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Assign To</FormLabel>
          <RadioGroup
            aria-label="assign to"
            name="assign-to"
            value={assignmentType}
            onChange={handleAssignmentTypeChange}
            row
          >
            <FormControlLabel
              value={AssignmentType.ContactOwner}
              control={<Radio color="primary" />}
              label="The assigned agent"
            />
            <FormControlLabel
              value={AssignmentType.TeamMember}
              control={<Radio color="primary" />}
              label="A team member"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {assignmentType === AssignmentType.TeamMember && (
        <Grid item xs={12}>
          <TeamMemberSelect
            label="Assign To"
            value={step.teamMemberId}
            onChange={handleTeamMemberChange}
          />
        </Grid>
      )}
      <Grid item sm={4}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={step.taskHasDueDate}
              onChange={(e, taskHasDueDate) => {
                setStep((step: any) =>
                  Object.assign({}, step, {
                    taskHasDueDate,
                    taskDueDateDays: taskHasDueDate ? 1 : null,
                  })
                );
              }}
            />
          }
          label="Add a due date"
        />
      </Grid>
      <Grid item sm={8}>
        {step.taskHasDueDate && (
          <TextField
            autoFocus
            type="number"
            variant="standard"
            value={step.taskDueDateDays}
            onChange={(e) => {
              setStep((step: any) =>
                Object.assign({}, step, {
                  taskDueDateDays: Number.parseInt(e.target.value, 10),
                })
              );
            }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">
                  {Number.parseInt(step.taskDueDateDays, 10) === 1
                    ? "day"
                    : "days"}{" "}
                  after this task is created
                </InputAdornment>
              ),
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TaskSettings;
