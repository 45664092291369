import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { Feature } from "../reducers/features";
import MainLayout from "./MainLayout";
import { List, ListItem, Collapse } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { AppDrawerListItemIcon, AppDrawerText } from "../components/AppDrawer";
import {
  ArchiveIcon,
  ContactsIcon,
  DashboardIcon,
  MessagesIcon,
  SentIcon,
  SettingsIcon,
  MoneyBagIcon as DealsIcon,
  ReportsIcon,
  CalendarIcon,
} from "../icons";
import { useUser } from "../context/user";
import { FiberManualRecord } from "@mui/icons-material";
import { useAuth } from "../context/auth";
import { io } from "socket.io-client";
import clsx from "clsx";

type TeamLayoutProps = {
  children: React.ReactNode;
  loading?: boolean;
  withoutPadding?: boolean;
  features: any;
};

const useStyles = makeStyles((theme) => ({
  flexAlignItemsCenter: {
    display: "flex",
    alignItems: "center",
  },
  unreadMessageIndicator: {
    fontSize: "11px",
    color: theme.palette.electricIndigo.main,
    "&.active": {
      color: theme.palette.common.white,
    },
  },
}));

const TeamLayout: FunctionComponent<TeamLayoutProps> = ({
  children,
  features,
  ...mainLayoutProps
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { authToken } = useAuth();
  const { currentUser, setCurrentUser } = useUser();

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_API_URL;
    if (!socketUrl) {
      return;
    }
    const socket = io(socketUrl, {
      path: "/io/",
      auth: {
        token: authToken,
      },
      transports: ["websocket"],
    });
    socket.on("hasUnreadSmsThreads", (hasUnreadSmsThreads: boolean) => {
      setCurrentUser((currentUser: any) =>
        Object.assign({}, currentUser, { hasUnreadSmsThreads })
      );
    });
    return () => {
      socket.disconnect();
    };
  }, [authToken, currentUser, setCurrentUser]);

  const isSelected = (key: string) => {
    if (key === "") {
      return location.pathname === "/";
    } else {
      let toMatch = location.pathname;
      if (key.indexOf("#") !== -1) {
        toMatch += location.hash;
      }
      return toMatch.indexOf(`/${key}`) === 0;
    }
  };

  const appDrawerListItems = () => {
    return (
      <>
        <ListItem
          button
          component={RouterLink}
          to="/dashboard"
          selected={isSelected("") || isSelected("dashboard")}
        >
          <AppDrawerListItemIcon>
            <DashboardIcon size="16px" />
          </AppDrawerListItemIcon>
          <AppDrawerText>Dashboard</AppDrawerText>
        </ListItem>
        {features.Calendar && (
          <ListItem
            button
            component={RouterLink}
            to="/calendar"
            selected={isSelected("calendar")}
          >
            <AppDrawerListItemIcon>
              <CalendarIcon size="16px" />
            </AppDrawerListItemIcon>
            <AppDrawerText>Calendar</AppDrawerText>
          </ListItem>
        )}

        <ListItem
          button
          component={RouterLink}
          to="/deals"
          selected={isSelected("deals")}
        >
          <AppDrawerListItemIcon>
            <DealsIcon size="16px" />
          </AppDrawerListItemIcon>
          <AppDrawerText>Deals</AppDrawerText>
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/messages#inbox"
          selected={isSelected("messages#inbox")}
        >
          <AppDrawerListItemIcon>
            <MessagesIcon size="16px" />
          </AppDrawerListItemIcon>
          <AppDrawerText>
            <span className={classes.flexAlignItemsCenter}>
              Messages&nbsp;&nbsp;
              {currentUser.hasUnreadSmsThreads && (
                <FiberManualRecord
                  fontSize="small"
                  className={clsx(classes.unreadMessageIndicator, {
                    active: isSelected("messages#inbox"),
                  })}
                />
              )}
            </span>
          </AppDrawerText>
        </ListItem>
        <Collapse in={isSelected("messages")} timeout="auto" unmountOnExit>
          <List component="div" style={{ marginLeft: "32px" }}>
            <ListItem
              button
              component={RouterLink}
              to="/messages#sent"
              selected={isSelected("messages#sent")}
            >
              <AppDrawerListItemIcon>
                <SentIcon size="16px" />
              </AppDrawerListItemIcon>
              <AppDrawerText>Sent</AppDrawerText>
            </ListItem>
            <ListItem
              button
              component={RouterLink}
              to="/messages#archived"
              selected={isSelected("messages#archived")}
            >
              <AppDrawerListItemIcon>
                <ArchiveIcon size="16px" />
              </AppDrawerListItemIcon>
              <AppDrawerText>Archived</AppDrawerText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          component={RouterLink}
          to="/contacts"
          selected={isSelected("contacts")}
        >
          <AppDrawerListItemIcon>
            <ContactsIcon size="16px" />
          </AppDrawerListItemIcon>
          <AppDrawerText>Contacts</AppDrawerText>
        </ListItem>

        {currentUser?.permissions?.reports !== "none" && (
          <ListItem
            button
            component={RouterLink}
            to="/reports"
            selected={isSelected("reports")}
          >
            <AppDrawerListItemIcon>
              <ReportsIcon size="16px" />
            </AppDrawerListItemIcon>
            <AppDrawerText>Reports</AppDrawerText>
          </ListItem>
        )}

        <ListItem
          button
          component={RouterLink}
          to="/settings/team"
          selected={isSelected("settings/")}
        >
          <AppDrawerListItemIcon>
            <SettingsIcon size="16px" />
          </AppDrawerListItemIcon>
          <AppDrawerText>Settings</AppDrawerText>
        </ListItem>
      </>
    );
  };

  return (
    <MainLayout
      {...mainLayoutProps}
      logo={
        <img
          src="/logo-full.svg"
          alt="Shaker logo"
          title="Shaker"
          style={{ height: "25px" }}
        />
      }
      appDrawerListItems={appDrawerListItems()}
    >
      {children}
    </MainLayout>
  );
};

const mapStateToProps = ({ features }: { [key: string]: Feature }) => {
  return { features };
};
export default connect(mapStateToProps)(TeamLayout);

//export default TeamLayout;
