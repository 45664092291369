import React, { useState, useMemo, useCallback, useEffect } from "react";
import Axios, { AxiosResponse } from "axios";
import QueryString from "qs";

const AUTH_TOKEN_KEY = "token";
const REFRESH_TOKEN_KEY = "refreshToken";
const IMPERSONATED_TOKEN_KEY = "impersonateToken";

class VerificationRequiredError extends Error {
  verificationId: string;
  constructor(verificationId: string) {
    super("Verification required");
    this.name = "VerificationRequiredError";
    this.verificationId = verificationId;
  }
}

const AuthContext = React.createContext<{
  loading: boolean;
  login: (
    email: string,
    password: string,
    verificationId?: string,
    verificationCode?: string
  ) => Promise<{
    accessToken: any;
    refreshToken: any;
  }>;
  logout: () => Promise<void>;
  refresh: () => Promise<any>;
  authToken: string | null;
  setAuthToken: (token: string) => void;
  loadToken: () => Promise<void>;
  clearAuthToken: () => void;
  isImpersonating: boolean;
  setImpersonateToken: (token: string) => void;
  removeImpersonateToken: () => Promise<void>;
}>({
  loading: true,
  login: () =>
    Promise.resolve({ accessToken: undefined, refreshToken: undefined }),
  logout: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
  authToken: null,
  setAuthToken: () => {},
  loadToken: () => Promise.resolve(),
  clearAuthToken: () => {},
  isImpersonating: false,
  setImpersonateToken: () => {},
  removeImpersonateToken: () => Promise.resolve(),
});

function AuthProvider(props: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [authToken, setAuthToken] = useState<string | null>(null);

  /**
   * Load auth token from storage into state
   */
  const loadToken = useCallback(async () => {
    setAuthToken(
      window.localStorage.getItem(IMPERSONATED_TOKEN_KEY) ??
        window.localStorage.getItem(AUTH_TOKEN_KEY)
    );
    setLoading(false);
  }, [setAuthToken]);

  /**
   * Load the token when the app is first loaded
   */
  useEffect(() => {
    loadToken();
  }, [loadToken]);

  /**
   * Save auth and refresh tokens to storage and state
   * @param token
   * @param refreshToken
   */
  const setTokens = useCallback(
    (token: string, refreshToken?: string) => {
      window.localStorage.setItem(AUTH_TOKEN_KEY, token);
      if (refreshToken) {
        window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      } else {
        window.localStorage.removeItem(REFRESH_TOKEN_KEY);
      }
      setAuthToken(token);
    },
    [setAuthToken]
  );

  const clearAuthToken = useCallback(() => {
    window.localStorage.removeItem(IMPERSONATED_TOKEN_KEY);
    window.localStorage.removeItem(AUTH_TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    loadToken();
  }, [loadToken]);

  /**
   * Revoke token with the oauth server and remove it from local storage
   * @param tokenKey
   */
  const revokeToken = useCallback(
    (tokenKey: typeof AUTH_TOKEN_KEY | typeof IMPERSONATED_TOKEN_KEY) => {
      if (!tokenKey) {
        if (window.localStorage.getItem(IMPERSONATED_TOKEN_KEY)) {
          tokenKey = IMPERSONATED_TOKEN_KEY;
        } else {
          tokenKey = AUTH_TOKEN_KEY;
        }
      }

      const token = window.localStorage.getItem(tokenKey);
      if (!token) {
        return Promise.resolve();
      }

      return Axios.post(
        `${process.env.REACT_APP_API_URL}/oauth/revoke`,
        QueryString.stringify({
          access_token: token,
        }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
        .then(() => {
          window.localStorage.removeItem(tokenKey as string);
        })
        .then(loadToken);
    },
    [loadToken]
  );

  const fetchToken = useCallback(
    (grant_type: string, params: any) => {
      // Get an OAuth token
      return Axios.post<
        any,
        AxiosResponse<{
          verificationId?: string;
          verificationRequired?: boolean;
          access_token: string;
          refresh_token: string;
        }>
      >(
        process.env.REACT_APP_API_URL + "/oauth/token",
        QueryString.stringify({
          client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
          grant_type,
          scope: "read write",
          ...params,
        }),
        {
          auth: {
            username: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
            password: process.env.REACT_APP_OAUTH_CLIENT_SECRET as string,
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      ).then((res) => {
        if (res.data.verificationId) {
          throw new VerificationRequiredError(res.data.verificationId);
        }
        setTokens(res.data.access_token, res.data.refresh_token);
        return {
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
        };
      });
    },
    [setTokens]
  );
  /**
   * Ask the oauth server to refresh our access token
   * @returns Promise returning the new access token
   */
  const refresh = useCallback(() => {
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);
    if (refreshToken) {
      return fetchToken("refresh_token", { refresh_token: refreshToken }).then(
        ({ accessToken }) => {
          return accessToken ?? Promise.reject();
        }
      );
    } else {
      return Promise.reject();
    }
  }, [fetchToken]);

  /**
   * Log the user out
   */
  const logout = useCallback(() => revokeToken(AUTH_TOKEN_KEY), [revokeToken]);

  /**
   * Gets an access token by password grant OAuth flow.
   *
   * @param email
   * @param password
   */
  const login = useCallback(
    (
      email: string,
      password: string,
      verificationId?: string,
      verificationCode?: string
    ) =>
      fetchToken("password", {
        username: email,
        password,
        verificationId,
        verificationCode,
      }),
    [fetchToken]
  );

  const setImpersonateToken = useCallback(
    (token: string) => {
      window.localStorage.setItem(IMPERSONATED_TOKEN_KEY, token);
      loadToken().then(() => setIsImpersonating(true));
    },
    [loadToken]
  );

  const removeImpersonateToken = useCallback(
    () =>
      revokeToken(IMPERSONATED_TOKEN_KEY)
        .then(loadToken)
        .then(() => setIsImpersonating(false)),
    [revokeToken, loadToken]
  );

  const hasImpersonateToken = useCallback(
    () => !!window.localStorage.getItem(IMPERSONATED_TOKEN_KEY),
    []
  );

  const [isImpersonating, setIsImpersonating] = useState<boolean>(
    hasImpersonateToken()
  );

  const value = useMemo(() => {
    return {
      loading,
      login,
      logout,
      refresh,
      authToken,
      setAuthToken,
      loadToken,
      clearAuthToken,
      isImpersonating,
      setImpersonateToken,
      removeImpersonateToken,
    };
  }, [
    loading,
    login,
    logout,
    refresh,
    authToken,
    setAuthToken,
    loadToken,
    clearAuthToken,
    isImpersonating,
    setImpersonateToken,
    removeImpersonateToken,
  ]);

  return <AuthContext.Provider value={value} {...props} />;
}

const useAuth = () => React.useContext(AuthContext);
export { AuthProvider, AuthContext, useAuth };
