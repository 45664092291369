import React, { useState, ChangeEvent, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Chip, Box } from "@mui/material";
import ContactNameWithAvatar from "./contacts/ContactNameWithAvatar";
import { gql, useQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";

const GET_CONTACTS = gql`
  query GetContacts($params: ContactQueryParams!) {
    getContacts(params: $params) {
      contacts {
        id
        name
        combinedName
        email
        phone
        avatarUrl
        linkedContact {
          id
          name
          avatarUrl
        }
      }
      page
      pageSize
      totalCount
    }
  }
`;

type ContactSelectProps = {
  onChange: (event: ChangeEvent<any>, contact: any) => void;
  onNewContactClick?: () => void;
  value?: any;
  label?: string;
  multiple?: boolean;
  removeFromList?: string;
  asIndividuals?: boolean;
  hideName?: boolean;
};

export default function ContactSelect({
  onChange,
  onNewContactClick,
  value,
  label = "Search for a contact...",
  multiple = false,
  removeFromList,
  asIndividuals = false,
  hideName = false,
}: ContactSelectProps) {
  const [inputValue, setInputValue] = useState("");
  const [contacts, setContacts] = useState<Array<any>>([]);
  let initialSelectedContacts: Array<any> = [];
  if (value) {
    if (multiple) {
      initialSelectedContacts = value;
    } else {
      initialSelectedContacts = [value];
    }
  }
  const [selectedContacts, setSelectedContacts] = useState<Array<any>>(
    initialSelectedContacts
  );

  const handleChange = (e: ChangeEvent<any>, value: any | any[]) => {
    if (Array.isArray(value) && value.find((v) => v.id === "new")) {
      if (onNewContactClick) {
        onNewContactClick();
      }
      return;
    }
    setSelectedContacts(multiple ? value : [value]);
    onChange(e, value);
  };

  const { refetch, data } = useQuery(GET_CONTACTS, {
    variables: { params: { pageSize: 10, term: inputValue, asIndividuals } },
  });

  const getValue = () => {
    if (multiple) {
      // Autocomplete expects an aray when multiple is true;
      return selectedContacts;
    } else {
      return selectedContacts.length ? selectedContacts[0] : null;
    }
  };

  const getOptionLabel = (option: any) => {
    if (option.id === "new" || option.id === null) {
      return option.name;
    }
    return asIndividuals ? option.name : option.combinedName;
  };

  const getOptionSelected = (option: any, value: any): boolean => {
    return option.id === value.id;
  };

  useEffect(() => {
    setContacts(() => {
      const ids = selectedContacts.map((c) => c.id);
      const fetchedContacts =
        data?.getContacts?.contacts.filter(
          (c: any) => ids.indexOf(c.id) === -1
        ) || [];

      const contacts = [...selectedContacts, ...fetchedContacts];
      if (onNewContactClick) {
        contacts.unshift({ id: "new", name: "Add New Contact" });
      }
      return contacts;
    });
  }, [data, selectedContacts, onNewContactClick]);

  useEffect(() => {
    if (value == null) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(multiple ? value : [value]);
    }
  }, [value, multiple]);

  useEffect(() => {
    refetch();
  }, [inputValue, refetch]);

  return (
    <Autocomplete
      onInputChange={(e, value) => setInputValue(value)}
      fullWidth
      multiple={multiple}
      onChange={handleChange}
      options={
        removeFromList
          ? contacts.filter((contact) => contact.id !== removeFromList)
          : contacts
      }
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      value={getValue()}
      renderTags={(value: string[], getTagProps) =>
        value.map((option: any, index: number) => {
          return (
            <Chip label={getOptionLabel(option)} {...getTagProps({ index })} />
          );
        })
      }
      renderOption={(props: any, option: any) => {
        return (
          <Box component="li" {...props}>
            {!option.id ? (
              <span>{getOptionLabel(option)}</span>
            ) : (
              <>
                {option.id === "new" ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AddIcon /> New Contact
                  </Box>
                ) : (
                  <ContactNameWithAvatar
                    avatarStyle={{ height: "32px", width: "32px" }}
                    name={getOptionLabel(option)}
                    avatarUrls={[
                      option.avatarUrl,
                      asIndividuals
                        ? undefined
                        : option.linkedContact?.avatarUrl,
                    ]}
                  />
                )}
              </>
            )}
          </Box>
        );
      }}
      renderInput={(params: any) => (
        <TextField {...params} label={label} variant="standard" />
      )}
    ></Autocomplete>
  );
}
