import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  ServerError,
  fromPromise,
  ApolloProvider,
  NormalizedCacheObject,
  getApolloContext,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import React, { useEffect, useMemo } from "react";
import { useAuth } from "./auth";

const cache = new InMemoryCache({
  possibleTypes: {
    ContactOrTeamMember: ["Contact", "TeamMember"],
  },
});

export type ApiClient = ApolloClient<NormalizedCacheObject>;

const ApiContext = React.createContext<{
  apolloClient: ApiClient;
}>({
  apolloClient: new ApolloClient({ cache }),
});

const ApiProvider = (props: any) => {
  const { client: existingClient } = React.useContext(getApolloContext());
  const { authToken, logout, refresh: refreshAuthToken } = useAuth();

  const apolloClient = useMemo(() => {
    const link = new HttpLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: authToken ? `Bearer ${authToken}` : "",
        },
      };
    });

    /**
     * Apollo middleware to remove __typename
     * Adapted from stackoverflow.com/a/51380645
     */
    const cleanTypeName = new ApolloLink((operation, forward) => {
      if (operation.variables) {
        const omitTypename = (key: string, value: any) =>
          key === "__typename" ? undefined : value;
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          omitTypename
        );
      }
      return forward(operation);
    });

    const handleError = onError(({ networkError, forward, operation }) => {
      if (networkError) {
        const errorCode = (networkError as ServerError)?.result?.code;
        if (errorCode === "TOKEN_NOT_FOUND") {
          logout();
        } else if (errorCode === "AUTH_TOKEN_EXPIRED") {
          return fromPromise(refreshAuthToken().catch(() => logout()))
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              const oldHeaders = operation.getContext().headers;
              // Use the new token
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`,
                },
              });

              // Retry the request
              return forward(operation);
            });
        }
      }

      return forward(operation);
    });

    return new ApolloClient({
      link: ApolloLink.from([cleanTypeName, authLink, handleError, link]),
      cache: cache,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "no-cache",
        },
        query: {
          fetchPolicy: "no-cache",
        },
      },
    });
  }, [authToken, logout, refreshAuthToken]);

  const value = useMemo(() => ({ apolloClient }), [apolloClient]);

  // Clear the cache when the user logs in or out
  useEffect(() => {
    apolloClient.cache.reset();
  }, [authToken, apolloClient]);

  if (existingClient) {
    // If there is already an apollo client, we will use that one. This
    // allows us to inject a mock client in tests.
    return (
      <ApiContext.Provider
        value={{ apolloClient: existingClient }}
        {...props}
      />
    );
  } else {
    return (
      <ApolloProvider client={apolloClient}>
        <ApiContext.Provider value={value} {...props} />
      </ApolloProvider>
    );
  }
};

const useApi = () => React.useContext(ApiContext);
export { ApiProvider, ApiContext, useApi };
