import React, { FunctionComponent } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Email } from "@mui/icons-material";

type EmailFieldProps = {
  type?: string;
  label?: any;
  error?: boolean;
  helperText?: React.ReactNode;
  value: string | null;
  settings?: { format: string };
  onChange: (value: null | string) => void;
  onBlur?: (value: null | string) => void;
  fullWidth?: boolean;
};

const EmailField: FunctionComponent<EmailFieldProps> = ({
  label,
  value,
  error,
  helperText,
  onChange,
  onBlur,
}) => {
  const handleOnBlur = (value: any) => {
    if (onBlur) {
      onBlur(value);
    }
  };

  return (
    <TextField
      type="email"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Email color="action" />
          </InputAdornment>
        ),
      }}
      label={label}
      fullWidth
      error={error}
      helperText={helperText}
      onBlur={(e) => handleOnBlur(e.target.value)}
      variant="standard"
      value={value === null ? "" : value}
      onChange={(e) => onChange(e.target.value)}
    ></TextField>
  );
};

export default EmailField;
