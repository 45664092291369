export const ACCOUNT = {
  SET: "ACCOUNT/set",
};

export type AccountState = {
  name: string;
  needsBillingInfo: boolean;
  stripeCheckoutUrl: string | null;
  trialEndDate: Date | null;
  trialEnded: boolean;
  cancelAtDate: Date | null;
  isCanceled: boolean;
};

const initialState: AccountState = {
  name: "",
  needsBillingInfo: false,
  stripeCheckoutUrl: null,
  trialEndDate: null,
  trialEnded: false,
  cancelAtDate: null,
  isCanceled: false,
};

const accountReducer = (state: AccountState = initialState, action: any) => {
  switch (action.type) {
    case ACCOUNT.SET:
      return { ...state, ...action.account };
    default:
      return state;
  }
};
export default accountReducer;
