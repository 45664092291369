import React, { useCallback, useEffect, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { connect } from "react-redux";
import moment from "moment";
import { REPORTS } from "../reducers/reports";
import {
  GET_DEALS,
  GET_TEAM_MEMBERS,
  AGGREGATE_DEALS_REPORT,
  AGGREGATE_DEALS_REPORT_BY_SOURCE,
  AGGREGATE_DEALS_REPORT_BY_TEAM_MEMBER,
} from "../api/graphql";
import SplitLayout from "../layouts/SplitLayout";
import LeftPane from "../components/LeftPane";
import DetailPane from "../components/DetailPane";
import { Grid, Theme, Paper, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ReportsSubNav from "../components/ReportsSubNav";
import ReportsFilter from "../components/reports/ReportsFilter";
import { FilterIcon } from "../icons";
import DealsTable from "../components/reports/DealsTable";
import PageTitle from "../components/PageTitle";
import { PrimaryButton } from "../components/buttons";

import { useParams } from "react-router-dom";
import ReportDataTable from "../components/reports/DataTable";
import { useUser } from "../context/user";
import TeamLayout from "../layouts/TeamLayout";
import { getFullAddress } from "../helpers/address";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import Annotation from "chartjs-plugin-annotation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Annotation,
  PointElement,
  LineElement,
  Filler
);

const pageHeaderHeight = 94;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageLayout: {
      flexGrow: 1,
      overflowX: "hidden",
      display: "flex",
      flexDirection: "row",
      marginBottom: "0",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
    },
    pageHeader: {
      padding: "40px 40px 30px 40px",
      height: `${pageHeaderHeight}px`,
      display: "flex",
    },
    tableHeader: {
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "14px",
      color: "rgb(107, 114, 128)",
    },
    hideText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    subtitleMobileStyles: {
      [theme.breakpoints.down(426)]: {
        maxWidth: "175px",
      },
    },
  })
);

const colors = [
  "#FF576D",
  "#351665",
  "#37E6D9",
  "#FFA184",
  "#7BDA5E",
  "#0B8D9B",
];

const singleDatasetChartData = (groupKey: string, dataKey: string) => {
  return (aggregatedData: any) => {
    const labels = aggregatedData.groups.map((group: any) => {
      switch (groupKey) {
        case "closedMonth":
          return moment(group[groupKey]).format("MMM");
        default:
          return group[groupKey];
      }
    });
    const data = aggregatedData.groups.map((group: any) => {
      return group.data;
    });
    return {
      labels,
      datasets: [
        {
          label: "Deals",
          backgroundColor: colors[0],
          data,
          datalabels: {
            display: false,
          },
        },
      ],
    };
  };
};

const byTeamMemberDatasetChartData = (groupKey: string, dataKey: string) => {
  return (aggregatedData: any) => {
    const labels: Array<string> = [];
    const teamMemberMap: { [index: string]: any } = {};

    const data: { [index: string]: { [index: string]: any } } = {};
    for (let i = 0; i < aggregatedData.groups.length; i++) {
      const group = aggregatedData.groups[i];
      let label: string;
      switch (groupKey) {
        case "closedMonth":
          label = moment(group[groupKey]).format("MMM");
          break;
        default:
          label = group[groupKey];
      }

      labels.push(label);
      for (let j = 0; j < group.groups.length; j++) {
        const dataGroup = group.groups[j];
        const teamMember: any = dataGroup.teamMember;
        teamMemberMap[teamMember.id as string] = teamMember;
        // Build out the data structure
        if (!data[label]) {
          data[label] = {};
        }
        data[label][teamMember.id] = dataGroup.data;
      }
    }

    // Now that we built out the initial data structure, we need to fill in any gaps.
    const teamMemberIds = Object.keys(teamMemberMap);
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      for (let j = 0; j < teamMemberIds.length; j++) {
        const teamMemberId = teamMemberIds[j];
        if (data[label][teamMemberId] === undefined) {
          data[label][teamMemberId] = {};
        }
      }
    }

    // Chart.js data
    const chartData: {
      labels: string[];
      datasets: { label: string; data: number[] }[];
    } = {
      labels,
      datasets: [],
    };

    for (let teamMemberId in teamMemberMap) {
      const teamMember = teamMemberMap[teamMemberId];
      const dataset = {
        label: teamMember?.name || "",
        backgroundColor: colors[chartData.datasets.length % colors.length],
        data: labels.map((label: string) => data[label][teamMemberId]),
        datalabels: {
          display: false,
        },
      };
      chartData.datasets.push(dataset);
    }

    return chartData;
  };
};

const makeChartOptions = (stacked = false, formatAsCurrency = false) => ({
  scales: {
    x: {
      gridLines: {
        display: false,
      },
      stacked,
    },

    y: {
      gridLines: {
        display: true,
      },
      stacked,
      ticks: {
        callback: function (value: number, index: number, values: any) {
          if (!formatAsCurrency) {
            return value;
          } else {
            return value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            });
          }
        },
      },
    },
  },
});

export const reports = [
  {
    id: "commissions",
    name: "Commissions",
    dataKey: "dealCommissionTotal",
    aggregateDealReport: AGGREGATE_DEALS_REPORT,
    toChartData: singleDatasetChartData("closedMonth", "dealCommissionTotal"),
    chartOptions: makeChartOptions(false, true),
    dealTableColumn: "commission",
    category: "Deals",
  },
  {
    id: "commissions-by-agent",
    name: "Commissions by Agent",
    dataKey: "dealCommissionTotal",
    aggregateDealReport: AGGREGATE_DEALS_REPORT_BY_TEAM_MEMBER,
    toChartData: byTeamMemberDatasetChartData(
      "closedMonth",
      "dealCommissionTotal"
    ),
    chartOptions: makeChartOptions(true, true),
    dealTableColumn: "commission",
    category: "Deals",
  },
  {
    id: "volume",
    name: "Volume",
    dataKey: "dealValueTotal",
    aggregateDealReport: AGGREGATE_DEALS_REPORT,
    toChartData: singleDatasetChartData("closedMonth", "dealValueTotal"),
    chartOptions: makeChartOptions(false, true),
    dealTableColumn: "value",
    category: "Deals",
  },
  {
    id: "volume-by-agent",
    name: "Volume by Agent",
    dataKey: "dealValueTotal",
    aggregateDealReport: AGGREGATE_DEALS_REPORT_BY_TEAM_MEMBER,
    toChartData: byTeamMemberDatasetChartData("closedMonth", "dealValueTotal"),
    chartOptions: makeChartOptions(true, true),
    dealTableColumn: "value",
    category: "Deals",
  },
  {
    id: "sources",
    name: "Sources",
    dataKey: "dealCount",
    aggregateDealReport: AGGREGATE_DEALS_REPORT_BY_SOURCE,
    toChartData: singleDatasetChartData("source", "dealCount"),
    chartOptions: makeChartOptions(),
    dealTableColumn: "source",
    category: "Deals",
  },
];

const ReportsPage = ({
  report,
  setReport,
  setDeals,
  setAgents,
}: any): JSX.Element => {
  const { currentUser } = useUser();
  const classes = useStyles();
  const apollo = useApolloClient();

  const [chartData, setChartData] = useState<any>();

  const { agents, dateRange, filter } = report;

  const handleGetDeals = useCallback(
    (data: any) => {
      const mungedDeals = data.getDeals.deals.map((deal: any) => {
        deal.addressFields = deal.address;
        deal.address = getFullAddress(deal.address);
        if (deal.contacts.length) {
          deal.name = deal.contacts[0].name;
        } else {
          deal.name = "";
        }
        deal.checked = true;
        deal.agent = deal.owner?.name || "";
        deal.agentId = deal.owner?.id;

        return deal;
      });
      setDeals(mungedDeals);
    },
    [setDeals]
  );

  const handleGetTeamMembers = useCallback(
    (res: any) => {
      if (!agents || agents.length === 0) {
        const mutatedAgents = res.getTeamMembers.map((agent: any) => {
          if (!agent.checked) agent.checked = true;
          return agent;
        });
        setAgents(mutatedAgents);
      }
    },
    [agents, setAgents]
  );

  useQuery(GET_TEAM_MEMBERS, {
    onCompleted: handleGetTeamMembers,
  });

  // Determine the type of report
  const params = useParams<{ reportId?: string }>();
  useEffect(() => {
    let report = reports.find((report) => report.id === params.reportId);
    if (!report) {
      // TODO: Do something more reasonable here
      report = reports[0];
    }
    setChartData(null);
    setReport(report);
  }, [params, setReport]);

  // Fetch the aggregated data for the report
  useEffect(() => {
    if (!report.aggregateDealReport) {
      return;
    }

    const variables: any = {
      startDate: moment(report.dateRange.from).format("YYYY-MM-DD"),
      endDate: moment(report.dateRange.to).format("YYYY-MM-DD"),
      teamMemberIds: agents
        .filter((agent: any) => agent.checked)
        .map((agent: any) => agent.id),
    };
    if (report.filter.includePending === false) {
      // Only include deals that are closed.
      variables.state = "closed";
    }

    apollo
      .query({
        query: report.aggregateDealReport,
        variables,
      })
      .then((res) => {
        // Generate chart data
        if (report.toChartData) {
          const chartData = report.toChartData(res.data.aggregateDealReport);
          if (chartData) {
            setChartData(chartData);
          }
        }
      })
      .catch((e) => {
        // Do nothing - charts won't load
      });
  }, [report, agents, apollo]);

  useEffect(() => {
    apollo
      .query({
        query: GET_DEALS,
        variables: {
          closingBefore: dateRange.to,
          closingAfter: dateRange.from,
          state: filter.includePending ? undefined : "closed",
          showAll: true,
          includeArchived: true,
          pageSize: 9999, // we want all deals in timeframe and this overrides the default of 10
        },
      })
      .then((res) => {
        return handleGetDeals(res.data);
      });
  }, [dateRange, filter, apollo, handleGetDeals]);

  const formatChartData = (): any => {
    return Object.assign({}, chartData, {
      datasets: chartData.datasets.map((dataset: any) => {
        return Object.assign({}, dataset, {
          data: dataset.data.map((data: any) => data[report.dataKey] || 0),
        });
      }),
    });
  };

  if (currentUser?.permissions?.reports === "none") {
    return (
      <TeamLayout>
        <Box p={4} component={Paper} textAlign="center">
          <p>Your account does not have access to reporting</p>
        </Box>
      </TeamLayout>
    );
  }

  return (
    <SplitLayout>
      <LeftPane width={224}>
        <ReportsSubNav reports={reports} />
      </LeftPane>
      <DetailPane>
        <Grid container className={classes.pageLayout} spacing={2}>
          <Grid item xs={12}>
            <PageTitle
              showOnMobile={true}
              title={report.name}
              subtitle={
                <div className={classes.subtitleMobileStyles}>
                  Date Range:{" "}
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bolder",
                    }}
                  >
                    {moment(dateRange.from).format("LL")} to{" "}
                    {moment(dateRange.to).format("LL")}
                  </span>
                </div>
              }
              actions={
                <ReportsFilter reports={report}>
                  <PrimaryButton>
                    <FilterIcon size={"16px"} style={{ paddingRight: "5px" }} />
                    Filter Data
                  </PrimaryButton>
                  {/* <ActionIconButton icon={FilterIcon} /> */}
                </ReportsFilter>
              }
            ></PageTitle>
          </Grid>
          {report.allDeals.length === 0 ? (
            <Grid item xs={12}>
              <Paper>
                <Box p={2} textAlign="center">
                  <Typography variant="h6">
                    We didn't find any deals!
                  </Typography>
                  <Typography>
                    Deals will appear in reports when they have a closing date
                    that falls within the selected date range
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ) : (
            <>
              {chartData && (
                <Grid item xs={12}>
                  <Bar
                    data={formatChartData()}
                    options={report.chartOptions}
                    height={75}
                  />
                </Grid>
              )}
              {chartData && (
                <Grid item xs={12}>
                  <ReportDataTable data={chartData} dataKey={report.dataKey} />
                </Grid>
              )}

              {true && (
                <Grid item xs={12}>
                  <DealsTable
                    reports={report}
                    columnToShow={report.dealTableColumn}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DetailPane>
    </SplitLayout>
  );
};

const mapStateToProps = ({ reports }: any) => {
  return { report: reports };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    setReport: (report: any) => {
      return dispatch({ type: REPORTS.SET, report });
    },
    setDeals: (deals: any) => {
      return dispatch({ type: REPORTS.SET_DEALS, deals });
    },
    setAgents: (agents: any) => {
      return dispatch({ type: REPORTS.SET_AGENTS, agents });
    },
    setChartInfo: (chartInfo: any) => {
      return dispatch({ type: REPORTS.SET_CHART_INFO, chartInfo });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
