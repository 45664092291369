import React, { FunctionComponent, useContext } from "react";
import {
  Navigate,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import DetailPane from "../../../../components/DetailPane";
import { TabGroup, Tab } from "../../../../components/Tabs";
import Tasks from "./Tasks";
import Documents from "./Documents";
import Timeline from "./Timeline";
import Notes from "./Notes";
import Details from "./details/DealDetailsContainer";
import { dealViewStore } from "../context";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createDragDropManager } from "dnd-core";

export const manager = createDragDropManager(HTML5Backend);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabGroup: {
      marginBottom: theme.spacing(2),
    },
  })
);

const TabsView: FunctionComponent = () => {
  const DEFAULT_TAB = "details";
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    state: { tasks, deal },
  } = useContext(dealViewStore);

  const tabValue = () => {
    const pathParts = location.pathname.split("/");
    const tabValue = pathParts.slice(-1)[0];
    if (pathParts.length < 4 || tabValue === "") {
      return DEFAULT_TAB;
    } else if (
      ["details", "notes", "tasks", "timeline", "documents"].includes(tabValue)
    ) {
      return tabValue;
    } else {
      return DEFAULT_TAB;
    }
  };

  return (
    <DetailPane>
      <TabGroup
        value={tabValue()}
        className={classes.tabGroup}
        variant="scrollable"
        scrollButtons="on"
      >
        <Tab
          label="Details"
          value="details"
          onClick={() => navigate("./details")}
        />
        <Tab label="Notes" value="notes" onClick={() => navigate("./notes")} />
        <Tab
          label="Tasks"
          value="tasks"
          onClick={() => navigate("./tasks")}
          badgeContent={tasks?.filter((task) => task.pastDue).length}
          badgeMax={9}
          badgeColor="error"
        />
        <Tab
          label="Timeline"
          value="timeline"
          onClick={() => navigate("./timeline")}
        />
        <Tab
          label="Documents"
          value="documents"
          onClick={() => navigate("./documents")}
        />
      </TabGroup>
      <Routes>
        <Route path="details" element={<Details />} />
        <Route path="notes" element={<Notes />} />
        <Route path="tasks" element={<Tasks />} />
        <Route path="timeline" element={<Timeline />} />
        <Route
          path="documents"
          element={
            <DndProvider manager={manager}>
              <Documents />
            </DndProvider>
          }
        />
        <Route
          path=""
          element={
            deal.id ? (
              <Navigate to={`/deals/${deal.id}/${DEFAULT_TAB}`} />
            ) : null
          }
        ></Route>
      </Routes>
    </DetailPane>
  );
};

export default TabsView;
