import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Typography, Skeleton, Paper, Grid } from "@mui/material";
import DetailCard from "../DetailCard";
import { Box } from "@mui/system";
import ReportTable from "./Table";
import DoughnutChart from "./DoughnutChart";
import BarChart from "./MonthlyChart";

export const GET_AGGREGATE_DATA = gql`
  fragment AggregateData on DealReportAggregate {
    units {
      total
      average
    }
    volume {
      total
      average
    }
    commission {
      total
      average
    }
    timeToClose {
      total
      average
    }
  }
  query GET_AGGREGATE_DATA(
    $state: DealState
    $listingBefore: Date
    $listingAfter: Date
    $groupBy: String
    $ownerId: ID
  ) {
    getReportAggregates(
      state: $state
      listingBefore: $listingBefore
      listingAfter: $listingAfter
      groupBy: $groupBy
      ownerId: $ownerId
    ) {
      dealCount
      years {
        year
        ...AggregateData
        months {
          month
          ...AggregateData
        }
      }
      byDealState {
        years {
          year
          ...AggregateData
          months {
            month
            ...AggregateData
          }
        }
        groups {
          dealState
          years {
            year
            ...AggregateData
            months {
              month
              ...AggregateData
            }
          }
        }
      }
    }
  }
`;

const InsightsReport = ({ report, dateRange }: any) => {
  const [tableHeaders, setTableHeaders] = useState();
  const [closedData, setClosedData] = useState();
  const { data, loading } = useQuery(GET_AGGREGATE_DATA, {
    variables: {
      groupBy: "listingDate",
      listingAfter: dateRange.from,
      listingBefore: dateRange.to,
    },
  });

  const cleanDealStateLabels = (groups: any) => {
    const cleanedGroups = groups.map((group: any) => {
      if (!group) return null;

      const words = group.dealState.split("_");
      const upperCasedWords = words.map(
        (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
      );

      const label = upperCasedWords.join(" ");

      return { ...group, dealState: label };
    });

    return cleanedGroups;
  };

  useEffect(() => {
    if (data && data.getReportAggregates?.years?.length > 0) {
      let months = data.getReportAggregates.years[0].months;
      months = months.map((month: any) => {
        const date = new Date();
        date.setMonth(month.month - 1);
        return date.toLocaleString("en-US", {
          month: "short",
        });
      });
      setTableHeaders(months);

      setClosedData(
        data.getReportAggregates.byDealState.groups.filter(
          (group: any) => group.dealState === "closed"
        )
      );
    }
  }, [data]);

  if (loading) {
    return <Skeleton />;
  }

  if (
    (!loading && data?.getReportAggregates?.dealCount === 0) ||
    !tableHeaders
  ) {
    return (
      <Grid item xs={12}>
        <Box p={4} component={Paper} textAlign="center" sx={{ width: "100%" }}>
          <Typography variant="h6">No deals were found!</Typography>
          <Typography variant="body1">
            Reports will show when we find deals that have closed in the
            selected date range.
          </Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      {closedData ? (
        <>
          <DetailCard
            title={<Typography variant="h5">Average Sale Price</Typography>}
          >
            <Box p={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BarChart
                    data={closedData}
                    getValue={(d) => d.volume}
                    label="Average Sales Price"
                    dataKey="average"
                    line={true}
                  />
                </Grid>
                <ReportTable
                  tableHeaders={tableHeaders}
                  tableData={closedData}
                  report={{
                    getAggBundle: (d: any) => d.volume,
                    formatter: report.currencyFormatter,
                  }}
                  cellKey="average"
                  label="Avg. Sales Price"
                />
              </Grid>
            </Box>
          </DetailCard>
          {/* COMBINED GRAPHS AND TABLE */}
          {/* <DetailCard
                      title={<Typography variant="h5">Per Deal</Typography>}
                    >
                      <Box p={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <BarChart
                              data={[aggregatedData]}
                              getValue={(d) => d.commission}
                              label="Average Commission"
                              dataKey="average"
                              line={true}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <BarChart
                              data={[aggregatedData]}
                              getValue={(d) => d.volume}
                              label="Average Sales Price"
                              dataKey="average"
                              colorIndex={1}
                              line={true}
                            />
                          </Grid>
                          <InsightsTable
                            tableHeaders={tableHeaders}
                            tableData={[aggregatedData]}
                            stats={aggregatedData.years?.[0]}
                            report={report}
                            cellKey="average"
                          />
                        </Grid>
                      </Box>
                    </DetailCard> */}
          <DetailCard
            title={<Typography variant="h5">Average Time To Close</Typography>}
          >
            <Box p={4}>
              <Grid container spacing={2}>
                {/* TIME TO CLOSE BY TEAM MEMBER */}
                {/* <Grid item xs={12}>
                            <BarChart
                              data={aggregatedData.byTeamMembers.groups}
                              getValue={(d) => d.timeToClose}
                              dataKey="average"
                            />
                          </Grid> */}
                {/* <ReportTable
                            // showAverages
                            tableHeaders={tableHeaders}
                            tableData={aggregatedData.byTeamMembers.groups}
                            stats={aggregatedData.byTeamMembers.years?.[0]}
                            report={report}
                            cellKey="average"
                          /> */}

                {/* TIME TO CLOSE OVERALL */}
                <Grid item xs={12}>
                  <BarChart
                    data={closedData}
                    getValue={(d) => d.timeToClose}
                    label="Average Days to Close"
                    dataKey="average"
                  />
                </Grid>
                <ReportTable
                  tableHeaders={tableHeaders}
                  tableData={closedData}
                  report={report}
                  label="Average Days to Close"
                  cellKey="average"
                />
              </Grid>
            </Box>
          </DetailCard>
          <DetailCard
            title={
              <Typography variant="h5">
                Average Commission (Per Deal)
              </Typography>
            }
          >
            <Box p={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BarChart
                    data={closedData}
                    getValue={(d) => d.commission}
                    label="Average Commission"
                    dataKey="average"
                    line={true}
                  />
                </Grid>
                <ReportTable
                  tableHeaders={tableHeaders}
                  tableData={closedData}
                  report={{
                    getAggBundle: (d: any) => d.commission,
                    formatter: report.currencyFormatter,
                  }}
                  cellKey="average"
                  label="Avg. Commission"
                />
              </Grid>
            </Box>
          </DetailCard>
        </>
      ) : (
        <Box p={4} component={Paper} textAlign="center">
          <Typography variant="h6">No deals were found!</Typography>
          <Typography variant="body1">
            We'll show this report when there are closed deals.
          </Typography>
        </Box>
      )}
      <DetailCard title={<Typography variant="h5">Deal Status</Typography>}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box width={"40%"}>
                <DoughnutChart
                  showLegend={true}
                  data={cleanDealStateLabels(
                    data.getReportAggregates.byDealState.groups
                  )}
                  getValue={(d) => d.years[0].units}
                />
              </Box>
            </Grid>
            <ReportTable
              tableHeaders={tableHeaders}
              tableData={cleanDealStateLabels(
                data.getReportAggregates.byDealState.groups
              )}
              report={{
                getAggBundle: (d: any) => d.units,
                formatter: report.formatter,
              }}
            />
          </Grid>
        </Box>
      </DetailCard>
    </Grid>
  );
};

export default InsightsReport;
