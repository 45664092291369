import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Box,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Close, InfoOutlined } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import ActionIconButton from "../ActionIconButton";
import { Note } from "../../models";
import { useAlert } from "../../context/alert";
import { SecondaryButton, PrimaryButton } from "../buttons";
import { EDIT_NOTE } from "../../api/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      minHeight: "100px",
    },
  })
);

interface EditNoteProps {
  note: Note | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onNoteEdited: (note: Note) => {};
}

const EditNote = ({ note, open, setOpen, onNoteEdited }: EditNoteProps) => {
  const classes = useStyles();
  const { showSuccess, showError } = useAlert();
  const [editNote] = useMutation(EDIT_NOTE);
  const [updatedNoteBody, setUpdatedNoteBody] = useState(note?.body);

  const handleClose = () => {
    setOpen(false);
  };

  const handleNoteSave = () => {
    editNote({
      variables: {
        input: {
          id: note?.id,
          body: updatedNoteBody,
        },
      },
    })
      .then((res) => {
        onNoteEdited(res.data.editNote);
      })
      .then(() => showSuccess("Note successfully saved"))
      .catch((res) => {
        showError("Unable to save note");
      })
      .finally(() => handleClose());
  };

  useEffect(() => {
    if (note && note.body) {
      setUpdatedNoteBody(note?.body);
    }
  }, [note]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Edit Note</Typography>
          <ActionIconButton
            icon={Close}
            style={{ margin: "4px -5px" }}
            buttonStyle={{ width: "22px", height: "22px" }}
            onClick={handleClose}
            aria-label="close"
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          autoFocus
          label="Edit note"
          name="noteBody"
          variant="outlined"
          multiline
          inputProps={{ className: classes.input }}
          onChange={(e) => setUpdatedNoteBody(e.target.value)}
          value={updatedNoteBody}
          sx={{ marginTop: "10px" }}
        />
        {note?.task && (
          <Typography
            color="textSecondary"
            style={{ display: "flex", alignItems: "center", marginTop: 11 }}
          >
            <InfoOutlined />
            &nbsp;Notes on tasks are internal and not visible to clients.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        <PrimaryButton onClick={handleNoteSave}> Save Note</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditNote;
