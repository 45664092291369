import React, { useReducer, ChangeEvent, useState, useCallback } from "react";
import { connect } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  Dialog,
  IconButton,
  DialogContentText,
  DialogActions,
  DialogTitle,
  DialogContent,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Typography,
  ListItemText,
  List,
  ListItem,
  ListItemSecondaryAction,
  Link,
  Tooltip,
  SelectChangeEvent,
  FormGroup,
} from "@mui/material";
import { PencilIcon, TaskIcon } from "../../icons";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "../../context/alert";
import { useQuery } from "@apollo/client";
import { GET_FIELDS } from "../../api/graphql";
import DetailCard from "../DetailCard";
import AddIcon from "@mui/icons-material/Add";
import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  createStageTask,
  updateStageTask,
  deleteStageTask,
  CreateStageTaskInput,
  UpdateStageTaskInput,
} from "../../actions/stageTasks";
import { Role, Stage } from "../../models";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { ReactSortable } from "react-sortablejs";
import { ApiClient, useApi } from "../../context/api";

type StageTasksProps = {
  stage: Stage;
  roles: Role[];
  editable?: boolean;
  createStageTask: (
    stageTask: CreateStageTaskInput,
    apolloClient: ApiClient
  ) => Promise<any>;
  updateStageTask: (
    stageTask: UpdateStageTaskInput,
    stageId: string,
    apolloClient: ApiClient
  ) => Promise<any>;
  deleteStageTask: (
    id: string,
    stageId: string,
    apolloClient: ApiClient
  ) => Promise<any>;
};
const StageTasks = ({
  stage,
  roles,
  editable = true,
  createStageTask,
  updateStageTask,
  deleteStageTask,
}: StageTasksProps): JSX.Element => {
  const DEAL_CONTACTS = "DEAL_CONTACTS";

  const intialTaskState = {
    toRoleId: stage.dealType.dealOwnerRole.id,
    name: "",
    hasDueDate: false,
    dueDateDays: 7,
    dueDatePreposition: "after",
    dueDateTargetColumnName: "stage_enter",
  };

  const { apolloClient } = useApi();

  const [open, setOpen] = useState(false);
  const { showSuccess, showError } = useAlert();
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "SET_TASK":
        return Object.assign({}, state, {
          task: action.task,
        });

      case "SET_TO":
        return Object.assign({}, state, {
          task: {
            ...state.task,
            toRoleId: action.toRoleId,
            toDealContacts: action.toDealContacts,
          },
        });

      case "SET_NAME":
        return Object.assign({}, state, {
          task: {
            ...state.task,
            name: action.name,
          },
        });

      case "SET_HAS_DUE_DATE":
        return Object.assign({}, state, {
          task: {
            ...state.task,
            hasDueDate: action.hasDueDate,
          },
        });
      case "SET_DUE_DATE_DAYS":
        return Object.assign({}, state, {
          task: {
            ...state.task,
            dueDateDays: action.dueDateDays,
          },
        });
      case "SET_DUE_DATE_PREPOSITION":
        return Object.assign({}, state, {
          task: {
            ...state.task,
            dueDatePreposition: action.dueDatePreposition,
          },
        });
      case "SET_DUE_DATE_TARGET":
        return Object.assign({}, state, {
          task: {
            ...state.task,
            dueDateTargetColumnName: action.dueDateTargetColumnName || null,
            dueDateTargetCustomFieldId:
              action.dueDateTargetCustomFieldId || null,
          },
        });
      case "SET_CUSTOM_DATE_FIELDS":
        return Object.assign({}, state, {
          customDateFields: action.customDateFields,
        });
      case "SET_SYSTEM_DATE_FIELDS":
        return Object.assign({}, state, {
          systemDateFields: action.systemDateFields,
        });

      case "SET_SHOW_IN_CLIENT_PORTAL":
        return Object.assign({}, state, {
          task: {
            ...state.task,
            showInClientPortal: action.showInClientPortal,
          },
        });
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    task: intialTaskState,
    systemDateFields: [],
    customDateFields: [],
  });

  const handleAdd = () => {
    dispatch({
      type: "SET_TASK",
      task: intialTaskState,
    });
    setOpen(true);
  };

  const handleEdit = (e: any, task: any) => {
    if (!task.hasDueDate) {
      // init any null values so that we can have controlled components
      task.dueDatePreposition = intialTaskState.dueDatePreposition;
      task.dueDateTargetColumnName = intialTaskState.dueDateTargetColumnName;
      task.dueDateDays = intialTaskState.dueDateDays;
    }
    dispatch({
      type: "SET_TASK",
      task: { ...intialTaskState, ...task },
    });
    setOpen(true);
  };

  const handleDelete = (e: any, task: any) => {
    deleteStageTask(task.id, stage.id, apolloClient)
      .then(() => {})
      .catch(() => {
        showError("An error occurred when deleting the task");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    // Cleanup our task
    const task = Object.assign({}, state.task);

    // React SortableJS adds the properties `chosen` and `selected` which
    // the mutation doesn't expect.
    delete task.chosen;
    delete task.selected;

    if (task.toRoleId === DEAL_CONTACTS) {
      delete task.toRoleId;
    }

    if (!task.hasDueDate) {
      task.dueDateDays = null;
      task.dueDatePreposition = null;
      task.dueDateTargetColumnName = null;
      task.dueDateTargetCustomFieldId = null;
    }

    // Determine if we're creating or updating.
    let promise: Promise<any>;
    if (!task.id) {
      task.stageId = stage.id;
      promise = createStageTask(task, apolloClient);
    } else {
      promise = updateStageTask(task, stage.id, apolloClient);
    }

    // Execute the mutation
    promise
      .then(() => {
        handleClose();
        showSuccess("Your task was saved successfully");
      })
      .catch((err: any) => {
        showError("An error occurred when saving the task");
      });
  };

  const handleToChange = (e: SelectChangeEvent) => {
    dispatch({
      type: "SET_TO",
      toRoleId: e.target.value,
      toDealContacts: e.target.value === DEAL_CONTACTS,
    });
  };

  const handleNameChange = (e: ChangeEvent<any>) => {
    dispatch({
      type: "SET_NAME",
      name: e.target.value,
    });
  };

  const handleDueDatePrepositionChange = (e: SelectChangeEvent) => {
    dispatch({
      type: "SET_DUE_DATE_PREPOSITION",
      dueDatePreposition: e.target.value,
    });
  };

  const handleDueDateDaysChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "SET_DUE_DATE_DAYS",
      dueDateDays: parseInt(e.target.value, 10),
    });
  };

  const handleShowInClientPortalChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch({
      type: "SET_SHOW_IN_CLIENT_PORTAL",
      showInClientPortal: checked,
    });
  };

  const handleDueDateTargetChange = (e: SelectChangeEvent) => {
    const value = e.target.value;
    const customField = state.customDateFields.find((f: any) => f.id === value);
    dispatch({
      type: "SET_DUE_DATE_TARGET",
      dueDateTargetColumnName: customField ? null : e.target.value,
      dueDateTargetCustomFieldId: customField ? customField.id : null,
    });
  };

  const handleHasDueDateChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch({ type: "SET_HAS_DUE_DATE", hasDueDate: checked });
  };

  const onCompleted = useCallback((data: any) => {
    const customDateFields: any = data.getCustomFields.filter(
      (f: any) => f.type === "datetime"
    );
    const systemDateFields: any = data.getSystemFields.filter(
      (f: any) => f.type === "datetime"
    );

    dispatch({
      type: "SET_CUSTOM_DATE_FIELDS",
      customDateFields,
    });
    dispatch({
      type: "SET_SYSTEM_DATE_FIELDS",
      systemDateFields,
    });
  }, []);

  useQuery(GET_FIELDS, {
    variables: {
      tableName: "deal",
    },
    onCompleted,
  });

  const setTasks = (tasks: any[]) => {
    tasks.forEach((stageTask: any, index: number) => {
      if (stageTask.sort !== index) {
        stageTask.sort = index;
        updateStageTask(
          { id: stageTask.id, sort: index },
          stage.id,
          apolloClient
        ).catch(() => {});
      }
    });
  };

  const dueDateTargetToPhrase = (stageTask: any): string => {
    if (
      stageTask.dueDateTargetColumnName === "stageEnter" ||
      stageTask.dueDateTargetColumnName === "stage_enter"
    ) {
      return "a deal enters this stage";
    }

    const fieldFinder = (id: any) => {
      return (f: any) => id === f.id;
    };
    let field;
    if (stageTask.dueDateTargetColumnName) {
      field = state.systemDateFields.find(
        fieldFinder(stageTask.dueDateTargetColumnName)
      );
    } else if (stageTask.dueDateTargetCustomFieldId) {
      field = state.customDateFields.find(
        fieldFinder(stageTask.dueDateTargetCustomFieldId)
      );
    }
    if (field) {
      return field.label;
    } else {
      return "";
    }
  };
  const DescribeTask = ({ task }: { task: any }) => {
    let to = "";

    if (task.toRoleId) {
      to = roles.find((r) => r.id === task.toRoleId)?.name ?? "";
    } else {
      to = stage.dealType.clientNoun;
    }

    let dueText = "";
    if (task.hasDueDate) {
      if (task.dueDateDays === 0) {
      } else if (task.dueDateDays === 1) {
        dueText += ` ${task.dueDateDays} day`;
      } else {
        dueText += ` ${task.dueDateDays} days`;
      }
    }
    dueText += " ";
    dueText += task.dueDateDays === 0 ? "on" : task.dueDatePreposition;
    dueText += " ";
    dueText += dueDateTargetToPhrase(task);

    return (
      <Typography
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        <span>
          Assign <Link>{task.name}</Link>
        </span>
        <ArrowRightAltIcon
          style={{ marginLeft: "11px", marginRight: "11px" }}
        />
        <span>To {to}</span>
        {task.hasDueDate && (
          <>
            <ArrowRightAltIcon
              style={{ marginLeft: "11px", marginRight: "11px" }}
            />
            <span>Due {dueText}</span>
          </>
        )}
      </Typography>
    );
  };

  return (
    <>
      <DetailCard
        collapsible={false}
        title={
          <Typography
            variant="h5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <TaskIcon
              style={{
                background: "#351665",
                color: "#fff",
                padding: "4px 4.5px",
                borderRadius: "50%",
                height: "22px",
                width: "22px",
                marginRight: "14px",
              }}
            />
            Tasks
          </Typography>
        }
        action={
          <Tooltip title="Add" aria-label="add a task">
            <IconButton onClick={handleAdd} disabled={!editable} size="large">
              <AddIcon />
            </IconButton>
          </Tooltip>
        }
      >
        {stage.tasks.length === 0 && (
          <Box textAlign="center" style={{ width: "100%" }} p={4}>
            <Typography style={{ marginBottom: "11px" }}>
              Assign tasks to your teammates or your{" "}
              {stage.dealType.clientNoun.toLowerCase()} when a deal enters this
              stage.
            </Typography>
            <PrimaryButton
              onClick={handleAdd}
              style={{ minWidth: "200px" }}
              disabled={!editable}
            >
              Add a task
            </PrimaryButton>
          </Box>
        )}

        {stage.tasks.length > 0 && (
          <List style={{ width: "100%" }} className="actions-on-hover">
            <ReactSortable
              list={stage.tasks}
              setList={setTasks}
              handle=".handle"
            >
              {stage.tasks.map((task: any) => (
                <ListItem key={task.id}>
                  {stage.tasks.length > 1 && editable && (
                    <DragIndicatorIcon
                      className="handle"
                      style={{
                        color: "#6b7280",
                        marginRight: "11px",
                        cursor: "grab",
                      }}
                    />
                  )}

                  <ListItemText primary={DescribeTask({ task })} />

                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={(e) => handleEdit(e, task)}
                      disabled={!editable}
                      size="large"
                    >
                      <PencilIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => handleDelete(e, task)}
                      disabled={!editable}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </ReactSortable>
          </List>
        )}
      </DetailCard>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Configure a task</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This task will be created when a deal enters the{" "}
            <strong>{stage.name}</strong> stage.
          </DialogContentText>
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="select-to">Assign to</InputLabel>
                <Select
                  inputProps={{
                    id: "select-to",
                  }}
                  fullWidth
                  native
                  value={state.task.toRoleId}
                  onChange={handleToChange}
                >
                  <option value={DEAL_CONTACTS}>
                    {stage.dealType.clientNoun}
                  </option>
                  {roles.map((role: any) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Task"
                placeholder="e.g. Perform title search"
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                fullWidth
                value={state.task.name}
                onChange={handleNameChange}
                variant="standard"
              />
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleShowInClientPortalChange}
                      checked={state.task.showInClientPortal}
                    />
                  }
                  label="Show in client portal"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleHasDueDateChange}
                      checked={state.task.hasDueDate}
                    />
                  }
                  label="Add a due date"
                />
              </FormGroup>
            </Grid>
            {state.task.hasDueDate && (
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    borderRadius: 1,
                    padding: 1,
                    backgroundColor: "background.default",
                  }}
                >
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      type="number"
                      value={state.task.dueDateDays ?? ""}
                      onChange={handleDueDateDaysChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {state.task.dueDateDays === 1 ? "day" : "days"}
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth variant="standard">
                      <Select
                        fullWidth
                        native
                        value={state.task.dueDatePreposition}
                        onChange={handleDueDatePrepositionChange}
                      >
                        <option value="after">After</option>
                        <option value="before">Before</option>
                        <option value="on">On</option>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="standard">
                      <Select
                        fullWidth
                        native
                        value={
                          state.task.dueDateTargetCustomFieldId ||
                          state.task.dueDateTargetColumnName
                        }
                        onChange={handleDueDateTargetChange}
                      >
                        <option value="stageEnter">
                          A deal enters this stage
                        </option>
                        {state.systemDateFields.map((f: any) => (
                          <option key={f.id} value={f.id}>
                            {f.label}
                          </option>
                        ))}
                        {state.customDateFields.length && (
                          <optgroup label="Custom Fields">
                            {state.customDateFields.map((f: any) => (
                              <option key={f.id} value={f.id}>
                                {f.label}
                              </option>
                            ))}
                          </optgroup>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            onClick={handleClose}
            color="primary"
            style={{ width: "100px" }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSave}
            color="primary"
            style={{ width: "100px" }}
          >
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ roles }: { roles: Role[] }) => {
  return { roles };
};

export default connect(mapStateToProps, {
  createStageTask,
  updateStageTask,
  deleteStageTask,
})(StageTasks);
