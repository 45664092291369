import React from "react";
import { connect } from "react-redux";
import { Feature } from "../reducers/features";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { List, ListItem, ListItemText } from "@mui/material";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Account } from "../models";
import { useUser } from "../context/user";

const drawerWidth = 312;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      height: "100%",
      flexShrink: 0,
      whiteSpace: "nowrap",
      background: "#ffffff",
      [theme.breakpoints.up("md")]: {
        minHeight: "100vh",
      },
    },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    listItem: {
      fontSize: "14px",
      fontWeight: 500,
      background: "#ffffff",
      [theme.breakpoints.down("md")]: {
        width: 0,
        display: "none",
      },
    },

    settingsHeader: {
      [theme.breakpoints.down("md")]: {
        width: 0,
        display: "none",
      },
    },
  })
);

const SettingsSubNav = ({
  features,
  account,
}: {
  features: { [key: string]: Feature };
  account: Account;
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { hasPermission } = useUser();

  const isSelected = (key: string) => {
    if (key === "") {
      return location.pathname === "/";
    } else {
      let toMatch = location.pathname;
      if (key.indexOf("#") !== -1) {
        toMatch += location.hash;
      }
      return (
        toMatch.indexOf(`/${key}`) === 0 && toMatch.length === `/${key}`.length
      );
    }
  };

  return (
    <div className={classes.drawer}>
      <List>
        <ListItem className={classes.settingsHeader}>
          <h3>Account Settings</h3>
        </ListItem>
        {hasPermission("settings", "full") && (
          <ListItem
            button
            component={RouterLink}
            to="/settings/account"
            selected={isSelected("settings/account")}
            className={classes.listItem}
          >
            <ListItemText>Account</ListItemText>
          </ListItem>
        )}
        <ListItem
          button
          component={RouterLink}
          to="/settings/team"
          selected={isSelected("settings/team")}
          className={classes.listItem}
        >
          <ListItemText>Collaborators</ListItemText>
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/settings/roles"
          selected={isSelected("settings/roles")}
          className={classes.listItem}
        >
          <ListItemText>Roles</ListItemText>
        </ListItem>

        <ListItem
          button
          component={RouterLink}
          to="/settings/email-templates"
          selected={isSelected("settings/email-templates")}
          className={classes.listItem}
        >
          <ListItemText>Message Templates</ListItemText>
        </ListItem>

        <ListItem
          button
          component={RouterLink}
          to="/settings/fields"
          selected={isSelected("settings/fields")}
          className={classes.listItem}
        >
          <ListItemText>Fields</ListItemText>
        </ListItem>

        <ListItem
          button
          component={RouterLink}
          to="/settings/stages"
          selected={isSelected("settings/stages")}
          className={classes.listItem}
        >
          <ListItemText>Deal Stages</ListItemText>
        </ListItem>

        {features["Document Types"] && (
          <ListItem
            button
            component={RouterLink}
            to="/settings/documents"
            selected={isSelected("settings/documents")}
            className={classes.listItem}
          >
            Document Checklist
          </ListItem>
        )}

        {features.Workflows && (
          <ListItem
            button
            component={RouterLink}
            to="/settings/workflows"
            selected={isSelected("settings/workflows")}
            className={classes.listItem}
          >
            <ListItemText>Workflows</ListItemText>
          </ListItem>
        )}

        {hasPermission("settings", "full") && (
          <ListItem
            button
            component={RouterLink}
            to="/settings/integrations"
            selected={isSelected("settings/integrations")}
            className={classes.listItem}
          >
            <ListItemText>Integrations</ListItemText>
          </ListItem>
        )}

        <ListItem
          button
          component={RouterLink}
          to="/settings/timeline"
          selected={isSelected("settings/timeline")}
          className={classes.listItem}
        >
          <ListItemText>Timeline</ListItemText>
        </ListItem>

        {features["Goals"] && (
          <ListItem
            button
            component={RouterLink}
            to="/settings/goals"
            selected={isSelected("settings/goals")}
            className={classes.listItem}
          >
            <ListItemText>Goals</ListItemText>
          </ListItem>
        )}
      </List>
      <List>
        <ListItem className={classes.settingsHeader}>
          <h3>User Settings</h3>
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/settings/profile"
          selected={isSelected("settings/profile")}
          className={classes.listItem}
        >
          <ListItemText>Profile</ListItemText>
        </ListItem>

        {!hasPermission("settings", "full") && (
          <ListItem
            button
            component={RouterLink}
            to="/settings/integrations"
            selected={isSelected("settings/integrations")}
            className={classes.listItem}
          >
            <ListItemText>Integrations</ListItemText>
          </ListItem>
        )}
        <ListItem
          button
          component={RouterLink}
          to="/settings/email"
          selected={isSelected("settings/email")}
          className={classes.listItem}
        >
          <ListItemText>Email</ListItemText>
        </ListItem>

        {features["Text Messaging"] && (
          <ListItem
            button
            component={RouterLink}
            to="/settings/phone"
            selected={isSelected("settings/phone")}
            className={classes.listItem}
          >
            <ListItemText>Phone &amp; SMS</ListItemText>
          </ListItem>
        )}

        <ListItem
          button
          component={RouterLink}
          to="/settings/calendar"
          selected={isSelected("settings/calendar")}
          className={classes.listItem}
        >
          <ListItemText>Calendar</ListItemText>
        </ListItem>
      </List>
    </div>
  );
};

const mapStateToProps = ({
  features,
  account,
}: {
  features: { [key: string]: Feature };
  account: Account;
}) => {
  return { features, account };
};

export default connect(mapStateToProps)(SettingsSubNav);
