import React, {
  useState,
  FunctionComponent,
  ChangeEvent,
  useEffect,
} from "react";
import { InputAdornment, InputProps, TextField } from "@mui/material";
import NumberFormat from "react-number-format";

type NumberFieldProps = {
  label?: any;
  value: number | string | null | undefined;
  settings?: any;
  onChange?: (value: null | number) => void;
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props: any,
  ref: any
) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
});

const NumberField: FunctionComponent<NumberFieldProps> = ({
  label,
  settings,
  value: _value,
  onChange,
}) => {
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    if (typeof _value === "string") {
      const numberVal = Number.parseFloat(_value);
      setValue(isNaN(numberVal) ? null : numberVal);
    } else {
      setValue(_value ?? null);
    }
  }, [_value]);

  const handleChange = (e: ChangeEvent<any>) => {
    const numberVal = Number.parseFloat(e.target.value.replace(/,/g, ""));
    const value = isNaN(numberVal) ? null : numberVal;
    /**
     * We're setting the value of the input to e.target.value instead of
     * value because when you type the start of a decimal "0." isNaN is true
     * and will give a null value which translates into an empty string (causing the input to
     * not accept any more text)
     */
    setValue(e.target.value);
    if (onChange) {
      onChange(value);
    }
  };

  if (settings.format === "usd") {
    return (
      <TextField
        label={label}
        value={value === null ? "" : value}
        style={{ width: "100%" }}
        onChange={handleChange}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        variant="standard"
      />
    );
  }

  const inputProps: InputProps = {};
  if (settings.format === "percentage") {
    inputProps.endAdornment = (
      <InputAdornment position="start">%</InputAdornment>
    );
  }

  return (
    <TextField
      variant="standard"
      type="number"
      label={label}
      value={value === null ? "" : value}
      fullWidth
      onChange={handleChange}
      InputProps={inputProps}
    />
  );
};

export default NumberField;
