import React, { FunctionComponent, useState } from "react";
import TableIcons from "../../components/TableIcons";
import SplitLayout from "../../layouts/SplitLayout";
import LeftPane from "../../components/LeftPane";
import SettingsSubNav from "../../components/SettingsSubNav";
import SettingsSelect from "../../components/SettingsSelectMobile";
import DetailPane from "../../components/DetailPane";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle";
import MaterialTable, { MTableBodyRow } from "@material-table/core";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FRAGMENT_WORKFLOW_FIELDS } from "../../api/graphql";
import { MoreVert } from "@mui/icons-material";
import ContactNameWithAvatar from "../../components/contacts/ContactNameWithAvatar";
import {
  ToggleButton,
  ToggleButtonGroup,
} from "../../components/ToggleButtons";
import { Menu, MenuItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useAlert } from "../../context/alert";

const useStyles = makeStyles(() =>
  createStyles({
    menuIcon: {
      color: "#000000",
      opacity: 0,
      transition: "all 0.1s ease-in-out",
    },
    tableRow: {
      "&:hover": {
        "& $menuIcon": {
          opacity: 1,
        },
      },
    },
  })
);

type WorkflowContactsProps = {};
const WorkflowContactsPage: FunctionComponent<
  WorkflowContactsProps
> = (): JSX.Element => {
  const classes = useStyles();
  const { showError, showSuccess } = useAlert();
  const { id } = useParams<{ id: string }>();
  const [selectedEnrolledContact, setSelectedEnrolledContact] = useState<
    any | null
  >(null);
  const [menuAnchorPosition, setMenuAnchorPosition] = useState<any>(null);
  const { data, refetch, loading } = useQuery(
    gql`
      query GetWorkflow($id: ID!) {
        getWorkflow(id: $id) {
          ...WorkflowFields
        }
      }
      ${FRAGMENT_WORKFLOW_FIELDS}
    `,
    { variables: { id } }
  );

  const [cancelWorkflowForEnrollee] = useMutation(
    gql`
      mutation CancelWorkflowForEnrollee($id: ID!) {
        cancelWorkflowForEnrollee(id: $id)
      }
    `
  );

  const renderName = (rowData: any): JSX.Element => {
    return (
      <ContactNameWithAvatar
        name={rowData.contact.name}
        avatarUrls={[rowData.contact.avatarUrl]}
        avatarStyle={{ marginRight: "20px" }}
      />
    );
  };

  const renderTags = () => {};

  const [stateFilter, setStateFilter] = useState("active");
  const [columns] = useState([
    {
      key: "contact.name",
      visible: true,
      title: "Name",
      field: "name",
      headerStyle: { paddingLeft: "24px" },
      cellStyle: { paddingLeft: "24px" },
      render: renderName,
    },
    {
      key: "email",
      visible: true,
      title: "Email",
      field: "email",
      render: (rowData: any) => rowData?.contact?.email,
    },
    {
      key: "phone",
      visible: true,
      title: "Phone",
      field: "phone",
      sorting: false,
      render: (rowData: any) => rowData?.contact?.phone,
    },
    {
      key: "tags",
      visible: true,
      title: "Tags",
      field: "tags",
      sorting: false,
      render: renderTags,
    },
  ]);

  const handleStateFilterChange = (e: any, value: string) => {
    setStateFilter(value);
  };

  const filter = (item: any) => {
    return item.state === stateFilter;
  };

  const handleCancelClick = () => {
    cancelWorkflowForEnrollee({
      variables: {
        id: selectedEnrolledContact.id,
      },
    })
      .then(() => {
        refetch();
        showSuccess(
          `${selectedEnrolledContact.contact.name} was canceled from this workflow`
        );
      })
      .catch(() => {
        showError(
          "An error occurred when canceling this contact from this workflow"
        );
      })
      .finally(() => {
        handleMenuClose();
      });
  };

  const handleMenuClick = (e: any, enrolledContact?: any) => {
    e.stopPropagation();
    const { top, left } = e.currentTarget.getBoundingClientRect();
    setMenuAnchorPosition({ top, left });
    setSelectedEnrolledContact(enrolledContact);
  };

  const handleMenuClose = (e?: any) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setMenuAnchorPosition(null);
  };

  return (
    <SplitLayout loading={loading}>
      <LeftPane width={224}>
        <SettingsSubNav />
        <SettingsSelect label="" defaultValue="/settings/workflows" />
      </LeftPane>
      <DetailPane>
        <PageTitle
          title={data?.getWorkflow?.name}
          backUrl="/settings/workflows"
          backText="Workflows"
          actions={
            <ToggleButtonGroup
              exclusive
              value={stateFilter}
              onChange={(e, value) => handleStateFilterChange(e, value)}
            >
              <ToggleButton value="active">Active</ToggleButton>
              <ToggleButton value="completed">Completed</ToggleButton>
              <ToggleButton value="canceled">Canceled</ToggleButton>
            </ToggleButtonGroup>
          }
        ></PageTitle>
        <Menu
          id="workflow-contact-menu"
          anchorPosition={menuAnchorPosition}
          anchorReference="anchorPosition"
          open={Boolean(menuAnchorPosition)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleCancelClick}>
            <ListItemText primary="Cancel from Workflow" />
          </MenuItem>
        </Menu>
        <MaterialTable
          icons={TableIcons}
          columns={columns.filter((column) => column.visible)}
          data={data?.getWorkflow?.enrolledContacts.filter(filter)}
          localization={{
            header: {
              actions: "",
            },
          }}
          components={{
            Row: (props) => (
              <MTableBodyRow className={classes.tableRow} {...props} />
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            selection: false,
            showTitle: false,
            search: false,
            emptyRowsWhenPaging: false,
            columnsButton: true,
            pageSize: 10,
            toolbar: false,
            headerStyle: {
              fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(
                ","
              ),
              fontSize: "14px",
              color: "rgb(107, 114, 128)",
            },
          }}
          style={{
            width: "100%",
            fontSize: "14px",
            fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
          }}
          actions={
            stateFilter === "active"
              ? [
                  {
                    icon: () => <MoreVert className={classes.menuIcon} />,
                    onClick: handleMenuClick,
                  },
                ]
              : undefined
          }
        ></MaterialTable>
      </DetailPane>
    </SplitLayout>
  );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(WorkflowContactsPage);
