import React, { useState, useRef } from "react";
import { Menu, MenuItem, Link, Theme, Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AccountCircle } from "@mui/icons-material";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";
import { Link as RouterLink } from "react-router-dom";

const AppMenuItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0",
      height: "38px",
      width: "150px",

      "& a": {
        padding: "0 18px",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",

        color: theme.palette.grey[900],

        "&:hover": {
          textDecoration: "none",
        },
      },
    },
  })
)(MenuItem);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: theme.spacing(4),
      marginRight: "8px",
    },
    username: {
      marginRight: theme.spacing(2),
      color: theme.palette.grey[700],
    },
    menuIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    link: {
      textDecoration: "none",
    },
  })
);

const UserNavigationElement = () => {
  const classes = useStyles();
  const { logout } = useAuth();
  const { currentUser } = useUser();
  const [open, setOpen] = useState<boolean>(false);
  const menuIconRef = useRef(null);

  return (
    <div className={classes.root}>
      <Avatar
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        className={classes.avatar}
        src={currentUser?.avatarUrl}
        onClick={() => setOpen(true)}
      >
        <AccountCircle />
      </Avatar>
      <MoreVertIcon
        className={classes.menuIcon}
        fontSize="small"
        color="secondary"
        onClick={() => setOpen(true)}
        ref={menuIconRef}
      />
      <Menu
        id="menu-appbar"
        anchorEl={menuIconRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <AppMenuItem>
          <Link
            href="https://www.shaker.io/referrals"
            target="_blank"
            className={classes.link}
            variant="body2"
          >
            Refer a Friend
          </Link>
        </AppMenuItem>
        <AppMenuItem>
          <Link
            to="/settings/profile"
            component={RouterLink}
            variant="body2"
            className={classes.link}
          >
            Profile Settings
          </Link>
        </AppMenuItem>
        <AppMenuItem onClick={logout}>
          <Link
            to="#"
            component={RouterLink}
            variant="body2"
            className={classes.link}
          >
            Sign Out
          </Link>
        </AppMenuItem>
      </Menu>
    </div>
  );
};

export default UserNavigationElement;
