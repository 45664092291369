import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Feature } from "../reducers/features";
import TitleSelect from "./TitleSelect";
import { Account } from "../models";
import { useUser } from "../context/user";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {
      backgroundColor: "#fff",
      marginTop: "8px",
      paddingRight: "3em",
      paddingLeft: "3em",
      boxShadow: "0 0 black",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      [theme.breakpoints.down("lg")]: {
        minHeight: "100%",
        marginBottom: "25px",
      },
    },
  })
);

type SettingsSelectProps = {
  label: string;
  defaultValue: string;
  features: { [key: string]: Feature };
  account: Account;
};

const SettingsSelect = ({
  label,
  defaultValue,
  features,
  account,
}: SettingsSelectProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { currentUser } = useUser();

  const { Workflows } = features;

  const options = [
    {
      value: "/settings/account",
      label: "Account",
      enabled: currentUser.permissions.settings === "full",
    },
    { value: "/settings/team", label: "Collaborators", enabled: true },
    { value: "/settings/roles", label: "Roles", enabled: true },
    {
      value: "/settings/email-templates",
      label: "Message Templates",
      enabled: true,
    },
    {
      value: "/settings/fields",
      label: "Fields",
      enabled: true,
    },
    { value: "/settings/documents", label: "Document Types", enabled: true },
    { value: "/settings/stages", label: "Deal Stages", enabled: true },
    {
      value: "/settings/workflows",
      label: "Workflows",
      enabled: Workflows,
    },
    { value: "/settings/integrations", label: "Integrations", enabled: true },
    { value: "/settings/timeline", label: "Timeline", enabled: true },
    {
      value: "/settings/billing",
      label: "Billing",
      enabled:
        account.billingEnabled && currentUser.permissions.billing === "full",
    },
    { value: "/settings/profile", label: "Profile", enabled: true },
    { value: "/settings/email", label: "Email", enabled: true },
    { value: "/settings/phone", label: "Phone", enabled: true },
  ];

  const handleOptionChange = (value: string) => {
    navigate(value);
  };

  return (
    <TitleSelect
      defaultValue={defaultValue}
      options={options.filter((option) => option.enabled)}
      onChange={handleOptionChange}
      className={classes.mobile}
    />
  );
};

const mapStateToProps = ({
  features,
  account,
}: {
  features: { [key: string]: Feature };
  account: Account;
}) => {
  return { features, account };
};

export default connect(mapStateToProps)(SettingsSelect);
