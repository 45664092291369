import React from "react";
import { useMutation } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { SecondaryButton, PrimaryButton } from "../buttons";
import { DELETE_TASK } from "../../api/graphql";
import { Task } from "../../models";
import { useAlert } from "../../context/alert";

type TaskDeleteProps = {
  open: boolean;
  onClose: () => void;
  onTaskDeleted: () => void;
  task: Task;
};

export const TaskDeleteDialog = ({
  open,
  onClose,
  onTaskDeleted,
  task,
}: TaskDeleteProps) => {
  const [deleteTask] = useMutation(DELETE_TASK);
  const { showSuccess, showError } = useAlert();

  const handleDeleteTask = () => {
    deleteTask({
      variables: {
        id: task.id,
      },
    })
      .then(() => {
        onTaskDeleted();
        showSuccess("Task successfully deleted");
      })
      .catch(() => showError("There was an problem deleting the task"));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Delete Task</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete {<strong>{`${task.name}`}</strong>}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose} style={{ width: "100px" }}>
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={handleDeleteTask} style={{ width: "100px" }}>
          Delete
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default TaskDeleteDialog;
