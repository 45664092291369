export type Feature = {
  [key: string]: boolean;
};

export const FEATURES = {
  SET: "FEATURES/set",
};

const initialState: Feature = {};

const featuresReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FEATURES.SET:
      const featureDictionary = action.features.reduce(
        (acc: any, feat: any) => {
          acc[feat.name] = feat.enabled;
          return acc;
        },
        {}
      );
      return featureDictionary;
    default:
      return state;
  }
};

export default featuresReducer;
