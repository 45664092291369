import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Box,
  Theme,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  Button,
  Typography,
  Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { TextField, TextFieldProps } from "@mui/material";
import { Account } from "../../models";
import clsx from "clsx";
import { CircleCheckIcon, PencilIcon, TrashIcon } from "../../icons";
import { gql, useApolloClient } from "@apollo/client";
import { useAlert } from "../../context/alert";
import CopyToClipboard from "react-copy-to-clipboard";
import { FileCopy } from "@mui/icons-material";
import moment from "moment";
import NumberField from "../fields/NumberField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { OutlineButton, PrimaryButton, SecondaryButton } from "../buttons";
import { useAuth } from "../../context/auth";
import download from "downloadjs";

const CREATE_DEAL_TYPE = gql`
  mutation CREATE_DEAL_TYPE($input: CreateDealTypeInput!) {
    createDealType(input: $input) {
      id
    }
  }
`;

const DELETE_DEFAULT_ACCOUNT_DATA = gql`
  mutation DELETE_DEFAULT_ACCOUNT_DATA($accountId: ID!) {
    deleteDefaultAccountData(accountId: $accountId)
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spaceBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    userCount: {
      ".editing &": { paddingTop: "18px" },
    },
  })
);

const AccountInfo = ({
  account,
  setAccount,
}: {
  account: Account;
  setAccount: (account: Account) => void;
}) => {
  const classes = useStyles();
  const apollo = useApolloClient();
  const { showSuccess, showError } = useAlert();
  const { authToken } = useAuth();

  const initialDialogsState = {
    dealTypes: false,
    defaultData: false,
  };
  const [dialogs, setDialogs] = useState(initialDialogsState);

  const initialDealTypeState = {
    name: "",
    clientNoun: "",
    dealOwnerRoleId: "",
  };
  const [dealType, setDealType] = useState(initialDealTypeState);

  const [editing, setEditing] = useState({
    trial: false,
    userLimit: false as false | null | number,
  });

  const handleEditTrial = () => {
    if (editing.trial) {
      setEditing({ ...editing, trial: false });
    } else {
      setEditing({ ...editing, trial: true });
    }
  };

  const handleTrialChange = (date: Date | null) => {
    apollo
      .mutate({
        mutation: gql`
          mutation SetTrialEnd($accountId: ID!, $trialEndDate: Date) {
            setTrialEndDate(
              accountId: $accountId
              trialEndDate: $trialEndDate
            ) {
              id
              trialEndDate
              trialEnded
            }
          }
        `,
        variables: {
          accountId: account.id,
          trialEndDate: date,
        },
      })
      .then((res) => {
        const { trialEndDate, trialEnded } = res.data.setTrialEndDate;
        setAccount({ ...account, trialEndDate, trialEnded });
        showSuccess(`${account.name}'s trial has been updated`);
      })
      .catch(() => {
        showError("An error occurred when updating the trial");
      });
  };

  const handleEditUserLimit = () => {
    setEditing((editing) => ({ ...editing, userLimit: account.userLimit }));
  };

  const handleUserLimitChange = (userLimit: number | null) => {
    setEditing((editing) => ({ ...editing, userLimit }));
  };

  const handleUserLimitCommit = () => {
    if (editing.userLimit === false) {
      return;
    }

    apollo
      .mutate({
        mutation: gql`
          mutation SetAccountUserLimit($accountId: ID!, $userLimit: Int) {
            setAccountUserLimit(accountId: $accountId, userLimit: $userLimit) {
              id
              userLimit
            }
          }
        `,
        variables: {
          accountId: account.id,
          userLimit: editing.userLimit,
        },
      })
      .then((res) => {
        const { userLimit } = res.data.setAccountUserLimit;
        setAccount({ ...account, userLimit });
        setEditing((editing) => ({ ...editing, userLimit: false }));
        showSuccess(`${account.name}'s user limit has been updated`);
      })
      .catch(() => {
        showError("An error occurred when updating the user limit");
      });
  };

  const handleAddDealType = () => {
    apollo
      .mutate({
        mutation: CREATE_DEAL_TYPE,
        variables: {
          input: {
            accountId: account.id,
            ...dealType,
          },
        },
      })
      .then(() => {
        setDealType(initialDealTypeState);
        showSuccess(`${dealType.name} was created`);
      })
      .catch(() => {
        showError("An error occurred when creating the deal type");
      });

    handleDialogClose();
  };

  const handleDeleteDefaultData = () => {
    apollo
      .mutate({
        mutation: DELETE_DEFAULT_ACCOUNT_DATA,
        variables: {
          accountId: account.id,
        },
      })
      .then(() => {
        showSuccess("Default account data was deleted");
      })
      .catch(() => {
        showError("An error occurred while deleting the default account data");
      });

    handleDialogClose();
  };

  const handleAddDealTypeClick = () => {
    setDialogs({ ...initialDialogsState, dealTypes: true });
  };

  const handleDeleteDefaultDataClick = () => {
    setDialogs({ ...initialDialogsState, defaultData: true });
  };

  const handleExportAllDeals = (accountId: string) => {
    const formData = new FormData();
    fetch(`${process.env.REACT_APP_API_URL}/backupAllDeals/${accountId}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(async (res) => {
        if (res.status !== 200) {
          showError("An error occurred when exporting your deals");
          return;
        }
        const root = `All_Deals_${new Date().toDateString()}`;
        const filename = root.replace(/[, ]+/g, "_");

        const blob = await res.blob();
        download(blob, filename);
        showSuccess("Your deals were exported successfully");
      })
      .catch((err) => {
        console.log("Error: ", err);
        showError("An error occurred when exporting your deals");
      })
      .finally(() => {});
  };

  const handleDialogClose = () => {
    setDialogs(initialDialogsState);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">ID</TableCell>
              <TableCell>
                <Box className={classes.spaceBetween}>
                  {account.id}
                  <CopyToClipboard text={account.id}>
                    <IconButton size="small">
                      <FileCopy fontSize="small" />
                    </IconButton>
                  </CopyToClipboard>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Created</TableCell>
              <TableCell>{moment(account.createdAt).format("l")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Last Activity</TableCell>
              <TableCell>
                {account.lastActiveDate
                  ? moment(account.lastActiveDate).fromNow()
                  : "Never"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>User Count / Limit</TableCell>
              <TableCell>
                <Box
                  className={clsx({
                    [classes.spaceBetween]: true,
                    editing: editing.userLimit !== false,
                  })}
                >
                  {editing.userLimit !== false ? (
                    <>
                      <span className={classes.userCount}>
                        {account.users.totalCount}&nbsp;/&nbsp;
                      </span>
                      <NumberField
                        label="User Limit"
                        onChange={handleUserLimitChange}
                        value={account.userLimit}
                        settings={{}}
                      />
                      <IconButton onClick={handleUserLimitCommit} size="large">
                        <CircleCheckIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <span className={classes.userCount}>
                        {account.users.totalCount}&nbsp;/&nbsp;
                        {account.userLimit ?? "No Limit"}
                      </span>
                      {!account.stripeCustomerId && (
                        <IconButton onClick={handleEditUserLimit} size="small">
                          <PencilIcon size="1.25rem" />
                        </IconButton>
                      )}
                    </>
                  )}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {account.trialEnded ? "Trial Ended" : "Trial Ends"}
              </TableCell>
              <TableCell>
                <Box className={classes.spaceBetween}>
                  {editing.trial ? (
                    <DatePicker
                      open={editing.trial}
                      renderInput={(props: TextFieldProps) => (
                        <TextField {...props} variant="standard" />
                      )}
                      onChange={handleTrialChange}
                      value={account.trialEndDate}
                      onClose={() => {
                        setEditing({ ...editing, trial: false });
                      }}
                    />
                  ) : (
                    <>
                      {account.trialEndDate
                        ? moment(account.trialEndDate).format("l")
                        : "Never"}
                      <div>
                        <IconButton onClick={handleEditTrial} size="small">
                          <PencilIcon size="1.25rem" />
                        </IconButton>
                        <IconButton
                          onClick={() => handleTrialChange(null)}
                          size="small"
                        >
                          <TrashIcon size="1.25rem" />
                        </IconButton>
                      </div>
                    </>
                  )}
                </Box>
              </TableCell>
            </TableRow>
            {account.hubspotId && (
              <TableRow>
                <TableCell variant="head">HubSpot</TableCell>
                <TableCell>
                  <Link
                    target="new"
                    href={`https://app.hubspot.com/contacts/7769392/company/${account.hubspotId}`}
                  >
                    {account.hubspotId}
                  </Link>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={2}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <OutlineButton onClick={handleAddDealTypeClick}>
                    Add Deal Type
                  </OutlineButton>

                  <OutlineButton onClick={handleDeleteDefaultDataClick}>
                    Delete Default Data
                  </OutlineButton>

                  <OutlineButton
                    onClick={() => handleExportAllDeals(account.id)}
                  >
                    Export All Deals
                  </OutlineButton>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={dialogs.dealTypes}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Add Custom Deal Type</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                autoFocus
                id="name"
                label="Name"
                type="text"
                fullWidth
                placeholder="New Construction"
                value={dealType.name}
                onChange={(e) =>
                  setDealType((dealType) =>
                    Object.assign({}, dealType, { name: e.currentTarget.value })
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                id="name"
                label="Client Noun"
                type="text"
                placeholder="Buyer"
                fullWidth
                value={dealType.clientNoun}
                onChange={(e) =>
                  setDealType((dealType) =>
                    Object.assign({}, dealType, {
                      clientNoun: e.currentTarget.value,
                    })
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="ownerRole">Owner Role</InputLabel>
                <Select
                  labelId="ownerRole"
                  native
                  label="Owner Role"
                  fullWidth
                  value={dealType.dealOwnerRoleId}
                  onChange={(e) => {
                    setDealType((dealType) =>
                      Object.assign({}, dealType, {
                        dealOwnerRoleId: e.target.value,
                      })
                    );
                  }}
                >
                  <option></option>
                  {account.roles.map((role: any) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            onClick={handleAddDealType}
            color="primary"
            disabled={
              !dealType.clientNoun ||
              !dealType.dealOwnerRoleId ||
              !dealType.name
            }
          >
            Create Deal Type
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogs.defaultData}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          Delete All Default Account Data?
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                This will delete all default Roles, Deal Types, Deal Stages,
                Email Templates and Workflows that were added during initial
                account setup.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleDialogClose}>Cancel</SecondaryButton>
          <PrimaryButton onClick={handleDeleteDefaultData} color="primary">
            Delete Default Account Data
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AccountInfo;
