import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Grid,
  Avatar,
  Typography,
  Button,
  Hidden,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_CUSTOM_FIELDS,
  GET_TEAM_MEMBER_CUSTOM_FIELDS,
  SAVE_PROFILE,
} from "../../api/graphql";
import { useAuth } from "../../context/auth";
import { useUser } from "../../context/user";
import { useAlert } from "../../context/alert";
import TimezoneSelect from "../TimezoneSelect";
import { PrimaryButton, OutlineButton } from "../buttons";
import ChangePasswordDialog from "../../pages/settings/ChangePasswordDialog";
import DetailCard from "../DetailCard";
import { useDropzone } from "react-dropzone";
import CustomField from "../fields/CustomField";
import fieldHelpers, {
  cleanCustomFieldValuesForInput,
} from "../../helpers/fields";

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      maxWidth: "170px",
      maxHeight: "170px",
      width: "100%",
      height: "100%",
    },
  })
);

const ProfileSettings = () => {
  const { data } = useQuery(GET_CUSTOM_FIELDS, {
    variables: { tableName: "teamMember" },
  });

  const { data: teamMemberCustomFieldValues } = useQuery(
    GET_TEAM_MEMBER_CUSTOM_FIELDS
  );

  const classes = useStyles();
  const { showSuccess, showError } = useAlert();
  const { authToken } = useAuth();
  const { currentUser, setCurrentUser } = useUser();
  const [saving, setSaving] = useState<boolean>(false);
  const [user, setUser] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    timezone: "",
  });
  const [customFieldValues, setCustomFieldValues] = useState<any>([]);
  const [saveProfile] = useMutation(SAVE_PROFILE);

  const handleChange = (updates: any) => {
    setUser((user: any) => {
      return { ...user, ...updates };
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSaving(true);
    const { firstName, lastName, email } = event.target.elements;

    saveProfile({
      variables: {
        input: {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          timezone: user.timezone,
          customFields: cleanCustomFieldValuesForInput(customFieldValues),
        },
      },
    })
      .then((response) => {
        setCurrentUser((currentUser: any) =>
          Object.assign({}, currentUser, response.data.saveProfile)
        );
        showSuccess("Your settings were saved successfully");
      })
      .catch(() => {
        showError("An error occurred when saving your settings");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    setCustomFieldValues(
      teamMemberCustomFieldValues?.getTeamMember?.customFields ?? []
    );
  }, [teamMemberCustomFieldValues]);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  const handleDrop = (files: File[]) => {
    if (!files.length) {
      return;
    }
    const formData = new FormData();
    formData.append("userId", currentUser.id);
    formData.append("userAvatar", files[0]);
    fetch(`${process.env.REACT_APP_API_URL}/userAvatar`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        showSuccess("Your profile picture was saved");
        setCurrentUser((currentUser: any) => ({
          ...currentUser,
          avatarUrl: res.avatarUrl,
        }));
        setUser((user: any) => ({
          ...user,
          avatarUrl: res.avatarUrl,
        }));
      })
      .catch((err) => {
        showError("An error occurred when saving your profile picture");
      })
      .finally(() => {});
  };

  const dropzone = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    noKeyboard: true,
    accept: "image/*",
  });

  const getFieldValue = (customField: any) => {
    const customFieldValue = customFieldValues?.find(
      (f: any) => f.customFieldId === customField.id
    );
    if (customFieldValue?.value != null) {
      return customFieldValue.value;
    }
    return customFieldValue
      ? fieldHelpers.getCustomFieldValue(customField, customFieldValue)
      : fieldHelpers.getDefaultValue(customField);
  };

  const handleCustomFieldChange = (customField: any, value: any) => {
    setCustomFieldValues((customFieldValues: any) => {
      const copy = [...customFieldValues];
      const index = copy.findIndex(
        (customFieldValue: any) =>
          customFieldValue.customFieldId === customField.id
      );
      if (index === -1) {
        copy.push({
          customFieldId: customField.id,
          value,
        });
      } else {
        copy[index].value = value;
      }
      return copy;
    });
  };

  return (
    <DetailCard
      collapsible={false}
      title={<Typography variant="h5">Profile Settings</Typography>}
    >
      <Box m={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Box
              {...dropzone.getRootProps()}
              onClick={dropzone.open}
              marginRight={3}
            >
              <input {...dropzone.getInputProps()} />
              <Avatar className={classes.avatar} src={user.avatarUrl}></Avatar>
            </Box>
            <div>
              <Button variant="outlined" onClick={dropzone.open}>
                Change Profile Picture
              </Button>
              <Hidden only={["xs"]}>
                <Typography>
                  We recommend an image size of at least 200x200 for your
                  profile picture
                </Typography>
              </Hidden>
            </div>
          </Grid>
          <Grid
            item
            sm={8}
            style={{ display: "flex", alignItems: "center" }}
          ></Grid>
          <Grid item xs={12}>
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    variant="standard"
                    value={user.firstName}
                    onChange={(e) =>
                      handleChange({ firstName: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    name="lastName"
                    variant="standard"
                    fullWidth
                    value={user.lastName}
                    onChange={(e) => handleChange({ lastName: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    name="email"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    type="email"
                    value={user.email}
                    onChange={(e) => handleChange({ email: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TimezoneSelect
                    value={user.timezone}
                    name="timezone"
                    variant="standard"
                    onChange={(value: string | null) =>
                      handleChange({ timezone: value })
                    }
                  ></TimezoneSelect>
                </Grid>
                {data?.getCustomFields.map((customField: any) => (
                  <Grid item xs={12}>
                    <CustomField
                      key={customField.id}
                      label={customField.label}
                      value={getFieldValue(customField)}
                      type={customField.type}
                      settings={customField.settings}
                      onChange={(value) =>
                        handleCustomFieldChange(customField, value)
                      }
                    />
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <ChangePasswordDialog>
                    <OutlineButton style={{ marginRight: "5px" }}>
                      Change Password
                    </OutlineButton>
                  </ChangePasswordDialog>
                  <PrimaryButton
                    type="submit"
                    disabled={saving}
                    style={{ width: "100px", marginRight: 0 }}
                  >
                    Save
                  </PrimaryButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </DetailCard>
  );
};

export default ProfileSettings;
