import React from "react";
import {
  Theme,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { PrimaryButton } from "../buttons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.secondary.main,
      height: "100px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },

    checkmark: {
      transform: "scale(2.5)",
      color: "white",
    },

    successTextWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export default function ImportCompleteModal({
  onDone,
  open,
}: {
  onDone: () => void;
  open: boolean;
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => (open = false)} maxWidth="md">
      <DialogContent className={classes.header}>
        <CheckCircleOutlinedIcon className={classes.checkmark} />
      </DialogContent>
      <DialogContent className={classes.successTextWrapper}>
        <Typography variant="h3" sx={{ marginBottom: "10px" }}>
          {" "}
          Success!{" "}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {" "}
          Your contacts were imported sucessfully.
        </Typography>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }}></div>
        <PrimaryButton onClick={onDone}>Done</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
