import React, { useState, FunctionComponent, useEffect, useMemo } from "react";
import { StaticDatePicker } from "@mui/x-date-pickers";
import {
  TextField,
  TextFieldProps,
  Typography,
  Stack,
  Popover,
  Box,
  IconButton,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Grid,
  ListItemText,
} from "@mui/material";
import { connect } from "react-redux";
import { Feature } from "../../reducers/features";
import ContactNameWithAvatar from "../contacts/ContactNameWithAvatar";
import moment, { Moment, MomentInput } from "moment";
import TimeDropdown from "./TimeDropdown";
import { Clear } from "@mui/icons-material";
import { OutlineButton, PrimaryButton, SecondaryButton } from "../buttons";
import TeamMemberSelectMultiple from "../TeamMemberPicker";
import { ToggleButton, ToggleButtonGroup } from "../ToggleButtons";
import { Deal } from "../../models";
import { useLazyQuery, gql } from "@apollo/client";
import { useUser } from "../../context/user";

interface DateTimeFieldValue {
  date: MomentInput | null;
  dateTime: MomentInput | null;
  selectedAgents?: string[];
  meetingLength?: number;
  googleEvent?: boolean;
}

type DateTimeEditProps = {
  anchorEl: any;
  setAnchorEl: (el: any) => void;
  label?: string;
  id?: string;
  deal?: Deal;
  value?: DateTimeFieldValue;
  settings?: { includeTime?: boolean; eventType?: string };
  onChange?: (value: DateTimeFieldValue | undefined) => void;
  features: any;
};

type Agent = {
  id: string;
  name: string;
  role?: string;
  avatarUrl?: string;
};

const valueToMoment = (value: DateTimeFieldValue) => {
  let m: Moment | null = null;
  if (value.dateTime) {
    m = moment(value.dateTime);
  } else if (value.date) {
    m = moment(value.date);
  }
  if (m?.isValid()) {
    return m;
  }
  return null;
};

const DateTimeEditModal: FunctionComponent<DateTimeEditProps> = ({
  anchorEl,
  setAnchorEl,
  label,
  id,
  deal,
  value: _value,
  settings,
  onChange,
  features,
}) => {
  const [getEvent] = useLazyQuery(gql`
    query GetEvent($input: GetEventInput!) {
      getEvent(input: $input) {
        id
        meetingLength
        participants {
          id
          contact {
            id
            name
            avatarUrl
          }
          teamMember {
            id
            name
            avatarUrl
          }
        }
      }
    }
  `);

  const [selectedDate, setSelectedDate] = useState<Moment | null>(() => {
    return _value ? valueToMoment(_value) : null;
  });

  const [selectedTime, setSelectedTime] = useState<string | null>(() => {
    if (_value?.dateTime) {
      const m = moment(_value.dateTime);
      if (m.isValid()) {
        return m.format("HH:mm:ss");
      }
    }
    return null;
  });

  const [value, setValue] = useState(_value);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const dateValue = useMemo(() => {
    if (!value) {
      return null;
    }
    let m: Moment | null = null;
    if (value.dateTime) {
      m = moment(value.dateTime);
    } else if (value.date) {
      m = moment(value.date);
    }
    if (m?.isValid()) {
      return m;
    }
    return null;
  }, [value]);

  const timeValue = useMemo(() => {
    if (value && value.dateTime) {
      const m = moment(value.dateTime);
      if (m.isValid()) {
        return m.format("HH:mm:ss");
      }
    }
    return null;
  }, [value]);

  const { currentUser } = useUser();

  const [event, setEvent] = useState<any>(null);
  const [participants, setParticipants] = useState<Agent[]>([]);
  const [meetingLength, setMeetingLength] = useState<number | null>(null);
  const [sendToCalendar, setSendToCalendar] = useState(false);
  const mobileView = useMediaQuery("(max-width:500px)");

  const hasEventIntegration =
    features["Events"] && currentUser?.hasCalendarLinked;

  const showEventOptions =
    selectedDate &&
    (!!settings?.eventType || settings?.includeTime) &&
    sendToCalendar;

  useEffect(() => {
    if (anchorEl) {
      const input: any = {};
      if (deal?.id) {
        input.dealId = deal.id;
      }
      input.columnName = id;
      console.log("Fetch Event info: ", input);

      getEvent({
        variables: {
          input,
        },
      }).then((res) => {
        const event = res.data.getEvent;
        setEvent(event ?? null);
        if (event) {
          setMeetingLength(event.meetingLength);
          setSendToCalendar(true);
          setParticipants(() => {
            return event.participants.map((p: any) => {
              return p.contact ?? p.teamMember;
            });
          });
        } else {
          setMeetingLength(null);
          setSendToCalendar(false);
          setParticipants([]);
        }
      });
    }
  }, [anchorEl, deal?.id, id, getEvent]);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time: string | null) => {
    setSelectedTime(time);
  };

  const handleReset = () => {
    setParticipants([]);
    setSendToCalendar(false);
    setMeetingLength(null);
    setSelectedDate(dateValue ?? null);
    setSelectedTime(() => {
      if (_value?.dateTime) {
        const m = moment(_value.dateTime);
        if (m.isValid()) {
          return m.format("HH:mm:ss");
        }
      }
      return null;
    });
  };

  const handleAcceptDate = () => {
    let value: DateTimeFieldValue | undefined = undefined;
    if (selectedDate) {
      const m = moment(selectedDate);
      let dateTime: any =
        settings?.includeTime && !settings.eventType
          ? m.startOf("day").toISOString()
          : null;
      if (selectedTime) {
        const time = moment(selectedTime, "HH:mm:ss");
        m.set({
          hour: time.get("hour"),
          minute: time.get("minute"),
          second: 0,
        });
        dateTime = m.toISOString();
      }
      value = {
        dateTime,
        date: m.format("YYYY-MM-DD"),
      };
    }
    setValue(value);
    if (onChange) {
      const input = {
        date: value?.date,
        dateTime: value?.dateTime,
        event: {
          startAt: value?.dateTime,
          startDate: value?.date,
          participants: participants,
          meetingLength: meetingLength,
          sendToCalendar: sendToCalendar,
          type: id,
        },
      };
      onChange({ ...input });
    }
    setAnchorEl(null);
  };

  const handleMeetingLengthChange = (e: any, meetingLength: any) => {
    setMeetingLength(meetingLength);
  };

  const handleAgentSelect = (e: any, agent: any) => {
    setParticipants(agent);
  };
  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Box p={2}>
          <Typography variant="h4" textAlign="center" mb={2}>
            {label}
          </Typography>
          <Stack
            direction={mobileView ? "column" : "row"}
            spacing={mobileView ? 0 : 2}
            alignItems="start"
          >
            <Box
              sx={{
                borderRight: mobileView ? "none" : "1px solid #3333",
                display: mobileView && dateValue?.isValid() ? "none" : "",
              }}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                renderInput={(params: TextFieldProps) => (
                  <TextField {...params} variant="standard" fullWidth />
                )}
                label={label}
                value={selectedDate ?? dateValue ?? null}
                onChange={handleDateChange}
              />
            </Box>
            <Stack spacing={2} sx={{ width: "300px" }}>
              <TimeDropdown
                styles={{}}
                label={"Time"}
                value={selectedTime ?? timeValue}
                disabled={!selectedDate && !dateValue}
                onChange={handleTimeChange}
              />
              {hasEventIntegration && (
                <>
                  {selectedDate && selectedTime && !event && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendToCalendar}
                          color="primary"
                          name="createEvent"
                          onChange={(e, checked) => {
                            setMeetingLength(null);
                            setParticipants([]);
                            setSendToCalendar(checked);
                          }}
                        />
                      }
                      label="Create Calendar Event"
                    />
                  )}
                  {showEventOptions && (
                    <Stack spacing={2}>
                      <Grid>
                        <Typography variant="body2" mb={1}>
                          Event Length
                        </Typography>
                        <ToggleButtonGroup
                          value={meetingLength}
                          aria-label="meeting-length"
                          exclusive
                          sx={{ width: "300px" }}
                          onChange={handleMeetingLengthChange}
                        >
                          <ToggleButton value={15}>15 min</ToggleButton>
                          <ToggleButton value={30}>30 min</ToggleButton>
                          <ToggleButton value={45}>45 min</ToggleButton>
                          <ToggleButton value={60}>1 hour</ToggleButton>
                          <ToggleButton value={120}>2 hours</ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                      <TeamMemberSelectMultiple
                        value={participants}
                        deal={deal}
                        label="Invite a Team Member"
                        onChange={handleAgentSelect}
                        placeholder={`Invite ${deal?.dealType?.clientNoun} and Collaborators`}
                      />
                      <Stack>
                        {participants.length > 0 && (
                          <Typography
                            variant="h6"
                            mb={1}
                            sx={{ marginBottom: "0px" }}
                          >
                            Invited:
                          </Typography>
                        )}
                        <Box sx={{ marginLeft: "20px" }}>
                          {participants.map((agent) => {
                            return (
                              <ListItemText
                                key={agent.id}
                                style={{ listStyle: "none" }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <ContactNameWithAvatar
                                    name={agent.name || ""}
                                    avatarUrls={[agent.avatarUrl]}
                                    title={agent.role}
                                    avatarStyle={{
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  />
                                  <IconButton
                                    onClick={(e) =>
                                      setParticipants(
                                        participants.filter(
                                          (a) => a.id !== agent.id
                                        )
                                      )
                                    }
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                </Stack>
                              </ListItemText>
                            );
                          })}
                        </Box>
                      </Stack>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="end"
            spacing={1}
            sx={{
              paddingTop: "4px",
            }}
          >
            <SecondaryButton onClick={() => setAnchorEl(null)}>
              Close
            </SecondaryButton>
            <OutlineButton onClick={() => handleReset()}>Reset</OutlineButton>
            <PrimaryButton onClick={handleAcceptDate}>Accept</PrimaryButton>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

const mapStateToProps = ({ features }: { [key: string]: Feature }) => {
  return { features };
};

export default connect(mapStateToProps)(DateTimeEditModal);
