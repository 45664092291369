import React, { useState, useReducer, useCallback, useRef } from "react";
import {
  Avatar,
  Typography,
  CardContent,
  Accordion,
  AccordionDetails,
  Box,
  Theme,
  Grid,
  IconButton,
  Chip,
  Menu,
  Link,
  MenuItem,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import DealList from "../../components/deals/List";
import { gql } from "@apollo/client";
import NoteList from "../../components/notes/List";
import TaskList from "../../components/tasks/List";
import SocialProfiles from "../../components/contacts/SocialProfiles";
import Messages from "../../components/contacts/Messages";
import {
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  MoreHoriz,
} from "@mui/icons-material";
import {
  EmailIcon,
  MessageIcon as SmsIcon,
  NoteIcon,
  MoneyBagIcon as DealIcon,
} from "../../icons";
import ComposeEmailDialog from "../../components/emails/ComposeEmailDialog";
import { ActionAccordionSummary } from "../../components/ActionAccordion";
import DetailPane from "../../components/DetailPane";
import CreateNote from "../../components/notes/CreateNote";
import DetailCard from "../../components/DetailCard";
import SplitLayout from "../../layouts/SplitLayout";
import SubjectData from "../../components/subject-pane/SubjectData";
import LeftPane from "../../components/LeftPane";
import EditButton from "../../components/subject-pane/EditButton";
import ActionIconButton from "../../components/ActionIconButton";
import ContactUpdateForm from "../../components/contacts/UpdateDialog";
import moment from "moment";
import { useAlert } from "../../context/alert";
import { Tag, Task } from "../../models";
import { refreshContactTags } from "../../actions/contactTags";
import { connect } from "react-redux";
import { ContactBatchDeleteButton } from "../../components/contacts/BatchDeleteButton";
import { ContactBatchEnrollIntoWorkflowButton } from "../../components/contacts/BatchEnrollIntoWorkflowButton";
import { Link as RouterLink } from "react-router-dom";
import TaskCreateDialog from "../../components/tasks/CreateDialog";
import DealCreateDialog from "../../components/deals/CreateDialog";
import ComposeSmsDialog from "../../components/sms/ComposeSmsDialog";
import UserInviteDialog from "../../components/users/UserInviteDialog";
import SocialPosts from "../../components/contacts/SocialPosts";
import {
  ToggleButton,
  ToggleButtonGroup,
} from "../../components/ToggleButtons";
import SmsThreads from "../../components/contacts/SmsThreads";
import { sourceToDomain } from "../../helpers";
import {
  FRAGMENT_TASK_FIELDS,
  GET_EMAIL_THREADS,
  GET_SMS_THREADS,
} from "../../api/graphql";
import { useUser } from "../../context/user";
import { ApiClient, useApi } from "../../context/api";
import {
  Provider,
  ContactViewState,
  ContactViewActionType,
  reducer,
} from "./context";
import { useEffect } from "react";
import { getFullAddress, getCityStateString } from "../../helpers/address";
import { useAuth } from "../../context/auth";
import { io } from "socket.io-client";

const DealsContent = withStyles({
  root: { padding: "0" },
})(CardContent);

const PAGE_SIZE = 10;

type PagingParams = {
  totalCount: number;
  pageSize: number;
  page: number;
};

type GetEmailThreadsResponse = {
  threads: any[];
  totalCount: number;
  pageSize: number;
  page: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      color: theme.palette.grey[600],
    },
    noContactContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

const initialState: ContactViewState = {
  contact: {},
  tasks: [],
  deals: [],
  notes: [],
};

const ContactsView = ({
  refreshContactTags,
}: {
  refreshContactTags: (apolloClient: ApiClient) => Promise<void>;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { apolloClient } = useApi();
  const navigate = useNavigate();
  const classes = useStyles();
  const pagingParams = useRef<PagingParams | null>(null);
  const [messageType, setMessageType] = useState<"sms" | "email">("email");
  const [threadsLoading, setThreadsLoading] = useState<boolean>(false);
  const { authToken } = useAuth();
  const [threads, setThreads] = useState<Array<any>>([]);
  let contact = state.contact;

  const loadPage = useCallback(
    (
      page: number = 0,
      pageSize = PAGE_SIZE
    ): Promise<GetEmailThreadsResponse> => {
      if (!contact.id) {
        return Promise.resolve({ threads: [], totalCount: 0, pageSize, page });
      }

      setThreadsLoading(true);
      const query =
        messageType === "email" ? GET_EMAIL_THREADS : GET_SMS_THREADS;
      return apolloClient
        .query({
          query,
          variables: {
            filter: {
              contactId: contact.id,
              page,
              pageSize,
            },
          },
        })
        .then((res) => {
          return messageType === "email"
            ? res.data.getEmailThreads
            : res.data.getSmsThreads;
        })
        .finally(() => {
          setThreadsLoading(false);
        });
    },
    [apolloClient, messageType, contact.id]
  );

  const loadFirstPage = () => {
    loadPage(0).then((res) => {
      setThreads(res.threads);
      pagingParams.current = {
        totalCount: res.totalCount,
        page: res.page,
        pageSize: res.pageSize,
      };
    });
  };

  const loadFirstPageCallback = useCallback(loadFirstPage, [loadPage]);
  useEffect(() => {
    loadFirstPageCallback();
  }, [loadFirstPageCallback]);

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_API_URL;
    if (!socketUrl) {
      return;
    }
    const socket = io(socketUrl, {
      path: "/io/",
      auth: {
        token: authToken,
      },
      transports: ["websocket"],
    });
    const cb = () => {};
    socket.on("email:incoming", cb);
    socket.on("email:outgoing", cb);
    socket.on("smsThread:incoming", () => {
      loadFirstPageCallback();
    });
    return () => {
      socket.disconnect();
    };
  }, [loadFirstPageCallback, messageType, authToken]);

  const handleScroll = (e: any) => {
    if (pagingParams.current === null) {
      return;
    }
    const isNearBottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    const hasMoreThreads = threads.length < pagingParams.current.totalCount;
    if (isNearBottom && hasMoreThreads && !threadsLoading) {
      const nextPage = pagingParams.current.page + 1;
      loadPage(nextPage).then((res) => {
        setThreads((threads) => [...threads, ...res.threads]);
        if (pagingParams.current) {
          pagingParams.current.page = res.page;
          pagingParams.current.totalCount = res.totalCount;
          pagingParams.current.pageSize = res.pageSize;
        }
      });
    }
  };

  const GET_CONTACT = gql`
    query GetContact($id: ID!) {
      getContact(id: $id) {
        id
        firstName
        lastName
        combinedName
        name
        email
        phone
        address {
          street
          street2
          city
          state
          zip
        }
        birthday
        avatarUrl
        userStatus
        leadSource
        ownerId
        owner {
          id
          name
          avatarUrl
        }
        deals {
          id
          name
          stage {
            id
            name
          }
          dealType {
            id
            name
            clientNoun
          }
          owner {
            id
            name
          }
          value
          closeDate
          photoUrl
          updatedAt
          folders {
            id
            name
            documents {
              id
              name
            }
          }
          documents(excludeInFolders: true) {
            id
            name
          }
        }
        notes {
          id
          body
          createdAt
          source
          author {
            id
            name
            avatarUrl
          }
        }
        linkedContact {
          id
          name
          avatarUrl
        }
        customFields {
          id
          customFieldId
          type
          label
          valueText
          settings {
            formatAsCurrency
            includeTime
            options
            format
          }
        }
        tags {
          id
          name
        }
        tasks {
          ...TaskFields
        }
        homeAnniversaryDate
        deletedAt
        socialProfiles {
          instagram
          linkedIn
          twitter
          facebook
        }
      }
    }
    ${FRAGMENT_TASK_FIELDS}
  `;

  let tasks = state.tasks;

  const { currentUser } = useUser();
  const { showSuccess } = useAlert();
  const { id } = useParams<any>();
  const [editing, setEditing] = useState(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [tasksToggleValue, setTasksToggleValue] = useState<
    "active" | "completed"
  >("active");

  const defaultDialogs = {
    delete: false,
    workflowEnrollment: false,
    task: false,
    createDeal: false,
    sendText: false,
    invite: false,
  };
  const [dialogs, setDialogs] = useState(defaultDialogs);

  const { data, loading, refetch } = useQuery(GET_CONTACT, {
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (data?.getContact) {
      dispatch({
        type: ContactViewActionType.SET_FULL_STATE,
        contact: data.getContact,
      });
    }
  }, [data]);

  function handleDealCreated(deal: any) {
    dispatch({
      type: ContactViewActionType.ADD_DEAL,
      deal: deal,
    });
    setDialogs(defaultDialogs);
    navigate(`/deals/${deal.id}/details`);
    showSuccess(`Deal "${deal.name}" was added`);
  }

  function handleNoteCreated(note: any) {
    dispatch({
      type: ContactViewActionType.ADD_NOTE,
      note,
    });
  }

  const handleNoteEdited = (noteToEdit: any) => {
    dispatch({
      type: ContactViewActionType.EDIT_NOTE,
      note: noteToEdit,
    });
  };

  function handleEditClick() {
    setEditing(true);
  }

  function handleUpdate() {
    refreshContactTags(apolloClient);
    refetch().then((res) => {
      setEditing(false);
    });
  }

  function formatValue(value: number) {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return currencyFormatter.format(value);
  }

  function formatDate(date: string) {
    return moment(date, "YYYY-MM-DD").format("LL");
  }

  function formatCustomField(customFieldValue: any) {
    if (customFieldValue.type === "number") {
      if (customFieldValue.settings.formatAsCurrency) {
        return formatValue(customFieldValue.valueText);
      } else {
        return new Intl.NumberFormat("en-US").format(
          customFieldValue.valueText
        );
      }
    } else if (customFieldValue.type === "datetime") {
      if (customFieldValue.settings.includeTime) {
        return moment(customFieldValue.valueText).format("lll");
      }
      return formatDate(customFieldValue.valueText);
    }
    return customFieldValue.valueText;
  }

  const handleInviteClick = () => {
    setDialogs({ ...dialogs, invite: true });
  };

  const CollaboratorStatus = (): JSX.Element => {
    switch (contact.userStatus) {
      case "active":
        return <>Active</>;
      case "invited":
        return <>Invited</>;
      default:
        return (
          <Link href="#" onClick={handleInviteClick}>
            Send Invite
          </Link>
        );
    }
  };

  const Address = (): JSX.Element => {
    return (
      <>
        {contact.address.street && (
          <>
            {contact.address.street}
            <br />
          </>
        )}
        {contact.address.street2 && (
          <>
            {contact.address.street2}
            <br />
          </>
        )}
        {getCityStateString(contact.address)}
      </>
    );
  };

  const Birthday = (): JSX.Element => {
    return <>{moment(contact.birthday).format("MMMM DD")}</>;
  };

  const Anniversary = (): JSX.Element => {
    return <>{moment(contact.homeAnniversaryDate).format("MMMM DD, YYYY")}</>;
  };

  const hasSocialProfiles = () => {
    // checks to see if every profile value is null
    return !Object.values(contact.socialProfiles).every(
      (profile) => profile === null || profile === "SocialProfiles"
    );
  };

  const handleTasksToggleChange = (_: any, value: "active" | "completed") => {
    setTasksToggleValue(value);
  };

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    handleClose();
    showSuccess(`${contact.name} was deleted`);
    navigate("/contacts");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleTaskUpdated = (task: Task) => {
    dispatch({
      type: ContactViewActionType.UPDATE_TASK,
      task: task,
    });
  };

  const handleTaskDeleted = (task: Task) => {
    dispatch({
      type: ContactViewActionType.DELETE_TASK,
      task: task,
    });
  };

  const LeadSource = ({ name }: { name: string }) => {
    const domain = sourceToDomain(name);
    if (domain) {
      return (
        <span
          style={{ display: "flex", alignItems: "center", marginTop: "1px" }}
        >
          <Avatar
            style={{ height: "24px", width: "24px" }}
            src={`/images/integrations/avatars/${domain}.png`}
          />
          &nbsp;{name}
        </span>
      );
    } else return <>{name}</>;
  };

  return (
    <Provider value={{ state, dispatch }}>
      <SplitLayout loading={loading}>
        {!loading && data.getContact === null ? (
          <div className={classes.noContactContainer}>
            <div style={{ textAlign: "center" }}>
              <h1>Contact not found</h1>
              <p>It doesn't look like this contact exists on your account.</p>
              <Link component={RouterLink} to={`/contacts/`}>
                Return to contacts view
              </Link>
            </div>
          </div>
        ) : (
          !loading &&
          contact.id && (
            <>
              <Box>
                <LeftPane>
                  <Box style={{ padding: "22px 8px 8px" }}>
                    <EditButton
                      onClick={handleEditClick}
                      disabled={!!contact.deletedAt || editing}
                    />
                  </Box>

                  <Avatar
                    src={contact?.avatarUrl}
                    style={{
                      width: "66px",
                      height: "66px",
                      margin: "9px auto",
                      marginTop: "18px",
                    }}
                  ></Avatar>
                  <Box textAlign="center" style={{ margin: "9px auto" }}>
                    <Typography variant="h5">{contact?.name}</Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.subtitle}
                    >
                      {/* Buyer */}
                    </Typography>
                  </Box>

                  <>
                    <Grid
                      container
                      spacing={1}
                      justifyContent="center"
                      style={{ marginBottom: "14px", marginTop: "20px" }}
                    >
                      <ComposeEmailDialog
                        recipients={[contact]}
                        contactDeals={contact.deals}
                      >
                        <ActionIconButton
                          icon={EmailIcon}
                          label="Email"
                          disabled={!!contact.deletedAt}
                        />
                      </ComposeEmailDialog>

                      {contact.phone &&
                        currentUser.teamMember.provisionedPhone && (
                          <>
                            <ActionIconButton
                              icon={SmsIcon}
                              label="SMS"
                              disabled={!!contact.deletedAt}
                              onClick={() => {
                                handleClose();
                                setDialogs({ ...dialogs, sendText: true });
                              }}
                            />
                            <ComposeSmsDialog
                              open={dialogs.sendText}
                              recipients={[contact]}
                              onClose={() => {
                                handleClose();
                                setDialogs(defaultDialogs);
                              }}
                            />
                          </>
                        )}

                      <ActionIconButton
                        icon={DealIcon}
                        label="Deal"
                        disabled={!!contact.deletedAt}
                        onClick={() => {
                          setDialogs({ ...dialogs, createDeal: true });
                        }}
                      />

                      <CreateNote
                        contactId={contact.id}
                        onNoteCreated={handleNoteCreated}
                      >
                        <ActionIconButton
                          icon={NoteIcon}
                          label="Note"
                          disabled={!!contact.deletedAt}
                        />
                      </CreateNote>
                      <ComposeSmsDialog
                        open={dialogs.sendText}
                        recipients={[contact]}
                        contactDeals={contact.deals}
                        onClose={() => {
                          handleClose();
                          setDialogs(defaultDialogs);
                        }}
                      />

                      <ActionIconButton
                        onClick={(e: any) => handleActionsClick(e)}
                        icon={MoreHoriz}
                        label="Actions"
                        disabled={!!contact.deletedAt}
                      />

                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={menuOpen}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setDialogs({ ...dialogs, task: true });
                          }}
                        >
                          <span>Create a Task</span>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setDialogs({
                              ...dialogs,
                              workflowEnrollment: true,
                            });
                          }}
                        >
                          Enroll in a Workflow
                        </MenuItem>
                        {contact.userStatus !== "active" && (
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setDialogs({ ...dialogs, invite: true });
                            }}
                          >
                            {contact.userStatus !== "invited"
                              ? "Invite to Client Portal"
                              : "Resend Invite to Portal"}
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setDialogs({ ...dialogs, delete: true });
                          }}
                        >
                          <span>Delete</span>
                        </MenuItem>
                      </Menu>

                      <TaskCreateDialog
                        open={dialogs.task}
                        contact={contact}
                        onTaskCreated={(task: Task) => {
                          dispatch({
                            type: ContactViewActionType.ADD_TASK,
                            task: task,
                          });
                          handleUpdate();
                          setDialogs(defaultDialogs);
                        }}
                        onClose={() => setDialogs(defaultDialogs)}
                        assignToCurrentUser={true}
                      />

                      <ContactBatchDeleteButton
                        open={dialogs.delete}
                        onClose={() => setDialogs(defaultDialogs)}
                        onDeleted={handleDelete}
                        selectedContacts={{
                          [contact.id]: { checked: true, pageNumber: 1 },
                        }}
                      ></ContactBatchDeleteButton>
                      <ContactBatchEnrollIntoWorkflowButton
                        open={dialogs.workflowEnrollment}
                        onClose={() => setDialogs(defaultDialogs)}
                        selectedContacts={{
                          [contact.id]: { checked: true, pageNumber: 1 },
                        }}
                        onEnrolled={handleClose}
                      ></ContactBatchEnrollIntoWorkflowButton>
                    </Grid>

                    <Grid item style={{ margin: "20px" }}>
                      {contact.deletedAt && (
                        <Chip
                          variant="outlined"
                          label={"Deleted"}
                          style={{
                            margin: "4px",
                            color: "rgb(207, 68, 64)",
                            borderColor: "rgb(207, 68, 64)",
                          }}
                        />
                      )}
                      {contact.tags.map((tag: Tag) => (
                        <Chip
                          key={tag.id}
                          variant="outlined"
                          label={tag.name}
                          style={{ margin: "4px" }}
                        />
                      ))}
                    </Grid>

                    {contact.email && (
                      <SubjectData label="Email" value={contact.email} />
                    )}
                    {contact.phone && (
                      <SubjectData label="Phone" value={contact.phone} />
                    )}
                    {getFullAddress(contact.address).length > 0 && (
                      <SubjectData label="Address" value={<Address />} />
                    )}
                    {contact.birthday && (
                      <SubjectData label="Birthday" value={<Birthday />} />
                    )}
                    {hasSocialProfiles() && (
                      <SocialProfiles socialProfiles={contact.socialProfiles} />
                    )}

                    {contact.linkedContact && (
                      <SubjectData
                        label="Linked Contact"
                        value={
                          <Link
                            component={RouterLink}
                            to={`/contacts/${contact.linkedContact.id}`}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              component="span"
                              src={contact.linkedContact.avatarUrl}
                              style={{
                                width: "24px",
                                height: "24px",
                                margin: "2px 8px 0 0",
                              }}
                            ></Avatar>
                            {contact.linkedContact.name}
                          </Link>
                        }
                      />
                    )}

                    {contact.homeAnniversaryDate && (
                      <SubjectData
                        label="Home Anniversary Date"
                        value={<Anniversary />}
                      />
                    )}

                    {contact.owner && (
                      <SubjectData
                        label="Assigned Agent"
                        value={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              src={contact.owner.avatarUrl}
                              style={{
                                width: "24px",
                                height: "24px",
                                margin: "2px 8px 0 0",
                              }}
                            ></Avatar>
                            {contact.owner.name}
                          </div>
                        }
                      />
                    )}

                    {contact.leadSource && (
                      <SubjectData
                        label="Lead Source"
                        value={<LeadSource name={contact.leadSource} />}
                      />
                    )}

                    {contact.deals.length > 0 && (
                      <SubjectData
                        label="Client Portal Access"
                        value={<CollaboratorStatus />}
                      />
                    )}

                    <Accordion square>
                      <ActionAccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5" component="h5">
                          Custom Fields
                        </Typography>
                      </ActionAccordionSummary>
                      <AccordionDetails style={{ flexDirection: "column" }}>
                        {contact.customFields
                          .filter(
                            (customFieldValue: any) =>
                              customFieldValue.valueText
                          )
                          .map((customFieldValue: any) => (
                            <SubjectData
                              key={customFieldValue.id}
                              label={customFieldValue.label}
                              value={formatCustomField(customFieldValue)}
                            />
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  </>
                </LeftPane>
              </Box>
              <DetailPane onScroll={handleScroll}>
                <DetailCard
                  collapsible={true}
                  title={<Typography variant="h5">Deals</Typography>}
                >
                  <DealsContent style={{ width: "100%", padding: "0" }}>
                    <DealList deals={state.deals} />
                  </DealsContent>
                </DetailCard>

                <DetailCard
                  collapsible={true}
                  title={<Typography variant="h5">Notes</Typography>}
                  action={
                    <CreateNote
                      contactId={contact.id}
                      onNoteCreated={handleNoteCreated}
                    >
                      <IconButton size="large">
                        <AddIcon />
                      </IconButton>
                    </CreateNote>
                  }
                >
                  <NoteList
                    notes={state.notes}
                    onNoteEdited={handleNoteEdited}
                  />
                </DetailCard>

                <DetailCard
                  title={<Typography variant="h5">Tasks</Typography>}
                  action={
                    <ToggleButtonGroup
                      exclusive
                      value={tasksToggleValue}
                      onChange={handleTasksToggleChange}
                      style={{ marginRight: "8px" }}
                    >
                      <ToggleButton value="active">Active</ToggleButton>
                      <ToggleButton value="completed">Completed</ToggleButton>
                    </ToggleButtonGroup>
                  }
                >
                  <TaskList
                    tasks={tasks}
                    dispatch={dispatch}
                    dispatchType={ContactViewActionType}
                    filter={tasksToggleValue}
                    emptyStateText={"There are no tasks for this contact"}
                    onTaskUpdated={handleTaskUpdated}
                    onTaskDeleted={handleTaskDeleted}
                  />
                </DetailCard>

                <DetailCard
                  title={
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5">Messages</Typography>
                      <ToggleButtonGroup
                        exclusive
                        value={messageType}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e, v) => {
                          setMessageType(v);
                        }}
                      >
                        <ToggleButton value="email">Email</ToggleButton>
                        <ToggleButton value="sms">SMS</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  }
                  collapsible
                >
                  <Accordion
                    square
                    elevation={0}
                    expanded={true}
                    style={{
                      width: "100%",
                    }}
                  >
                    <AccordionDetails
                      style={{
                        flexDirection: "column",
                        padding: 0,
                      }}
                    >
                      {messageType === "sms" && (
                        <SmsThreads
                          threads={threads}
                          loading={threadsLoading}
                        />
                      )}
                      {messageType === "email" && (
                        <Messages
                          contact={contact}
                          threads={threads}
                          loading={threadsLoading}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </DetailCard>

                {contact.socialProfiles.twitter && (
                  <DetailCard
                    title={<Typography variant="h5">Social Posts</Typography>}
                    collapsible={true}
                  >
                    <SocialPosts contact={contact} />
                  </DetailCard>
                )}
              </DetailPane>
              <DealCreateDialog
                contacts={[contact]}
                open={dialogs.createDeal}
                onClose={() => {
                  setDialogs(defaultDialogs);
                }}
                onDealCreated={handleDealCreated}
              ></DealCreateDialog>
              <ContactUpdateForm
                open={editing}
                contact={contact}
                onClose={() => {
                  setEditing(false);
                }}
                onUpdate={handleUpdate}
              />
              <UserInviteDialog
                invitee={contact}
                teamMemberOrContact={"contact"}
                open={dialogs.invite}
                onClose={() => {
                  setDialogs(defaultDialogs);
                  handleUpdate();
                }}
              />
            </>
          )
        )}
      </SplitLayout>
    </Provider>
  );
};
export default connect(null, { refreshContactTags })(ContactsView);
