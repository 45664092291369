import { gql } from "@apollo/client";
import { ApiClient } from "../context/api";

const GET_MLS_LISTING = gql`
  query GetMlsListing($mlsKey: MlsKey!, $listingId: String!) {
    getMlsListing(mlsKey: $mlsKey, listingId: $listingId) {
      mlsKey
      listingId
      StreetNumber
      StreetName
      StreetSuffix
      StreetAdditionalInfo
      City
      StateOrProvince
      PostalCode
      ExpirationDate
      ListingContractDate
      ListPrice
      ClosePrice
      ConcessionsAmount
      CloseDate
    }
  }
`;

type MlsListingResult = {
  StreetNumber?: string;
  StreetName?: string;
  StreetSuffix?: string;
  StreetAdditionalInfo?: string;
  City?: string;
  StateOrProvince?: string;
  PostalCode?: string;
  ExpirationDate?: Date;
  ListingContractDate?: Date;
  ListPrice?: number;
  ClosePrice?: number;
  ConcessionsAmount?: number;
  CloseDate?: Date;
};

export const pullMlsListing = (
  client: ApiClient,
  mlsKey: string,
  listingId: string
) => {
  return client
    .query({
      query: GET_MLS_LISTING,
      variables: { mlsKey, listingId },
    })
    .then((res) => {
      const listing: MlsListingResult = res.data.getMlsListing;
      if (!listing) return null;
      return {
        address: {
          street: [
            listing.StreetNumber,
            listing.StreetName,
            listing.StreetSuffix,
          ].join(" "),
          street2: listing.StreetAdditionalInfo,
          city: listing.City,
          state: listing.StateOrProvince,
          zip: listing.PostalCode,
        },
        listingExpirationDate: listing.ExpirationDate,
        contractAgreementDate: listing.ListingContractDate,
        listPrice: listing.ListPrice,
        salePrice: listing.ClosePrice,
        sellerConcession: listing.ConcessionsAmount,
        closedDate: listing.CloseDate,
      };
    });
};
