import React, { useEffect, useState } from "react";
import { useAlert } from "../../../context/alert";
import TimelineSettings from "../../../components/TimelineSettings";
import { PrimaryButton } from "../../../components/buttons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { GET_DEAL_DOCUMENTS } from "../../../api/graphql";
import { Box, Typography, Paper } from "@mui/material";
import { Deal, DealType } from "../../../models";
import { DocumentChecklist } from "./DocumentChecklist";
import {
  AddDocumentTypesModal,
  DocumentTypesDeleteModal,
} from "../../../components/DocumentTypesModal";

type DocumentSettingsProps = {
  dealType?: DealType;
  setOpen?: (boolean: boolean) => void;
  deal?: Deal;
};

const DocumentTypeSettings = ({
  dealType,
  deal,
  setOpen,
}: DocumentSettingsProps) => {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [documentTypes, setDocumentTypes] = useState<any[]>();
  const [checklistItems, setChecklistItems] = useState<any[]>();
  const [state, setState] = useState<any>();
  const { showSuccess, showError } = useAlert();

  const [updateDocumentType] = useMutation(
    gql`
      mutation updateDocumentType($input: UpdateDocumentTypeInput!) {
        updateDocumentType(input: $input) {
          id
        }
      }
    `,
    {
      refetchQueries: [GET_DEAL_DOCUMENTS, "GetDealDocumentTypes"],
    }
  );

  const [deleteDocumentType] = useMutation(
    gql`
      mutation deleteDocumentType($input: DeleteDocumentTypeInput!) {
        deleteDocumentType(input: $input)
      }
    `,
    {
      refetchQueries: [GET_DEAL_DOCUMENTS, "GetDealDocumentTypes"],
    }
  );

  const { data, loading } = useQuery(GET_DEAL_DOCUMENTS, {
    variables: {
      dealId: deal ? deal.id : undefined,
      dealTypeId: dealType ? dealType.id : undefined,
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    setChecklistItems(data.getDocumentChecklist.items);
    setDocumentTypes(data.getDocumentTypes);
  }, [data]);

  const handleDeleteClick = (field: { id: string; name: string }) => {
    setState({ id: field.id, name: field.name });
    setDeleteOpen(true);
  };

  const handleDocumentTypeDelete = (state: { id: string; name: string }) => {
    deleteDocumentType({
      variables: {
        input: { id: state.id },
      },
    })
      .then(() => showSuccess(`The Document Type "${state.name}" was deleted`))
      .catch(() =>
        showError(
          `An error occurred when deleting the "${state.name}" Document Type`
        )
      );
    setDeleteOpen(false);
  };

  const handleEditClick = (field: { name: string; id: string }) => {
    setEditOpen(true);
    setState(field);
  };

  const handleDocumentTypeEdit = (
    e: React.FormEvent<HTMLFormElement>,
    state: { id: string; name: string }
  ) => {
    const input = {
      id: state.id,
      name: state.name,
    };
    updateDocumentType({
      variables: {
        input,
      },
    })
      .then(() => showSuccess(`The Document Type "${state.name}" was updated`))
      .catch(() =>
        showError(
          `An error occurred when updating the "${state.name} Document Type`
        )
      );
    setEditOpen(false);
  };
  return (
    <>
      {(documentTypes && documentTypes?.length > 0) ||
      (checklistItems && checklistItems?.length > 0) ||
      (deal && checklistItems) ? (
        <>
          <TimelineSettings
            deal={deal}
            documentTypes={documentTypes}
            checklistItems={checklistItems}
            isDocumentType
            dealType={dealType}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          >
            {setOpen && (
              <DocumentChecklist
                setOpen={setOpen}
                dealTypeName={dealType?.name}
              />
            )}
          </TimelineSettings>
        </>
      ) : (
        !loading && (
          <Paper>
            <Box p={4} textAlign="center">
              <Typography sx={{ marginBottom: "11px" }}>
                To get started with Document Checklists, create a Document Type
              </Typography>
              <PrimaryButton onClick={setOpen}>
                Create a Document Type
              </PrimaryButton>
            </Box>
          </Paper>
        )
      )}

      <AddDocumentTypesModal
        open={editOpen}
        setOpen={setEditOpen}
        setState={setState}
        state={state}
        handleAction={handleDocumentTypeEdit}
      />

      <DocumentTypesDeleteModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        handleDocumentTypeDelete={handleDocumentTypeDelete}
        state={state}
      />
    </>
  );
};
export default DocumentTypeSettings;
