import { Tag } from "../models";

export const CONTACT_TAGS = {
  SET: "CONTACT_TAGS/set",
};

const initialState: Tag[] = [];

export default function contactTagsReducer(state = initialState, action: any) {
  switch (action.type) {
    case CONTACT_TAGS.SET:
      return [...action.contactTags];
    default:
      return state;
  }
}
