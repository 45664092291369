import React, { useEffect, useMemo, useState } from "react";
import qs from "querystring";
import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { getOAuthState } from "../helpers";
import { connect } from "react-redux";
import { ACCOUNT } from "../reducers/account";

const CREATE_INTEGRATION = gql`
  mutation CreateIntegration($input: CreateIntegrationInput!) {
    createIntegration(input: $input)
  }
`;

const LINK_NYLAS = gql`
  mutation LinkNylas($input: LinkNylasInput!) {
    linkNylas(input: $input)
  }
`;

const LINK_STRIPE = gql`
  mutation LinkStripe($checkoutSessionId: String!) {
    linkStripe(checkoutSessionId: $checkoutSessionId)
  }
`;

function FinishPage({ reactivateAccount }: { reactivateAccount: () => void }) {
  const [showError, setShowError] = useState(false);
  const [createIntegration] = useMutation(CREATE_INTEGRATION);
  const [linkNylas] = useMutation(LINK_NYLAS);
  const [linkStripe] = useMutation(LINK_STRIPE);

  const navigate = useNavigate();
  const query = useMemo(() => qs.parse(window.location.search.slice(1)), []);
  const state = useMemo(() => {
    let state: any | null = null;
    if (query.state) {
      state = getOAuthState(query.state as string);
    }
    return state;
  }, [query]);

  useEffect(() => {
    if (state) {
      if (state.integration) {
        createIntegration({
          variables: {
            input: {
              level: state.level ?? "account",
              integration: state.integration,
              code: query.code,
            },
          },
        })
          .then((res) => {
            // Redirect to settings page
            const prefix =
              state.level === "user" ? "/settings/user" : "/settings";
            navigate(`${prefix}/integrations/${state.integration}`);
          })
          .catch(() => {
            setShowError(true);
          });
      } else if (state.partner === "nylas") {
        linkNylas({
          variables: {
            input: { code: query.code, scopes: state.scopes },
          },
        })
          .then(() => {
            navigate("/");
          })
          .catch(() => {
            setShowError(true);
          });
      }
    } else if (query?.checkoutSessionId) {
      linkStripe({
        variables: {
          checkoutSessionId: query.checkoutSessionId,
        },
      }).then(() => {
        // Update the user's account so they don't see the payment dialog again
        reactivateAccount();
        navigate("/");
      });
    }
  }, [
    state,
    navigate,
    linkNylas,
    linkStripe,
    createIntegration,
    query,
    reactivateAccount,
  ]);

  if (showError) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 21,
        }}
      >
        <Typography>
          An error occured when setting up your integration. Please{" "}
          <Link
            href=""
            onClick={(e) => {
              e.stopPropagation();
              navigate(-1);
            }}
          >
            go back
          </Link>{" "}
          and try again.
        </Typography>
      </Box>
    );
  }
  return <></>;
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    reactivateAccount: () => {
      return dispatch({
        type: ACCOUNT.SET,
        account: {
          cancelAtDate: null,
          isCanceled: false,
          needsBillingInfo: false,
          stripeCheckoutUrl: null,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinishPage);
