import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
  Theme,
  CircularProgress,
  Box,
  IconButton,
  CssBaseline,
  Button,
} from "@mui/material";
import { MenuIcon } from "../icons";
import UserNavigationElement from "../components/UserNavigationElement";
import AppDrawer from "../components/AppDrawer";
import moment from "moment";
import { connect } from "react-redux";
import { useUser } from "../context/user";
import { Close } from "@mui/icons-material";
import AccountInactiveDialog from "../components/AccountInactiveDialog";
import { PastDueAlert } from "../components/PastDueAlert";
import { useLocation } from "react-router-dom";
import { useAuth } from "../context/auth";
import { NylasSendAlert } from "../components/NylasAlert";
import { useMutation } from "@apollo/client";
import { ENSURE_STRIPE_CHECKOUT_URL } from "../api/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minWidth: "100%",
    },

    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    main: {
      height: "100vh",
      width: "75%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      display: "flex",
      flexDirection: "column",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    contentWithoutPadding: {
      flexGrow: 1,
    },
    loading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    },
    grow: {
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        flexGrow: 3,
      },
    },
    mobileAppLinks: {
      top: "auto",
      bottom: 0,
      height: 60,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  })
);

type MainLayoutProps = {
  children?: React.ReactNode;
  account?: any;
  loading?: boolean;
  withoutPadding?: boolean;
  logo: React.ReactElement;
  appDrawerListItems: JSX.Element;
};

const MainLayout: FunctionComponent<MainLayoutProps> = ({
  account,
  children,
  loading = false,
  withoutPadding = false,
  logo,
  appDrawerListItems,
}) => {
  const [ensureStripeCheckoutUrl] = useMutation(ENSURE_STRIPE_CHECKOUT_URL);
  const { isImpersonating } = useAuth();
  const { currentUser } = useUser();
  const classes = useStyles();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleCheckoutClick = async () => {
    if (
      account.trialStatus === "trial_lead" ||
      account.trialStatus === "trialLead"
    ) {
      const res = await ensureStripeCheckoutUrl();
      window.location.href = res.data.ensureStripeCheckoutUrl;
    } else {
      window.location.href = "/settings/account/billing";
    }
  };

  const allowWhileCanceled = [
    "/settings/account",
    "/settings/account/billing",
    "/settings/profile",
  ];
  const routeAllowed = allowWhileCanceled.includes(location.pathname);

  let blockUserFromApp = false;
  if (routeAllowed || isImpersonating) {
    // We won't block the user from accessing the app
  } else if (
    account.trialEnded ||
    account.isCanceled ||
    account.needsBillingInfo
  ) {
    blockUserFromApp = true;
  }

  const trialExpiresFromNow = () => {
    const current = moment().startOf("d");
    const trialEndDate = moment(account.trialEndDate).startOf("d");
    const days = Math.round(
      moment.duration(trialEndDate.diff(current)).asDays()
    );
    if (days === 1) {
      return "tomorrow";
    } else if (days <= 7) {
      return `in ${days} days`;
    } else {
      return `on ${trialEndDate.format("MMMM Do")}`;
    }
  };

  const UseMobileAppAlert = () => {
    const MOBILE_APP_LINKS_DISMISSED_KEY = "mobileAppLinksDismissed";
    const { currentUser } = useUser();
    const [show, setShow] = useState(false);

    useEffect(() => {
      const dismissedUntil = moment(
        window.localStorage.getItem(MOBILE_APP_LINKS_DISMISSED_KEY) ??
          Date.now()
      );
      if (
        currentUser &&
        !currentUser.teamMember &&
        window.innerWidth < 768 &&
        dismissedUntil.isBefore(moment())
      ) {
        setShow(true);
      }
    }, [currentUser]);

    const dismiss = useCallback(() => {
      // Hide the mobile app links for a day
      window.localStorage.setItem(
        MOBILE_APP_LINKS_DISMISSED_KEY,
        moment().add(1, "d").toISOString()
      );
      setShow(false);
    }, [setShow]);

    return show ? (
      <>
        <div style={{ paddingTop: 60 }}></div>
        <AppBar position="fixed" className={classes.mobileAppLinks}>
          <IconButton
            onClick={() => {}}
            style={{ visibility: "hidden" }}
            size="large"
          >
            <Close />
          </IconButton>
          <a
            href="https://play.google.com/store/apps/details?id=io.shaker.clientapp"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              style={{ height: "60px" }}
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/shaker-client-portal/id1579709663"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Download on the App Store"
              src="/app-store.svg"
              style={{ height: "60px", padding: "10px" }}
            />
          </a>
          <IconButton onClick={dismiss} size="large">
            <Close />
          </IconButton>
        </AppBar>
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar style={{ padding: "0 20px" }}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
              size="large"
            >
              <MenuIcon size="24px" />
            </IconButton>
            {logo}
            <div className={classes.grow} />

            {currentUser.hasEmailConnectivityError && <NylasSendAlert />}
            {account.cancelAtDate && <PastDueAlert account={account} />}
            {account.trialEndDate && !account.trialEnded && (
              <Button
                size="small"
                sx={{ marginRight: "1em" }}
                variant="outlined"
                color="warning"
                onClick={handleCheckoutClick}
              >
                Your trial ends {trialExpiresFromNow()}
              </Button>
            )}

            <UserNavigationElement />
          </Toolbar>
        </AppBar>

        <AppDrawer open={mobileOpen} onClose={() => setMobileOpen(false)}>
          {appDrawerListItems}
        </AppDrawer>

        {blockUserFromApp && <AccountInactiveDialog account={account} />}

        <main
          className={`${
            withoutPadding ? classes.contentWithoutPadding : classes.content
          } ${classes.main}`}
        >
          <div className={classes.toolbar} />
          {loading ? (
            <Box className={classes.loading}>
              <CircularProgress />
            </Box>
          ) : (
            children
          )}
          <UseMobileAppAlert />
        </main>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    account: state.account,
  };
};
export default connect(mapStateToProps)(MainLayout);
