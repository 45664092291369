import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import Annotation from "chartjs-plugin-annotation";
import DataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Annotation,
  ArcElement,
  DataLabels
);

const DoughnutChart = ({
  data: agg,
  getValue,
  showLegend = false,
}: {
  data: any[];
  getValue: (aggBundle: any) => any;
  showLegend?: boolean;
}) => {
  const colors = [
    "#351665",
    "#FF576D",
    "#37E6D9",
    "#FFB000",
    "#FFA184",
    "#7BDA5E",
    "#0B8D9B",
  ];

  const acc = agg.reduce(
    (acc, cur) => {
      acc.labels ??= [];
      acc.labels.push(
        cur.dealType?.name ?? cur.source ?? cur.dealState ?? "Unknown"
      );

      acc.data ??= [];
      acc.data.push(getValue(cur).total);

      return acc;
    },
    {
      labels: [],
      data: [],
    }
  );

  const data = {
    labels: acc.labels,
    datasets: [
      {
        label: "Commissions",
        data: acc.data,
        backgroundColor: acc.data.map(
          (_: unknown, i: number) => colors[i % colors.length]
        ),
      },
    ],
  };

  return (
    <Doughnut
      data={data}
      options={{
        plugins: {
          legend: {
            display: showLegend,
            position: "right",
            labels: {
              usePointStyle: true,
              boxWidth: 8,
            },
          },
          datalabels: {
            borderColor: "white",
            borderRadius: 25,
            borderWidth: 2,
            color: "white",
            anchor: "center",
            formatter(value, context) {
              return Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: 1,
              }).format(value);
            },
          },
        },
      }}
    />
  );
};

export default DoughnutChart;
