import React, { useState } from "react";
import { PrimaryButton, SecondaryButton } from "../buttons";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Link,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  DialogActions,
} from "@mui/material";

interface DealReportDialogProps {
  open: boolean;
  onClose: () => void;
  deal: any;
}

interface includedInReport {
  timeline: boolean;
  docs: boolean;
  notes: boolean;
  tasks: boolean;
}

const DealReportDialog = ({ onClose, open, deal }: DealReportDialogProps) => {
  const [includedInReport, setIncludedInReport] = useState<includedInReport>({
    timeline: true,
    docs: true,
    notes: true,
    tasks: true,
  });

  const handleClose = () => {
    onClose();
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludedInReport({
      ...includedInReport,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={"md"}
      aria-labelledby="generate-deal-report"
      open={open}
    >
      <DialogTitle id="generate-deal-report">
        Generate Deal Report
        <Typography variant="subtitle1">
          Choose which details to include in the report.
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={includedInReport.tasks}
                onChange={handleToggle}
                name="tasks"
                color="primary"
              />
            }
            label="Tasks"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includedInReport.timeline}
                onChange={handleToggle}
                name="timeline"
                color="primary"
              />
            }
            label="Timeline"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includedInReport.notes}
                onChange={handleToggle}
                name="notes"
                color="primary"
              />
            }
            label="Notes"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includedInReport.docs}
                onChange={handleToggle}
                name="docs"
                color="primary"
              />
            }
            label="Documents"
          />
        </FormGroup>
        <DialogActions style={{ marginTop: "16px" }}>
          <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
          <Link
            style={{ color: "#fff" }}
            component={PrimaryButton}
            href={`/deals/report/${deal.id}?timeline=${includedInReport.timeline}&docs=${includedInReport.docs}&notes=${includedInReport.notes}&tasks=${includedInReport.tasks}`}
            target="_blank"
            rel="noreferrer"
          >
            Generate Report
          </Link>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DealReportDialog;
