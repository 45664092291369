import { gql, useQuery } from "@apollo/client";
import { useCallback } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Skeleton,
  Paper,
} from "@mui/material";
import { PrimaryButton } from "../buttons";
import DetailCard from "../DetailCard";
import { Box } from "@mui/system";
import { CsvBuilder } from "filefy";
import { reportTableCheck } from "./Table";
import DoughnutChart from "./DoughnutChart";
import { Chart } from "./MonthlyChart";
import { RowDataTypes } from "../../models";

export const GET_AGGREGATE_DATA = gql`
  fragment AggregateData on DealReportAggregate {
    units {
      total
      average
    }
    volume {
      total
      average
    }
    commission {
      total
      average
    }
  }
  query GET_AGGREGATE_DATA(
    $state: DealState
    $groupBy: String
    $archived: Boolean
  ) {
    getReportAggregates(state: $state, archived: $archived, groupBy: $groupBy) {
      dealCount
      byTeamMembers {
        ...AggregateData
        groups {
          teamMemberId
          teamMember {
            name
          }
          ...AggregateData
        }
      }
      byDealTypes {
        ...AggregateData
        groups {
          dealTypeId
          dealType {
            name
          }
          ...AggregateData
        }
      }
      bySources {
        ...AggregateData
        groups {
          source
          ...AggregateData
        }
      }
    }
  }
`;

const ActiveDealsReport = () => {
  const { data, loading } = useQuery(GET_AGGREGATE_DATA, {
    variables: {
      state: "prospect",
      archived: false,
      groupBy: "createdAt",
    },
  });

  const exportCsv = useCallback(
    (data: { groups: RowDataTypes[] }, reportName: string) => {
      const builder = new CsvBuilder(`Active_${reportName}_report.csv`);
      const handleAddRows = () => {
        let arr: any = [];
        arr = data.groups.map((rowData: any) => {
          return [
            reportTableCheck(rowData),
            rowData.units.total,
            rowData.volume.total,
            rowData.commission.total,
          ];
        });

        return arr;
      };
      builder
        .setDelimeter(",")
        .setColumns([`${reportName}`, "Units", "Volume", "Commission"])
        .addRows(handleAddRows())
        .exportFile();
    },
    []
  );

  const currencyFormatter = (value: number | null | undefined) => {
    if (value == null) {
      return "";
    } else {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  };

  if (loading) {
    return <Skeleton />;
  }

  if (!loading && data?.getReportAggregates?.dealCount === 0) {
    return (
      <Box p={4} component={Paper} textAlign="center">
        <Typography variant="h6">No deals were found!</Typography>
        <Typography variant="body1">
          We'll show this report when there are active deals.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <DetailCard
        action={
          <PrimaryButton
            height="full"
            onClick={() =>
              exportCsv(data?.getReportAggregates?.byDealTypes, "DealType")
            }
          >
            Export as CSV
          </PrimaryButton>
        }
        title={<Typography variant="h5">By Deal Type</Typography>}
      >
        <Box
          p={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box width={"40%"}>
            <DoughnutChart
              showLegend
              data={data?.getReportAggregates?.byDealTypes.groups ?? []}
              getValue={(aggBundle: any) => {
                return aggBundle.units;
              }}
            />
          </Box>
          <TableContainer>
            <Table>
              <TableHead sx={{ background: "#351665", color: "#fff" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                  >
                    Deal Type
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                    align="right"
                  >
                    Units
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                    align="right"
                  >
                    Volume
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                    align="right"
                  >
                    Commission
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.getReportAggregates?.byDealTypes?.groups?.map(
                  (rowData: any, index: number) => (
                    <TableRow key={`dealTypeRow${index}`}>
                      <TableCell
                        sx={{
                          color: "#fff",
                          whiteSpace: "nowrap",
                          background: "#351665",
                          fontWeight: "bold",
                          borderColor: "#1A0B31",
                        }}
                      >
                        {rowData.dealType.name}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {rowData.units.total}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {currencyFormatter(rowData.volume.total)}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {currencyFormatter(rowData.commission.total)}
                      </TableCell>
                    </TableRow>
                  )
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#fff",
                      whiteSpace: "nowrap",
                      background: "#0b8d9b",
                      fontWeight: "bold",
                    }}
                  >
                    Total
                  </TableCell>

                  <TableCell
                    //bottom row month totals
                    sx={{
                      whiteSpace: "nowrap",
                      borderTop: "2px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {data?.getReportAggregates?.byDealTypes?.units?.total ?? 0}
                  </TableCell>

                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {currencyFormatter(
                      data?.getReportAggregates?.byDealTypes?.volume?.total ?? 0
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {currencyFormatter(
                      data?.getReportAggregates?.byDealTypes?.commission
                        ?.total ?? 0
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DetailCard>

      <DetailCard
        action={
          <PrimaryButton
            height="full"
            onClick={() =>
              exportCsv(data?.getReportAggregates?.byTeamMembers, "Agents")
            }
          >
            Export as CSV
          </PrimaryButton>
        }
        title={<Typography variant="h5">By Agent</Typography>}
      >
        <Box p={4}>
          <Chart
            data={data?.getReportAggregates?.byTeamMembers.groups ?? []}
            getValue={(agg) => agg.units}
          />
          <TableContainer>
            <Table>
              <TableHead sx={{ background: "#351665", color: "#fff" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                  >
                    Agent
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                    align="right"
                  >
                    Units
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                    align="right"
                  >
                    Volume
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                    align="right"
                  >
                    Commission
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.getReportAggregates?.byTeamMembers?.groups?.map(
                  (rowData: any, index: number) => (
                    <TableRow key={`teamMemberRow${index}`}>
                      <TableCell
                        sx={{
                          color: "#fff",
                          whiteSpace: "nowrap",
                          background: "#351665",
                          fontWeight: "bold",
                          borderColor: "#1A0B31",
                        }}
                      >
                        {rowData.teamMember.name}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {rowData.units.total}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {currencyFormatter(rowData.volume.total)}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {currencyFormatter(rowData.commission.total)}
                      </TableCell>
                    </TableRow>
                  )
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#fff",
                      whiteSpace: "nowrap",
                      background: "#0b8d9b",
                      fontWeight: "bold",
                    }}
                  >
                    Total
                  </TableCell>

                  <TableCell
                    //bottom row month totals
                    sx={{
                      whiteSpace: "nowrap",
                      borderTop: "2px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {data?.getReportAggregates?.byTeamMembers?.units?.total ??
                      0}
                  </TableCell>

                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {currencyFormatter(
                      data?.getReportAggregates?.byTeamMembers?.volume?.total ??
                        0
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {currencyFormatter(
                      data?.getReportAggregates?.byTeamMembers?.commission
                        ?.total ?? 0
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DetailCard>
      <DetailCard
        action={
          <PrimaryButton
            height="full"
            onClick={() =>
              exportCsv(data?.getReportAggregates?.bySources, "Sources")
            }
          >
            Export as CSV
          </PrimaryButton>
        }
        title={<Typography variant="h5">By Sources</Typography>}
      >
        <Box p={4}>
          <Chart
            data={data?.getReportAggregates?.bySources.groups ?? []}
            getValue={(agg) => agg.units}
          />
          <TableContainer>
            <Table>
              <TableHead sx={{ background: "#351665", color: "#fff" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                  >
                    Source
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                    align="right"
                  >
                    Units
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                    align="right"
                  >
                    Volume
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#1A0B31",
                    }}
                    align="right"
                  >
                    Commission
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.getReportAggregates?.bySources?.groups?.map(
                  (rowData: any, index: number) => (
                    <TableRow key={`teamMemberRow${index}`}>
                      <TableCell
                        sx={{
                          color: "#fff",
                          whiteSpace: "nowrap",
                          background: "#351665",
                          fontWeight: "bold",
                          borderColor: "#1A0B31",
                        }}
                      >
                        {rowData.source}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {rowData.units.total}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {currencyFormatter(rowData.volume.total)}
                      </TableCell>
                      <TableCell key={index} align="right">
                        {currencyFormatter(rowData.commission.total)}
                      </TableCell>
                    </TableRow>
                  )
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#fff",
                      whiteSpace: "nowrap",
                      background: "#0b8d9b",
                      fontWeight: "bold",
                    }}
                  >
                    Total
                  </TableCell>

                  <TableCell
                    //bottom row month totals
                    sx={{
                      whiteSpace: "nowrap",
                      borderTop: "2px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {data?.getReportAggregates?.bySources?.units?.total ?? 0}
                  </TableCell>

                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {currencyFormatter(
                      data?.getReportAggregates?.bySources?.volume?.total ?? 0
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {currencyFormatter(
                      data?.getReportAggregates?.bySources?.commission?.total ??
                        0
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DetailCard>
    </>
  );
};

export default ActiveDealsReport;
