import React, { useState, ChangeEvent } from "react";
import SettingsSubNav from "../components/SettingsSubNav";
import SettingsSelect from "../components/SettingsSelectMobile";
import SplitLayout from "../layouts/SplitLayout";
import LeftPane from "../components/LeftPane";
import DetailPane from "../components/DetailPane";
import { SecondaryButton, PrimaryButton } from "../components/buttons";
import {
  Add as AddIcon,
  DragIndicator as DragIndicatorIcon,
} from "@mui/icons-material";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  Theme,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Tabs,
  Tab,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { TrashIcon as DeleteIcon } from "../icons";
import { PencilIcon } from "../icons";
import { useAlert } from "../context/alert";
import PageTitle from "../components/PageTitle";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { DealType, Role } from "../models";
import {
  setRoles,
  createRole,
  updateRole,
  deleteRole,
  CreateRoleInput,
  UpdateRoleInput,
} from "../actions/roles";
import { ApiClient, useApi } from "../context/api";
import { ReactSortable, Sortable } from "react-sortablejs";
import clsx from "clsx";
import { gql, useMutation } from "@apollo/client";
import TeamMemberSelect from "../components/TeamMemberSelect";
import { useUser } from "../context/user";
import { Stack } from "@mui/system";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    roleName: {
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "14px",
      fontWeight: 500,
    },
    pageLayout: {
      flexGrow: 1,
      overflowX: "hidden",
      display: "flex",
      flexDirection: "row",
      marginBottom: "0",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        // padding: "2em",
      },
    },
    roleListItem: {
      background: "#fff",
      border: "1px solid #e5e7eb",
      marginBottom: "16px",
    },
    dragHandle: {
      color: "#6b7280",
      marginRight: "11px",
      cursor: "grab",
    },
  })
);

const SET_ROLE_ORDER = gql`
  mutation SetRoleOrder($roleIds: [ID!]!) {
    setRoleOrder(roleIds: $roleIds) {
      id
      sort
    }
  }
`;

type RolesProps = {
  roles: Role[];
  dealTypes: DealType[];
  setRoles: (roles: Role[]) => Promise<any>;
  createRole: (role: CreateRoleInput, apolloClient: ApiClient) => Promise<any>;
  updateRole: (role: UpdateRoleInput, apolloClient: ApiClient) => Promise<any>;
  deleteRole: (role: Role, apolloClient: ApiClient) => Promise<any>;
};

const Roles = ({
  roles,
  dealTypes,
  setRoles,
  createRole,
  updateRole,
  deleteRole,
}: RolesProps): JSX.Element => {
  const { apolloClient } = useApi();
  const classes = useStyles();
  const { hasPermission } = useUser();
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const { showSuccess, showError } = useAlert();
  const [role, setRole] = useState<Role>({});
  const [setRoleOrder] = useMutation(SET_ROLE_ORDER);

  const canEditRoles = () => hasPermission("settings", "full");

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddClick = (e: any) => {
    setRole({ name: "" });
    setOpen(true);
  };

  const handleEditClick = (e: any, role: any) => {
    setRole(role);
    setOpen(true);
  };

  const handleSave = (e: any) => {
    if (!role) return;

    let promise: Promise<any>;

    const dealTypeIds = role.dealTypes?.map((dealType) => dealType.id);
    if (role.id) {
      promise = updateRole(
        {
          dealTypeIds,
          id: role.id,
          name: role.name as string,
          defaultTeamMemberId: role.defaultTeamMemberId,
        },
        apolloClient
      );
    } else {
      promise = createRole(
        {
          dealTypeIds,
          name: role.name as string,
          defaultTeamMemberId: role.defaultTeamMemberId,
        },
        apolloClient
      );
    }

    promise
      .then(() => {
        showSuccess(`The role "${role.name} was saved"`);
        setOpen(false);
      })
      .catch(() => {
        showError("An error occurred when saving the role");
      });
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRole((role: Role) => {
      return { ...role, name: e.target.value };
    });
  };

  const handleDelete = (e: any, role: Role) => {
    if (!window.confirm("Are you sure you want to delete this role?")) {
      return;
    }
    deleteRole(role, apolloClient)
      .then(() => {
        showSuccess(`The role "${role.name}" was deleted successfully`);
      })
      .catch(() => {
        showError("An error occurred when deleting the role");
      });
  };

  const handleSortUpdate = (event: Sortable.SortableEvent) => {
    const { oldIndex, newIndex } = event;
    const copy = [...roles];
    const roleToMove = copy.splice(oldIndex as number, 1)[0];
    copy.splice(newIndex as number, 0, roleToMove);

    const roleIds = copy.map((role) => role.id);
    setRoleOrder({ variables: { roleIds } });
  };

  return (
    <SplitLayout>
      <LeftPane width={224}>
        <SettingsSubNav />
        <SettingsSelect label="" defaultValue="/settings/roles" />
      </LeftPane>
      <DetailPane>
        <Grid container className={classes.pageLayout} spacing={2}>
          <Grid item xs={12}>
            <PageTitle
              title="Roles"
              subtitle={
                <Typography>
                  Roles allow you to associate one or more{" "}
                  <Link to="/settings/team">collaborators</Link> to a deal
                </Typography>
              }
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "right" }}>
            <PrimaryButton
              height="short"
              onClick={handleAddClick}
              disabled={!canEditRoles()}
            >
              <AddIcon
                style={{
                  width: "13px",
                  height: "13px",
                  marginRight: "4px",
                }}
              />
              New Role
            </PrimaryButton>
          </Grid>

          <Grid item xs={12}>
            <ReactSortable
              list={roles as any[]}
              setList={(roles) => setRoles(roles as any[])}
              handle=".handle"
              onUpdate={handleSortUpdate}
              filter=".undraggable"
            >
              {roles.map((role: Role) => {
                return (
                  <ListItem className={classes.roleListItem} key={role.id}>
                    <ListItemText
                      primary={
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          {canEditRoles() && (
                            <DragIndicatorIcon
                              className={clsx("handle", classes.dragHandle)}
                            />
                          )}
                          <Typography className={classes.roleName}>
                            {role.name}
                          </Typography>
                        </Box>
                      }
                    ></ListItemText>
                    <div style={{ flexGrow: 1 }}></div>

                    <Tooltip
                      title="Delete"
                      aria-label="delete"
                      className="table-row-action"
                    >
                      <span>
                        <IconButton
                          onClick={(e) => handleDelete(e, role)}
                          disabled={
                            role.isBuyersAgent ||
                            role.isListingAgent ||
                            !canEditRoles()
                          }
                          size="large"
                        >
                          <DeleteIcon size="20px" />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip
                      title="Edit"
                      aria-label="edit"
                      className="table-row-action"
                    >
                      <span>
                        <IconButton
                          onClick={(e) => handleEditClick(e, role)}
                          disabled={
                            role.isBuyersAgent ||
                            role.isListingAgent ||
                            !canEditRoles()
                          }
                          size="large"
                        >
                          <PencilIcon size="20px" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </ListItem>
                );
              })}
            </ReactSortable>
          </Grid>
        </Grid>
      </DetailPane>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>{role.id ? "Edit a Role " : "Add a Role"}</DialogTitle>
        <DialogContent>
          <Tabs
            sx={{ marginBottom: 2 }}
            value={tabIndex}
            onChange={(e, tabIndex) => {
              setTabIndex(tabIndex);
            }}
            aria-label="Settings Tabs"
          >
            <Tab label="General" />
            <Tab label="Advanced" />
          </Tabs>

          <Stack spacing={2}>
            {tabIndex === 0 && (
              <>
                <TextField
                  fullWidth
                  autoFocus
                  label="Name"
                  variant="standard"
                  value={role.name}
                  onChange={handleNameChange}
                  placeholder="e.g. Listing Specialist"
                />
                <TeamMemberSelect
                  label="Default Team Member"
                  value={role.defaultTeamMemberId}
                  onChange={(teamMember) => {
                    setRole((role) =>
                      Object.assign({}, role, {
                        defaultTeamMemberId: teamMember?.id ?? null,
                      })
                    );
                  }}
                />
              </>
            )}
            {tabIndex === 1 && (
              <>
                <FormControl style={{ width: "100%" }} variant="standard">
                  <FormLabel>
                    Which Deal Types should this field appear on?
                  </FormLabel>
                  <FormGroup>
                    {dealTypes?.map((dealType) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={role.dealTypes?.some(
                              (dt) => dt.id === dealType.id
                            )}
                            onChange={(e, checked) => {
                              if (checked) {
                                setRole((role) =>
                                  Object.assign({}, role, {
                                    dealTypes: [
                                      ...(role.dealTypes ?? []),
                                      dealType,
                                    ],
                                  })
                                );
                              } else {
                                setRole((role) =>
                                  Object.assign({}, role, {
                                    dealTypes: role.dealTypes?.filter(
                                      (dt) => dt.id !== dealType.id
                                    ),
                                  })
                                );
                              }
                            }}
                            value={dealType.id}
                          />
                        }
                        label={dealType.name}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            onClick={handleClose}
            color="primary"
            style={{ width: "100px" }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSave}
            color="primary"
            style={{ width: "100px" }}
          >
            {role.id ? "Save Role " : "Add Role"}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </SplitLayout>
  );
};

const mapStateToRoles = ({
  roles,
  dealTypes,
}: {
  roles: Role[];
  dealTypes: DealType[];
}) => {
  return { roles, dealTypes };
};

export default connect(mapStateToRoles, {
  setRoles,
  createRole,
  updateRole,
  deleteRole,
})(Roles);
