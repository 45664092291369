import React, { useEffect, useState } from "react";
import moment from "moment";
import { useUser } from "../../context/user";
import { Grid, Box, SelectChangeEvent } from "@mui/material";

import LabeledSelect from "../../components/LabeledSelect";

import {
  ToggleButtonGroup,
  ToggleButton,
} from "../../components/ToggleButtons";

import DetailCard from "../DetailCard";
import CreateGoalRows from "./GoalRows";
import { PrimaryButton } from "../buttons";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useAlert } from "../../context/alert";
import { GET_GOALS } from "../../api/graphql";
import { GoalType } from "../../models";
import TeamMemberLabeledSelect from "../TeamMemberLabeledSelect";

const SET_GOALS = gql`
  mutation ($input: SetGoalsInput!) {
    setGoals(input: $input) {
      teamMemberId
      year
      month
      units
      commission
      volume
    }
  }
`;

const GoalSettings = () => {
  const [form, setForm] = useState<{ yearly: GoalType; monthly: GoalType[] }>({
    yearly: {
      year: moment().year(),
      units: undefined,
      commission: undefined,
      volume: undefined,
    },
    monthly: [],
  });

  const { currentUser } = useUser();

  const [year, setYear] = useState(moment().year());
  const [goalRange, setGoalRange] = useState("monthly");
  const [teamMember, setTeamMember] = useState(currentUser.teamMember.id);
  const [displayPrefill, setDisplayPrefill] = useState(false);
  const [toggleGoalTypes, setToggleGoalTypes] = useState<"team" | "individual">(
    "individual"
  );

  const [yearOptions] = useState(() => {
    const years = [];
    const dateStart = moment();
    const dateEnd = moment().add(2, "y");
    while (dateEnd.diff(dateStart, "years") >= 0) {
      years.push(dateStart.format("YYYY"));
      dateStart.add(1, "year");
    }
    let yearOptionsArr: { label: string; value: string }[] = [];
    years.map((year) => yearOptionsArr.push({ label: year, value: year }));

    return yearOptionsArr;
  });

  const { showSuccess } = useAlert();

  const { data, loading, refetch } = useQuery(GET_GOALS, {
    variables: {
      teamMemberId: teamMember === "Team" ? undefined : teamMember,
      year: year,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setDisplayPrefill(data.getGoals.yearly.id === null);
      return setForm(data.getGoals);
    }
  }, [loading, data, goalRange, year]);

  const [setGoals] = useMutation(SET_GOALS);

  const handleSubmit = async () => {
    let input = {};

    const teamMemberCheck = teamMember === "Team" ? undefined : teamMember;
    if (goalRange === "yearly") {
      input = {
        teamMemberId: teamMemberCheck,
        year: year,
        units: form.yearly.units,
        commission: form.yearly.commission,
        volume: form.yearly.volume,
      };
      setGoals({
        variables: {
          input,
        },
      }).then(() => {
        showSuccess("Yearly Goals Saved successfully");
        refetch();
      });
    } else {
      await Promise.all(
        form.monthly.map(async (goal: GoalType): Promise<any> => {
          input = {
            teamMemberId: teamMemberCheck,
            year: year,
            month: goal.month,
            units: goal.units,
            commission: goal.commission,
            volume: goal.volume,
          };
          await setGoals({
            variables: {
              input,
            },
          }).then(() => {
            showSuccess("Monthly Goals Saved successfully");
          });
        })
      ).then(() => {
        refetch();
        showSuccess("Monthly Goals Saved successfully");
      });
    }
  };

  const handleGoalRangeChange = () => {
    const currentYear = moment().year().toString();
    return (
      <>
        <LabeledSelect
          label="Year:"
          selectProps={{
            onChange: (event: SelectChangeEvent<any>) =>
              setYear(Number.parseInt(event.target.value, 10)),
            style: { height: "30px", width: "125px" },
            defaultValue: currentYear,
          }}
          options={yearOptions}
        />
      </>
    );
  };

  const handleGoalToggleTypes = (goalType: any) => {
    if (goalType === "team") {
      setTeamMember(null);
    } else setTeamMember(currentUser.teamMember.id);
    return setToggleGoalTypes(goalType);
  };
  return (
    <Grid>
      <Grid
        container
        spacing={3}
        alignItems="center"
        sx={{
          marginBottom: "10px",
        }}
      >
        <Grid
          item
          sx={{
            "&:first": {
              paddingTop: "0",
            },
          }}
        >
          <ToggleButtonGroup
            value={goalRange}
            exclusive
            onChange={(e: any) => setGoalRange(e.target.value)}
            sx={{
              height: "35px",
            }}
          >
            <ToggleButton value="monthly">Monthly</ToggleButton>
            <ToggleButton value="yearly">Yearly</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>{handleGoalRangeChange()}</Grid>
        <Grid item>
          <ToggleButtonGroup
            value={toggleGoalTypes}
            exclusive
            onChange={(e: any) => handleGoalToggleTypes(e.target.value)}
          >
            <ToggleButton value="individual">Individual</ToggleButton>
            <ToggleButton value="team">Team</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid
          item
          sx={{
            width: "250px",
          }}
        >
          {toggleGoalTypes === "individual" && (
            <TeamMemberLabeledSelect
              onlyUsers
              onChange={(teamMember) => setTeamMember(teamMember.id)}
              value={teamMember}
            />
          )}
        </Grid>
      </Grid>
      {!loading && (
        <DetailCard collapsible={false}>
          {}
          <Box m={3}>
            {goalRange === "yearly" ? (
              <CreateGoalRows
                rowData={form.yearly}
                i={0}
                setForm={setForm}
                form={form.yearly}
                displayPrefill={displayPrefill}
                setDisplayPrefill={setDisplayPrefill}
              />
            ) : (
              form?.monthly.map((rowData: GoalType, i: number) => (
                <Box key={i}>
                  <CreateGoalRows
                    rowData={rowData}
                    i={i}
                    setForm={setForm}
                    form={form.monthly}
                    displayPrefill={displayPrefill}
                    setDisplayPrefill={setDisplayPrefill}
                  />
                </Box>
              ))
            )}

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <PrimaryButton onClick={() => handleSubmit()}>
                Save Goals
              </PrimaryButton>
            </Grid>
          </Box>
        </DetailCard>
      )}
    </Grid>
  );
};

export default GoalSettings;
