// @ts-nocheck
import React, { FunctionComponent } from "react";
import { TextField, InputAdornment } from "@mui/material";
import InputMask from "react-input-mask";
import { Phone } from "@mui/icons-material";

type PhoneFieldProps = {
  type?: string;
  label?: any;
  value: string | null;
  settings: { format: "us_can" | "uk" | "aus" | "default" };
  onChange: (value: null | string) => void;
  fullWidth?: boolean;
  hideIcon?: boolean;
};

const PhoneField: FunctionComponent<PhoneFieldProps> = ({
  label,
  settings,
  value,
  onChange,
  hideIcon,
}) => {
  let phoneMask: string;

  switch (settings?.format) {
    case "us_can":
      phoneMask = "(999) 999-9999";
      break;
    case "uk":
      phoneMask = "999 9999 9999";
      break;
    case "aus":
      phoneMask = "(99) 9999 9999";
      break;
    default:
      phoneMask = "(999) 999-9999";
  }

  let inputObject: any = {
    startAdornment: (
      <InputAdornment position="start">
        <Phone color="action" />
      </InputAdornment>
    ),
  };
  if (hideIcon) {
    inputObject = {};
  }

  /**
   *
   */
  return (
    /*
      // @ts-ignore */
    <InputMask
      mask={phoneMask}
      alwaysShowMask={true}
      value={value === null ? "" : value}
      onChange={(e) => onChange(e.target.value)}
    >
      {() => (
        <TextField
          label={label}
          fullWidth
          InputProps={inputObject}
          variant="standard"
        />
      )}
    </InputMask>
  );
};

export default PhoneField;
