import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ActionIconButton from "../ActionIconButton";
import { gql, useApolloClient } from "@apollo/client";
import { withStyles } from "@mui/styles";
import SmsThreadContainer from "./SmsThreadContainer";
import { Deal } from "../../models/index";

type ComposeEmailDialogProps = {
  open: boolean;
  recipients?: Array<any>;
  smsThread?: any | undefined;
  disableRecipientSelection?: boolean;
  onSmsSent?: () => any;
  onClose?: () => any;
  classes: any;
  contactDeals?: Deal[];
};

const ComposeSmsDialog: FunctionComponent<ComposeEmailDialogProps> = ({
  open,
  recipients: _recipients,
  smsThread,
  onClose,
  disableRecipientSelection = false,
  classes,
  contactDeals,
}) => {
  const apollo = useApolloClient();
  const [recipients, setRecipients] = useState<any[] | undefined>(_recipients);
  const [smsThreadViewProps, setSmsThreadViewProps] = useState<{
    recipients?: any[];
    smsThread?: any;
  }>({
    recipients: [],
    smsThread: undefined,
  });

  const cleanRecipients = (values: any[]) =>
    values.map((value: any) => {
      if (value.__typename === "Contact") {
        return { contactId: value.id };
      } else if (value.__typename === "TeamMember") {
        return { teamMemberId: value.id };
      } else {
        return { phone: value };
      }
    });

  useEffect(() => {
    if (!recipients || recipients.length === 0) {
      return;
    }
    apollo
      .query({
        query: gql`
          query GetSmsThreadByRecipients($recipients: [SmsRecipientInput!]!) {
            getSmsThreadByRecipients(recipients: $recipients) {
              id
            }
          }
        `,
        variables: {
          recipients: cleanRecipients(recipients),
        },
      })
      .then((res) => {
        if (res.data.getSmsThreadByRecipients) {
          // Found a thread
          setSmsThreadViewProps({
            smsThread: res.data.getSmsThreadByRecipients,
          });
        } else {
          setSmsThreadViewProps({
            recipients,
          });
        }
      });
  }, [recipients, apollo]);

  const handleRecipientChange = async (values: any[]) => {
    setRecipients(values);
  };

  useEffect(() => {
    setRecipients(_recipients);
  }, [_recipients]);

  useEffect(() => {
    setSmsThreadViewProps({
      smsThread,
    });
  }, [smsThread]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle className={classes.dialogTitleContainer}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Send Text Message</Typography>
          <ActionIconButton
            icon={Close}
            style={{ margin: "4px -5px" }}
            buttonStyle={{ width: "22px", height: "22px" }}
            onClick={onClose}
            aria-label="close"
          />
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContentContainer}>
        <SmsThreadContainer
          recipients={recipients}
          handleRecipientChange={handleRecipientChange}
          disableRecipientSelection={disableRecipientSelection}
          smsThreadViewProps={smsThreadViewProps}
          contactDeals={contactDeals}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withStyles({
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
  dialogContentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 0,
    paddingBottom: 0,
  },
  dialogTitleContainer: {
    paddingBottom: 0,
    marginBottom: "8px",
  },
})(ComposeSmsDialog);
