import React, { useState, useRef } from "react";
import { Popover, Typography, Theme, Avatar, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ContactNameWithAvatar from "../contacts/ContactNameWithAvatar";
import { EmailIcon, PhoneIcon } from "../../icons";
import LinkIcon from "@mui/icons-material/Link";
import { TeamMember } from "../../models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarAndName: {
      cursor: "default",
      "&:hover": { textDecoration: "underline" },
    },
    popoverPaper: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
    },
    avatar: {
      marginRight: "24px",
      height: "74px",
      width: "74px",
    },
    popoverRole: {
      color: theme.palette.grey[600],
    },
    popoverContactInfo: {
      fontSize: "14px",
      height: "24px",
      display: "flex",
      alignItems: "center",
      "& svg": {
        color: theme.palette.grey[600],
        marginRight: "12px",
      },
    },
    popoverContactLink: {
      textDecoration: "none",
      color: theme.palette.grey[900],
    },
    popoverIcon: {
      fontSize: "16px",
    },
  })
);

type DealTeamMemberWithPopoverProps = {
  dealTeamMember: any;
  avatarStyle?: any;
  sx?: React.CSSProperties;
};

const DealTeamMemberWithPopover = ({
  dealTeamMember,
  avatarStyle,
  sx,
}: DealTeamMemberWithPopoverProps) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const TitleAndCompany = ({ teamMember }: { teamMember: TeamMember }) => {
    let textParts: JSX.Element[] = [];

    if (teamMember.title) {
      textParts.push(<>{teamMember.title}</>);
    }

    if (teamMember.company) {
      textParts.push(<>{teamMember.company}</>);
    }

    if (textParts.length > 0) {
      return (
        <Typography variant="subtitle1">
          {textParts.reduce((joined, element) => (
            <>
              {joined}, {element}
            </>
          ))}
        </Typography>
      );
    } else {
      return <></>;
    }
  };

  const formatLink = (link: string) => {
    if (link.substring(0, 3) === "http") {
      return link;
    } else {
      return `//${link}`;
    }
  };

  return (
    <Box sx={sx}>
      <div
        onClick={() => setPopoverOpen(true)}
        className={classes.avatarAndName}
      >
        <ContactNameWithAvatar
          name={dealTeamMember.teamMember.name}
          avatarUrls={[dealTeamMember.teamMember.avatarUrl]}
          avatarStyle={avatarStyle}
          ref={anchorRef}
        />
      </div>
      <Popover
        classes={{
          paper: classes.popoverPaper,
        }}
        open={popoverOpen}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => setPopoverOpen(false)}
        disableRestoreFocus
      >
        <Avatar
          component="span"
          alt={dealTeamMember.teamMember.name}
          title={dealTeamMember.teamMember.name}
          src={dealTeamMember.teamMember.avatarUrl}
          className={classes.avatar}
        />

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" className={classes.popoverRole}>
            {dealTeamMember.role.name}
          </Typography>
          <Typography variant="h5">{dealTeamMember.teamMember.name}</Typography>
          <TitleAndCompany teamMember={dealTeamMember.teamMember} />
          <div style={{ margin: "6px" }}></div>
          {dealTeamMember.teamMember.phone && (
            <a
              href={`tel:${dealTeamMember.teamMember.phone}`}
              className={classes.popoverContactLink}
            >
              <Typography
                variant="body2"
                className={classes.popoverContactInfo}
              >
                <PhoneIcon size="16px" />
                {dealTeamMember.teamMember.phone}
              </Typography>
            </a>
          )}
          {dealTeamMember.teamMember.email && (
            <a
              href={`mailto:${dealTeamMember.teamMember.email}`}
              className={classes.popoverContactLink}
            >
              <Typography
                variant="body2"
                className={classes.popoverContactInfo}
              >
                <EmailIcon size="16px" />
                {dealTeamMember.teamMember.email}
              </Typography>
            </a>
          )}
          {dealTeamMember.teamMember.websiteUrl && (
            <a
              href={formatLink(dealTeamMember.teamMember.websiteUrl)}
              className={classes.popoverContactLink}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                variant="body2"
                className={classes.popoverContactInfo}
              >
                <LinkIcon className={classes.popoverIcon} />
                {dealTeamMember.teamMember.websiteUrl}
              </Typography>
            </a>
          )}
        </div>
      </Popover>
    </Box>
  );
};

export default DealTeamMemberWithPopover;
