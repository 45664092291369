//export * from "./auth";
//export * from "./validation";

import { v4 as uuid } from "uuid";
import moment from "moment";

export function makeMoment(s: string): moment.Moment {
  if (typeof s === "string" && s.indexOf("T00:00:00.000Z") > -1) {
    // Midnight UTC, discard time and treat as a date in local time
    const YmdInUtc = moment.utc(s).format("YYYY-MM-DD");
    return moment(YmdInUtc);
  }
  return moment(s);
}

export function validateEmail(s: string | undefined | null) {
  s = s?.trim();
  return [s, !s || s.length === 0 || s.indexOf("@") === -1];
}

export function validateString(
  s: string | undefined | null,
  minLength: number = 1
) {
  s = s?.trim();

  return [s, !s || s.length < minLength];
}

export const hasTrueValues = (obj: object) =>
  Object.values(obj).indexOf(true) !== -1;

export const sourceToDomain = (source: string) => {
  switch (source.toLowerCase()) {
    case "shaker":
    case "shaker.io":
      return "shaker.io";
    case "zillow":
    case "zillow.com":
      return "zillow.com";
    case "trulia":
    case "trulia.com":
      return "trulia.com";
    case "realtor.com":
      return "realtor.com";
    case "zapier":
    case "zapier.com":
      return "zapier.com";
    case "hubspot":
    case "hubspot.com":
      return "hubspot.com";
    default:
      return null;
  }
};

export const screenLessThan = (screenSize: number) => {
  if (window.screen.availWidth <= screenSize) {
    return true;
  } else {
    return false;
  }
};

export const makeOAuthState = (state: any) => {
  const id = uuid();
  window.localStorage.setItem(id, JSON.stringify(state));
  return id;
};

export const getOAuthState = (id: string): any | null => {
  const stateStr = window.localStorage.getItem(id);
  return stateStr ? JSON.parse(stateStr) : null;
};

export const format24HourTime = (time: string) => {
  if (!time) {
    return;
  }
  const [hours, minutes] = time.split(":").map((part) => parseInt(part, 10));
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
};
