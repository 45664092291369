import React, { FunctionComponent } from "react";
import { TextField, Typography, Box } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import { gql, useQuery } from "@apollo/client";

import { Skeleton } from "@mui/material";

type SmsTemplateAutocompleteProps = {
  value?: any;
  onChange?: (e: React.ChangeEvent<{}>, value: any) => void;
};
const SmsTemplateAutocomplete: FunctionComponent<
  SmsTemplateAutocompleteProps
> = ({ value, onChange }) => {
  const { data, loading } = useQuery(gql`
    query {
      getSmsTemplates {
        id
        name
        body
      }
    }
  `);

  const renderOption = (props: any, option: any) => {
    return (
      <Box
        component="li"
        {...props}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h6">{option.name}</Typography>
        <Typography
          color="textSecondary"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            height: "2em",
            lineHeight: "1em",
          }}
        >
          {option.body}
        </Typography>
      </Box>
    );
  };

  const getOptionSelected = (option: any, value: any) => {
    return option.id === value.id;
  };

  const handleChange = (e: React.ChangeEvent<{}>, value: any) => {
    if (onChange) {
      onChange(e, value);
    }
  };

  if (loading) {
    return <Skeleton variant="rectangular" />;
  }
  return (
    <Autocomplete
      fullWidth
      onChange={handleChange}
      value={value}
      renderOption={renderOption}
      options={data?.getSmsTemplates || []}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={(option: any) =>
        option && option.name ? option.name : ""
      }
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="standard"
          label="SMS template"
          placeholder="Select a SMS template&hellip;"
        />
      )}
    ></Autocomplete>
  );
};

export default SmsTemplateAutocomplete;
