import React, { useState } from "react";
import ClientPortalLayout from "../layouts/ClientPortalLayout";
import VendorCard from "../components/vendors/VendorCard";
import { gql, useQuery } from "@apollo/client";
import { Tag } from "../models";
import { Typography, Theme, Grow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import FilterVendorCards from "../components/vendors/FilterVendorCards";

const GET_VENDORS = gql`
  query GetVendors {
    getVendors {
      id
      name
      company
      phone
      email
      websiteUrl
      tags {
        id
        name
        color
      }
      account {
        id
        name
        logoUrl
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0 16px 16px",
      overflow: "scroll",
    },
    headerTitle: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1),
      },
    },
    headerRow: {
      display: "flex",
      alignItems: "center",
      padding: "10px 0",
      position: "sticky",
      top: 0,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        backgroundColor: "#fafafa",
      },
    },
    vendorCardContainer: {
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.down("lg")]: {
        justifyContent: "center",
      },
    },
  })
);

const ClientVendors = () => {
  const classes = useStyles();

  const [vendors, setVendors] = useState<Array<any>>([]);
  const [tags, setTags] = useState<Array<any>>([]);
  const [accountIsSame, setAccountIsSame] = useState<boolean>(true);

  const handleCompleted = (res: any) => {
    setVendors(
      res.getVendors.map((vendor: any) => ({ ...vendor, visible: true }))
    );

    // Build an array of all the tags that the vendors have
    const allTags: Tag[] = res.getVendors.reduce(
      (acc: { [name: string]: Tag }, vendor: any) => {
        vendor.tags.forEach((tag: Tag) => (acc[tag.name] = tag));
        return acc;
      },
      {}
    );

    setTags(
      Object.values(allTags).sort((a: Tag, b: Tag) =>
        a.name.localeCompare(b.name)
      )
    );

    const moreThanOneAccount = res.getVendors.every(
      (vendor: any) => vendor.account.id === res.getVendors[0].account.id
    );
    setAccountIsSame(moreThanOneAccount);
  };

  useQuery(GET_VENDORS, {
    onCompleted: handleCompleted,
  });

  const filteredVendors = vendors.filter((vendor) => vendor.visible === true);

  return (
    <ClientPortalLayout>
      <div className={classes.root}>
        <div className={classes.headerRow}>
          <Typography variant="h3" className={classes.headerTitle}>
            Vendors
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
          <FilterVendorCards
            tags={tags}
            vendors={vendors}
            setVendors={setVendors}
          />
        </div>
        <div className={classes.vendorCardContainer}>
          {filteredVendors.map((vendor: any) => (
            <Grow
              in={vendor.visible}
              style={{ transformOrigin: "0 0 0" }}
              {...(vendor.visible ? { timeout: 1000 } : {})}
            >
              <div>
                <VendorCard
                  key={vendor.id}
                  vendor={vendor}
                  accountIsSame={accountIsSame}
                />
              </div>
            </Grow>
            // <VendorCard
            //   key={vendor.id}
            //   vendor={vendor}
            //   accountIsSame={accountIsSame}
            // />
          ))}
        </div>
      </div>
    </ClientPortalLayout>
  );
};

export default ClientVendors;
