import React, { useState, useCallback, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Box, Chip } from "@mui/material";
import ContactNameWithAvatar from "./contacts/ContactNameWithAvatar";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_TEAM_MEMBERS, GET_TEAM_MEMBER_SUGGESTIONS } from "../api/graphql";
import { Deal, TeamMember } from "../models";

type TeamMemberSelectProps = {
  onChange: (teamMember: any) => void;
  value?: string | null;
  initialTeamMember?: TeamMember;
  label?: string;
  deal?: Deal;
  disabled?: boolean;
  filterVendors?: boolean;
};

export default function TeamMemberSelect({
  onChange,
  value = null,
  initialTeamMember,
  label = "Search for a team member...",
  deal,
  disabled = false,
  filterVendors,
}: TeamMemberSelectProps) {
  const { data, refetch } = useQuery(GET_TEAM_MEMBERS);
  const [getTeamMemberSuggestions] = useLazyQuery(GET_TEAM_MEMBER_SUGGESTIONS);
  const [inputValue, setInputValue] = useState("");
  const [teamMembers, setTeamMembers] = useState<Array<any>>([]);
  const [suggestions, setSuggestions] = useState<Array<any>>([]);

  const handleChange = useCallback(
    (newValue: any) => {
      if (newValue !== value) {
        onChange(newValue);
      }
    },
    [onChange, value]
  );

  const parseTeamMembersResponse = useCallback(
    (teamMembers: TeamMember[]) => {
      if (filterVendors) {
        teamMembers = teamMembers.filter((tm) => !tm.preferred);
      }

      // If getTeamMembers returns a list without the initialTeamMember, that
      // means the currently selected team member has been deleted, but is still
      // on the deal. This is fine, so we need to add that team member to the
      // select list.
      if (
        initialTeamMember &&
        !teamMembers.find((tm) => tm.id === initialTeamMember.id)
      ) {
        teamMembers = [initialTeamMember, ...teamMembers];
      }
      setTeamMembers(teamMembers);
      return teamMembers;
    },
    [filterVendors, initialTeamMember]
  );

  useEffect(() => {
    if (data) {
      parseTeamMembersResponse(data?.getTeamMembers as TeamMember[]);
    }
  }, [data, parseTeamMembersResponse]);

  const findOptions = useCallback(() => {
    const options = [...teamMembers, ...suggestions];
    if (deal) {
      options.unshift({ id: "deal_contacts", name: deal.dealType?.clientNoun });
    }
    return options;
  }, [deal, teamMembers, suggestions]);

  useEffect(() => {
    if (inputValue.length < 3) {
      setSuggestions([]);
      return;
    }
    getTeamMemberSuggestions({
      variables: {
        term: inputValue,
      },
    })
      .then((response) => {
        setSuggestions(response.data.getTeamMemberSuggestions);
      })
      .catch((error) => {
        // Ignore errors
      });
  }, [inputValue, getTeamMemberSuggestions]);

  const getValue = useCallback(() => {
    const teamMemberValue =
      findOptions().find((option) => option.id === value) || null;

    if (value && !teamMemberValue) {
      refetch().then((result) => {
        // If value does not match an existing team member, clear the selection
        const teamMembers = parseTeamMembersResponse(
          result.data.getTeamMembers
        );
        const teamMember = teamMembers.find(
          (teamMember: any) => teamMember.id === value
        );
        if (!teamMember) {
          handleChange(null);
        }
      });
    }
    return teamMemberValue || null;
  }, [findOptions, value, refetch, handleChange, parseTeamMembersResponse]);

  const InfoChip = ({ option }: { option: any }) => {
    if (option.id.indexOf("skyslope:") === 0) {
      return (
        <Chip
          sx={{ color: "rgb(63, 91, 119)" }}
          size="small"
          label="Import From SkySlope"
        ></Chip>
      );
    } else if (option.id.indexOf("kvcore:") === 0) {
      return (
        <Chip
          sx={{ color: "rgb(106,180,228)" }}
          size="small"
          label="Import From kvCORE"
        ></Chip>
      );
    }

    return null;
  };

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      onChange={(_: any, value: any) => handleChange(value)}
      options={findOptions()}
      getOptionLabel={(option: any) => option.name || ""}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={getValue()}
      renderOption={(props, option: any) => {
        if (option.id === "deal_contacts" && deal !== undefined) {
          // Name
          let name = deal.dealType?.clientNoun || "Client";
          if (deal.contacts && deal.contacts.length > 1) {
            name += "s";
          }
          let avatarUrls: Array<string | undefined> = [];
          if (deal.contacts) {
            avatarUrls = deal.contacts.map((contact) => contact.avatarUrl);
          }
          return (
            <Box component="li" {...props} key={option.id}>
              <ContactNameWithAvatar name={name} avatarUrls={avatarUrls} />
            </Box>
          );
        }
        return (
          <Box component="li" {...props} key={option.id}>
            <ContactNameWithAvatar
              name={option.name}
              avatarUrls={[option.avatarUrl]}
              avatarStyle={{ width: 32, height: 32 }}
              right={<InfoChip option={option} />}
            />
          </Box>
        );
      }}
      renderInput={(params: any) => (
        <TextField {...params} label={label} variant="standard" />
      )}
      onInputChange={(_, value) => {
        setInputValue(value);
      }}
    ></Autocomplete>
  );
}
