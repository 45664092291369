import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Input,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { User } from "../../models";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { useAlert } from "../../context/alert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    softDeleteSelect: {
      padding: "8px",
      width: "100%",
      marginTop: "12px",
    },
    confirmText: {
      marginTop: "48px",
    },
    confirmInput: {
      padding: "8px",
      width: "100%",
    },
  })
);

type UserDeleteDialogProps = {
  user: User;
  dialogOpen: boolean;
  onClose?: () => void;
  onUserDelete: (softDelete?: boolean) => void;
};

const UserDeleteDialog = ({
  user,
  dialogOpen,
  onClose,
  onUserDelete,
}: UserDeleteDialogProps) => {
  const [deleteType, setDeleteType] = useState<string>("soft");
  const [confirmDeleteInput, setConfirmDeleteInput] = useState<string>("");

  const { showError } = useAlert();
  const classes = useStyles();

  const checkUserDelete = () => {
    if (confirmDeleteInput === user.name && deleteType === "soft") {
      onUserDelete(true);
    } else if (confirmDeleteInput === user.name && deleteType === "hard") {
      onUserDelete();
    } else {
      showError("The text input does not match the prompt");
    }
  };

  return (
    <Grid md={12}>
      <Dialog open={dialogOpen} onClose={onClose}>
        <DialogTitle>{`Delete user ${user.name}?`}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            How do you want to handle {user.name}'s data?
          </Typography>
          <FormControl>
            <RadioGroup
              value={deleteType}
              className={classes.softDeleteSelect}
              onChange={(e) => setDeleteType(e.target.value as string)}
            >
              <FormControlLabel
                value="soft"
                control={<Radio />}
                label="Keep deals, messages, contacts, etc."
              />
              <FormControlLabel
                value="hard"
                control={<Radio />}
                label="Delete all data along with user"
              />
            </RadioGroup>
          </FormControl>
          <Typography variant="body1" className={classes.confirmText}>
            This action cannot be undone. Please type <b>{user.name}</b> below
            to acknowledge you still wish to delete the user:
          </Typography>
          <Input
            value={confirmDeleteInput}
            onChange={(e) => setConfirmDeleteInput(e.target.value)}
            className={classes.confirmInput}
          ></Input>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton
            onClick={checkUserDelete}
            disabled={confirmDeleteInput !== user.name}
          >
            Delete User
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UserDeleteDialog;
