import React from "react";
import { Select, FormControl, Theme, MenuItem } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      width: "100%",
      height: "100%",
      border: "none",

      "&:before": {
        borderBottom: "none",
      },
      "&:hover": {
        cursor: "pointer",
      },
      "&.Mui-underline:hover:before": {
        borderBottom: "none",
      },
    },
    icon: {
      fill: theme.palette.primary.main,
    },
    option: {
      padding: "8px 18px",
      width: "100%",
      height: "100%",
      color: theme.palette.grey[900],
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "14px",
      border: "none",

      "&:before": {
        borderBottom: "none",
      },

      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.grey[300],
      },

      "&.Mui-underline:hover:before": {
        borderBottom: "none",
      },
    },
    value: {
      color: theme.palette.grey[900],
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      display: "flex",
      alignItems: "flex-start",
      fontSize: "22px",
      fontWeight: 600,
    },
  })
);

type TitleSelectProps = {
  label?: string;
  value?: string;
  defaultValue?: string;
  options: { value: string; label: string }[];
  onChange: (value: any) => void;
  className?: string;
};

const TitleSelect = ({
  label,
  value,
  defaultValue,
  options,
  onChange,
  className,
}: TitleSelectProps) => {
  const classes = useStyles();

  const renderSelectedValue = (value: any) => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {label && <div>{label}</div>}
        <div className={classes.value}>
          {options.find((option) => option.value === value)?.label}
        </div>
      </div>
    );
  };

  const handleOptionChange = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <FormControl variant="standard" className={className}>
      <Select
        disableUnderline
        value={value}
        defaultValue={defaultValue}
        className={classes.select}
        renderValue={renderSelectedValue}
        onChange={handleOptionChange}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            className={classes.option}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TitleSelect;
