import React from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldItem: {
      margin: "0 25px",
      paddingBottom: "18px",
    },
    fieldItemLabel: {
      fontSize: "12px",
      fontWeight: 500,
      height: "16px",
      color: theme.palette.grey[500],
    },
    fieldItemValue: {
      fontSize: "14px",
      fontWeight: 500,
    },
  })
);

export default function SubjectData({
  label,
  value,
  className,
}: {
  label?: string;
  value: any;
  className?: string;
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.fieldItem, className)}>
      {label && <div className={classes.fieldItemLabel}>{label}</div>}
      <div className={classes.fieldItemValue}>{value}</div>
    </div>
  );
}
