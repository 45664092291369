import { Mls } from "../models";

export const MLSES = {
  SET: "MLSES/set",
} as const;

export type MlsesState = Mls[];
export type MlsesAction = {
  type: typeof MLSES[keyof typeof MLSES];
  mlses: Mls[];
};

const initialState: Mls[] = [];

const compareMls = (mlsA: Mls, mlsB: Mls) => {
  return mlsA.connected > mlsB.connected ||
    (mlsA.connected === mlsB.connected &&
      mlsA.name.toLowerCase() <= mlsB.name.toLowerCase())
    ? -1
    : 1;
};

const mlsesReducer = (
  state: MlsesState = initialState,
  action: MlsesAction
) => {
  switch (action.type) {
    case MLSES.SET:
      return action.mlses.sort(compareMls);
    default:
      return state;
  }
};

export const findMls =
  ({ mlses }: { mlses: MlsesState }) =>
  (mlsKey: string | null) =>
    mlses.find((mls) => mls.mlsKey === mlsKey) ?? null;

export default mlsesReducer;
