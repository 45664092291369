import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import moment from "moment";
import { List, ListItem, ListItemText, Grid } from "@mui/material";
import "react-day-picker/lib/style.css";
import "./DatePicker.scss";

interface DatePickerProps {
  range: any;
  setDates: any;
}

const DatePicker = ({ range, setDates }: DatePickerProps) => {
  const { from, to } = range;
  const modifiers = { start: from, end: to };

  const handleDayClick = (day: any) => {
    const newRange = DateUtils.addDayToRange(day, range);
    setDates(newRange);
  };

  const dateRangeOptions = [
    {
      text: "Today",
      from: moment().toDate(),
      to: moment().toDate(),
    },
    {
      text: "This week",
      from: moment().startOf("week").toDate(),
      to: moment().endOf("week").toDate(),
    },
    {
      text: "Next week",
      from: moment().add(1, "weeks").startOf("week").toDate(),
      to: moment().add(1, "weeks").endOf("week").toDate(),
    },
    {
      text: "This month",
      from: moment().startOf("month").toDate(),
      to: moment().endOf("month").toDate(),
    },
    {
      text: "This year",
      from: moment().startOf("year").toDate(),
      to: moment().endOf("year").toDate(),
    },
    {
      text: "Last year",
      from: moment().subtract(1, "year").startOf("year").toDate(),
      to: moment().subtract(1, "year").endOf("year").toDate(),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={3}>
        <List>
          {dateRangeOptions.map((option) => {
            return (
              <ListItem
                button
                onClick={() => setDates({ from: option.from, to: option.to })}
              >
                <ListItemText primary={option.text} />
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid item xs={9}>
        <DayPicker
          className="Selectable"
          onDayClick={handleDayClick}
          numberOfMonths={1}
          modifiers={modifiers}
          selectedDays={[from, { from, to }]}
        />
      </Grid>
    </Grid>
  );
};

export default DatePicker;
