import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Box,
  Theme,
  TextField,
  IconButton,
  Link,
  Typography,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Account, Subscription, SubscriptionInvoice } from "../../models";
import clsx from "clsx";
import { CircleCheckIcon, PencilIcon } from "../../icons";
import { gql, useApolloClient } from "@apollo/client";
import { useAlert } from "../../context/alert";
import { Launch } from "@mui/icons-material";
import moment from "moment";

const GET_SUBSCRIPTIONS = gql`
  query getAccount($id: ID!) {
    account(id: $id) {
      subscriptions {
        id
        cancelAt
        cancelAtPeriodEnd
        canceledAt
        collectionMethod
        currentPeriodEnd
        currentPeriodStart
        daysUntilDue
        endedAt
        items {
          id
          planName
          unitPrice
          quantity
          interval
          intervalCount
        }
        startDate
        status
        trialEnd
        trialStart
        lastPaidInvoice {
          id
          amountDue
          dueDate
          billingMethod
        }
        upcomingInvoice {
          amountDue
          dueDate
          billingMethod
          url
          nextPaymentAttempt
        }
        openInvoices {
          id
          amountDue
          dueDate
          billingMethod
          url
          nextPaymentAttempt
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spaceBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    userCount: {
      ".editing &": { paddingTop: "18px" },
    },
    amountDue: {
      textAlign: "right",
    },
  })
);

const AccountBilling = ({
  account,
  setAccount,
}: {
  account: Account;
  setAccount: (account: Account) => void;
}) => {
  const classes = useStyles();
  const apollo = useApolloClient();
  const { showSuccess, showError } = useAlert();

  const [editing, setEditing] = useState({
    stripeCustomerId: false as boolean | string,
  });

  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  useEffect(() => {
    if (account?.id && apollo) {
      setLoading(true);
      apollo
        .query({
          query: GET_SUBSCRIPTIONS,
          variables: { id: account.id },
        })
        .then((response) =>
          setSubscriptions(response.data.account.subscriptions)
        )
        .then(() => setLoading(false))
        .catch((error) => console.log(error));
    }
  }, [account, apollo]);

  const handleEditStripeCustomerId = () => {
    setEditing((editing) => ({
      ...editing,
      stripeCustomerId: account.stripeCustomerId ?? "",
    }));
  };

  const handleStripeCustomerIdChange = ({
    target: { value: customerId },
  }: {
    target: { value: string };
  }) => {
    setEditing((editing) => ({ ...editing, stripeCustomerId: customerId }));
  };

  const handleStripeCustomerIdCommit = () => {
    if (editing.stripeCustomerId === false) {
      return;
    }

    apollo
      .mutate({
        mutation: gql`
          mutation SetStripeCustomerId(
            $accountId: ID!
            $stripeCustomerId: String!
          ) {
            setAccountStripeCustomerId(
              accountId: $accountId
              stripeCustomerId: $stripeCustomerId
            ) {
              id
              stripeCustomerId
            }
          }
        `,
        variables: {
          accountId: account.id,
          stripeCustomerId: editing.stripeCustomerId,
        },
      })
      .then((res) => {
        const { stripeCustomerId } = res.data.setAccountStripeCustomerId;
        setAccount({ ...account, stripeCustomerId });
        setEditing((editing) => ({ ...editing, stripeCustomerId: false }));
        showSuccess("Stripe Customer ID has been updated");
      })
      .catch((error) => {
        showError(error.message);
      });
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const formatAmountDue = (invoice?: SubscriptionInvoice) =>
    invoice?.amountDue
      ? currencyFormatter.format(invoice.amountDue / 100)
      : null;

  const formatDueDate = (invoice?: SubscriptionInvoice) => {
    if (invoice?.dueDate) {
      return `invoice due ${moment(invoice.dueDate).format("MM/DD/YYYY")}`;
    } else if (invoice?.nextPaymentAttempt) {
      return `will charge ${moment(invoice.nextPaymentAttempt).format(
        "MM/DD/YYYY"
      )}`;
    } else {
      return "due ¯\\_(ツ)_/¯";
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Stripe Customer ID</TableCell>
            <TableCell>
              <Box
                className={clsx({
                  [classes.spaceBetween]: true,
                  editing: editing.stripeCustomerId !== false,
                })}
              >
                {editing.stripeCustomerId !== false ? (
                  <>
                    <TextField
                      label="Stripe Customer ID"
                      onChange={handleStripeCustomerIdChange}
                      value={editing.stripeCustomerId}
                      variant="standard"
                    />
                    <IconButton
                      onClick={handleStripeCustomerIdCommit}
                      size="large"
                    >
                      <CircleCheckIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <span className={classes.userCount}>
                      {account.stripeCustomerId ?? "Not set"}
                    </span>
                    <IconButton
                      onClick={handleEditStripeCustomerId}
                      size="small"
                    >
                      <PencilIcon size="1.25rem" />
                    </IconButton>
                  </>
                )}
              </Box>
            </TableCell>
            <TableCell>
              {account.stripeCustomerId && (
                <Link
                  href={`https://dashboard.stripe.com/customers/${account.stripeCustomerId}`}
                  target="_blank"
                >
                  <IconButton size="small">
                    <Launch fontSize="small" />
                  </IconButton>
                </Link>
              )}
            </TableCell>
          </TableRow>
          {loading && (
            <TableRow>
              <TableCell colSpan={2}>Loading...</TableCell>
              <TableCell>
                <CircularProgress size={18} />
              </TableCell>
            </TableRow>
          )}
          {subscriptions.map((subscription) => (
            <>
              <TableRow key={subscription.id}>
                <TableCell>
                  <Typography variant="h6">
                    {subscription.items
                      ?.map((item) => item.planName)
                      .join(", ")}
                  </Typography>
                </TableCell>
                <TableCell>{subscription.status}</TableCell>
                <TableCell>
                  <Link
                    href={`https://dashboard.stripe.com/subscriptions/${subscription.id}`}
                    target="_blank"
                  >
                    <IconButton size="small">
                      <Launch fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
              {subscription.lastPaidInvoice && (
                <TableRow>
                  <TableCell className={classes.amountDue}>
                    {formatAmountDue(subscription.lastPaidInvoice)}
                  </TableCell>
                  <TableCell>paid</TableCell>
                  <TableCell>
                    <Link
                      href={`https://dashboard.stripe.com/invoices/${subscription.lastPaidInvoice.id}`}
                      target="_blank"
                    >
                      <IconButton size="small">
                        <Launch fontSize="small" />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              )}
              {subscription.openInvoices?.map((invoice) => (
                <TableRow key={invoice.id}>
                  <TableCell className={classes.amountDue}>
                    {formatAmountDue(invoice)}
                  </TableCell>
                  <TableCell>{formatDueDate(invoice)}</TableCell>
                  <TableCell>
                    <Link
                      href={`https://dashboard.stripe.com/invoices/${invoice.id}`}
                      target="_blank"
                    >
                      <IconButton size="small">
                        <Launch fontSize="small" />
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
              {subscription.upcomingInvoice && (
                <TableRow>
                  <TableCell className={classes.amountDue}>
                    {formatAmountDue(subscription.upcomingInvoice)}
                  </TableCell>
                  <TableCell>
                    {formatDueDate(subscription.upcomingInvoice)}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountBilling;
