import React from "react";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

export default function SideLayout(props: any) {
  const useStyles = makeStyles(() => ({
    root: {
      height: "100vh",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
    },
  }));
  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        xs={false}
        sm={12}
        md={4}
        className="layout-left"
        style={{
          backgroundImage: `url("splash-screen.svg")`,
        }}
      >
        {props.left}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        className={classes.paper + " layout-right"}
      >
        <Container>{props.right}</Container>
      </Grid>
    </Grid>
  );
}
