import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import DetailCard from "../DetailCard";
import { Box } from "@mui/system";
import { gql, useQuery } from "@apollo/client";

interface GetTaskAggregatesResponse {
  getTaskAggregates: {
    teamMember: {
      id: string;
      name: string;
    };
    overdueTaskCount: number;
    incompleteTaskCount: number;
  }[];
}

const TaskReport = () => {
  const { data } = useQuery<GetTaskAggregatesResponse>(gql`
    query {
      getTaskAggregates {
        teamMember {
          id
          name
        }
        overdueTaskCount
        incompleteTaskCount
      }
    }
  `);

  if (!data) {
    return null;
  }

  return (
    <>
      <DetailCard title={<Typography variant="h4">Current Tasks</Typography>}>
        <Box p={4}>
          <TableContainer>
            <Table>
              <TableHead sx={{ background: "#0b8d9b" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#f5f5f5",
                      borderRight: "1px solid #f5f5f5",
                    }}
                    align="center"
                  >
                    Agent
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#f5f5f5",
                      textAlign: "center",
                      borderRight: "1px solid #f5f5f5",
                    }}
                  >
                    Tasks
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#f5f5f5",
                      textAlign: "center",
                      borderRight: "1px solid #f5f5f5",
                    }}
                  >
                    Overdue Tasks
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.getTaskAggregates?.map((row, index) => (
                  <TableRow key={`teamMemberRow${index}`}>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        background: "#0b8d9b14",
                        fontWeight: "bold",
                        borderColor: "#e0e0e0",
                      }}
                    >
                      <Typography>{row.teamMember.name}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        maxWidth: "200px",
                      }}
                    >
                      {row.incompleteTaskCount}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        maxWidth: "200px",
                      }}
                    >
                      {row.overdueTaskCount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DetailCard>
    </>
  );
};

export default TaskReport;
