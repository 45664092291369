import React, { useState, FunctionComponent, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../buttons";
import {
  useMutation,
  gql,
  useLazyQuery,
  useApolloClient,
} from "@apollo/client";
import { useAlert } from "../../context/alert";
import ActionIconButton from "../ActionIconButton";
import { Close as CloseIcon } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import CreateFromAutocomplete from "./CreateFromAutocomplete";
import { DotloopLoop } from "./useCreateFromDotloop";

const transactionTypes = [
  {
    id: "LISTING_FOR_SALE",
    name: " Listing for Sale",
  },
  {
    id: "LISTING_FOR_LEASE",
    name: "Listing for Lease",
  },
  {
    id: "PURCHASE_OFFER",
    name: "Purchase",
  },
  {
    id: "LEASE_OFFER",
    name: "Lease",
  },

  {
    id: "REAL_ESTATE_OTHER",
    name: "Real Estate Other",
  },
  {
    id: "OTHER",
    name: "Other",
  },
];

const statusTypeMap: { [key: string]: Array<string> } = {
  LISTING_FOR_SALE: [
    "PRE_LISTING",
    "PRIVATE_LISTING",
    "ACTIVE_LISTING",
    "UNDER_CONTRACT",
    "SOLD",
    "ARCHIVED",
  ],
  LISTING_FOR_LEASE: [
    "PRE_LISTING",
    "PRIVATE_LISTING",
    "ACTIVE_LISTING",
    "UNDER_CONTRACT",
    "SOLD",
    "ARCHIVED",
  ],
  PURCHASE_OFFER: ["PRE_OFFER", "UNDER_CONTRACT", "SOLD", "ARCHIVED"],
  LEASE_OFFER: ["PRE_OFFER", "UNDER_CONTRACT", "LEASED", "ARCHIVED"],
  REAL_ESTATE_OTHER: ["NEW", "IN_PROGRESS", "DONE", "ARCHIVED"],
  OTHER: ["NEW", "IN_PROGRESS", "DONE", "ARCHIVED"],
};

const statusNameMap: { [key: string]: string } = {
  NEW: "New",
  PRE_OFFER: "Pre-Offer",
  PRE_LISTING: "Pre-Listing",
  PRIVATE_LISTING: "Private Listing",
  ACTIVE_LISTING: "Active Listing",
  IN_PROGRESS: "In Progress",
  UNDER_CONTRACT: "Under Contract",
  SOLD: "Sold",
  LEASED: "Leased",
  DONE: "Done",
  ARCHIVED: "Archived",
};

const CREATE_DOTLOOP_LOOP = gql`
  mutation CreateDotloopLoop($input: CreateDotloopLoopInput!) {
    createDotloopLoop(input: $input) {
      id
      loopUrl
    }
  }
`;

const LINK_DOTLOOP_LOOP = gql`
  mutation linkDotloopLoop($input: LinkDotloopLoopInput!) {
    linkDotloopLoop(input: $input) {
      id
      loopUrl
    }
  }
`;

const GET_DOTLOOP_PROFILES = gql`
  query GetDotloopProfiles {
    getDotloopProfiles {
      id
      name
      default
    }
  }
`;

const GET_DOTLOOP_TEMPLATES = gql`
  query GetDotloopTemplates($profileId: Int!) {
    getDotloopTemplates(profileId: $profileId) {
      id
      name
      transactionType
    }
  }
`;

type DotloopTemplate = {
  id: number;
  name: string;
  transactionType: string;
};

type DotloopProfile = {
  id: number;
  name: string;
  default: boolean;
};

const DotloopCreateLoopDialog: FunctionComponent<{
  deal?: any;
  open: boolean;
  onClose: () => any;
  onLoopCreated: (
    loop: { id: string; loopUrl: string },
    mode: "create" | "link"
  ) => any;
  onLoopImport?: any;
}> = ({ deal, open, onClose, onLoopCreated, onLoopImport }) => {
  const apollo = useApolloClient();
  const { showSuccess, showError } = useAlert();
  const [mode, setMode] = useState<"create" | "link">(deal ? "create" : "link");
  const [profiles, setProfiles] = useState<Array<DotloopProfile>>([]);

  let defaultTransactionType: string = "";
  if (deal?.dealType) {
    switch (deal?.dealType?.name?.toLowerCase()) {
      case "buying":
        defaultTransactionType = "PURCHASE_OFFER";
        break;
      case "selling":
      case "listings":
        defaultTransactionType = "LISTING_FOR_SALE";
    }
  }

  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState<Array<DotloopTemplate>>([]);
  const [loop, setLoop] = useState<DotloopLoop>();
  const [syncLoop, setSyncLoop] = useState(true);
  const [profileId, setProfileId] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [transactionType, setTransactionType] = useState(
    defaultTransactionType
  );
  const [transactionStatus, setTransactionStatus] = useState("");

  const [integrationType, setIntegrationType] = useState("");

  const [getDotloopProfiles, { data, loading }] =
    useLazyQuery(GET_DOTLOOP_PROFILES);

  const [createDotloopLoop, { loading: creating }] =
    useMutation(CREATE_DOTLOOP_LOOP);

  const [linkDotloopLoop, { loading: linking }] =
    useMutation(LINK_DOTLOOP_LOOP);

  const createFromIntegration = !deal;

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!canSubmit()) {
      return;
    }

    let promise: Promise<any>;
    let input: any = {
      dealId: deal?.id,
      profileId: Number.parseInt(profileId, 10),
    };
    if (onLoopImport) {
      onLoopImport(loop, profileId);
      return onClose();
    } else {
      if (mode === "link") {
        input.loopId = loop?.id;
        input.syncLoop = syncLoop;
        promise = linkDotloopLoop({ variables: { input } }).then(
          (res) => res.data.linkDotloopLoop
        );
      } else {
        input = {
          ...input,
          transactionType,
        };
        if (transactionStatus) {
          input.transactionStatus = transactionStatus;
        }
        if (templateId) {
          input.templateId = Number.parseInt(templateId, 10);
        }
        promise = createDotloopLoop({
          variables: {
            input,
          },
        }).then((res) => res.data.createDotloopLoop);
      }
      promise
        .then((loop) => {
          onLoopCreated(loop, mode);
          showSuccess(
            <>
              <Typography>
                A Loop was {mode === "create" ? "created" : "linked"}
                <Button size="small" href={loop.loopUrl}>
                  View in Dotloop
                </Button>
              </Typography>
            </>
          );
        })
        .catch(() => {
          const verb = mode === "create" ? "creating a" : "linking the";
          showError(`An error occurred when ${verb} Loop`);
        })
        .finally(() => {
          handleClose();
        });
    }
  };

  const handleClose = () => {
    onClose();
  };

  const canSubmit = () => {
    if (creating || linking) {
      return false;
    } else if (mode === "create") {
      return profileId !== "" && transactionType !== "";
    } else if (mode === "link") {
      return loop != null;
    }
    return false;
  };

  useEffect(() => {
    if (!profileId) {
      return;
    }
    setLoadingTemplates(true);
    apollo
      .query({
        query: GET_DOTLOOP_TEMPLATES,
        variables: {
          profileId: Number.parseInt(profileId, 10),
        },
      })
      .then((res) => {
        setTemplates(
          res.data.getDotloopTemplates.filter(
            (template: DotloopTemplate) =>
              template.transactionType === transactionType
          )
        );
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  }, [profileId, transactionType, apollo]);

  useEffect(() => {
    if (open) {
      // If the dialog is opened, fetch the Dotloop profiles
      getDotloopProfiles();
    }
  }, [open, getDotloopProfiles]);

  useEffect(() => {
    if (data?.getDotloopProfiles) {
      setProfiles(data.getDotloopProfiles);
      const defaultProfile = data?.getDotloopProfiles.find(
        (profile: any) => profile.default
      );
      if (defaultProfile) {
        setProfileId(defaultProfile.id);
      }
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">
            {mode === "create" ? "Create" : "Link"} a Loop
          </Typography>
          <ActionIconButton
            icon={CloseIcon}
            style={{ margin: "4px -5px" }}
            buttonStyle={{ width: "22px", height: "22px" }}
            onClick={handleClose}
            aria-label="close"
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <Box textAlign="center" mb={2}>
            <CircularProgress size="24px" />
          </Box>
        )}
        {!loading && (
          <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
            <Grid item xs={12}>
              {createFromIntegration && (
                <FormControl component="fieldset" variant="standard">
                  {/* <FormLab>Create from integration</FormLab> */}
                  <RadioGroup
                    aria-label="createFromIntegration"
                    name="createFromIntegration"
                    value={integrationType}
                    onChange={(e, value: string) => {
                      setIntegrationType(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="dotLoop"
                      control={<Radio />}
                      label="Dotloop Loop"
                    />
                    <FormControlLabel
                      value="skyslope"
                      control={<Radio />}
                      label="Skyslope Sale"
                    />
                    <FormControlLabel
                      value="docusign"
                      control={<Radio />}
                      label="Docusign Room"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="dotloopProfile">Profile</InputLabel>
                <Select
                  native
                  label="Profile"
                  inputProps={{ id: "dotloopProfile" }}
                  value={profileId}
                  onChange={(e) => {
                    setProfileId(e.target.value as string);
                  }}
                >
                  {profiles.map((profile) => (
                    <option key={profile.id} value={profile.id}>
                      {profile.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {mode === "link" && (
              <>
                <Grid item xs={12}>
                  <CreateFromAutocomplete
                    profileId={profileId}
                    onSelected={(loop) => setLoop(loop)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={syncLoop}
                        onChange={(e, checked) => setSyncLoop(checked)}
                        name="syncLoop"
                        color="primary"
                      />
                    }
                    label="Sync loop details into this deal"
                  />
                </Grid>
              </>
            )}
            {mode === "create" && (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="dotloopTransactionType">
                      Transaction Type
                    </InputLabel>
                    <Select
                      label="Transaction Type"
                      native
                      inputProps={{ id: "dotloopTransactionType" }}
                      value={transactionType}
                      onChange={(e) => {
                        setTransactionType(e.target.value as string);
                      }}
                    >
                      <option></option>
                      {transactionTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {templates?.length > 0 && (
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="dotloopTemplate">
                        Template
                      </InputLabel>
                      {loadingTemplates ? (
                        <Skeleton variant="rectangular" />
                      ) : (
                        <Select
                          label="Template"
                          native
                          inputProps={{ id: "dotloopTemplate" }}
                          value={templateId}
                          onChange={(e) => {
                            setTemplateId(e.target.value as string);
                          }}
                        >
                          <option></option>
                          {templates.map((template) => (
                            <option key={template.id} value={template.id}>
                              {template.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="dotloopStatus">Status</InputLabel>
                    <Select
                      label="Status"
                      native
                      inputProps={{ id: "dotloopStatus" }}
                      value={transactionStatus}
                      onChange={(e) => {
                        setTransactionStatus(e.target.value as string);
                      }}
                    >
                      <option></option>
                      {statusTypeMap[transactionType]?.map((status: string) => (
                        <option key={status} value={status}>
                          {statusNameMap[status]}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                style={{ fontSize: 12, textAlign: "center" }}
              >
                {mode === "create" && (
                  <>
                    Already have a loop created for this deal?{" "}
                    <Button
                      size="small"
                      color="primary"
                      style={{ textTransform: "none" }}
                      onClick={() => setMode("link")}
                    >
                      Link an existing loop
                    </Button>
                  </>
                )}
                {mode === "link" && (
                  <>
                    Can't find a loop for this deal?{" "}
                    <Button
                      size="small"
                      color="primary"
                      style={{ textTransform: "none" }}
                      onClick={() => setMode("create")}
                    >
                      Create a new loop
                    </Button>
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          fullWidth
          onClick={handleClose}
          disabled={linking || creating}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={!canSubmit()}
          onClick={handleSubmit}
        >
          {mode === "create" ? "Create" : "Link"} a Loop
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
export default DotloopCreateLoopDialog;
