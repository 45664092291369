import axios from "axios";
import React, { useState } from "react";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import { Theme, Chip, Snackbar, CircularProgress, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useApolloClient } from "@apollo/client";
import { gql } from "@apollo/client";
import Alert from "@mui/material/Alert";
import { useDropzone } from "react-dropzone";
import "./Attachments.scss";

const GET_SIGNED_UPLOAD_URL = gql`
  query GetSignedUploadAttachmentUrl($input: GetSignedUploadUrlInput!) {
    getSignedUploadAttachmentUrl(input: $input) {
      uploadUrl
      objectKey
    }
  }
`;

const DELETE_ATTACHMENT = gql`
  mutation DeleteDraftAttachment($objectKey: String!) {
    deleteDraftAttachment(objectKey: $objectKey)
  }
`;

export type Attachment = {
  id: string;
  fileName: string;
  objectKey?: string;
  loading: boolean;
  dealDocumentId?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentsList: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    attachment: { margin: "4px" },
  })
);

type AttachmentsProps = {
  attachments: Attachment[];
  onAttachmentAdded: (attachment: Attachment) => void;
  onAttachmentDeleted: (id: string) => void;
  onAttachmentUpdated: (id: string, attachment: Attachment) => void;
};

const Attachments = ({
  attachments,
  onAttachmentAdded,
  onAttachmentDeleted,
  onAttachmentUpdated,
}: AttachmentsProps) => {
  const apollo = useApolloClient();
  const classes = useStyles();
  const [error, setError] = useState<string | undefined>();

  const uploadAttachment = (file: File) => {
    if (!file) {
      // No file selected
      return;
    }

    if (file.size > 10000000) {
      return window.alert("Too big");
    }

    const attachmentId = `${file.name}-${new Date().getTime()}`;
    onAttachmentAdded({
      id: attachmentId,
      fileName: file.name,
      loading: true,
    });

    const params = {
      fileName: file.name,
      fileType: file.type,
    };
    apollo
      .mutate({
        mutation: GET_SIGNED_UPLOAD_URL,
        variables: {
          input: params,
        },
      })
      .then((response) => {
        const uploadUrl = response.data.getSignedUploadAttachmentUrl.uploadUrl;
        const objectKey = response.data.getSignedUploadAttachmentUrl.objectKey;
        axios
          .put(uploadUrl, file, { headers: { "Content-Type": file.type } })
          .then(() => {
            onAttachmentUpdated(attachmentId, {
              id: attachmentId,
              fileName: file.name,
              objectKey: objectKey,
              loading: false,
            });
          })
          .catch((error) => {
            console.error(error);
            onAttachmentDeleted(attachmentId);
            setError("There was a problem uploading the attachment");
          });
      })
      .catch((error) => {
        console.error(error);
        setError("There was a problem uploading the attachment");
      });
  };

  const handleDeleteAttachment = (attachment: Attachment) => {
    if (attachment.loading) return;

    onAttachmentUpdated(attachment.id, {
      ...attachment,
      loading: true,
    });

    if (attachment.objectKey) {
      apollo
        .mutate({
          mutation: DELETE_ATTACHMENT,
          variables: {
            objectKey: attachment.objectKey,
          },
        })
        .then((response) => {
          if (response.data.deleteDraftAttachment) {
            onAttachmentDeleted(attachment.id);
          } else {
            setError("There was a problem deleting the attachment");
          }
        })
        .catch((error) => {
          console.error(error);
          onAttachmentUpdated(attachment.id, {
            ...attachment,
            loading: false,
          });
          setError("There was a problem deleting the attachment");
        });
    } else {
      onAttachmentDeleted(attachment.id);
    }
  };

  const renderAttachment = (attachment: Attachment) => {
    return (
      <Chip
        label={attachment.fileName}
        key={attachment.id}
        onDelete={() => handleDeleteAttachment(attachment)}
        deleteIcon={
          attachment.loading ? <CircularProgress size={"16px"} /> : undefined
        }
        className={classes.attachment}
      />
    );
  };

  const onDrop = (files: File[]) => files.forEach(uploadAttachment);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={10000}
        open={!!error}
        onClose={(e) => {
          setError(undefined);
        }}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            {...getRootProps()}
            className={`attachments-dnd ${isDragActive && "active"}`}
          >
            <input {...getInputProps()}></input>
            <AttachFileIcon />
            Attach files
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.attachmentsList}>
            {attachments.map((attachment) => renderAttachment(attachment))}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Attachments;
