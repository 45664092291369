import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { SecondaryButton, PrimaryButton } from "./buttons";
import StageForm from "./stages/StageForm";
import { cleanCustomFields, cleanDateValues } from "../helpers/fields";

type EditDealTypeModalProps = {
  open: boolean;
  deal: any;
  dealTypes: any;
  updateDeal(formUpdates: any): Promise<any>;
  onClose(): void | undefined;
  onSave(dealTeamMember: any): void | undefined;
};

const EditDealTypeModal: FunctionComponent<EditDealTypeModalProps> = ({
  open,
  deal,
  dealTypes,
  updateDeal,
  onClose,
  onSave,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [dealType, setDealType] = useState<any>();
  const [stage, setStage] = useState<any>();
  const [showStageFields, setShowStageFields] = useState<boolean>(false);
  const [stageFormData, setStageFormData] = useState<any>({ customFields: [] });

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    setSubmitting(true);
    let formData = cleanDateValues(stageFormData);
    formData = cleanCustomFields(formData);
    const formUpdates: any = {
      id: deal.id,
      stageId: stage.id,
      ...formData,
    };
    updateDeal(formUpdates);
    setSubmitting(false);
    handleClose();
  };

  const handleDealTypeChange = (e: SelectChangeEvent<any>) => {
    let showStageFields = false;
    const dealType = dealTypes.find((dt: any) => dt.id === e.target.value);
    if (dealType?.id !== deal.dealType.id) {
      setStage(dealType.stages[0]);
    } else {
      let stage = dealType.stages.find((s: any) => s.id === deal.stage.id);
      if (!stage) {
        stage = dealType.stages[0];
      }
      setStage(stage);
    }
    setDealType(dealType);
    setShowStageFields(showStageFields);
  };

  const handleStageChange = (e: SelectChangeEvent<any>) => {
    setStage(dealType.stages.find((s: any) => s.id === e.target.value));
  };

  useEffect(() => {
    if (!deal || !dealTypes) {
      return;
    }
    const initialDealType = dealTypes.find(
      (dealType: any) => dealType.id === deal.dealType.id
    );
    const initialStage = initialDealType.stages.find(
      (stage: any) => stage.id === deal.stage?.id
    );
    setDealType(initialDealType);
    setStage(initialStage ?? initialDealType.stages[0]);
  }, [deal, dealTypes]);

  if (!deal || !dealTypes) {
    return <></>;
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle style={{ minWidth: "368px" }}>Change Deal Type</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="select-deal-type">Deal Type</InputLabel>
              <Select
                variant="standard"
                label="Deal Type"
                native
                required
                inputProps={{ id: "select-deal-type" }}
                value={dealType?.id ?? ""}
                onChange={handleDealTypeChange}
              >
                {dealTypes.map((dealType: any) => (
                  <option key={dealType.id} value={dealType.id}>
                    {dealType.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }} variant="standard">
              <InputLabel htmlFor="select-stage">Stage</InputLabel>
              <Select
                fullWidth
                label="Stage"
                variant="standard"
                native
                required
                inputProps={{ id: "select-stage" }}
                value={stage?.id ?? ""}
                onChange={handleStageChange}
              >
                {dealType?.stages.map((stage: any) => (
                  <option key={stage.id} value={stage.id}>
                    {stage.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {stage && showStageFields && (
            <Grid item xs={12}>
              <StageForm
                stage={stage}
                deal={deal}
                onChange={setStageFormData}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={handleClose}
          color="primary"
          style={{ width: "100px" }}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={handleSave}
          color="primary"
          style={{ width: "100px" }}
          disabled={submitting || !stage || !dealType}
        >
          Save
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditDealTypeModal;
