import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { gql, useApolloClient } from "@apollo/client";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Grid, Chip, IconButton, Typography, Input } from "@mui/material";
import AdminToolbar from "../../components/admin/AdminToolbar";
import {
  ToggleButton,
  ToggleButtonGroup,
} from "../../components/ToggleButtons";
import { ArrowBack } from "@mui/icons-material";
import { CircleCheckIcon, PencilIcon } from "../../icons";
import { useAlert } from "../../context/alert";
import UserActivityChart from "../../components/admin/UserActivityChart";
import { Account } from "../../models";
import AccountBilling from "../../components/admin/AccountBilling";
import AccountInfo from "../../components/admin/AccountInfo";
import AccountIntegrations from "../../components/admin/AccountIntegrations";
import AccountFeatures from "../../components/admin/AccountFeatures";
import AccountUsersList from "../../components/admin/AccountUsersList";

const GET_ACCOUNT = gql`
  query getAccount($id: ID!) {
    account(id: $id) {
      id
      name
      trialEndDate
      trialEnded
      updatedAt
      createdAt
      hubspotId
      lastActiveDate
      userLimit
      users {
        totalCount
      }
      availableIntegrations {
        name
        id
        enabled
        added
      }
      features {
        key
        name
        enabled
        enabledAt
        disabledAt
      }
      roles {
        id
        name
      }
      stripeCustomerId
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: "1440px",
      margin: "0 auto",
      fontFamily: ["AvenirNext-Regular", "Avenir", "sans-serif"].join(","),
    },
    h4: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    gridContainer: {
      padding: "100px 25px 0",
    },
    accountName: {
      fontSize: "32px",
      fontFamily: theme.typography.h2.fontFamily,
      fontWeight: theme.typography.h2.fontWeight,
      lineHeight: theme.typography.h2.lineHeight,
      marginRight: theme.spacing(2),
      display: "flex",
      "& button": {
        marginLeft: theme.spacing(2),
      },
    },
    trialEndedChip: {
      backgroundColor: "#FFB000",
    },
    organizationInfo: {
      padding: 0,
      listStyleType: "none",
      "& li": {
        marginBottom: "10px",
        "& span": {
          fontWeight: 700,
        },
      },
    },
    spaceBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    userCount: {
      ".editing &": { paddingTop: "18px" },
    },
  })
);

const AccountDetail = () => {
  const classes = useStyles();
  const apollo = useApolloClient();
  const { showSuccess, showError } = useAlert();

  const { id } = useParams<{ id: string }>();
  const [view, setView] = useState<"users" | "activity">("users");
  const [account, setAccount] = useState<Account>({
    id: "",
    name: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    hubspotId: "",
    availableIntegrations: [],
    features: [],
    roles: [],
    lastActiveDate: null,
    trialEndDate: null,
    trialEnded: false,
    userLimit: null,
    users: { totalCount: 0 },
    stripeCustomerId: null,
    subscriptions: [],
  });

  const [editing, setEditing] = useState({
    trial: false,
    userLimit: false as false | null | number,
    accountName: false as false | string,
  });

  useEffect(() => {
    apollo
      .query({
        query: GET_ACCOUNT,
        variables: { id: id },
      })
      .then((response) => {
        setAccount(response.data.account);
      })
      .catch((error) => console.log(error));
  }, [id, apollo]);

  const handleEditAccountName = () => {
    setEditing((editing) => ({ ...editing, accountName: account.name }));
  };

  const handleAccountNameChange = ({
    target: { value: accountName },
  }: {
    target: { value: string };
  }) => {
    setEditing((editing) => ({ ...editing, accountName }));
  };

  const handleAccountNameCommit = () => {
    if (editing.accountName === false) {
      return;
    }

    apollo
      .mutate({
        mutation: gql`
          mutation SetAccountName($accountId: ID!, $name: String!) {
            setAccountName(accountId: $accountId, name: $name) {
              id
              name
            }
          }
        `,
        variables: {
          accountId: account.id,
          name: editing.accountName,
        },
      })
      .then((res) => {
        const { name } = res.data.setAccountName;
        setAccount({ ...account, name });
        setEditing((editing) => ({ ...editing, accountName: false }));
        showSuccess("Name has been updated");
      })
      .catch(() => {
        showError("An error occurred when updating the account name");
      });
  };

  return (
    <div className={classes.root}>
      <AdminToolbar />
      <Grid container className={classes.gridContainer} spacing={4}>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <IconButton component={Link} to="/admin" size="large">
            <ArrowBack />
          </IconButton>

          {editing.accountName ? (
            <>
              <Input
                value={editing.accountName}
                className={classes.accountName}
                onChange={handleAccountNameChange}
                onKeyDown={({ key }: any) => {
                  key === "Enter" && handleAccountNameCommit();
                }}
              />
              <IconButton onClick={handleAccountNameCommit} size="large">
                <CircleCheckIcon />
              </IconButton>
            </>
          ) : (
            <Typography className={classes.accountName} variant="h2">
              {account.name}
              <IconButton onClick={handleEditAccountName} size="small">
                <PencilIcon size="1.25rem" />
              </IconButton>
            </Typography>
          )}

          {account.trialEnded && (
            <Chip
              size="small"
              label="Trial Ended"
              className={classes.trialEndedChip}
            ></Chip>
          )}

          <div style={{ flexGrow: 1 }} />

          <ToggleButtonGroup
            exclusive
            buttonWidth="100px"
            onChange={(event: any, view: "users" | "activity") => setView(view)}
            value={view}
          >
            <ToggleButton value={"users"}>Users</ToggleButton>
            <ToggleButton value={"activity"}>Activity</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} lg={4}>
          <AccountInfo account={account} setAccount={setAccount} />

          <Typography variant="h4" className={classes.h4}>
            Billing
          </Typography>
          <AccountBilling account={account} setAccount={setAccount} />

          <Typography variant="h4" className={classes.h4}>
            Available Integrations
          </Typography>
          <AccountIntegrations account={account} />

          <Typography variant="h4" className={classes.h4}>
            Features
          </Typography>
          <AccountFeatures account={account} setAccount={setAccount} />
        </Grid>
        <Grid item xs={12} lg={8}>
          {view === "users" ? (
            <AccountUsersList accountId={account.id} />
          ) : (
            <UserActivityChart accountId={account.id} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountDetail;
