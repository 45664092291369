import React, { useState, FunctionComponent } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { List, ListItem, ListItemText, Collapse, Divider } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { screenLessThan } from "../helpers";

type ReportsSubNavProps = {
  version?: 1 | 2;
  reports: Array<{ id: string; name: string; category: string }>;
};
const ReportsSubNav: FunctionComponent<ReportsSubNavProps> = ({
  reports,
  version = 1,
}): JSX.Element => {
  const location = useLocation();
  const [categories, setCategories] = useState(
    Array.from(new Set(reports.map((report) => report.category))).map(
      (name, i) => ({
        name,
        open: i === 0,
        reports: reports.filter((report) => report.category === name),
      })
    )
  );

  const isSelected = (report: any) => {
    if (location.pathname === "/reports" && report.name === "Active") {
      return true;
    } else if (location.pathname === `/reports/${report.id}`) {
      return true;
    } else {
      return false;
    }
  };

  const handleCategoryToggle = (category: any) => {
    setCategories((categories) =>
      categories.map((c) => {
        if (c.name === category.name) {
          return { ...c, open: !c.open };
        } else {
          return c;
        }
      })
    );
  };

  return (
    <List disablePadding={screenLessThan(960)}>
      {categories.map((category, i) => (
        <React.Fragment key={i}>
          <ListItem onClick={() => handleCategoryToggle(category)}>
            <ListItemText>
              <h3>{category.name}</h3>
            </ListItemText>
            {category.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={category.open} timeout="auto" unmountOnExit>
            <List>
              {category.reports.map((report) => (
                <ListItem
                  key={report.id}
                  button
                  component={RouterLink}
                  to={`/reports/${report.id}`}
                  selected={isSelected(report)}
                >
                  <ListItemText primary={report.name} />
                </ListItem>
              ))}
            </List>
          </Collapse>
          <Divider light />
        </React.Fragment>
      ))}
    </List>
  );
};

export default ReportsSubNav;
