import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add, Close, PhoneAndroid } from "@mui/icons-material";
import { TimerIcon, EmailIcon, TaskIcon } from "../../icons";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Feature } from "../../reducers/features";

type AddStepButtonProps = {
  open: boolean;
  end?: boolean;
  features: any;
  enabled: boolean;
  onToggle: () => void;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: "delay" | "email" | "task" | "sms"
  ) => void;
};

const useStyles = makeStyles((theme) => ({
  stick: {
    backgroundColor: "#6b7280",
    height: "22px",
    left: "50%",
    marginRight: "-1px",
    position: "relative",
    width: "1px",
    zIndex: 0,
  },
  circleWrapper: {
    position: "relative",
    textAlign: "center",
  },
  circle: {
    display: "inline-block",
    background: "#6b7280",
    color: "#fff",
    borderRadius: "50%",
    position: "relative",
    zIndex: 2,
  },
  iconButton: {
    padding: "0",
    color: "#fff",
    "&:hover": {
      //backgroundColor: theme.palette.primary.main,
    },
  },
  arrowUp: {
    position: "relative",
    background: "#fff",
    marginTop: "40px",
    "&:after": {
      content: "ff",
      position: "absolute",
      right: "calc(50% - 10px)",
      top: "-15px",
      borderTop: "none",
      borderRight: "15px solid transparent",
      borderLeft: "15px solid transparent",
      borderBottom: "15px solid black",
    },
  },
  addAStepCta: {
    fontSize: "16px",
    fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
    marginBottom: "11px",
  },
  button: {
    border: "1px solid #e5e7eb",
    borderRadius: "3px",
    padding: "21px",
    marginRight: "11px",
    width: "96px",
    height: "104px",
  },
  buttonLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
    textTransform: "capitalize",
  },
  iconWrapper: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "11px",
    color: "#fff",
  },
}));

const AddStepButton: FunctionComponent<AddStepButtonProps> = ({
  open,
  end,
  onToggle,
  onClick,
  features,
  enabled,
}) => {
  const classes = useStyles();
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      {enabled && <div className={classes.stick} />}
      {enabled && (
        <div className={classes.circleWrapper}>
          <div className={classes.circle}>
            <IconButton
              size="small"
              className={classes.iconButton}
              onClick={onToggle}
            >
              {!open && <Add />}
              {open && <Close />}
            </IconButton>
          </div>
        </div>
      )}
      {!end && <div className={classes.stick} />}
      {open && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Paper>
            <Box p={2}>
              <Typography component="h5" className={classes.addAStepCta}>
                Add a step to your workflow
              </Typography>

              <Button
                onClick={(e) => onClick(e, "delay")}
                className={classes.button}
              >
                <span className={classes.buttonLabel}>
                  <span
                    className={classes.iconWrapper}
                    style={{ background: "#0B8D9B" }}
                  >
                    <TimerIcon />
                  </span>
                  Delay
                </span>
              </Button>

              <Button
                onClick={(e) => onClick(e, "email")}
                className={classes.button}
              >
                <span className={classes.buttonLabel}>
                  <span
                    className={classes.iconWrapper}
                    style={{ background: "#FF576D" }}
                  >
                    <EmailIcon />
                  </span>
                  Email
                </span>
              </Button>

              {features["Text Messaging"] && (
                <Button
                  onClick={(e) => onClick(e, "sms")}
                  className={classes.button}
                >
                  <span className={classes.buttonLabel}>
                    <span
                      className={classes.iconWrapper}
                      style={{ background: "#5759FF" }}
                    >
                      <PhoneAndroid />
                    </span>
                    SMS
                  </span>
                </Button>
              )}

              <Button
                onClick={(e) => onClick(e, "task")}
                className={classes.button}
              >
                <span className={classes.buttonLabel}>
                  <span
                    className={classes.iconWrapper}
                    style={{ background: "#351665" }}
                  >
                    <TaskIcon />
                  </span>
                  Task
                </span>
              </Button>
            </Box>
          </Paper>
        </div>
      )}
      {open && !end && <div className={classes.stick} />}
    </div>
  );
};

const mapStateToProps = ({ features }: { [key: string]: Feature }) => {
  return { features };
};
export default connect(mapStateToProps)(AddStepButton);
