import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AvatarGroup } from "@mui/material";

type DealContactNameWithAvatarProps = {
  contacts: [];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

const DealContactNameWithAvatar = ({
  contacts,
}: DealContactNameWithAvatarProps) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleAvatarClick = (contact: any) => {
    navigate(`/contacts/${contact.id}`);
  };

  return (
    <div className={classes.wrapper}>
      <AvatarGroup style={{ marginRight: "20px" }} max={4}>
        {contacts.map((contact: any) => {
          return (
            <Avatar
              key={contact.id}
              alt={contact.name}
              title={contact.name}
              src={contact.avatarUrl}
              onClick={() => handleAvatarClick(contact)}
              className={classes.avatar}
            />
          );
        })}
      </AvatarGroup>
      <span>
        {contacts.length > 1
          ? contacts.map((contact: any) => contact.firstName).join(" & ")
          : contacts.map((contact: any) => contact.name)}
      </span>
    </div>
  );
};

export default DealContactNameWithAvatar;
