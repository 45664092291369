import React from "react";
import { Box } from "@mui/material";
import ActionIconButton from "../ActionIconButton";
import { Save } from "@mui/icons-material";

export default function SaveButton({
  onClick = () => {},
  style,
}: {
  onClick: Function;
  style?: any;
}) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        ...style,
      }}
    >
      <ActionIconButton
        buttonStyle={{ padding: "0" }}
        onClick={() => onClick()}
        icon={Save}
      />
    </Box>
  );
}
