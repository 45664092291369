import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Grid, TextField, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useApi } from "../context/api";
import { Mls } from "../models";
import MlsSelect from "./MlsSelect";
import { PrimaryButton } from "./buttons";
import { connect } from "react-redux";
import { findMls } from "../reducers/mlses";
import { pullMlsListing } from "../api/mls";

const useStyles = makeStyles((theme) =>
  createStyles({
    mlsCopyContainer: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
  })
);

export type MlsListingData = {
  mlsKey?: string | null;
  mlsId?: string;
  address?: {
    street?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  listingExpirationDate?: Date;
  contractAgreementDate?: Date;
  listPrice?: number;
  salePrice?: number;
  sellerConcession?: number;
  closedDate?: Date;
};

enum LoadingState {
  notLoading,
  loading,
  success,
  notFound,
  failure,
}

type MlsInputProps = {
  onChange?: (data: MlsListingData) => void;
  mlsKey?: string | null;
  mlsId?: string;
  findMls: (mlsKey: string | null) => Mls | null;
};

const MlsInput = ({
  onChange = () => {},
  mlsKey = null,
  mlsId = "",
  findMls,
}: MlsInputProps) => {
  const classes = useStyles();
  const { apolloClient } = useApi();
  const [initialStateLoaded, setInitialStateLoaded] = useState(false);
  const [mls, setMls] = useState<Mls | null>(null);
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.notLoading
  );

  function onPullMlsListing() {
    if (!mls) return;

    setLoadingState(LoadingState.loading);
    pullMlsListing(apolloClient, mls.mlsKey, mlsId)
      .then((listing) => {
        if (listing) {
          onChange(listing);
          setLoadingState(LoadingState.success);
        } else {
          setLoadingState(LoadingState.notFound);
        }
      })
      .catch((err) => {
        console.error("Trouble getting MLS data", err);
        setLoadingState(LoadingState.failure);
      });
  }

  useEffect(() => {
    setMls(findMls(mlsKey));
  }, [mlsKey, findMls]);

  const LAST_SELECTED_MLS_KEY = "lastSelectedMls";
  const mlsSelected = useCallback(
    (mlsKey: string | null) => {
      setMls(findMls(mlsKey));
      onChange({ mlsKey });
      if (mlsKey) {
        window.localStorage.setItem(LAST_SELECTED_MLS_KEY, mlsKey);
      }
    },
    [onChange, findMls]
  );

  useEffect(() => {
    if (initialStateLoaded) return;
    if (mlsKey) {
      setInitialStateLoaded(true);
      return;
    }

    const lastSelectedMlsKey = window.localStorage.getItem(
      LAST_SELECTED_MLS_KEY
    );

    const lastSelectedMls = findMls(lastSelectedMlsKey);

    mlsSelected(lastSelectedMls?.mlsKey ?? null);
    setInitialStateLoaded(true);
  }, [initialStateLoaded, findMls, mlsKey, mlsSelected]);

  const mlsIdChanged = useCallback(
    (mlsId: string) => {
      onChange({ mlsId });
    },
    [onChange]
  );

  return (
    <>
      <Grid item xs={12} sm={7} lg={8}>
        <MlsSelect
          onChange={mlsSelected}
          style={{ flexGrow: 10, width: "auto" }}
          value={mls?.mlsKey}
        />
      </Grid>

      <Grid item xs={12} sm={5} lg={4}>
        <TextField
          variant="standard"
          label="Listing ID"
          value={mlsId}
          style={{ width: "100%" }}
          onChange={(event) => mlsIdChanged(event.target.value)}
        />
      </Grid>

      <Grid item xs={12} sm={7} lg={8}>
        <div className={classes.mlsCopyContainer}>
          <Typography variant="body1">
            {mls?.connected ? (
              <>
                You can pull information from this MLS to fill in details on the
                deal
              </>
            ) : mls?.supported ? (
              <>
                Contact us at&nbsp;
                <a
                  href="mailto:support@shaker.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@shaker.io
                </a>
                &nbsp;to connect this MLS to your account.
              </>
            ) : mls ? (
              <>
                You can save the MLS and Listing ID to this deal, however Shaker
                does not currently support pulling info from this MLS.
                <br />
                <br />
                Contact us at&nbsp;
                <a
                  href="mailto:support@shaker.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@shaker.io
                </a>
                &nbsp;if you are interested in adding support for {mls.name}.
              </>
            ) : (
              <>
                Contact us at&nbsp;
                <a
                  href="mailto:support@shaker.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@shaker.io
                </a>
                &nbsp;if you are interested in adding support for an MLS not
                listed here.
              </>
            )}
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} sm={5} lg={4}>
        {loadingState === LoadingState.loading ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <CircularProgress size="36px" />
          </div>
        ) : (
          <PrimaryButton
            disabled={!(mls?.connected && mlsId?.length)}
            onClick={onPullMlsListing}
            style={{ width: "100%", marginBottom: "8px" }}
          >
            Pull now
          </PrimaryButton>
        )}
        {loadingState === LoadingState.notFound ? (
          <Typography variant="body2" color="error">
            We couldn't find that listing. Is the Listing ID correct?
          </Typography>
        ) : loadingState === LoadingState.failure ? (
          <Typography variant="body2" color="error">
            Sorry, we had some trouble finding your listing. You can try again
            later.
          </Typography>
        ) : loadingState === LoadingState.success ? (
          <Typography variant="body2" color="textSecondary">
            We found your listing! We filled in some details on the deal.
          </Typography>
        ) : loadingState === LoadingState.loading ? (
          <Typography variant="body2" color="textSecondary">
            Give us a second - we're looking for your listing...
          </Typography>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = ({ mlses }: { mlses: Mls[] }) => {
  return { findMls: findMls({ mlses }) };
};
export default connect(mapStateToProps)(MlsInput);
