import React, {
  FunctionComponent,
  ChangeEvent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { gql } from "@apollo/client";
import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Chip,
  Avatar,
  Theme,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useMutation } from "@apollo/client";
import CustomField from "../fields/CustomField";
import AddressField from "../fields/Address";
import DateTimeField from "../fields/DateTimeField";
import FieldHelper from "../../helpers/fields";
import { useApolloClient } from "@apollo/client";
import { GET_CUSTOM_FIELDS } from "../../api/graphql";
import TeamMemberSelect from "../TeamMemberSelect";
import { connect } from "react-redux";
import PhoneField from "../fields/PhoneField";
import EmailField from "../fields/EmailField";
import TagAutocomplete from "./TagAutocomplete";
import ContactSelect from "../ContactSelect";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { RightSideDrawer } from "../RightSideDrawer";
import { cleanFields } from "../../helpers/fields";
import { useAlert } from "../../context/alert";
import { CloseRounded } from "@mui/icons-material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    socialProfileContainer: {
      flexWrap: "inherit",
    },
    socialTextField: {
      flexGrow: 1,
    },
    url: {
      marginRight: "1px",
    },
    socialIcon: {
      marginRight: "3px",
      color: "gray",
    },
  })
);

type ContactUpdateFormProps = {
  open: boolean;
  contact: any;
  onUpdate?: () => any;
  onClose: () => any;
};

const ContactUpdateForm: FunctionComponent<ContactUpdateFormProps> = ({
  open,
  contact,
  onUpdate,
  onClose,
}) => {
  const classes = useStyles();
  const { showSuccess, showError } = useAlert();
  const apollo = useApolloClient();
  const [updateContact, { loading: saving }] = useMutation(gql`
    mutation UpdateContact($input: UpdateContactInput!) {
      updateContact(input: $input) {
        id
      }
    }
  `);
  const makeInitialFormState = useCallback(() => {
    const state: any = {
      id: contact.id,
      customFields: [],
      ownerId: contact.owner?.id || "",
      tags: contact.tags,
    };
    const systemFields = [
      "id",
      "email",
      "firstName",
      "lastName",
      "phone",
      "birthday",
      "homeAnniversaryDate",
      "linkedContact",
      "leadSource",
    ];
    systemFields.forEach((key: string) => {
      state[key] = contact[key];
    });

    state["address"] = {
      street: contact.address?.street || "",
      street2: contact.address?.street2 || "",
      city: contact.address?.city || "",
      state: contact.address?.state || "",
      zip: contact.address?.zip || "",
    };

    state["socialProfiles"] = {
      linkedIn: contact.socialProfiles.linkedIn,
      twitter: contact.socialProfiles.twitter,
      instagram: contact.socialProfiles.instagram,
      facebook: contact.socialProfiles.facebook,
    };

    // Being explicit here, the time picker needs a null value,
    // rather than undefined.
    if (state["birthday"] === undefined) {
      state["birthday"] = null;
    }

    if (state["homeAnniversaryDate"] === undefined) {
      state["homeAnniversaryDate"] = null;
    }

    if (state["linkedContact"] === undefined) {
      state["linkedContact"] = null;
    }

    state.customFields = contact.customFields
      .map((customFieldValue: any) => ({
        id: customFieldValue.id,
        customFieldId: customFieldValue.customFieldId,
        value: FieldHelper.getCustomFieldValue(
          customFieldValue,
          customFieldValue
        ),
      }))
      .filter((cfv: any) => cfv.value !== "");
    return state;
  }, [contact]);

  const [form, setForm] = useState<null | any>(makeInitialFormState());
  const [customFields, setCustomFields] = useState<null | Array<any>>(
    contact.customFields ? (contact.customFields as Array<any>) : null
  );
  const [showingAllCustomFields, setShowingAllCustomFields] = useState(false);

  const setFieldValue = (key: string, value: any) => {
    if (value && typeof value === "object" && value.date !== undefined) {
      value = value.date;
    }
    setForm({ ...form, [key]: value });
  };

  const handleCustomFieldChange = (field: any, value: any) => {
    const customFieldId =
      field.__typename === "CustomFieldValue" ? field.customFieldId : field.id;
    setForm({
      ...form,
      customFields: form.customFields.map((cfv: any) => {
        if (cfv.customFieldId === customFieldId) {
          return { ...cfv, value };
        }
        return cfv;
      }),
    });
  };

  const getCustomFieldValue = (field: any): any => {
    const customFieldId =
      field.__typename === "CustomFieldValue" ? field.customFieldId : field.id;
    const cfv = form.customFields.find(
      (cfv: any) => cfv.customFieldId === customFieldId
    );
    if (!cfv) {
      return FieldHelper.getDefaultValue(field);
    } else {
      return cfv.value;
    }
  };

  const loadCustomFields = () => {
    apollo
      .query({
        query: GET_CUSTOM_FIELDS,
        variables: {
          tableName: "contact",
        },
      })
      .then((res) => {
        // List of custom field ids we're already showing.
        let customFieldIds: Array<string> = [];
        if (customFields) {
          customFieldIds = customFields.map((field: any) => {
            return field.__typename === "CustomFieldValue"
              ? field.customFieldId
              : field.id;
          });
        }
        const customFieldsToAdd = res.data.getCustomFields.filter(
          (customField: any) => customFieldIds.indexOf(customField.id) === -1
        );

        // Add the default values
        const values = customFieldsToAdd.map((customField: any) => ({
          customFieldId: customField.id,
          value: FieldHelper.getDefaultValue(customField),
        }));
        setForm({
          ...form,
          customFields: [...form.customFields, ...values],
        });

        // Add the fields
        if (customFields) {
          setCustomFields([...customFields, ...customFieldsToAdd]);
        } else {
          setCustomFields(customFieldsToAdd);
        }
      })
      .catch(() => {});

    setShowingAllCustomFields(true);
  };

  const resetCustomFieldRender = useCallback(() => {
    setShowingAllCustomFields(false);
    setCustomFields(
      contact.customFields
        ? (contact.customFields.filter(
            (cfv: any) => cfv.valueText !== ""
          ) as Array<any>)
        : null
    );
  }, [contact.customFields]);

  useEffect(() => {
    setForm(makeInitialFormState());
    resetCustomFieldRender();
  }, [contact, makeInitialFormState, setCustomFields, resetCustomFieldRender]);

  const renderFields = (fields: Array<any>): JSX.Element => {
    return (
      <>
        {fields.map((field: any) => (
          <Grid item xs={12} key={field.id}>
            <CustomField
              {...field}
              onChange={(value: any) => handleCustomFieldChange(field, value)}
              value={getCustomFieldValue(field)}
            />
          </Grid>
        ))}
      </>
    );
  };

  const handleSubmit = () => {
    const input = Object.assign({}, cleanFields(form));
    updateContact({
      variables: {
        input,
      },
    })
      .then((res) => {
        showSuccess("Contact saved");
        if (onUpdate) {
          onUpdate();
        }
      })
      .then(() => resetCustomFieldRender())
      .catch(() => {
        showError("An error occurred when saving the contact");
      });
  };

  return (
    <RightSideDrawer open={open} anchor="right">
      <Box
        style={{
          minHeight: "64px",
          background: "#1A0B31",
          display: "flex",
          alignItems: "center",
          padding: "16px",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#fff",
            display: "flex",
            alignItems: "center",
          }}
          variant={"h3"}
        >
          {contact.name}
        </Typography>
        <IconButton color="primary" onClick={onClose} size="large">
          <CloseRounded />
        </IconButton>
      </Box>

      <Box
        p={4}
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Paper style={{ marginBottom: "21px" }}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  variant="standard"
                  fullWidth
                  value={form.firstName}
                  onChange={(e: ChangeEvent<any>) =>
                    setFieldValue("firstName", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Last Name"
                  variant="standard"
                  fullWidth
                  value={form.lastName}
                  onChange={(e: ChangeEvent<any>) =>
                    setFieldValue("lastName", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <EmailField
                  type="email"
                  label="Email"
                  fullWidth
                  value={form.email}
                  onChange={(value: string | null) =>
                    setFieldValue("email", value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneField
                  type="phone"
                  label="Phone"
                  fullWidth
                  settings={{ format: "us_can" }}
                  value={form.phone}
                  onChange={(value: string | null) =>
                    setFieldValue("phone", value)
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Address</Typography>
              </Grid>
              <Grid item xs={12}>
                <AddressField
                  value={form.address}
                  onChange={(address) => setFieldValue("address", address)}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimeField
                  label="Birthday"
                  value={{ date: form.birthday, dateTime: null }}
                  onChange={(birthday) => setFieldValue("birthday", birthday)}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimeField
                  label="Home Anniversary Date"
                  value={{ date: form.homeAnniversaryDate, dateTime: null }}
                  onChange={(anniversary) =>
                    setFieldValue("homeAnniversaryDate", anniversary)
                  }
                />
              </Grid>

              {customFields && customFields.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6">Custom Fields</Typography>
                </Grid>
              )}
              {customFields && renderFields(customFields)}
              {!showingAllCustomFields && (
                <Grid item xs={12}>
                  <Button
                    size="small"
                    variant="text"
                    fullWidth
                    onClick={loadCustomFields}
                  >
                    Show empty custom fields
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
        <Paper sx={{ marginBottom: "21px" }}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ paddingBottom: "16px" }} variant="h6">
                  Linked Contact
                </Typography>
                {form.linkedContact ? (
                  <Chip
                    avatar={
                      <Avatar
                        alt={form.linkedContact.name}
                        src={form.linkedContact.avatarUrl}
                      />
                    }
                    label={form.linkedContact.name}
                    onDelete={() => setFieldValue("linkedContact", null)}
                    variant="outlined"
                  />
                ) : (
                  <ContactSelect
                    onChange={(e, value) => {
                      setFieldValue("linkedContact", value);
                    }}
                    value={form.linkedContact}
                    label="Select a Contact"
                    removeFromList={contact.id}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Paper sx={{ marginBottom: "21px" }}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Social Profiles</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.socialProfileContainer}
                >
                  <TwitterIcon style={{ marginRight: "3px", color: "gray" }} />
                  <Typography variant="subtitle1" className={classes.url}>
                    twitter.com/
                  </Typography>
                  <TextField
                    variant="standard"
                    className={classes.socialTextField}
                    value={form.socialProfiles.twitter}
                    onChange={(e) =>
                      setFieldValue("socialProfiles", {
                        ...form.socialProfiles,
                        twitter: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.socialProfileContainer}
                >
                  <FacebookIcon style={{ marginRight: "3px", color: "gray" }} />
                  <Typography variant="subtitle1" className={classes.url}>
                    facebook.com/
                  </Typography>
                  <TextField
                    variant="standard"
                    className={classes.socialTextField}
                    value={form.socialProfiles.facebook}
                    onChange={(e) =>
                      setFieldValue("socialProfiles", {
                        ...form.socialProfiles,
                        facebook: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.socialProfileContainer}
                >
                  <LinkedInIcon style={{ marginRight: "3px", color: "gray" }} />
                  <Typography variant="subtitle1" className={classes.url}>
                    linkedin.com/in/
                  </Typography>
                  <TextField
                    variant="standard"
                    className={classes.socialTextField}
                    value={form.socialProfiles.linkedIn}
                    onChange={(e) =>
                      setFieldValue("socialProfiles", {
                        ...form.socialProfiles,
                        linkedIn: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.socialProfileContainer}
                >
                  <InstagramIcon
                    style={{ marginRight: "3px", color: "gray" }}
                  />
                  <Typography variant="subtitle1" className={classes.url}>
                    instagram.com/
                  </Typography>
                  <TextField
                    variant="standard"
                    className={classes.socialTextField}
                    value={form.socialProfiles.instagram}
                    onChange={(e) =>
                      setFieldValue("socialProfiles", {
                        ...form.socialProfiles,
                        instagram: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Paper sx={{ marginBottom: "21px" }}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Advanced</Typography>
              </Grid>
              <Grid item xs={12}>
                <TagAutocomplete
                  freeSolo
                  value={form.tags}
                  onChange={(tags) => {
                    setForm(Object.assign({}, form, { tags }));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TeamMemberSelect
                  label="Assigned Agent"
                  value={form.ownerId}
                  onChange={(teamMember: any) =>
                    setFieldValue("ownerId", teamMember?.id || null)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  label="Lead Source"
                  fullWidth
                  value={form.leadSource}
                  onChange={(e: ChangeEvent<any>) =>
                    setFieldValue("leadSource", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Box p={2} style={{ background: "#F5F5F5" }}>
        <Grid container spacing={2} style={{}}>
          <Grid item xs={6}>
            <SecondaryButton fullWidth variant="outlined" onClick={onClose}>
              Close
            </SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton fullWidth onClick={handleSubmit} disabled={saving}>
              Save Contact
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
    </RightSideDrawer>
  );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(ContactUpdateForm);
