import { Theme, Drawer } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
export const RightSideDrawer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1201,
    },
    paper: {
      background: "#F5F5F5",
      //background: "#fff",
      width: "40%",
      [theme.breakpoints.down("lg")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  })
)(Drawer);
