import React, { FunctionComponent } from "react";

import { PrimaryButton, SecondaryButton } from "../buttons";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import { gql, useMutation } from "@apollo/client";
import { Typography } from "@mui/material";

type ContactBatchDeleteButtonProps = {
  open: boolean;
  selectedContacts: any;
  onClose?: () => void;
  onDeleted?: () => void;
};

export const ContactBatchDeleteButton: FunctionComponent<
  ContactBatchDeleteButtonProps
> = ({ open, onClose, selectedContacts, onDeleted }) => {
  const [deleteContacts, { loading: deleting }] = useMutation(gql`
    mutation BatchDeleteContacts($input: BatchDeleteContactsInput!) {
      batchDeleteContacts(input: $input)
    }
  `);
  const handleDelete = () => {
    const input = {
      contacts: Object.keys(selectedContacts),
    };
    deleteContacts({ variables: { input } })
      .then((res) => {
        if (onDeleted) {
          onDeleted();
        }
      })
      .catch((err) => {});
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        Are you sure you want to Delete {Object.keys(selectedContacts).length}{" "}
        {Object.keys(selectedContacts).length === 1 ? "contact" : "contacts"}?
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          Contacts will still show on any deals that they have been a part of,
          but will otherwise removed from lists and workflows.
        </Typography>
      </DialogContent>
      <DialogActions>
        <SecondaryButton autoFocus onClick={onClose}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={handleDelete}
          color="primary"
          variant="contained"
          disabled={deleting}
        >
          Delete Contacts
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
