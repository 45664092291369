import React, { useState, cloneElement, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import {
  TableHead,
  TableContainer,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Select,
  LinearProgress,
  CardActions,
  Dialog,
  Theme,
  DialogContent,
  DialogActions,
  Typography,
  Alert,
  FormControl,
  FormHelperText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { CREATE_CONTACT, GET_CUSTOM_FIELDS } from "../../api/graphql";
import clsx from "clsx";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { Close as CloseIcon } from "@mui/icons-material";
import { CloudUploadIcon } from "../../icons";
import ActionIconButton from "../ActionIconButton";
import moment from "moment";
import TeamMemberSelect from "../TeamMemberSelect";
import { useAlert } from "../../context/alert";
import ImportCompleteModal from "./ImportCompleteModal";
import ContactNameWithAvatar from "../contacts/ContactNameWithAvatar";
import { useNavigate } from "react-router-dom";
import SupportLink from "../SupportLink";

export const GET_CONTACTS_WITH_EMAIL = gql`
  query GetPotentialDuplicates($params: ContactByEmailQueryParams!) {
    getPotentialDuplicates(params: $params) {
      contacts {
        id
        name
        email
        owner {
          id
          name
          avatarUrl
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dnd: {
      backgroundColor: theme.palette.grey[100],
      border: `2px dashed ${theme.palette.grey[300]}`,
      padding: "24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      color: theme.palette.grey[700],
    },

    activeDnd: {
      backgroundColor: theme.palette.grey[300],
      border: `2px dashed ${theme.palette.grey[500]}`,
    },

    importTableRow: {
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
        cursor: "pointer",
      },
    },
  })
);

const fields = [
  {
    key: "firstName",
    value: "First Name",
    secondaryKey: "secondaryFirstName",
  },
  {
    key: "lastName",
    value: "Last Name",
    secondaryKey: "secondaryLastName",
  },
  {
    key: "fullName",
    value: "Full Name",
    secondaryKey: "secondaryFullName",
  },
  {
    key: "email",
    value: "Email",
    secondaryKey: "secondaryEmail",
  },
  {
    key: "phone",
    value: "Phone",
    secondaryKey: "secondaryPhone",
  },
  {
    key: "street",
    value: "Street Address",
  },
  {
    key: "street2",
    value: "Street Address Line 2",
  },
  {
    key: "city",
    value: "City",
  },
  {
    key: "state",
    value: "State",
  },
  {
    key: "zip",
    value: "Zip",
  },
  {
    key: "birthday",
    value: "Birthday",
    secondaryKey: "secondaryBirthday",
  },
  {
    key: "homeAnniversaryDate",
    value: "Home Anniversary Date",
  },
  {
    key: "leadSource",
    value: "Lead Source",
  },
  {
    key: "tags",
    value: "Tags",
  },
];

export default function ContactImport({
  children,
  onImportCompleted = () => {},
}: {
  children: any;
  onImportCompleted?: () => void;
}) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [csvRows, setCsvRows] = useState<any>([]);
  const [sampleRows, setSampleRows] = useState<any>([]);
  const [mapping, setMapping] = useState<any>({});
  const [progress, setProgress] = useState(0);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [customFields, setCustomFields] = useState<Array<any>>([]);
  const [existingContacts, setExistingContacts] = useState<Array<any>>([]);
  const [contactsToAdd, setContactsToAdd] = useState<Array<any>>([]);
  const [contactOwner, setContactOwner] = useState<any | null>(null);
  const [importDisabled, setImportDisabled] = useState<boolean>(true);

  const classes = useStyles();
  const navigate = useNavigate();
  const apollo = useApolloClient();
  const { showError } = useAlert();

  const { data } = useQuery(GET_CUSTOM_FIELDS, {
    variables: {
      tableName: "contact",
    },
  });
  useEffect(() => {
    if (data?.getCustomFields) {
      setCustomFields(data.getCustomFields);
    }
  }, [data]);

  const onDrop = (files: File[]) => {
    if (!files.length) {
      return;
    }
    Papa.parse(files[0], {
      complete: (r) => {
        if (r.data.length > 1) {
          const sampleRows: any = [];
          const headers: Array<string> = r.data[0] as Array<string>;
          const sampleValues = r.data[1] as string;
          let importFieldAssignments: any = {};

          headers.forEach((header, index: number) => {
            let importGuess;
            const importGuessObj = guessImportField(header);
            if (
              importGuessObj?.priority ||
              !importFieldAssignments[importGuessObj?.field]
            ) {
              importGuess = importGuessObj?.field;
              importFieldAssignments = {
                ...importFieldAssignments,
                [importGuess]: header,
              };
            }

            sampleRows.push({
              index: index,
              heading: header,
              sampleValue: sampleValues[index],
              bestGuess: importGuess,
            });
          });

          setSampleRows(sampleRows);
          setCsvRows(r.data);
          mapGuesses(sampleRows);
        } else {
          // The file was empty, or just contained headers!
        }
      },
    });
  };

  const guessPriority = (header: any, field: any) => {
    const priorityValues: { [key: string]: string[] } = {
      firstName: ["firstname", "first"],
      lastName: ["lastname", "last"],
      fullName: ["fullname", "name", "full"],
      phone: ["phone", "phonenumber", "number", "#", "cell", "cellphone"],
      email: ["email", "emailaddress"],
      street: ["sreet", "address"],
      city: ["city"],
      state: ["state"],
      zip: ["zip", "zipcode", "postal", "postalcode"],
      birthday: ["birthday"],
      leadSource: ["leadsource", "source"],
      tags: ["tags"],
    };

    priorityValues.secondaryFirstName = priorityValues.firstName;
    priorityValues.secondaryLastName = priorityValues.lastName;
    priorityValues.secondaryFullName = priorityValues.fullName;
    priorityValues.secondaryEmail = priorityValues.email;
    priorityValues.secondaryPhone = priorityValues.phone;
    priorityValues.secondaryBirthday = priorityValues.birthday;

    return priorityValues[field]?.includes(header)
      ? { priority: true, field: field }
      : { priority: false, field: field };
  };

  const guessImportField = (header: string) => {
    header = header.toLowerCase().replace(/[^A-Z0-9]+/gi, "");
    const isSecondary =
      header.includes("spouse") ||
      header.includes("partner") ||
      header.includes("linked") ||
      header.includes("secondary");

    switch (true) {
      case header.includes("first"):
        return guessPriority(
          header,
          isSecondary ? "secondaryFirstName" : "firstName"
        );
      case header.includes("last"):
        return guessPriority(
          header,
          isSecondary ? "secondaryLastName" : "lastName"
        );
      case header.includes("full"):
      case header.includes("name"):
        return guessPriority(
          header,
          isSecondary ? "secondaryFullName" : "fullName"
        );
      case header.includes("email"):
        return guessPriority(header, isSecondary ? "secondaryEmail" : "email");
      case header.includes("phone"):
        return guessPriority(header, isSecondary ? "secondaryPhone" : "phone");
      case header.includes("street"):
      case header.includes("address"):
        return guessPriority(header, "street");
      case header.includes("city"):
        return guessPriority(header, "city");
      case header.includes("state"):
        return guessPriority(header, "state");
      case header.includes("zip"):
      case header.includes("postal"):
        return guessPriority(header, "zip");
      case header.includes("birthday"):
        return guessPriority(
          header,
          isSecondary ? "secondaryBirthday" : "birthday"
        );
      case header.includes("source"):
        return guessPriority(header, "leadSource");
      case header.includes("tag"):
        return guessPriority(header, "tags");
      default:
        if (customFields.length > 0) {
          for (let i = 0; i < customFields.length; i++) {
            let customFieldHeader = customFields[i].label.toLowerCase();
            if (header.includes(customFieldHeader)) {
              return { priority: false, field: customFields[i].id };
            }
          }
        } else {
          break;
        }
    }
  };

  const mapGuesses = (
    columns: Array<{
      index: number;
      heading: string;
      sampleValue: string;
      bestGuess: any;
    }>
  ) => {
    let guessedMapping = { ...mapping };
    for (let i = 0; i < columns.length; i++) {
      let current = columns[i];
      if (current.bestGuess) {
        guessedMapping[current.bestGuess] = current.index;
      }
    }
    setMapping(guessedMapping);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const updateMapping = (e: any, col: any) => {
    const updatedMapping = { ...mapping };
    if (e.target.value) {
      updatedMapping[e.target.value] = col.index;
    } else {
      // Find the col.index in the mapping and delete it.
      for (const [k, v] of Object.entries(updatedMapping)) {
        if (col.index === v) {
          delete updatedMapping[k];
        }
      }
    }
    setMapping(updatedMapping);
  };

  const availableFields = (index: number) => {
    return fields.filter((field) => {
      return mapping[field.key] === undefined || mapping[field.key] === index;
    });
  };

  const availableCustomFields = (index: number) => {
    return customFields.filter(
      (field: any) =>
        mapping[field.id] === undefined || mapping[field.id] === index
    );
  };

  const availableSecondaryFields = (index: number) => {
    return fields.filter((field) => {
      if (field.secondaryKey == null) {
        return false;
      }
      return (
        mapping[field.secondaryKey] === undefined ||
        mapping[field.secondaryKey] === index
      );
    });
  };

  const handleOwnerChange = (owner: any) => {
    setContactOwner(owner);
  };

  useEffect(() => {
    if (Object.keys(mapping).filter((key) => key !== undefined).length > 0) {
      setImportDisabled(false);
    } else {
      setImportDisabled(true);
    }
  }, [mapping, importDisabled]);

  const handleImportValidContacts = async () => {
    setIsImporting(true);
    setIsComplete(false);
    addContacts(contactsToAdd);
  };

  const handleImport = async () => {
    setIsImporting(true);
    setIsComplete(false);

    let i: number;
    const contacts: any[] = [];

    /**
     * Format the contacts.
     * The loop skips the header row (hence starting at index 1) and the last row seems to always
     * be an empty row (maybe the last line feed in the CSV?).
     * We'll skip that as well (hence the length - 1)
     */

    for (i = 1; i < csvRows.length; i++) {
      const contact: any = { customFields: [] };
      if (contactOwner !== null) {
        contact.ownerId = contactOwner.id;
      }
      // eslint-disable-next-line no-loop-func
      Object.keys(mapping).forEach((key) => {
        const customField = customFields.find((cf: any) => cf.id === key);
        const index = mapping[key];
        const value: string = csvRows[i][index];
        if (index !== undefined && index !== null) {
          if (
            key === "street" ||
            key === "street2" ||
            key === "city" ||
            key === "state" ||
            key === "zip"
          ) {
            if (!contact.address) {
              contact.address = {};
            }
            contact.address[key] = value;
          } else if (key === "fullName") {
            const parts = value.split(" ");
            if (parts.length) {
              contact.firstName = parts.shift();
              if (parts.length) {
                contact.lastName = parts.join(" ");
              }
            }
          } else if (key === "secondaryFullName") {
            const parts = value.split(" ");
            if (parts.length) {
              contact.secondaryFirstName = parts.shift();
              if (parts.length) {
                contact.secondaryLastName = parts.join(" ");
              }
            }
          } else if (key === "birthday") {
            const m = moment(value);
            if (m.isValid()) {
              contact.birthday = m.toISOString();
            }
          } else if (key === "secondaryBirthday") {
            const m = moment(value);
            if (m.isValid()) {
              contact.secondaryBirthday = m.toISOString();
            }
          } else if (customField) {
            contact.customFields.push({
              customFieldId: customField.id,
              value,
            });
          } else if (key === "tags") {
            const tags = value
              .split(",")
              .map((tag) => tag.trim())
              .filter((tag) => tag.length > 0)
              .map((tag) => ({ name: tag }));
            if (tags.length > 0) {
              contact.tags = tags;
            }
          } else {
            contact[key] = value;
          }
        }
      });
      contacts.push(contact);
    }
    const contactEmails = contacts.map((contact) => contact.email);
    await getExistingEmails(contactEmails).then((response: any) => {
      console.log(response);

      if (response.data.contacts.length < 1) {
        addContacts(contacts);
      } else {
        setExistingContacts(response.data.contacts);
        const existingEmails = response.data.contacts.map(
          (existingContact: any) => existingContact.email
        );
        const contactsToAdd = contacts.filter(
          (contact) => !existingEmails.includes(contact.email)
        );

        setContactsToAdd(contactsToAdd);
        setIsImporting(false);
        return;
      }
    });
  };

  const addContacts = async (contacts: any) => {
    const requests = contacts.map(async (contact: any, index: number) => {
      try {
        await apollo.mutate({
          mutation: CREATE_CONTACT,
          variables: { input: contact },
        });
      } catch (e) {
        showError(`There was an error adding the contact ${contact.fullName}`);
      }
      setProgress((index / contacts.length) * 100);
    });

    await Promise.all(requests).then(() => {
      setIsComplete(true);
      handleCancel();
      onImportCompleted();
    });
  };

  const getExistingEmails = async (emails: any) => {
    return await new Promise((resolve, reject) => {
      apollo
        .query({
          query: GET_CONTACTS_WITH_EMAIL,
          variables: { params: { emails } },
        })
        .then((response) => {
          resolve({
            data: response.data.getPotentialDuplicates,
          });
        })
        .catch((error) => reject(error));
    });
  };

  const handleCancel = () => {
    setDialogOpen(false);
    setIsImporting(false);
    setMapping({});
    setSampleRows([]);
    setCsvRows([]);
    setExistingContacts([]);
    setContactsToAdd([]);
    setContactOwner(null);
  };

  const handleComplete = () => {
    setIsComplete(false);
  };

  const isReadyForFile = () => sampleRows.length === 0;
  const isMappingFields = () =>
    sampleRows.length > 0 &&
    !isImporting &&
    !isComplete &&
    existingContacts.length < 1;

  return (
    <>
      {cloneElement(children as any, {
        onClick: (e) => {
          e.stopPropagation();
          setDialogOpen(true);
        },
      })}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "800px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 24px",
          }}
        >
          <div>
            <Typography variant="h5">Import</Typography>
            <Typography color="textSecondary">
              Import your existing contacts by uploading a CSV
            </Typography>
            <SupportLink url="https://intercom.help/shakerio/en/articles/7436407-how-do-i-import-my-contact-list">
              Need help importing contacts?
            </SupportLink>
          </div>
          <ActionIconButton
            icon={CloseIcon}
            style={{ margin: "4px -5px" }}
            buttonStyle={{ width: "22px", height: "22px" }}
            onClick={() => {
              setDialogOpen(false);
            }}
          />
        </div>

        <>
          {isReadyForFile() && (
            <>
              <DialogContent>
                <div
                  {...getRootProps()}
                  className={clsx(classes.dnd, {
                    [classes.activeDnd]: isDragActive,
                  })}
                >
                  <input {...getInputProps()}></input>
                  <CloudUploadIcon size="96px" />
                  <p style={{ marginTop: "0" }}>Drag &amp; drop a CSV</p>
                  <p>or</p>
                  <p>
                    <PrimaryButton onClick={open}>Browse Files</PrimaryButton>
                  </p>
                </div>
              </DialogContent>
              <DialogActions />
            </>
          )}

          {isMappingFields() && (
            <>
              <DialogContent>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>CSV Header Row</TableCell>
                        <TableCell>Sample Value</TableCell>
                        <TableCell>Mapping</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sampleRows.map((row: any) => (
                        <TableRow key={row.index}>
                          <TableCell
                            component="th"
                            sx={{
                              flex: 1,
                              maxWidth: "200px",
                            }}
                          >
                            {row.heading}
                          </TableCell>
                          <TableCell
                            sx={{
                              maxWidth: "200px",
                              flex: 1,
                            }}
                          >
                            {row.sampleValue}
                          </TableCell>
                          <TableCell
                            sx={{
                              flex: 1,
                              flexDirection: "row",
                            }}
                          >
                            {availableFields(row.index).length > 0 ? (
                              <FormControl fullWidth>
                                <Select
                                  margin="dense"
                                  size="small"
                                  native
                                  fullWidth
                                  defaultValue={row.bestGuess}
                                  onChange={(e) => updateMapping(e, row)}
                                >
                                  <option></option>
                                  {availableFields(row.index).map((item) => (
                                    <option key={item.key} value={item.key}>
                                      {item.value}
                                    </option>
                                  ))}
                                  <optgroup label="Secondary Contact">
                                    {availableSecondaryFields(row.index).map(
                                      (item) => (
                                        <option
                                          key={item.secondaryKey}
                                          value={item.secondaryKey}
                                        >
                                          Secondary - {item.value}
                                        </option>
                                      )
                                    )}
                                  </optgroup>
                                  {customFields.length && (
                                    <optgroup label="Custom Fields">
                                      {availableCustomFields(row.index).map(
                                        (item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.label}
                                          </option>
                                        )
                                      )}
                                    </optgroup>
                                  )}
                                </Select>
                                {mapping.tags === row.index && (
                                  <FormHelperText>
                                    We'll assume multiple tags are comma
                                    separated
                                  </FormHelperText>
                                )}
                              </FormControl>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={2}>Assigned Agent</TableCell>
                        <TableCell>
                          <TeamMemberSelect
                            value={contactOwner?.id}
                            onChange={handleOwnerChange}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              <DialogActions>
                <div style={{ flexGrow: 1 }}></div>
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleImport} disabled={importDisabled}>
                  Import
                </PrimaryButton>
              </DialogActions>
            </>
          )}

          {existingContacts.length > 0 && (
            <>
              <DialogContent>
                <Alert severity="warning">
                  The following contacts will not be imported due to duplication
                </Alert>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Existing Name</TableCell>
                        <TableCell>Team Member</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {existingContacts.map(
                        (contactRow: any, index: number) => (
                          <TableRow
                            key={index}
                            onClick={() => {
                              navigate(`/contacts/${contactRow.id}`);
                            }}
                            className={classes.importTableRow}
                          >
                            <TableCell component="th">
                              {contactRow.email}
                            </TableCell>
                            <TableCell>{contactRow.name ?? "N/A"}</TableCell>
                            <TableCell align="center">
                              {contactRow.owner ? (
                                <ContactNameWithAvatar
                                  name={contactRow.owner.name}
                                  avatarUrls={[contactRow.owner.avatarUrl]}
                                  avatarStyle={{
                                    height: "25px",
                                    width: "25px",
                                  }}
                                />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              <DialogActions>
                <div style={{ flexGrow: 1 }}></div>
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
                <PrimaryButton
                  onClick={handleImportValidContacts}
                  disabled={importDisabled}
                >
                  Continue
                </PrimaryButton>
              </DialogActions>
            </>
          )}

          {isImporting && (
            <>
              <DialogContent>
                <LinearProgress variant="determinate" value={progress} />
              </DialogContent>
              <CardActions>
                <div style={{ flexGrow: 1 }}></div>
                <DialogActions onClick={handleCancel}>Cancel</DialogActions>
              </CardActions>
            </>
          )}
        </>
      </Dialog>
      {!isImporting && (
        <ImportCompleteModal onDone={handleComplete} open={isComplete} />
      )}
    </>
  );
}
