import React, { useCallback, useContext } from "react";
import { IconButton, Typography } from "@mui/material";
import { AddIcon } from "../../../../icons";
import { Note } from "../../../../models";
import DetailCard from "../../../../components/DetailCard";
import CreateNote from "../../../../components/notes/CreateNote";
import NoteList from "../../../../components/notes/List";
import { DealViewActionType, dealViewStore } from "../context";

const Notes = () => {
  const {
    state: { deal, notes },
    dispatch,
  } = useContext(dealViewStore);

  const handleNoteCreated = useCallback(
    (note: Note) => dispatch({ type: "ADD_NOTE", note }),
    [dispatch]
  );

  const handleNoteDeleted = useCallback(
    (note: Note) => dispatch({ type: DealViewActionType.REMOVE_NOTE, note }),
    [dispatch]
  );

  const handleNoteEdited = useCallback(
    (note: Note) => dispatch({ type: DealViewActionType.EDIT_NOTE, note }),
    [dispatch]
  );

  return (
    <DetailCard
      title={<Typography variant="h5">Notes</Typography>}
      action={
        <CreateNote dealId={deal?.id} onNoteCreated={handleNoteCreated}>
          <IconButton size="large">
            <AddIcon />
          </IconButton>
        </CreateNote>
      }
    >
      <NoteList
        notes={notes}
        onNoteDeleted={handleNoteDeleted}
        onNoteEdited={handleNoteEdited}
      />
    </DetailCard>
  );
};

export default Notes;
