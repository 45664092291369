import { createContext, Dispatch } from "react";
import { Contact, Task, Deal, Note } from "../../models";

export enum ContactViewActionType {
  SET_FULL_STATE = "SET_FULL_STATE",
  SET_CONTACT = "SET_CONTACT",
  SET_TASKS = "SET_TASKS",
  SET_DEALS = "SET_DEALS",
  SET_NOTES = "SET_NOTES",
  ADD_TASK = "ADD_TASK",
  UPDATE_TASK = "UPDATE_TASK",
  DELETE_TASK = "DELETE_TASK",
  ADD_TASK_NOTE = "ADD_TASK_NOTE",
  EDIT_TASK_NOTE = "EDIT_TASK_NOTES",
  REMOVE_TASK_NOTE = "REMOVE_TASK_NOTE",
  ADD_DEAL = "ADD DEAL",
  ADD_NOTE = "ADD_NOTE",
  EDIT_NOTE = "EDIT_NOTE",
}

export type ContactViewState = {
  contact: Contact;
  tasks: Task[];
  deals: Deal[];
  notes: Note[];
};

export const contactViewStore = createContext(
  {} as { state: ContactViewState; dispatch: Dispatch<any> }
);

export const { Provider } = contactViewStore;

export const reducer = (state: ContactViewState, action: any) => {
  switch (action.type) {
    case ContactViewActionType.SET_FULL_STATE:
      return Object.assign({}, state, {
        contact: action.contact,
        tasks: action.contact.tasks || [],
        deals: action.contact.deals || [],
        notes: action.contact.notes || [],
      });
    case ContactViewActionType.SET_CONTACT:
      return Object.assign({}, state, {
        contact: action.contact,
      });
    case ContactViewActionType.SET_TASKS:
      return Object.assign({}, state, {
        tasks: action.tasks,
      });
    case ContactViewActionType.SET_DEALS:
      return Object.assign({}, state, {
        deals: action.deals,
      });
    case ContactViewActionType.SET_NOTES:
      return Object.assign({}, state, {
        notes: action.notes,
      });
    case ContactViewActionType.ADD_TASK:
      state = Object.assign({}, state, {
        tasks: [action.task, ...state.tasks],
      });
      return state;
    case ContactViewActionType.UPDATE_TASK:
      return Object.assign({}, state, {
        tasks: state.tasks.map((contactTask: any) => {
          if (contactTask.id === action.task.id) return action.task;
          else return contactTask;
        }),
      });

    case ContactViewActionType.DELETE_TASK:
      return Object.assign({}, state, {
        tasks: state.tasks.filter(
          (contactTask: any) => contactTask.id !== action.task.id
        ),
      });
    case ContactViewActionType.ADD_TASK_NOTE:
      return Object.assign({}, state, {
        tasks: state.tasks.map((task) => {
          if (task.id === action.note.task.id) {
            task.notes = [...task.notes, action.note];
          }
          return task;
        }),
      });
    case ContactViewActionType.EDIT_TASK_NOTE:
      return Object.assign({}, state, {
        tasks: state.tasks.map((task: any) => {
          task.notes.map((note: any) => {
            if (action.note.id === note.id) {
              note.body = action.note.body;
            }
            return note;
          });
          return task;
        }),
      });
    case ContactViewActionType.REMOVE_TASK_NOTE:
      return Object.assign({}, state, {
        tasks: state.tasks.map((task: any) => {
          const notes = task.notes.filter(
            (note: any) => action.note.id !== note.id
          );
          task.notes = notes;
          return task;
        }),
      });
    case ContactViewActionType.ADD_DEAL:
      return Object.assign({}, state, {
        deals: [...state.deals, action.deal],
      });
    case ContactViewActionType.ADD_NOTE:
      return Object.assign({}, state, {
        notes: [action.note, ...state.notes],
      });
    case ContactViewActionType.EDIT_NOTE:
      return Object.assign({}, state, {
        notes: state.notes.map((note: any) => {
          if (note.id === action.note.id) {
            note.body = action.note.body;
          }
          return note;
        }),
      });
    default:
      return state;
  }
};
