import React from "react";
import { PrimaryButton, SecondaryButton } from "./buttons";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
} from "@mui/material";

type AddDocumentTypesModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  setState: (value: any) => void;
  handleAction: (e: any, state: { id: string; name: string }) => void;
  state: any;
};

const AddDocumentTypesModal = ({
  open,
  setOpen,
  setState,
  state = {},
  handleAction,
}: AddDocumentTypesModalProps) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitle>
        {state.id ? "Edit Document Type" : "New Document Type"}
      </DialogTitle>
      <form>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoFocus
                label="Document Type Name"
                variant="standard"
                id="document-type-name"
                value={state.name}
                onChange={(e) =>
                  setState({ id: state.id, name: e.target.value })
                }
                placeholder="e.g. Buyer Agreement"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={() => setOpen(false)}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            onClick={(e: object) => handleAction(e, state)}
            disabled={!state.name}
          >
            Save
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const DocumentTypesDeleteModal = ({
  deleteOpen,
  setDeleteOpen,
  handleDocumentTypeDelete,
  state = {},
}: {
  deleteOpen: boolean;
  state: any;
  handleDocumentTypeDelete: (state: any) => void;
  setDeleteOpen: (boolean: boolean) => void;
}) => {
  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={deleteOpen}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Typography>
          Are you sure you want to delete {""}
          <Typography sx={{ fontWeight: "bold", display: "inline" }}>
            {state.name}
          </Typography>
          ?
        </Typography>
      </DialogTitle>
      <DialogActions>
        <SecondaryButton autoFocus onClick={() => setDeleteOpen(false)}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={() => handleDocumentTypeDelete(state)}
          color="primary"
          variant="contained"
        >
          Delete
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export { AddDocumentTypesModal, DocumentTypesDeleteModal };
