import React from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import FileTypeIcon from "../../FileTypeIcon";
import SecondaryText from "./SecondaryText";

interface DocumentProps {
  document: any;
  reportView?: boolean;
  isClient?: boolean;
  setAnchorEl: any;
}

const Document = ({
  document,
  setAnchorEl,
  reportView = false,
  isClient = false,
}: DocumentProps) => {
  const handleMenuClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <ListItem>
      <ListItemIcon style={{ paddingLeft: "20px" }}>
        <FileTypeIcon filename={document.name} />
      </ListItemIcon>
      <ListItemText
        primary={document.name}
        secondary={<SecondaryText document={document} isClient={isClient} />}
        style={{ margin: 0 }}
      />
      <ListItemSecondaryAction>
        {!reportView && (
          <IconButton onClick={(e: any) => handleMenuClick(e)} size="large">
            <MoreVert />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default Document;
