import React, { FC } from "react";
import { Box, Link, Alert } from "@mui/material";
import { useUser } from "../context/user";
import { Link as RouterLink } from "react-router-dom";
type PastDueAlertProps = {
  account: any;
};

export const PastDueAlert: FC<PastDueAlertProps> = ({ account }) => {
  const user = useUser();
  const hasBillingPermission = user.hasPermission("billing", "full");

  return (
    <Alert
      icon={false}
      severity="warning"
      sx={{ marginRight: 1, marginLeft: 1 }}
    >
      <Box component="span">
        {hasBillingPermission ? (
          <>
            {" "}
            Please update your{" "}
            <Link to="/settings/account/billing" component={RouterLink}>
              billing information
            </Link>
          </>
        ) : (
          " Contact your admin"
        )}
        <Box
          component={"span"}
          sx={{ display: { xs: "inline", sm: "none", md: "inline" } }}
        >
          {" "}
          to avoid interruption
        </Box>
      </Box>
    </Alert>
  );
};
