import React, { useState, ChangeEvent, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Chip, Box } from "@mui/material";
import ContactNameWithAvatar from "./contacts/ContactNameWithAvatar";
import { useQuery } from "@apollo/client";
import { GET_TEAM_MEMBERS } from "../api/graphql";

type TeamMemberSelectMultipleProps = {
  onChange: (event: ChangeEvent<any>, teamMembers: any) => void;
  value: any[];
  variant?: any;
  style?: any;
  label?: string;
  limitTags?: number;
  placeholder?: string;
};

export default function TeamMemberSelectMultiple({
  onChange,
  value,
  variant = "standard",
  style = {},
  label,
  placeholder = "Agents",
  limitTags = -1,
}: TeamMemberSelectMultipleProps) {
  const [teamMembers, setTeamMembers] = useState<Array<any>>([]);

  const handleChange = (e: ChangeEvent<any>, value: any) => {
    onChange(e, value);
  };

  const handleGetTeamMembers = useCallback((res: any) => {
    setTeamMembers(res.getTeamMembers);
  }, []);

  useQuery(GET_TEAM_MEMBERS, {
    onCompleted: handleGetTeamMembers,
  });

  return (
    <Autocomplete
      style={style}
      limitTags={limitTags}
      multiple
      id="selected-agents-autocomplete"
      options={teamMembers}
      value={value}
      onChange={handleChange}
      getOptionLabel={(option: any) => option.name || ""}
      filterSelectedOptions
      renderTags={(value: any, getOptionSelected) =>
        value.map((value: any, index: number) => (
          <Chip
            variant="outlined"
            label={value.name}
            {...getOptionSelected({ index })}
          />
        ))
      }
      renderOption={(props: any, option: any) => (
        <Box component="li" {...props}>
          <ContactNameWithAvatar
            name={option.name}
            avatarUrls={[option.avatarUrl]}
          />
        </Box>
      )}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant={variant}
          label={label ? label : "Select Agent(s)"}
          placeholder={placeholder}
        />
      )}
    />
  );
}
