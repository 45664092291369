import React from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import Annotation from "chartjs-plugin-annotation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Annotation,
  PointElement,
  LineElement,
  Filler
);

const MonthlyChart = ({
  data: agg,
  getValue,
  goals,
  stacked = false,
  line = false,
  hideLegend = false,
  showLabels = false,
  dataKey = "total",
  label,
  colorIndex,
}: {
  data: any[];
  goals?: any;
  getValue: (agg: any) => any;
  stacked?: boolean;
  line?: boolean;
  hideLegend?: boolean;
  showLabels?: boolean;
  dataKey?: string;
  label?: string;
  colorIndex?: number;
}) => {
  if (agg.length === 0) {
    return null;
  }

  const compact = (value: string | number | null | undefined) => {
    if (value == null) {
      return "";
    }
    return Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(typeof value === "string" ? Number(value) : value);
  };

  const colors = [
    "#351665",
    "#FF576D",
    "#37E6D9",
    "#FFB000",
    "#FFA184",
    "#7BDA5E",
    "#0B8D9B",
  ];

  const labels = agg[0].years[0].months.map((monthAgg: any) => {
    const date = new Date();
    date.setMonth(monthAgg.month - 1);
    return date.toLocaleString("en-US", {
      month: "short",
    });
  });

  const datasets: any = agg.map((data: any, index) => {
    return {
      label:
        data.dealType?.name ??
        data.teamMember?.name ??
        data.source ??
        data.dealState ??
        label ??
        "Unknown",
      data: data.years[0].months.map(
        (monthAgg: any) => getValue(monthAgg)[dataKey]
      ),
      backgroundColor: colorIndex
        ? colors[colorIndex]
        : colors[index % colors.length],
    };
  });

  if (goals) {
    const data: number[] = goals.monthly.map((goal: any) => getValue(goal));
    if (data.some((value) => value > 0)) {
      datasets.push({
        data,
        datalabels: {
          display: false,
        },
        label: "Monthly Goal",
        backgroundColor: "#eefcfd",
        borderColor: "#0B8D9B",
        borderWidth: 2,
        borderDash: [8, 4],
        type: "line",
        pointRadius: 0,
        fill: {
          target: "origin",
        },
      });
    }
  }

  const options: any = {
    plugins: {
      datalabels: {
        display: showLabels,
        color: "#FEFBF8",
        font: {
          weight: "bold",
        },
        formatter: (value: number) => {
          return compact(value);
        },
      },
      legend: {
        display: !hideLegend,
        position: "bottom" as "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 8,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked,
      },
      y: {
        stacked,
        beginAtZero: true,
      },
    },
  };

  const data = {
    labels,
    datasets,
  };

  return line ? (
    <Line data={data} options={options} />
  ) : (
    <Bar data={data} options={options} />
  );
};

export default MonthlyChart;

export const Chart = ({
  data: agg,
  getValue,
  stacked = false,
  line = false,
  hideLegend = false,
}: {
  data: any[];
  goals?: any;
  getValue: (agg: any) => any;
  stacked?: boolean;
  line?: boolean;
  hideLegend?: boolean;
  showLabels?: boolean;
}) => {
  if (agg.length === 0) {
    return null;
  }

  const colors = [
    "#351665",
    "#FF576D",
    "#37E6D9",
    "#FFB000",
    "#FFA184",
    "#7BDA5E",
    "#0B8D9B",
  ];

  const { labels, data: datasetData } = agg.reduce(
    (acc, agg) => {
      acc.labels.push(
        agg.dealType?.name ?? agg.teamMember?.name ?? agg.source ?? "Unknown"
      );
      acc.data.push(getValue(agg).total);
      return acc;
    },
    { labels: [], data: [] }
  );

  const datasets = [
    {
      label: "Units",
      data: datasetData,
      backgroundColor: colors[2],
    },
  ];

  const options: any = {
    plugins: {
      legend: {
        display: !hideLegend,
        position: "bottom" as "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 8,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const data = {
    labels,
    datasets,
  };

  return line ? (
    <Line data={data} options={options} />
  ) : (
    <Bar data={data} options={options} />
  );
};
