import { useState, useEffect } from "react";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import {
  FRAGMENT_DEAL_FIELDS,
  FRAGMENT_DEAL_CUSTOM_FIELDS,
  FRAGMENT_DEAL_TEAM_MEMBERS,
} from "../../api/graphql";
import { useAlert } from "../../context/alert";

const GET_DOTLOOP_PROFILES = gql`
  query GetDotloopProfiles {
    getDotloopProfiles {
      id
      name
      default
    }
  }
`;

type DotloopProfile = {
  id: number;
  name: string;
  default: boolean;
};

export type DotloopLoop = {
  id: number;
  name: string;
  loopUrl?: string;
};

export const useCreateFromDotloop = (open: boolean) => {
  const [profiles, setProfiles] = useState<Array<DotloopProfile>>([]);
  const [profileId, setProfileId] = useState("");
  const [selectedLoop, setSelectedLoop] = useState<DotloopLoop>();

  const { showError } = useAlert();

  const dotLoopSubmit = selectedLoop && profileId;

  const [getDotloopProfiles, { data, loading }] =
    useLazyQuery(GET_DOTLOOP_PROFILES);

  const [importDotloopLoop] = useMutation(gql`
    mutation ImportDotloopLoop($input: ImportDotloopLoopInput!) {
      importDotloopLoop(input: $input) {
        ...DealFields
        ...DealCustomFields
        ...DealTeamMembers
        dealType {
          id
        }
      }
    }
    ${FRAGMENT_DEAL_FIELDS}
    ${FRAGMENT_DEAL_CUSTOM_FIELDS}
    ${FRAGMENT_DEAL_TEAM_MEMBERS}
  `);

  const importDeal = () => {
    if (!selectedLoop) {
      return Promise.reject("No loop selected");
    }
    return importDotloopLoop({
      variables: {
        input: {
          profileId: parseInt(profileId, 10),
          loopId: selectedLoop?.id,
        },
      },
    })
      .then((res) => {
        return res.data.importDotloopLoop;
      })
      .catch(() => {
        showError("An error occurred when trying to import from Dotloop");
      });
  };

  useEffect(() => {
    if (open) {
      getDotloopProfiles();
    }
  }, [open, getDotloopProfiles]);

  useEffect(() => {
    if (data?.getDotloopProfiles) {
      setProfiles(data.getDotloopProfiles);
      const defaultProfile = data?.getDotloopProfiles.find(
        (profile: any) => profile.default
      );
      if (defaultProfile) {
        setProfileId(defaultProfile.id);
      }
    }
  }, [data]);

  return {
    profileId,
    profiles,
    loading,
    setProfileId,
    selectedLoop,
    setSelectedLoop,
    dotLoopSubmit,
    importDeal,
  };
};
