import React, { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Divider,
  Chip,
  Box,
  Theme,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import moment from "moment";
import EmailBodyIframe from "./EmailBodyIframe";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import ComposeEmailDialog from "./ComposeEmailDialog";
import { Archive, Reply, ReplyAll } from "@mui/icons-material";
import { useUser } from "../../context/user";
import { GET_EMAIL_THREAD } from "../../api/graphql";
import { useAlert } from "../../context/alert";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontSize: "13px",
      color: theme.palette.secondary.main,
      fontWeight: 600,
    },
    muted: {
      color: "#6b7280",
      fontSize: "12px",
    },
    email: {
      display: "flex",
      flexDirection: "column",
      paddingBottom: theme.spacing(4),
    },
    link: {
      fontWeight: 600,
      color: theme.palette.secondary.main,
      transition: "0.2s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.turquoise.main,
      },
    },
    attachment: { marginBottom: "16px", marginRight: "6px" },
  })
);

type Contact = {
  id: string;
  name: string;
};

type EmailThreadPanelProps = {
  emailThread: any;
  contact?: any;
  onArchive?: (thread: any) => void;
  standalone?: boolean;
};
const EmailThreadView: FunctionComponent<EmailThreadPanelProps> = ({
  emailThread: et,
  onArchive,
  standalone,
  contact,
}) => {
  const [emailThread, setEmailThread] = useState<any | null>(null);
  const [contactList, setContactList] = useState<Array<Contact>>([]);
  const apollo = useApolloClient();
  const classes = useStyles();
  const [composeEmailDialogProps, setComposeEmailDialogProps] =
    useState<any>(null);
  const [archiveEmailThread] = useMutation(gql`
    mutation ArchiveEmailThread($input: ArchiveEmailThreadInput!) {
      archiveEmailThread(input: $input)
    }
  `);

  const [getFullEmailThread, { data, loading }] = useLazyQuery(
    GET_EMAIL_THREAD,
    {
      variables: {
        id: et.id,
        contactId: contact?.id,
      },
    }
  );

  const { currentUser } = useUser();
  const { showSuccess, showError } = useAlert();

  const formatDateTime = (date: Date): string => {
    let dateFormat: string;
    const m = moment(date);
    const now = moment();

    // Date format
    const diff = now.diff(m, "d");
    const isCurrentYear = m.get("y") === now.get("y");
    if (diff === 0) {
      dateFormat = "[Today]";
    } else if (diff === -1) {
      dateFormat = "[Yesterday]";
    } else if (isCurrentYear) {
      dateFormat = "MMM D";
    } else {
      dateFormat = "MMM D, YYYY";
    }

    return m.format(`${dateFormat} [at] h:ss A`);
  };

  const handleAttachmentClick = async (e: any, attachment: any) => {
    const getDownloadUrl = gql`
      query GetSignedDownloadAttachmentUrl($id: ID!) {
        getSignedDownloadAttachmentUrl(id: $id) {
          downloadUrl
        }
      }
    `;
    const res = await apollo.query({
      query: getDownloadUrl,
      variables: {
        id: attachment.id,
      },
    });
    const url = res.data.getSignedDownloadAttachmentUrl.downloadUrl;
    window.location = url;
  };

  const handleReply = (email: any) => {
    setComposeEmailDialogProps({
      emailThread,
      recipients: [email.from],
    });
  };

  const handleReplyAll = (email: any) => {
    const recipients = [
      email.from,
      ...email.recipients.map((r: any) => r.to),
    ].filter((r: any) => r.id !== currentUser.teamMember?.id);

    setComposeEmailDialogProps({
      emailThread,
      recipients,
    });
  };

  const handleComposeEmailDialogClosed = () => {
    setComposeEmailDialogProps(null);
  };

  const handleEmailSent = () => {
    setComposeEmailDialogProps(null);
  };

  const handleArchive = () => {
    archiveEmailThread({
      variables: {
        input: {
          emailThreadId: emailThread.id,
        },
      },
    })
      .then(() => {
        showSuccess(`Email thread "${emailThread.subject}" archived`);
        if (onArchive) {
          onArchive(emailThread);
        }
      })
      .catch(() => {
        showError(`An error occurred when archiving "${emailThread.subject}"`);
      });
  };

  const OutgoingEmailHeader = ({ email }: { email: any }): JSX.Element => {
    let fromName = email.from.name;
    let isYou = false;
    if (
      email.from.__typename === "TeamMember" &&
      currentUser.teamMember?.id === email.from.id
    ) {
      isYou = true;
      fromName = "You";
    }
    return (
      <Box>
        <p>
          <span className={classes.bold}>
            {isYou ? `${currentUser.name} (${fromName})` : fromName}
          </span>
          <br />
          <span className={classes.muted}>
            {formatDateTime(email.messageDate)}
          </span>
        </p>
      </Box>
    );
  };

  const IncomingEmailHeader = ({ email }: { email: any }): JSX.Element => {
    return (
      <p>
        <Link
          className={clsx(classes.link, classes.bold)}
          to={`contacts/${email.from.id}`}
        >
          {email.from.name}
        </Link>
        <br />
        <span className={classes.muted}>
          {formatDateTime(email.messageDate)}
        </span>
      </p>
    );
  };

  useEffect(() => {
    if (data?.getEmailThread) {
      setEmailThread(data?.getEmailThread);
    }
  }, [data]);

  useEffect(() => {
    // Load the full version of the thread (including emails)
    getFullEmailThread();
  }, [et, getFullEmailThread]);

  useEffect(() => {
    if (emailThread?.emails.length > 0) {
      let incomingEmails = emailThread?.emails.filter(
        (email: any) => email.direction === "incoming"
      );
      if (incomingEmails.length > 0) {
        setContactList(
          incomingEmails.reduce((acc: Contact[], email: any, index: number) => {
            if (acc.some((contact) => contact.name === email.from.name)) {
              return acc;
            } else {
              acc.push({ id: email.from.id, name: email.from.name });
              return acc;
            }
          }, [])
        );
      } else {
        setContactList(
          emailThread?.emails.reduce((acc: Contact[], email: any) => {
            email.recipients.forEach((rec: any) => {
              acc.push({ id: rec.to.id, name: rec.to.name });
              return acc;
            });
            return acc;
          }, [])
        );
      }
    }
  }, [emailThread?.emails]);

  if (loading) {
    return (
      <Box
        style={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (!loading && emailThread === null) {
    return (
      <Paper>
        <Box p={4}>
          <Typography>
            An error occurred when loading this email thread.
          </Typography>
        </Box>
      </Paper>
    );
  }
  return (
    <Paper elevation={standalone ? undefined : 0}>
      {standalone && (
        <>
          <Box
            pl={4}
            pr={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              In this thread:{" "}
              {contactList.map((contact) => (
                <Link
                  key={contact.id}
                  className={classes.link}
                  to={`contacts/${contact.id}`}
                >
                  {contact.name}
                </Link>
              ))}
            </Box>
            <IconButton onClick={handleArchive} size="large">
              <Archive fontSize="small" />
            </IconButton>
          </Box>
          <Divider />
        </>
      )}

      <Box
        pt={standalone ? 4 : 0}
        pl={standalone ? 4 : 0}
        pr={standalone ? 4 : 0}
      >
        {composeEmailDialogProps !== null && (
          <ComposeEmailDialog
            {...composeEmailDialogProps}
            onClosed={handleComposeEmailDialogClosed}
            onEmailSent={handleEmailSent}
          />
        )}

        {emailThread.emails.map((email: any, index: number) => (
          <Box key={email.id} className={classes.email}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {email.direction === "outgoing" && (
                <OutgoingEmailHeader email={email} />
              )}
              {email.direction === "incoming" && (
                <IncomingEmailHeader email={email} />
              )}
              <Box>
                <IconButton onClick={(e) => handleReply(email)} size="large">
                  <Reply fontSize="small" />
                </IconButton>
                <IconButton onClick={(e) => handleReplyAll(email)} size="large">
                  <ReplyAll fontSize="small" />
                </IconButton>
              </Box>
            </Box>

            <div>
              {email.attachments.map((attachment: any) => (
                <Chip
                  clickable
                  label={attachment.filename}
                  onClick={(e) => handleAttachmentClick(e, attachment)}
                  className={classes.attachment}
                  key={attachment.id}
                />
              ))}
            </div>
            <EmailBodyIframe html={email.messageHtml} />
            {index !== emailThread.emails.length - 1 && <Divider />}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};
export default EmailThreadView;
