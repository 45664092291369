import React from "react";
import RecipientAutocomplete from "../RecipientAutocomplete";
import SmsThreadView from "./SmsThreadView";
import { Deal } from "../../models/index";

interface SmsThreadContainerProps {
  disableRecipientSelection: boolean;
  recipients: any;
  handleRecipientChange: any;
  smsThreadViewProps: any;
  contactDeals?: Deal[];
}

const SmsThreadContainer = ({
  disableRecipientSelection,
  recipients,
  handleRecipientChange,
  smsThreadViewProps,
  contactDeals,
}: SmsThreadContainerProps) => {
  return (
    <>
      {!disableRecipientSelection && (
        <RecipientAutocomplete
          style={{
            position: "sticky",
            top: 0,
            zIndex: 99,
            background: "white",
            marginBottom: "12px",
          }}
          forSms
          value={recipients || []}
          onChange={handleRecipientChange}
        />
      )}
      <SmsThreadView
        showRecipients={disableRecipientSelection}
        inDialog={true}
        contactDeals={contactDeals}
        {...smsThreadViewProps}
      />
    </>
  );
};

export default SmsThreadContainer;
