import React from "react";
import { Box, Typography } from "@mui/material";
import { Visibility, Check, FiberManualRecord } from "@mui/icons-material";
import moment from "moment";

interface TextProps {
  document?: any;
  documentType?: any;
  isClient?: boolean;
}

const SecondaryText = ({
  document,
  documentType,
  isClient = false,
}: TextProps) => {
  const uploadedByText = () => {
    const uploadedAt = moment(document.createdAt).format("MMM D");
    if (document.uploadedBy?.name) {
      return `Uploaded by ${document.uploadedBy.name} on ${uploadedAt}`;
    } else {
      return `Uploaded on ${uploadedAt}`;
    }
  };

  const portalStatus = (document: any) => {
    if (!document.showInPortal || isClient) {
      return null;
    }
    return (
      <Box style={{ display: "flex", alignItems: "center", marginLeft: "4px" }}>
        <Visibility fontSize="small" style={{ paddingRight: 4 }} />
        <Typography variant="body2" color="textSecondary">
          Visible to clients
        </Typography>
      </Box>
    );
  };

  const documentStatus = (document: any) => {
    if (!document.status || isClient) {
      return null;
    }
    const style = { paddingRight: 4 };
    return (
      <Box style={{ display: "flex", alignItems: "center", marginLeft: "4px" }}>
        {document.status === "completed" ? (
          <Check fontSize="small" style={style} />
        ) : (
          <FiberManualRecord style={{ ...style, fontSize: 12 }} />
        )}
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ textTransform: "capitalize" }}
        >
          {document.status}
        </Typography>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Typography component="span" variant="body2" color="textSecondary">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {document ? (
            <>
              {documentType ? `${document.name} - ` : ""}
              {uploadedByText()}
              {portalStatus(document)}
              {documentStatus(document)}
            </>
          ) : (
            "No File"
          )}
        </Box>
      </Typography>
    </React.Fragment>
  );
};

export default SecondaryText;
