import React, { useEffect, ChangeEvent } from "react";
import { RightSideDrawer } from "../RightSideDrawer";
import { Typography, Box, IconButton, Grid, Paper } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { TextField, TextFieldProps } from "@mui/material";
import { Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TeamMemberSelectMultiple from "../TeamMemberSelectMultiple";
import ZipCodeSelectMultiple from "../ZipCodeSelectMultiple";
import TagAutocomplete from "./TagAutocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CloseRounded } from "@mui/icons-material";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { ToggleButtonGroup, ToggleButton } from "../ToggleButtons";
import moment from "moment";

const StyledAlert = withStyles((theme) => ({
  root: {
    width: "95%",
    borderRadius: "5px",
    margin: "5px auto",
  },
}))(Alert);

interface ContactsFilterProps {
  header?: any;
  filterOpen: boolean;
  onClose: () => void;
  search: any;
  setSearch: (search: any) => void;
  selectedTags: any[];
  setSelectedTags: any;
  selectedAgents: any[];
  setSelectedAgents: any;
  zipcodes: any;
  setZipcodes: any;
  dispatch: any;
  state: any;
  handleFilter: any;
}

const ContactsFilter = ({
  header = "Filter Contacts",
  filterOpen,
  onClose,
  search,
  setSearch,
  selectedAgents,
  setSelectedAgents,
  zipcodes,
  setZipcodes,
  dispatch,
  state,
  handleFilter,
}: ContactsFilterProps) => {
  const { anniversaryDates, birthdayDates } = state;

  const handleAgentSelect = (e: any, selectedAgents: any) => {
    setSelectedAgents(selectedAgents);
  };

  const resetFilter = () => {
    dispatch({ value: "resetFilter" });
    setZipcodes([]);
    setSelectedAgents([]);
    setSearch({
      term: "",
      tags: [],
      owner: null,
    });
  };

  useEffect(() => {
    handleFilter();
  }, [state, zipcodes, search.tags, selectedAgents, handleFilter]);

  return (
    <RightSideDrawer
      anchor="right"
      open={filterOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        style={{
          minHeight: "64px",
          background: "#1A0B31",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
        }}
      >
        <Typography style={{ color: "#fff" }} variant={"h3"}>
          {header}
        </Typography>
        <IconButton color="primary" onClick={onClose} size="large">
          <CloseRounded />
        </IconButton>
      </Box>

      {selectedAgents.length > 0 && (
        <StyledAlert
          icon={false}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSelectedAgents([]);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography variant="caption">
            <b>Selected Agents:</b>{" "}
            {selectedAgents.map((agent) => agent.name).join(", ")}
          </Typography>
        </StyledAlert>
      )}
      {Object.keys(state).map((category) => {
        if (state[category].filterOn) {
          return (
            <StyledAlert
              key={category}
              icon={false}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch({
                      value: "resetCategory",
                      category: category,
                    });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <Typography variant="caption">
                <b>{state[category].renderTitle} Range:</b>{" "}
                {moment(state[category].from).format("MMM Do YYYY")} -{" "}
                {moment(state[category].to).format("MMM Do YYYY")}
              </Typography>
            </StyledAlert>
          );
        } else {
          return "";
        }
      })}

      {zipcodes.length > 0 && (
        <StyledAlert
          icon={false}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setZipcodes([]);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography variant="caption">
            <b>Zipcodes:</b> {zipcodes.join(", ")}
          </Typography>
        </StyledAlert>
      )}
      {search.tags.length > 0 && (
        <StyledAlert
          icon={false}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSearch((search: any) =>
                  Object.assign({}, search, {
                    tags: [],
                  })
                );
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography variant="caption">
            <b>Tags:</b> {search.tags.join(", ")}
          </Typography>
        </StyledAlert>
      )}
      <Box
        p={4}
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Assigned Agent(s)
            </Typography>
            <TeamMemberSelectMultiple
              value={selectedAgents}
              onChange={handleAgentSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Location
            </Typography>
            <ZipCodeSelectMultiple
              value={zipcodes}
              onChange={(zipcodes) => {
                setZipcodes(zipcodes);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Tags
            </Typography>
            <TagAutocomplete
              filterSelectedOptions
              value={search.tags}
              onChange={(tags) =>
                setSearch((search: any) => Object.assign({}, search, { tags }))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: "20px" }}>
              Birthdays
            </Typography>
            <Paper elevation={0}>
              <ToggleButtonGroup
                value={birthdayDates.label}
                exclusive
                fullWidth
                onChange={(e, value) =>
                  dispatch({ category: "birthdayDates", value: value })
                }
                aria-label="Birthday date select"
              >
                <ToggleButton value="today" aria-label="today">
                  Today
                </ToggleButton>
                <ToggleButton value="thisMonth" aria-label="this month">
                  This Month
                </ToggleButton>
                <ToggleButton value="nextMonth" aria-label="next year">
                  Next Month
                </ToggleButton>
                <ToggleButton value="customRange" aria-label="custom range">
                  Custom Range
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
          </Grid>

          {birthdayDates.label === "customRange" && (
            <>
              <Grid container xs={12} lg={6} justifyContent={"center"}>
                <DatePicker
                  label="Start Date"
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} variant="standard" />
                  )}
                  value={birthdayDates.from}
                  onChange={(value: ChangeEvent<any> | null) => {
                    dispatch({
                      category: "birthdayDates",
                      value: "customRange",
                      dates: { from: value },
                    });
                  }}
                />
              </Grid>
              <Grid container justifyContent={"center"} xs={12} lg={6}>
                <DatePicker
                  renderInput={(props: TextFieldProps) => (
                    <TextField {...props} variant="standard" />
                  )}
                  label="End Date"
                  value={birthdayDates.to}
                  onChange={(value: ChangeEvent<any> | null) => {
                    dispatch({
                      category: "birthdayDates",
                      value: "customRange",
                      dates: { to: value },
                    });
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: "20px" }}>
              Anniversaries
            </Typography>
            <Paper elevation={0}>
              <ToggleButtonGroup
                value={anniversaryDates.label}
                exclusive
                fullWidth
                onChange={(e, value) =>
                  dispatch({ category: "anniversaryDates", value: value })
                }
                aria-label="Anniversary date select"
              >
                <ToggleButton value="today" aria-label="today">
                  Today
                </ToggleButton>
                <ToggleButton value="thisMonth" aria-label="this month">
                  This Month
                </ToggleButton>
                <ToggleButton value="nextMonth" aria-label="next month">
                  Next Month
                </ToggleButton>
                <ToggleButton value="customRange" aria-label="custom range">
                  Custom Range
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
          </Grid>

          {anniversaryDates.label === "customRange" && (
            <>
              <Grid container justifyContent={"center"} xs={12} lg={6}>
                <DatePicker
                  renderInput={(props: TextFieldProps) => (
                    <TextField {...props} variant="standard" />
                  )}
                  label="Start Date"
                  value={anniversaryDates.from}
                  onChange={(value: ChangeEvent<any> | null) => {
                    dispatch({
                      category: "anniversaryDates",
                      value: "customRange",
                      dates: { from: value },
                    });
                  }}
                />
              </Grid>
              <Grid container justifyContent={"center"} xs={12} lg={6}>
                <DatePicker
                  renderInput={(props: TextFieldProps) => (
                    <TextField {...props} variant="standard" />
                  )}
                  label="End Date"
                  value={anniversaryDates.to}
                  onChange={(value: ChangeEvent<any> | null) => {
                    dispatch({
                      category: "anniversaryDates",
                      value: "customRange",
                      dates: { to: value },
                    });
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      <Box p={2} style={{ background: "#F5F5F5" }}>
        <Grid container spacing={2} style={{}}>
          <Grid item xs={6}>
            <SecondaryButton
              fullWidth
              variant="outlined"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton
              fullWidth
              variant="outlined"
              onClick={() => {
                resetFilter();
              }}
            >
              Reset Filter
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
    </RightSideDrawer>
  );
};

export default ContactsFilter;
