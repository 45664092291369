import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import {
  Typography,
  IconButton,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  Box,
  CircularProgress,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Add as AddIcon } from "@mui/icons-material";
import { CloudUploadIcon, SettingsIcon } from "../../icons";
import clsx from "clsx";
import DetailCard from "../../components/DetailCard";
import { v4 as uuid } from "uuid";
import { useQuery, useMutation, gql, useApolloClient } from "@apollo/client";
import Skeleton from "@mui/material/Skeleton";
import { useAuth } from "../../context/auth";
import { useAlert } from "../../context/alert";
import { PrimaryButton, SecondaryButton, OutlineButton } from "../buttons";
import { pdfjs } from "react-pdf";
import DocuSignCreateEnvelopeDialog from "../integrations/DocuSignCreateEnvelopeDialog";
import Folder from "./documents/Folder";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { Feature } from "../../reducers/features";
import { Integration } from "../../models";
import DocumentTypeSettings from "../../pages/settings/DocumentTypes/DocumentTypeSettings";
import DocumentListItem from "./documents/DocumentListItem";
import { useUser } from "../../context/user";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dndEmpty: {
      color: theme.palette.grey[500],
      margin: "12px",
      padding: "12px 0",
      display: "flex",
      alignItems: "center",
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      borderRadius: "5px",
      border: `dashed 2px ${theme.palette.grey[200]}`,
    },
    dndMini: {
      margin: "0 12px 8px",
    },
    miniIcon: {
      margin: "0 10px 0 25px",
    },
    activeDnd: {
      backgroundColor: theme.palette.grey[200],
    },
    checkboxes: {
      display: "flex",
      flexDirection: "column",
    },
    dotloopFolderContainer: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
    uploadSelect: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

type DealDocumentsProps = {
  deal: any;
  reportView?: boolean;
  features: Feature;
  availableIntegrations: Integration[];
};

interface DocumentUploadParams {
  sendToDotloop?: boolean;
  sendToDocuSign?: boolean;
  sendToSkySlope?: boolean;
  dotloopFolderId?: string;
  showInPortal?: boolean;
  hasPdf?: boolean;
  documentFolderId?: string;
}

const defaultUploadParams = {
  sendToDotloop: false,
  sendToDocuSign: false,
  sendToSkySlope: false,
  dotloopFolderId: "",
  showInPortal: false,
  hasPdf: false,
  documentFolderId: "",
};

type UpdateDealDocumentInput = {
  id: string;
  documentTypeId?: string;
  documentFolderId?: string | null;
};

const DealDocuments: FunctionComponent<DealDocumentsProps> = ({
  deal,
  reportView = false,
  features,
  availableIntegrations,
}) => {
  const { hasPermission } = useUser();
  const fileInput = useRef<HTMLInputElement>(null);
  const { authToken } = useAuth();
  const classes = useStyles();
  const { showError } = useAlert();
  const apollo = useApolloClient();
  const [addAnchorEl, setAddAnchorEl] = useState<null | any>(null);
  const [unassignedDocuments, setUnassignedDocuments] = useState<Array<any>>(
    []
  );
  const [folders, setFolders] = useState<Array<any>>([]);
  const [newFolder, setNewFolder] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [loadingDotloopFolders, setLoadingDotloopFolders] = useState(false);
  const [dotloopFolders, setDotloopFolders] = useState<any[]>([]);
  const [uploadFormData, setUploadFormData] =
    useState<DocumentUploadParams>(defaultUploadParams);
  const selectedFiles = useRef<Array<File>>();
  const [uploadingFiles, setUploadingFiles] = useState<
    { filename: string; id: string }[]
  >([]);
  const [filesToUpload, setFilesToUpload] = useState<Array<File>>([]);
  const [openDocuSign, setOpenDocuSign] = useState(false);
  const [documentTypeSettingsOpen, setDocumentTypeSettingsOpen] =
    useState(false);
  const [documentTypeId, setDocumentTypeId] = useState<any>(null);
  const [documentTypes, setDocumentTypes] = useState<any[]>([]);
  const [documentTypesToShow, setDocumentTypesToShow] = useState<any[]>([]);

  const integrations: { [key: string]: Integration } = {};
  availableIntegrations.forEach(
    (integration) => (integrations[integration.id] = integration)
  );

  const canEditDocumentChecklist =
    features["Document Types"] && hasPermission("settings", "limited");

  const [updateDocument] = useMutation(gql`
    mutation updateDocument($input: UpdateDocumentInput!) {
      updateDocument(input: $input) {
        id
      }
    }
  `);

  const { data, refetch, loading } = useQuery(
    gql`
      query GetDealDocuments($id: ID!, $dealTypeId: ID!) {
        getDocumentTypes(dealTypeId: $dealTypeId) {
          id
          dealTypeId
          name
          sort
        }
        deal(id: $id) {
          documentChecklist {
            items {
              documentType {
                id
                name
              }
            }
          }
          folders {
            id
            name
            dealId
            createdAt
            updatedAt
            documents {
              id
              documentTypeId
              name
              size
              status
              docusignEnvelopeId
              mimeType
              showInPortal
              createdAt
              uploadedBy {
                ... on Contact {
                  id
                  name
                }
                ... on TeamMember {
                  id
                  name
                }
              }
            }
          }
          documents(excludeInFolders: true) {
            id
            documentTypeId
            name
            size
            status
            docusignEnvelopeId
            mimeType
            showInPortal
            createdAt
            uploadedBy {
              ... on Contact {
                id
                name
              }
              ... on TeamMember {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: deal.id,
        dealTypeId: deal.dealType.id,
      },
    }
  );

  useEffect(() => {
    if (!loading && !documentTypeSettingsOpen) {
      refetch();
    }
  }, [refetch, documentTypeSettingsOpen, loading]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const documents: any[] = data.deal.documents;

    // Map each document type to a document (if it exists)
    const documentMap = new Map<string, any>();
    documents.forEach((document) => {
      if (document.documentTypeId) {
        documentMap.set(document.documentTypeId, document);
      }
    });

    // Associate documents with their document types
    const documentTypes: any[] = data.getDocumentTypes.map(
      (documentType: any) => {
        const document = documentMap.get(documentType.id);
        return {
          ...documentType,
          document,
        };
      }
    );

    // Set all available document types
    setDocumentTypes(documentTypes);

    /**
     * Now we'll determine which document types need to be shown for this deal.
     * We'll start with the document types that are already associated with
     * the deal (part of the document checklists) and then we'll add any remaining
     * document types that are not associated with a document.
     */
    const documentTypesToShow: any[] = [];
    const documentTypeIdsToShow = new Set<string>();
    const documentTypeMap = new Map<string, any>();
    documentTypes.forEach((documentType) => {
      documentTypeMap.set(documentType.id, documentType);
    });

    // Add the checklist items
    (data.deal.documentChecklist.items as any[]).forEach((checklistItem) => {
      const documentType = documentTypeMap.get(checklistItem.documentType.id);
      if (documentType) {
        documentTypesToShow.push(documentType);
        documentTypeIdsToShow.add(documentType.id);
      }
    });
    // Add any remaining document types that have a document
    documentTypesToShow.push(
      ...documentTypes.filter((documentType) => {
        return (
          documentType.document && !documentTypeIdsToShow.has(documentType.id)
        );
      })
    );

    setDocumentTypesToShow(documentTypesToShow);
    setFolders(data.deal.folders);
    setUnassignedDocuments(
      documents.filter((document) => !document.documentTypeId)
    );
  }, [data, open]);

  const emptyDocumentTypes = useMemo(() => {
    return documentTypes.filter((documentType) => !documentType.document);
  }, [documentTypes]);

  const handleDrop = (
    item: any,
    documentFolderId?: string,
    destinationType?: "folder" | "documentType"
  ) => {
    if (item.files) {
      handleFileDrop(item.files, documentFolderId, destinationType);
    } else {
      handleDocumentDrop(item, documentFolderId, destinationType);
    }
  };

  const [collectedProps, drop] = useDrop(() => ({
    accept: ["document", NativeTypes.FILE],
    drop: (item: any, monitor) => {
      if (monitor.didDrop()) {
        // A nested dropzone (a Folder component) already handled this drop.
        // We'll ignore this one.
        return;
      }
      handleDrop(item);
    },
    canDrop: () => {
      return !reportView;
    },
    collect: (monitor) => {
      return { isOver: monitor.isOver({ shallow: true }) };
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setUploadFormData(defaultUploadParams);
    setDocumentTypeId(null);
  };

  const handleUpload = (e: any, sendToIntegration: boolean = false) => {
    if (!selectedFiles.current) {
      performUpload(filesToUpload, uploadFormData);
      setOpen(false);
      setUploadFormData(defaultUploadParams);
      setDocumentTypeId(null);
    } else {
      const data: any = {};
      if (deal.docusignRoomId && sendToIntegration) {
        data.sendToDocuSign = "1";
      } else if (deal.dotloopLoopUrl && sendToIntegration) {
        data.sendToDotloop = "1";
      }

      performUpload(
        selectedFiles.current,
        Object.assign({}, uploadFormData, data)
      );
      setOpen(false);
      setUploadFormData(defaultUploadParams);
      setDocumentTypeId(null);
    }
  };

  const performUpload = (
    files: Array<File>,
    uploadParams: DocumentUploadParams = {}
  ) => {
    const filenames = files.map((file) => ({
      filename: file.name,
      id: uuid(),
    }));
    setUploadingFiles((uploadingFiles) => [...uploadingFiles, ...filenames]);
    const formData = new FormData();
    formData.append("dealId", deal.id);
    if (documentTypeId) formData.append("documentTypeId", documentTypeId);
    if (uploadParams.sendToDocuSign) {
      formData.append("sendToDocuSign", "1");
    }
    if (uploadParams.sendToDotloop) {
      formData.append("sendToDotloop", "1");
    }
    if (uploadParams.dotloopFolderId) {
      formData.append("dotloopFolderId", uploadParams.dotloopFolderId);
    }
    if (uploadParams.showInPortal) {
      formData.append("showInPortal", "1");
    }
    if (uploadParams.documentFolderId) {
      formData.append("documentFolderId", uploadParams.documentFolderId);
    }
    files.forEach((file) => {
      formData.append("dealDocuments", file);
    });

    fetch(`${process.env.REACT_APP_API_URL}/dealDocuments`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {
        showError("There was an error uploading the documents");
      })
      .finally(() => {
        selectedFiles.current = undefined;
        setUploadingFiles((uploadingFiles) =>
          uploadingFiles.filter(
            (uploadingFile) => !filenames.includes(uploadingFile)
          )
        );
      });
  };

  const handleDocumentDrop = (
    item: any,
    destinationId?: any,
    destinationType?: "folder" | "documentType"
  ) => {
    const input: UpdateDealDocumentInput = {
      id: item.id,
    };

    if (destinationType === "folder") {
      input.documentFolderId = destinationId;
    } else if (destinationType === "documentType") {
      input.documentTypeId = destinationId;
      input.documentFolderId = null;
    } else {
      input.documentFolderId = null;
    }

    updateDocument({
      variables: {
        input,
      },
    })
      .then(() => {
        refetch();
      })
      .catch(() => {
        showError("An error occurred when moving the document");
      });
  };

  const handleFileDrop = (
    files: Array<File>,
    destinationId?: string,
    destinationType?: "folder" | "documentType"
  ) => {
    if (!files.length) {
      return;
    }
    const hasPdf =
      files.find((file) => file.type === "application/pdf") !== undefined;

    if (destinationType === "folder" || !destinationId) {
      setUploadFormData((uploadFormData) =>
        Object.assign({}, uploadFormData, {
          hasPdf,
          documentFolderId: destinationId,
        })
      );
    } else {
      setDocumentTypeId(destinationId);
    }

    const showIntegrationDialog =
      deal.docusignRoomId || (deal.dotloopLoopUrl && hasPdf);

    if (showIntegrationDialog) {
      selectedFiles.current = files;
      if (deal.dotloopLoopUrl) {
        // Load Dotloop folders
        setLoadingDotloopFolders(true);
        const dotloopFolderQuery = gql`
          query GetDotloopFolders($id: ID!) {
            deal(id: $id) {
              dotloopFolders {
                id
                name
              }
            }
          }
        `;
        apollo
          .query({
            query: dotloopFolderQuery,
            variables: {
              id: deal.id,
            },
          })
          .then((res) => {
            setDotloopFolders(res.data.deal.dotloopFolders);
          })
          .finally(() => {
            setLoadingDotloopFolders(false);
          });
      }
    }
    setOpen(true);
    setFilesToUpload(files);
  };

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadFormData({
      ...uploadFormData,
      [e?.target.name]: e?.target.checked,
    });
  };

  const createFolderPlaceholder = () => {
    setNewFolder(true);
    setAddAnchorEl(null);
  };

  const handleUploadForDocType = (docTypeId: string) => {
    fileInput.current?.click();
    setAddAnchorEl(null);
    setDocumentTypeId(docTypeId);
  };

  const EmptyState = () => {
    return (
      <>
        <div
          className={clsx(classes.dndEmpty, {
            [classes.activeDnd]: collectedProps.isOver,
          })}
          style={{
            flexDirection: "column",
          }}
        >
          <CloudUploadIcon size="52px" />
          <Typography>Drag &amp; drop documents here</Typography>
        </div>
      </>
    );
  };

  return (
    <>
      <DetailCard
        title={
          <Typography variant="h4">
            {documentTypeSettingsOpen
              ? "Configure the Document Checklist"
              : "Documents"}
          </Typography>
        }
        action={
          !reportView && (
            <>
              {!documentTypeSettingsOpen && (
                <IconButton
                  onClick={(e: any) => setAddAnchorEl(e.currentTarget)}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              )}

              {canEditDocumentChecklist && (
                <OutlineButton
                  onClick={() =>
                    setDocumentTypeSettingsOpen(!documentTypeSettingsOpen)
                  }
                >
                  {!documentTypeSettingsOpen ? (
                    <>
                      <SettingsIcon style={{ marginRight: "3px" }} /> Configure
                    </>
                  ) : (
                    <>View Documents</>
                  )}
                </OutlineButton>
              )}
              <Menu
                id=""
                anchorEl={addAnchorEl}
                keepMounted
                open={Boolean(addAnchorEl)}
                onClose={() => setAddAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    fileInput.current?.click();
                    setAddAnchorEl(null);
                  }}
                >
                  Add File
                </MenuItem>
                <MenuItem onClick={() => createFolderPlaceholder()}>
                  Add Folder
                </MenuItem>
                {integrations.docusign?.added && (
                  <MenuItem
                    onClick={() => {
                      setOpenDocuSign(true);
                      setAddAnchorEl(null);
                    }}
                  >
                    Send DocuSign Envelope
                  </MenuItem>
                )}
              </Menu>
            </>
          )
        }
      >
        <div style={{ width: "100%" }} ref={drop}>
          <input
            type="file"
            id="file"
            ref={fileInput}
            onChange={(e) => {
              if (e.target.files) {
                handleFileDrop(Array.from(e.target.files));
              }
            }}
            style={{ display: "none" }}
          />
          {documentTypeSettingsOpen && (
            <Box
              style={{
                padding: 21,
                margin: "0 auto 21px",
                width: "100%",
                maxWidth: "1200px",
              }}
            >
              <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
                <span style={{ fontWeight: 600 }}>Note:</span> These document
                types will only be added to this deal's checklist
              </Typography>
              {!loading && <DocumentTypeSettings deal={deal} />}
            </Box>
          )}
          {loading && <Skeleton style={{ width: "100%", height: 200 }} />}
          {!loading && !documentTypeSettingsOpen && (
            <List>
              {documentTypesToShow.map((documentType: any) => (
                <DocumentListItem
                  key={documentType.id}
                  isDraggableDocument={false}
                  documentType={documentType}
                  document={documentType.document}
                  refetch={refetch}
                  handleOpenUpload={handleUploadForDocType}
                  reportView={reportView}
                  onDrop={handleDrop}
                />
              ))}

              {unassignedDocuments.map((document) => (
                <DocumentListItem
                  key={document.id}
                  isDraggableDocument={true}
                  document={document}
                  refetch={refetch}
                  reportView={reportView}
                />
              ))}
              <div
                style={{
                  opacity: collectedProps.isOver ? "0.5" : "1",
                }}
              >
                {newFolder && (
                  <Folder
                    dragAndDrop={!reportView}
                    folderProp={null}
                    newFolder={newFolder}
                    setNewFolder={setNewFolder}
                    dealId={deal.id}
                    refetch={refetch}
                    onDrop={() => {}}
                  />
                )}
                {folders.map((folder) => (
                  <Folder
                    dealId={deal.id}
                    dragAndDrop={!reportView}
                    key={folder.id}
                    folderProp={folder}
                    refetch={refetch}
                    reportView={reportView}
                    onDrop={handleDrop}
                    otherFolders={folders.filter(
                      (element) => element.id !== folder.id
                    )}
                  />
                ))}
              </div>

              {uploadingFiles.map(({ filename, id }) => (
                <ListItem key={id}>
                  <ListItemText primary={filename} />
                  <ListItemSecondaryAction>
                    <div style={{ padding: "12px" }}>
                      <CircularProgress size={24} />
                    </div>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
          {!loading && !reportView && !documentTypeSettingsOpen && EmptyState()}
        </div>
      </DetailCard>

      <DocuSignCreateEnvelopeDialog
        open={openDocuSign}
        onClose={() => setOpenDocuSign(false)}
        deal={deal}
        onEnvelopeCreated={() => {
          setOpenDocuSign(false);
          refetch();
        }}
      />

      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        onClose={(e) => handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Document Upload Options
        </DialogTitle>
        <DialogContent className={classes.checkboxes}>
          {folders.length > 0 && !documentTypeId && (
            <FormControl variant="outlined" className={classes.uploadSelect}>
              <InputLabel id="add-to-folder">Add to Folder</InputLabel>
              <Select
                labelId="add-to-folder"
                id="demo-simple-select-outlined"
                value={uploadFormData.documentFolderId}
                onChange={(e: any) =>
                  setUploadFormData({
                    ...uploadFormData,
                    documentFolderId: e?.target.value,
                  })
                }
                label="Add to Folder"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {folders.map((folder) => (
                  <MenuItem key={folder.id} value={folder.id}>
                    {folder.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {emptyDocumentTypes.length > 0 && !uploadFormData.documentFolderId && (
            <FormControl className={classes.uploadSelect} variant="outlined">
              <InputLabel>Select Document Type</InputLabel>
              <Select
                label-id="document-type-select"
                value={documentTypeId}
                onChange={(e: any) =>
                  e.target.value === ""
                    ? setDocumentTypeId(null)
                    : setDocumentTypeId(e.target.value)
                }
                label="Select Document Type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {emptyDocumentTypes.length > 0 ? (
                  emptyDocumentTypes.map((documentType: any) => {
                    return (
                      <MenuItem key={documentType.id} value={documentType.id}>
                        {documentType.name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={uploadFormData.showInPortal}
                  onChange={handleCheckChange}
                  name="showInPortal"
                />
              }
              label="Show in Client Portal"
            />
          </FormControl>

          {deal.skyslopeUrl && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={uploadFormData.sendToSkySlope}
                  onChange={handleCheckChange}
                  name="sendToSkySlope"
                />
              }
              label="Send to SkySlope"
            />
          )}

          {deal.docusignRoomUrl && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={uploadFormData.sendToDocuSign}
                  onChange={handleCheckChange}
                  name="sendToDocuSign"
                />
              }
              label="Send to DocuSign Room"
            />
          )}
          {deal.dotloopLoopUrl && uploadFormData.hasPdf && (
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={uploadFormData.sendToDotloop}
                    onChange={handleCheckChange}
                    name="sendToDotloop"
                  />
                }
                label="Send to Dotloop"
              />
            </FormControl>
          )}

          {deal.dotloopLoopUrl && uploadFormData.sendToDotloop && (
            <Box className={classes.dotloopFolderContainer}>
              {loadingDotloopFolders && <Skeleton variant="rectangular" />}
              {!loadingDotloopFolders && (
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="dotloopFolder">
                    Dotloop Folder
                  </InputLabel>
                  <Select
                    native
                    inputProps={{ id: "dotloopFolder" }}
                    onChange={(e) => {
                      const element = e.currentTarget as HTMLInputElement;
                      const value = element.value;
                      setUploadFormData((uploadFormData) =>
                        Object.assign({}, uploadFormData, {
                          dotloopFolderId: value,
                        })
                      );
                    }}
                  >
                    <option></option>
                    {dotloopFolders.map((folder) => (
                      <option key={folder.id} value={folder.id}>
                        {folder.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={
              uploadFormData.sendToDotloop && !uploadFormData.dotloopFolderId
            }
            onClick={(e: any) =>
              handleUpload(
                e,
                Boolean(
                  uploadFormData.sendToDocuSign || uploadFormData.sendToDotloop
                )
              )
            }
            color="primary"
            autoFocus
          >
            Upload Document
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  features,
  availableIntegrations,
}: {
  features: Feature;
  availableIntegrations: Integration[];
}) => {
  return {
    features,
    availableIntegrations,
  };
};
export default connect(mapStateToProps)(DealDocuments);
