import React, { useReducer, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsSubNav from "../components/SettingsSubNav";
import SplitLayout from "../layouts/SplitLayout";
import LeftPane from "../components/LeftPane";
import DetailPane from "../components/DetailPane";
import UserInviteDialog from "../components/users/UserInviteDialog";
import moment from "moment-timezone";
import SettingsSelect from "../components/SettingsSelectMobile";
import { Tag } from "../models";
import {
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Theme,
  Typography,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ToggleButtonGroup, ToggleButton } from "../components/ToggleButtons";
import { SecondaryButton, PrimaryButton } from "../components/buttons";
import {
  Add as AddIcon,
  Replay,
  Email as EmailIcon,
  Web as WebIcon,
} from "@mui/icons-material";
import { useMutation, useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import {
  PencilIcon,
  TrashIcon as DeleteIcon,
  UserAddIcon,
  CircleCheckIcon,
} from "../icons";
import { useAlert } from "../context/alert";
import StyledChip from "../components/vendors/StyledChip";
import { refreshVendorTags } from "../actions/vendorTags";
import ContactNameWithAvatar from "../components/contacts/ContactNameWithAvatar";
import PageTitle from "../components/PageTitle";
import { ApiClient, useApi } from "../context/api";
import TeamMemberSelect from "../components/TeamMemberSelect";
import { Skeleton } from "@mui/material";
import CreateOrEditTeamMemberDialog from "../components/CreateOrEditTeamMemberDialog";
import { SEND_INVITE } from "../api/graphql";
import CopyToClipboard from "react-copy-to-clipboard";
import { useUser } from "../context/user";

const pageHeaderHeight = 94;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageLayout: {
      flexGrow: 1,
      overflowX: "hidden",
      display: "flex",
      flexDirection: "row",
      marginBottom: "0",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
    },
    pageHeader: {
      padding: "40px 40px 30px 40px",
      height: `${pageHeaderHeight}px`,
      display: "flex",
    },
    tableHeader: {
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "14px",
      color: "rgb(107, 114, 128)",
    },
    hideText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    reassignmentSelect: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    longUrl: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

const GET_TEAM_MEMBER = gql`
  query getTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      name
      id
      contactCount
    }
  }
`;

const Team = ({
  refreshVendorTags,
}: {
  refreshVendorTags: (apolloClient: ApiClient) => Promise<void>;
}): JSX.Element => {
  const GET_TEAM_MEMBERS = React.useMemo(() => {
    return gql`
      query GetTeamMembers {
        getTeamMembers {
          id
          name
          firstName
          lastName
          email
          phone
          title
          company
          avatarUrl
          preferred
          websiteUrl
          address {
            street
            street2
            city
            state
            zip
          }
          invite {
            id
            createdAt
          }
          user {
            id
            permissions {
              settings
              contacts
              deals
              reports
            }
          }
          tags {
            id
            name
            color
          }
        }
      }
    `;
  }, []);

  const DELETE_TEAM_MEMBER = gql`
    mutation deleteTeamMember($input: DeleteTeamMemberInput) {
      deleteTeamMember(input: $input)
    }
  `;

  const initialState: any = {
    teamMembers: [],
    teamMember: {},
    vendors: [],
    vendor: {},
  };

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "SET_TEAM_MEMBERS":
        return Object.assign({}, state, { teamMembers: action.teamMembers });
      case "SET_TEAM_MEMBER":
        return Object.assign({}, state, { teamMember: action.teamMember });
      case "DELETE_TEAM_MEMBER":
        return Object.assign({}, state, {
          teamMembers: state.teamMembers.filter(
            (teamMember: any) => teamMember.id !== action.teamMember.id
          ),
        });
      case "UPDATE_TEAM_MEMBER":
        return Object.assign({}, state, {
          teamMembers: state.teamMembers.map((teamMember: any) => {
            if (action.teamMember.id === teamMember.id) {
              return action.teamMember;
            } else {
              return teamMember;
            }
          }),
        });
      case "INVITE_TEAM_MEMBER":
      default:
        return state;
    }
  };

  const { apolloClient } = useApi();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermission } = useUser();

  // Get the default type
  let defaultType: "team" | "vendors" = "team";
  if (location.hash === "#team" || location.hash === "#vendors") {
    defaultType = location.hash.substr(1) as "team" | "vendors";
  }
  const [type, setType] = useState<"team" | "vendors">(defaultType);

  const [open, setOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [inviteTeamMember, setInviteTeamMember] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showSuccess, showError } = useAlert();
  const [getTeamMembers, { data }] = useLazyQuery(GET_TEAM_MEMBERS);
  const [reassignToTeamMemberId, setReassignToTeamMemberId] =
    useState(undefined);
  const [contactsActionOnDelete, setContactsActionOnDelete] =
    useState("unassign");

  const canEditTeamMembers = useCallback(
    () => hasPermission("settings", "full"),
    [hasPermission]
  );
  const canEditVendors = useCallback(
    () => hasPermission("settings", "none"),
    [hasPermission]
  );
  const canEditCurrentType = useCallback(
    () => (type === "team" ? canEditTeamMembers() : canEditVendors()),
    [type, canEditTeamMembers, canEditVendors]
  );

  useEffect(() => {
    getTeamMembers();
  }, [type, getTeamMembers]);

  useEffect(() => {
    if (!data) {
      return;
    }
    let teamMembers: Array<any> = [];
    if (type === "team") {
      teamMembers = data.getTeamMembers.filter(
        (tm: any) => tm.user || tm.invite
      );
    } else if (type === "vendors") {
      teamMembers = data.getTeamMembers.filter(
        (tm: any) => !tm.user && !tm.invite
      );
    }
    dispatch({
      type: "SET_TEAM_MEMBERS",
      teamMembers,
    });
  }, [data, type]);

  const [
    getTeamMember,
    { loading: loadingTeamMember, data: contactCountData },
  ] = useLazyQuery(GET_TEAM_MEMBER);

  useEffect(() => {
    if (deleteOpen && state.teamMember.id) {
      getTeamMember({
        variables: { id: state.teamMember.id },
      });
    }
  }, [state.teamMember.id, deleteOpen, getTeamMember]);

  useEffect(() => {
    if (contactCountData && state.teamMember.contactCount === undefined) {
      if (contactCountData?.getTeamMember?.id === state.teamMember.id) {
        dispatch({
          type: "SET_TEAM_MEMBER",
          teamMember: Object.assign({}, state.teamMember, {
            contactCount: contactCountData.getTeamMember.contactCount,
          }),
        });
      }
    }
  }, [contactCountData, state.teamMember]);

  const [deleteTeamMember] = useMutation(DELETE_TEAM_MEMBER);

  // Handle Toggle
  const handleTypeChange = (type: "team" | "vendors") => {
    navigate("/settings/team#" + type);
    setType(type);
  };

  const handleTeamMemberClick = (e: any, teamMember: any) => {
    dispatch({
      type: "SET_TEAM_MEMBER",
      teamMember,
    });
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    dispatch({
      type: "SET_TEAM_MEMBER",
      teamMember: {},
    });
  };

  const handleAddClick = (e: any) => {
    dispatch({
      type: "SET_TEAM_MEMBER",
      teamMember: {
        firstName: "",
        lastName: "",
        title: "",
        company: "",
        email: "",
        preferred: false,
        address: {
          street: "",
          street2: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    });
    setOpen(true);
  };

  const handleEditClick = (e: any, teamMember: any) => {
    dispatch({
      type: "SET_TEAM_MEMBER",
      teamMember,
    });
    setOpen(true);
  };

  const handleInviteClick = (e: any, teamMember: any) => {
    dispatch({
      type: "INVITE_TEAM_MEMBER",
      teamMember,
    });
    setInviteTeamMember(teamMember);
    setInviteOpen(true);
  };

  const onInviteClose = () => {
    setInviteOpen(false);
  };

  const handleSave = () => {
    getTeamMembers();
    refreshVendorTags(apolloClient);
  };

  const handleDeleteClick = (e: any, teamMember: any) => {
    dispatch({
      type: "SET_TEAM_MEMBER",
      teamMember,
    });

    setDeleteOpen(true);
  };

  const handleAssigneeChange = (teamMember: any) => {
    setReassignToTeamMemberId(teamMember.id);
  };

  const handleDelete = (value: any, teamMember: any) => {
    const input: {
      id: string;
      deleteContacts?: boolean;
      reassignToTeamMemberId?: string;
    } = {
      id: state.teamMember.id,
    };

    let successMessage: string = `The ${
      type === "team" ? "team member" : "vendor"
    } "${state.teamMember.name}" was deleted`;
    if (state.teamMember.contactCount && value === "re-assign") {
      input.reassignToTeamMemberId = reassignToTeamMemberId;
      successMessage += " and their contacts re-assigned";
    } else if (state.teamMember.contactCount && value === "delete") {
      input.deleteContacts = true;
      successMessage += " and their contacts deleted";
    } else {
      if (state.teamMember.contactCount) {
        successMessage += " and their contacts unassigned";
      }
    }

    deleteTeamMember({ variables: { input } })
      .then(() => {
        showSuccess(successMessage);
        dispatch({
          type: "DELETE_TEAM_MEMBER",
          teamMember,
        });
      })
      .catch(() => {
        showError("An error occurred while deleting the team member");
      })
      .finally(() => {
        setDeleteOpen(false);
      });
  };

  const teamMemberTitle = (teamMember: any): string | undefined => {
    if (type === "vendors") {
      if (teamMember.title && !teamMember.company) {
        return teamMember.title;
      } else if (teamMember.company && !teamMember.title) {
        return teamMember.company;
      } else if (teamMember.company && teamMember.title) {
        return teamMember.title + " - " + teamMember.company;
      }
    }
    if (type === "team" && teamMember.invite) {
      return "Invite sent " + moment(teamMember.invite.createdAt).fromNow();
    }
  };

  const handleResendInviteClick = (e: any, teamMember: any) => {
    apolloClient
      .mutate({
        mutation: SEND_INVITE,
        variables: {
          input: {
            teamMemberId: teamMember.id,
          },
        },
      })
      .then(() => {
        showSuccess(`An invite was resent to ${teamMember.name}`);
        teamMember.invite.createdAt = new Date();
        dispatch({
          type: "UPDATE_TEAM_MEMBER",
          teamMember,
        });
      })
      .catch(() => {
        showError(
          `An error occurred when sending an invite to ${teamMember.name}`
        );
      });
  };

  return (
    <SplitLayout>
      <LeftPane width={224}>
        <SettingsSubNav />
        <SettingsSelect label="" defaultValue="/settings/team" />
      </LeftPane>
      <DetailPane>
        <Grid container className={classes.pageLayout} spacing={2}>
          <Grid item xs={12}>
            <PageTitle
              title="Collaborators"
              subtitle="Create new users or vendors, customize user permissions, and remove users from your account."
            />
          </Grid>

          {state.teamMember.length === 0 && canEditTeamMembers() && (
            <Grid item xs={12}>
              <p>
                Team members can be assigned roles on deals. To get started,
                create a team member:
              </p>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddClick}
              >
                Add a Team Member
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ToggleButtonGroup
                value={type}
                exclusive
                onChange={(e, v) => handleTypeChange(v)}
                buttonWidth="80px"
              >
                <ToggleButton value="team">Team</ToggleButton>
                <ToggleButton value="vendors">Vendors</ToggleButton>
              </ToggleButtonGroup>

              <div style={{ flexGrow: 1 }}></div>

              <PrimaryButton
                height="short"
                onClick={handleAddClick}
                disabled={!canEditCurrentType()}
              >
                <AddIcon
                  style={{
                    width: "13px",
                    height: "13px",
                    marginRight: "4px",
                  }}
                />
                <span className={classes.hideText}>
                  New {type === "team" ? "Team Member" : "Vendor"}
                </span>
              </PrimaryButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    {type === "team" && <TableCell>Email</TableCell>}
                    {type === "vendors" && (
                      <>
                        <TableCell>Contact Info</TableCell>
                        <TableCell
                          sx={{ whiteSpace: "nowrap", textAlign: "center" }}
                        >
                          Shown in Portal
                        </TableCell>
                        <TableCell>Services</TableCell>
                      </>
                    )}
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.teamMembers.map((teamMember: any) => (
                    <TableRow
                      key={teamMember.id}
                      onClick={(e) => handleTeamMemberClick(e, teamMember)}
                    >
                      <TableCell>
                        <ContactNameWithAvatar
                          name={teamMember.name}
                          title={teamMemberTitle(teamMember)}
                          avatarUrls={[teamMember.avatarUrl]}
                          avatarStyle={{
                            width: "32px",
                            height: "32px",
                          }}
                        />
                      </TableCell>
                      {type === "team" && (
                        <TableCell>{teamMember.email ?? "-"}</TableCell>
                      )}
                      {type === "vendors" && (
                        <>
                          <TableCell>
                            {teamMember.email && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <EmailIcon
                                  fontSize="small"
                                  sx={{ color: " #656160", marginRight: "6px" }}
                                />{" "}
                                {teamMember.email}
                              </Box>
                            )}
                            {teamMember.websiteUrl && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <WebIcon
                                  fontSize="small"
                                  sx={{ color: " #656160", marginRight: "6px" }}
                                />{" "}
                                {teamMember.websiteUrl.length > 40 ? (
                                  <CopyToClipboard
                                    text={teamMember.websiteUrl}
                                    onCopy={() => showSuccess("URL Copied!")}
                                  >
                                    <span className={classes.longUrl}>
                                      {teamMember.websiteUrl.slice(0, 40)}
                                      &hellip;
                                    </span>
                                  </CopyToClipboard>
                                ) : (
                                  teamMember.websiteUrl
                                )}
                              </Box>
                            )}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {teamMember.preferred ? (
                              <CircleCheckIcon
                                size="18px"
                                style={{ color: "#0B8D9B" }}
                              />
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell sx={{ maxWidth: "150px" }}>
                            {teamMember.tags.length
                              ? teamMember.tags.map((tag: Tag) => (
                                  <StyledChip
                                    key={tag.id}
                                    label={tag.name}
                                    variant={"outlined"}
                                    size={"small"}
                                    colorhex={tag.color}
                                    style={{
                                      margin: "10px 3px",
                                    }}
                                  />
                                ))
                              : "-"}
                          </TableCell>
                        </>
                      )}

                      <TableCell align="right">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {teamMember.user ||
                          teamMember.invite ||
                          !canEditTeamMembers() ? (
                            <></>
                          ) : (
                            <Tooltip
                              title="Invite"
                              aria-label="invite"
                              className="table-row-action"
                            >
                              <IconButton
                                onClick={(e) =>
                                  handleInviteClick(e, teamMember)
                                }
                                size="large"
                              >
                                <UserAddIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          {teamMember.invite && canEditTeamMembers() && (
                            <Tooltip
                              title="Resend invite"
                              aria-label="Resend invite"
                              className="table-row-action"
                            >
                              <IconButton
                                disabled={!teamMember.email}
                                onClick={(e) =>
                                  handleResendInviteClick(e, teamMember)
                                }
                                size="large"
                              >
                                <Replay />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Tooltip
                            title="Edit"
                            aria-label="edit"
                            className="table-row-action"
                          >
                            <IconButton
                              onClick={(e) => handleEditClick(e, teamMember)}
                              disabled={!canEditCurrentType()}
                              size="large"
                            >
                              <PencilIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Delete"
                            aria-label="delete"
                            className="table-row-action"
                          >
                            <IconButton
                              onClick={(e) => {
                                handleDeleteClick(e, teamMember);
                              }}
                              disabled={!canEditCurrentType()}
                              size="large"
                            >
                              <DeleteIcon size="20px" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DetailPane>
      <CreateOrEditTeamMemberDialog
        open={open}
        onClose={() => setOpen(false)}
        onSave={handleSave}
        teamMember={state.teamMember}
        type={type}
      />
      <UserInviteDialog
        open={inviteOpen}
        invitee={inviteTeamMember}
        teamMemberOrContact={"teamMember"}
        onClose={onInviteClose}
      />
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Delete {state.teamMember.name}?</DialogTitle>
        <DialogContent>
          {loadingTeamMember && <Skeleton />}
          {!loadingTeamMember && state.teamMember.contactCount !== 0 && (
            <div>
              <Typography>
                {state.teamMember.name} has {state.teamMember.contactCount}{" "}
                {state.teamMember.contactCount === 1 ? `contact` : `contacts`}{" "}
                assigned. How should we handle them?
              </Typography>
              <br />
              <FormControl component="fieldset" fullWidth variant="standard">
                <RadioGroup
                  defaultValue="unassign"
                  aria-label="handleContacts"
                  onChange={(e: any) => {
                    setContactsActionOnDelete(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="unassign"
                    control={<Radio />}
                    label="Unassign the contacts"
                  />
                  <div className={classes.reassignmentSelect}>
                    <FormControlLabel
                      style={{ minWidth: "220px" }}
                      value="re-assign"
                      control={<Radio />}
                      label="Re-assign the contacts to:"
                    />
                    <TeamMemberSelect
                      disabled={contactsActionOnDelete !== "re-assign"}
                      onChange={handleAssigneeChange}
                      value={reassignToTeamMemberId}
                    />
                  </div>
                  <FormControlLabel
                    value="delete"
                    control={<Radio />}
                    label="Delete the contacts"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          )}
          {!loadingTeamMember && state.teamMember.contactCount === 0 && (
            <div>
              <Typography>
                Are you sure you want to delete {state.teamMember.name}?
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleDeleteClose}>Cancel</SecondaryButton>
          {state.teamMember.contactCount !== 0 && (
            <PrimaryButton
              disabled={loadingTeamMember}
              onClick={(e: any) =>
                handleDelete(contactsActionOnDelete, state.teamMember)
              }
            >
              {`Delete ${type === "team" ? "Team Member" : "Vendor"}`}
            </PrimaryButton>
          )}
          {state.teamMember.contactCount === 0 && (
            <PrimaryButton
              disabled={loadingTeamMember}
              onClick={(e: any) => handleDelete("delete", state.teamMember)}
            >
              {`Delete ${type === "team" ? "Team Member" : "Vendor"}`}
            </PrimaryButton>
          )}
        </DialogActions>
      </Dialog>
    </SplitLayout>
  );
};

export default connect(null, { refreshVendorTags })(Team);
