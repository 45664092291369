import React from "react";
import { Route } from "react-router";
import WorkflowListPage from "../pages/workflows/List";
import WorkflowEditPage from "../pages/workflows/Edit";
import WorkflowContactsPage from "../pages/workflows/Contacts";
import { Feature } from "../reducers/features";

const routes = (currentUser: any, features: Feature) => {
  if (currentUser && features.Workflows) {
    return [
      <Route
        key="/settings/workflows"
        path={"/settings/workflows"}
        element={<WorkflowListPage />}
      />,
      <Route
        key="/settings/workflows/:id"
        path={"/settings/workflows/:id"}
        element={<WorkflowEditPage />}
      />,
      <Route
        key="/settings/workflows/:id/enrollees"
        path={"/settings/workflows/:id/enrollees"}
        element={<WorkflowContactsPage />}
      />,
    ];
  } else {
    return [];
  }
};

export default routes;
