import React, { useEffect, useMemo, useState } from "react";
import { Typography, Theme, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Alert, AlertTitle } from "@mui/material";
import Documents from "./client-timeline/Documents";
import TimelineList from "./TimelineList";
import DetailCard from "../DetailCard";
import TaskList from "../tasks/List";
import { gql, useMutation } from "@apollo/client";
import { WhiteButton } from "../buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";

import { useLocation } from "react-router";
import QueryString from "qs";
import { useAlert } from "../../context/alert";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createDragDropManager } from "dnd-core";

import { ToggleButton, ToggleButtonGroup } from "../ToggleButtons";

export const manager = createDragDropManager(HTML5Backend);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    header: {
      marginBottom: theme.spacing(3),

      "& .MuiTypography-subtitle1": {
        fontSize: "14px",
      },
    },
    stages: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(3),
    },
  })
);

const StyledAlert = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 25,
    },
    filledInfo: {
      backgroundColor: theme.palette.primary.main,
    },
  })
)(Alert);

type ClientTimelineProps = {
  stages: any;
  deal: any;
  onTaskUpdated?: () => void;
  refetch?: Function;
};

const ClientTimeline = ({
  stages,
  deal,
  onTaskUpdated,
  refetch,
}: ClientTimelineProps) => {
  const classes = useStyles();
  const location = useLocation();
  const search = QueryString.parse(location.search.substr(1));
  const { showSuccess } = useAlert();
  const [showDocuSignEnvelopes] = useState(search.event === undefined);
  const [showSigningSuccess] = useState(search.event === "signing_complete");

  const [taskFilter, setTaskFilter] = useState<
    "assignedToMe" | "assignedToOthers"
  >("assignedToMe");
  const [createDocuSignRecipientView] = useMutation(gql`
    mutation CreateDocuSignRecipientView(
      $input: CreateDocuSignRecipientViewInput!
    ) {
      createDocuSignRecipientView(input: $input)
    }
  `);

  const renderHeader = () => {
    return (
      <div className={classes.header}>
        <Typography variant="h3">{deal.dealType.name} Timeline</Typography>
        <Typography variant="subtitle1">
          Keep track of your tasks and documents throughout your{" "}
          {deal.dealType.name.toLowerCase()} process.
        </Typography>
      </div>
    );
  };

  const handleClick = (docusignEnvelopeId: any) => {
    createDocuSignRecipientView({
      variables: {
        input: {
          docusignEnvelopeId,
        },
      },
    })
      .then((res) => {
        window.open(res.data.createDocuSignRecipientView, "_self");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (showSigningSuccess) {
      showSuccess(
        "Thank you for signing. Your documents will appear below after processing"
      );
    }
  }, [showSigningSuccess, showSuccess]);

  const tasks = useMemo(() => {
    if (!deal?.tasks) {
      return [];
    }
    return deal.tasks.filter(
      (task: { assignedToType: "deal_contacts" | "role" }) => {
        if (taskFilter === "assignedToMe") {
          return task.assignedToType === "deal_contacts";
        } else if (taskFilter === "assignedToOthers") {
          return task.assignedToType === "role";
        } else {
          return false;
        }
      }
    );
  }, [deal?.tasks, taskFilter]);

  const showTaskFilter = deal?.tasks?.some(
    (task: any) => task.assignedToType === "role"
  );

  return stages ? (
    <div className={classes.root}>
      {renderHeader()}

      {showDocuSignEnvelopes && (
        <>
          {deal.docusignEnvelopes?.map((envelope: any) => (
            <StyledAlert
              icon={<FontAwesomeIcon icon={faFileSignature} />}
              severity="info"
              variant="filled"
              action={
                <WhiteButton
                  bold={true}
                  onClick={() => handleClick(envelope.id)}
                  size="small"
                >
                  Sign with DocuSign
                </WhiteButton>
              }
            >
              <AlertTitle>
                <strong>
                  {envelope.documents.length === 1
                    ? "You have a document to sign:"
                    : `You have ${envelope.documents.length} documents to sign:`}
                </strong>
              </AlertTitle>
              <Typography>
                <ul style={{ margin: 0 }}>
                  {envelope.documents?.map((d: any, i: number) => {
                    if (i <= 2) {
                      return <li key={i}>{d.name}</li>;
                    } else if (i === 3) {
                      return (
                        <>
                          <li key={i}>{d.name}</li>
                          <Typography
                            variant="subtitle1"
                            style={{ marginTop: "3px" }}
                          >
                            plus {envelope.documents.length - 4} more
                          </Typography>
                        </>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </ul>
              </Typography>
            </StyledAlert>
          ))}
        </>
      )}

      <DetailCard
        title={
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            sx={{
              width: "100%",
            }}
          >
            <Typography variant="h5"> Tasks</Typography>
            {showTaskFilter && (
              <ToggleButtonGroup
                exclusive
                value={taskFilter}
                onChange={(e, value) => setTaskFilter(value)}
              >
                <ToggleButton value="assignedToMe">My Tasks</ToggleButton>
                <ToggleButton value="assignedToOthers">
                  Agency Tasks
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </Box>
        }
      >
        <TaskList
          tasks={tasks}
          secondaryText="none"
          emptyStateText="You don't have any tasks associated with this property to do right now."
          editable={false}
          delayCompletedUpdates={false}
          onTaskUpdated={onTaskUpdated}
        />
      </DetailCard>

      <DndProvider manager={manager}>
        <Documents
          refetch={refetch}
          folders={deal.folders}
          deal={deal}
          documents={deal.documents}
        />
      </DndProvider>

      <DetailCard title={<Typography variant="h5">Deal Timeline</Typography>}>
        <TimelineList dealId={deal.id} />
      </DetailCard>
    </div>
  ) : (
    <></>
  );
};

export default ClientTimeline;
