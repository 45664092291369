import React, { useMemo, useState } from "react";

import {
  Chip,
  Tooltip,
  TextField,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import {
  deleteStage,
  updateStage,
  UpdateStageInput,
  copyStageAction,
} from "../../actions/stages";

import IconButton from "@mui/material/IconButton";
import StageSendable from "../../components/stages/Emails";
import { useAlert } from "../../context/alert";

import { TrashIcon as DeleteIcon } from "../../icons";

import StagesTasks from "../../components/stages/Tasks";
import StageFields from "../../components/stages/Fields";

import SplitLayout from "../../layouts/SplitLayout";
import LeftPane from "../../components/LeftPane";
import SettingsSubNav from "../../components/SettingsSubNav";
import StagesMobileTitle from "../../components/StagesMobileTitle";
import DetailPane from "../../components/DetailPane";
import PageTitle from "../../components/PageTitle";

import { useNavigate, useParams } from "react-router-dom";
import { DealType, Role, Stage } from "../../models";
import { Edit, Save, FileCopy } from "@mui/icons-material";

import { ApiClient, useApi } from "../../context/api";
import { Feature } from "../../reducers/features";
import { useUser } from "../../context/user";
import { useMutation } from "@apollo/client";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { COPY_STAGE } from "../../api/graphql";

type StageViewProps = {
  deleteStage: (stage: Stage, apolloClient: ApiClient) => Promise<any>;
  updateStage: (
    stage: UpdateStageInput,
    apolloClient: ApiClient
  ) => Promise<any>;
  copyStageAction: any;
  stages: Stage[];
  roles: Role[];
  features: { [key: string]: Feature };
  dealTypes: DealType[];
  dispatch?: any;
};

const StageView = ({
  deleteStage,
  updateStage,
  copyStageAction,
  stages,
  roles,
  features,
  dealTypes,
  dispatch,
}: StageViewProps) => {
  const { apolloClient } = useApi();
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  const { showError, showSuccess } = useAlert();
  const [editing, setEditing] = useState<boolean>(false);
  const [newStageName, setNewStageName] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [copyItems, setCopyItems] = useState([
    { key: "tasks", label: "Tasks", copy: true },
    { key: "emails", label: "Emails", copy: true },
    { key: "fields", label: "Fields", copy: true },
    { key: "sms", label: "SMS", copy: true },
  ]);
  const params = useParams();
  const [copyStage] = useMutation(COPY_STAGE);
  const stage = useMemo(
    () => stages.find((s) => s.id === params.id),
    [stages, params]
  );
  const [copyToDealType, setCopyToDealType] = useState(
    stage?.dealType?.id || dealTypes[0].id
  );

  if (!stage) {
    return null;
  }

  const canEditStages = () => hasPermission("settings", "full");
  const handleDeleteStage = (e: any, stage: any) => {
    if (!window.confirm("Are you sure you want to delete this stage?")) {
      return;
    }

    deleteStage(stage, apolloClient)
      .then(() => {
        showSuccess(`The stage "${stage.name}" was deleted`);
        navigate("/settings/stages", { replace: true });
      })
      .catch((err) => {
        showError("An error occurred when deleting the stage");
      });
  };

  const handleOpenCopyStage = () => {
    setDialogOpen(true);
  };

  const handleCopyStage = (e: any, stage: any) => {
    const input = {
      id: stage.id,
      dealTypeId: copyToDealType,

      ...copyItems.reduce((acc, item) => {
        acc[item.key] = item.copy;
        return acc;
      }, {} as Record<string, boolean>),
    };
    copyStage({
      variables: {
        input,
      },
    })
      .then((res) => {
        const copiedStage = res.data.copyStage;
        setDialogOpen(false);
        showSuccess(`The stage ${stage.name} has been copied`);
        copyStageAction(copiedStage);
        navigate(`/settings/stages/${copiedStage.id}`);
      })
      .catch((err) => {
        showError("An error occurred while copying the stage");
      });
  };

  const handleEditClick = () => {
    setEditing(true);
    setNewStageName(stage.name);
  };

  const handleSaveClick = (stage: any) => {
    const input = {
      id: stage.id,
      sort: stage.sort,
      dealState: stage.dealState,
      name: newStageName,
    };

    updateStage(input, apolloClient)
      .then((res) => {
        showSuccess("The stage has been updated");
        setEditing(false);
      })
      .catch(() => {
        showError("An error occurred while updating the stage");
      });
  };

  const handleStageNameChange = (e: any) => {
    setNewStageName(e.target.value);
  };

  const handleSubmit = (e: any, stage: any) => {
    e.preventDefault();
    handleSaveClick(stage);
  };

  const pageTitle = (): JSX.Element => {
    return (
      <span style={{ display: "flex", alignItems: "center" }}>
        {editing ? (
          <form
            onSubmit={(e) => {
              handleSubmit(e, stage);
            }}
          >
            <TextField
              label=""
              value={newStageName}
              onChange={handleStageNameChange}
              autoFocus
              onBlur={(e) => handleSaveClick(stage)}
              variant="standard"
            />
          </form>
        ) : (
          <span>{stage?.name}</span>
        )}
        &nbsp;&nbsp;
        <Chip
          label={stage?.dealType?.name}
          variant="outlined"
          color="primary"
        ></Chip>
        {editing ? (
          <IconButton onClick={(e: any) => handleSaveClick(stage)} size="large">
            <Save fontSize="small" />
          </IconButton>
        ) : (
          <IconButton
            onClick={handleEditClick}
            disabled={!canEditStages()}
            size="large"
          >
            <Edit fontSize="small" />
          </IconButton>
        )}
      </span>
    );
  };

  return (
    <SplitLayout>
      <LeftPane width={224}>
        <SettingsSubNav />
        <StagesMobileTitle
          backUrl="/settings/stages"
          title={pageTitle()}
          actions={
            <Tooltip title="Delete this stage" aria-label="delete this stage">
              <IconButton
                onClick={(e) => handleDeleteStage(e, stage)}
                disabled={!canEditStages()}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          }
        />
      </LeftPane>
      {roles.length && stage?.id && (
        <DetailPane>
          <PageTitle
            title={pageTitle()}
            backText="Deal Stages"
            backUrl="/settings/stages"
            actions={
              <Stack direction="row">
                <Tooltip title="Copy this stage" aria-label="copy this stage">
                  <IconButton
                    disabled={!canEditStages()}
                    onClick={(e) => handleOpenCopyStage()}
                    size="small"
                  >
                    <FileCopy fontSize="small" />
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={(e) => handleDeleteStage(e, stage)}
                  disabled={!canEditStages()}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            }
          />
          <StageSendable
            stage={stage}
            type="email"
            editable={canEditStages()}
          />
          {features["Text Messaging"] && (
            <StageSendable
              stage={stage}
              type="sms"
              editable={canEditStages()}
            />
          )}
          <StagesTasks stage={stage} editable={canEditStages()} />
          <StageFields stage={stage} editable={canEditStages()} />
        </DetailPane>
      )}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
        <DialogTitle> Copy Stage</DialogTitle>
        <DialogContent>
          <FormControl style={{ width: "100%" }} variant="standard">
            <InputLabel htmlFor="copy-to-dealType">
              Copy Stage to DealType
            </InputLabel>

            <Select
              onChange={(e) => setCopyToDealType(e.target.value)}
              value={copyToDealType}
              inputProps={{
                name: "dealType",
                id: "copy-to-dealType",
              }}
            >
              <option aria-label="None" value="" />
              {dealTypes.map((dealType) => {
                return <MenuItem value={dealType.id}>{dealType.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <Typography variant="body1" style={{ marginTop: "1rem" }}>
            Select the items to copy
          </Typography>
          <FormGroup>
            {copyItems.map((item) => (
              <FormControlLabel
                label={item.label}
                control={
                  <Checkbox
                    checked={item.copy}
                    onChange={(e, checked) => {
                      const newItemsToCopy = copyItems.map((i) => {
                        if (i.key === item.key) {
                          return {
                            ...i,
                            copy: checked,
                          };
                        }
                        return i;
                      });
                      setCopyItems(newItemsToCopy);
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={() => setDialogOpen(false)}>
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={(e: any) => handleCopyStage(e, stage)}>
            Copy Stage
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </SplitLayout>
  );
};

const mapStateToProps = ({
  stages,
  roles,
  features,
  dealTypes,
}: {
  stages: Stage[];
  roles: Role[];
  features: { [key: string]: Feature };
  dealTypes: DealType[];
}) => {
  return {
    stages,
    roles,
    features,
    dealTypes,
  };
};

export default connect(mapStateToProps, {
  deleteStage,
  updateStage,
  copyStageAction,
})(StageView);
