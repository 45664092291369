import React, { FunctionComponent, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Theme, Box } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { ReactSortable } from "react-sortablejs";
import makeStyles from "@mui/styles/makeStyles";
import { Deal, Field, FieldGroup, Mls } from "../../../../../models";
import { useDragandDropList } from "../../../../../hooks/useDragandDropList";
import { useDealDetails } from "../../../../../hooks/useDealDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dealDetails: (isLeftPane: any) => ({
      paddingLeft: isLeftPane ? "15px" : "24px",
      padding: isLeftPane ? "5px" : "24px",
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    }),
    detailItem: {
      flex: "1 1 220px",
      margin: "10px",
      maxWidth: "250px",
      width: "100%",
      minHeight: "36px",
      overflowWrap: "anywhere",
    },
  })
);

type DealDetailProps = {
  group: FieldGroup;
  mlses: Mls[];
  deal: Deal;
  renderTypes?: string[];
  editing?: boolean;
  reportView?: boolean;
  fields?: Field[];
  setGroups: (list: any) => void;
  isLeftPane?: boolean;
  favoriteFields?: Field[];
  setFavoriteFields?: (list: any) => void;
};

export const createDateTime = (
  field: any,
  value: any,
  deal: Deal,
  isCustomField: boolean
) => {
  const updates: { [key: string]: any } = {};
  const { date, dateTime, event } = value;

  // Update the date value
  updates[field.id as string] = date ?? null;
  if (field.id.endsWith("Date") || isCustomField) {
    // This field also supports time, so we'll save that in the "At" field
    const timeField: string = field.id.replace("Date", "At");
    updates[timeField] = dateTime ?? null;
  }

  if (event && !isCustomField) {
    // This field contains event data, so we'll add that to the deal.events array
    updates.events = deal.events?.map((e) => {
      if (e.type === event.type) {
        return { ...event };
      } else {
        return e;
      }
    }) ?? [event];
  }

  return {
    deal,
    updates,
  };
};

const DealDetailCard: FunctionComponent<DealDetailProps> = ({
  mlses,
  deal,
  renderTypes = [],
  editing = false,
  fields,
  group,
  setGroups,
  isLeftPane = false,
  setFavoriteFields,
  favoriteFields,
}) => {
  const classes = useStyles(isLeftPane);
  const [systemFields, setSystemFields] = useState<Array<any>>([]);

  const { setList } = useDragandDropList(
    deal,
    group,
    setGroups,
    isLeftPane,
    setFavoriteFields
  );

  const { renderField } = useDealDetails(
    deal,
    mlses,
    classes,
    editing,
    isLeftPane,
    setFavoriteFields,
    favoriteFields
  );
  useEffect(() => {
    if (!deal || !fields) {
      return;
    }

    if (renderTypes.length > 0) {
      const systemFields = fields
        .reduce((acc: any, sysField: any) => {
          renderTypes.map((renderField: any) => {
            if (renderField === sysField.id) {
              acc.push(sysField);
            }
            return acc;
          });
          return acc;
        }, [])
        .filter(
          (sysField: any) =>
            sysField.id !== "address" && sysField.id !== "closingAddress"
        );

      if (renderTypes.includes("address")) {
        systemFields.push(
          { id: "street", type: "address", label: "Address" },
          { id: "street2", type: "address", label: "Address (Line 2)" },
          { id: "city", type: "address", label: "City" },
          { id: "state", type: "address", label: "State" },
          { id: "zip", type: "address", label: "Zip" }
        );
      } else if (renderTypes.includes("closingAddress")) {
        systemFields.push(
          { id: "street", type: "address", label: "Closing Address" },
          {
            id: "street2",
            type: "address",
            label: "Closing Address 2",
          },
          { id: "city", type: "address", label: "Closing City" },
          { id: "state", type: "address", label: "Closing State" },
          { id: "zip", type: "address", label: "Closing Zip" }
        );
      }
      setSystemFields(systemFields);
    } else {
      setSystemFields(fields);
    }
  }, [deal, fields, renderTypes]);

  return (
    <Box>
      <ReactSortable
        list={systemFields || []}
        className={classes.dealDetails}
        setList={setList}
        group={{
          name: isLeftPane ? "favorites" : "dealDetails",
          pull: (to: any, from: any) => {
            if (to.options.group.name === "favorites") {
              return "clone";
            } else if (isLeftPane) {
              return false;
            }
            return true;
          },
          put: ["dealDetails", "favorites"],
        }}
        animation={200}
        handle=".drag-handle"
      >
        {systemFields?.map(renderField)}
      </ReactSortable>
    </Box>
  );
};

const mapStateToProps = ({ mlses }: { mlses: Mls[] }) => {
  return { mlses };
};
export default connect(mapStateToProps)(DealDetailCard);
