import React from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
  Theme,
  alpha,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { MoreVert } from "@mui/icons-material";
import FileTypeIcon from "../../FileTypeIcon";
import SecondaryText from "./SecondaryText";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

interface DocumentTypeProps {
  documentType: any;
  setAnchorEl: any;
  document?: any;
  isClient?: boolean;
  reportView?: boolean;
  onDrop?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemWithDocType: {
      backgroundColor: alpha(theme.palette.turquoise.main, 0.05),
      margin: theme.spacing(2),
      borderRadius: "5px",
      border: "solid 2px",
      borderColor: theme.palette.turquoise.main,
      width: "97%",
    },
    noListItemWithDocType: {
      backgroundColor: theme.palette.grey[100],
      margin: theme.spacing(2),
      borderRadius: "5px",
      border: "solid 2px black",
      width: "97%",
    },
  })
);

const DocumentType = ({
  documentType,
  document,
  setAnchorEl,
  isClient,
  reportView = false,
  onDrop,
}: DocumentTypeProps) => {
  const classes = useStyles();

  const handleMenuClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const [collectedProps, drop] = useDrop(
    () => ({
      accept: ["document", NativeTypes.FILE],
      canDrop: () => {
        return onDrop;
      },
      drop: (item: any) => {
        if (onDrop) {
          onDrop(item, documentType.id, "documentType");
        }
      },
      collect: (monitor) => {
        return { isOver: monitor.isOver({ shallow: true }) };
      },
    }),
    [documentType]
  );

  return (
    <div ref={!document ? drop : undefined}>
      <ListItem
        className={
          document || collectedProps.isOver
            ? classes.listItemWithDocType
            : classes.noListItemWithDocType
        }
      >
        <ListItemIcon style={{ paddingLeft: "5px" }}>
          <FileTypeIcon filename={document ? document.name : "missing"} />
        </ListItemIcon>
        <ListItemText
          primary={
            document ? documentType.name : `${documentType.name} (missing)`
          }
          secondary={
            <SecondaryText
              document={document}
              documentType={documentType}
              isClient={isClient}
            />
          }
          style={{ margin: 0, marginLeft: "-15px" }}
        />
        <ListItemSecondaryAction>
          {!reportView && (
            <IconButton onClick={(e: any) => handleMenuClick(e)} size="large">
              <MoreVert />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
};

export default DocumentType;
