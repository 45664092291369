import React from "react";
import { Navigate, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import CalendarPage from "../pages/Calendar";
import TaskList from "../pages/Tasks";
import DealsList from "../pages/deals/List";
import DealsView from "../pages/deals/view";
import DealReport from "../pages/deals/Report";
import Reports from "../pages/ReportsV1";
import ReportsV2 from "../pages/Reports";
import ContactsView from "../pages/contacts/View";
import ContactsList from "../pages/contacts/List";
import EmailTemplateList from "../pages/email-templates/TemplatesList";
import StagesList from "../pages/stages/List";
import StagesView from "../pages/stages/View";
import Roles from "../pages/Roles";
import Team from "../pages/Team";
import MessagesPage from "../pages/Messages";
import Goals from "../pages/Goals";
import { Feature } from "../reducers/features";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createDragDropManager } from "dnd-core";
import AccountSettingsFallback from "../layouts/AccountSettingsFallback";

export const manager = createDragDropManager(HTML5Backend);

const EmailSettings = React.lazy(() => import("../pages/settings/Email"));

const UserSettings = React.lazy(() => import("../pages/settings/User"));
const CustomFieldsPage = React.lazy(
  () => import("../pages/settings/CustomFields")
);
const PhoneAndSmsSettingsPage = React.lazy(
  () => import("../pages/settings/PhoneAndSms")
);
const IntegrationsList = React.lazy(
  () => import("../pages/settings/integrations/List")
);
const TimelineSettings = React.lazy(() => import("../pages/settings/Timeline"));

const DocumentTypes = React.lazy(
  () => import("../pages/settings/DocumentTypes/DocumentTypes")
);
const IntegrationSettings = React.lazy(
  () => import("../pages/settings/integrations/Settings")
);
const AccountTabsView = React.lazy(
  () => import("../pages/settings/account/index")
);

const CalendarSettings = React.lazy(() => import("../pages/settings/Calendar"));

const routes = (currentUser: any, features: Feature) => {
  if (currentUser?.teamMember) {
    let ReportsPage = Reports;
    if (features["Enhanced Reports"]) {
      ReportsPage = ReportsV2;
    }

    return [
      <Route key="/calendar" path="/calendar" element={<CalendarPage />} />,
      <Route key="/deals" path="/deals" element={<DealsList />} />,
      <Route key="/tasks" path="/tasks" element={<TaskList />} />,
      <Route
        key="/deals/report/:id"
        path="/deals/report/:id"
        element={
          <DndProvider manager={manager}>
            <DealReport />
          </DndProvider>
        }
      />,
      <Route key="/deals/:id/*" path="/deals/:id/*" element={<DealsView />} />,
      <Route key="/reports/" path="/reports/">
        <Route path="" element={<ReportsPage />}>
          <Route path=":reportId" element={<ReportsPage />}></Route>
        </Route>
      </Route>,
      <Route key="/reports/v2/" path="/reports/v2/">
        <Route path="" element={<ReportsV2 />}>
          <Route path=":reportId" element={<ReportsV2 />}></Route>
        </Route>
      </Route>,
      <Route key="/contacts" path="/contacts" element={<ContactsList />} />,
      <Route
        key="/contacts/:id"
        path="/contacts/:id"
        element={<ContactsView />}
      />,
      <Route key="/dashboard" path="/dashboard" element={<Dashboard />} />,
      <Route
        key="/settings/stages"
        path="/settings/stages"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <StagesList />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/stages/:id"
        path="/settings/stages/:id"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <StagesView />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/goals"
        path="/settings/goals"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <Goals />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/roles"
        path="/settings/roles"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <Roles />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/team"
        path="/settings/team"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <Team />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/documents"
        path="/settings/documents"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <DocumentTypes />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/account/*"
        path="/settings/account/*"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <AccountTabsView />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings"
        path="/settings"
        element={<Navigate to="/settings/profile" />}
      />,
      <Route
        key="/settings/calendar"
        path="/settings/calendar"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <CalendarSettings />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/profile"
        path="/settings/profile"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <UserSettings />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/email"
        path="/settings/email"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <EmailSettings />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/phone"
        path="/settings/phone"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <PhoneAndSmsSettingsPage />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/email-templates"
        path="/settings/email-templates"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <EmailTemplateList />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/fields"
        path="/settings/fields"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <CustomFieldsPage />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/user/integrations"
        path={"/settings/user/integrations"}
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <IntegrationsList />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/integrations"
        path={"/settings/integrations"}
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <IntegrationsList />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/integrations/:type"
        path={"/settings/integrations/:type"}
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <IntegrationSettings />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/user/integrations/:type"
        path={"/settings/user/integrations/:type"}
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <IntegrationSettings />
          </React.Suspense>
        }
      />,
      <Route
        key="/settings/timeline"
        path="/settings/timeline"
        element={
          <React.Suspense fallback={<AccountSettingsFallback />}>
            <TimelineSettings />
          </React.Suspense>
        }
      />,
      <Route key="/messages" path="/messages" element={<MessagesPage />} />,
    ];
  } else {
    return [];
  }
};

export default routes;
