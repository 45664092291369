import React, { useEffect, useState, useCallback } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { connect } from "react-redux";
import moment from "moment";
import { REPORTS } from "../reducers/reports";
import { GET_GOALS } from "../api/graphql";
import SplitLayout from "../layouts/SplitLayout";
import LeftPane from "../components/LeftPane";
import DetailPane from "../components/DetailPane";
import {
  Grid,
  Theme,
  Paper,
  Box,
  Typography,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ReportsSubNav from "../components/ReportsSubNav";
import PageTitle from "../components/PageTitle";
import { useParams } from "react-router-dom";
import { useUser } from "../context/user";
import TeamLayout from "../layouts/TeamLayout";
import BarChart from "../components/reports/MonthlyChart";
import DoughnutChart from "../components/reports/DoughnutChart";
import DetailCard from "../components/DetailCard";
import TeamMemberLabeledSelect from "../components/TeamMemberLabeledSelect";
import { PrimaryButton } from "../components/buttons";
import { ToggleButton, ToggleButtonGroup } from "../components/ToggleButtons";
import { CsvBuilder } from "filefy";
import { reportTableCheck } from "../components/reports/Table";
import ReportTable from "../components/reports/Table";
import ActiveDealsReport from "../components/reports/ActiveDealsReport";
import { RowDataTypes } from "../models";
import InsightsReport from "../components/reports/InsightsReport";
import LabeledSelect from "../components/LabeledSelect";
import TeamLeaderboardReport from "../components/reports/TeamLeaderboardReport";
import TaskReport from "../components/reports/TaskReport";

type TableDataTypes = {
  years: [
    {
      year: number;
    }
  ];
  groups: RowDataTypes[];
};

const pageHeaderHeight = 94;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageLayout: {
      flexGrow: 1,
      overflowX: "hidden",
      display: "flex",
      flexDirection: "row",
      marginBottom: "0",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
    },
    pageHeader: {
      padding: "40px 40px 30px 40px",
      height: `${pageHeaderHeight}px`,
      display: "flex",
    },
    tableHeader: {
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "14px",
      color: "rgb(107, 114, 128)",
    },
    hideText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    subtitleMobileStyles: {
      [theme.breakpoints.down(426)]: {
        maxWidth: "175px",
      },
    },
  })
);

const currencyFormatter = (value: number | null | undefined) => {
  if (value == null) {
    return "";
  } else {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
};

export const rawFormatter = (value: number | null | undefined) => {
  if (value == null) {
    return "";
  } else {
    return value.toLocaleString("en-US", {
      style: "decimal",
      maximumFractionDigits: 0,
    });
  }
};

export const reports = [
  {
    id: "insights",
    name: "Insights",
    formatter: rawFormatter,
    currencyFormatter: currencyFormatter,
    getAggBundle: (aggregates: any) => aggregates.timeToClose,
    category: "Deals",
  },
  {
    id: "active",
    name: "Active",
    category: "Deals",
  },
  {
    id: "units",
    name: "Units",
    dataKey: "dealCommissionTotal",
    formatter: rawFormatter,
    getAggBundle: (aggregates: any) => aggregates.units,
    category: "Deals",
  },
  {
    id: "volume",
    name: "Volume",
    dataKey: "dealValueTotal",
    formatter: currencyFormatter,
    getAggBundle: (aggregates: any) => aggregates.volume,
    category: "Deals",
  },
  {
    id: "commission",
    name: "Commissions",
    dataKey: "dealCommissionTotal",
    formatter: currencyFormatter,
    getAggBundle: (aggregates: any) => aggregates.commission,
    category: "Deals",
  },
  {
    id: "teamLeaderboard",
    name: "Leaderboard",
    category: "Agents",
  },
  {
    id: "teamMember",
    name: "Deals",
    category: "Agents",
  },
  {
    id: "tasks",
    name: "Tasks",
    category: "Agents",
  },
];

export const GET_AGGREGATE_DATA = gql`
  fragment AggregateData on DealReportAggregate {
    units {
      total
      average
    }
    volume {
      total
      average
    }
    commission {
      total
      average
    }
  }
  query GET_AGGREGATE_DATA(
    $state: DealState
    $closingBefore: Date
    $closingAfter: Date
    $ownerId: ID
    $year: Int
  ) {
    getReportAggregates(
      state: $state
      closingBefore: $closingBefore
      closingAfter: $closingAfter
      ownerId: $ownerId
    ) {
      dealCount
      years {
        year
        ...AggregateData
        months {
          month
          ...AggregateData
        }
      }
      byTeamMembers {
        years {
          year
          ...AggregateData
          months {
            ...AggregateData
          }
        }
        groups {
          teamMemberId
          teamMember {
            name
            goals(year: $year) {
              yearly {
                id
                teamMemberId
                month
                units
                commission
                volume
              }
              monthly {
                id
                teamMemberId
                year
                month
                units
                commission
                volume
              }
            }
          }
          years {
            year
            ...AggregateData
            months {
              month
              ...AggregateData
            }
          }
        }
      }
      byDealTypes {
        years {
          year
          ...AggregateData
          months {
            month
            ...AggregateData
          }
        }
        groups {
          dealTypeId
          dealType {
            name
          }
          years {
            year
            ...AggregateData
            months {
              month
              ...AggregateData
            }
          }
        }
      }
      bySources {
        years {
          year
          ...AggregateData
          months {
            month
            ...AggregateData
          }
        }
        groups {
          source
          years {
            year
            ...AggregateData
            months {
              month
              ...AggregateData
            }
          }
        }
      }
    }
  }
`;

const ReportsPage = (): JSX.Element => {
  const { currentUser } = useUser();

  const params = useParams();
  const classes = useStyles();

  const [report, setReport] = useState(reports[0]);
  const [filters, setFilters] = useState({
    includeActive: false,
    dateRange: {
      from: moment().startOf("year"),
      to: moment().endOf("year"),
    },
    teamMember: currentUser.teamMember ?? null,
  });

  const [getAggregatedData] = useLazyQuery(GET_AGGREGATE_DATA);
  const [getGoals] = useLazyQuery(GET_GOALS);
  const [goals, setGoals] = useState<any>();
  const [aggregatedData, setAggregatedData] = useState<any>();
  const [tableHeaders, setTableHeaders] = useState<any[]>();
  const [timeFrame, setTimeFrame] = useState<
    "lastYear" | "thisYear" | "monthly"
  >("thisYear");

  const [isMonthly, setIsMonthly] = useState(false);
  const [month, setMonth] = useState<any>(moment().format("M"));
  const [leaderboardType, setLeaderBoardType] = useState<
    "percentToGoal" | "percentOfTeam"
  >("percentOfTeam");

  useEffect(() => {
    if (!filters.dateRange) {
      return;
    }
    const variables: any = {
      closingBefore: filters.dateRange?.to?.format("YYYY-MM-DD"),
      closingAfter: filters.dateRange?.from?.format("YYYY-MM-DD"),
      state: filters.includeActive ? undefined : "closed",
      // year: moment().format("Y"),
    };
    if (report.id === "teamMember") {
      variables.ownerId = filters.teamMember?.id;
    } else {
      variables.ownerId = null;
    }

    getAggregatedData({
      variables,
    }).then((result) => {
      const aggregatedData = result.data.getReportAggregates;
      setAggregatedData(aggregatedData);
      if (aggregatedData.years?.length > 0) {
        let months = aggregatedData.years[0].months;
        months = months.map((month: any) => {
          const date = new Date();
          date.setMonth(month.month - 1);
          return date.toLocaleString("en-US", {
            month: "short",
          });
        });
        setTableHeaders(months);
      }
    });

    getGoals({
      variables: {
        teamMemberId: variables.ownerId ? variables.ownerId : undefined,
      },
    }).then((result) => {
      const goals = result.data.getGoals;
      setGoals(goals);
    });
  }, [report, getAggregatedData, filters, getGoals]);

  useEffect(() => {
    setTimeFrame("thisYear");
    setIsMonthly(false);
    setReport(reports.find((r) => r.id === params.reportId) || reports[0]);
  }, [params.reportId]);

  const exportCsv = useCallback(
    (tableHeaders: string[], tableData: TableDataTypes, reportName: string) => {
      const builder = new CsvBuilder(
        `${tableData.years[0].year}_${reportName}_report.csv`
      );
      const handleAddRows = () => {
        let arr: any[] = [];
        const id = report.id;
        arr = tableData.groups.map((rowData: RowDataTypes) => {
          return [
            reportTableCheck(rowData),
            ...rowData?.years[0].months.map(
              (monthAgg: { [key: string]: { total: number } }) => {
                return rawFormatter(monthAgg[id].total);
              }
            ),
            rawFormatter(rowData.years[0][id].total),
          ];
        });

        // keeping this if we want to add Totals/Averages to table at some point
        // arr.push(
        //   [
        //     "Totals",
        //     ...yearData.months.map((month: string) => cellValue?.(month)),
        //     cellValue?.(yearData),
        //   ],
        //   [
        //     "Averages",
        //     ...tableData.years[0].months.map((month: any) =>
        //       cellValue?.(month, "average")
        //     ),
        //   ]
        // );
        console.log(tableHeaders.map((month: string) => month));

        return arr;
      };
      builder
        .setDelimeter(",")
        .setColumns(["Month", ...tableHeaders.map((month) => month), "Totals"])
        .addRows(handleAddRows())
        .exportFile();
    },
    [report]
  );

  const handleTimeFrameChange = (timeframe: "thisYear" | "lastYear") => {
    let dateRange: any = {};

    if (timeframe === "thisYear") {
      dateRange.from = moment().startOf("y");
      dateRange.to = moment().endOf("y");
      setIsMonthly(false);
      setIsMonthly(false);
    } else if (timeframe === "lastYear") {
      dateRange.from = moment().subtract(1, "y").startOf("y");
      dateRange.to = moment().subtract(1, "y").endOf("y");
      setIsMonthly(false);
    } else if (timeframe === "monthly") {
      setIsMonthly(true);
    }
    setFilters((filters) => {
      return {
        ...filters,
        dateRange,
      };
    });
    setTimeFrame(timeframe);
  };

  if (currentUser?.permissions?.reports === "none") {
    return (
      <TeamLayout>
        <Box p={4} component={Paper} textAlign="center">
          <p>Your account does not have access to reporting</p>
        </Box>
      </TeamLayout>
    );
  }

  const leaderBoard = report.id === "teamLeaderboard";
  const isTaskReport = report.id === "tasks";

  const monthOptions = moment.months().map((month) => {
    return {
      label: month,
      value: moment().month(month).format("M"),
    };
  });

  return (
    <SplitLayout>
      <LeftPane width={224}>
        <ReportsSubNav reports={reports} version={2} />
      </LeftPane>
      <DetailPane>
        <Grid container className={classes.pageLayout} spacing={2}>
          <Grid item xs={12}>
            <PageTitle showOnMobile={true} title={report.name}></PageTitle>
          </Grid>

          {(report.id !== "active" || leaderBoard) && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {leaderBoard && (
                  <ToggleButtonGroup
                    sx={{ marginRight: "1rem" }}
                    value={leaderboardType}
                    exclusive
                    onChange={(e: any) => setLeaderBoardType(e.target.value)}
                  >
                    <ToggleButton value="percentToGoal">
                      Percent to Goal
                    </ToggleButton>
                    <ToggleButton
                      value="percentOfTeam"
                      sx={{
                        minWidth: "105px",
                      }}
                    >
                      Percent of Team
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                {!isTaskReport && (
                  <ToggleButtonGroup
                    sx={{ marginRight: "1rem" }}
                    value={timeFrame}
                    exclusive
                    onChange={(e: any) => handleTimeFrameChange(e.target.value)}
                  >
                    <ToggleButton value={leaderBoard ? "monthly" : "lastYear"}>
                      {leaderBoard ? "Monthly" : "Last Year"}
                    </ToggleButton>
                    <ToggleButton value="thisYear">This Year</ToggleButton>
                  </ToggleButtonGroup>
                )}
                {isMonthly && leaderBoard && (
                  <FormControl style={{ width: "20%" }} variant="standard">
                    <LabeledSelect
                      selectProps={{
                        value: month,
                        onChange: (e: SelectChangeEvent<any>) =>
                          setMonth(e.target.value),
                      }}
                      label="Months:"
                      options={monthOptions}
                    ></LabeledSelect>
                  </FormControl>
                )}
                {report.id === "teamMember" && (
                  <TeamMemberLabeledSelect
                    onlyUsers
                    value={filters.teamMember}
                    onChange={(teamMember) => {
                      setFilters({
                        ...filters,
                        teamMember,
                      });
                    }}
                  />
                )}
              </Box>
            </Grid>
          )}

          {aggregatedData?.dealCount === 0 ? (
            <Grid item xs={12}>
              <Box p={4} component={Paper} textAlign="center">
                <Typography variant="h6">No deals were found!</Typography>

                {report.id === "teamMember" ? (
                  <Typography variant="body1">
                    Please try a different date range or team member.
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    Reports will show when we find deals that have closed in the
                    selected date range.
                  </Typography>
                )}
              </Box>
            </Grid>
          ) : (
            <>
              {report.id === "insights" && (
                <InsightsReport report={report} dateRange={filters.dateRange} />
              )}
              {report.id === "active" && (
                <Grid item xs={12}>
                  <ActiveDealsReport />
                </Grid>
              )}
              {leaderBoard && goals && (
                <Grid item xs={12}>
                  <TeamLeaderboardReport
                    month={month}
                    isMonthly={isMonthly}
                    data={aggregatedData?.byTeamMembers}
                    goals={goals}
                    leaderboardType={leaderboardType}
                  />
                </Grid>
              )}
              {isTaskReport && (
                <Grid item xs={12}>
                  <TaskReport />
                </Grid>
              )}

              {report.id === "teamMember" &&
                aggregatedData?.byTeamMembers.groups.length > 0 && (
                  <Grid item xs={12}>
                    <DetailCard
                      title={<Typography variant="h5">Units</Typography>}
                    >
                      <Box p={4}>
                        <BarChart
                          hideLegend
                          showLabels
                          data={aggregatedData.byTeamMembers.groups}
                          goals={goals}
                          getValue={(d) => d.units}
                          stacked
                        />
                      </Box>
                    </DetailCard>
                    <DetailCard
                      title={<Typography variant="h5">Commission</Typography>}
                    >
                      <Box p={4}>
                        <BarChart
                          hideLegend
                          showLabels
                          data={aggregatedData.byTeamMembers.groups}
                          goals={goals}
                          getValue={(d) => d.commission}
                          stacked
                        />
                      </Box>
                    </DetailCard>
                    <DetailCard
                      title={<Typography variant="h5">Volume</Typography>}
                    >
                      <Box p={4}>
                        <BarChart
                          hideLegend
                          showLabels
                          data={aggregatedData.byTeamMembers.groups}
                          goals={goals}
                          getValue={(d) => d.volume}
                          stacked
                        />
                      </Box>
                    </DetailCard>
                  </Grid>
                )}

              {aggregatedData?.byDealTypes.groups.length > 0 &&
                tableHeaders &&
                report.id !== "insights" &&
                report.getAggBundle && (
                  <Grid item xs={12}>
                    <DetailCard
                      title={<Typography variant="h5">By Deal Type</Typography>}
                      action={
                        <PrimaryButton
                          height="full"
                          onClick={() =>
                            exportCsv(
                              tableHeaders,
                              aggregatedData.byDealTypes,
                              "DealType"
                            )
                          }
                        >
                          Export as CSV
                        </PrimaryButton>
                      }
                    >
                      <Box p={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={8}>
                            <BarChart
                              data={aggregatedData.byDealTypes.groups}
                              getValue={report.getAggBundle}
                              stacked
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <DoughnutChart
                              data={aggregatedData.byDealTypes.groups}
                              getValue={(agg) =>
                                report.getAggBundle(agg.years[0])
                              }
                            />
                          </Grid>
                          <ReportTable
                            tableHeaders={tableHeaders}
                            tableData={aggregatedData.byDealTypes.groups}
                            stats={aggregatedData.byDealTypes.years?.[0]}
                            report={report}
                          />
                        </Grid>
                      </Box>
                    </DetailCard>
                  </Grid>
                )}
              {aggregatedData?.byTeamMembers.groups.length > 0 &&
                tableHeaders &&
                report.id !== "insights" &&
                report.getAggBundle && (
                  <Grid item xs={12}>
                    <DetailCard
                      title={<Typography variant="h5">By Agent</Typography>}
                      action={
                        <PrimaryButton
                          height="full"
                          onClick={() =>
                            exportCsv(
                              tableHeaders,
                              aggregatedData.byTeamMembers,
                              "Agent"
                            )
                          }
                        >
                          Export as CSV
                        </PrimaryButton>
                      }
                    >
                      <Box p={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <BarChart
                              data={aggregatedData.byTeamMembers.groups}
                              getValue={report.getAggBundle}
                            />
                          </Grid>
                          <ReportTable
                            showAverages
                            tableHeaders={tableHeaders}
                            tableData={aggregatedData.byTeamMembers.groups}
                            stats={aggregatedData.byTeamMembers.years?.[0]}
                            report={report}
                          />
                        </Grid>
                      </Box>
                    </DetailCard>
                  </Grid>
                )}
              {aggregatedData?.bySources.groups.length > 0 &&
                tableHeaders &&
                report.id !== "insights" &&
                report.getAggBundle && (
                  <Grid item xs={12}>
                    <DetailCard
                      title={<Typography variant="h5">By Sources</Typography>}
                      action={
                        <PrimaryButton
                          height="full"
                          onClick={() =>
                            exportCsv(
                              tableHeaders,
                              aggregatedData.bySources,
                              "Sources"
                            )
                          }
                        >
                          Export as CSV
                        </PrimaryButton>
                      }
                    >
                      <Box p={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <BarChart
                              data={aggregatedData.bySources.groups}
                              getValue={report.getAggBundle}
                              stacked
                            />
                          </Grid>
                          <ReportTable
                            tableHeaders={tableHeaders}
                            tableData={aggregatedData.bySources.groups}
                            stats={aggregatedData.bySources.years?.[0]}
                            report={report}
                          />
                        </Grid>
                      </Box>
                    </DetailCard>
                  </Grid>
                )}
            </>
          )}
        </Grid>
      </DetailPane>
    </SplitLayout>
  );
};

const mapStateToProps = ({ reports }: any) => {
  return { report: reports };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    setReport: (report: any) => {
      return dispatch({ type: REPORTS.SET, report });
    },
    setDeals: (deals: any) => {
      return dispatch({ type: REPORTS.SET_DEALS, deals });
    },
    setAgents: (agents: any) => {
      return dispatch({ type: REPORTS.SET_AGENTS, agents });
    },
    setChartInfo: (chartInfo: any) => {
      return dispatch({ type: REPORTS.SET_CHART_INFO, chartInfo });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
