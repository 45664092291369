import React from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldItem: {
      margin: "0 25px",
      paddingBottom: "18px",
    },
    fieldItemLabel: {
      fontSize: "12px",
      fontWeight: 500,
      height: "16px",
      color: theme.palette.grey[500],
      marginBottom: "3px",
    },
    socialIcon: {
      color: theme.palette.secondary.main,
      marginRight: "5px",
      transition: theme.transitions.create(["color"]),
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  })
);

type SocialProfilesProps = {
  instagram?: string;
  twitter?: string;
  linkedIn?: string;
  facebook?: string;
  _typename: string;
};

interface SocialProfilesIntProps {
  socialProfiles: SocialProfilesProps;
}

const SocialProfiles = ({ socialProfiles }: SocialProfilesIntProps) => {
  const classes = useStyles();

  return (
    <div className={classes.fieldItem}>
      <div className={classes.fieldItemLabel}>Social Profiles</div>
      {socialProfiles.facebook && (
        <a
          href={`https://facebook.com/${socialProfiles.facebook}`}
          target="blank"
        >
          <FacebookIcon className={classes.socialIcon} />
        </a>
      )}
      {socialProfiles.twitter && (
        <a
          href={`https://twitter.com/${socialProfiles.twitter}`}
          target="blank"
        >
          <TwitterIcon className={classes.socialIcon} />
        </a>
      )}
      {socialProfiles.instagram && (
        <a
          href={`https://instagram.com/${socialProfiles.instagram}`}
          target="blank"
        >
          <InstagramIcon className={classes.socialIcon} />
        </a>
      )}
      {socialProfiles.linkedIn && (
        <a
          href={`https://linkedin.com/in/${socialProfiles.linkedIn}`}
          target="blank"
        >
          <LinkedInIcon className={classes.socialIcon} />
        </a>
      )}
    </div>
  );
};

export default SocialProfiles;
