import React, { useState, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  Box,
  IconButton,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  Typography,
  CircularProgress,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Folder as FolderIcon,
  FolderOpen,
  GetApp as DownloadFilesIcon,
  ChevronRight,
  MoreVert,
  Delete,
  Done,
  Edit as EditIcon,
} from "@mui/icons-material/";
import { PrimaryButton, SecondaryButton } from "../../../components/buttons";
import moment from "moment";
import { useEffect } from "react";
import { useAlert } from "../../../context/alert";
import { useAuth } from "../../../context/auth";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import FileTypeIcon from "../../FileTypeIcon";
import axios, { AxiosResponse } from "axios";
import DocumentListItem from "./DocumentListItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      padding: theme.spacing(1),
      borderRadius: "5px",
      border: `1px solid ${theme.palette.primary.main}`,
      fontSize: "14px",
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      outline: theme.palette.primary.main,
    },
  })
);

interface FolderProps {
  folderProp?: any;
  newFolder?: boolean;
  setNewFolder?: Function;
  dealId: string;
  refetch?: any;
  reportView?: boolean;
  dragAndDrop?: boolean;
  isClient?: boolean;
  onDrop?: (
    files: Array<File>,
    destinationId: string,
    destinationType?: "folder" | "documentType"
  ) => any;
  otherFolders?: any[];
}

const Folder = ({
  folderProp,
  newFolder = false,
  dealId,
  refetch,
  setNewFolder,
  reportView = false,
  dragAndDrop = true,
  isClient = false,
  onDrop,
  otherFolders,
}: FolderProps) => {
  const classes = useStyles();
  const { authToken } = useAuth();

  const { showSuccess, showError } = useAlert();
  const [anchorEl, setAnchorEl] = useState<null | any>(null);
  const [folder, setFolder] = useState<any>({ name: "", updatedAt: "" });
  const [openFolder, setOpenFolder] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [fileDownloading, setFileDownloading] = useState<boolean>(false);
  const folderNameRef = useRef<HTMLInputElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteValue, setDeleteValue] = useState<string>("unassign");
  const [deleteDocuments, setDeleteDocuments] = useState<boolean>(false);

  const [collectedProps, drop] = useDrop(
    () => ({
      accept: ["document", NativeTypes.FILE],
      canDrop: () => {
        return dragAndDrop;
      },
      drop: (item: any) => {
        if (onDrop) {
          onDrop(item, folder?.id, "folder");
        }
      },
      collect: (monitor) => {
        return { isOver: monitor.isOver({ shallow: true }) };
      },
    }),
    [folder]
  );

  const [createDocumentFolder] = useMutation(gql`
    mutation CreateDocumentFolder($input: CreateDocumentFolderInput!) {
      createDocumentFolder(input: $input) {
        id
        name
      }
    }
  `);

  const [deleteDocumentFolder] = useMutation(gql`
    mutation DeleteDocumentFolder($input: DeleteDocumentFolderInput) {
      deleteDocumentFolder(input: $input)
    }
  `);

  const [updateDocumentFolder] = useMutation(gql`
    mutation UpdateDocumentFolder($input: UpdateDocumentFolderInput!) {
      updateDocumentFolder(input: $input) {
        id
        name
      }
    }
  `);

  const handleMenuClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFolderCreate = () => {
    createDocumentFolder({
      variables: { input: { dealId: dealId, name: folder.name } },
    })
      .then(() => {
        showSuccess(`The folder "${folder.name}" was created`);
        if (refetch) {
          refetch();
        }
      })
      .catch(() => {
        showError(`An error occurred when creating "${folder.name}"`);
      });
  };

  const handleFolderUpdate = () => {
    updateDocumentFolder({
      variables: { input: { id: folder.id, name: folder.name } },
    })
      .then(() => {
        showSuccess(`The folder "${folder.name}" was updated`);
        if (refetch) {
          refetch();
        }
      })
      .catch(() => {
        showError(`An error occurred when updating "${folder.name}"`);
      });
  };

  const handleFolderClick = () => {
    if (folder.documents?.length > 0) {
      setOpenFolder(!openFolder);
    }
  };

  const handleFolderEdit = () => {
    setEditing(!editing);
    handleMenuClose();
  };

  const handleFolderDelete = () => {
    let variables: {
      input: {
        id: string;
        deleteDocuments?: boolean;
      };
    } = {
      input: {
        id: folder.id,
      },
    };
    if (deleteValue === "delete") {
      variables.input.deleteDocuments = deleteDocuments;
    }
    deleteDocumentFolder({
      variables,
    })
      .then(() => {
        showSuccess(`The folder "${folder.name}" was deleted`);
        if (refetch) {
          refetch();
        }
      })
      .catch((err) => {
        showError(`An error occurred when deleting "${folder.name}"`);
      });

    handleDialogClose();
  };

  const handleFolderDeleteClick = () => {
    if (folder.documents.length) {
      setDeleteDialogOpen(true);
      setAnchorEl(null);
    } else {
      handleFolderDelete();
    }
  };

  const handleDoneClick = () => {
    if (newFolder) {
      handleFolderCreate();
      if (setNewFolder) {
        setNewFolder(false);
      }
    } else {
      handleFolderUpdate();
    }
    setEditing(false);
  };

  const handleSecondaryText = () => {
    let secondaryString = "";
    if (folder.documents?.length > 0) {
      secondaryString += `${folder.documents?.length} File${
        folder.documents?.length > 1 ? "s" : ""
      }`;
    }
    if (folder.updatedAt) {
      secondaryString += `${
        secondaryString.length > 0 ? " | " : ""
      }Last Updated on ${moment(folder.updatedAt).format("MMM D")}`;
    }
    return secondaryString;
  };

  const handleDownloadZip = () => {
    setFileDownloading(true);
    const body: any = {
      folderId: folder.id,
    };
    axios
      .post<{ folderId: string }, AxiosResponse<{ downloadUrl: string }>>(
        `${process.env.REACT_APP_API_URL}/zipDocuments`,
        body,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.downloadUrl) {
          window.location.href = res.data.downloadUrl;
          handleMenuClose();
          setFileDownloading(false);
        }
      })
      .catch((err) => {
        showError(err.message);
        handleMenuClose();
      });
  };

  useEffect(() => {
    if (folderNameRef.current) {
      folderNameRef.current.focus();
    }
  }, [folderNameRef, editing]);

  useEffect(() => {
    if (folderProp) {
      setFolder(folderProp);
    }
  }, [folderProp]);

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
    setDeleteDocuments(false);
    setDeleteValue("");
  };

  const handleDialogChange = (e: any) => {
    e.preventDefault();
    setDeleteValue(e.target.value);
    if (e.target.value === "unassign") {
      setDeleteDocuments(false);
    } else if (e.target.value === "delete") {
      setDeleteDocuments(true);
    }
  };

  const DeleteFolderDialog = ({ folder }: any) => {
    return (
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{`Delete Folder: ${folder.name}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            What would you like to do with the{" "}
            {folder.documents.length === 1 ? "document" : "documents"} in{" "}
            {`${folder.name}?`}
          </DialogContentText>
          {folder.documents.length < 8 &&
            folder.documents.map((document: any) => (
              <Box
                style={{
                  display: "flex",
                  alignContent: "center",
                  marginBottom: "11px",
                }}
                key={document.id}
              >
                <FileTypeIcon filename={document.name} smallIcons={true} />
                <Typography style={{ marginLeft: "11px" }} variant="body2">
                  {document.name}
                </Typography>
              </Box>
            ))}
          <Select
            defaultValue="unassign"
            value={deleteValue}
            onChange={(e) => handleDialogChange(e)}
            style={{ width: "100%", marginTop: "6px", marginBottom: "8px" }}
          >
            <MenuItem value="unassign" key="unassign">
              Leave documents on the deal, but delete the folder
            </MenuItem>
            <MenuItem value="delete" key="delete">
              Delete these documents along with the folder
            </MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleDialogClose}>Cancel</SecondaryButton>
          <PrimaryButton onClick={() => handleFolderDelete()}>
            Delete
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div ref={drop}>
      <ListItem onClick={handleFolderClick}>
        <ListItemIcon style={{ alignItems: "center" }}>
          <ChevronRight
            fontSize="small"
            style={{
              opacity: folder.documents?.length > 0 ? 1 : 0,
              transform: openFolder ? "rotate(90deg)" : "initial",
            }}
          />
          {collectedProps.isOver ? (
            <FolderOpen color="primary" />
          ) : (
            <FolderIcon color="primary" />
          )}
        </ListItemIcon>
        {!editing && !newFolder ? (
          <ListItemText
            primary={folder.name}
            secondary={handleSecondaryText()}
            style={{ margin: 0 }}
          />
        ) : (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <input
              className={classes.textField}
              ref={folderNameRef}
              autoFocus
              name="Folder Name"
              placeholder={newFolder ? "Folder Name" : ""}
              onChange={(e) =>
                setFolder({
                  ...folder,
                  name: e.target.value,
                })
              }
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleDoneClick();
                }
              }}
              value={folder.name}
            />
            <IconButton onClick={handleDoneClick} size="large">
              <Done style={{ marginLeft: "5px" }} />
            </IconButton>
          </Box>
        )}
        {!isClient && (
          <ListItemSecondaryAction>
            {!reportView && (
              <IconButton onClick={(e: any) => handleMenuClick(e)} size="large">
                <MoreVert />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {openFolder && (
        <div style={{ maxWidth: "95%", marginLeft: "auto" }}>
          {folder.documents.map((document: any) => (
            <DocumentListItem
              key={document.id}
              dealId={dealId}
              isClient={isClient}
              document={document}
              refetch={refetch}
              isDraggableDocument={true}
            />
          ))}
        </div>
      )}

      <Menu
        id="documentMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleFolderEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit Folder Name" />
        </MenuItem>
        <MenuItem onClick={handleDownloadZip}>
          <ListItemIcon>
            {fileDownloading ? (
              <CircularProgress size={20} />
            ) : (
              <DownloadFilesIcon />
            )}
          </ListItemIcon>
          <ListItemText primary="Download Files as Zip" />
        </MenuItem>
        <MenuItem onClick={handleFolderDeleteClick}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
      {deleteDialogOpen && folderProp && (
        <DeleteFolderDialog folder={folderProp} />
      )}
    </div>
  );
};

export default Folder;
