import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Alert,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import SupportLink from "./SupportLink";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { PrimaryButton, SecondaryButton } from "./buttons";
import { ToggleButton, ToggleButtonGroup } from "./ToggleButtons";
import PhoneField from "./fields/PhoneField";
import ServicesAutocomplete from "./vendors/ServicesAutocomplete";
import { Tag, TeamMember } from "../models";
import { useAlert } from "../context/alert";
import { useUser } from "../context/user";

const CREATE_TEAM_MEMBER = gql`
  mutation CreateTeamMember($input: CreateTeamMemberInput!) {
    createTeamMember(input: $input) {
      id
      firstName
      lastName
      email
      address {
        street
        street2
        city
        state
        zip
      }
      avatarUrl
      preferred
      tags {
        id
        name
      }
    }
  }
`;

const UPDATE_TEAM_MEMBER = gql`
  mutation UpdateTeamMember($input: UpdateTeamMemberInput!) {
    updateTeamMember(input: $input) {
      id
      firstName
      lastName
      email
      avatarUrl
      preferred
      tags {
        id
        name
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    permissionSelect: {
      marginBottom: "4px",
      marginTop: "8px",
    },
    permissionsHeader: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

type CreateOrEditTeamMemberDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (teamMember: TeamMember) => void;
  teamMember: any;
  type: "team" | "vendors";
};

const CreateOrEditTeamMemberDialog = ({
  open,
  onClose,
  onSave,
  teamMember: initialTeamMemberState,
  type,
}: CreateOrEditTeamMemberDialogProps) => {
  const apollo = useApolloClient();
  const { currentUser } = useUser();
  const { showSuccess, showError } = useAlert();
  const classes = useStyles();

  const [teamMember, setTeamMember] = useState<any>({});
  useEffect(() => {
    setTeamMember({ ...initialTeamMemberState });
  }, [initialTeamMemberState]);
  const [updateTeamMember] = useMutation(UPDATE_TEAM_MEMBER);
  const [createTeamMember] = useMutation(CREATE_TEAM_MEMBER);
  const [atUserLimit, setAtUserLimit] = useState(false);

  const PermissionSelect = ({
    label,
    permission,
    permissionKey,
  }: {
    label: string;
    permission: string;
    permissionKey: string;
  }) => {
    return (
      <>
        <Typography
          color="textSecondary"
          className={classes.permissionSelect}
        >{`${label}`}</Typography>

        <ToggleButtonGroup
          exclusive
          value={permission}
          onChange={(e, value) => handlePermissionChange(permissionKey, value)}
        >
          <ToggleButton value="full">Full</ToggleButton>
          <ToggleButton value="limited">Limited</ToggleButton>
          <ToggleButton value="none">None</ToggleButton>
        </ToggleButtonGroup>
      </>
    );
  };

  const handleAddressEdit = (attribute: string, value: string) =>
    setTeamMember((teamMember: any) => ({
      ...teamMember,
      address: { ...teamMember.address, [attribute]: value },
    }));

  const handleEdit = (attribute: string, value: string | boolean) =>
    setTeamMember((teamMember: any) => ({
      ...teamMember,
      [attribute]: value,
    }));

  const handlePermissionChange = (attribute: string, value: string) => {
    setTeamMember((teamMember: any) => ({
      ...teamMember,
      user: {
        ...teamMember.user,
        permissions: { ...teamMember.user.permissions, [attribute]: value },
      },
    }));
  };

  const handleSave = (e: any) => {
    const variables: any = {
      input: {
        id: teamMember.id,
        firstName: teamMember.firstName,
        lastName: teamMember.lastName,
        phone: teamMember.phone,
        email: teamMember.email,
        title: teamMember.title,
        company: teamMember.company,
        permissions: teamMember.user?.permissions,
        preferred: teamMember.preferred,
        websiteUrl: teamMember.websiteUrl,
        tags: teamMember.tags?.length
          ? teamMember.tags.map((tag: Tag) =>
              typeof tag === "string"
                ? { name: tag }
                : { name: tag.name, id: tag.id }
            )
          : [],
      },
    };
    if (type === "vendors" && teamMember.address) {
      variables.input.address = {
        street: teamMember.address.street ?? "",
        street2: teamMember.address.street2 ?? "",
        city: teamMember.address.city ?? "",
        state: teamMember.address.state ?? "",
        zip: teamMember.address.zip ?? "",
      };
    }
    let promise: Promise<any>;
    if (teamMember.id) {
      promise = updateTeamMember({ variables });
    } else {
      if (type === "team") {
        variables.input.sendInvite = true;
      }
      promise = createTeamMember({ variables });
    }

    promise
      .then(
        (response) =>
          response.data.createTeamMember ?? response.data.updateTeamMember
      )
      .then(onSave)
      .then(() =>
        showSuccess(
          `The ${type === "team" ? "team member" : "vendor"} 
          "${teamMember.firstName} ${teamMember.lastName}" was saved`
        )
      )
      .then(onClose)
      .catch(() => {
        showError(`An error occurred when saving
         ${type === "team" ? "team member" : "vendor"}`);
      });
  };

  useEffect(() => {
    if (!open || teamMember.id) {
      return;
    }
    apollo
      .query({
        query: gql`
          query {
            me {
              account {
                atUserLimit
              }
            }
          }
        `,
      })
      .then((response) => {
        setAtUserLimit(response.data.me.account.atUserLimit);
      })
      .catch((error) => {
        // Do nothing
      });
  }, [open, teamMember, apollo]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {teamMember.id ? "Edit " : "Add a "}
        {type === "team" ? "Team Member" : "Vendor"}

        {type === "vendors" && (
          <Typography>
            Vendors are people or companies that you frequently work with (title
            companies, mortgage lenders, etc) that don't need a login to Shaker.
          </Typography>
        )}
        {type === "team" && (
          <Typography>
            Team members are part of your real estate team. We'll send an invite
            to them so that they can log into Shaker, create deals, and be
            assigned to tasks.
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              fullWidth
              autoFocus
              label="First name"
              value={teamMember.firstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleEdit("firstName", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              fullWidth
              label="Last name"
              value={teamMember.lastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleEdit("lastName", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              fullWidth
              label="Email address"
              value={teamMember.email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleEdit("email", e.target.value)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <PhoneField
              fullWidth
              label="Phone"
              value={teamMember.phone}
              onChange={(value: string | null) => {
                if (!value) {
                  value = "";
                }
                handleEdit("phone", value);
              }}
              settings={{ format: "us_can" }}
              hideIcon={true}
            />
          </Grid>
          {type === "vendors" && (
            <>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Company"
                  value={teamMember.company}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleEdit("company", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Title"
                  value={teamMember.title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleEdit("title", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Address"
                  value={teamMember.address?.street}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleAddressEdit("street", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Address 2"
                  value={teamMember.address?.street2}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleAddressEdit("street2", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="City"
                  value={teamMember.address?.city}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleAddressEdit("city", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="State"
                  value={teamMember.address?.state}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleAddressEdit("state", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Zip"
                  value={teamMember.address?.zip}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleAddressEdit("zip", e.target.value)
                  }
                />
              </Grid>
            </>
          )}

          {type === "vendors" && (
            <>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Website"
                  value={teamMember.websiteUrl}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleEdit("websiteUrl", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ServicesAutocomplete
                  value={teamMember.tags || []}
                  setValue={(tags) => handleEdit("tags", tags)}
                  label="Add Services"
                  freeSolo
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={teamMember.preferred}
                      onChange={(e) =>
                        handleEdit("preferred", !teamMember.preferred)
                      }
                      name="is-preferred-check"
                    />
                  }
                  label="Show this Preferred Vendor in the Client Portal"
                />
              </Grid>
            </>
          )}

          {teamMember.user && currentUser?.permissions?.settings === "full" && (
            <Grid item xs={12}>
              <div className={classes.permissionsHeader}>
                <Typography variant="h6">User Permissions</Typography>

                <SupportLink url="https://intercom.help/shakerio/en/articles/7436448-integrating-shaker-with-dotloop">
                  Guide to user permission settings
                </SupportLink>
              </div>

              <PermissionSelect
                label="Settings"
                permission={teamMember.user.permissions.settings}
                permissionKey="settings"
              />

              <PermissionSelect
                label="Contacts"
                permission={teamMember.user.permissions.contacts}
                permissionKey="contacts"
              />

              <PermissionSelect
                label="Deals"
                permission={teamMember.user.permissions.deals}
                permissionKey="deals"
              />

              <PermissionSelect
                label="Reports"
                permission={teamMember.user.permissions.reports}
                permissionKey="reports"
              />
            </Grid>
          )}
          {atUserLimit && !teamMember.id && type === "team" && (
            <Grid item xs={12}>
              <Alert severity="info">
                <strong>You've reached your user limit.</strong>&nbsp;We'll add
                a user license to your account when this team member accepts
                their invite.
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          onClick={onClose}
          color="primary"
          style={{ width: "100px" }}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={handleSave}
          color="primary"
          style={{ width: "100px" }}
        >
          {teamMember.id ? "Save" : "Add"}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrEditTeamMemberDialog;
