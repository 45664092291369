import { gql } from "@apollo/client";
import { ApiClient } from "../context/api";
import { FRAGMENT_STAGE_FIELDS } from "../api/graphql";
import { STAGES } from "../reducers/stages";

const CREATE_STAGE_FIELD = gql`
  mutation CreateStageField($input: CreateStageFieldInput!) {
    createStageField(input: $input) {
      ...StageFields
    }
  }
  ${FRAGMENT_STAGE_FIELDS}
`;

const UPDATE_STAGE_FIELD = gql`
  mutation UpdateStageField($input: UpdateStageFieldInput!) {
    updateStageField(input: $input) {
      ...StageFields
    }
  }
  ${FRAGMENT_STAGE_FIELDS}
`;

const DELETE_STAGE_FIELD = gql`
  mutation DeleteStageField($id: ID!) {
    deleteStageField(id: $id)
  }
`;

export type CreateStageFieldInput = {
  stageId: string;
  customFieldId?: string;
  columnName?: string;
  sort?: number;
};

export type UpdateStageFieldInput = {
  id: string;
  customFieldId?: string;
  columnName?: string;
  sort?: number;
};

export const createStageField =
  (stageField: CreateStageFieldInput, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: CREATE_STAGE_FIELD,
      variables: { input: { ...stageField } },
    });

    dispatch({
      type: STAGES.ADD_FIELD,
      stageField: response.data.createStageField,
      stageId: stageField.stageId,
    });
  };

export const deleteStageField =
  (id: string, stageId: string, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    await apolloClient.mutate({
      mutation: DELETE_STAGE_FIELD,
      variables: { id },
    });

    dispatch({ type: STAGES.REMOVE_FIELD, id, stageId });
  };

export const updateStageField =
  (
    stageField: UpdateStageFieldInput,
    stageId: string,
    apolloClient: ApiClient
  ) =>
  async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_STAGE_FIELD,
      variables: {
        input: stageField,
      },
    });

    const updatedStageField = response.data.updateStageField;

    dispatch({
      type: STAGES.UPDATE_FIELD,
      stageField: updatedStageField,
      stageId,
    });
  };
