import React, { FunctionComponent, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../buttons";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import StageForm from "../stages/StageForm";
import { RightSideDrawer } from "../RightSideDrawer";
import moment, { Moment } from "moment";
import { cleanCustomFieldValuesForInput } from "../../helpers/fields";

type StageChangeDialogProps = {
  open: boolean;
  deal: any;
  currentStage: any;
  newStage: any;
  fields?: any[];
  onSubmit: (stageFormData: any) => Promise<any>;
  onClose: () => any;
};

const StageChangeDialog: FunctionComponent<StageChangeDialogProps> = ({
  open,
  deal,
  currentStage,
  newStage,
  fields: _fields,
  onSubmit,
  onClose,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [stageFormData, setStageFormData] = useState<any>({ customFields: [] });

  const handleClose = () => {
    onClose();
  };

  const cleanCustomFields = (stageFormData: any) => {
    const customFields = cleanCustomFieldValuesForInput(
      stageFormData.customFields
    );
    return { ...stageFormData, customFields };
  };

  const cleanDateValues = (stageFormData: any) => {
    const cleanFormData = { ...stageFormData };
    Object.keys(cleanFormData).forEach((key: string) => {
      const value: any = cleanFormData[key];
      if (moment.isMoment(value)) {
        cleanFormData[key] = (value as Moment).toISOString();
      }
    });
    return cleanFormData;
  };

  const handleSubmit = async () => {
    setSubmitting(true);

    let formData = cleanDateValues(stageFormData);
    formData = cleanCustomFields(formData);
    onSubmit(formData)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (!newStage || !deal) {
    return <></>;
  }

  return (
    <RightSideDrawer open={open} anchor="right">
      <Box
        style={{
          minHeight: "64px",
          background: "#1A0B31",
          display: "flex",
          alignItems: "center",
          padding: "16px",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#fff",
            display: "flex",
            alignItems: "center",
          }}
          variant={"h3"}
        >
          <span>{deal?.name ?? deal?.address?.street ?? "Stage Change"}</span>
        </Typography>
      </Box>

      <Box
        p={4}
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {currentStage?.name ?? "None"}
              &nbsp;
              <ArrowRightAltIcon />
              &nbsp;
              {newStage.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StageForm
              stage={newStage}
              deal={deal}
              onChange={setStageFormData}
            />
          </Grid>
        </Grid>
      </Box>

      <Box p={2} style={{ background: "#F5F5F5" }}>
        <Grid container spacing={2} style={{}}>
          <Grid item xs={6}>
            <SecondaryButton fullWidth variant="outlined" onClick={handleClose}>
              Close
            </SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton
              fullWidth
              onClick={handleSubmit}
              disabled={submitting}
            >
              Move Deal to {newStage.name}
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
    </RightSideDrawer>
  );
};

export default StageChangeDialog;
