import { combineReducers } from "redux";
import appDataState from "./appDataState";
import dealTypes from "./dealTypes";
import stages from "./stages";
import roles from "./roles";
import contactTags from "./contactTags";
import vendorTags from "./vendorTags";
import notification from "./notification";
import reports from "./reports";
import features from "./features";
import account from "./account";
import availableIntegrations from "./availableIntegrations";
import mlses from "./mlses";

export default combineReducers({
  appDataState,
  account,
  dealTypes,
  stages,
  roles,
  contactTags,
  vendorTags,
  notification,
  reports,
  features,
  availableIntegrations,
  mlses,
});
