import React, { useState, useEffect } from "react";
import { Snackbar } from "@mui/material";
import AlertUI from "@mui/material/Alert";

interface Alert {
  type: "success" | "error";
  message: string;
}
const AlertContext = React.createContext<any>(null);

function AlertProvider(props: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<Alert | null>(null);

  const showSuccess = (message: string) => {
    setAlert({
      type: "success",
      message,
    });
  };

  const showError = (message: string) => {
    setAlert({
      type: "error",
      message,
    });
  };

  useEffect(() => {
    if (alert) {
      setOpen(true);
    }
  }, [alert]);

  return (
    <AlertContext.Provider value={{ showSuccess, showError }} {...props}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        open={open}
        onClose={(e) => setOpen(false)}
      >
        <AlertUI severity={alert?.type}>{alert?.message}</AlertUI>
      </Snackbar>
      {props.children}
    </AlertContext.Provider>
  );
}

const useAlert = () => React.useContext(AlertContext);
export { AlertProvider, AlertContext, useAlert };
