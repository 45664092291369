import { gql } from "@apollo/client";
import { ApiClient } from "../context/api";
import { Role } from "../models";
import { ROLES } from "../reducers/roles";

const CREATE_ROLE = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      id
      name
      defaultTeamMemberId
      isBuyersAgent
      isListingAgent
    }
  }
`;

const UPDATE_ROLE = gql`
  mutation CreateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
      name
      defaultTeamMemberId
      isBuyersAgent
      isListingAgent
    }
  }
`;

const DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id)
  }
`;

export type CreateRoleInput = {
  name: string;
  defaultTeamMemberId?: string | null;
  dealTypeIds?: string[];
};

export type UpdateRoleInput = {
  id: string;
  name: string;
  defaultTeamMemberId?: string | null;
  dealTypeIds?: string[];
};

export const setRoles = (roles: Role[]) => async (dispatch: any) =>
  dispatch({ type: ROLES.SET, roles });

export const createRole =
  (role: CreateRoleInput, apolloClient: ApiClient) => async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: CREATE_ROLE,
      variables: { input: role },
    });

    dispatch({ type: ROLES.ADD, role: response.data.createRole });
  };

export const deleteRole =
  (role: Role, apolloClient: ApiClient) => async (dispatch: any) => {
    await apolloClient.mutate({
      mutation: DELETE_ROLE,
      variables: { id: role.id },
    });

    dispatch({ type: ROLES.REMOVE, role });
  };

export const updateRole =
  (role: UpdateRoleInput, apolloClient: ApiClient) => async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_ROLE,
      variables: {
        input: role,
      },
    });

    const updatedRole = response.data.updateRole;

    dispatch({ type: ROLES.UPDATE, role: updatedRole });
  };
