import { gql } from "@apollo/client";
import { ApiClient } from "../context/api";
import { CONTACT_TAGS } from "../reducers/contactTags";

const GET_CONTACT_TAGS = gql`
  query GetTags {
    tags(type: "contact") {
      id
      name
    }
  }
`;

export const refreshContactTags =
  (apolloClient: ApiClient) => async (dispatch: any) => {
    const response = await apolloClient.query({ query: GET_CONTACT_TAGS });

    dispatch({ type: CONTACT_TAGS.SET, contactTags: response.data.tags });
  };
