import React, { useState, useEffect } from "react";
import { Container, Typography, CircularProgress, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { SET_SUBSCRIBED } from "../api/graphql";
import { useMutation } from "@apollo/client";
import { PrimaryButton } from "../components/buttons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
    },
    logo: {
      height: "25px",
      marginBottom: theme.spacing(4),
    },
    primaryText: {
      marginBottom: theme.spacing(4),
    },
    secondaryText: {
      marginBottom: theme.spacing(1),
    },
  })
);

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

const Unsubscribe = () => {
  const classes = useStyles();

  let navigate = useNavigate();
  const [subscription, setSubscription] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [resubscribe, setResubscribe] = useState<boolean>(false);
  const [setSubscribed, { data }] = useMutation(SET_SUBSCRIBED);

  const contactId = useQueryParams().get("c") || null;
  const teamMemberId = useQueryParams().get("t") || null;
  const success = data?.setAllEmailsEnabled;

  useEffect(() => {
    if (contactId || teamMemberId) {
      setLoading(true);
      setSubscribed({
        variables: {
          teamMemberId: teamMemberId,
          contactId: contactId,
          subscribed: subscription,
        },
      })
        .then((res) => {
          setLoading(false);
          if (res.data.setAllEmailsEnabled) {
            if (subscription === true) {
              setResubscribe(true);
            }
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    } else {
      navigate("/");
    }
  }, [navigate, subscription, contactId, teamMemberId, setSubscribed]);

  return (
    <Container maxWidth="sm" className={classes.pageContainer}>
      {loading ? (
        <CircularProgress />
      ) : (
        success && (
          <>
            <img
              src="/logo512.png"
              alt="Shaker logo"
              title="Shaker"
              className={classes.logo}
            />
            {!resubscribe ? (
              <div>
                <Typography className={classes.primaryText} variant="h2">
                  Got it! We&apos;ve unsubscribed you.
                </Typography>
                <PrimaryButton onClick={() => setSubscription(true)}>
                  Resubscribe?
                </PrimaryButton>
              </div>
            ) : (
              <Typography className={classes.primaryText} variant="h2">
                Whew! That was close. We&apos;ve resubscribed you.
              </Typography>
            )}
          </>
        )
      )}
    </Container>
  );
};

export default Unsubscribe;
