import { gql } from "@apollo/client";
import { ApiClient } from "../context/api";
import { STAGES } from "../reducers/stages";

const CREATE_STAGE_TASK = gql`
  mutation CreateStageTask($input: CreateStageTaskInput!) {
    createStageTask(input: $input) {
      id
      name
      toDealContacts
      toRoleId
      hasDueDate
      dueDateDays
      dueDatePreposition
      dueDateTargetColumnName
      dueDateTargetCustomFieldId
      sort
    }
  }
`;

const UPDATE_STAGE_TASK = gql`
  mutation UpdateStageTask($input: UpdateStageTaskInput!) {
    updateStageTask(input: $input) {
      id
      name
      toDealContacts
      toRoleId
      hasDueDate
      dueDateDays
      dueDatePreposition
      dueDateTargetColumnName
      dueDateTargetCustomFieldId
      sort
    }
  }
`;

const DELETE_STAGE_TASK = gql`
  mutation DeleteStageTask($id: ID!) {
    deleteStageTask(id: $id)
  }
`;

export type CreateStageTaskInput = {
  stageId: string;
  toRoleId: string;
  toDealContacts: boolean;
  name: string;
  hasDueDate: boolean;
  dueDateDays: number;
  dueDatePreposition: string;
  dueDateTargetColumnName: string;
  dueDateTargetCustomFieldId: string;
};

export type UpdateStageTaskInput = {
  id: string;
  toRoleId?: string;
  toDealContacts?: boolean;
  hasDueDate?: boolean;
  dueDateDays?: number;
  dueDatePreposition?: string;
  dueDateTargetColumnName?: string;
  dueDateTargetCustomFieldId?: string;
  name?: string;
  sort?: number;
};

export const createStageTask =
  (stageTask: CreateStageTaskInput, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: CREATE_STAGE_TASK,
      variables: { input: { ...stageTask } },
    });

    dispatch({
      type: STAGES.ADD_TASK,
      stageTask: response.data.createStageTask,
      stageId: stageTask.stageId,
    });
  };

export const deleteStageTask =
  (id: string, stageId: string, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    await apolloClient.mutate({
      mutation: DELETE_STAGE_TASK,
      variables: { id },
    });

    dispatch({ type: STAGES.REMOVE_TASK, id, stageId });
  };

export const updateStageTask =
  (stageTask: UpdateStageTaskInput, stageId: string, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_STAGE_TASK,
      variables: {
        input: stageTask,
      },
    });

    const updatedStageTask = response.data.updateStageTask;

    dispatch({
      type: STAGES.UPDATE_TASK,
      stageTask: updatedStageTask,
      stageId,
    });
  };
