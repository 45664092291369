import React from "react";
import { Typography, Grid, Link } from "@mui/material";
import { SecondaryButton } from "../../../components/buttons";
import { AddIcon, DocumentIcon } from "../../../icons";

type setOpenProps = {
  setOpen?: (value: boolean) => void | undefined;
  dealTypeName?: string | undefined;
};

export const DocumentChecklist = ({ setOpen, dealTypeName }: setOpenProps) => (
  <>
    <Grid container justifyContent="space-between" columns={{ sm: 2 }}>
      <Grid item>
        <Grid container alignItems="center">
          <DocumentIcon
            style={{
              background: "#0B8D9B",
              color: "#fff",
              padding: "4.5px 4.5px",
              borderRadius: "50%",
              height: "22px",
              width: "22px",
              marginRight: "12px",
            }}
          />
          <Typography variant="h5">Document Checklist</Typography>
        </Grid>
      </Grid>
      <SecondaryButton onClick={setOpen}>
        <AddIcon />
      </SecondaryButton>
    </Grid>

    {setOpen && (
      <>
        <Typography
          color="textSecondary"
          style={{
            paddingBottom: 11,
            fontSize: "14px",
            wordWrap: "break-word",
          }}
          align="center"
        >
          {`Build your document checklist for ${dealTypeName} deals by dragging
          available document types from the left`}{" "}
          <Link
            onClick={() => setOpen(true)}
            sx={{ color: "#0B8D9B", cursor: "pointer" }}
          >
            or create a custom Document Type
          </Link>
        </Typography>
      </>
    )}
  </>
);
