import { Chip, Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

const StyledChip = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: ({ colorhex }: any) => colorhex,
      borderColor: ({ colorhex }: any) => colorhex,
    },
    label: {
      fontWeight: 600,
      color: ({ colorhex }: any) =>
        colorhex !== "#E8E2E1" ? "#fafafa" : "#323131",
      padding: "6px 9px",
    },
  })
)(Chip);

export default StyledChip;
