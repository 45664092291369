import React from "react";
import { Card, Theme, Typography, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Link } from "react-router-dom";
import { ListingPriceIcon, StageIcon, CalendarIcon } from "../../icons";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: { textDecoration: "none" },
    root: { margin: "16px 0", padding: "24px", display: "flex" },
    propertyPhotoContainer: {
      width: "185px",
      height: "128px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
      overflow: "hidden",
      position: "relative",
    },
    photoImg: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    placeholder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    placeholderImg: {
      width: "100%",
    },
    typeTag: {
      position: "absolute",
      bottom: "8px",
      left: "8px",
      height: "24px",
      paddingTop: "1px",
      color: "#ffffff",
      fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      fontSize: "13px",
      textTransform: "uppercase",
      backgroundColor: "rgb(75, 177, 236)",
    },
    address: {
      marginBottom: "16px",
    },
    propertyInfo: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "0 40px",
    },
    propertyStatuses: {
      display: "flex",
      flexDirection: "row",
    },
    propertyStatus: {
      marginRight: "32px",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",

      "& svg": {
        margin: "-2px 8px 0 0",
        color: theme.palette.grey[600],
      },
    },
    updated: {
      display: "flex",
      alignItems: "center",

      "& p": {
        fontSize: "14px",
        color: theme.palette.grey[600],
      },
    },
  })
);

type ClientDealCardProps = {
  deal: any;
};

const ClientDealCard = ({ deal }: ClientDealCardProps) => {
  const classes = useStyles();

  const renderDealPhoto = () => {
    let image;
    if (deal.photoUrl) {
      image = (
        <img className={classes.photoImg} alt={deal.name} src={deal.photoUrl} />
      );
    } else {
      image = (
        <div className={classes.placeholder}>
          <img
            src="/images/deal-placeholder-photo.png"
            className={classes.placeholderImg}
            alt="Placeholder"
          />
        </div>
      );
    }

    const typeTag = (
      <Chip className={classes.typeTag} label={deal.dealType.clientNoun} />
    );

    return (
      <>
        {image}
        {typeTag}
      </>
    );
  };

  const formatAddress = () => {
    let cityState = [];
    if (deal.address.city?.length > 0) cityState.push(deal.address.city);
    if (deal.address.state?.length > 0) cityState.push(deal.address.state);

    const line2 = [cityState.join(", "), deal.address.zip].join(" ");

    return (
      <>
        {(deal.address.street &&
          `${deal.address.street}${
            deal.address.street2 && `, ${deal.address.street2}`
          }`) ||
          deal.name}
        {line2.length > 1 ? `, ${line2}` : ""}
      </>
    );
  };

  const renderValue = () => {
    const value = deal.listPrice || deal.salePrice || deal.value;

    if (!value) {
      return <></>;
    }

    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    const formattedValue = currencyFormatter.format(value);

    return (
      <Typography variant="body2" className={classes.propertyStatus}>
        <ListingPriceIcon size="16px" />
        {formattedValue}
      </Typography>
    );
  };

  const renderDate = () => {
    const date = deal.listDate;

    if (!date) {
      return <></>;
    }

    const formattedDate = moment(date, "YYYY-MM-DD").format("LL");

    return (
      <Typography variant="body2" className={classes.propertyStatus}>
        <CalendarIcon size="16px" />
        {formattedDate}
      </Typography>
    );
  };

  const renderStage = () => {
    return (
      <Typography variant="body2" className={classes.propertyStatus}>
        <StageIcon size="16px" />
        {deal.stage.name}
      </Typography>
    );
  };

  const renderUpdated = () => {
    return (
      <Typography variant="body2">
        Updated {moment(deal.updatedAt, "YYYY-MM-DD").fromNow()}
      </Typography>
    );
  };

  return (
    <Link key={deal.id} to={`/portal/deal/${deal.id}`} className={classes.link}>
      <Card className={classes.root}>
        <div className={classes.propertyPhotoContainer}>
          {renderDealPhoto()}
        </div>

        <div className={classes.propertyInfo}>
          <Typography variant="h4" className={classes.address}>
            {formatAddress()}
          </Typography>

          <div className={classes.propertyStatuses}>
            {renderValue()}
            {renderDate()}
            {renderStage()}
          </div>
        </div>

        <div className={classes.updated}>{renderUpdated()}</div>
      </Card>
    </Link>
  );
};

export default ClientDealCard;
