import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useCustomSearchParams = (
  initialValues: any = {},
  isContacts?: boolean
) => {
  const [searchParams, setSearchParams] = useSearchParams(initialValues);
  const getParamValue = useCallback(
    (key: string) => searchParams.get(key),
    [searchParams]
  );
  const currentParams = Object.fromEntries(Array.from(searchParams));
  const setParams = useCallback(
    (newValue: { [key: string]: any }) => {
      setSearchParams({ ...currentParams, ...newValue });
    },
    [currentParams, setSearchParams]
  );

  const parsedParams = useMemo(() => {
    let state: any;
    if (isContacts) {
      const {
        term,
        tags,
        ownerId,
        orderDirection,
        orderBy,
        page = "0",
        pageSize = "25",
      } = Object.fromEntries(Array.from(searchParams));
      state = {
        term,
        tags,
        ownerId,
        orderBy: orderBy === "0" || orderBy === "2" ? orderBy : "",
        orderDirection:
          orderDirection === "asc" || orderDirection === "desc"
            ? orderDirection
            : "",
        pageSize: parseInt(pageSize, 10),
        page: parseInt(page, 10),
      };
    } else {
      const {
        includeArchived,
        showAll,
        dealTypeId,
        term,
        page,
        pageSize,
        ...restOfParams
      } = Object.fromEntries(Array.from(searchParams));
      state = {
        includeArchived: includeArchived === "true",
        showAll: showAll === "true",
        dealTypeId: dealTypeId,
        term: term,
        page: parseInt(page, 10),
        pageSize: parseInt(pageSize, 10),
        ...restOfParams,
      };
    }
    return state;
  }, [searchParams, isContacts]);

  return { parsedParams, getParamValue, setParams };
};
