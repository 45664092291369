import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Theme,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import DealCard from "./DealCard";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import clsx from "clsx";
import moment from "moment";
import { Sort } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    column: {
      width: `${290 + Number(theme.spacing(3).slice(0, -2)) * 2}px`,
      borderRadius: theme.spacing(2),
      padding: `${theme.spacing(2)} 0 ${theme.spacing(3)} 0`,
      maxHeight: "100%",
      overflowY: "hidden",
      "& ul": {
        listStyleType: "none",
        padding: 0,
      },
      "& .sortButton": {
        visibility: "hidden",
      },
      "&:hover": {
        "& .sortButton": {
          visibility: "visible",
        },
      },
    },
    stageName: { padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(3)}` },
    totalValue: { color: theme.palette.grey[700] },
    scrollableDealsColumn: {
      maxHeight: "100%",
      padding: `0 ${theme.spacing(3)}`,
    },
    isHovered: {
      backgroundColor: "#F2F2F2",
      "& $stageName": {
        color: "#34254B",
      },
    },
    selectedDeal: {
      opacity: 0.2,
    },
    columnHeader: {
      display: "flex",
      alignItems: "center",
    },
  })
);

type StageColumnProps = {
  stage: any;
  deals: any[];
  selectedDeal?: any;
  onDealDragStart: (event: any, deal: any) => any;
  onDealDrop: (event: any, stage: any) => void;
  onDealDelete?: (deal: any) => any;
  onDealArchive?: (deal: any) => any;
  dealsLoading?: boolean;
  allowSort?: boolean;
  showDualProgress?: boolean;
};

const StageColumn = ({
  stage,
  deals: _deals,
  selectedDeal,
  onDealDragStart,
  onDealDrop,
  onDealDelete,
  onDealArchive,
  allowSort = false,
  dealsLoading = false,
  showDualProgress = false,
}: StageColumnProps) => {
  const COLUMN_SORT_KEY = `stage${stage.id}Sort`;
  const classes = useStyles();
  const [deals, setDeals] = useState<Array<any>>(_deals || []);
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = useState(
    window.localStorage.getItem(COLUMN_SORT_KEY) ?? "closeDate"
  );

  const handleDealDelete = (deal: any) => {
    if (!onDealDelete) {
      return;
    }
    onDealDelete(deal);
  };

  const handleDealArchive = (deal: any) => {
    if (!onDealArchive) {
      return;
    }
    onDealArchive(deal);
  };

  const totalValue = () => {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });

    const total = deals.reduce((total, deal) => total + (deal.value || 0), 0);
    return currencyFormatter.format(total);
  };

  const compareFunction = useCallback(
    (a: any, b: any) => {
      const aValue = a[sortBy] ?? undefined;
      const bValue = b[sortBy] ?? undefined;
      // Nulls
      if (!aValue && !bValue) {
        return 0;
      } else if (aValue && !bValue) {
        return -1;
      } else if (bValue && !aValue) {
        return 1;
      }
      if (sortBy === "closeDate" || sortBy === "listingDate") {
        // Date
        const momentA = moment(aValue);
        const momentB = moment(bValue);
        return momentA.toDate().getTime() - momentB.toDate().getTime();
      } else {
        // Number
        return aValue - bValue;
      }
    },
    [sortBy]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (columnName: string) => {
    setSortBy(columnName);
    window.localStorage.setItem(COLUMN_SORT_KEY, columnName);
    handleClose();
  };

  useEffect(() => {
    _deals.sort(compareFunction);
    setDeals(_deals);
  }, [_deals, compareFunction]);

  useEffect(() => {
    setDeals((deals) => {
      const copy = [...deals];
      copy.sort(compareFunction);
      return copy;
    });
  }, [compareFunction]);

  return (
    <Box
      key={stage.id}
      className={clsx({
        [classes.column]: true,
        [classes.isHovered]: isHovered,
      })}
      p={2}
      onDrop={(e) => {
        setIsHovered(false);
        onDealDrop(e, stage);
      }}
      onDragEnter={() => setIsHovered(true)}
      onDragLeave={() => setIsHovered(false)}
      onDragOver={(e) => {
        e.preventDefault();
        setIsHovered(true);
        return false;
      }}
    >
      <div className={classes.columnHeader}>
        <Typography variant="h6" className={classes.stageName}>
          {stage.name}{" "}
        </Typography>
        <Typography variant="body1" className={classes.totalValue}>
          {totalValue()}
        </Typography>
        {allowSort && (
          <>
            <IconButton
              title="Sort Column By..."
              className="sortButton"
              size="small"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <Sort fontSize="small" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                selected={sortBy === "listingDate"}
                onClick={() => handleSort("listingDate")}
              >
                Listing Date
              </MenuItem>
              <MenuItem
                selected={sortBy === "closeDate"}
                onClick={() => handleSort("closeDate")}
              >
                Closing Date
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
      <SimpleBar className={classes.scrollableDealsColumn}>
        <ul>
          {deals.map((deal: any) => (
            <li
              className={clsx({
                [classes.selectedDeal]: selectedDeal?.id === deal.id,
              })}
              key={`dealCard${deal.id}`}
              draggable
              onDragStart={(e) => onDealDragStart(e, deal)}
            >
              <DealCard
                onDealDelete={handleDealDelete}
                onDealArchive={handleDealArchive}
                deal={deal}
                showDualProgress={showDualProgress}
              />
            </li>
          ))}
        </ul>
      </SimpleBar>
    </Box>
  );
};

export default StageColumn;
