import React, { FunctionComponent, useEffect, useState } from "react";
import MainLayout from "./MainLayout";
import { ListItem } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { AppDrawerListItemIcon, AppDrawerText } from "../components/AppDrawer";
import { DashboardIcon, ContactsIcon as VendorsIcon } from "../icons";
import { gql, useQuery } from "@apollo/client";

type ClientPortalLayoutProps = {
  loading?: boolean;
  withoutPadding?: boolean;
  children?: React.ReactNode;
};

export const ME_CONTACTS_ACCOUNTS = gql`
  query MeContactsAccounts {
    me {
      contacts {
        account {
          id
          name
          logoUrl
        }
      }
    }
    getVendors {
      id
    }
  }
`;

const ClientPortalLayout: FunctionComponent<ClientPortalLayoutProps> = ({
  children,
  ...mainLayoutProps
}) => {
  const { data } = useQuery(ME_CONTACTS_ACCOUNTS);
  const location = useLocation();
  const [accounts, setAccounts] =
    useState<Array<{ id: string; name: string; logoUrl: string | null }>>();

  useEffect(() => {
    const contacts = data?.me?.contacts || [];
    setAccounts(contacts.map((contact: any) => contact.account));
  }, [data]);

  const isSelected = (key: string) => {
    if (key === "") {
      return location.pathname === "/portal";
    } else {
      let toMatch = location.pathname;
      if (key.indexOf("#") !== -1) {
        toMatch += location.hash;
      }
      return toMatch.indexOf(`/portal/${key}`) === 0;
    }
  };

  const PortalLogo = () => {
    let currentAccount: any | undefined;
    if (accounts?.length === 1) {
      currentAccount = accounts[0];
    }
    if (!currentAccount?.logoUrl) {
      return (
        <img
          src="/logo-full.svg"
          alt="Shaker logo"
          title="Shaker"
          style={{ maxHeight: "25px", width: "auto" }}
        />
      );
    }
    return (
      <img
        src={currentAccount.logoUrl}
        alt={currentAccount.name + " logo"}
        aria-label={currentAccount.name + " logo"}
        title={currentAccount.name}
        style={{ maxHeight: "48px", width: "auto" }}
      />
    );
  };

  const appDrawerListItems = () => {
    return (
      <>
        <ListItem
          button
          component={RouterLink}
          to="/portal"
          selected={isSelected("")}
        >
          <AppDrawerListItemIcon>
            <DashboardIcon size="16px" />
          </AppDrawerListItemIcon>
          <AppDrawerText>My Properties</AppDrawerText>
        </ListItem>

        {data?.getVendors.length > 0 && (
          <ListItem
            button
            component={RouterLink}
            to="/portal/vendors"
            selected={isSelected("vendors")}
          >
            <AppDrawerListItemIcon>
              <VendorsIcon size="16px" />
            </AppDrawerListItemIcon>
            <AppDrawerText>Vendors</AppDrawerText>
          </ListItem>
        )}
      </>
    );
  };

  return (
    <MainLayout
      {...mainLayoutProps}
      logo={<PortalLogo />}
      appDrawerListItems={appDrawerListItems()}
    >
      {children}
    </MainLayout>
  );
};

export default ClientPortalLayout;
