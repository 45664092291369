export interface AddressProps {
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export const getStreetString = (address: AddressProps) => {
  let streetStr = address.street ?? "";
  if (address.street2) {
    if (streetStr.length) {
      streetStr += ", ";
    }
    streetStr += address.street2;
  }
  return streetStr;
};

export const getCityStateString = (address: AddressProps) => {
  let cityStateStr = address.city ?? "";
  if (address.state) {
    if (cityStateStr.length) {
      cityStateStr += ", ";
    }
    cityStateStr += address.state;
  }

  return address.zip ? `${cityStateStr} ${address.zip}` : cityStateStr;
};

export const getFullAddress = (address: AddressProps) => {
  const streetAddress = getStreetString(address);
  const cityStateAddress = getCityStateString(address);
  return streetAddress.length
    ? `${streetAddress}, ${cityStateAddress}`
    : cityStateAddress;
};
