import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Grid,
} from "@mui/material";
import DetailCard from "../DetailCard";
import { Box } from "@mui/system";
import { LinearProgressWithLabel } from "../LinearProgressWithLabel";
import { RowDataTypes } from "../../models";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export const intToFriendlyString = (value: number) => {
  const suffixes = ["", "K", "M", "B", "T"];
  const suffixNum = Math.floor(("" + Math.round(value)).length / 3);
  const shortValue = parseFloat(
    (suffixNum !== 0
      ? Math.round(value) / Math.pow(1000, suffixNum)
      : value
    ).toPrecision(2)
  );
  let formatted =
    shortValue % 1 !== 0 ? shortValue.toFixed(1) : shortValue.toString();
  formatted += suffixes[suffixNum];
  return `$${formatted}`;
};

const DisplayCell = ({
  children,
  isUnits,
}: {
  children: any;
  isUnits?: boolean;
}) => {
  const teamMemberData = children[0]; // user data
  const totalTeamData = children[1]; // goal or team total data
  const percentCalc = teamMemberData / totalTeamData;
  const noGoalsCheck = totalTeamData === 0 || totalTeamData === null;
  return (
    <>
      <Grid display="flex" flexDirection="column" m={"auto"} xs={10}>
        {noGoalsCheck ? (
          <Typography variant="body2">No Goals Set</Typography>
        ) : (
          <>
            {
              <LinearProgressWithLabel
                percent={percentCalc}
                reportsProgressBar
              />
            }
          </>
        )}
        {!noGoalsCheck ? (
          <Stack>
            <Typography fontWeight="700" variant="body2">
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {percentCalc > 1 && (
                  <TaskAltIcon
                    color="success"
                    fontSize="small"
                    sx={{
                      marginRight: "3px",
                    }}
                  />
                )}
                {`${Math.round(percentCalc * 100)}%`}
              </Stack>
            </Typography>
            <Box display="flex" justifyContent="center">
              <Typography variant="body2">
                {!noGoalsCheck && (
                  <>
                    {isUnits
                      ? teamMemberData
                      : intToFriendlyString(teamMemberData)}{" "}
                    {`of
              ${
                isUnits
                  ? `${totalTeamData} Units`
                  : intToFriendlyString(children[1])
              }`}
                  </>
                )}
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Stack>
            <Typography variant="body2">
              {!noGoalsCheck && (
                <>
                  {isUnits
                    ? teamMemberData
                    : intToFriendlyString(teamMemberData)}{" "}
                  {`of
              ${
                isUnits
                  ? `${totalTeamData} Units`
                  : intToFriendlyString(totalTeamData)
              }`}
                </>
              )}
            </Typography>
          </Stack>
        )}
      </Grid>
    </>
  );
};

const TeamLeaderboardReport = ({
  data,
  month,
  isMonthly,
  goals,
  leaderboardType,
}: {
  data: any;
  month: number;
  isMonthly: boolean;
  goals: any;
  leaderboardType: "percentToGoal" | "percentOfTeam";
}) => {
  const tableType = leaderboardType === "percentToGoal" ? "goal" : "team";
  const isGoal = tableType === "goal";
  const renderData = (
    rowData: RowDataTypes,

    dataType: string
  ) => {
    const getKey = `${tableType}${dataType}`;
    const dataOptions = {
      goalUnits: isMonthly
        ? goals.monthly[month - 1].units
        : rowData.teamMember.goals.yearly.units,
      teamUnits: isMonthly
        ? data.years[0].months[month - 1].units.total
        : data.years[0].units.total,
      goalVolume: isMonthly
        ? goals.monthly[month - 1].volume
        : rowData.teamMember.goals.yearly.volume,
      teamVolume: isMonthly
        ? data.years[0].months[month - 1].volume.total
        : data.years[0].volume.total,
      goalCommission: isMonthly
        ? goals.monthly[month - 1].commission
        : rowData.teamMember.goals.yearly.commission,
      teamCommission: isMonthly
        ? data.years[0].months[month - 1].commission.total
        : data.years[0].commission.total,
    };
    return dataOptions[getKey as keyof typeof dataOptions];
  };
  return (
    <>
      <DetailCard
        title={
          <Typography variant="h4">
            {leaderboardType === "percentToGoal"
              ? "Percent To Goal"
              : "Percent of Team"}
          </Typography>
        }
      >
        <Box p={4}>
          <TableContainer>
            <Table>
              <TableHead sx={{ background: "#0b8d9b" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#f5f5f5",
                      borderRight: "1px solid #f5f5f5",
                    }}
                    align="center"
                  >
                    Agent
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#f5f5f5",
                      textAlign: "center",
                      borderRight: "1px solid #f5f5f5",
                    }}
                  >
                    Units to Goal
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#f5f5f5",
                      textAlign: "center",
                      borderRight: "1px solid #f5f5f5",
                    }}
                  >
                    Volume to Goal
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderColor: "#f5f5f5",
                      textAlign: "center",
                    }}
                  >
                    Commission to Goal
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.groups?.map((rowData: any, index: number) => (
                  <TableRow key={`teamMemberRow${index}`}>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        background: "#0b8d9b14",
                        fontWeight: "bold",
                        borderColor: "#e0e0e0",
                      }}
                    >
                      <Typography>{rowData.teamMember.name}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        maxWidth: "200px",
                      }}
                    >
                      <DisplayCell isUnits>
                        {isMonthly
                          ? rowData.years[0].months[month - 1].units.total
                          : rowData.years[0].units.total}
                        {renderData(rowData, "Units")}
                      </DisplayCell>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        maxWidth: "200px",
                      }}
                    >
                      <DisplayCell>
                        {isMonthly
                          ? rowData.years[0].months[month - 1].volume.total
                          : rowData.years[0].volume.total}
                        {renderData(rowData, "Volume")}
                      </DisplayCell>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        maxWidth: "200px",
                      }}
                    >
                      <DisplayCell>
                        {isMonthly
                          ? rowData.years[0].months[month - 1].commission.total
                          : rowData.years[0].commission.total}
                        {renderData(rowData, "Commission")}
                      </DisplayCell>
                    </TableCell>
                  </TableRow>
                ))}
                {isGoal && (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#fff",
                        whiteSpace: "nowrap",
                        background: "#0b8d9b",
                        fontWeight: "bold",
                      }}
                    >
                      Team Total
                    </TableCell>

                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        borderTop: "2px solid #e0e0e0",
                        background: "#cee7ec",
                      }}
                      align="center"
                    >
                      <DisplayCell isUnits>
                        {isMonthly
                          ? data?.years[0].months[month - 1].units.total ?? 0
                          : data?.years[0].units.total ?? 0}

                        {isMonthly
                          ? goals?.monthly[month - 1].units
                          : goals?.yearly.units}
                      </DisplayCell>
                    </TableCell>

                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        borderBottom: "1px solid #e0e0e0",
                        borderRight: "1px solid #e0e0e0",
                        background: "#cee7ec",
                      }}
                      align="center"
                    >
                      <DisplayCell>
                        {isMonthly
                          ? data?.years[0].months[month - 1].volume.total ?? 0
                          : data?.years[0].volume.total ?? 0}

                        {isMonthly
                          ? goals?.monthly[month - 1].volume
                          : goals?.yearly.volume}
                      </DisplayCell>
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        borderBottom: "1px solid #e0e0e0",
                        borderRight: "1px solid #e0e0e0",
                        background: "#cee7ec",
                      }}
                      align="center"
                    >
                      <DisplayCell>
                        {isMonthly
                          ? data?.years[0].months[month - 1].commission.total ??
                            0
                          : data?.years[0].commission.total ?? 0}
                        {isMonthly
                          ? goals?.monthly[month - 1].commission
                          : goals?.yearly.commission}
                      </DisplayCell>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DetailCard>
    </>
  );
};

export default TeamLeaderboardReport;
