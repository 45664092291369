import { useQuery } from "@apollo/client";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { GET_EMAIL_TEMPLATES } from "../../api/graphql";
import TeamMemberSelect from "../TeamMemberSelect";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

enum FromType {
  ContactOwner = "contactOwner",
  TeamMember = "teamMember",
}

type EmailSettingsProps = {
  step: any;
  setStep: (step: any) => any;
};

const EmailSettings: FunctionComponent<EmailSettingsProps> = ({
  step,
  setStep,
}) => {
  const { data } = useQuery(GET_EMAIL_TEMPLATES);
  const [fromType, setFromType] = useState<FromType>(FromType.ContactOwner);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<
    any | null
  >(null);

  useEffect(() => {
    if (!data?.getEmailTemplates) {
      return;
    }
    setSelectedEmailTemplate(
      data.getEmailTemplates.find(
        (emailTemplate: any) => emailTemplate.id === step.emailTemplateId
      ) || null
    );
  }, [data, step.emailTemplateId]);

  useEffect(() => {
    const fromType = step.teamMemberId
      ? FromType.TeamMember
      : FromType.ContactOwner;
    setFromType(fromType);
  }, [step]);

  if (!step || !data) {
    return <></>;
  }

  const handleChange = (e: ChangeEvent<{}>, emailTemplate: any | null) => {
    setSelectedEmailTemplate(emailTemplate);
    setStep({ ...step, emailTemplateId: emailTemplate?.id || null });
  };

  const handleTeamMemberChange = (teamMember: any) => {
    setStep({ ...step, teamMemberId: teamMember?.id || null });
  };

  const handleFromChange = (e: any, value: string) => {
    const fromType = value as FromType;
    if (fromType === FromType.ContactOwner) {
      setStep({ ...step, teamMemberId: null });
    }
    setFromType(fromType);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          value={selectedEmailTemplate}
          onChange={handleChange}
          options={data.getEmailTemplates}
          getOptionLabel={(option: any) => (option?.name ? option.name : "")}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label="Email template"
              placeholder="Select an email template&hellip;"
              variant="standard"
            />
          )}
        ></Autocomplete>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">Send From</FormLabel>
          <RadioGroup
            aria-label="send from"
            name="send-from"
            value={fromType}
            onChange={handleFromChange}
            row
          >
            <FormControlLabel
              value={FromType.ContactOwner}
              control={<Radio color="primary" />}
              label="The assigned agent"
            />
            <FormControlLabel
              value={FromType.TeamMember}
              control={<Radio color="primary" />}
              label="A team member"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {fromType === FromType.TeamMember && (
        <Grid item xs={12}>
          <TeamMemberSelect
            label="Send From"
            value={step.teamMemberId}
            onChange={handleTeamMemberChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default EmailSettings;
