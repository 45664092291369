import React, { FunctionComponent } from "react";
import { Theme, Paper } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

type LeftPaneProps = {
  width?: number;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
};
const LeftPane: FunctionComponent<LeftPaneProps> = ({
  width,
  onScroll,
  children,
}) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      leftPane: {
        [theme.breakpoints.up("md")]: {
          overflowY: "auto",
          overflowX: "hidden",
          width: width ? `${width}px` : "312px",
          flexShrink: 0,
          height: "100%",
        },
        [theme.breakpoints.down("lg")]: {
          backgroundColor: "#fff",
        },

        boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.36)",
      },
      paper: {
        [theme.breakpoints.up("sm")]: {
          minHeight: "100%",
        },
        [theme.breakpoints.down("lg")]: {
          boxShadow: "0 0 black",
        },
      },
    })
  );
  const classes = useStyles();

  return (
    <div id="left-pane" className={classes.leftPane} onScroll={onScroll}>
      <Paper square className={classes.paper} elevation={0}>
        {children}
      </Paper>
    </div>
  );
};

export default LeftPane;
