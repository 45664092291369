import React, { KeyboardEvent, useRef, useState } from "react";
import { Theme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { MagnifyIcon as SearchIcon } from "../icons";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "250px",
      width: "100%",
      position: "relative",
      boxSizing: "content-box",
      [theme.breakpoints.up(425)]: {
        maxWidth: "inherit",
        width: "250px",
      },
    },
    input: {
      height: "24px",
      width: "calc(100% - 36px)",
      padding: "0 12px 0 24px",
      borderRadius: "12px",
      border: (props) =>
        props.white ? `1px solid ${theme.palette.grey[500]}` : "none",
      overflow: "hidden",
      background: (props: any) =>
        props.white ? theme.palette.common.white : theme.palette.grey[100],
      fontFamily: theme.typography.body2.fontFamily,
      fontSize: "12px",
      color: theme.palette.grey[800],
      "&:focus": {
        outline: "none",
        boxShadow: (props) =>
          props.white ? "none" : `0 0 0 1pt ${theme.palette.grey[500]}`,
        background: theme.palette.common.white,
      },
    },
    searchIcon: {
      position: "absolute",
      left: "6px",
      top: "5px",
      height: "16px",
      width: "16px",
      color: theme.palette.grey[500],
    },
    clearIcon: {
      position: "absolute",
      left: "calc(100% - 50px)",
      top: "5px",
      height: "16px",
      width: "16px",
      color: theme.palette.grey[500],
    },
  })
);

type SearchFieldProps = {
  onChange?: CallableFunction;
  inputProps?: any;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
};

export default function SearchField({
  onChange = () => {},
  inputProps = {},
  white = false,
  className = "",
  setSearchTerm,
  ...props
}: SearchFieldProps & any) {
  const classes = useStyles({ white });
  const inputRef = useRef(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();
  const clearTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  const isQueryParamSearch = typeof setSearchTerm === "function";

  const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimer();
    if (isQueryParamSearch) {
      setSearchTerm(event.target.value);
    }
    setTimer(setTimeout(onChange, 600, event.target.value));
  };

  const onKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      clearTimer();
      onChange((event.target as any).value);
    }
  };

  const handleClear = () => {
    isQueryParamSearch && setSearchTerm("");
    onChange("");
  };

  return (
    <div className={clsx(classes.container, className)} {...props}>
      <SearchIcon className={classes.searchIcon} />
      <input
        ref={inputRef}
        type="text"
        className={classes.input}
        placeholder="Search"
        onChange={onValueChanged}
        onKeyDown={onKeyPress}
        {...inputProps}
      />
      {isQueryParamSearch && (
        <ClearIcon
          className={classes.clearIcon}
          sx={{
            cursor: "pointer",
          }}
          onClick={handleClear}
        />
      )}
    </div>
  );
}
