import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import Alert from "@mui/material/Alert";
import { useAlert } from "../../context/alert";
import ContactNameWithAvatar from "../contacts/ContactNameWithAvatar";
import { SecondaryButton, PrimaryButton } from "../buttons";
import { SEND_INVITE } from "../../api/graphql";

type UserInviteDialogProps = {
  invitee?: any;
  invitees?: any[];
  teamMemberOrContact?: "teamMember" | "contact";
  socialProofUsers?: any[];
  open: boolean;
  onClose: Function;
};

export default function UserInviteDialog({
  invitee,
  invitees,
  teamMemberOrContact = "teamMember",
  socialProofUsers,
  open,
  onClose,
}: UserInviteDialogProps) {
  const apollo = useApolloClient();
  const [atUserLimit, setAtUserLimit] = useState(false);
  const [error, setError] = useState<string>();
  const { showSuccess, showError } = useAlert();

  const [sendInvite] = useMutation(SEND_INVITE, {
    onCompleted: handleSent,
    onError: handleSendError,
  });

  useEffect(() => {
    if (!open || teamMemberOrContact === "contact") {
      return;
    }
    apollo
      .query({
        query: gql`
          query {
            me {
              account {
                atUserLimit
              }
            }
          }
        `,
      })
      .then((response) => {
        setAtUserLimit(response.data.me.account.atUserLimit);
      })
      .catch((error) => {
        // Do nothing
      });
  }, [open, teamMemberOrContact, apollo]);

  function handleClose() {
    setError(undefined);
    onClose();
  }

  function handleSendInvite() {
    if (invitee) {
      const input =
        teamMemberOrContact === "teamMember"
          ? {
              teamMemberId: invitee.id,
              contactId: null,
            }
          : {
              teamMemberId: null,
              contactId: invitee.id,
            };
      sendInvite({ variables: { input } })
        .then(() => {
          showSuccess("Invite sent");
        })
        .catch(() => {
          showError("There was an error while sending invite");
        });
    } else if (invitees) {
      Promise.all(
        invitees.map((contact: any) => {
          const input = {
            contactId: contact.id,
          };
          return sendInvite({ variables: { input } });
        })
      )
        .then(() => {
          showSuccess("Invites sent to clients");
        })
        .catch(() => {
          showError("There was an error while sending invites");
        });
    }
  }

  function handleSent() {
    handleClose();
  }

  function handleSendError(err: ApolloError) {
    if (err.graphQLErrors.length) {
      setError(err.graphQLErrors[0].message);
    } else {
      setError("An error occurred when sending the invite");
    }
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        {!invitees ? (
          <>
            <DialogTitle>Invite {invitee?.name}</DialogTitle>
            <DialogContent>
              {error && <Alert severity="error">{error}</Alert>}
              <DialogContentText>
                <Typography gutterBottom variant="body1">
                  We'll send instructions to {invitee?.firstName}'s email (
                  <strong>{invitee?.email}</strong>) so that they can log into
                  Shaker and collaborate with you.
                </Typography>
                {atUserLimit && (
                  <Alert severity="info">
                    <strong>You've reached your user limit.</strong>&nbsp;We'll
                    add a user license to your account when this team member
                    accepts thier invite.
                  </Alert>
                )}
              </DialogContentText>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogContent>
              <Typography variant="h5">
                <strong>Client Portal Invites</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                We'll send the following contacts an invite so that they can log
                into the Shaker client portal and collaborate with you
              </Typography>
              <List>
                {invitees.map((invitee: any) => (
                  <ListItem key={invitee.id}>
                    <ContactNameWithAvatar
                      name={invitee.name}
                      avatarUrls={[invitee.avatarUrl]}
                    />
                  </ListItem>
                ))}
              </List>
              {socialProofUsers && socialProofUsers.length > 0 && (
                <>
                  <Divider />
                  <br />
                  <Typography variant="h5">
                    <strong>Already Invited</strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    These users have already been invited or are active in the
                    client portal:
                  </Typography>
                  <List>
                    {socialProofUsers.map((user: any) => (
                      <ListItem key={user.id}>
                        <ContactNameWithAvatar
                          name={user.name}
                          avatarUrls={[user.avatarUrl]}
                        />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </DialogContent>
          </>
        )}
        <DialogActions>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <PrimaryButton onClick={handleSendInvite}>
            {invitees && invitees.length > 1 ? "Send Invites" : "Send Invite"}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
