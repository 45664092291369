import React from "react";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TeamLayout from "./TeamLayout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    splitLayout: {
      flexGrow: 1,
      overflowX: "hidden",
      display: "flex",
      flexDirection: "row",
      marginBottom: "0",
      [theme.breakpoints.up("md")]: {
        overflowY: "hidden",
      },
      [theme.breakpoints.down("md")]: {
        overflowY: "scroll",
        flexDirection: "column",
      },
    },
  })
);

export default function SplitLayout({
  parentLayout: ParentLayout = TeamLayout,
  children,
  loading = false,
}: {
  parentLayout?: any;
  children?: React.ReactNode;
  loading?: boolean;
}) {
  const classes = useStyles();

  return (
    <ParentLayout loading={loading} withoutPadding={true}>
      <Box className={classes.splitLayout}>{!loading && children}</Box>
    </ParentLayout>
  );
}
