import React from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";

import { RowDataTypes } from "../../models";

type ReportTableTypes = {
  tableHeaders: string[];
  tableData: any[];
  report: any;
  stats?: { months: any[] };
  showAverages?: boolean;
  cellKey?: string;
  label?: string;
};

export const reportTableCheck = (rowData: RowDataTypes) => {
  let options: { [key: string]: string } = {
    DealTypeAggregate: rowData.dealType?.name,
    TeamMemberReportAggregate: rowData.teamMember?.name,
    DealSourceAggregate: rowData.source,
    DealStateAggregate: rowData.dealState,
  };
  return options[rowData.__typename];
};

const ReportTable = ({
  tableHeaders,
  tableData,
  report,
  stats,
  showAverages = false,
  cellKey = "total",
  label,
}: ReportTableTypes) => {
  const reportTableCheck = (rowData: {
    source: string;
    dealType: { name: string };
    teamMember: { name: string };
    dealState: string;
    __typename: string;
  }) => {
    const options: { [key: string]: string } = {
      DealTypeAggregate: rowData.dealType?.name,
      TeamMemberReportAggregate: rowData.teamMember?.name,
      DealSourceAggregate: rowData.source,
      DealStateAggregate: rowData.dealState,
    };
    return options[rowData.__typename];
  };

  const cellValue = (agg: any, key: string = "total") => {
    return report.formatter(report.getAggBundle(agg)[key]);
  };

  return (
    <Grid item xs={12}>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead sx={{ background: "#351665", color: "#fff" }}>
            <TableRow>
              <TableCell sx={{ borderColor: "#1A0B31" }}></TableCell>
              {tableHeaders.map((month) => (
                <TableCell
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                    borderColor: "#1A0B31",
                  }}
                  align="right"
                  key={month}
                >
                  {month}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  color: "#fff",
                  whiteSpace: "nowrap",
                  background: "#0b8d9b",
                  fontWeight: "bold",
                  borderBottom: "none",
                }}
                align="right"
              >
                Totals
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((rowData: any, index: number) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    color: "#fff",
                    whiteSpace: "nowrap",
                    background: "#351665",
                    fontWeight: "bold",
                    borderColor: "#1A0B31",
                  }}
                  align="right"
                >
                  {label ?? reportTableCheck(rowData)}
                </TableCell>

                {rowData.years[0].months.map((monthAgg: any, index: number) => {
                  return (
                    //data in middle part of table
                    <TableCell key={index} align="right">
                      {cellValue(monthAgg, cellKey)}
                    </TableCell>
                  );
                })}

                <TableCell
                  //right column totals
                  sx={{
                    whiteSpace: "nowrap",
                    background: "#cee7ec",
                    borderLeft: "2px solid rgba(224, 224, 224, 1)",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    borderBottom: "none",
                  }}
                  align="right"
                >
                  {cellValue(rowData.years[0], cellKey)}
                </TableCell>
              </TableRow>
            ))}

            {stats && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "#fff",
                    whiteSpace: "nowrap",
                    background: "#0b8d9b",
                    fontWeight: "bold",
                  }}
                  align="right"
                >
                  Total
                </TableCell>
                {stats.months.map((monthAgg: any) => (
                  <TableCell
                    //bottom row month totals
                    sx={{
                      whiteSpace: "nowrap",
                      borderTop: "2px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {cellValue(monthAgg, cellKey)}
                  </TableCell>
                ))}
                <TableCell
                  // bottom row total
                  sx={{
                    whiteSpace: "nowrap",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    background: "#cee7ec",
                  }}
                  align="right"
                >
                  {cellValue(stats, cellKey)}
                </TableCell>
              </TableRow>
            )}
            {stats && showAverages && (
              <TableRow>
                <TableCell
                  sx={{
                    color: "#fff",
                    whiteSpace: "nowrap",
                    background: "#0b8d9b",
                    fontWeight: "bold",
                  }}
                  align="right"
                >
                  Average
                </TableCell>
                {stats.months.map((monthAgg: any) => (
                  <TableCell
                    //bottom row month totals
                    sx={{
                      whiteSpace: "nowrap",
                      borderTop: "2px solid rgba(224, 224, 224, 1)",
                      background: "#cee7ec",
                    }}
                    align="right"
                  >
                    {cellValue(monthAgg, "average")}
                  </TableCell>
                ))}
                <TableCell
                  // bottom row total
                  sx={{
                    whiteSpace: "nowrap",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    background: "#cee7ec",
                  }}
                  align="right"
                ></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default ReportTable;
