import React, { useContext, useState, useRef, forwardRef } from "react";
import { Box, Typography } from "@mui/material";
import { OutlineButton, PrimaryButton } from "../../../../components/buttons";
import TimelineList from "../../../../components/deals/TimelineList";
import { dealViewStore } from "../context";
import TimelineSettings from "../../../../components/TimelineSettings";
import { SettingsIcon } from "../../../../icons";
import DetailCard from "../../../../components/DetailCard";
import { useUser } from "../../../../context/user";

const Timeline = () => {
  const {
    state: { deal },
  } = useContext(dealViewStore);

  const { hasPermission } = useUser();

  const canEditTimeline = hasPermission("settings", "limited");

  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const todayRef = useRef<HTMLDivElement | null>(null);
  const TimelineListRef = forwardRef((props, ref) => {
    return <TimelineList dealId={deal.id} todayRef={ref} />;
  });

  if (!deal) {
    return <></>;
  } else {
    return (
      <>
        <DetailCard
          title={<Typography variant="h5">Timeline</Typography>}
          action={
            <>
              {!settingsOpen && (
                <PrimaryButton
                  onClick={() =>
                    todayRef?.current?.scrollIntoView({ behavior: "smooth" })
                  }
                  style={{ marginRight: "5px" }}
                >
                  Go to Today
                </PrimaryButton>
              )}
              {canEditTimeline && (
                <OutlineButton
                  onClick={() =>
                    setSettingsOpen((settingsOpen) => !settingsOpen)
                  }
                >
                  {!settingsOpen ? (
                    <>
                      <SettingsIcon style={{ marginRight: "3px" }} /> Configure
                    </>
                  ) : (
                    <>View Timeline</>
                  )}
                </OutlineButton>
              )}
            </>
          }
        >
          {settingsOpen && (
            <Box
              style={{
                padding: 21,
                margin: "0 auto 21px",
                width: "100%",
                maxWidth: "1200px",
              }}
            >
              <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
                <span style={{ fontWeight: 600 }}>Note:</span> these settings
                will only affect the timeline for this deal.
              </Typography>
              <TimelineSettings deal={deal} />
            </Box>
          )}
          {!settingsOpen && <TimelineListRef ref={todayRef} />}
        </DetailCard>
      </>
    );
  }
};

export default Timeline;
