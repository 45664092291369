import { gql } from "@apollo/client";
import { FRAGMENT_STAGE_FIELDS } from "../api/graphql";
import { FRAGEMNT_STAGE_SENDABLE_FIELDS } from "./stageEmails";
import { DealType, Role, Stage, Integration } from "../models";
import { APP_DATA } from "../reducers/appDataState";
import { DEAL_TYPES } from "../reducers/dealTypes";
import { ROLES } from "../reducers/roles";
import { STAGES } from "../reducers/stages";
import { CONTACT_TAGS } from "../reducers/contactTags";
import { VENDOR_TAGS } from "../reducers/vendorTags";
import { ACCOUNT } from "../reducers/account";
import { FEATURES } from "../reducers/features";
import { AVAILABLE_INTEGRATIONS } from "../reducers/availableIntegrations";
import { ApiClient } from "../context/api";
import { MLSES } from "../reducers/mlses";

export const GET_APP_DATA = gql`
  query GET_APP_DATA {
    me {
      hasEmailConnectivityError
      account {
        id
        stripeCheckoutUrl
        needsBillingInfo
        name
        trialEndDate
        trialEnded
        billingEnabled
        isCanceled
        cancelAtDate
        features {
          name
          enabled
        }
        availableIntegrations {
          id
          added
        }
      }
      availableIntegrations {
        id
        added
      }
    }
    dealTypes {
      id
      name
      clientNoun
      dealOwnerRoleId
      dealOwnerRole {
        id
        name
      }
      stages {
        id
        name
        dealType {
          id
          name
          clientNoun
          dealOwnerRole {
            id
          }
        }
        sort
        dealState
        emails {
          id
          emailTemplateId
          sort
          ...stageSendableFields
        }
        sms {
          id
          smsTemplateId
          sort
          ...stageSendableFields
        }
        tasks {
          id
          name
          toDealContacts
          toRoleId
          showInClientPortal
          hasDueDate
          dueDateDays
          dueDatePreposition
          dueDateTargetColumnName
          dueDateTargetCustomFieldId
          sort
        }
        fields {
          ...StageFields
        }
      }
    }
    getRoles {
      id
      name
      isBuyersAgent
      isListingAgent
      defaultTeamMemberId
      dealTypes {
        id
      }
    }
    contactTags: tags(type: "contact") {
      id
      name
    }
    vendorTags: tags(type: "vendor") {
      id
      name
    }
    getMlses {
      name
      mlsKey
      connected
      supported
    }
  }
  ${FRAGMENT_STAGE_FIELDS}
  ${FRAGEMNT_STAGE_SENDABLE_FIELDS}
`;

export const fetchAppData =
  (currentUser: any, apolloClient: ApiClient) => async (dispatch: any) => {
    if (currentUser) {
      return apolloClient
        .query({ query: GET_APP_DATA })
        .then((response) => {
          const roles: Role[] = response.data.getRoles;
          const dealTypes: DealType[] = response.data.dealTypes;
          const stages = dealTypes.reduce((stages, dealType) => {
            return [...stages, ...dealType.stages];
          }, [] as Stage[]);
          const contactTags = response.data.contactTags;
          const vendorTags = response.data.vendorTags;
          const mlses = response.data.getMlses ?? [];
          const account = response.data.me?.account || {};
          const features = response.data.me?.account?.features || [];

          // We'll merge the available integrations from the account and the user
          const availableIntegrations: Integration[] =
            response.data.me?.account?.availableIntegrations || [];
          const userIntegrations: Integration[] =
            response.data.me?.availableIntegrations || [];
          for (const integration of userIntegrations) {
            const index = availableIntegrations.findIndex(
              (i) => i.id === integration.id
            );
            if (index === -1) {
              availableIntegrations.push(integration);
            } else {
              if (integration.added) {
                availableIntegrations[index] = integration;
              }
            }
          }

          dispatch({ type: ROLES.SET, roles });
          dispatch({ type: STAGES.SET, stages });
          dispatch({ type: DEAL_TYPES.SET, dealTypes });
          dispatch({ type: CONTACT_TAGS.SET, contactTags });
          dispatch({ type: VENDOR_TAGS.SET, vendorTags });
          dispatch({ type: FEATURES.SET, features });
          dispatch({ type: MLSES.SET, mlses });
          dispatch({ type: ACCOUNT.SET, account });
          dispatch({ type: AVAILABLE_INTEGRATIONS.SET, availableIntegrations });
          dispatch({ type: APP_DATA.LOADED });
        })
        .catch(() => {});
    } else {
      dispatch({ type: ROLES.SET, roles: [] });
      dispatch({ type: STAGES.SET, stages: [] });
      dispatch({ type: DEAL_TYPES.SET, dealTypes: [] });
      dispatch({ type: CONTACT_TAGS.SET, contactTags: [] });
      dispatch({ type: VENDOR_TAGS.SET, vendorTags: [] });
      dispatch({ type: FEATURES.SET, features: [] });
      dispatch({ type: MLSES.SET, mlses: [] });
      dispatch({ type: ACCOUNT.SET, account: {} });
      dispatch({ type: AVAILABLE_INTEGRATIONS.SET, availableIntegrations: [] });
      dispatch({ type: APP_DATA.NOT_LOADED });
    }
  };
