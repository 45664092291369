import React, {
  Fragment,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import SplitLayout from "../../layouts/SplitLayout";
import LeftPane from "../../components/LeftPane";
import SettingsSubNav from "../../components/SettingsSubNav";
import SettingsSelect from "../../components/SettingsSelectMobile";
import DetailPane from "../../components/DetailPane";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Switch,
  TextField,
  Paper,
  Box,
  Link,
  Tooltip,
  IconProps,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddStepButton from "../../components/workflows/AddStepButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DelaySettings from "../../components/workflows/DelaySettings";
import EmailSettings from "../../components/workflows/EmailSettings";
import TaskSettings from "../../components/workflows/TaskSettings";
import { ArrowRightAlt, PersonAdd, PhoneAndroid } from "@mui/icons-material";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  FRAGMENT_WORKFLOW_FIELDS,
  FRAGMENT_WORKFLOW_STEP_FIELDS,
} from "../../api/graphql";
import { EmailIcon, TaskIcon, TimerIcon } from "../../icons";
import Autocomplete from "@mui/material/Autocomplete";
import { Tag } from "../../models";
import { TrashIcon as DeleteIcon } from "../../icons";
import { PencilIcon } from "../../icons";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { useAlert } from "../../context/alert";
import SmsSettings from "../../components/workflows/SmsSettings";
import { useUser } from "../../context/user";

const useStyles = makeStyles(() => ({
  iconWrapper: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "10px",
    color: "#fff",
    marginRight: "21px",
    "&.delay": {
      background: "#31ae6f",
    },
    "&.email": {
      background: "#3590f3",
    },
    "&.task": {
      background: "#d46f46",
    },
  },
  stepActions: {},
  step: {
    "& $stepActions": {
      display: "none",
    },
    "&:hover": {
      "& $stepActions": {
        display: "initial",
      },
    },
  },
}));

enum DisableOptions {
  CancelRemainingSteps = "cancelRemainingSteps",
  CompleteRemainingSteps = "completeRemainingSteps",
}

type WorkflowEditProps = { tags: Tag[] };
const WorkflowEditPage: FunctionComponent<WorkflowEditProps> = ({
  tags,
}): JSX.Element => {
  const { hasPermission } = useUser();
  const classes = useStyles();
  const navigate = useNavigate();
  const { showError, showSuccess } = useAlert();
  const { id } = useParams<{ id: string }>();
  const [createWorkflow] = useMutation(gql`
    mutation CreateWorkflow($input: CreateWorkflowInput!) {
      createWorkflow(input: $input) {
        ...WorkflowFields
      }
    }
    ${FRAGMENT_WORKFLOW_FIELDS}
  `);
  const [updateWorkflow] = useMutation(gql`
    mutation UpdateWorkflow($input: UpdateWorkflowInput!) {
      updateWorkflow(input: $input) {
        ...WorkflowFields
      }
    }
    ${FRAGMENT_WORKFLOW_FIELDS}
  `);

  const [getWorkflow, { data }] = useLazyQuery(
    gql`
      query GetWorkflow($id: ID!) {
        getWorkflow(id: $id) {
          ...WorkflowFields
        }
      }
      ${FRAGMENT_WORKFLOW_FIELDS}
    `,
    { variables: { id } }
  );

  const [createWorkflowStep] = useMutation(gql`
    mutation CreateWorkflowStep($input: CreateWorkflowStepInput!) {
      createWorkflowStep(input: $input) {
        ...WorkflowStepFields
      }
    }
    ${FRAGMENT_WORKFLOW_STEP_FIELDS}
  `);

  const [updateWorkflowStep] = useMutation(gql`
    mutation UpdateWorkflowStep($input: UpdateWorkflowStepInput!) {
      updateWorkflowStep(input: $input) {
        ...WorkflowStepFields
      }
    }
    ${FRAGMENT_WORKFLOW_STEP_FIELDS}
  `);

  const [deleteWorkflowStep] = useMutation(gql`
    mutation DeleteWorkflowStep($id: ID!) {
      deleteWorkflowStep(id: $id)
    }
  `);

  const [enrollTagInWorkflow] = useMutation(gql`
    mutation EnrollTagInWorkflow($input: EnrollTagInWorkflowInput!) {
      enrollTagInWorkflow(input: $input)
    }
  `);

  const [removeTagInWorkflow] = useMutation(gql`
    mutation EnrollTagInWorkflow($input: RemoveTagInWorkflowInput!) {
      removeTagInWorkflow(input: $input)
    }
  `);

  const [disableWorkflow] = useMutation(gql`
    mutation DisableWorkflow($input: DisableWorkflowInput) {
      disableWorkflow(input: $input)
    }
  `);

  const [enableWorkflow] = useMutation(gql`
    mutation EnableWorkflow($id: ID!) {
      enableWorkflow(id: $id)
    }
  `);

  const [workflow, setWorkflow] = useState<any | null>(null);
  const [workflowTags, setWorkflowTags] = useState<Array<Tag>>([]);
  const [selectedAddStepButtonIndex, setSelectedAddStepButtonIndex] = useState<
    number | null
  >(null);

  const [selectedStep, setSelectedStep] = useState<any | null>(null);
  const [showEnrollDialog, setShowEnrollDialog] = useState<boolean>(false);
  const [showDisableDialog, setShowDisableDialog] = useState<boolean>(false);
  const [disabledOption, setDisabledOption] = useState<DisableOptions>(
    DisableOptions.CompleteRemainingSteps
  );

  const canEditWorkflows = () => hasPermission("settings", "limited");

  const handleAddStep = (e: any, type: string, index: number) => {
    const step: any = {
      workflowId: workflow.id,
      type,
      sort: index,
    };
    if (type === "delay") {
      step.delayInterval = 1;
      step.delayFrequency = "weekly";
      step.delayStartType = "lastActionDate";
      step.delayPreposition = "after";
      step.delayStartAtTime = "09:00";
    } else if (type === "email") {
      step.emailTemplateId = "";
    } else if (type === "task") {
      step.taskName = "";
    }
    setSelectedStep(step);
  };

  const handleDialogClose = () => {
    setSelectedStep(null);
  };

  const handleDialogSave = () => {
    if (!selectedStep) {
      return;
    }

    let input = Object.assign({}, selectedStep);
    if (input.type === "delay") {
      input.delayInterval = parseInt(input.delayInterval, 10);
      if (isNaN(input.delayInterval)) {
        input.delayInterval = 0;
      }
    }

    let promise: Promise<any>;
    if (!input.id) {
      promise = createWorkflowStep({ variables: { input } });
      promise = promise.then((res) => res.data.createWorkflowStep);
    } else {
      // Cleanup fields not allowed during update.
      delete input.workflowId;
      delete input.type;
      delete input.sort;
      delete input.description;
      delete input.teamMember;
      promise = updateWorkflowStep({ variables: { input } });
      promise = promise.then((res) => res.data.updateWorkflowStep);
    }
    promise.catch((err) => {
      console.error(err);
    });

    promise.then((step: any) => {
      setSelectedStep(null);
      setSelectedAddStepButtonIndex(null);
      // Place the step at the proper index.
      let steps = [...workflow.steps];
      const index = steps.findIndex((s: any) => s.id === step.id);
      if (index !== -1) {
        steps[index] = step;
      } else {
        steps.splice(step.sort, 0, step);
      }
      // Update sort
      steps = steps.map((step: any, index: number) => {
        step.sort = index;
        return step;
      });
      setWorkflow({ ...workflow, steps });
    });
  };

  const handleToggle = (index: number) => {
    setSelectedAddStepButtonIndex(
      index === selectedAddStepButtonIndex ? null : index
    );
  };

  const handleEdit = (index: number) => {
    setSelectedStep(workflow.steps[index]);
  };

  const handleDelete = (index: number) => {
    const step = workflow.steps[index];
    if (!step) {
      return;
    }
    if (!window.confirm("Are you sure you want to delete this step?")) {
      return;
    }
    deleteWorkflowStep({ variables: { id: step.id } }).then(() => {
      const steps = workflow.steps
        .filter((value: any, i: number) => i !== index)
        .map((step: any, index: number) => {
          step.sort = index;
          return step;
        });
      setWorkflow(Object.assign({}, workflow, { steps }));
    });
  };

  const handleSave = (): Promise<any> => {
    const mutationOpts: any = {
      variables: {
        input: {
          name: workflow.name,
        },
      },
    };

    let promise: Promise<any>;
    if (!workflow.id) {
      promise = createWorkflow(mutationOpts);
      promise = promise.then((res) => res.data.createWorkflow);
    } else {
      mutationOpts.variables.input.id = workflow.id;
      promise = updateWorkflow(mutationOpts);
      promise = promise.then((res) => res.data.updateWorkflow);
    }
    promise.then((workflow: any) => {
      setWorkflow(workflow);
      if (id === "new") {
        navigate(`/settings/workflows/${workflow.id}`, { replace: true });
      }
      return workflow;
    });
    promise.catch((err) => {});
    return promise;
  };

  const handleKeyDownOnName = (e: KeyboardEvent<any>) => {
    if (e.key === "Enter" && !workflow.id) {
      handleSave();
    }
  };

  const handleShowEnrollDialog = () => {
    setShowEnrollDialog(true);
  };

  const handleCancelEnrollDialog = () => {
    setShowEnrollDialog(false);
  };

  const handleTagChange = (e: any, tags: Array<Tag> | null) => {
    if (tags === null) {
      setWorkflowTags([]);
    } else {
      setWorkflowTags(tags);
    }
  };

  const handleEnroll = () => {
    const old: Array<string> = workflow.enrolledTags.map(
      (t: { id: string }) => t.id
    );
    const new_: Array<string | undefined> = workflowTags
      .filter((t) => t.id)
      .map((t) => t.id);

    const toAdd = new_.filter((id) => !old.includes(id as string));
    const toRemove = old.filter((id) => !new_.includes(id));

    let promises: Array<Promise<any>> = [];

    // Perform enrollments
    promises.push(
      ...toAdd.map((tagId) => {
        return enrollTagInWorkflow({
          variables: {
            input: {
              workflowId: workflow.id,
              tagId,
            },
          },
        });
      })
    );

    // Remove tags
    promises.push(
      ...toRemove.map((tagId) => {
        return removeTagInWorkflow({
          variables: {
            input: {
              workflowId: workflow.id,
              tagId,
            },
          },
        });
      })
    );

    Promise.all(promises)
      .then(() => {
        setShowEnrollDialog(false);
        setWorkflow({ ...workflow, enrolledTags: workflowTags });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getDialogTitle = () => {
    const prefix = selectedStep?.id ? "Edit" : "New";
    switch (selectedStep?.type) {
      case "delay":
        return `${prefix} Delay`;
      case "task":
        return `${prefix} Assigned Task`;
      case "email":
        return `${prefix} Triggered Email`;
      case "sms":
        return `${prefix} Triggered Text Message`;
      default:
        return "";
    }
  };

  const getDialogSubtext = () => {
    switch (selectedStep?.type) {
      case "delay":
        return "Tasks and emails will be delayed until the specified timeframe";
      case "task":
        return "This task will be assigned to the selected team member";
      case "email":
        return "This email will be automatically sent from the selected team member";
      default:
        return "";
    }
  };

  const StepIcon = ({ type }: { type: string }): JSX.Element | null => {
    let background = "#0B8D9B";
    let icon: (props: IconProps) => JSX.Element;
    icon = TimerIcon;
    switch (type) {
      case "email":
        background = "#FF576D";
        icon = EmailIcon;
        break;
      case "task":
        background = "#351665";
        icon = TaskIcon;
        break;
      case "sms":
        background = "#5759FF";
        break;
    }
    const style = {
      background,
      color: "#fff",
      padding: "4px 4.5px",
      borderRadius: "50%",
      height: "22px",
      width: "22px",
      marginRight: "14px",
    };

    if (type === "sms") {
      return <PhoneAndroid style={style} />;
    } else if (icon) {
      return icon({ style });
    }
    return null;
  };

  const handleEnabledSwitch = () => {
    if (!workflow.enabled) {
      // enable
      enableWorkflow({
        variables: {
          id: workflow.id,
        },
      })
        .then(() => {
          setWorkflow({ ...workflow, enabled: true });
          showSuccess("This workflow is now running");
        })
        .catch(() => {
          showError("An error occurred when enabling this workflow");
        });
    } else {
      // We're disabling the workflow.
      if (workflow.activeEnrolleeCount === 0) {
        disableWorkflow({ variables: { input: { id: workflow.id } } })
          .then(() => {
            showSuccess("This workflow has been disabled");
            setWorkflow({ ...workflow, enabled: false });
          })
          .catch((e) => {
            showError("An error occurred when disabling the workflow");
          });
      } else {
        setShowDisableDialog(true);
      }
    }
  };

  const handleDisableWorkflow = () => {
    disableWorkflow({
      variables: {
        input: {
          id: workflow.id,
          cancelActiveContacts:
            disabledOption === DisableOptions.CancelRemainingSteps,
        },
      },
    })
      .then(() => {
        setShowDisableDialog(false);
        showSuccess("This workflow has been disabled");
        setWorkflow({ ...workflow, enabled: false });
      })
      .catch((e) => {
        showError("An error occurred when disabling the workflow");
      });
  };

  const taskDueDateDescription = (step: {
    taskHasDueDate: boolean;
    taskDueDateDays: number;
  }): string => {
    if (!step.taskHasDueDate) {
      return "";
    }
    if (step.taskDueDateDays === 0) {
      return "Due the day the task is assigned";
    } else if (step.taskDueDateDays === 1) {
      return "Due the next day";
    } else {
      if (step.taskDueDateDays % 7 === 0) {
        const weeks = step.taskDueDateDays / 7;
        return `Due in ${weeks} week${weeks > 1 ? "s" : ""}`;
      } else {
        return `Due in ${step.taskDueDateDays} days`;
      }
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setWorkflow(data.getWorkflow);
  }, [data]);

  useEffect(() => {
    if (workflow && workflow.enrolledTags && tags) {
      const workflowTags = tags.filter((tag) =>
        workflow?.enrolledTags.find((et: Tag) => et.id === tag.id)
      );
      setWorkflowTags(workflowTags);
    }
  }, [workflow, tags]);

  useEffect(() => {
    if (id === "new") {
      setWorkflow({
        name: "",
        steps: [],
      });
    } else {
      getWorkflow();
    }
  }, [id, getWorkflow]);

  if (!workflow) {
    return <></>;
  }
  return (
    <SplitLayout>
      <LeftPane width={224}>
        <SettingsSubNav />
        <SettingsSelect label="" defaultValue="/settings/workflows" />
      </LeftPane>
      <DetailPane>
        <PageTitle
          title={"Workflows"}
          backUrl="/settings/workflows"
          actions={
            <>
              {workflow.id && (
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      onClick={handleShowEnrollDialog}
                      variant="contained"
                      sx={{
                        background: "#fff",
                        color: "#6b7280",
                        "&:hover": {
                          background: "#fff",
                        },
                      }}
                    >
                      <PersonAdd /> Enroll
                    </Button>
                  </Grid>
                  <Grid item>
                    Workflow is{" "}
                    <Switch
                      color="primary"
                      checked={workflow.enabled}
                      onChange={handleEnabledSwitch}
                      disabled={!canEditWorkflows()}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          }
        />
        <Card>
          <CardContent>
            <TextField
              autoFocus={!workflow.id}
              disabled={!canEditWorkflows()}
              fullWidth
              placeholder="Name your workflow"
              variant="standard"
              value={workflow.name}
              onBlur={handleSave}
              onKeyDown={handleKeyDownOnName}
              onChange={(e) =>
                setWorkflow({ ...workflow, name: e.target.value })
              }
            />
          </CardContent>
        </Card>
        <AddStepButton
          onToggle={() => handleToggle(0)}
          open={selectedAddStepButtonIndex === 0}
          onClick={(e, type) => handleAddStep(e, type, 0)}
          end={!workflow.steps || workflow.steps?.length === 0}
          enabled={canEditWorkflows()}
        />
        {workflow?.steps?.map((step: any, index: number) => (
          <Fragment key={index}>
            <Paper className={classes.step}>
              <Box
                p={2}
                style={{
                  minHeight: "76px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <StepIcon type={step.type} />
                    <span
                      style={{
                        fontFamily: [
                          "AvenirNext-Medium",
                          "Avenir",
                          "sans-serif",
                        ].join(","),
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {step.description.verb}&nbsp;
                      {step.type === "delay" && (
                        <>{step.description.preposition}&nbsp;</>
                      )}
                      {step.description.nounLink && (
                        <Link>{step.description.noun}</Link>
                      )}
                      {!step.description.nounLink && step.description.noun}
                      {step.type !== "delay" && (
                        <>
                          <ArrowRightAlt
                            style={{ marginLeft: "11px", marginRight: "11px" }}
                          />
                          {step.description.preposition}&nbsp;
                          <Link>
                            {step.teamMember
                              ? step.teamMember.name
                              : "Assigned Agent"}
                          </Link>
                        </>
                      )}
                      {step.type === "task" && step.taskHasDueDate && (
                        <>
                          <ArrowRightAlt
                            style={{ marginLeft: "11px", marginRight: "11px" }}
                          />
                          {taskDueDateDescription(step)}
                        </>
                      )}
                    </span>
                  </div>

                  <div className={classes.stepActions}>
                    {canEditWorkflows() && (
                      <>
                        <Tooltip title="Edit" aria-label="edit">
                          <IconButton
                            onClick={(e) => handleEdit(index)}
                            size="large"
                          >
                            <PencilIcon size="20px" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" aria-label="delete">
                          <IconButton
                            onClick={(e) => handleDelete(index)}
                            size="large"
                          >
                            <DeleteIcon size="20px" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </div>
              </Box>
            </Paper>
            <AddStepButton
              onToggle={() => handleToggle(index + 1)}
              open={selectedAddStepButtonIndex === index + 1}
              onClick={(e, type) => handleAddStep(e, type, index + 1)}
              end={workflow?.steps.length === index + 1}
              enabled={canEditWorkflows()}
            />
          </Fragment>
        ))}
        <Dialog open={Boolean(selectedStep)} maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title">{getDialogTitle()}</DialogTitle>
          <DialogContent>
            <DialogContentText>{getDialogSubtext()}</DialogContentText>
            {selectedStep?.type === "delay" && (
              <DelaySettings step={selectedStep} setStep={setSelectedStep} />
            )}
            {selectedStep?.type === "email" && (
              <EmailSettings step={selectedStep} setStep={setSelectedStep} />
            )}
            {selectedStep?.type === "sms" && (
              <SmsSettings step={selectedStep} setStep={setSelectedStep} />
            )}
            {selectedStep?.type === "task" && (
              <TaskSettings step={selectedStep} setStep={setSelectedStep} />
            )}
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={handleDialogClose} color="primary">
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={handleDialogSave} color="primary">
              Save
            </PrimaryButton>
          </DialogActions>
        </Dialog>

        <Dialog open={showEnrollDialog} maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title">Enroll Contacts</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Autocomplete
                  onChange={handleTagChange}
                  multiple
                  options={tags}
                  value={workflowTags}
                  getOptionLabel={(tag) => tag.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose tags to enroll"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelEnrollDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEnroll} color="primary" variant="contained">
              Enroll
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={showDisableDialog} maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title">
            Are you sure you want to turn off this workflow?
          </DialogTitle>
          <DialogContent>
            <FormControl>
              <FormLabel>
                There are {workflow.activeEnrolleeCount} currently active in
                this workflow.
              </FormLabel>
              <RadioGroup aria-label="Disable Options" name="disable-options">
                <FormControlLabel
                  checked={
                    disabledOption === DisableOptions.CompleteRemainingSteps
                  }
                  onChange={(e, v) =>
                    setDisabledOption(DisableOptions.CompleteRemainingSteps)
                  }
                  control={<Radio />}
                  label="Let them continue, but prevent new contacts from entering the workflow"
                />
                <FormControlLabel
                  checked={
                    disabledOption === DisableOptions.CancelRemainingSteps
                  }
                  onChange={(e, v) =>
                    setDisabledOption(DisableOptions.CancelRemainingSteps)
                  }
                  control={<Radio />}
                  label="Cancel them from the workflow"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <SecondaryButton
              onClick={() => setShowDisableDialog(false)}
              color="primary"
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={handleDisableWorkflow} color="primary">
              Turn Off Workflow
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      </DetailPane>
    </SplitLayout>
  );
};

const mapStateToProps = ({ contactTags: tags }: { contactTags: Tag[] }) => ({
  tags,
});
export default connect(mapStateToProps)(WorkflowEditPage);
