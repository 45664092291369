import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableContainer,
  Paper,
  Box,
  Typography,
  Theme,
  Chip,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  ToggleButton,
  ToggleButtonGroup,
} from "../../components/ToggleButtons";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SettingsSubNav from "../../components/SettingsSubNav";
import SettingsSelect from "../../components/SettingsSelectMobile";
import SplitLayout from "../../layouts/SplitLayout";
import LeftPane from "../../components/LeftPane";
import DetailPane from "../../components/DetailPane";
import TemplateDialog from "../../components/messageTemplates/TemplateDialog";
import SmsTemplateDialog from "../../components/messageTemplates/SmsTemplateDialog";
import TemplateIframe from "../../components/messageTemplates/TemplateIframe";
import { PrimaryButton } from "../../components/buttons";
import { Add as AddIcon } from "@mui/icons-material";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { DELETE_EMAIL_TEMPLATE, DELETE_SMS_TEMPLATE } from "../../api/graphql";
import { useAlert } from "../../context/alert";
import PageTitle from "../../components/PageTitle";
import { TrashIcon, PencilIcon } from "../../icons";
import { connect } from "react-redux";
import { Feature } from "../../reducers/features";
import { useUser } from "../../context/user";
import SupportLink from "../../components/SupportLink";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageLayout: {
      flexGrow: 1,
      overflowX: "hidden",
      display: "flex",
      flexDirection: "row",
      marginBottom: "0",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
    },
    textAlignRight: {
      textAlign: "right",
    },
    templateRow: {
      cursor: "pointer",
    },
    templateName: {
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "14px",
      fontWeight: 500,
    },
  })
);

function EmailTemplateList({
  features,
}: {
  [key: string]: Feature;
}): JSX.Element {
  const classes = useStyles();
  const { showSuccess, showError } = useAlert();
  const { hasPermission } = useUser();

  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [templateToEdit, setTemplateToEdit] = useState<any>(null);

  const canEditTemplates = () => hasPermission("settings", "limited");
  const { data, refetch } = useQuery(gql`
    query GetEmailTemplatesAndMergeFields {
      getEmailTemplates {
        id
        name
        subject
        body {
          html
          plaintext
          delta
        }
        attachments {
          id
          fileName: filename
        }
      }
      getEmailTemplateMergeFields {
        id
        name
        placeholder
      }
    }
  `);

  const [getSmsTemplates, { data: smsData }] = useLazyQuery(gql`
    query {
      getSmsTemplates {
        id
        name
        body
      }
    }
  `);

  const [deleteTemplate] = useMutation(DELETE_EMAIL_TEMPLATE);
  const [deleteSmsTemplate] = useMutation(DELETE_SMS_TEMPLATE);
  const [messageType, setMessageType] = useState<"email" | "sms">("email");

  useEffect(() => {
    if (data?.getEmailTemplates && messageType === "email") {
      setTemplates(data.getEmailTemplates);
    } else if (smsData?.getSmsTemplates && messageType === "sms") {
      setTemplates(smsData.getSmsTemplates);
    }
  }, [data, messageType, smsData]);

  const handleRowClick = (e: any, template: any) => {
    if (selectedTemplate && selectedTemplate.id === template.id) {
      setSelectedTemplate(null);
    } else {
      setSelectedTemplate(template);
    }
  };

  const handleDelete = async (e: any, template: any) => {
    e.stopPropagation();
    if (
      !window.confirm(
        `Are you sure you want to delete "${template.name}" template?`
      )
    ) {
      return;
    }
    const variables = {
      id: template.id,
    };

    const deleteFunc =
      messageType === "email" ? deleteTemplate : deleteSmsTemplate;
    deleteFunc({ variables })
      .then(() => {
        setTemplates(templates.filter((t: any) => t.id !== template.id));
        showSuccess(`Email template "${template.name}" was deleted`);
      })
      .catch(() => {
        showError("An error occurred when deleting the email template");
      });
  };

  const handleMessageTypeChange = (value: any) => {
    setMessageType(value);
    if (value === "email") {
      refetch();
    } else if (value === "sms") {
      getSmsTemplates();
    }
  };

  const handleTemplateSaved = (template: any) => {
    messageType === "email" ? refetch() : getSmsTemplates();
    setTemplateToEdit(null);
  };

  if (!data) {
    return <></>;
  }

  return (
    <SplitLayout>
      <LeftPane width={224}>
        <SettingsSubNav />
        <SettingsSelect label="" defaultValue="/settings/email-templates" />
      </LeftPane>
      <DetailPane>
        <Grid container className={classes.pageLayout} spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <PageTitle
              title={"Message Templates"}
              subtitle={
                <>
                  Create message templates to save time with client outreach and
                  schedule sales and marketing messages.
                  <SupportLink url="https://intercom.help/shakerio/en/articles/7436403-guide-to-email-and-sms-text-message-templates">
                    Guide to creating message templates
                  </SupportLink>
                </>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: features["Text Messaging"]
                  ? "space-between"
                  : "flex-end",
              }}
            >
              {features["Text Messaging"] && (
                <ToggleButtonGroup
                  style={{ marginBottom: "10px" }}
                  exclusive
                  value={messageType}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e, v) => {
                    handleMessageTypeChange(v);
                  }}
                >
                  <ToggleButton value="email">Email</ToggleButton>
                  <ToggleButton value="sms">SMS</ToggleButton>
                </ToggleButtonGroup>
              )}
              {messageType === "email" && (
                <PrimaryButton
                  disabled={!canEditTemplates()}
                  onClick={() => {
                    setTemplateToEdit({
                      subject: "",
                      name: "",
                      body: {
                        html: "",
                        plaintext: "",
                      },
                    });
                  }}
                >
                  <AddIcon
                    style={{
                      width: "13px",
                      height: "13px",
                      marginRight: "4px",
                    }}
                  />
                  New Template
                </PrimaryButton>
              )}
              {messageType === "sms" && (
                <SmsTemplateDialog onTemplateSaved={handleTemplateSaved}>
                  <PrimaryButton
                    key={"newtemplate"}
                    disabled={!canEditTemplates()}
                  >
                    <AddIcon
                      style={{
                        width: "13px",
                        height: "13px",
                        marginRight: "4px",
                      }}
                    />
                    New Template
                  </PrimaryButton>
                </SmsTemplateDialog>
              )}
            </Box>
          </Grid>
          {templates.length === 0 && (
            <Grid item xs={12}>
              <Paper>
                <Box p={4} textAlign="center">
                  <Typography>
                    You don't have any{" "}
                    {messageType === "email" ? "email" : "sms"} templates
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          )}
          {templates.length > 0 && (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {templates.map((template: any) => (
                      <React.Fragment key={template.id}>
                        <TableRow
                          className={classes.templateRow}
                          onClick={(e) => handleRowClick(e, template)}
                        >
                          <TableCell className={classes.templateName}>
                            {template.name}
                          </TableCell>
                          <TableCell align="right">
                            {messageType === "email" && (
                              <IconButton
                                className="table-row-action"
                                onClick={(e) => {
                                  setTemplateToEdit(template);
                                  e.stopPropagation();
                                }}
                                disabled={!canEditTemplates()}
                                size="large"
                              >
                                <PencilIcon />
                              </IconButton>
                            )}
                            {messageType === "sms" && (
                              <SmsTemplateDialog
                                onTemplateSaved={handleTemplateSaved}
                                template={template}
                              >
                                <IconButton
                                  className="table-row-action"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  disabled={!canEditTemplates()}
                                  size="large"
                                >
                                  <PencilIcon />
                                </IconButton>
                              </SmsTemplateDialog>
                            )}
                            <IconButton
                              className="table-row-action"
                              onClick={(e) => handleDelete(e, template)}
                              disabled={!canEditTemplates()}
                              size="large"
                            >
                              <TrashIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        {selectedTemplate?.id === template.id ? (
                          messageType === "email" ? (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <TemplateIframe
                                  html={template.body.html}
                                  subject={template.subject}
                                />
                                {template?.attachments?.length > 0 && (
                                  <>
                                    <Divider
                                      light
                                      style={{ marginBottom: "10px" }}
                                    />
                                    <Typography variant="h6" gutterBottom>
                                      Attachments:
                                    </Typography>
                                    {template.attachments.map(
                                      (attachment: any) => {
                                        return (
                                          <Chip
                                            avatar={
                                              <InsertDriveFileIcon fontSize="small" />
                                            }
                                            key={attachment.id}
                                            label={attachment.fileName}
                                            style={{ marginRight: "5px" }}
                                          />
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <TemplateIframe
                                  html={template.body}
                                  subject={template.name}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
        {templateToEdit && (
          <TemplateDialog
            template={templateToEdit}
            onTemplateSaved={handleTemplateSaved}
            onClose={() => setTemplateToEdit(null)}
            mergeFields={data.getEmailTemplateMergeFields}
          />
        )}
      </DetailPane>
    </SplitLayout>
  );
}

const mapStateToProps = ({ features }: { [key: string]: Feature }) => {
  return { features };
};
export default connect(mapStateToProps)(EmailTemplateList);
