import React, { FormEvent, useState, ChangeEvent } from "react";
import { TextField, Button, Link, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { validateEmail, validateString, hasTrueValues } from "../helpers";
import SideLayout from "../layouts/SideLayout";
import "./Login.scss";

import QueryString from "qs";
import { useAuth } from "../context/auth";
import { Stack } from "@mui/system";

function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [showVerification, setShowVerification] = useState(false);
  const [verificationId, setVerificationId] = useState<string | undefined>();
  const [verificationCode, setVerificationCode] = useState<
    string | undefined
  >();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>();
  const [formErrors, setFormErrors] = useState<any>({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const location = useLocation();
  const goToNext = () => {
    let next = "/";
    if (location.search.length) {
      const search = QueryString.parse(location.search.substr(1));
      if (search.next) {
        next = search.next as string;
        delete search.next;
        // Do we have more search params?
        if (Object.keys(search).length) {
          next += `?${QueryString.stringify(search)}`;
        }
      }
    }
    navigate(next);
  };

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    // Validate
    setError(undefined);
    const formErrors: any = {};
    const params: any = {};
    [params.email, formErrors.email] = validateEmail(email);
    [params.password, formErrors.password] = validateString(password);

    setFormErrors(formErrors);
    if (hasTrueValues(formErrors)) {
      return;
    }

    // Perform login
    setSubmitting(true);
    login(email, password, verificationId, verificationCode)
      .then(() => {
        goToNext();
      })
      .catch((err) => {
        if (err.verificationId) {
          setVerificationId(err.verificationId);
          setShowVerification(true);
        } else {
          setError("The email or password is not correct.");
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  function handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  const useStyles = makeStyles((theme) => ({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    img: {
      height: "45px",
      width: "100%",
      paddingBottom: "1em",
    },
    p: {
      fontSize: "16px",
      fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      fontWeight: 600,
      textAlign: "center",
    },

    leftSide: {
      height: "100%",
      textAlign: "center",
      padding: "25px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  }));

  const classes = useStyles();

  return (
    <SideLayout
      left={
        <>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.leftSide}
          >
            <Typography
              variant="h2"
              style={{
                fontFamily: "RecoletaSemiBold, AvenirNext, Avenir, sans-serif",
              }}
            >
              Better Real Estate Transactions, Period
            </Typography>
          </Box>
        </>
      }
      right={
        <Box alignItems="center" justifyContent="center" display="flex">
          <Box
            sx={{
              width: {
                md: "60%",
              },
            }}
          >
            <img
              src="/logo-full.svg"
              alt="Shaker logo"
              aria-label="Shaker logo"
              title="Shaker"
              className={classes.img}
            />

            <Box textAlign="center">
              <Typography variant="h5" gutterBottom>
                Login to Shaker
              </Typography>
            </Box>

            <form noValidate className={classes.form} onSubmit={handleSubmit}>
              <Stack spacing={1}>
                {error ? <Alert severity="error">{error}</Alert> : null}
                {!showVerification && (
                  <>
                    <TextField
                      label="Email"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      error={formErrors.email}
                      onChange={handleEmailChange}
                      value={email}
                    />
                    <TextField
                      label="Password"
                      type="password"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      value={password}
                      error={formErrors.password}
                      onChange={handlePasswordChange}
                    />
                  </>
                )}
                {showVerification && (
                  <>
                    <Typography gutterBottom>
                      We sent a verification code to your phone. Please enter it
                      below.
                    </Typography>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Verification Code"
                      value={verificationCode ?? ""}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                  </>
                )}
                {!showVerification && (
                  <Box textAlign="right">
                    <Link
                      to="/forgot-password"
                      component={RouterLink}
                      variant="body2"
                    >
                      Forgot password?
                    </Link>
                  </Box>
                )}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                >
                  Login
                </Button>

                <Link
                  href="https://www.shaker.io/company/demo-request"
                  variant="body2"
                >
                  {"Don't have an account? Talk to sales"}
                </Link>
              </Stack>
            </form>
          </Box>
        </Box>
      }
    />
  );
}

const mapDispatchToProps = (dispatch: any) => ({});
const mapStateToProps = (state: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
