import React, { useState, cloneElement } from "react";
import {
  TextField,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { SecondaryButton, PrimaryButton } from "../buttons";
import ActionIconButton from "../ActionIconButton";
import { Close, InfoOutlined } from "@mui/icons-material";
import { Task, TeamMember } from "../../models";
import TeamMemberPicker from "../TeamMemberPicker";
import { AvatarGroup } from "@mui/material";
import { useGetTeamMembers } from "../../hooks/useGetTeamMembers";
import { useCreateNote } from "../../hooks/useCreateNote";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      minHeight: "100px",
    },
    notificationContainer: {
      backgroundColor: theme.palette.grey["100"],
    },
  })
);

export const notifyDesciption = (selectedAgents: TeamMember[]) => {
  if (selectedAgents.length === 0) {
    return (
      <Box marginBottom={1}>
        <Typography variant="body2">No one will be notified</Typography>
      </Box>
    );
  } else {
    return (
      <Box marginBottom={1}>
        <Typography variant="body2">
          {selectedAgents.length}{" "}
          {selectedAgents.length === 1 ? "person" : "people"} will be notified
        </Typography>
        <AvatarGroup>
          {selectedAgents.map((agent) => (
            <Avatar src={agent.avatarUrl} key={agent.id}></Avatar>
          ))}
        </AvatarGroup>
      </Box>
    );
  }
};

type CreateNoteProps = {
  contactId?: string;
  dealId?: string;
  taskId?: string;
  task?: Task;
  onNoteCreated: Function;
  children: any;
  childOnClick?: Function;
};

export default function CreateNote({
  contactId,
  dealId,
  onNoteCreated,
  children,
  taskId,
  childOnClick,
}: CreateNoteProps) {
  const [error, setError] = useState<string>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [note, setNote] = useState("");
  const classes = useStyles();

  const { selectedAgents, handleAgentSelect, teamMembers } = useGetTeamMembers(
    dealId,
    contactId,
    dialogOpen
  );

  const { createNote } = useCreateNote();

  const handleSubmit = () => {
    // Do something with those selected agents?

    if (note.length === 0) {
      return;
    }

    const input: {
      body: string;
      contactId: string | undefined;
      dealId?: string;
      taskId?: string;
      notifications?: { teamMemberId: string }[];
    } = {
      body: note,
      contactId: contactId,
      notifications: selectedAgents.map((agent) => ({
        teamMemberId: agent.id,
      })),
    };

    if (dealId && !taskId) {
      input.dealId = dealId;
    } else if (taskId) {
      input.taskId = taskId;
    }

    createNote({
      variables: {
        input,
      },
    })
      .then((result: any) => {
        setError(undefined);
        setNote("");
        onNoteCreated(result.data.createNote);
        setDialogOpen(false);
      })
      .catch((e) => setError(e.message));
  };

  return (
    <>
      {cloneElement(children as any, {
        onClick: (e) => {
          e.stopPropagation();
          if (childOnClick) {
            childOnClick();
          }
          setDialogOpen(true);
        },
      })}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Add Note</Typography>
            <ActionIconButton
              icon={Close}
              style={{ margin: "4px -5px" }}
              buttonStyle={{ width: "22px", height: "22px" }}
              onClick={() => setDialogOpen(false)}
              aria-label="close"
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", paddingTop: 7 }}>
            <TextField
              fullWidth
              autoFocus
              label="Write a note"
              name="noteBody"
              variant="outlined"
              multiline
              error={!!error}
              onChange={(e) => setNote(e.currentTarget.value)}
              value={note}
              inputProps={{ className: classes.input }}
            />
            {error && (
              <FormHelperText id="note-error-text">{error}</FormHelperText>
            )}
          </div>
          <Box
            marginTop={1}
            padding={2}
            className={classes.notificationContainer}
          >
            <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
              Notifications
            </Typography>
            {notifyDesciption(selectedAgents)}
            <TeamMemberPicker
              limitTags={2}
              variant="outlined"
              placeholder="Team Members"
              label="Select Team Member(s)"
              value={selectedAgents}
              onChange={handleAgentSelect}
              teamMembers={teamMembers}
            />
          </Box>
          {taskId && (
            <Typography
              color="textSecondary"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 11,
              }}
            >
              <InfoOutlined />
              &nbsp;Notes on tasks are internal and not visible to clients.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            onClick={() => setDialogOpen(false)}
            style={{ width: "100px" }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSubmit}
            style={{ width: "100px" }}
            disabled={note.length === 0}
          >
            Add Note
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
