import React, { useState, useRef, useEffect, useCallback } from "react";
import MaterialTable, { MTableBodyRow } from "@material-table/core";
import { Menu, MenuItem, Checkbox, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import moment from "moment";
import download from "downloadjs";
import ActionIconButton from "../ActionIconButton";
import TableIcons from "../TableIcons";
import { ViewColumnIcon } from "../../icons";
import { PrimaryButton } from "../buttons";
import { useNavigate } from "react-router-dom";
import DealContactNamesWithAvatar from "./DealContactNamesWithAvatar";
import { screenLessThan } from "../../helpers";
import "./DealsTable.scss";
import { useAuth } from "../../context/auth";
import { getFullAddress } from "../../helpers/address";

type DealsTableProps = {
  reports: any;
  columnToShow?: any;
};

type Column = {
  key: string;
  visible: boolean;
  title: any;
  sorting?: boolean;
  width?: string;
  headerStyle?: any;
  cellStyle?: any;
  render?: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewDealButton: {
      opacity: 0,
      transition: "all 0.1s ease-in-out",
    },
    tableRow: {
      "&:hover": {
        "& $viewDealButton": {
          opacity: 1,
        },
      },
    },
  })
);

const DealsTable = ({
  reports,
  columnToShow = "value",
}: DealsTableProps): JSX.Element => {
  const { authToken } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const columnsMenuButtonRef = useRef(null);
  const deals = reports.allDeals;
  const [columnMenuOpen, setColumnMenuOpen] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);

  const renderName = (rowData: any): JSX.Element => {
    return <DealContactNamesWithAvatar contacts={rowData.contacts} />;
  };

  const renderAddress = (rowData: any): JSX.Element => (
    <>{getFullAddress(rowData.addressFields)}</>
  );

  const renderAsCurrency =
    (key: string) =>
    (rowData: any): JSX.Element => {
      let value = rowData[key];
      if (value) {
        value = value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      } else {
        value = "$0.00";
      }
      return <div>{value}</div>;
    };

  const renderColumnSelectHeader = () => {
    return (
      <ActionIconButton
        ref={columnsMenuButtonRef}
        icon={ViewColumnIcon}
        buttonStyle={{ margin: "0", width: "26px", height: "26px" }}
        onClick={(event: any) => {
          event.stopPropagation();
          setColumnMenuOpen(true);
        }}
      />
    );
  };

  const toggleColumnVisible = (key: string) => () => {
    setColumns((columns) => {
      const column = columns.find((column) => column.key === key);
      const updatedColumn = Object.assign({}, column, {
        visible: !column?.visible,
      });
      return columns.map((c) => (c.key === key ? updatedColumn : c));
    });
  };

  const renderViewDealsButton =
    (key: string) =>
    (rowData: any): JSX.Element => {
      return (
        <PrimaryButton
          className={classes.viewDealButton}
          onClick={() => navigate(`/deals/${rowData.id}`)}
        >
          View Deal
        </PrimaryButton>
      );
    };

  const renderClosedDate = (rowData: any) => {
    return moment(rowData.closeDate).format("MMM Do, YYYY");
  };

  const handleExportCSV = () => {
    const { dateRange, filter } = reports;
    const body = {
      fields: [
        // "id",
        "name",
        "address",
        "source",
        "agentName",
        "commission",
        "value",
        "closeDate",
      ],
      closingBefore: dateRange.to,
      closingAfter: dateRange.from,
      includeArchived: true,
      state: filter.includePending ? undefined : "closed",
    };

    fetch(`${process.env.REACT_APP_API_URL}/reports/deals.csv`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        download(blob, "deals.csv", "text/csv");
      });
  };

  const buildInitialColumns = useCallback((relatedColumn: string) => {
    return [
      {
        key: "name",
        visible: true,
        title: "Name",
        field: "name",
        headerStyle: { paddingLeft: "24px" },
        cellStyle: { paddingLeft: "24px" },
        render: renderName,
      },
      {
        key: "address",
        visible: true,
        title: "Address",
        field: "address",
        sorting: false,
        render: renderAddress,
      },
      {
        key: "agent",
        visible: true,
        title: "Agent",
        field: "agent",
        sorting: true,
      },
      {
        key: "source",
        visible: relatedColumn === "source",
        title: "Source",
        field: "source",
        sorting: true,
      },
      {
        key: "value",
        visible: relatedColumn === "value",
        title: "Sale Price",
        field: "value",
        sorting: true,
        render: renderAsCurrency("value"),
      },
      {
        key: "commission",
        visible: relatedColumn === "commission",
        title: "Commission",
        field: "commission",
        sorting: true,
        render: renderAsCurrency("commission"),
      },
      {
        key: "closeDate",
        visible: true,
        title: "Closed Date",
        field: "closeDate",
        sorting: true,
        render: renderClosedDate,
      },
      {
        key: "column-select",
        visible: true,
        title: renderColumnSelectHeader(),
        sorting: false,
        width: "34px",
        headerStyle: { paddingRight: "24px" },
        cellStyle: { paddingRight: "24px", minWidth: 150 },
        render: renderViewDealsButton("viewDeals"),
      },
    ];
    // ignoring dependency array because we do not need to rerender on various initial render methods
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const initialColumns = buildInitialColumns(columnToShow);
    setColumns(initialColumns);
  }, [columnToShow, buildInitialColumns]);

  return (
    <>
      {columns.length > 0 && (
        <>
          <Menu
            open={columnMenuOpen}
            onClose={() => setColumnMenuOpen(false)}
            anchorEl={columnsMenuButtonRef.current}
          >
            {columns
              .filter((column) => column.key !== "column-select")
              .map((column) => (
                <MenuItem
                  key={column.key}
                  onClick={toggleColumnVisible(column.key)}
                  style={{ paddingLeft: "0", fontSize: "12px" }}
                >
                  <Checkbox checked={column.visible} size="small" />
                  {column.title}
                </MenuItem>
              ))}
          </Menu>
          <MaterialTable
            columns={columns.filter((column) => column.visible)}
            data={deals ? deals.filter((deal: any) => deal.checked) : []}
            icons={TableIcons}
            actions={[
              {
                icon: () => (
                  <PrimaryButton height="full">Export as CSV</PrimaryButton>
                ),
                position: "toolbar",
                onClick: () => handleExportCSV(),
              },
            ]}
            components={{
              Row: (props) => (
                <MTableBodyRow className={classes.tableRow} {...props} />
              ),
            }}
            options={{
              selection: false,
              showTitle: false,
              search: true,
              searchFieldStyle: {
                border: "none",
                borderRadius: "15px",
                paddingLeft: "10px",
                backgroundColor: "#f4f5f7",
                fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(
                  ","
                ),
                maxWidth: screenLessThan(426) ? "134px" : "auto",
              },
              searchFieldAlignment: "left",
              emptyRowsWhenPaging: false,
              columnsButton: false,
              pageSize: 10, // sets default to 10
              toolbar: true,
              headerStyle: {
                fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(
                  ","
                ),
                fontSize: "14px",
                color: "rgb(107, 114, 128)",
              },
            }}
            style={{
              width: "100%",
              fontSize: "14px",
              fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(
                ","
              ),
            }}
          ></MaterialTable>
        </>
      )}
    </>
  );
};

export default DealsTable;
