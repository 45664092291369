import React, { useState, useRef, useEffect, useCallback } from "react";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { useMutation, gql } from "@apollo/client";
import { CREATE_DEAL, GET_POTENTIAL_DUPLICATES } from "../../api/graphql";
import StageForm from "../stages/StageForm";
import { PrimaryButton, SecondaryButton } from "../buttons";
import ContactSelect from "../ContactSelect";
import { useUser } from "../../context/user";
import { connect } from "react-redux";
import { DealType, Integration } from "../../models";
import { RightSideDrawer } from "../../components/RightSideDrawer";
import { cleanFields } from "../../helpers/fields";
import { useAlert } from "../../context/alert";
import EmailField from "../fields/EmailField";
import PhoneField from "../fields/PhoneField";
import { CloseRounded } from "@mui/icons-material";
import { useApi } from "../../context/api";
import CreateFromIntegration from "../integrations/CreateFromIntegration";

type CreateDialogProps = {
  open: boolean;
  onClose: () => any;
  dealTypes: DealType[];
  dealTypeId?: string;
  contacts?: Array<any>;
  onDealCreated: Function;
  availableIntegrations: Integration[];
};

type Address = {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
};

type IntegrationType = "dotloop" | "skyslope" | "docusign";

const GET_CONTACT_ADDRESS = gql`
  query GetContact($id: ID!) {
    getContact(id: $id) {
      address {
        street
        street2
        city
        state
        zip
      }
    }
  }
`;

const CreateForm = ({
  open,
  onClose,
  dealTypes,
  dealTypeId: dealTypeIdProp,
  contacts = [],
  onDealCreated,
  availableIntegrations,
}: CreateDialogProps) => {
  const DEFAULT_DEAL_TYPE_KEY = "createDealDialogDefaultDealTypeId";
  const { currentUser } = useUser();
  const [dealType, setDealType] = useState<DealType>();
  const [invalidEmailIndexes, setInvalidEmailIndexes] = useState<number[]>([]);
  const [contactSuggestions, setContactSuggestions] = useState<{
    [key: number]: any;
  }>({});
  const [stage, setStage] = useState<any>();
  const [deal, setDeal] = useState({
    dealTypeId: null,
    contacts,
    stageId: null,
    agentId: currentUser?.teamMember?.id,
  });
  const { showError } = useAlert();
  const { apolloClient } = useApi();
  const [sendInvites, setSendInvites] = useState<boolean>(false);
  const [addressSuggestion, setAddressSuggestion] = useState<
    Address | undefined
  >(undefined);
  const [importDeal, setImportDeal] = useState(false);
  const [integrationType, setIntegrationType] =
    useState<IntegrationType | null>(null);

  const validateEmail = async (email: string | null, index: number) => {
    const setValid = () => {
      setInvalidEmailIndexes((invalidEmailIndexes) =>
        invalidEmailIndexes.filter((i) => i !== index)
      );
    };
    // Disregard blank email
    if (!email?.length) {
      setValid();
    }
    const contacts = await getContactsByEmail(email);
    if (contacts.length) {
      if (contacts[0].indexOf("kvcore:") === 0) {
        setContactSuggestions((contactSuggestions) => ({
          ...contactSuggestions,
          [index]: contacts[0],
        }));
      }
      /*
      if (!invalidEmailIndexes.includes(index)) {
        setInvalidEmailIndexes((invalidEmailIndexes) => [
          ...invalidEmailIndexes,
          index,
        ]);
      }*/
    } else {
      setValid();
    }

    return !contacts.length;
  };

  const getContactsByEmail = async (email: string | null) => {
    return await apolloClient
      .query({
        query: GET_POTENTIAL_DUPLICATES,
        variables: { params: { emails: [email] } },
      })
      .then((response) => {
        return response.data.getPotentialDuplicates.contacts;
      })
      .catch((error) => showError(error));
  };

  const currentIntegrations: IntegrationType[] = [];
  availableIntegrations.filter((integration) => {
    if (
      integration.added === true &&
      (integration.id === "dotloop" || integration.id === "skyslope")
      // removing docusign from available integration for now
    ) {
      currentIntegrations.push(integration.id as IntegrationType);
    }
    return currentIntegrations;
  });

  const getContactAddress = useCallback(
    async (id: string | null) => {
      return await apolloClient.query({
        query: GET_CONTACT_ADDRESS,
        variables: { id },
      });
    },
    [apolloClient]
  );

  const setDealTypeId = useCallback(
    (dealTypeId: string) => {
      const dealType = dealTypes.find((type: any) => type.id === dealTypeId);
      if (!dealType) return;
      const stage = dealType.stages[0];
      setDealType(dealType);
      setStage(stage);
      setDeal((deal: any) => {
        const dealTeamMembers = [
          {
            role: {
              id: dealType.dealOwnerRoleId,
            },
            teamMember: { id: currentUser?.teamMember?.id },
          },
        ];
        return {
          ...deal,
          dealTypeId,
          dealType,
          dealTeamMembers,
          stageId: stage.id,
          agentId: currentUser?.teamMember?.id,
        };
      });
    },
    [setDeal, dealTypes, currentUser?.teamMember?.id]
  );

  useEffect(() => {
    // When the dialog is opened, do some state setup
    if (open) {
      // Get the default type
      let dealType: DealType | undefined;
      if (dealTypeIdProp) {
        dealType = dealTypes.find((type) => type.id === dealTypeIdProp);
      }

      if (!dealType) {
        const value = window.localStorage.getItem(DEFAULT_DEAL_TYPE_KEY);
        dealType = dealTypes.find((type) => type.id === value);
      }

      if (!dealType) {
        dealType = dealTypes[0];
      }

      setDealType(dealType);

      const stage = dealType.stages[0];
      setStage(stage);
      setDeal((deal: any) => {
        return { ...deal, dealTypeId: dealType?.id, stageId: stage.id };
      });
    }
  }, [open, dealTypes, dealTypeIdProp, setDeal]);

  const stageFormData = useRef<any>({});
  const [createDeal, { loading: creatingDeal }] = useMutation(CREATE_DEAL);

  function sendInviteLabel() {
    return `Collaborate with the ${dealType?.clientNoun.toLowerCase()}${
      deal.contacts.length > 1 ? "s" : ""
    } within the Shaker client portal`;
  }

  async function handleSubmit() {
    // Force the deal to also include linked contacts
    const contacts = deal.contacts
      .map((c) => {
        if (!c.id) {
          return {
            firstName: c.firstName,
            lastName: c.lastName,
            email: c.email,
            phone: c.phone,
          };
        } else {
          const arr = [{ id: c.id }];
          if (c.linkedContact) {
            arr.push({ id: c.linkedContact.id });
          }
          return arr;
        }
      })
      .flat();

    // Clean custom field data for mutation
    const input = Object.assign({}, deal, {
      ...cleanFields(stageFormData.current),
      contacts,
      sendInvites,
    });

    delete input.prefillAddress;
    delete input.dealType;
    delete input.updateContacts;

    return createDeal({
      variables: {
        input,
      },
    })
      .then((result: any) => {
        if (onDealCreated) {
          onDealCreated(result.data.createDeal);
        }
      })
      .catch((err) => {
        console.error(err);
        showError(err.message);
      });
  }

  function handleTypeChange(dealTypeId: string) {
    setDealTypeId(dealTypeId);
  }

  function handleStageChange(e: any) {
    if (!dealType) return;

    const stageId = e.target.value;
    setDeal({ ...deal, stageId });
    setStage(dealType.stages.find((stage: any) => stage.id === stageId));
  }

  function handleNewContactClick() {
    setDeal((deal: any) => {
      return {
        ...deal,
        contacts: [
          ...deal.contacts,
          {
            id: null,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            name: dealType?.clientNoun
              ? `${dealType.clientNoun} (New)`
              : "New Contact",
          },
        ],
      };
    });
  }

  useEffect(() => {
    if (dealTypes?.length > 0 && dealTypeIdProp) {
      setDealTypeId(dealTypeIdProp);
    }
  }, [dealTypes, dealTypeIdProp, setDealTypeId]);

  useEffect(() => {
    if (deal.contacts.length > 0 && deal.contacts[0].id) {
      getContactAddress(deal.contacts[0].id)
        .then((response: any) => {
          return setAddressSuggestion(response.data.getContact.address);
        })
        .catch((error: string) => showError(error));
    } else if (deal.contacts.length === 0) {
      setAddressSuggestion(undefined);
    }
  }, [deal.contacts, getContactAddress, showError, setAddressSuggestion]);

  const integrationTypeTitle: { [key in IntegrationType]: string } = {
    dotloop: "Dotloop",
    skyslope: "SkySlope",
    docusign: "Docusign Room",
  };
  const integrationTitle =
    (integrationType && integrationTypeTitle[integrationType]) || "Integration";

  return (
    <RightSideDrawer anchor="right" open={open}>
      <Box
        style={{
          minHeight: "64px",
          background: "#1A0B31",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
        }}
      >
        <Typography style={{ color: "#fff" }} variant={"h3"}>
          {!importDeal
            ? "New Deal"
            : integrationType
            ? `Import Deal from ${integrationTitle}`
            : "Import Deal From Integration"}
        </Typography>
        <Grid
          justifyContent="center"
          style={{
            marginTop: "10px",
          }}
        >
          {currentIntegrations.length > 0 && (
            <PrimaryButton
              onClick={() => {
                setImportDeal(!importDeal);
                setIntegrationType(null);
              }}
            >
              {importDeal ? "Create New Deal" : "Import Deal"}
            </PrimaryButton>
          )}
          <IconButton color="primary" onClick={onClose} size="large">
            <CloseRounded />
          </IconButton>
        </Grid>
      </Box>

      {!importDeal ? (
        <>
          <Box
            p={4}
            style={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              height: "100%",
              overflow: "auto",
            }}
          >
            <Paper style={{ marginBottom: "21px" }}>
              <Box p={2}>
                <Typography variant="h6">What kind of deal is this?</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="select-deal-type">
                        Deal Type
                      </InputLabel>
                      <Select
                        label="Deal Type"
                        native
                        required
                        inputProps={{ id: "select-deal-type" }}
                        value={deal.dealTypeId || ""}
                        onChange={(event: any) =>
                          handleTypeChange(event.target.value)
                        }
                      >
                        {dealTypes.map((dealType: DealType) => (
                          <option key={dealType.id} value={dealType.id}>
                            {dealType.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="select-stage">Stage</InputLabel>
                      <Select
                        label="Stage"
                        native
                        required
                        inputProps={{ id: "select-stage" }}
                        value={deal.stageId || ""}
                        onChange={handleStageChange}
                      >
                        {dealType ? (
                          dealType.stages.map((stage: any) => (
                            <option key={stage.id} value={stage.id}>
                              {stage.name}
                            </option>
                          ))
                        ) : (
                          <option></option>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            <Paper style={{ marginBottom: "21px" }}>
              <Box p={2}>
                <Typography variant="h6">
                  Who's the {dealType?.clientNoun}?
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ContactSelect
                      multiple={true}
                      label={dealType?.clientNoun + "s"}
                      onChange={(e, contacts) => setDeal({ ...deal, contacts })}
                      onNewContactClick={handleNewContactClick}
                      value={deal.contacts}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={sendInvites}
                          onChange={(e, checked) => setSendInvites(checked)}
                          name=""
                        />
                      }
                      label={sendInviteLabel()}
                    />
                    <Typography variant="subtitle1">
                      When selected, we'll send the {dealType?.clientNoun} an
                      invite to Shaker where they can keep up to date with their
                      deal's status, tasks, and communication
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            {deal?.contacts.map((contact: any, index) => (
              <React.Fragment key={`newContact${index}`}>
                {contact.id === null && (
                  <Paper
                    style={{ marginBottom: "21px" }}
                    key={`contact${index}`}
                  >
                    <Box p={2}>
                      <Typography variant="h6">
                        {dealType?.clientNoun} (New)
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            autoFocus
                            label="First Name"
                            fullWidth
                            required
                            variant="standard"
                            onChange={(e) => {
                              const contacts = [...deal.contacts];
                              contacts[index].firstName = e.target.value;
                              setDeal({ ...deal, contacts });
                            }}
                            value={contact.firstName ?? ""}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Last Name"
                            fullWidth
                            required
                            variant="standard"
                            value={contact.lastName ?? ""}
                            onChange={(e) => {
                              const contacts = [...deal.contacts];
                              contacts[index].lastName = e.target.value;
                              setDeal({ ...deal, contacts });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <EmailField
                            label="Email"
                            fullWidth
                            error={invalidEmailIndexes.includes(index)}
                            helperText={
                              contactSuggestions[index] ? (
                                <Typography>Import</Typography>
                              ) : null
                              /*
                          invalidEmailIndexes.includes(index)
                            ? "A contact with this email address already exists"
                            : ""
                            */
                            }
                            onBlur={(email: string | null) =>
                              validateEmail(email, index)
                            }
                            value={contact.email ?? ""}
                            onChange={(email) => {
                              const contacts = [...deal.contacts];
                              contacts[index].email = email;
                              setDeal({ ...deal, contacts });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <PhoneField
                            label="Phone"
                            fullWidth
                            value={contact.phone ?? ""}
                            onChange={(phone) => {
                              const contacts = [...deal.contacts];
                              contacts[index].phone = phone;
                              setDeal({ ...deal, contacts });
                            }}
                            settings={{
                              format: "us_can",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                )}
              </React.Fragment>
            ))}
            <StageForm
              deal={deal}
              addressSuggestion={addressSuggestion}
              stage={stage}
              onChange={(value: any) => {
                stageFormData.current = value;
              }}
            />
          </Box>
          <Box p={2} style={{ background: "#F5F5F5" }}>
            <Grid container spacing={2} style={{}}>
              <Grid item xs={6}>
                <SecondaryButton
                  fullWidth
                  variant="outlined"
                  onClick={() => onClose()}
                >
                  Close
                </SecondaryButton>
              </Grid>
              <Grid item xs={6}>
                <PrimaryButton
                  fullWidth
                  onClick={handleSubmit}
                  disabled={
                    creatingDeal ||
                    invalidEmailIndexes.length > 0 ||
                    deal.contacts.length === 0
                  }
                >
                  Create Deal
                </PrimaryButton>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <CreateFromIntegration
          open={true}
          onDealCreated={onDealCreated}
          onClose={() => onClose()}
          setIntegrationType={setIntegrationType}
          integrationType={integrationType}
          currentIntegrations={currentIntegrations}
        />
      )}
    </RightSideDrawer>
  );
};

const mapStateToProps = ({
  dealTypes,
  availableIntegrations,
}: {
  dealTypes: DealType[];
  availableIntegrations: Integration[];
}) => {
  return { dealTypes, availableIntegrations };
};

export default connect(mapStateToProps)(CreateForm);
