import React, {
  useState,
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { connect } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  Dialog,
  IconButton,
  DialogContentText,
  DialogActions,
  DialogTitle,
  DialogContent,
  Select,
  FormControl,
  InputLabel,
  Link,
  Typography,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  Tooltip,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useQuery } from "@apollo/client";
import { GET_FIELDS_FULL, GET_TEMPLATES } from "../../api/graphql";
import { PencilIcon, EmailIcon } from "../../icons";
import { useAlert } from "../../context/alert";
import DetailCard from "../DetailCard";
import AddIcon from "@mui/icons-material/Add";
import { SecondaryButton, PrimaryButton } from "../../components/buttons";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { Role, Stage } from "../../models";
import { ReactSortable } from "react-sortablejs";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  createStageEmail,
  updateStageEmail,
  deleteStageEmail,
  CreateStageEmailInput,
  UpdateStageEmailInput,
} from "../../actions/stageEmails";
import {
  createStageSms,
  updateStageSms,
  deleteStageSms,
  CreateStageSmsInput,
  UpdateStageSmsInput,
} from "../../actions/stageSms";
import TemplateDialog from "../messageTemplates/TemplateDialog";
import { ApiClient, useApi } from "../../context/api";
import { PhoneAndroid } from "@mui/icons-material";
import EmailTemplateAutocomplete from "../messageTemplates/EmailTemplateAutocomplete";
import SmsTemplateAutocomplete from "../messageTemplates/SmsTemplateAutcomplete";
import SmsTemplateDialog from "../messageTemplates/SmsTemplateDialog";
import { useUser } from "../../context/user";
import { format24HourTime } from "../../helpers";

type StageSendableProps = {
  type: "email" | "sms";
  stage: Stage;
  roles: Role[];
  editable?: boolean;
  createStageEmail: (
    stageEmail: CreateStageEmailInput,
    apolloClient: ApiClient
  ) => Promise<any>;
  updateStageEmail: (
    stageEmail: UpdateStageEmailInput,
    stageId: string,
    apolloClient: ApiClient
  ) => Promise<any>;
  deleteStageEmail: (
    id: string,
    stageId: string,
    apolloClient: ApiClient
  ) => Promise<any>;
  createStageSms: (
    stageEmail: CreateStageSmsInput,
    apolloClient: ApiClient
  ) => Promise<any>;
  updateStageSms: (
    stageEmail: UpdateStageSmsInput,
    stageId: string,
    apolloClient: ApiClient
  ) => Promise<any>;
  deleteStageSms: (
    id: string,
    stageId: string,
    apolloClient: ApiClient
  ) => Promise<any>;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    scheduledEmailsContainer: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    linkButton: {
      margin: "3px",
    },
  })
);

const StageSendable = ({
  type,
  stage,
  roles,
  editable = true,
  createStageEmail,
  updateStageEmail,
  deleteStageEmail,
  createStageSms,
  updateStageSms,
  deleteStageSms,
}: StageSendableProps): JSX.Element => {
  const TO_DEAL_CONTACTS = "DEAL_CONTACTS";
  const classes = useStyles();
  const { apolloClient } = useApi();
  const { hasPermission } = useUser();

  const initialEmailState = {
    toRoleId: null,
    fromRoleId: stage.dealType.dealOwnerRole.id,
    emailTemplate: null,
    smsTemplate: null,
    toDealContacts: false,
    hasSendDate: false,
    sendDateDays: 7,
    sendDatePreposition: "after",
    sendDateTargetColumnName: "stage_enter",
    sendDateTargetCustomFieldId: null,
    sendDateTime: "09:00",
    sendAfterStageChange: true,
    to: [],
    cc: [],
    bcc: [],
  };

  const [open, setOpen] = useState(false);
  const [friendlyTypeName, setFriendlyTypeName] = useState("");
  const { showSuccess, showError } = useAlert();
  const [selected, setSelected] = useState(initialEmailState);
  const [sendables, setSendables] = useState<any[]>([]);
  const [showBcc, setShowBcc] = useState(false);
  const [showCc, setShowCc] = useState(false);
  const [templateToPreview, setTemplateToPreview] = useState<any>(null);

  const [dateFields, setDateFields] = useState<{
    system: any[];
    custom: any[];
  }>({
    system: [],
    custom: [],
  });

  const [templates, setTemplates] = useState<{
    email: any[];
    sms: any[];
  }>({
    email: [],
    sms: [],
  });

  const getFieldsOnCompletedCallback = useCallback((data: any) => {
    setDateFields({
      system: data.getSystemFields.filter((f: any) => f.type === "datetime"),
      custom: data.getCustomFields.filter((f: any) => f.type === "datetime"),
    });
  }, []);

  useQuery(GET_FIELDS_FULL, {
    variables: {
      tableName: "deal",
    },
    onCompleted: getFieldsOnCompletedCallback,
  });

  const handleGetTemplates = useCallback((data: any) => {
    setTemplates({
      email: data.getEmailTemplates || [],
      sms: data.getSmsTemplates || [],
    });
  }, []);

  useQuery(GET_TEMPLATES, {
    onCompleted: handleGetTemplates,
  });

  const handleAdd = () => {
    setSelected(initialEmailState);
    setOpen(true);
  };

  const handleEdit = (e: any, sendable: any) => {
    if (!sendable.hasSendDate) {
      // init any null values so that we can have controlled components
      sendable.sendDatePreposition = initialEmailState.sendDatePreposition;
      sendable.sendDateTargetColumnName =
        initialEmailState.sendDateTargetColumnName;
      sendable.sendDateDays = initialEmailState.sendDateDays;
      sendable.sendAfterStageChange = initialEmailState.sendAfterStageChange;
    }
    if (sendable.emailTemplateId) {
      sendable.emailTemplate = templates.email.find(
        (t: any) => t.id === sendable.emailTemplateId
      );
    } else if (sendable.smsTemplateId) {
      sendable.smsTemplate = templates.sms.find(
        (t: any) => t.id === sendable.smsTemplateId
      );
    }
    setSelected(sendable);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowBcc(false);
    setShowCc(false);
  };

  const handleToChange = (recipients: any) => {
    setSelected((value) =>
      Object.assign({}, value, {
        to: recipients,
      })
    );
  };

  const handleCcChange = (recipients: any) => {
    setSelected((value) =>
      Object.assign({}, value, {
        cc: recipients,
      })
    );
  };

  const handleBccChange = (recipients: any) => {
    setSelected((value) =>
      Object.assign({}, value, {
        bcc: recipients,
      })
    );
  };

  const handleFromChange = (e: any) => {
    setSelected((value) =>
      Object.assign({}, value, {
        fromRoleId: e.target.value,
      })
    );
  };

  const handleEmailTemplateChange = (e: any, emailTemplate: any) => {
    setSelected((value) =>
      Object.assign({}, value, {
        emailTemplate,
      })
    );
  };

  const handleSmsTemplateChange = (e: any, smsTemplate: any) => {
    setSelected((value) =>
      Object.assign({}, value, {
        smsTemplate,
      })
    );
  };

  const handleSendDatePrepositionChange = (e: any) => {
    setSelected((value) =>
      Object.assign({}, value, {
        sendDatePreposition: e.target.value,
      })
    );
  };

  const handleSendDateDaysChange = (e: any) => {
    setSelected((value) =>
      Object.assign({}, value, {
        sendDateDays: parseInt(e.target.value, 10),
      })
    );
  };

  const handleSendDateTargetChange = (e: any) => {
    const value = e.target.value;
    const customField = dateFields.custom.find((f: any) => f.id === value);

    setSelected((value) =>
      Object.assign({}, value, {
        sendDateTargetColumnName: customField ? null : e.target.value,
        sendDateTargetCustomFieldId: customField ? customField.id : null,
      })
    );
  };

  const handleSendAfterStageChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSelected((value) =>
      Object.assign({}, value, {
        sendAfterStageChange: !checked,
      })
    );
  };

  const handleHasSendDateChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSelected((value) =>
      Object.assign({}, value, {
        hasSendDate: checked,
      })
    );
  };

  const handleSendDateTimeChange = (e: any) => {
    setSelected((value) =>
      Object.assign({}, value, {
        sendDateTime: e.target.value,
      })
    );
  };

  const handleSave = () => {
    // Cleanup our email object.
    const email: any = Object.assign({}, selected);

    // React SortableJS adds the properties `chosen` and `selected` which
    // the mutation doesn't expect.
    delete email.chosen;
    delete email.selected;

    if (email.toRoleId === TO_DEAL_CONTACTS) {
      delete email.toRoleId;
    }

    if (type === "sms") {
      email.smsTemplateId = email.smsTemplate.id;
      delete email.cc;
      delete email.bcc;
    } else if (type === "email") {
      email.emailTemplateId = email.emailTemplate.id;
    }

    delete email.emailTemplate;
    delete email.smsTemplate;

    if (!email.hasSendDate) {
      email.sendDateDays = null;
      email.sendDatePreposition = null;
      email.sendDateTargetColumnName = null;
      email.sendDateTargetCustomFieldId = null;
    }

    const mapContacts = (contact: any) => {
      return contact.map((t: any) => {
        return {
          roleId: t.roleId ? t.roleId : t.id ? t.id : null,
          dealContacts: t.value === TO_DEAL_CONTACTS,
        };
      });
    };

    if (email.to) {
      email.to = email.to.map((t: any) => {
        return {
          roleId: t.roleId ? t.roleId : t.id ? t.id : null,
          dealContacts: t.value === TO_DEAL_CONTACTS || t.roleId === null,
        };
      });
    }

    if (email.cc) {
      email.cc = mapContacts(email.cc);
    }

    if (email.bcc) {
      email.bcc = mapContacts(email.bcc);
    }

    // Determine if we're creating or updating.

    let promise: Promise<any>;
    if (!email.id) {
      email.stageId = stage.id;
      const createFunc = type === "email" ? createStageEmail : createStageSms;
      promise = createFunc(email, apolloClient);
    } else {
      const updateFunc = type === "email" ? updateStageEmail : updateStageSms;
      promise = updateFunc(email, stage.id, apolloClient);
    }
    // Execute the mutation
    promise
      .then(() => {
        handleClose();
        showSuccess(
          `Your ${
            type === "email" ? "email" : "SMS"
          } trigger was saved successfully`
        );
      })
      .catch((err) => {
        showError("An error occurred when saving the email trigger");
      });
  };

  const handleDelete = (e: any, sendable: any) => {
    if (sendable.__typename === "StageEmail") {
      deleteStageEmail(sendable.id, stage.id, apolloClient).catch(() => {
        showError("An error occurred when deleting the email trigger");
      });
    } else if (sendable.__typename === "StageSms") {
      deleteStageSms(sendable.id, stage.id, apolloClient).catch(() => {
        showError("An error occurred when deleting the SMS trigger");
      });
    }
  };

  useEffect(() => {
    switch (type) {
      case "sms":
        setSendables(stage.sms ?? []);
        setFriendlyTypeName("SMS");
        break;
      case "email":
        setSendables(stage.emails ?? []);
        setFriendlyTypeName("email");
        break;
      default:
        setSendables([]);
    }
  }, [type, stage]);

  const showTimePicker = useMemo(() => {
    const customField = dateFields.custom.find(
      (f) => f.id === selected.sendDateTargetCustomFieldId
    );
    if (customField) {
      return !customField.settings.includeTime;
    }
    const systemField = dateFields.system.find(
      (f) => f.id === selected.sendDateTargetColumnName
    );
    if (systemField) {
      return !systemField.settings.includeTime;
    }
    return true;
  }, [selected, dateFields]);

  const sendDateTargetToPhrase = (stageEmail: any): string => {
    if (
      stageEmail.sendDateTargetColumnName === "stageEnter" ||
      stageEmail.sendDateTargetColumnName === "stage_enter"
    ) {
      return "a deal enters this stage";
    }

    const fieldFinder = (id: any) => {
      return (f: any) => id === f.id;
    };
    let field;
    if (stageEmail.sendDateTargetColumnName) {
      field = dateFields.system.find(
        fieldFinder(stageEmail.sendDateTargetColumnName)
      );
    } else if (stageEmail.sendDateTargetCustomFieldId) {
      field = dateFields.custom.find(
        fieldFinder(stageEmail.sendDateTargetCustomFieldId)
      );
    }
    if (field) {
      return field.label;
    } else {
      return "";
    }
  };

  const sendWhenText = (email: any) => {
    let sendText = "";
    if (email.hasSendDate) {
      if (email.sendDateDays === 0) {
      } else if (email.sendDateDays === 1) {
        sendText += ` ${email.sendDateDays} day`;
      } else {
        sendText += ` ${email.sendDateDays} days`;
      }
      sendText += " ";
      sendText += email.sendDatePreposition;
      sendText += " ";
      sendText += sendDateTargetToPhrase(email);

      if (email.sendDateTime) {
        sendText += ` at ${format24HourTime(email.sendDateTime)}`;
      }
    } else {
      sendText = "Immediately";
    }
    return sendText;
  };

  const setEmails = (emails: any) => {
    const f = type === "email" ? updateStageEmail : updateStageSms;
    emails.forEach((stageEmail: any, index: number) => {
      if (stageEmail.sort !== index) {
        stageEmail.sort = index;
        f({ id: stageEmail.id, sort: index }, stage.id, apolloClient).catch(
          () => {}
        );
      }
    });
  };

  const DescribeEmail = ({ sendable }: { sendable: any }) => {
    let to = [];
    let cc = [];
    let bcc = [];
    let from = "";
    let name = "";

    const mapSendable = (sendable: any) => {
      return sendable.map(
        (t: any) =>
          roles.find((r) => r.id === t.roleId)?.name ||
          stage.dealType.clientNoun
      );
    };

    if (sendable.to.length > 0) {
      to = mapSendable(sendable.to);
    }

    if (sendable.cc && sendable.cc.length > 0) {
      cc = mapSendable(sendable.cc);
    }

    if (sendable.bcc && sendable.bcc.length > 0) {
      bcc = mapSendable(sendable.bcc);
    }

    let template: any;

    if (sendable.emailTemplateId) {
      template = templates.email.find(
        (t: any) => t.id === sendable.emailTemplateId
      );
    } else if (sendable.smsTemplateId) {
      template = templates.sms.find(
        (t: any) => t.id === sendable.smsTemplateId
      );
    }
    if (template) {
      name = template.name;
    }

    const fromRole = roles.find((r) => r.id === sendable.fromRoleId);
    if (fromRole) {
      from = fromRole.name ?? "";
    }

    return (
      <Typography
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
          fontSize: "14px",
          fontWeight: 500,
          overflowX: "auto",
        }}
      >
        <span>
          Send{" "}
          {template?.__typename === "EmailTemplate" && (
            <Link
              href="#"
              onClick={(e: any) => {
                e.stopPropagation();
                setTemplateToPreview(template);
              }}
            >
              {name}
            </Link>
          )}
          {template?.__typename === "SmsTemplate" && (
            <SmsTemplateDialog
              template={template}
              editable={hasPermission("settings", "limited")}
            >
              <Link
                href="#"
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
              >
                {name}
              </Link>
            </SmsTemplateDialog>
          )}
        </span>
        <ArrowRightAltIcon
          style={{ marginLeft: "11px", marginRight: "11px" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <span>To: {to.join(", ")}</span>
          {cc.length > 0 && <span>Cc: {cc.join(", ")}</span>}
          {bcc.length > 0 && <span>Bcc: {bcc.join(", ")}</span>}
        </Box>
        <ArrowRightAltIcon
          style={{ marginLeft: "11px", marginRight: "11px" }}
        />
        <span>From {from}</span>
        <ArrowRightAltIcon
          style={{ marginLeft: "11px", marginRight: "11px" }}
        />
        <span>{sendWhenText(sendable)}</span>
      </Typography>
    );
  };

  return (
    <>
      {templateToPreview && (
        <TemplateDialog
          template={templateToPreview}
          onTemplateSaved={() => setTemplateToPreview(null)}
          onClose={() => {
            setTemplateToPreview(null);
          }}
          editable={hasPermission("settings", "limited")}
        ></TemplateDialog>
      )}
      <DetailCard
        collapsible={false}
        title={
          <Typography
            variant="h5"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {type === "email" && (
              <>
                <EmailIcon
                  style={{
                    background: "#FF576D",
                    color: "#fff",
                    padding: "4px 4.5px",
                    borderRadius: "50%",
                    height: "22px",
                    width: "22px",
                    marginRight: "14px",
                  }}
                />
                Emails
              </>
            )}

            {type === "sms" && (
              <>
                <div
                  style={{
                    background: "#5759FF",
                    height: "22px",
                    width: "22px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                    color: "#fff",
                    marginRight: "21px",
                  }}
                >
                  <PhoneAndroid style={{ fontSize: "14px" }} />
                </div>
                SMS
              </>
            )}
          </Typography>
        }
        action={
          <Tooltip
            title="Add"
            aria-label={`Add a triggered ${friendlyTypeName}`}
          >
            <IconButton onClick={handleAdd} disabled={!editable} size="large">
              <AddIcon />
            </IconButton>
          </Tooltip>
        }
      >
        {sendables.length === 0 && (
          <Box textAlign="center" style={{ width: "100%" }} p={4}>
            <Typography style={{ marginBottom: "11px" }}>
              Send a triggered {friendlyTypeName} to your{" "}
              {stage.dealType.clientNoun.toLowerCase()} when a deal enters this
              stage.
            </Typography>
            <PrimaryButton
              onClick={handleAdd}
              style={{ minWidth: "200px" }}
              disabled={!editable}
            >
              Add a triggered {friendlyTypeName}
            </PrimaryButton>
          </Box>
        )}

        {sendables.length > 0 && (
          <List style={{ width: "100%" }} className="actions-on-hover">
            <ReactSortable
              list={sendables}
              setList={setEmails}
              handle=".handle"
            >
              {sendables.map((email: any) => (
                <ListItem key={email.id}>
                  {sendables.length > 1 && editable && (
                    <DragIndicatorIcon
                      className="handle"
                      style={{
                        color: "#6b7280",
                        marginRight: "11px",
                        cursor: "grab",
                      }}
                    />
                  )}
                  <ListItemText primary={DescribeEmail({ sendable: email })} />
                  <ListItemSecondaryAction>
                    <Tooltip
                      title="Edit"
                      aria-label="edit"
                      className="table-row-action"
                    >
                      <IconButton
                        onClick={(e) => handleEdit(e, email)}
                        disabled={!editable}
                        size="large"
                      >
                        <PencilIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title="Delete"
                      aria-label="delete"
                      className="table-row-action"
                    >
                      <IconButton
                        onClick={(e) => handleDelete(e, email)}
                        disabled={!editable}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </ReactSortable>
          </List>
        )}
      </DetailCard>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Configure a triggered {friendlyTypeName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selected.hasSendDate ? (
              <>
                This {friendlyTypeName} will be sent {sendWhenText(selected)}
              </>
            ) : (
              <>
                This {friendlyTypeName} will be sent when a deal enters the{" "}
                <strong>{stage.name}</strong> stage.
              </>
            )}
          </DialogContentText>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                filterSelectedOptions
                onChange={(e, value) => handleToChange(value)}
                options={[
                  { name: stage.dealType.clientNoun, value: TO_DEAL_CONTACTS },
                  ...roles,
                ]}
                value={selected.to.map((t: any) => {
                  const roleName = roles.find((r) => r.id === t.roleId)?.name;
                  return {
                    name: roleName
                      ? roleName
                      : t.dealContacts
                      ? stage.dealType.clientNoun
                      : "",
                    ...t,
                  };
                })}
                getOptionLabel={(option: any) => {
                  return option.name;
                }}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.name === value.name
                }
                renderInput={(params: any) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="To:"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          {type === "email" && !showCc && !showBcc && (
                            <>
                              <Link
                                className={classes.linkButton}
                                underline="hover"
                                component="button"
                                onClick={() => setShowCc(true)}
                              >
                                Cc
                              </Link>
                              <Link
                                className={classes.linkButton}
                                underline="hover"
                                component="button"
                                onClick={() => setShowBcc(true)}
                              >
                                Bcc
                              </Link>
                            </>
                          )}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {type === "email" && showCc && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  options={[
                    {
                      name: stage.dealType.clientNoun,
                      value: TO_DEAL_CONTACTS,
                    },
                    ...roles,
                  ]}
                  onChange={(e, value) => handleCcChange(value)}
                  value={selected.cc.map((t: any) => {
                    const roleName = roles.find((r) => r.id === t.roleId)?.name;
                    return {
                      name: roleName
                        ? roleName
                        : t.dealContacts
                        ? stage.dealType.clientNoun
                        : "",
                      ...t,
                    };
                  })}
                  getOptionLabel={(role: any) => role.name}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.name === value.name
                  }
                  renderInput={(params: any) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="CC:"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {!showBcc && (
                              <Link
                                component="button"
                                underline="hover"
                                onClick={() => setShowBcc(true)}
                              >
                                Bcc
                              </Link>
                            )}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {type === "email" && showBcc && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  options={[
                    {
                      name: stage.dealType.clientNoun,
                      value: TO_DEAL_CONTACTS,
                    },
                    ...roles,
                  ]}
                  onChange={(e, value) => handleBccChange(value)}
                  value={selected.bcc.map((t: any) => {
                    const roleName = roles.find((r) => r.id === t.roleId)?.name;
                    return {
                      name: roleName
                        ? roleName
                        : t.dealContacts
                        ? stage.dealType.clientNoun
                        : "",
                      ...t,
                    };
                  })}
                  getOptionLabel={(role: any) => role.name}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.name === value.name
                  }
                  renderInput={(params: any) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="BCC:"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {!showCc && (
                              <Link
                                component="button"
                                underline="hover"
                                onClick={() => setShowCc(true)}
                              >
                                Cc
                              </Link>
                            )}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {type === "email" && (
              <Grid item xs={12}>
                <EmailTemplateAutocomplete
                  value={selected.emailTemplate}
                  onChange={handleEmailTemplateChange}
                />
              </Grid>
            )}

            {type === "sms" && (
              <Grid item xs={12}>
                <SmsTemplateAutocomplete
                  value={selected.smsTemplate}
                  onChange={handleSmsTemplateChange}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="select-from" variant="standard">
                  From
                </InputLabel>
                <Select
                  inputProps={{
                    id: "select-from",
                  }}
                  fullWidth
                  label="From"
                  native
                  variant="standard"
                  onChange={handleFromChange}
                  value={selected.fromRoleId}
                >
                  {roles.map((role: any) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleHasSendDateChange}
                  checked={selected.hasSendDate}
                />
              }
              label={`Delay sending the ${friendlyTypeName}`}
            />
          </Grid>

          {selected.hasSendDate && (
            <Box className={classes.scheduledEmailsContainer}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      type="number"
                      variant="standard"
                      value={selected.sendDateDays}
                      onChange={handleSendDateDaysChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {selected.sendDateDays === 1 ? "day" : "days"}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth variant="standard">
                      <Select
                        fullWidth
                        native
                        variant="standard"
                        value={selected.sendDatePreposition}
                        onChange={handleSendDatePrepositionChange}
                      >
                        <option value="after">After</option>
                        <option value="before">Before</option>
                        <option value="on">On</option>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={showTimePicker ? 5 : 8}>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        native
                        variant="standard"
                        value={
                          selected.sendDateTargetCustomFieldId ||
                          selected.sendDateTargetColumnName
                        }
                        onChange={handleSendDateTargetChange}
                      >
                        <option value="stageEnter">
                          A deal enters this stage
                        </option>
                        {dateFields.system.map((f: any) => (
                          <option key={f.id} value={f.id}>
                            {f.label}
                          </option>
                        ))}
                        {dateFields.custom.length && (
                          <optgroup label="Custom Fields">
                            {dateFields.custom.map((f: any) => (
                              <option key={f.id} value={f.id}>
                                {f.label}
                              </option>
                            ))}
                          </optgroup>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  {showTimePicker && (
                    <Grid item xs={3}>
                      <TextField
                        id="time"
                        type="time"
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">@</InputAdornment>
                          ),
                        }}
                        value={selected.sendDateTime}
                        onChange={handleSendDateTimeChange}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleSendAfterStageChange}
                          checked={!selected.sendAfterStageChange}
                        />
                      }
                      label={`Don't send this ${friendlyTypeName} if the deal changes from "${stage.name}"`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            onClick={handleClose}
            color="primary"
            style={{ width: "100px" }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSave}
            color="primary"
            style={{ width: "100px" }}
            disabled={
              type === "email"
                ? selected.to.length < 1 || !selected.emailTemplate
                : selected.to.length < 1 || !selected.smsTemplate
            }
          >
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ roles }: { roles: Role[] }) => {
  return { roles };
};

export default connect(mapStateToProps, {
  createStageEmail,
  updateStageEmail,
  deleteStageEmail,
  createStageSms,
  updateStageSms,
  deleteStageSms,
})(StageSendable);
