import React, { useContext, useState } from "react";
import { Typography } from "@mui/material";
import {
  ToggleButton,
  ToggleButtonGroup,
} from "../../../../components/ToggleButtons";
import DetailCard from "../../../../components/DetailCard";
import TaskList from "../../../../components/tasks/List";
import { DealViewActionType, dealViewStore } from "../context";
import { Task } from "../../../../models";

const Tasks = () => {
  const {
    state: { deal, tasks },
    dispatch,
  } = useContext(dealViewStore);
  const [tasksToggleValue, setTasksToggleValue] = useState<
    "active" | "completed"
  >("active");

  const handleTasksToggleChange = (_: any, value: "active" | "completed") => {
    setTasksToggleValue(value);
  };

  const handleTaskUpdated = (task: Task) =>
    dispatch({ type: DealViewActionType.UPDATE_TASK, task });

  const handleTaskDeleted = (task: Task) =>
    dispatch({ type: DealViewActionType.REMOVE_TASK, task });

  return (
    <DetailCard
      title={<Typography variant="h5">Tasks</Typography>}
      action={
        <ToggleButtonGroup
          exclusive
          value={tasksToggleValue}
          onChange={handleTasksToggleChange}
          style={{ marginRight: "8px" }}
        >
          <ToggleButton value="active">Active</ToggleButton>
          <ToggleButton value="completed">Completed</ToggleButton>
        </ToggleButtonGroup>
      }
    >
      <TaskList
        tasks={tasks ?? []}
        dispatch={dispatch}
        dispatchType={DealViewActionType}
        filter={tasksToggleValue}
        deal={deal}
        onTaskUpdated={handleTaskUpdated}
        onTaskDeleted={handleTaskDeleted}
      />
    </DetailCard>
  );
};

export default Tasks;
