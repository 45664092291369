import React from "react";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import { OutlineButton, PrimaryButton } from "../buttons";

interface ContacTableActionsDialogInterface {
  openActionsDialog: { open: boolean; action: string };
  setOpenActionsDialog(openActionsDialog: {
    open: boolean;
    action: string;
  }): any;
}

const ContactTableActionsDialog = ({
  openActionsDialog,
  setOpenActionsDialog,
}: ContacTableActionsDialogInterface) => {
  const { action, open } = openActionsDialog;
  const handleClose = () => {
    setOpenActionsDialog({ ...openActionsDialog, open: false });
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      {action === "enrollInWorkflow" && (
        <>
          <DialogContent>
            <h1>Enroll in Workflow</h1>
            <p>LOGIC WILL BE HERE</p>
          </DialogContent>
        </>
      )}
      {action === "deleteContacts" && (
        <>
          <DialogContent>
            <h1>Delete Contacts</h1>
            <p>LOGIC WILL BE HERE</p>
          </DialogContent>
        </>
      )}
      {action === "assignTags" && (
        <>
          <DialogContent>
            <h1>Assign Tags</h1>
            <p>LOGIC WILL BE HERE</p>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <OutlineButton onClick={handleClose}>Cancel</OutlineButton>
        <PrimaryButton>Do the thing!</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ContactTableActionsDialog;
