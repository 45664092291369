import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from "@mui/material";
import { Account } from "../../models";

const AccountIntegrations = ({ account }: { account: Account }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {account.availableIntegrations.map((integ: any) => (
            <TableRow key={integ.id}>
              <TableCell>{integ.name}</TableCell>
              <TableCell>
                {integ.added ? (
                  <Chip color="primary" label={"Added"} size="small" />
                ) : integ.enabled ? (
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={"Enabled"}
                    size="small"
                  />
                ) : (
                  <Chip
                    variant="outlined"
                    color="secondary"
                    label={"Unavailable"}
                    size="small"
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountIntegrations;
