import React, { useCallback, useEffect, useRef, useState } from "react";
import { Paper, Theme, Box, Fab, Typography, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link as RouterLink } from "react-router-dom";
import { useMutation, gql, useApolloClient } from "@apollo/client";
import SplitLayout from "../layouts/SplitLayout";
import LeftPane from "../components/LeftPane";
import DetailPane from "../components/DetailPane";
import { GET_EMAIL_THREADS, GET_SMS_THREADS } from "../api/graphql";
import EmailThreadView from "../components/emails/ThreadView";

import { Send } from "@mui/icons-material";
import ComposeEmailDialog from "../components/emails/ComposeEmailDialog";

import { useLocation } from "react-router-dom";
import EmailThreadList from "../components/emails/ThreadList";
import { io } from "socket.io-client";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";
//import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { ToggleButtonGroup, ToggleButton } from "../components/ToggleButtons";
import SmsThreadView from "../components/sms/SmsThreadView";

const PAGE_SIZE = 10;

type PagingParams = {
  totalCount: number;
  pageSize: number;
  page: number;
};

type GetEmailThreadsResponse = {
  threads: any[];
  totalCount: number;
  pageSize: number;
  page: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

export default function MessagesPage() {
  const apollo = useApolloClient();
  const { authToken } = useAuth();
  const classes = useStyles();
  const location = useLocation();
  const [mode, setMode] = useState<"sms" | "email">("email");
  const [filter, setFilter] = useState<any>(null);
  const [isSent, setIsSent] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [thread, setThread] = useState<any | null>(null);
  const [threads, setThreads] = useState<any[]>([]);
  const pagingParams = useRef<PagingParams | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentUser } = useUser();
  const emailIsLinked = currentUser?.hasEmailLinked;

  const [markEmailThreadAsRead] = useMutation(gql`
    mutation MarkEmailThreadAsRead($input: MarkEmailThreadAsReadInput!) {
      markEmailThreadAsRead(input: $input)
    }
  `);

  const [markSmsThreadAsRead] = useMutation(gql`
    mutation MarkSmsThreadAsRead($input: MarkSmsThreadAsReadInput!) {
      markSmsThreadAsRead(input: $input)
    }
  `);

  const handleThreadClick = (thread: any) => {
    if (thread.__typename === "SmsThread") {
      markSmsThreadAsRead({
        variables: {
          input: {
            smsThreadId: thread.id,
          },
        },
      }).catch(() => {});
      setThread(thread);
      setThreads((threads) =>
        threads.map((t: any) => {
          return {
            ...t,
            firstUnreadSmsId: t.id === thread.id ? null : t.firstUnreadSmsId,
          };
        })
      );
    } else {
      markEmailThreadAsRead({
        variables: {
          input: {
            emailThreadId: thread.id,
          },
        },
      }).catch(() => {});
      setThread(thread);
      setThreads((threads) =>
        threads.map((t: any) => {
          return {
            ...t,
            unreadCount: t.id === thread.id ? 0 : t.unreadCount,
          };
        })
      );
    }
  };

  const handleArchive = () => {
    setThreads((threads) => threads.filter((t: any) => t.id !== thread.id));
    setThread(null);
  };

  useEffect(() => {
    const filter: any = {};
    let isDefault = false;
    if (location.hash === "#archived") {
      filter.archived = true;
    } else if (location.hash === "#sent") {
      setIsSent(true);
      filter.direction = "outgoing";
    } else {
      // Default inbox
      isDefault = true;
      filter.direction = "incoming";
      filter.archived = false;
    }
    if (searchTerm) {
      filter.query = searchTerm;
      // If we're on the default inbox and a search term is entered,
      // we'll show all emails regardless of direction or archive
      if (isDefault) {
        delete filter.direction;
        delete filter.archived;
      }
    }
    setFilter(filter);
  }, [location.hash, searchTerm]);

  const loadPage = useCallback(
    (
      page: number = 0,
      pageSize = PAGE_SIZE
    ): Promise<GetEmailThreadsResponse> => {
      setLoading(true);
      const query = mode === "email" ? GET_EMAIL_THREADS : GET_SMS_THREADS;
      return apollo
        .query({
          query,
          variables: {
            filter: {
              ...filter,
              page,
              pageSize,
            },
          },
        })
        .then((res) => {
          return mode === "email"
            ? res.data.getEmailThreads
            : res.data.getSmsThreads;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [apollo, filter, mode]
  );

  const loadFirstPage = () => {
    loadPage(0).then((res) => {
      setThreads(res.threads);
      pagingParams.current = {
        totalCount: res.totalCount,
        page: res.page,
        pageSize: res.pageSize,
      };
    });
  };

  const loadFirstPageCallback = useCallback(loadFirstPage, [loadPage]);
  useEffect(() => {
    if (filter) {
      setThread(null);
      loadFirstPageCallback();
    }
  }, [filter, loadFirstPageCallback]);

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_API_URL;
    if (!socketUrl) {
      return;
    }
    const socket = io(socketUrl, {
      path: "/io/",
      auth: {
        token: authToken,
      },
      transports: ["websocket"],
    });
    const cb = () => {};
    socket.on("email:incoming", cb);
    socket.on("email:outgoing", cb);
    socket.on("smsThread:incoming", (payload) => {
      loadFirstPageCallback();
    });
    return () => {
      socket.disconnect();
    };
  }, [loadFirstPageCallback, mode, authToken]);

  const handleScroll = (e: any) => {
    if (pagingParams.current === null) {
      return;
    }
    const isNearBottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    const hasMoreThreads = threads.length < pagingParams.current.totalCount;
    if (isNearBottom && hasMoreThreads && !loading) {
      const nextPage = pagingParams.current.page + 1;
      loadPage(nextPage).then((res) => {
        setThreads((threads) => [...threads, ...res.threads]);
        if (pagingParams.current) {
          pagingParams.current.page = res.page;
          pagingParams.current.totalCount = res.totalCount;
          pagingParams.current.pageSize = res.pageSize;
        }
      });
    }
  };

  return (
    <SplitLayout>
      <LeftPane onScroll={handleScroll}>
        <ToggleButtonGroup
          exclusive
          style={{ width: "100%", height: 45 }}
          value={mode}
          onChange={(e: any, mode: string) => {
            if (mode === "sms" || mode === "email") {
              setMode(mode);
            }
          }}
        >
          <ToggleButton
            value="email"
            style={{ width: "100%", borderRadius: 0 }}
          >
            Email
          </ToggleButton>
          <ToggleButton value="sms" style={{ width: "100%", borderRadius: 0 }}>
            SMS
          </ToggleButton>
        </ToggleButtonGroup>
        <EmailThreadList
          isSent={isSent}
          loading={loading}
          threads={threads}
          onThreadClick={handleThreadClick}
          onSearch={(searchTerm) => setSearchTerm(searchTerm)}
        ></EmailThreadList>
      </LeftPane>
      <DetailPane>
        {!loading && threads.length === 0 && (
          <Paper>
            <Box p={2} textAlign="center">
              {mode === "email" && (
                <>
                  <Typography variant="h6">
                    You don't have any emails
                  </Typography>
                  {!emailIsLinked && (
                    <Typography>
                      <Link component={RouterLink} to={"/settings/email"}>
                        Link your email
                      </Link>{" "}
                      to sync messages between you and your contacts
                    </Typography>
                  )}
                </>
              )}
              {mode === "sms" && !currentUser.teamMember.provisionedPhone && (
                <>
                  <Typography variant="h6">
                    SMS is not enabled on your account
                  </Typography>
                  <Typography>
                    Please{" "}
                    <Link href="mailto:support@shaker.io">contact support</Link>{" "}
                    to get a phone number provisioned to your account
                  </Typography>
                </>
              )}
              {mode === "sms" && currentUser.teamMember.provisionedPhone && (
                <>
                  <Typography variant="h6">
                    You don't have any SMS messages
                  </Typography>
                </>
              )}
            </Box>
          </Paper>
        )}
        {thread?.__typename === "EmailThread" && (
          <EmailThreadView
            standalone
            emailThread={thread}
            onArchive={handleArchive}
          />
        )}
        {thread?.__typename === "SmsThread" && (
          <SmsThreadView smsThread={thread} />
        )}
        <ComposeEmailDialog>
          <Fab className={classes.fab}>
            <Send />
          </Fab>
        </ComposeEmailDialog>
      </DetailPane>
    </SplitLayout>
  );
}
