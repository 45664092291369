import { gql, useQuery } from "@apollo/client";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import TeamMemberSelect from "../TeamMemberSelect";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

type FromType = "teamMember" | "contactOwner";

type SmsSettingsProps = {
  step: any;
  setStep: (step: any) => any;
};

const SmsSettings: FunctionComponent<SmsSettingsProps> = ({
  step,
  setStep,
}) => {
  const { data } = useQuery(
    gql`
      query {
        getSmsTemplates {
          id
          name
        }
      }
    `
  );

  const [fromType, setFromType] = useState<FromType>("contactOwner");
  const [selectedSmsTemplate, setSelectedSmsTemplate] = useState<any | null>(
    null
  );

  useEffect(() => {
    if (!data?.getSmsTemplates) {
      return;
    }
    setSelectedSmsTemplate(
      data.getSmsTemplates.find(
        (smsTemplate: { id: string }) => smsTemplate.id === step.smsTemplateId
      ) || null
    );
  }, [data, step.smsTemplateId]);

  useEffect(() => {
    const fromType: FromType = step.teamMemberId
      ? "teamMember"
      : "contactOwner";
    setFromType(fromType);
  }, [step]);

  if (!step || !data) {
    return <></>;
  }

  const handleChange = (e: ChangeEvent<{}>, smsTemplate: any | null) => {
    setSelectedSmsTemplate(smsTemplate);
    setStep({ ...step, smsTemplateId: smsTemplate?.id || null });
  };

  const handleTeamMemberChange = (teamMember: any) => {
    setStep({ ...step, teamMemberId: teamMember?.id || null });
  };

  const handleFromChange = (e: any, value: string) => {
    const fromType = value as FromType;
    if (fromType === "contactOwner") {
      setStep({ ...step, teamMemberId: null });
    }
    setFromType(fromType);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          value={selectedSmsTemplate}
          onChange={handleChange}
          options={data.getSmsTemplates}
          getOptionLabel={(option: any) => (option?.name ? option.name : "")}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label="SMS Template"
              placeholder="Select an SMS template&hellip;"
              variant="standard"
            />
          )}
        ></Autocomplete>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">Send From</FormLabel>
          <RadioGroup
            aria-label="send from"
            name="send-from"
            value={fromType}
            onChange={handleFromChange}
            row
          >
            <FormControlLabel
              value={"contactOwner"}
              control={<Radio color="primary" />}
              label="The assigned agent"
            />
            <FormControlLabel
              value={"teamMember"}
              control={<Radio color="primary" />}
              label="A team member"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {fromType === "teamMember" && (
        <Grid item xs={12}>
          <TeamMemberSelect
            label="Send From"
            value={step.teamMemberId}
            onChange={handleTeamMemberChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SmsSettings;
