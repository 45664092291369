import React from "react";
import { ReactComponent as AlertCircle } from "./alert-circle.svg";
import { ReactComponent as Archive } from "./archive.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as CheckboxChecked } from "./checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "./checkbox-unchecked.svg";
import { ReactComponent as ChevronLeft } from "./chevron-left.svg";
import { ReactComponent as ChevronRight } from "./chevron-right.svg";
import { ReactComponent as CircleCheck } from "./circle-check.svg";
import { ReactComponent as CloudUpload } from "./cloud-upload.svg";
import { ReactComponent as Contacts } from "./contacts.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";
import { ReactComponent as Document } from "./document.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as Email } from "./email.svg";
import { ReactComponent as Expand } from "./expand.svg";
import { ReactComponent as FileUpload } from "./file-upload.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as ListingPrice } from "./listing-price.svg";
import { ReactComponent as Magnify } from "./magnify.svg";
import { ReactComponent as Message } from "./message.svg";
import { ReactComponent as Messages } from "./messages.svg";
import { ReactComponent as MoneyBag } from "./money-bag.svg";
import { ReactComponent as Note } from "./note.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as Reports } from "./reports.svg";
import { ReactComponent as Sent } from "./sent.svg";
import { ReactComponent as Stage } from "./stage.svg";
import { ReactComponent as Task } from "./task.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as UserAdd } from "./user-add.svg";
import { ReactComponent as ViewColumn } from "./view-column.svg";
import { ReactComponent as Settings } from "./settings.svg";
import { ReactComponent as Timer} from "./timer.svg";
import { ReactComponent as Tags} from "./tags.svg";
import { ReactComponent as Add} from "./add.svg";
import { ReactComponent as Cards} from "./cards.svg";
import { ReactComponent as Table} from "./table.svg";
import { ReactComponent as Menu} from "./menu.svg";


type IconProps = {
  size?: string;
  style?: any;
  className?: any;
  ref?: any;
};

type IconTypeProps = {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

const Icon = ({ Icon, size = "18px", ...rest }: IconProps & IconTypeProps) => {
  return <Icon width={size} height={size} {...rest} />;
};

const makeIcon = (icon: any) => (props: IconProps) => <Icon Icon={icon} {...props} />;

export const AlertCircleIcon       = makeIcon(AlertCircle);
export const ArchiveIcon           = makeIcon(Archive);
export const CalendarIcon          = makeIcon(Calendar);
export const CheckboxCheckedIcon   = makeIcon(CheckboxChecked);
export const CheckboxUncheckedIcon = makeIcon(CheckboxUnchecked);
export const ChevronLeftIcon       = makeIcon(ChevronLeft);
export const ChevronRightIcon      = makeIcon(ChevronRight);
export const CircleCheckIcon       = makeIcon(CircleCheck);
export const CloudUploadIcon       = makeIcon(CloudUpload);
export const ContactsIcon          = makeIcon(Contacts);
export const DashboardIcon         = makeIcon(Dashboard);
export const DocumentIcon          = makeIcon(Document);
export const DownloadIcon          = makeIcon(Download);
export const EmailIcon             = makeIcon(Email);
export const ExpandIcon            = makeIcon(Expand);
export const FileUploadIcon        = makeIcon(FileUpload);
export const FilterIcon            = makeIcon(Filter);
export const ListingPriceIcon      = makeIcon(ListingPrice);
export const MagnifyIcon           = makeIcon(Magnify);
export const MessageIcon           = makeIcon(Message);
export const MessagesIcon          = makeIcon(Messages);
export const MoneyBagIcon          = makeIcon(MoneyBag);
export const NoteIcon              = makeIcon(Note);
export const PencilIcon            = makeIcon(Pencil);
export const PhoneIcon             = makeIcon(Phone);
export const ReportsIcon           = makeIcon(Reports);
export const SentIcon              = makeIcon(Sent);
export const StageIcon             = makeIcon(Stage);
export const TaskIcon              = makeIcon(Task);
export const TrashIcon             = makeIcon(Trash);
export const UserAddIcon           = makeIcon(UserAdd);
export const ViewColumnIcon        = makeIcon(ViewColumn);
export const SettingsIcon          = makeIcon(Settings);
export const TimerIcon             = makeIcon(Timer);
export const TagsIcon              = makeIcon(Tags);
export const AddIcon               = makeIcon(Add);
export const CardsIcon             = makeIcon(Cards);
export const TableIcon             = makeIcon(Table);
export const MenuIcon             = makeIcon(Menu);
