import React, { useCallback } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Menu,
  MenuItem,
  IconButton,
  Grid,
  Link,
  Typography,
  Dialog,
  DialogContent,
  Paper,
  List,
  DialogTitle,
  TextField,
  Popover,
} from "@mui/material";
import {
  OutlineButton,
  PrimaryButton,
  WhiteButton,
} from "../components/buttons";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Close } from "@mui/icons-material";
import ActionIconButton from "../components/ActionIconButton";
import { DragEvent, useEffect, useMemo, useState } from "react";
import TeamLayout from "../layouts/TeamLayout";
import { Task, Note } from "../models";
import { TASK_LIST_FIELDS } from "../api/graphql";
import SimpleBar from "simplebar-react";
import { Stack } from "@mui/system";
import { MoreVert } from "@mui/icons-material";
import { TaskDueDate } from "../components/tasks/TaskDueDate";
import TaskCreateDialog from "../components/tasks/CreateDialog";
import TaskDeleteDialog from "../components/tasks/DeleteDialog";
import moment from "moment";
import { NoteAuthor } from "../components/notes/List";
import InlineEdit from "../components/InlineEdit";
import { AddIcon, TrashIcon } from "../icons";
import { useAlert } from "../context/alert";
import TeamMemberPicker from "../components/TeamMemberPicker";
import { useCreateNote } from "../hooks/useCreateNote";
import { InfoOutlined } from "@mui/icons-material";
import { useGetTeamMembers } from "../hooks/useGetTeamMembers";
import { notifyDesciption } from "../components/notes/CreateNote";
import SearchField from "../components/SearchField";
import { getFullAddress } from "../helpers/address";
import LabeledSelect from "../components/LabeledSelect";
import { useUser } from "../context/user";

const GET_TASKS = gql`
  ${TASK_LIST_FIELDS}
  query TaskBoardPage($teamMemberId: ID, $completedAfter: Date) {
    activeTasks: getTasks(teamMemberId: $teamMemberId, completed: false) {
      ...TaskListFields
    }
    completedTasks: getTasks(
      teamMemberId: $teamMemberId
      completedAfter: $completedAfter
      completed: true
    ) {
      ...TaskListFields
    }
  }
`;

const UPDATE_TASK = gql`
  ${TASK_LIST_FIELDS}
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      ...TaskListFields
    }
  }
`;

const NoteDialog = ({
  open,
  onNoteCreated,
  handleClose,
  task,
}: {
  open: boolean;
  onNoteCreated: any;
  handleClose: () => void;
  task: Task;
}) => {
  const { noteBody, setNoteBody, editNote, deleteNote, createNote } =
    useCreateNote();

  const { selectedAgents, handleAgentSelect, teamMembers, setSelectedAgents } =
    useGetTeamMembers();
  const taskId = task.id;

  const [notes, setNotes] = useState<Note[]>(task.notes);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [deletedNote, setDeletedNote] = useState<Note | null>(null);
  const { showSuccess, showError } = useAlert();
  let editId: string = "";

  const openPopover = Boolean(anchorEl);

  const handleDeleteNote = () => {
    deleteNote({
      variables: {
        id: deletedNote?.id,
      },
    })
      .then((response: any) => {
        if (response.data.deleteNote) {
          setNotes((prevState) => {
            const newNotes = prevState.filter(
              (note) => note.id !== deletedNote?.id
            );
            return [...newNotes];
          });
          showSuccess("Note Successfully Deleted");
        }
        setDeletedNote(null);
        setAnchorEl(null);
      })
      .catch((e: Error) => {
        setAnchorEl(null);
        return showError("There was a problem deleteing the note");
      });
  };

  const handleNoteSubmit = () => {
    if (noteBody.length === 0) {
      return;
    }

    const input: {
      body: string;
      contactId: string | undefined;
      dealId?: string;
      taskId?: string;
      notifications?: { teamMemberId: string }[];
    } = {
      body: noteBody,
      taskId: taskId,
      contactId: undefined, //not using for task notes
      notifications: selectedAgents.map((agent) => ({
        teamMemberId: agent.id,
      })),
    };

    createNote({
      variables: {
        input,
      },
    })
      .then((result: any) => {
        setNotes((prevState) => {
          return [result.data.createNote, ...prevState];
        });
        setNoteBody("");
        setSelectedAgents([]);
        onNoteCreated();
      })
      .catch((e) => showError(e.message));
  };

  const handleEditNote = ({ id, value }: { id: string; value: string }) => {
    editId = id;
    editNote({
      variables: {
        input: {
          id: id && id,
          body: value,
        },
      },
    }).then((res) => {
      return setNotes((prevState) => {
        const editedIndex = prevState.findIndex((note) => note.id === editId);
        const updatedNote = (prevState[editedIndex] = res.data.editNote);
        return Object.assign(prevState, updatedNote);
      });
    });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid display="flex" direction="column">
            <Typography variant="h5">
              {" "}
              {task?.deal?.name ? task.deal.name : null}
            </Typography>
            <Typography variant="h5">{task?.name}</Typography>
          </Grid>

          <ActionIconButton
            icon={Close}
            style={{ margin: "4px -5px" }}
            buttonStyle={{ width: "22px", height: "22px" }}
            onClick={handleClose}
            aria-label="close"
          />
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            background: "#f5f5f5",
          }}
        >
          <List>
            <Box
              padding={1}
              sx={{
                border: "1px solid #ffff",
                borderRadius: "4px",
                background: "white",
                boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.11)",
              }}
            >
              <Box sx={{ display: "flex", paddingTop: 2 }}>
                <TextField
                  fullWidth
                  autoFocus
                  label="Write a note"
                  name="noteBody"
                  variant="outlined"
                  multiline
                  onChange={(e) => setNoteBody(e.currentTarget.value)}
                  value={noteBody}
                />
              </Box>
              <Box marginTop={1}>
                <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
                  Notifications
                </Typography>
                {notifyDesciption(selectedAgents)}
                <TeamMemberPicker
                  limitTags={2}
                  variant="outlined"
                  placeholder="Team Members"
                  label="Select Team Member(s)"
                  value={selectedAgents}
                  onChange={handleAgentSelect}
                  teamMembers={teamMembers}
                />
              </Box>
              <Stack direction="row" spacing={4} justifyContent="center" mt={2}>
                {task.id && (
                  <Typography
                    color="textSecondary"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <InfoOutlined />
                    &nbsp;Notes on tasks are internal and not visible to
                    clients.
                  </Typography>
                )}
                <PrimaryButton
                  onClick={handleNoteSubmit}
                  style={{ width: "100px" }}
                  disabled={noteBody?.length === 0}
                >
                  Add Note
                </PrimaryButton>
              </Stack>
            </Box>
            {notes?.length === 0 && (
              <Paper
                sx={{
                  margin: "5px",
                  padding: "15px",
                  textAlign: "center",
                }}
              >
                <Typography>No notes found on this task</Typography>
              </Paper>
            )}
            <Stack direction="column" mt={3} spacing={2}>
              {notes?.map((note) => {
                return (
                  <Paper key={note.id} sx={{ minHeight: "145px" }}>
                    <Stack direction="row" alignItems="start" p={3}>
                      <Box m={"0 12px 0 0 "}>
                        <NoteAuthor note={note} />
                      </Box>
                      <Stack sx={{ width: "100%" }}>
                        <Typography variant="h5" fontWeight="600">
                          {note?.author?.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          fontWeight="lighter"
                        >
                          {moment(note?.createdAt).format("MMM Do YYYY")}
                        </Typography>
                        <Box my="8px">
                          <InlineEdit
                            id={note.id}
                            initialValue={note.body}
                            saveEdit={handleEditNote}
                            multiline
                          />
                        </Box>
                      </Stack>
                      <Box
                        sx={{
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        id={`delete-note-${note.id}`}
                        onClick={(e: React.MouseEvent) => {
                          setDeletedNote(note);
                          return setAnchorEl(e.currentTarget);
                        }}
                      >
                        <TrashIcon size="16px" />
                      </Box>
                    </Stack>
                  </Paper>
                );
              })}
            </Stack>
          </List>
        </DialogContent>
      </Dialog>
      <Popover
        id={`delete-confirmation`}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Stack
          direction="column"
          spacing={2}
          p={2}
          sx={{ backgroundColor: "#f5f5f5" }}
        >
          <Typography textAlign="center">Delete this Note?</Typography>
          <Stack direction="row" spacing={2}>
            <OutlineButton onClick={() => setAnchorEl(null)}>
              Cancel
            </OutlineButton>
            <PrimaryButton onClick={() => handleDeleteNote()}>
              Delete
            </PrimaryButton>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

function TaskCard({
  task,
  onNoteCreated,
  onTaskUpdated,
}: {
  task: Task;
  onNoteCreated: () => void;
  onTaskUpdated: (task: Task) => void;
}) {
  const [menuAnchor, setMenuAnchor] = useState<any>(null);
  const [showNotes, setShowNotes] = useState<boolean>(false);
  const [hover, setHover] = useState<any>(null);

  let itemName: string | null = null;
  let itemUrl: string | null = null;
  if (task.deal?.id) {
    itemName = task.deal?.name ?? "";
    itemUrl = `/deals/${task.deal.id}`;
  } else if (task.contact?.id) {
    itemName = task.contact.name ?? "";
    itemUrl = `/contacts/${task.contact.id}`;
  }
  const [editing, setEditing] = useState(false);
  const [deletingTask, setDeletingTask] = useState(false);

  const handleTaskEdit = () => {
    setEditing(true);
    setMenuAnchor(null);
  };

  const setTaskDelete = () => {
    setDeletingTask(true);
    setMenuAnchor(null);
  };

  const handleTaskDelete = () => {
    setDeletingTask(false);
    onNoteCreated();
  };

  const handleTaskEditSave = (task: any) => {
    setEditing(false);
    onTaskUpdated(task);
  };
  const handleMenuClick = (e: React.MouseEvent, task: Task) => {
    setMenuAnchor(e.currentTarget);
  };

  const showTaskNotes = () => {
    setMenuAnchor(null);
    setShowNotes(true);
  };

  const hasNotes = task.notes.length > 0;
  const plural = task.notes.length > 1;
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const shouldTruncate = task.name && task?.name.length > 38;
  const truncatedTaskName = shouldTruncate
    ? task?.name && task?.name.substring(0, 38) + "..."
    : task.name;

  return (
    <>
      <Card
        sx={{
          marginBottom: "12px",
          cursor: "pointer",
          borderLeft: task.pastDue ? "4px solid #cf4440" : null,
        }}
      >
        <CardContent
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          <Stack spacing={1}>
            <Stack
              direction="row"
              alignContent="baseline"
              justifyContent="space-between"
              sx={{ height: "30px", borderBottom: "2px solid #f5f5f5" }}
            >
              <Box
                id={task.id}
                onMouseOver={(e: any) => {
                  shouldTruncate && setAnchorEl(e.currentTarget);
                }}
                onMouseOut={() => {
                  shouldTruncate && setAnchorEl(null);
                }}
                sx={{
                  cursor: shouldTruncate ? "text" : "",
                }}
              >
                <Typography>{truncatedTaskName}</Typography>
              </Box>
              <Box>
                <IconButton
                  edge="end"
                  aria-label="Show task menu"
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => handleMenuClick(e, task)}
                  size="large"
                  sx={{
                    paddingTop: "0",
                    display: "table",
                  }}
                >
                  <MoreVert sx={{ display: hover ? "initial" : "none" }} />
                </IconButton>
              </Box>
            </Stack>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <Stack direction="column" spacing={1} alignItems="start">
                <TaskDueDate task={task} />
                <Stack direction="row" spacing={2} alignItems="center">
                  {itemName && itemUrl && (
                    <Link href={itemUrl}>
                      <Typography sx={{ fontSize: "12px" }}>
                        {itemName}
                      </Typography>
                    </Link>
                  )}
                  <Typography>
                    {hasNotes ? (
                      <Link
                        color="secondary"
                        sx={{
                          fontSize: "13px",
                          color: "#747b88",
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "underline",
                            cursor: "pointer",
                          },
                        }}
                        onClick={showTaskNotes}
                      >{`${task.notes.length} ${
                        plural ? "Notes" : "Note"
                      }`}</Link>
                    ) : null}{" "}
                  </Typography>
                </Stack>
              </Stack>
              {task.teamMember?.avatarUrl ? (
                <Avatar
                  sx={{ height: 22, width: 22 }}
                  src={task.teamMember?.avatarUrl}
                />
              ) : (
                <IconButton onClick={() => setEditing(true)}>
                  <PersonAddAltIcon color="action" />
                </IconButton>
              )}
            </Box>
          </Stack>
        </CardContent>
        <Popover
          id="task-name-popover"
          open={openPopover}
          anchorEl={anchorEl}
          disableRestoreFocus
          onClose={() => setAnchorEl(null)}
          sx={{
            pointerEvents: "none",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              borderRadius: 0,
              maxWidth: "600px",
            },
          }}
        >
          <Typography
            p={3}
            sx={{
              backgroundColor: "#656160",
              color: "white",
            }}
          >
            {task.name}
          </Typography>
          <Box
            sx={{
              position: "relative",
              mb: "10px",
              "&::before": {
                backgroundColor: "#656160",
                content: '""',
                display: "block",
                position: "absolute",
                width: 12,
                height: 12,
                bottom: -6,
                transform: "rotate(45deg)",
                left: "calc(50% - 6px)",
              },
            }}
          />
        </Popover>
        <TaskCreateDialog
          open={editing}
          task={task}
          onClose={() => setEditing(false)}
          onTaskCreated={handleTaskEditSave}
          deal={task?.deal}
        />

        <Menu
          id="task-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={() => setMenuAnchor(null)}
        >
          <MenuItem onClick={showTaskNotes}>
            Add {`${hasNotes ? "and View Notes" : "Notes"}`}
          </MenuItem>
          <MenuItem onClick={handleTaskEdit}>Edit</MenuItem>
          <MenuItem onClick={setTaskDelete}>Delete</MenuItem>
        </Menu>
        <NoteDialog
          onNoteCreated={onNoteCreated}
          task={task}
          open={showNotes}
          handleClose={() => setShowNotes(false)}
        />
        <TaskDeleteDialog
          open={deletingTask}
          task={task}
          onClose={() => setDeletingTask(false)}
          onTaskDeleted={handleTaskDelete}
        />
      </Card>
    </>
  );
}

function TaskColumn({
  name,
  tasks,
  onTaskDragStart,
  onTaskDrop,
  onTaskEditSave,
  onNoteCreated,
  onLoadMore,
  loading,
}: {
  name: string;
  tasks: Task[];
  onTaskDragStart: (e: DragEvent, task: Task) => void;
  onTaskDrop: (e: DragEvent) => void;
  onTaskEditSave: (task: Task) => void;
  onNoteCreated: () => void;
  onLoadMore?: () => void;
  loading?: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const formattedCount = (count: number) => {
    return Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(count);
  };
  return (
    <Box
      sx={{
        height: "100%",
        padding: "11px",
        backgroundColor: isHovered ? "#F2F2F2" : undefined,
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h6">{name}</Typography>
        {tasks.length > 0 && (
          <Typography variant="body1">
            &nbsp;({formattedCount(tasks.length)})
          </Typography>
        )}
      </Box>
      <SimpleBar style={{ height: "100%" }}>
        <ul
          style={{
            listStyleType: "none",
            padding: 0,
            height: "100%",
            minHeight: "200px",
          }}
          onDrop={(e) => {
            setIsHovered(false);
            onTaskDrop(e);
          }}
          onDragEnter={() => setIsHovered(true)}
          onDragLeave={() => setIsHovered(false)}
          onDragOver={(e) => {
            e.preventDefault();
            setIsHovered(true);
            return false;
          }}
        >
          {tasks.map((task) => (
            <li
              key={`task${task.id}`}
              draggable
              onDragStart={(e) => onTaskDragStart(e, task)}
            >
              <TaskCard
                key={task.id}
                task={task}
                onTaskUpdated={onTaskEditSave}
                onNoteCreated={onNoteCreated}
              />
            </li>
          ))}
          {onLoadMore && (
            <WhiteButton
              disabled={loading}
              onClick={onLoadMore}
              style={{ display: "block", width: "100%" }}
            >
              {loading ? "Loading..." : "Load 30 More Days"}
            </WhiteButton>
          )}
        </ul>
      </SimpleBar>
    </Box>
  );
}

function Tasks() {
  const { currentUser } = useUser();
  const [getTasks] = useLazyQuery(GET_TASKS);
  const [updateTask] = useMutation(UPDATE_TASK);
  const [taskBeingDragged, setTaskBeingDragged] = useState<Task | null>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const [adding, setAdding] = useState(false);
  const { showSuccess, showError } = useAlert();
  const [searchTerm, setSearchTerm] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [daysAgo, setDaysAgo] = useState(30);
  const [loading, setLoading] = useState(true);

  const loadTasks = useCallback(() => {
    setLoading(true);
    const variables: { teamMemberId?: string; completedAfter?: Date } = {};
    const completedAfter = moment()
      .utc()
      .subtract(daysAgo, "days")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    variables.completedAfter = new Date(completedAfter);
    if (!showAll) {
      variables.teamMemberId = currentUser.teamMember.id;
    }
    getTasks({
      variables,
    }).then((result) => {
      setTasks([...result.data.activeTasks, ...result.data.completedTasks]);
      setLoading(false);
    });
  }, [showAll, getTasks, currentUser, daysAgo]);

  const handleTaskDragStart = (e: DragEvent, task: Task) => {
    setTaskBeingDragged(task);
    e.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        taskId: task.id,
      })
    );
  };

  const handleLoadMore = () => {
    const moreDays = daysAgo < 720 ? daysAgo + 30 : daysAgo;
    setDaysAgo(moreDays);
  };

  const handleTaskDrop = (
    e: DragEvent,
    column: "ready" | "inProgress" | "completed"
  ) => {
    let partial: {
      completed: boolean;
      inProgress: boolean;
    };
    switch (column) {
      case "ready":
        partial = {
          completed: false,
          inProgress: false,
        };
        break;
      case "inProgress":
        partial = {
          completed: false,
          inProgress: true,
        };
        break;
      case "completed":
        partial = {
          completed: true,
          inProgress: false,
        };
        break;
    }

    const input = {
      id: taskBeingDragged?.id,
      ...partial,
    };

    updateTask({
      variables: {
        input,
      },
    })
      .then(() => {
        loadTasks();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleTaskEditSave = (task: Task) => {
    const { id, name, teamMemberId, dueAt } = task;
    const input = {
      id,
      name,
      teamMemberId,
      dueAt,
    };

    updateTask({
      variables: {
        input,
      },
    })
      .then((res) => {
        showSuccess("Task Updated");
        loadTasks();
      })
      .catch((err) => {
        showError("There was a problem updating the task");
        console.error(err);
      });
  };

  useEffect(() => {
    loadTasks();
  }, [loadTasks]);

  useMemo(() => {
    let filtered: any[] = tasks;
    if (searchTerm.length > 0) {
      const lowerCaseTerm = searchTerm.toLowerCase();

      filtered = filtered.filter((task) => {
        // Match address
        let addressMatch = false;
        if (!!task.deal?.address?.street) {
          addressMatch = getFullAddress(task.deal.address)
            .toLowerCase()
            .includes(lowerCaseTerm);
        }
        // Match contact name
        let contactMatch = false;
        let contact = task.contact;
        if (!contact && task.deal?.contacts.length) {
          // Get the contact from the deal
          contact = task.deal.contacts[0];
        }
        contactMatch = contact?.name?.toLowerCase().includes(lowerCaseTerm);

        // Match team member
        let teamMemberMatch = false;
        if (task.teamMember) {
          teamMemberMatch = task.teamMember.name
            .toLowerCase()
            .includes(lowerCaseTerm);
        }

        return (
          task.name.toLowerCase().includes(lowerCaseTerm) ||
          contactMatch ||
          teamMemberMatch ||
          addressMatch
        );
      });
    }
    setFilteredTasks(filtered);
  }, [searchTerm, tasks]);

  const tasksReady = useMemo(() => {
    return filteredTasks.filter((task) => !task.completed && !task.inProgress);
  }, [filteredTasks]);

  const tasksInProgress = useMemo(
    () => filteredTasks.filter((task) => task.inProgress),
    [filteredTasks]
  );

  const tasksCompleted = useMemo(() => {
    return filteredTasks.filter((task) => task.completed);
  }, [filteredTasks]);

  const handleTaskAddSave = (task: any) => {
    setAdding(false);
    showSuccess("Task Created Successfully");
    setTasks([...tasks, task]);
  };

  const handleNoteCreated = () => {
    loadTasks();
  };

  const handleViewChange = (e: any) => {
    setShowAll((prev) => !prev);
  };

  return (
    <TeamLayout>
      <Grid
        container
        direction="row"
        alignItems="flex-end"
        sx={{ marginBottom: "40px" }}
      >
        <Grid item xs={4}>
          <Typography variant="h3">Tasks</Typography>
        </Grid>
        <Grid item xs={8}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <SearchField
              onChange={(term: string) => setSearchTerm(term)}
              white
            />
            {/*
              <ToggleButtonGroup
                exclusive
                sx={{ height: "24px", fontSize: "12px" }}
                value={filter ?? "none"}
                onChange={(event, status) => {
                  setFilter(status);
                }}
              >
                <ToggleButton
                  value="none"
                  sx={{
                    padding: "7px 15px",
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: "#ffffff",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#ffffff",
                        fontWeight: 600,
                      },
                    },
                  }}
                >
                  All
                </ToggleButton>
                <ToggleButton
                  value="overdue"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: "#ffffff",
                      fontWeight: 600,
                    },
                    "& > p": {
                      fontWeight: "700",
                      lineHeight: "1",
                      padding: "0 1px",
                      fontSize: "12px",
                      ...((filter === "none" || filter === null) && {
                        color: "#FF576D",
                        border: "2px solid #FF576D",
                        borderRadius: "5px",
                      }),
                    },
                  }}
                >
                  Overdue
                  {overdueTaskCount.count > 0 && (
                    <>
                      &nbsp;
                      {filter === "none" || filter === null ? (
                        <Typography>{overdueTaskCount.formatted}</Typography>
                      ) : (
                        <Typography>({overdueTaskCount.formatted})</Typography>
                      )}
                    </>
                  )}
                </ToggleButton>
              </ToggleButtonGroup>
              */}

            <PrimaryButton
              height="short"
              width="100%"
              onClick={() => setAdding(true)}
              style={{ marginBottom: 0 }}
            >
              <AddIcon
                style={{
                  width: "13px",
                  height: "13px",
                }}
              />
              New Task
            </PrimaryButton>

            <LabeledSelect
              label="Show:"
              selectProps={{
                onChange: handleViewChange,
                value: showAll ? "true" : "false",
                style: {
                  height: "24px",
                  minWidth: "160px",
                  paddingTop: "2px",
                },
              }}
              options={[
                { value: "true", label: "All" },
                { value: "false", label: "My Tasks" },
              ]}
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={4}>
          <TaskColumn
            name="Unstarted"
            onTaskDragStart={handleTaskDragStart}
            onTaskDrop={(e) => handleTaskDrop(e, "ready")}
            onNoteCreated={handleNoteCreated}
            tasks={tasksReady ?? []}
            onTaskEditSave={handleTaskEditSave}
          />
        </Grid>
        <Grid item sm={4}>
          <TaskColumn
            name="Started"
            onTaskDragStart={handleTaskDragStart}
            onTaskDrop={(e) => handleTaskDrop(e, "inProgress")}
            onNoteCreated={handleNoteCreated}
            tasks={tasksInProgress ?? []}
            onTaskEditSave={handleTaskEditSave}
          />
        </Grid>
        <Grid item sm={4}>
          <TaskColumn
            name="Completed"
            onTaskDragStart={handleTaskDragStart}
            onTaskDrop={(e) => handleTaskDrop(e, "completed")}
            onNoteCreated={handleNoteCreated}
            tasks={tasksCompleted ?? []}
            onTaskEditSave={handleTaskEditSave}
            onLoadMore={handleLoadMore}
            loading={loading}
          />
        </Grid>
      </Grid>
      <TaskCreateDialog
        open={adding}
        onClose={() => setAdding(false)}
        onTaskCreated={handleTaskAddSave}
        assignToCurrentUser={true}
      />
    </TeamLayout>
  );
}

export default Tasks;
