import React, { FunctionComponent, useState } from "react";
import { TextField, Typography, Box } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import { gql, useQuery } from "@apollo/client";
import { Skeleton } from "@mui/material";

type MergeFieldAutoCompleteProps = {
  onSelect: (value: any) => any;
};
const MergeFieldAutocomplete: FunctionComponent<
  MergeFieldAutoCompleteProps
> = ({ onSelect }) => {
  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState("");
  const { data, loading } = useQuery(gql`
    {
      getEmailTemplateMergeFields {
        id
        name
        placeholder
      }
    }
  `);

  const handleChange = (e: React.ChangeEvent<any>, value: any) => {
    setValue(null);
    setInputValue("");
    onSelect(value);
  };

  const renderOption = (props: any, option: any) => {
    return (
      <Box
        component="li"
        {...props}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h6">{option.name}</Typography>
        <Typography color="textSecondary">{option.placeholder}</Typography>
      </Box>
    );
  };

  if (loading) {
    return <Skeleton variant="rectangular" />;
  }
  return (
    <Autocomplete
      onInputChange={(e, v) => setInputValue(v)}
      inputValue={inputValue}
      size="small"
      fullWidth
      renderOption={renderOption}
      value={value}
      onChange={handleChange}
      options={data?.getEmailTemplateMergeFields || []}
      getOptionLabel={(option: any) =>
        option && option.name ? option.name : ""
      }
      renderInput={(params: any) => (
        <TextField
          size="small"
          {...params}
          variant="outlined"
          placeholder="Search for a field&hellip;"
        />
      )}
    ></Autocomplete>
  );
};

export default MergeFieldAutocomplete;
