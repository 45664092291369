import React, { useState } from "react";
import { connect } from "react-redux";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { GET_CONTACTS_ID_ONLY } from "../../api/graphql";
import { Feature } from "../../reducers/features";
import { ContactBatchDeleteButton } from "./BatchDeleteButton";
import { ContactBatchEnrollIntoWorkflowButton } from "./BatchEnrollIntoWorkflowButton";
import BatchManageTagsButton from "./BatchManageTagsButton";
import { SecondaryButton } from "../buttons";
import { DownloadIcon } from "../../icons";
import download from "downloadjs";
import { AddIcon } from "../../icons";
import { TrashIcon } from "../../icons";
import { useApi } from "../../context/api";
import { useAuth } from "../../context/auth";
import {
  ContactQueryFilter,
  ContactSearchParams,
  makeContactQueryParams,
} from "../../pages/contacts/List";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectAll: {
      color: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
    actionsContainer: {
      padding: "0 25px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    hideDivider: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);
interface ContactTableActionsInterface {
  selectedContacts: {
    [key: string]: {
      checked: boolean;
      pageNumber: number;
    };
  };
  setSelectedContacts(selectedContacts: {
    [key: string]: {
      checked: boolean;
      pageNumber: number;
    };
  }): any;
  search?: ContactSearchParams;
  searchParams?: ContactSearchParams;
  filters?: [ContactQueryFilter];
  totalContacts: number;
  setAllContactsSelected(boolean: boolean): any;
  allContactsSelected: boolean;
  onAction?: () => void;
  openActionsDialog: { open: boolean; action: string };
  setOpenActionsDialog: (value: { open: boolean; action: string }) => void;
  features: { [key: string]: Feature };
}

const ContactTableActions = ({
  selectedContacts,
  setSelectedContacts,
  totalContacts,
  setAllContactsSelected,
  allContactsSelected,
  search,
  searchParams,
  filters,
  onAction,
  openActionsDialog,
  setOpenActionsDialog,
  features,
}: ContactTableActionsInterface) => {
  const classes = useStyles();
  const { Workflows } = features;
  const { apolloClient } = useApi();
  const { authToken } = useAuth();

  const defaultDialogs = {
    delete: false,
    workflowEnrollment: false,
  };
  const [dialogs, setDialogs] = useState(defaultDialogs);

  const handleSelectAll = (areSelected: boolean) => {
    const params = makeContactQueryParams(search, searchParams, filters);
    params.pageSize = totalContacts;

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: GET_CONTACTS_ID_ONLY,
          variables: { params },
        })
        .then((response) => {
          if (areSelected) {
            const allSelected = response.data.getContacts.contacts.reduce(
              (acc: any, c: any) => {
                acc[c.id] = { checked: true };
                return acc;
              },
              {}
            );
            setSelectedContacts(allSelected);
            setAllContactsSelected(true);
          } else {
            setSelectedContacts({});
            setAllContactsSelected(false);
          }
        })
        .catch((error) => reject(error));
    });
  };

  const handleAction = () => {
    setSelectedContacts({});
    setAllContactsSelected(false);
    if (onAction) {
      onAction();
    }
  };

  const handleExportCsv = () => {
    fetch(`${process.env.REACT_APP_API_URL}/exports/contacts.csv`, {
      method: "POST",
      body: JSON.stringify({ contacts: Object.keys(selectedContacts) }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        download(blob, "contacts.csv", "text/csv");
      });
  };

  return (
    <Box
      className={classes.actionsContainer}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {allContactsSelected ? (
        <span style={{ marginRight: "6px" }}>
          All contacts selected ({Object.keys(selectedContacts).length})
        </span>
      ) : (
        <span style={{ marginRight: "6px" }}>
          {Object.keys(selectedContacts).length} contact
          {Object.keys(selectedContacts).length > 1 && "s"} selected
        </span>
      )}
      <span className={classes.hideDivider} style={{ marginRight: "6px" }}>
        |
      </span>
      {allContactsSelected ? (
        <span
          onClick={() => handleSelectAll(false)}
          className={classes.selectAll}
          style={{ marginRight: "6px" }}
        >
          Unselect All Contacts
        </span>
      ) : (
        <span
          onClick={() => handleSelectAll(true)}
          className={classes.selectAll}
          style={{ marginRight: "6px" }}
        >
          Select All Contacts
        </span>
      )}

      <SecondaryButton
        height="short"
        onClick={() =>
          setDialogs(Object.assign({}, defaultDialogs, { delete: true }))
        }
        disabled={dialogs.delete}
      >
        <TrashIcon size="12px" style={{ marginRight: "6px" }} />
        <span>Delete</span>
      </SecondaryButton>
      <ContactBatchDeleteButton
        onClose={() => setDialogs(defaultDialogs)}
        open={dialogs.delete}
        selectedContacts={selectedContacts}
        onDeleted={handleAction}
      />

      <BatchManageTagsButton
        selectedContacts={selectedContacts}
        onSubmitted={handleAction}
      />

      {Workflows && (
        <>
          <SecondaryButton
            height="short"
            onClick={() =>
              setDialogs(
                Object.assign({}, defaultDialogs, { workflowEnrollment: true })
              )
            }
            disabled={dialogs.workflowEnrollment}
          >
            <AddIcon size="12px" style={{ marginRight: "6px" }} />
            <span>Enroll in Workflow</span>
          </SecondaryButton>
          <ContactBatchEnrollIntoWorkflowButton
            onClose={() => setDialogs(defaultDialogs)}
            open={dialogs.workflowEnrollment}
            selectedContacts={selectedContacts}
            onEnrolled={handleAction}
          ></ContactBatchEnrollIntoWorkflowButton>
        </>
      )}
      <SecondaryButton height="short" onClick={handleExportCsv}>
        <DownloadIcon size="12px" style={{ marginRight: "6px" }} />
        <span>Export to CSV</span>
      </SecondaryButton>
    </Box>
  );
};

const mapStateToProps = ({ features }: any) => {
  return { features };
};

export default connect(mapStateToProps)(ContactTableActions);
