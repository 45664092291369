import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AppNotification = (props: any) => {
  const [open, setOpen] = useState(true);
  const notification = props.notification;

  // when the notification object changes, it means we have a new message
  useEffect(() => {
    setOpen(true);
  }, [notification]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={notification.message === "" || !open ? false : true}
      autoHideDuration={3000}
      onClose={(e) => setOpen(false)}
      message={notification.message}
    >
      <Alert severity={notification.type}>{notification.message}</Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state: any) => {
  return {
    notification: state.notification,
  };
};

export default connect(mapStateToProps)(AppNotification);
