import { gql } from "@apollo/client";
import { ApiClient } from "../context/api";
import { STAGES } from "../reducers/stages";

export const FRAGEMNT_STAGE_SENDABLE_FIELDS = gql`
  fragment stageSendableFields on StageSendable {
    toDealContacts
    toRoleId
    fromRoleId
    hasSendDate
    sendDateTime
    sendDateDays
    sendDatePreposition
    sendDateTargetColumnName
    sendDateTargetCustomFieldId
    sendAfterStageChange
    to {
      dealContacts
      roleId
    }
    ... on StageEmail {
      cc {
        dealContacts
        roleId
      }
      bcc {
        dealContacts
        roleId
      }
    }
  }
`;

const CREATE_STAGE_EMAIL = gql`
  mutation CreateStageEmail($input: CreateStageEmailInput!) {
    createStageEmail(input: $input) {
      id
      emailTemplateId
      sort
      ...stageSendableFields
    }
  }
  ${FRAGEMNT_STAGE_SENDABLE_FIELDS}
`;

const UPDATE_STAGE_EMAIL = gql`
  mutation UpdateStageEmail($input: UpdateStageEmailInput!) {
    updateStageEmail(input: $input) {
      id
      emailTemplateId
      sort
      ...stageSendableFields
    }
  }
  ${FRAGEMNT_STAGE_SENDABLE_FIELDS}
`;

const DELETE_STAGE_EMAIL = gql`
  mutation DeleteStageEmail($id: ID!) {
    deleteStageEmail(id: $id)
  }
`;

type StageEmailRecipientInput = {
  roleId?: string;
  dealContacts: boolean;
  email?: string;
};

export type StageSendableInput = {
  toDealContacts?: boolean;
  toRoleId?: string;
  fromRoleId?: string;
  hasSendDate?: boolean;
  sendDateTime?: string;
  sendDateDays?: number;
  sendDatePreposition?: string;
  sendDateTargetColumnName?: string;
  sendDateTargetCustomFieldId?: string;
  sendScheduledEmailsAfterStageChange?: boolean;
  to?: Array<StageEmailRecipientInput>;
  cc?: Array<StageEmailRecipientInput>;
  bcc?: Array<StageEmailRecipientInput>;
};

export type CreateStageEmailInput = {
  stageId: string;
  emailTemplateId: string;
} & StageSendableInput;

export type UpdateStageEmailInput = {
  id: string;
  emailTemplateId?: string;
  sendScheduledEmailsAfterStageChange?: boolean;
  sort?: number;
} & StageSendableInput;

export const createStageEmail =
  (stageEmail: CreateStageEmailInput, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: CREATE_STAGE_EMAIL,
      variables: { input: { ...stageEmail } },
    });

    dispatch({
      type: STAGES.ADD_EMAIL,
      stageEmail: response.data.createStageEmail,
      stageId: stageEmail.stageId,
    });
  };

export const deleteStageEmail =
  (id: string, stageId: string, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    await apolloClient.mutate({
      mutation: DELETE_STAGE_EMAIL,
      variables: { id },
    });

    dispatch({ type: STAGES.REMOVE_EMAIL, id, stageId });
  };

export const updateStageEmail =
  (
    stageEmail: UpdateStageEmailInput,
    stageId: string,
    apolloClient: ApiClient
  ) =>
  async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_STAGE_EMAIL,
      variables: {
        input: stageEmail,
      },
    });

    const updatedStageEmail = response.data.updateStageEmail;
    dispatch({
      type: STAGES.UPDATE_EMAIL,
      stageEmail: updatedStageEmail,
      stageId,
    });
  };
