import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Theme, Grid, Box, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  useNavigate,
  Link as RouterLink,
  useSearchParams,
} from "react-router-dom";
import StageChangeDialog from "../../components/deals/StageChangeDialog";
import { useMutation, gql, useApolloClient } from "@apollo/client";
import { NEEDED_FOR_STAGE_ACTIONS, UPDATE_DEAL } from "../../api/graphql";
import TeamLayout from "../../layouts/TeamLayout";
import { drawerWidth } from "../../components/AppDrawer";
import StageColumn from "../../components/deals/StageColumn";
import { PrimaryButton } from "../../components/buttons";
import { Add as AddIcon, Menu, Apps } from "@mui/icons-material";
import { useAlert } from "../../context/alert";
import { connect } from "react-redux";
import { DealType } from "../../models";
import TitleSelect from "../../components/TitleSelect";
import { FRAGMENT_DEAL_FIELDS } from "../../api/graphql";
import DealCreateDialog from "../../components/deals/CreateDialog";
import SearchField from "../../components/SearchField";
import NewDealTable from "../../components/deals/NewDealTable";
import LabeledSelect from "../../components/LabeledSelect";
import { useUser } from "../../context/user";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Feature } from "../../reducers/features";

export interface DealSearch {
  term?: string;
  showAll?: boolean;
  includeArchived?: boolean;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  orderDirection?: "asc" | "desc";
  dealTypeId?: string;
  closingBefore?: string;
  closingAfter?: string;
  state?: string | null;
}

export interface DealsResponse {
  deals: any[];
  page: number;
  pageSize: number;
  totalCount: number;
}

const GET_DEALS = gql`
  query GetDeals(
    $dealTypeId: ID
    $pageSize: Int
    $page: Int
    $term: String
    $showAll: Boolean
    $includeArchived: Boolean
    $closingBefore: Date
    $closingAfter: Date
    $state: DealState
    $orderBy: String
    $orderDirection: OrderDirection
  ) {
    getDeals(
      dealTypeId: $dealTypeId
      pageSize: $pageSize
      page: $page
      showAll: $showAll
      includeArchived: $includeArchived
      term: $term
      closingBefore: $closingBefore
      closingAfter: $closingAfter
      state: $state
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      totalCount
      page
      pageSize
      deals {
        id
        name
        stageId
        dealState
        stage {
          name
        }
        dealType {
          id
          name
        }
        owner {
          id
          name
          avatarUrl
        }
        address {
          street
          street2
          city
          state
          zip
        }
        contacts {
          id
          name
          combinedName
          avatarUrl
        }
        dealTeamMembers {
          role {
            id
            name
          }
          teamMember {
            id
            name
            avatarUrl
          }
        }
        listingDate
        listPrice
        currentListPrice
        salePrice
        closeDate
        value
        createdAt
        updatedAt
        archived
        progress {
          percent
          tasks {
            total
            completed
            percent
          }
          documents {
            total
            completed
            percent
          }
        }
      }
    }
  }
`;

const pageHeaderHeight = 94;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageLayout: {
      overflowX: "hidden",
      display: "flex",
      flexDirection: "row",
      marginBottom: "0",
      height: "100%",
      width: `calc(100vw - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    pageHeader: {
      padding: "40px 40px 30px 40px",
      height: `${pageHeaderHeight}px`,
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(1047)]: {
        height: "auto",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    pageHeaderRight: {
      display: "flex",
      [theme.breakpoints.down(1047)]: {
        height: "24px",
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.between(0, 765)]: {
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    viewToggle: {
      display: "flex",
      alignItems: "center",
      paddingRight: "25px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    searchField: {
      maxWidth: "250px",
      paddingRight: "25px",
      "&input": {
        backgroundColor: "white",
      },
      [theme.breakpoints.down(1025)]: {
        maxWidth: "200px",
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "0",
        marginBottom: "16px",
      },
    },
    dealBoardContainer: {
      display: "flex",
      width: "100%",
      overflowX: "scroll",
      height: `calc(100% - ${pageHeaderHeight}px)`,
    },
    dealTableContainer: {
      padding: "0 40px",
      marginBottom: "30px",
      height: "100%",
    },
    dealBoard: {
      display: "flex",
      padding: `0 ${theme.spacing(2)}`,
      height: "100%",
    },
    hideText: {
      marginLeft: "5px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      flexGrow: 1,
    },
  })
);

interface DealStageChange {
  deal: any;
  fromStage: any;
  toStage: any;
  neededFields: any[];
  neededRoles: any[];
}

interface DealListSettings {
  showAll: boolean;
  view: "kanban" | "table";
  dealTypeId?: string | null;
}

const DealsPage = ({
  dealTypes,
  features,
}: {
  dealTypes: DealType[];
  features: Feature;
}) => {
  const DEFAULT_DEAL_LIST_SETTINGS_KEY = "dealListSettings";

  const max351 = useMediaQuery("(max-width:351px)");
  const max425 = useMediaQuery("(max-width:425px)");

  const { currentUser } = useUser();
  const apollo = useApolloClient();
  const navigate = useNavigate();
  const classes = useStyles();

  let defaultDealListSettings: DealListSettings = {
    view: "kanban",
    showAll: true,
    dealTypeId: dealTypes[0]?.id ?? null,
  };
  const dealListSettingsStr = window.localStorage.getItem(
    DEFAULT_DEAL_LIST_SETTINGS_KEY
  );
  if (dealListSettingsStr) {
    const localDealListSettings = JSON.parse(
      dealListSettingsStr
    ) as Partial<DealListSettings>;
    if (
      localDealListSettings.dealTypeId &&
      localDealListSettings.dealTypeId !== "all"
    ) {
      // Make sure the deal type is still valid
      const dealType = dealTypes.find(
        (dt) => dt.id === localDealListSettings.dealTypeId
      );
      if (!dealType) {
        localDealListSettings.dealTypeId = dealTypes[0]?.id ?? null;
      }
    }
    defaultDealListSettings = {
      ...defaultDealListSettings,
      ...localDealListSettings,
    };
  }

  const [searchParams, setParams] = useSearchParams({
    page: "0",
    pageSize: "20",
    term: "",
    showAll: defaultDealListSettings.showAll ? "true" : "false",
    includeArchived: "false",
    view: defaultDealListSettings.view,
    dealTypeId: defaultDealListSettings.dealTypeId ?? "",
  });

  const { showError, showSuccess } = useAlert();

  const [addingDeal, setAddingDeal] = useState(false);
  const [dealStageChange, setDealStageChange] =
    useState<DealStageChange | null>(null);
  const [dealStageChangeDialogOpen, setDealStageChangeDialogOpen] =
    useState(false);
  const [dealsLoading, setDealsLoading] = useState(false);
  const [dealBeingDragged, setDealbeingDragged] = useState<null | any>(null);

  const [dealResponse, setDealResponse] = useState<DealsResponse>({
    deals: [],
    totalCount: 0,
    page: 0,
    pageSize: 30,
  });

  const [searchTerm, setSearchTerm] = useState("");

  const GET_DEAL_DATA = gql`
    query GetDeal($id: ID!) {
      deal(id: $id) {
        id
        ...DealFields
        customFields {
          id
          customFieldId
          type
          label
          valueText
          settings {
            format
            includeTime
            options
          }
        }
        dealTeamMembers {
          id
          role {
            id
          }
          teamMember {
            id
          }
        }
      }
    }
    ${FRAGMENT_DEAL_FIELDS}
  `;

  const selectedDealType = useMemo(() => {
    // Assume the dealTypeIs set in searchParams
    let dealTypeId = searchParams.get("dealTypeId");
    if (dealTypeId === "all") {
      return null;
    }

    if (!dealTypeId && dealTypes.length) {
      // We didn't find a dealType in the URL or local storage, so we'll use the first one
      return dealTypes[0];
    }

    let dealType: DealType | undefined;
    if (dealTypeId) {
      dealType = dealTypes.find((dealType) => dealType.id === dealTypeId);
    }
    return dealType ?? null;
  }, [dealTypes, searchParams]);

  const dealSearchParams = useMemo(() => {
    const dealSearch: DealSearch = {
      page: parseInt(searchParams.get("page") || "0", 10),
      pageSize: parseInt(searchParams.get("pageSize") || "20", 10),
      dealTypeId: selectedDealType?.id ?? undefined,
    };

    const state = searchParams.get("state");
    if (state) {
      dealSearch.state = state;
    }

    if (searchParams.get("showAll") === "true") {
      dealSearch.showAll = true;
    }
    if (searchParams.get("includeArchived") === "true") {
      dealSearch.includeArchived = true;
    }
    const term = searchParams.get("term");
    if (term && term.length > 0) {
      dealSearch.term = term;
    }
    const orderBy = searchParams.get("orderBy");
    if (orderBy) {
      dealSearch.orderBy = orderBy;
    }
    const orderDirection = searchParams.get("orderDirection");
    if (orderDirection === "asc" || orderDirection === "desc") {
      dealSearch.orderDirection = orderDirection;
    }

    const closingBefore = searchParams.get("closingBefore");
    if (closingBefore) {
      dealSearch.closingBefore = closingBefore;
    }
    const closingAfter = searchParams.get("closingAfter");
    if (closingAfter) {
      dealSearch.closingAfter = closingAfter;
    }
    return dealSearch;
  }, [searchParams, selectedDealType?.id]);

  const showAllDealTypes = useMemo(() => {
    let dealTypeId = searchParams.get("dealTypeId");
    return dealTypeId === "all";
  }, [searchParams]);

  const stages = useMemo(() => {
    if (!selectedDealType || showAllDealTypes) {
      return [];
    }
    return selectedDealType.stages;
  }, [selectedDealType, showAllDealTypes]);

  const view = useMemo((): "kanban" | "table" => {
    const view = searchParams.get("view");
    if (view === "table" || view === "kanban") {
      return view;
    } else {
      return "kanban";
    }
  }, [searchParams]);

  const dealsByStageId = useMemo(() => {
    const dealsByStageId: { [index: string]: any } = {};
    stages.forEach((stage) => {
      dealsByStageId[stage.id] = [];
    });
    dealResponse.deals.forEach((deal: any) => {
      dealsByStageId[deal.stageId] ??= [];
      dealsByStageId[deal.stageId].push(deal);
    });
    console.log(dealsByStageId);
    return dealsByStageId;
  }, [dealResponse, stages]);

  const getDeals = useCallback(async (): Promise<DealsResponse> => {
    if (view === "kanban" && !selectedDealType) {
      const defaultResponse: DealsResponse = {
        deals: [],
        page: 0,
        pageSize: 20,
        totalCount: 0,
      };
      return defaultResponse;
    }

    const loadPage = (dealSearch: DealSearch): Promise<DealsResponse> => {
      return apollo
        .query({
          query: GET_DEALS,
          variables: dealSearch,
        })
        .then((res) => {
          return res.data.getDeals;
        });
    };

    setDealsLoading(true);
    if (view === "kanban") {
      const deals: any[] = [];
      let page = 0;
      let totalCount = 0;
      do {
        const { totalCount: tc, deals: pagedDeals } = await loadPage({
          ...dealSearchParams,
          page,
          pageSize: 50,
        });
        totalCount = tc;
        deals.push(...pagedDeals);
        page++;
      } while (deals.length < totalCount);
      setDealsLoading(false);
      return {
        deals,
        totalCount,
        pageSize: totalCount,
        page: 0,
      };
    } else {
      return loadPage(dealSearchParams).then((res) => {
        setDealsLoading(false);
        return res;
      });
    }
  }, [apollo, dealSearchParams, view, selectedDealType]);

  useEffect(() => {
    let dealTypeId = searchParams.get("dealTypeId");
    if (!dealTypeId) {
      dealTypeId = null;
    }

    const dealListSettings: DealListSettings = {
      view,
      dealTypeId,
      showAll: searchParams.get("showAll") === "true",
    };

    window.localStorage.setItem(
      DEFAULT_DEAL_LIST_SETTINGS_KEY,
      JSON.stringify(dealListSettings)
    );
  }, [searchParams, view]);

  useEffect(() => {});
  useEffect(() => {
    getDeals().then((res) => setDealResponse(res));
  }, [getDeals]);

  const [updateDeal] = useMutation(UPDATE_DEAL);

  const handleTypeChange = (dealTypeId: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("dealTypeId", dealTypeId);
    setParams(newSearchParams);
  };

  const handleTermChange = (term: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("term", term);
    setParams(newSearchParams);
  };

  const handleTableParamsChange = (newParams: { [key: string]: string }) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    Object.keys(newParams).forEach((key) => {
      newSearchParams.set(key, newParams[key]);
    });
    setParams(newSearchParams);
  };

  const handleShowAllChange = (e: any) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("showAll", e.target.value);
    setParams(newSearchParams);
  };

  const handleDealStageChangeCancel = () => {
    setDealStageChange(null);
    setDealStageChangeDialogOpen(false);
    setDealbeingDragged(null);
  };

  const handleDealStageChangeSubmit = (stageFormData: any) => {
    if (!dealStageChange) {
      return Promise.resolve();
    }

    // Build a deal object that can be used as the mutation input
    const input = {
      id: dealStageChange.deal.id,
      ...stageFormData,
      stageId: dealStageChange.toStage.id,
    };

    return updateDeal({
      variables: {
        input,
      },
    })
      .then(() => getDeals())
      .then((res) => {
        setDealResponse(res);
        setDealStageChangeDialogOpen(false);
        setDealbeingDragged(null);
        setDealStageChange(null);
      })
      .catch(() => {
        showError("An error occurred when updating the deal");
      });
  };

  const removeDeal = (deal: any) => {
    setDealResponse({
      ...dealResponse,
      deals: dealResponse.deals.filter((d: any) => d.id !== deal.id),
    });
  };

  const onDealMoved = (
    dealId: string,
    fromStageId: string,
    toStageId: string
  ) => {
    if (fromStageId === toStageId) {
      setDealbeingDragged(null);
      return;
    }
    const deal = dealResponse.deals.find((deal: any) => dealId === deal.id);
    const fromStage: any = stages.find(
      (stage: any) => stage.id === fromStageId
    );
    const toStage: any = stages.find((stage: any) => stage.id === toStageId);

    const checkForStageActions = apollo.query({
      query: NEEDED_FOR_STAGE_ACTIONS,
      variables: {
        stageId: toStageId,
        dealId: deal.id,
      },
    });

    checkForStageActions.then(async (res) => {
      const { fields: neededFields, roles: neededRoles } =
        res.data.getStage.neededForActions;
      const showModal =
        neededFields.length > 0 ||
        neededRoles.length > 0 ||
        toStage.dealState === "closed";

      // Pull in all the deal's data to ensure we can render the deal form with
      // the correct values
      if (showModal) {
        const dealData = await apollo
          .query({
            query: GET_DEAL_DATA,
            variables: {
              id: deal.id,
            },
          })
          .then((res) => res.data.deal);

        setDealStageChange({
          deal: { ...deal, ...dealData },
          fromStage,
          toStage,
          neededFields,
          neededRoles,
        });
        setDealStageChangeDialogOpen(true);
      } else {
        updateDeal({
          variables: {
            input: {
              id: deal.id,
              stageId: toStage.id,
            },
          },
        })
          .then(() => getDeals())
          .then((res) => {
            setDealResponse(res);
            setDealStageChangeDialogOpen(false);
            setDealStageChange(null);
          })
          .catch(() => {
            showError("An error occurred when updating the deal");
          })
          .finally(() => {
            setDealbeingDragged(null);
          });
      }
    });
  };

  const handleDealCreated = (deal: any) => {
    setAddingDeal(false);
    showSuccess(`Deal "${deal.name}" was added`);
    getDeals().then((res) => setDealResponse(res));
    navigate(`/deals/${deal.id}/details`);
  };

  const handleDealDrop = (event: any, stage: any) => {
    let data = JSON.parse(event.dataTransfer.getData("text/plain"));
    onDealMoved(data.dealId, data.fromStageId, stage.id);
  };

  const handleDealDragStart = (event: any, deal: any) => {
    setDealbeingDragged(deal);
    event.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        dealId: deal.id,
        fromStageId: deal.stageId,
      })
    );
  };

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment) {
      searchParams.set("view", newAlignment);
      const newSearchParams = new URLSearchParams(searchParams.toString());
      newSearchParams.set("view", newAlignment);
      setParams(newSearchParams);
    }
  };

  const StyledToggleButton = withStyles((theme: Theme) => ({
    root: {
      padding: "7px 15px",
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        fontWeight: 600,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "#ffffff",
          fontWeight: 600,
        },
      },
    },
    label: {
      textTransform: "none",
    },
  }))(ToggleButton);

  const titleSelectionOption = () => {
    let arr: any[] = [];
    arr = dealTypes.map((dealType: DealType) => {
      return { label: dealType.name, value: dealType.id };
    });
    if (view === "table") {
      arr.unshift({ label: "All Deals", value: "all" });
    }
    return arr;
  };

  return (
    <TeamLayout withoutPadding={true}>
      <Grid container className={classes.pageLayout}>
        <Grid item xs={12} className={classes.pageHeader}>
          {(selectedDealType || showAllDealTypes) && (
            <React.Fragment>
              <TitleSelect
                value={showAllDealTypes ? "all" : selectedDealType?.id}
                options={titleSelectionOption()}
                onChange={handleTypeChange}
              />
              <div className={classes.pageHeaderRight}>
                <SearchField
                  white
                  className={classes.searchField}
                  inputProps={{
                    value: searchTerm,
                  }}
                  setSearchTerm={setSearchTerm}
                  onChange={handleTermChange}
                />
                <div className={classes.viewToggle}>
                  <ToggleButtonGroup
                    exclusive
                    value={view}
                    onChange={handleViewChange}
                    style={{ height: "100%" }}
                  >
                    <StyledToggleButton
                      size="small"
                      value="kanban"
                      aria-label="card view"
                    >
                      <Apps />
                    </StyledToggleButton>

                    <StyledToggleButton
                      size="small"
                      value="table"
                      aria-label="table view"
                    >
                      <Menu />
                    </StyledToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div>
                  <PrimaryButton
                    height="short"
                    onClick={() => setAddingDeal(true)}
                    style={{ marginBottom: "12px" }}
                  >
                    <AddIcon
                      style={{
                        width: "13px",
                        height: "13px",
                      }}
                    />
                    New Deal
                  </PrimaryButton>

                  {currentUser?.permissions?.deals === "full" && (
                    <LabeledSelect
                      label="Show:"
                      selectProps={{
                        onChange: handleShowAllChange,
                        value: searchParams.get("showAll"),
                        style: {
                          height: "24px",
                          minWidth: "160px",
                          marginLeft: max425
                            ? max351
                              ? "0px"
                              : "12px"
                            : "24px",
                          paddingTop: "2px",
                        },
                      }}
                      options={[
                        { value: "true", label: "All" },
                        { value: "false", label: "My Deals" },
                      ]}
                    />
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </Grid>

        {view === "kanban" ? (
          <Grid item xs={12} className={classes.dealBoardContainer}>
            {stages.length > 0 && (
              <div className={classes.dealBoard}>
                {stages.map((stage: any) => (
                  <StageColumn
                    showDualProgress={features["Document Types"]}
                    key={stage.id}
                    stage={stage}
                    deals={dealsByStageId[stage.id] ?? []}
                    selectedDeal={dealBeingDragged}
                    onDealDelete={removeDeal}
                    onDealArchive={removeDeal}
                    onDealDragStart={handleDealDragStart}
                    onDealDrop={handleDealDrop}
                    dealsLoading={dealsLoading}
                    allowSort={selectedDealType?.name === "Selling"}
                  />
                ))}
              </div>
            )}
            {stages.length === 0 && (
              <Box
                mb={10}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Typography style={{ fontSize: "18px" }}>
                  You'll need to{" "}
                  <Link
                    component={RouterLink}
                    to={"/settings/stages#" + selectedDealType?.id}
                  >
                    Create Stages
                  </Link>{" "}
                  to use this deal board
                </Typography>
              </Box>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.dealTableContainer}>
            <NewDealTable
              dealsResponse={dealResponse}
              onDealDelete={removeDeal}
              onDealArchive={removeDeal}
              dealsLoading={dealsLoading}
              showAllDeals={showAllDealTypes}
              searchParams={dealSearchParams}
              setParams={handleTableParamsChange}
            />
          </Grid>
        )}
      </Grid>
      <StageChangeDialog
        open={dealStageChangeDialogOpen}
        currentStage={dealStageChange?.fromStage}
        newStage={dealStageChange?.toStage}
        deal={dealStageChange?.deal}
        onClose={handleDealStageChangeCancel}
        onSubmit={handleDealStageChangeSubmit}
      />
      {addingDeal && (
        <DealCreateDialog
          dealTypeId={selectedDealType?.id}
          open={addingDeal}
          onDealCreated={handleDealCreated}
          onClose={() => setAddingDeal(false)}
        />
      )}
    </TeamLayout>
  );
};

const mapStateToProps = ({ dealTypes, features }: any) => {
  return { dealTypes, features };
};

export default connect(mapStateToProps)(DealsPage);
