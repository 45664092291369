import { gql } from "@apollo/client";
import { ApiClient } from "../context/api";
import { VENDOR_TAGS } from "../reducers/vendorTags";

const GET_VENDOR_TAGS = gql`
  query GetTags {
    tags(type: "vendor") {
      id
      name
    }
  }
`;

export const refreshVendorTags =
  (apolloClient: ApiClient) => async (dispatch: any) => {
    const response = await apolloClient.query({ query: GET_VENDOR_TAGS });

    dispatch({ type: VENDOR_TAGS.SET, vendorTags: response.data.tags });
  };
