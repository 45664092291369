import "./index.css";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import App from "./App";
import "./fonts/RecoletaSemiBold/font.woff";
import { createRoot } from "react-dom/client";
if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

declare global {
  interface Window {
    _hsq: undefined | any[];
  }
}

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
