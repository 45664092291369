import React, { useEffect, useState } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FunctionComponent } from "react";
import { GET_FIELDS, GET_DEAL_DOCUMENTS } from "../api/graphql";
import { Deal, DealType } from "../models";
import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Tooltip,
  Paper,
} from "@mui/material";

import { PencilIcon, TrashIcon as DeleteIcon } from "../icons";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ReactSortable } from "react-sortablejs";
import { DragIndicator } from "@mui/icons-material";
import { DocumentIcon } from "../icons";
import clsx from "clsx";
import { theme } from "../App";
import { useUser } from "../context/user";

type DocumentTypes = {
  dealTypeId: string;
  name: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    dragHandle: {
      color: "#6b7280",
      marginRight: "11px",
      cursor: "grab",
    },
  })
);
type TimelineSettingsProps = {
  deal?: Deal;
  handleEditClick?: Function;
  handleDeleteClick?: Function;
  dealType?: DealType;
  isDocumentType?: boolean;
  documentTypes?: DocumentTypes[];
  checklistItems?: any;
  children?: React.ReactNode;
};
const TimelineSettings: FunctionComponent<TimelineSettingsProps> = ({
  deal,
  dealType,
  isDocumentType = false,
  children,
  checklistItems,
  documentTypes,
  handleEditClick,
  handleDeleteClick,
}) => {
  const { hasPermission } = useUser();
  const classes = useStyles();
  const [availableFields, setAvailableFields] = useState<any[]>();
  const [checklistFields, setChecklistFields] = useState<any[]>();

  const [usedFields, setUsedFields] = useState<any[]>();

  const { data: fieldData } = useQuery(GET_FIELDS, {
    variables: { tableName: "deal" },
  });
  const [getTimelineSettings, { data }] = useLazyQuery(
    gql`
      query GetTimelineSettings($dealId: String, $dealTypeId: String) {
        getTimelineSettings(dealId: $dealId, dealTypeId: $dealTypeId) {
          id
          columnName
          customFieldId
          sort
        }
      }
    `,
    {
      variables: {
        dealId: deal?.id ?? undefined,
        dealTypeId: dealType?.id ?? undefined,
      },
    }
  );

  const [saveTimelineSettings] = useMutation(gql`
    mutation SaveTimelineSettings($input: SaveTimelineSettingsInput!) {
      saveTimelineSettings(input: $input) {
        id
        columnName
        customFieldId
        sort
      }
    }
  `);

  const [updateDocumentChecklist] = useMutation(
    gql`
      mutation UpdateDocumentChecklist($input: UpdateDocumentChecklistInput!) {
        updateDocumentChecklist(input: $input) {
          items {
            documentType {
              id
            }
          }
        }
      }
    `,
    {
      refetchQueries: [GET_DEAL_DOCUMENTS, "GetDealDocumentTypes"],
    }
  );
  useEffect(() => {
    if ((!fieldData || !data) && !isDocumentType) {
      return;
    }
    if (isDocumentType) {
      //filters out document types already in checklist
      let fields;
      const set = new Set(
        checklistItems?.map((item: any) => item.documentType.id)
      );
      fields =
        documentTypes?.filter((field: any) => {
          return !set.has(field.id);
        }) || [];
      setAvailableFields(fields);
      const arr: any[] = [];
      checklistItems.map(
        (field: { documentType: { name: string; id: string } }) =>
          arr.push(field.documentType)
      );
      setChecklistFields(arr);
    } else {
      // All datetime fields.
      const allFields = [
        ...fieldData.getCustomFields,
        ...fieldData.getSystemFields,
      ].filter((field) => field.type === "datetime");
      const fieldMap: { [key: string]: any } = {};
      for (const field of allFields) {
        fieldMap[field.id] = field;
      }

      const usedMap: { [key: string]: boolean } = {};
      const usedFields: any[] = [];
      for (const timelineSetting of data.getTimelineSettings) {
        const key = timelineSetting.columnName ?? timelineSetting.customFieldId;
        // We're building our array of used fields in this loop so we get them
        // in the proper sort order!
        const field = fieldMap[key];
        if (field) {
          usedFields.push(field);
        }
        usedMap[key] = true;
      }
      setAvailableFields(allFields.filter((field) => !usedMap[field.id]));
      setUsedFields(usedFields);
    }
  }, [data, isDocumentType, documentTypes, fieldData, checklistItems]);

  useEffect(() => {
    if (deal || dealType) {
      getTimelineSettings();
    }
  }, [deal, dealType, getTimelineSettings]);

  const handleSettingsChange = (fields: any[]) => {
    if (isDocumentType) {
      let documentTypeIdList: string[] = [];

      const dealTypeIdInput = deal ? undefined : dealType?.id;
      fields.forEach((field) => {
        documentTypeIdList.push(field.id);
      });
      const input = {
        dealTypeId: dealTypeIdInput,
        dealId: deal?.id,
        documentTypeIds: documentTypeIdList,
      };
      updateDocumentChecklist({
        variables: {
          input,
        },
      })
        .then(() => {
          setUsedFields(fields);
        })
        .catch((err) => console.log(err));
    } else if (!isDocumentType) {
      const settings = fields.map((field) => {
        return {
          [field.__typename === "SystemField" ? "columnName" : "customFieldId"]:
            field.id,
        };
      });
      const input: any = { settings };
      if (deal) {
        input.dealId = deal.id;
      } else if (dealType) {
        input.dealTypeId = dealType.id;
      }
      saveTimelineSettings({ variables: { input } }).catch((err) => {
        console.log(err);
      });
      setUsedFields(fields);
    }
  };

  const canEditTimeline = hasPermission(
    "settings",
    deal != null ? "limited" : "full"
  );
  const fieldItemList = (fields: any[]) => {
    return fields.map(
      (field: {
        label?: string;
        name?: string;
        id?: string;
        documentType?: { name?: string; id?: string; label: string };
      }) => {
        let item;
        if (field.documentType) {
          item = field.documentType;
        } else item = field;

        return (
          <div key={item.id}>
            <ListItem
              button
              style={{
                background: "#fff",
                border: "1px solid #e5e7eb",
                marginBottom: "11px",
              }}
            >
              {canEditTimeline && (
                <ListItemIcon>
                  <DragIndicator
                    className={clsx("handle", classes.dragHandle)}
                  />
                </ListItemIcon>
              )}
              <ListItemText>{item?.label || item.name}</ListItemText>
              {isDocumentType && !deal && (
                <>
                  <Tooltip
                    title="Edit"
                    aria-label="edit"
                    className="table-row-action"
                  >
                    <span>
                      <IconButton onClick={() => handleEditClick?.(field)}>
                        <PencilIcon size="20px" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title="Delete"
                    aria-label="delete"
                    className="table-row-action"
                  >
                    <span>
                      <IconButton onClick={() => handleDeleteClick?.(field)}>
                        <DeleteIcon size="20px" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              )}
            </ListItem>
          </div>
        );
      }
    );
  };
  const docTypeTimelineCheck = () => {
    if (isDocumentType) {
      return <>{children}</>;
    } else if (canEditTimeline) {
      return (
        <Typography
          color="textSecondary"
          style={{ padding: 11 }}
          align="center"
        >
          Drag fields here to make them appear on the deal's timeline and in the
          client portal
        </Typography>
      );
    } else
      return (
        <Typography
          color="textSecondary"
          style={{ padding: 11 }}
          align="center"
        >
          These fields appear by default on the deal's timeline and in the
          client portal
        </Typography>
      );
  };

  const handleItemList: any =
    isDocumentType && checklistFields ? checklistFields : usedFields;

  const emptyListVerbiage = isDocumentType
    ? "No more Document Types available to add"
    : "No more Timeline Dates available to add ";

  return (
    <Box style={{ height: "100%" }}>
      {availableFields && (usedFields || checklistFields) && (
        <Grid container spacing={2} style={{ height: "100%" }}>
          {canEditTimeline && (
            <Grid item xs={6} style={{ height: "100%" }}>
              <Paper
                style={{
                  border: `1px ${theme.palette.grey[300]} solid`,
                  borderRadius: "4px",
                  background: "#fff",
                  padding: "11px",
                  minHeight: "100px",
                }}
                elevation={2}
              >
                <Typography
                  color="textPrimary"
                  variant="h5"
                  style={{ paddingBottom: "15px" }}
                >
                  {isDocumentType
                    ? "Available Document Types"
                    : "Available Dates"}
                </Typography>
                <>
                  {availableFields.length === 0 && (
                    <Typography color="textSecondary" align="center">
                      {emptyListVerbiage}
                    </Typography>
                  )}
                  <ReactSortable
                    group="settings"
                    animation={200}
                    list={availableFields}
                    setList={setAvailableFields}
                    style={{ height: "100%", overflow: "auto" }}
                  >
                    {fieldItemList(availableFields)}
                  </ReactSortable>
                </>
              </Paper>
            </Grid>
          )}
          <Grid item xs={6}>
            <Paper
              style={{
                border: `1px ${theme.palette.grey[300]} solid`,
                borderRadius: "4px",
                background: "#fff",
                padding: "11px",
              }}
              elevation={2}
            >
              {/* // checks if rendering Document
                Types or Timeline copy */}
              {isDocumentType && deal && (
                <Grid
                  sx={{ marginLeft: "10px", paddingBottom: "15px" }}
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  container
                >
                  <Grid item display="flex" sx={{ marginBottom: "5px" }}>
                    <DocumentIcon
                      style={{
                        background: "#0B8D9B",
                        color: "#fff",
                        padding: "4.5px 4.5px",
                        borderRadius: "50%",
                        height: "22px",
                        width: "22px",
                        marginRight: "12px",
                      }}
                    />
                    <Typography color="textPrimary" variant="h5">
                      Deal Document Checklist
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      Drag available document types from the left to add to this
                      deal's checklist
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {docTypeTimelineCheck()}
              {canEditTimeline ? (
                <ReactSortable
                  group="settings"
                  animation={200}
                  list={handleItemList}
                  setList={handleSettingsChange}
                  style={{ height: "100%", overflow: "auto" }}
                >
                  {fieldItemList(handleItemList)}
                </ReactSortable>
              ) : (
                <>{fieldItemList(handleItemList)}</>
              )}
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default TimelineSettings;
