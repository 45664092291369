import { NOTIFICATION } from "../actions/notification";

export function setNotification(notification: {
  type: string;
  message: string;
}) {
  return {
    type: NOTIFICATION.SET,
    notification: notification,
  };
}

const initialState = {
  type: "",
  message: "",
};

export default function notificationReducer(state = initialState, action: any) {
  switch (action.type) {
    case NOTIFICATION.SET:
      return { ...state, ...action.notification };
    default:
      return state;
  }
}
