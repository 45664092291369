import React from "react";
import { Link, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import clsx from "clsx";
import { makeMoment } from "../../helpers";

const useStyles = makeStyles((theme: Theme) => ({
  taskDueDate: {
    fontSize: "12px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  taskDueDateUpcoming: {
    color: theme.palette.grey[700],
  },
  taskDueLaterToday: {
    color: theme.palette.success.main,
  },
  taskDueDatePastDue: {
    color: "#cf4440",
  },
}));

export const TaskDueDate = ({
  task,
  editable = false,
}: {
  task: any;
  editable?: boolean;
}): JSX.Element => {
  const classes = useStyles();
  let dueAt = task.dueAt ? makeMoment(task.dueAt) : null;
  let format = "MMMM D";
  if (!task.allDay) {
    format += ", LT";
  }
  if (task.completed) {
    return (
      <span className={classes.taskDueDate}>
        Completed {moment(task.completedAt).format(format)}
      </span>
    );
  } else if (dueAt && dueAt.isValid()) {
    const dueLaterToday = dueAt.isSame(moment(), "day") && !task.pastDue;
    return (
      <span
        className={clsx({
          [classes.taskDueDate]: true,
          [classes.taskDueLaterToday]: dueLaterToday,
          [classes.taskDueDateUpcoming]: !dueLaterToday && !task.pastDue,
          [classes.taskDueDatePastDue]: task.pastDue,
        })}
      >
        Due {dueAt.format(format)}
      </span>
    );
  } else if (task.dueAtPreview) {
    return (
      <span
        className={clsx({
          [classes.taskDueDate]: true,
          [classes.taskDueDateUpcoming]: true,
        })}
      >
        {task.dueAtPreview}
      </span>
    );
  } else if (editable) {
    return (
      <Link
        href="#"
        color="secondary"
        //onClick={(e: any) => handleEditTask(e, task)}
        className={classes.taskDueDate}
      >
        Set a Due Date
      </Link>
    );
  } else {
    return <></>;
  }
};
