import React from "react";
import { Checkbox, TextField, Theme } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import StyledChip from "./StyledChip";
import { Box } from "@mui/system";

const StyledAutoComplete = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#ffffff",
      minWidth: 200,
      "& label": {
        fontWeight: 600,
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
        maxWidth: 325,
        width: "100%",
      },
    },
  })
)(Autocomplete);

interface FilterVendorCardsProps {
  tags: any[];
  vendors: any[];
  setVendors: (vendors: any) => void;
}

const FilterVendorCards = ({
  tags,
  vendors,
  setVendors,
}: FilterVendorCardsProps) => {
  return (
    <StyledAutoComplete
      multiple
      id="filter-vendor-tags"
      options={tags}
      limitTags={2}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.name}
      onChange={(event: any, values: any) => {
        let filteredVendors;
        if (values.length === 0) {
          filteredVendors = vendors.map((vendor: any) => ({
            ...vendor,
            visible: true,
          }));
        } else {
          filteredVendors = vendors.map((vendor: any) => {
            const matches = vendor.tags.some((vt: any) => {
              return values.some((value: any) => value.name === vt.name);
            });
            if (matches) {
              vendor.visible = true;
            } else {
              vendor.visible = false;
            }
            return vendor;
          });
        }
        setVendors(filteredVendors);
      }}
      renderOption={(props: any, option: any, { selected }: any) => {
        return (
          <Box component="li" {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8, color: option.color }}
              checked={selected}
            />
            {option.name}
          </Box>
        );
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            label="Filter"
            placeholder=""
          />
        );
      }}
      renderTags={(value: any, getTagProps: any) =>
        value.map((tag: any, index: number) => (
          <StyledChip
            key={index}
            variant="outlined"
            label={tag.name}
            colorhex={tag.color || "#2061BE"}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};

export default FilterVendorCards;
