import { Integration } from "../models";

export const AVAILABLE_INTEGRATIONS = {
  SET: "AVAILABLE_INTEGRATIONS/set",
};

const initialState: Integration[] = [];

export default function availableIntegrationsReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case AVAILABLE_INTEGRATIONS.SET:
      return action.availableIntegrations;
    default:
      return state;
  }
}
