import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { format24HourTime } from "../../helpers";
import ClearIcon from "@mui/icons-material/Clear";

type TimeDropdownProps = {
  styles?: any;
  label?: any;
  value: string | null;
  settings?: any;
  onChange?: (value: string | null) => void;
  disabled?: boolean;
};

interface Option {
  label: string;
  value: string;
}

const TimeDropdown: FunctionComponent<TimeDropdownProps> = ({
  styles,
  label,
  settings = {},
  value: _value = null,
  onChange,
  disabled,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [value, setValue] = useState<Option | null>(null);

  useEffect(() => {
    const options = [{ label: "", value: "" }];
    for (let hour = 0; hour < 24; hour++) {
      for (let min = 0; min < 60; min += 15) {
        const value = `${hour.toString().padStart(2, "0")}:${min
          .toString()
          .padStart(2, "0")}:00`;
        const label = format24HourTime(value) as string;
        options.push({ label, value });
      }
    }

    let value = options.find((o) => o.value === _value);
    if (!value) {
      value = options[0];
    }
    setOptions(options);
    setValue(value);
  }, [_value]);

  const handleChange = (e: any, option: Option | null, reason: string) => {
    setValue(option);
    if (onChange) {
      onChange(option?.value ?? null);
    }
  };

  const renderOption = (props: any, option: any) => {
    return (
      <Box component="li" {...props} data-name={option.label}>
        {option.label}
      </Box>
    );
  };

  const renderInput = (params: any) => {
    return (
      <TextField
        {...params}
        inputProps={{
          ...params.inputProps,
        }}
        label={label}
        variant="standard"
      />
    );
  };
  return (
    <Autocomplete
      sx={{ ...styles }}
      options={options}
      fullWidth
      onOpen={() => {
        setTimeout(() => {
          const optionEl = document.querySelector(`[data-name="9:00 AM"]`);
          optionEl?.scrollIntoView();
        }, 1);
      }}
      value={value}
      clearIcon={<ClearIcon fontSize="small" />}
      disabled={disabled}
      onChange={handleChange}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );
};

export default TimeDropdown;
