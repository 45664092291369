import React, { FunctionComponent, ChangeEvent, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Paper,
  Button,
  Alert,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { RightSideDrawer } from "../../components/RightSideDrawer";
import AddressField from "../fields/Address";
import TeamMemberSelect from "../TeamMemberSelect";
import { connect } from "react-redux";
import PhoneField from "../fields/PhoneField";
import EmailField from "../fields/EmailField";
import TagAutocomplete from "./TagAutocomplete";
import { useUser } from "../../context/user";
import { CREATE_CONTACT, GET_POTENTIAL_DUPLICATES } from "../../api/graphql";
import { gql, useMutation } from "@apollo/client";
import { CloseRounded } from "@mui/icons-material";
import { useAlert } from "../../context/alert";
import { refreshContactTags } from "../../actions/contactTags";
import { ApiClient, useApi } from "../../context/api";

type ContactCreateFormProps = {
  open: boolean;
  onCreate: (contact: any) => any;
  onClose: () => any;
  refreshContactTags: (apolloClient: ApiClient) => Promise<void>;
};

const ContactCreateForm: FunctionComponent<ContactCreateFormProps> = ({
  open,
  onCreate,
  onClose,
  refreshContactTags,
}) => {
  const { currentUser } = useUser();
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    secondaryFirstName: "",
    secondaryLastName: "",
    secondaryEmail: "",
    secondaryPhone: "",
    address: {
      street: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
    },
    tags: [],
    ownerId: currentUser?.teamMember?.id,
    leadSource: "",
  };

  const [createSecondary, setCreateSecondary] = useState(false);
  const [contact, setContact] = useState(initialState);
  const [contactExists, setContactExists] = useState({
    primary: false,
    secondary: false,
  });
  const [contactSuggestions, setContactSuggestions] = useState<{
    primary: any | null;
    secondary: any | null;
  }>({
    primary: null,
    secondary: null,
  });
  const [createContact, { loading: saving }] = useMutation(CREATE_CONTACT);
  const { showError } = useAlert();
  const { apolloClient } = useApi();

  const getPotentialDuplicates = async (email: string) => {
    return apolloClient
      .query({
        query: GET_POTENTIAL_DUPLICATES,
        variables: { params: { emails: [email] } },
      })
      .then((response) => {
        return response.data.getPotentialDuplicates;
      })
      .catch((error) => showError(error));
  };

  const handleSubmit = async () => {
    createContact({
      variables: { input: contact },
    })
      .then((response: any) => {
        onCreate({
          ...response.data.createContact,
          firstName: contact.firstName,
          lastName: contact.lastName,
        });
        setContact(initialState);
      })
      .then(() => refreshContactTags(apolloClient))
      .catch(() => {
        showError("An error occurred when creating the contact");
      });
  };

  const validateEmail = async (email: any, key: keyof typeof contactExists) => {
    if (!email.length) {
      setContactExists({ ...contactExists, [key]: false });
      return;
    }
    const res: any = await getPotentialDuplicates(email);
    setContactExists((contactExists) => ({
      ...contactExists,
      [key]: res.contacts.length > 0,
    }));
    if (res.importableContacts && res.importableContacts.length > 0) {
      setContactSuggestions({
        ...contactSuggestions,
        [key]: res.importableContacts[0],
      });
    }
  };

  const handleImportContact = async (type: "primary" | "secondary") => {
    const suggestion = contactSuggestions[type];
    if (!suggestion) return;

    const [integration, contactId] = suggestion.id.split(":");
    const res = await apolloClient.query({
      query: gql`
        query GetContactFromIntegration(
          $integration: String!
          $contactId: String!
        ) {
          getContactFromIntegration(
            integration: $integration
            contactId: $contactId
          ) {
            id
            firstName
            lastName
            email
            phone
            address {
              street
              street2
              city
              state
              zip
            }
            leadSource
          }
        }
      `,
      variables: {
        integration,
        contactId,
      },
    });

    setContact((contact) => ({
      ...contact,
      ...res.data.getContactFromIntegration,
      id: undefined,
    }));

    setContactSuggestions((contactSuggestions) => ({
      ...contactSuggestions,
      [type]: null,
    }));
  };

  const ContactHelperText = (props: { type: "primary" | "secondary" }) => {
    if (contactExists[props.type]) {
      return <>A contact with this email address already exists</>;
    }
    const contactSuggestion = contactSuggestions[props.type];
    if (contactSuggestion) {
      return (
        <Alert
          variant="outlined"
          severity="info"
          icon={false}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => handleImportContact(props.type)}
            >
              Import
            </Button>
          }
        >
          Import {contactSuggestion.name} ({contactSuggestion.email}) from
          kvCORE?{" "}
        </Alert>
      );
    }
    return null;
  };

  return (
    <RightSideDrawer
      anchor="right"
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        style={{
          minHeight: "64px",
          background: "#1A0B31",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
        }}
      >
        <Typography style={{ color: "#fff" }} variant={"h3"}>
          New Contact
        </Typography>
        <IconButton color="primary" onClick={onClose} size="large">
          <CloseRounded />
        </IconButton>
      </Box>

      <Box
        p={4}
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Paper sx={{ marginBottom: "21px" }}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Primary Contact</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  autoFocus
                  label="First Name"
                  fullWidth
                  value={contact.firstName}
                  onChange={(e: ChangeEvent<any>) =>
                    setContact({ ...contact, firstName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  label="Last Name"
                  fullWidth
                  value={contact.lastName}
                  onChange={(e: ChangeEvent<any>) =>
                    setContact({ ...contact, lastName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <EmailField
                  type="email"
                  label="Email"
                  error={contactExists.primary}
                  helperText={<ContactHelperText type="primary" />}
                  fullWidth
                  value={contact.email}
                  onBlur={(email) => validateEmail(email, "primary")}
                  onChange={(email: string | null) =>
                    setContact({ ...contact, email: email ?? "" })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneField
                  type="phone"
                  label="Phone"
                  fullWidth
                  settings={{ format: "us_can" }}
                  value={contact.phone}
                  onChange={(phone: string | null) => {
                    if (phone) {
                      setContact({ ...contact, phone });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createSecondary}
                      color="primary"
                      name="addSecondaryContact"
                      onChange={(e, checked) => {
                        setCreateSecondary(checked);
                        setContact({
                          ...contact,
                          secondaryFirstName: "",
                          secondaryLastName: "",
                          secondaryEmail: "",
                          secondaryPhone: "",
                        });
                      }}
                    />
                  }
                  label="Add a secondary contact"
                />
                <Typography variant="subtitle1">
                  A secondary contact is typically a spouse or significant
                  other.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        {createSecondary && (
          <Paper sx={{ marginBottom: "21px" }}>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Secondary Contact</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    label="First Name"
                    fullWidth
                    value={contact.secondaryFirstName}
                    onChange={(e: ChangeEvent<any>) =>
                      setContact({
                        ...contact,
                        secondaryFirstName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    label="Last Name"
                    fullWidth
                    value={contact.secondaryLastName}
                    onChange={(e: ChangeEvent<any>) =>
                      setContact({
                        ...contact,
                        secondaryLastName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <EmailField
                    type="email"
                    label="Email"
                    error={contactExists.secondary}
                    helperText={
                      contactExists.secondary
                        ? "A contact with this email address already exists"
                        : undefined
                    }
                    onBlur={(email) => validateEmail(email, "secondary")}
                    fullWidth
                    value={contact.secondaryEmail}
                    onChange={(email: string | null) => {
                      if (email) {
                        setContact({ ...contact, secondaryEmail: email });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneField
                    type="phone"
                    label="Phone"
                    fullWidth
                    settings={{ format: "us_can" }}
                    value={contact.secondaryPhone}
                    onChange={(phone: string | null) => {
                      if (phone) {
                        setContact({ ...contact, secondaryPhone: phone });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}

        <Paper sx={{ marginBottom: "21px" }}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Address</Typography>
              </Grid>
              <Grid item xs={12}>
                <AddressField
                  value={contact.address}
                  onChange={(address) => setContact({ ...contact, address })}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Paper sx={{ marginBottom: "21px" }}>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Advanced</Typography>
              </Grid>
              <Grid item xs={12}>
                <TagAutocomplete
                  freeSolo
                  value={contact.tags}
                  onChange={(tags) => {
                    setContact((contact) =>
                      Object.assign({}, contact, { tags })
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TeamMemberSelect
                  label="Assign team member"
                  value={contact.ownerId}
                  onChange={(teamMember) => {
                    setContact({
                      ...contact,
                      ownerId: teamMember?.id ? teamMember.id : null,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="standard"
                  label="Lead Source"
                  value={contact.leadSource}
                  onChange={(e) => {
                    setContact(
                      Object.assign({}, contact, { leadSource: e.target.value })
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Box p={2} style={{ background: "#F5F5F5" }}>
        <Grid container spacing={2} style={{}}>
          <Grid item xs={6}>
            <SecondaryButton
              fullWidth
              variant="outlined"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton
              fullWidth
              onClick={handleSubmit}
              disabled={
                saving ||
                (!contact.firstName && !contact.lastName && !contact.email) ||
                contactExists["primary"] ||
                contactExists["secondary"]
              }
            >
              Create Contact
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
    </RightSideDrawer>
  );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps, { refreshContactTags })(
  ContactCreateForm
);
