import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { IMPERSONATE_USER } from "../api/graphql";
import { useAuth } from "./auth";
import { useApi } from "./api";
import mixpanel from "../mixpanel";

export const GET_USER = gql`
  {
    me {
      id
      email
      firstName
      lastName
      name
      avatarUrl
      timezone
      internalAdmin
      hasEmailLinked
      hasCalendarLinked
      hasUnreadSmsThreads
      hasEmailConnectivityError
      createdAt
      impersonatedBy {
        id
        email
        name
      }
      teamMember {
        id
        provisionedPhone
      }
      contacts {
        id
        email
        firstName
        lastName
        name
        avatarUrl
      }
      permissions {
        settings
        contacts
        deals
        reports
        billing
      }
      account {
        id
        name
        logoUrl
      }
    }
  }
`;

type PermissionKey = "settings" | "billing" | "contacts" | "deals" | "reports";
type PermissionLevel = "full" | "limited" | "none";
const comparePermissions = (a: PermissionLevel, b: PermissionLevel) => {
  if (a === b) return 0;
  else if (a === "none") return -1;
  else if (b === "none") return 1;
  else if (a === "limited") return -1;
  else if (b === "limited") return 1;
  else return 0;
};

const UserContext = React.createContext<{
  currentUser: any;
  setCurrentUser: (user: any) => void;
  handleImpersonate: (userId: string) => void;
  handleStopImpersonate: () => void;
  refetchUser: () => void;
  isImpersonating: boolean;
  hasPermission: (
    permissionKey: PermissionKey,
    requiredLevel: PermissionLevel
  ) => boolean;
}>({
  currentUser: undefined,
  setCurrentUser: () => {},
  handleImpersonate: () => {},
  handleStopImpersonate: () => {},
  refetchUser: () => {},
  isImpersonating: false,
  hasPermission: () => false,
});

function UserProvider(props: any) {
  const navigate = useNavigate();
  const { apolloClient } = useApi();
  const { authToken, setImpersonateToken, removeImpersonateToken } = useAuth();
  const [currentUser, setCurrentUser] = useState<any>();

  const [impersonateUser] = useMutation(IMPERSONATE_USER, {
    onCompleted: (res: any) => {
      setImpersonateToken(res.impersonateUser.token);
      setCurrentUser(undefined);
      navigate("/");
    },
  });

  const refetchUser = useCallback(() => {
    apolloClient
      .query({ query: GET_USER })
      .then((res) => {
        setCurrentUser(res.data.me);
      })
      .catch((err) => console.error("There was a problem logging in", err));
  }, [apolloClient]);

  useEffect(() => {
    if (authToken && !currentUser) {
      /**
       * We have a token but haven't fetched the current user. We'll
       * query for the current user.
       */
      apolloClient
        .query({ query: GET_USER })
        .then((res) => {
          setCurrentUser(res.data.me);
        })
        .catch((err) => console.error("There was a problem logging in", err));
    } else if (!authToken && currentUser) {
      setCurrentUser(null);
    }
  }, [apolloClient, authToken, currentUser]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    mixpanel.identify(currentUser.id);
    // https://help.mixpanel.com/hc/en-us/articles/115004708186-Profile-Properties
    mixpanel.people.set({
      $name: currentUser.name,
      $email: currentUser.email,
      $avatar: currentUser.avatarUrl,
    });
    if (currentUser.account) {
      mixpanel.addGroup("accountId", currentUser.account.id);
      mixpanel.getGroup("accountId", currentUser.account.id)?.set({
        $name: currentUser.account.name,
      });
    }
  }, [currentUser]);

  const value = useMemo(() => {
    const handleImpersonate = (userId: string) => {
      impersonateUser({
        variables: {
          userId: userId,
          clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
        },
      });
    };

    const handleStopImpersonate = () => {
      removeImpersonateToken().then(() => {
        setCurrentUser(undefined);
        navigate("/admin");
      });
    };

    const hasPermission = (
      permissionKey: PermissionKey,
      requiredLevel: PermissionLevel
    ) => {
      const userLevel = currentUser.permissions[permissionKey];
      return comparePermissions(userLevel, requiredLevel) >= 0;
    };

    return {
      currentUser,
      setCurrentUser,
      handleImpersonate,
      handleStopImpersonate,
      refetchUser,
      hasPermission,
    };
  }, [
    currentUser,
    setCurrentUser,
    navigate,
    impersonateUser,
    removeImpersonateToken,
    refetchUser,
  ]);

  return <UserContext.Provider value={value} {...props} />;
}

const useUser = () => React.useContext(UserContext);
export { UserProvider, UserContext, useUser };
