import React from "react";
import SplitLayout from "./SplitLayout";
import LeftPane from "../components/LeftPane";
import SettingsSubNav from "../components/SettingsSubNav";
import { CircularProgress, Grid } from "@mui/material";

const AccountSettingsFallback = () => (
  <SplitLayout>
    <LeftPane width={224}>
      <SettingsSubNav />
    </LeftPane>
    <Grid
      container
      spacing={0}
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <CircularProgress />
    </Grid>
  </SplitLayout>
);

export default AccountSettingsFallback;
