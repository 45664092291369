import React, { useState, useRef } from "react";
import MaterialTable, { Query, QueryResult } from "@material-table/core";
import { Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import moment from "moment";
import { OutlineButton } from "../buttons";
import TableIcons from "../TableIcons";
import { gql, useApolloClient } from "@apollo/client";
import { useUser } from "../../context/user";
import { TrashIcon as DeleteIcon } from "../../icons";
import UserDeleteDialog from "../users/UserDeleteDialog";

const GET_ACCOUNT_USERS = gql`
  query getAccountUsers(
    $accountId: ID!
    $page: Int
    $pageSize: Int
    $term: String
  ) {
    account(id: $accountId) {
      users(page: $page, pageSize: $pageSize, term: $term) {
        page
        pageSize
        totalCount
        users {
          id
          name
          email
          nylasAccountId
          lastActiveDate
          permissions {
            settings
          }
        }
      }
    }
  }
`;

const UPGRADE_NYLAS = gql`
  mutation UPGRADE_NYLAS($userId: ID!) {
    upgradeNylas(userId: $userId)
  }
`;

const AccountUsersList = ({ accountId }: { accountId: string }) => {
  const apollo = useApolloClient();
  const { handleImpersonate } = useUser();

  const [menuAnchorEl, setMenuAnchorEl] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] =
    useState<boolean>(false);
  const tableRef = useRef<any>(null);

  const queryUsers = (query: Query<any>): Promise<QueryResult<any>> => {
    const params = {
      accountId,
      page: query.page,
      pageSize: query.pageSize,
      term: query.search,
    };

    return new Promise((resolve, reject) => {
      apollo
        .query({
          query: GET_ACCOUNT_USERS,
          variables: { ...params },
        })
        .then((response) => {
          resolve({
            data: response.data.account.users.users,
            page: response.data.account.users.page,
            totalCount: response.data.account.users.totalCount,
          });
        })
        .catch((error) => reject(error));
    });
  };

  const handleUserMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedUser(null);
  };

  const handleUpgradeNylas = () => {
    apollo
      .mutate({
        mutation: UPGRADE_NYLAS,
        variables: {
          userId: selectedUser.id,
        },
      })
      .then(() => {})
      .finally(() => {
        handleUserMenuClose();
      });
  };

  if (!accountId) {
    return <></>;
  }

  const handleDialogClose = () => {
    setDeleteUserDialogOpen(false);
    handleUserMenuClose();
  };

  const handleUserDelete = (softDelete?: boolean) => {
    const DELETE_USER = gql`
      mutation deleteUser($id: ID!) {
        deleteUser(id: $id)
      }
    `;
    let variables: {
      id: string;
      softDelete?: boolean;
    } = {
      id: selectedUser.id,
    };
    if (softDelete) {
      variables.softDelete = true;
    }
    apollo
      .mutate({
        mutation: DELETE_USER,
        variables,
      })
      .then(() => {})
      .finally(() => {
        handleUserMenuClose();
        tableRef.current?.onQueryChange();
      });
  };

  return (
    <>
      <MaterialTable
        columns={[
          {
            title: "Name",
            field: "name",
            render: (rowData: any) => (
              <div>
                <Typography variant="body1">{rowData.name}</Typography>
                <Typography variant="subtitle2">
                  {rowData.permissions.settings === "full" && <>Admin</>}
                </Typography>
              </div>
            ),
          },
          { title: "Email", field: "email" },
          {
            title: "Last Active",
            field: "lastActiveDate",
            sorting: false,
            render: (rowData: any) => {
              if (rowData.lastActiveDate) {
                return moment(rowData.lastActiveDate).fromNow();
              }
            },
          },
          {
            title: "",
            field: "impersonate",
            align: "right",
            render: (rowData: any) => {
              return (
                <div>
                  <OutlineButton onClick={() => handleImpersonate(rowData.id)}>
                    Impersonate
                  </OutlineButton>
                </div>
              );
            },
          },
          {
            title: "",
            field: "actions",
            cellStyle: {
              textAlign: "right",
            },
            render: (rowData: any) => {
              return (
                <IconButton
                  onClick={(e) => {
                    setSelectedUser(rowData);
                    setMenuAnchorEl(e.currentTarget);
                  }}
                  size="large"
                >
                  <MoreVert />
                </IconButton>
              );
            },
          },
        ]}
        data={queryUsers}
        tableRef={tableRef}
        title="Users"
        icons={TableIcons}
        options={{
          selection: false,
          showTitle: true,
          search: true,
          emptyRowsWhenPaging: false,
          pageSize: 10,
          toolbar: true,
          headerStyle: {
            fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
            fontSize: "14px",
            color: "rgb(107, 114, 128)",
          },
        }}
        style={{
          width: "100%",
          fontSize: "14px",
          fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
        }}
      ></MaterialTable>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleUserMenuClose}
      >
        <MenuItem
          disabled={!selectedUser?.nylasAccountId}
          onClick={handleUpgradeNylas}
        >
          Upgrade Nylas
        </MenuItem>
        <MenuItem onClick={() => setDeleteUserDialogOpen(true)}>
          <DeleteIcon style={{ marginRight: "3px" }} /> Delete User
        </MenuItem>
      </Menu>
      {selectedUser && (
        <UserDeleteDialog
          user={selectedUser}
          dialogOpen={deleteUserDialogOpen}
          onClose={handleDialogClose}
          onUserDelete={handleUserDelete}
        />
      )}
    </>
  );
};

export default AccountUsersList;
