import React, { ChangeEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Chip } from "@mui/material";
import { useQuery, gql } from "@apollo/client";

const GET_ZIPCODES = gql`
  {
    getZipSuggestions
  }
`;

type ZipCodeSelectMultipleProps = {
  onChange: (zipcodes: string) => void;
  value: any[];
  variant?: any;
};

export default function ZipCodeSelectMultiple({
  onChange,
  value,
  variant = "standard",
}: ZipCodeSelectMultipleProps) {
  const { data } = useQuery(GET_ZIPCODES);

  const handleChange = (e: ChangeEvent<any>, value: any) => {
    onChange(value);
  };

  return (
    <Autocomplete
      multiple
      id="selected-agents-autocomplete"
      options={data?.getZipSuggestions || []}
      value={value}
      onChange={handleChange}
      getOptionLabel={(option: any) => option}
      filterSelectedOptions
      renderTags={(value: any, getOptionSelected) =>
        value.map((value: any, index: number) => (
          <Chip
            variant={variant}
            label={value}
            {...getOptionSelected({ index })}
          />
        ))
      }
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant={variant}
          label="Zipcode(s)"
          placeholder="Zipcode(s)"
        />
      )}
    />
  );
}
