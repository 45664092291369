import React, { FunctionComponent, useEffect } from "react";
import { TextField } from "@mui/material";
import { gql, useLazyQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";

export type DocuSignRoom = {
  id: number;
  name: string;
  url: string;
};

const DocuSignRoomAutocomplete: FunctionComponent<{
  accountId: string;
  onRoomSelected: (room: DocuSignRoom) => any;
}> = ({ accountId, onRoomSelected }) => {
  const [getDocuSignRooms, { data, loading }] = useLazyQuery(gql`
    query GetDocuSignRooms($accountId: String!) {
      getDocuSignRooms(accountId: $accountId) {
        id
        name
      }
    }
  `);

  useEffect(() => {
    if (accountId) {
      getDocuSignRooms({
        variables: {
          accountId,
        },
      });
    }
  }, [accountId, getDocuSignRooms]);

  return (
    <Autocomplete
      fullWidth
      loading={loading}
      onChange={(event, room) => {
        onRoomSelected(room as DocuSignRoom);
      }}
      options={data?.getDocuSignRooms || []}
      getOptionLabel={(option: DocuSignRoom) => option.name}
      renderInput={(params) => (
        <TextField {...params} label="Room" variant="standard" />
      )}
    />
  );
};
export default DocuSignRoomAutocomplete;
