import Quill from "quill";

let Inline = Quill.import("blots/inline");
class SpanBlot extends Inline {}
SpanBlot.blotName = "span";
SpanBlot.tagName = "span";
Quill.register(SpanBlot);

const DirectionAttribute = Quill.import("attributors/attribute/direction");
Quill.register(DirectionAttribute, true);

const AlignClass = Quill.import("attributors/class/align");
Quill.register(AlignClass, true);

const BackgroundClass = Quill.import("attributors/class/background");
Quill.register(BackgroundClass, true);

const ColorClass = Quill.import("attributors/class/color");
Quill.register(ColorClass, true);

const DirectionClass = Quill.import("attributors/class/direction");
Quill.register(DirectionClass, true);

const FontClass = Quill.import("attributors/class/font");
Quill.register(FontClass, true);

const SizeClass = Quill.import("attributors/class/size");
Quill.register(SizeClass, true);

const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);

const BackgroundStyle = Quill.import("attributors/style/background");
Quill.register(BackgroundStyle, true);

const ColorStyle = Quill.import("attributors/style/color");
Quill.register(ColorStyle, true);

const DirectionStyle = Quill.import("attributors/style/direction");
Quill.register(DirectionStyle, true);

const FontStyle = Quill.import("attributors/style/font");
Quill.register(FontStyle, true);

const SizeStyle = Quill.import("attributors/style/size");
Quill.register(SizeStyle, true);

export const toolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [{ align: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  ["blockquote"],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  ["link", "image"],
];

export const makeToolbarWithMergeFields = (mergeFields: Array<any>) => {
  // https://github.com/quilljs/quill/issues/1817
  const options = mergeFields.map((mergeField: any) => mergeField.name);
  const recipes = [
    {
      name: "All Contacts' Full Names",
      placeholder:
        "{{#each deal.contacts}}{{#if @last}}and {{/if}}{{ name }}{{#unless @last}}, {{/unless}}{{/each}}",
    },
    {
      name: "All Contacts' First Names",
      placeholder:
        "{{#each deal.contacts}}{{#if @last}}and {{/if}}{{ firstName }}{{#unless @last}}, {{/unless}}{{/each}}",
    },
    {
      name: "All Contacts' Contact Info",
      placeholder: `{{#each deal.contacts}}
{{ name }}
{{ email }}
{{ phone }}
{{#unless @last}}

{{/unless}}
{{/each}}`,
    },
    {
      name: "Primary Contact's Name",
      placeholder: "{{ deal.contacts.[0].name }}",
    },
    {
      name: "Primary Contact's Email",
      placeholder: "{{ deal.contacts.[0].email }}",
    },
    {
      name: "Primary Contact's Phone",
      placeholder: "{{ deal.contacts.[0].phone }}",
    },
    {
      name: "Secondary Contact's Name",
      placeholder: "{{ deal.contacts.[1].name }}",
    },
    {
      name: "Secondary Contact's Email",
      placeholder: "{{ deal.contacts.[1].email }}",
    },
    {
      name: "Secondary Contact's Phone",
      placeholder: "{{ deal.contacts.[1].phone }}",
    },
  ];
  return {
    container: [
      ...toolbar,
      [
        { mergeFields: options },
        { recipes: recipes.map((recipe) => recipe.name) },
      ],
    ],
    handlers: {
      mergeFields: function (value: any) {
        const mergeField = mergeFields.find(
          (mergeField: any) => mergeField.name === value
        );
        if (!mergeField) {
          return;
        }
        const quill = (this as any).quill; // Not sure the best way to get quill here...
        const cursorPosition = quill.getSelection().index;
        const placeholder = mergeField.placeholder;
        quill.insertText(cursorPosition, placeholder);
        quill.setSelection(cursorPosition + placeholder.length);
      },
      recipes: function (value: any) {
        const recipe = recipes.find((recipe: any) => recipe.name === value);
        if (!recipe) {
          return;
        }
        const quill = (this as any).quill; // Not sure the best way to get quill here...
        const cursorPosition = quill.getSelection().index;
        const placeholder = recipe.placeholder;
        quill.insertText(cursorPosition, placeholder);
        quill.setSelection(cursorPosition + placeholder.length);
      },
    },
  };
};
