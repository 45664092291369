import React, { useRef, useEffect, useState, useCallback } from "react";
import MaterialTable, { Query, QueryResult } from "@material-table/core";
import TableIcons from "../TableIcons";
import ContactNameWithAvatar from "../contacts/ContactNameWithAvatar";
import { ViewColumnIcon } from "../../icons";
import ActionIconButton from "../ActionIconButton";
import {
  Menu,
  MenuItem,
  Checkbox,
  Chip,
  Theme,
  Typography,
  Avatar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { LinearProgressWithLabel } from "../LinearProgressWithLabel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dealTypeChip: {
      height: "26px",
      width: "64px",
      textTransform: "uppercase",
      fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      fontSize: "10px",
      color: "#ffffff",
      backgroundColor: "rgb(75, 177, 236)",
    },
  })
);

export default function ClosingsTable({
  closings,
  searchTerm,
}: {
  closings: string[];
  searchTerm?: string;
}) {
  const tableRef = useRef(null);
  const columnsMenuButtonRef = useRef(null);
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    (tableRef.current as any)?.onQueryChange();
  }, [searchTerm, closings]);

  const fullAddress = (address: any) => {
    let fullAddress = [];
    if (address.street?.length > 0) fullAddress.push(`${address.street}, `);
    if (address.street2?.length > 0) fullAddress.push(`${address.street2},`);
    return [
      fullAddress.join(" "),
      `${address.city}, `,
      address.state,
      address.zip,
    ].join(" ");
  };

  const filterClosings = useCallback(
    (query: Query<any>): Promise<QueryResult<any>> => {
      let data: any[] = closings || [];

      if (searchTerm) {
        const lowerCaseTerm = searchTerm.toLowerCase();

        data = data.filter((deal) => {
          return (
            deal.contacts[0].name.toLowerCase().includes(lowerCaseTerm) ||
            fullAddress(deal.address).includes(lowerCaseTerm)
          );
        });
      }

      return Promise.resolve({
        data: data,
        page: 0,
        totalCount: data.length * 20,
      });
    },
    [closings, searchTerm]
  );

  const renderName = (deal: any): JSX.Element => {
    if (deal.contacts.length === 0) {
      return (
        <span style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            component="span"
            alt="No Contact Icon"
            title="No Contact Icon"
            src="//www.gravatar.com/avatar/?d=mp"
            style={{ marginRight: "10px" }}
          />
          <Typography color="textSecondary">No Contact Found</Typography>
        </span>
      );
    } else {
      return (
        <>
          <ContactNameWithAvatar
            name={deal.contacts[0].combinedName || deal.contacts[0].name}
            avatarUrls={[
              deal.contacts[0].avatarUrl,
              deal.contacts[0].linkedContact?.avatarUrl,
            ]}
            avatarStyle={{ marginRight: "20px" }}
          />
        </>
      );
    }
  };

  const renderAddress = (deal: any): JSX.Element => {
    return <div>{fullAddress(deal.address)}</div>;
  };

  const renderCloseDate = (deal: any): JSX.Element => {
    if (deal.closeDate) {
      return <div>{moment(deal.closeDate, "YYYY-MM-DD").format("ll")}</div>;
    } else {
      return <div></div>;
    }
  };

  const renderDealType = (deal: any): JSX.Element => (
    <Chip label={deal.dealType.clientNoun} className={classes.dealTypeChip} />
  );

  const renderColumnSelectHeader = (): JSX.Element => {
    return (
      <ActionIconButton
        ref={columnsMenuButtonRef}
        icon={ViewColumnIcon}
        buttonStyle={{ margin: "0", width: "26px", height: "26px" }}
        onClick={(event: any) => {
          event.stopPropagation();
          setColumnMenuOpen(true);
        }}
      />
    );
  };

  const renderCloseProgress = (deal: any) => (
    <LinearProgressWithLabel percent={deal.taskCompletionPercent} />
  );

  const renderMenu = (rowData: any) => {
    // For when we are ready to implement this menu
    // return (
    //   <ActionIconButton
    //     icon={MoreVertIcon}
    //     buttonStyle={{ margin: "0", width: "26px", height: "26px" }}
    //   />
    // );

    return <></>;
  };

  const [columnMenuOpen, setColumnMenuOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState([
    {
      key: "name",
      visible: true,
      title: "Name",
      field: "name",
      headerStyle: { paddingLeft: "24px" },
      cellStyle: { paddingLeft: "24px" },
      render: renderName,
    },
    {
      key: "address",
      visible: true,
      title: "Address",
      field: "address",
      render: renderAddress,
    },
    {
      key: "closeDate",
      visible: true,
      title: "Close Date",
      field: "closeDate",
      render: renderCloseDate,
    },
    {
      key: "dealType",
      visible: true,
      title: "Deal Type",
      field: "type",
      render: renderDealType,
    },
    {
      key: "closeProgress",
      visible: true,
      title: "Closing Progress",
      field: "type",
      render: renderCloseProgress,
    },
    {
      key: "column-select",
      visible: true,
      title: renderColumnSelectHeader(),
      sorting: false,
      width: "34px",
      headerStyle: { paddingRight: "24px" },
      cellStyle: { paddingRight: "24px" },
      render: renderMenu,
    },
  ]);

  const toggleColumnVisible = (key: string) => () => {
    setColumns((columns) => {
      const column = columns.find((column) => column.key === key);
      const updatedColumn = Object.assign({}, column, {
        visible: !column?.visible,
      });
      return columns.map((c) => (c.key === key ? updatedColumn : c));
    });
  };

  const handleRowClick = (e: any, deal: any) => {
    navigate(`/deals/${deal.id}`);
  };

  return (
    <>
      <Menu
        open={columnMenuOpen}
        onClose={() => setColumnMenuOpen(false)}
        anchorEl={columnsMenuButtonRef.current}
      >
        {columns
          .filter((column) => column.key !== "column-select")
          .map((column) => (
            <MenuItem
              key={column.key}
              onClick={toggleColumnVisible(column.key)}
              style={{ paddingLeft: "0", fontSize: "12px" }}
            >
              <Checkbox checked={column.visible} size="small" />
              {column.title}
            </MenuItem>
          ))}
      </Menu>
      <MaterialTable
        tableRef={tableRef}
        icons={TableIcons}
        columns={columns.filter((column) => column.visible)}
        data={filterClosings}
        onRowClick={handleRowClick}
        options={{
          selection: false,
          showTitle: false,
          search: false,
          emptyRowsWhenPaging: false,
          columnsButton: true,
          paging: false,
          toolbar: false,
          maxBodyHeight: "475px",
          headerStyle: {
            position: "sticky",
            fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
            fontSize: "14px",
            color: "rgb(107, 114, 128)",
          },
          rowStyle: {
            height: "72px",
          },
        }}
        style={{
          width: "100%",
          fontSize: "14px",
          fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
        }}
      ></MaterialTable>
    </>
  );
}
