import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { AuthProvider } from "./context/auth";
import "./App.scss";
import RootComponent from "./components/Root";
import { ApiProvider } from "./context/api";
import { UserProvider } from "./context/user";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: React.CSSProperties["color"];
    };
  }

  interface Palette {
    amethyst: Palette["primary"];
    coral: Palette["primary"];
    beige: Palette["primary"];
    teal: Palette["primary"];
    amber: Palette["primary"];
    salmon: Palette["primary"];
    lime: Palette["primary"];
    turquoise: Palette["primary"];
    deepAmethyst: Palette["primary"];
    electricIndigo: Palette["primary"];
  }
  interface PaletteOptions {
    amethyst: PaletteOptions["primary"];
    coral: PaletteOptions["primary"];
    beige: PaletteOptions["primary"];
    teal: PaletteOptions["primary"];
    amber: PaletteOptions["primary"];
    salmon: PaletteOptions["primary"];
    lime: PaletteOptions["primary"];
    turquoise: PaletteOptions["primary"];
    deepAmethyst: PaletteOptions["primary"];
    electricIndigo: PaletteOptions["primary"];
  }
}

const store = createStore(rootReducer, applyMiddleware(thunk));
export const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#0B8D9B",
      },
      secondary: {
        main: "#1A0B31",
      },
      error: {
        main: "rgb(207, 68, 64)",
      },
      grey: {
        "100": "#f4f5f7",
        "300": "#E8E2E1",
        "500": "#656160",
      },
      amethyst: {
        main: "#351665",
      },
      coral: {
        main: "#FF576D",
      },
      beige: {
        main: "#FEFBF8",
      },
      teal: {
        main: "#37E6D9",
      },
      amber: {
        main: "#FFB000",
      },
      salmon: {
        main: "#FFA184",
      },
      lime: {
        main: "#7BDA5E",
      },
      turquoise: {
        main: "#0B8D9B",
      },
      deepAmethyst: {
        main: "#1A0B31",
      },
      electricIndigo: {
        main: "#5759FF",
      },
      background: {
        default: "#F5F5F5",
        paper: "#FFFFFF",
      },
    },
    typography: {
      fontFamily: ["AvenirNext-Regular", "Avenir", "sans-serif"].join(","),
      body1: {
        fontFamily: ["AvenirNext-Regular", "Avenir", "sans-serif"].join(","),
        fontSize: "14px",
      },
      body2: {
        fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
        fontSize: "12px",
        fontWeight: 500,
      },
      h1: {},
      h2: {
        fontSize: "32px",
        fontWeight: "bold",
        fontFamily: ["AvenirNext-Bold", "Avenir", "sans-serif"].join(","),
        lineHeight: "40px",
      },
      h3: {
        fontSize: "22px",
        fontWeight: 600,
        fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      },
      h4: {
        fontSize: "18px",
        fontWeight: 600,
        fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      },
      h5: {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      },
      h6: {
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
      },
      subtitle1: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: ["AvenirNext-Regular", "Avenir", "sans-serif"].join(","),
      },
      subtitle2: {
        fontSize: "12px",
        fontWeight: 600,
        fontFamily: ["AvenirNext-DemiBold", "Avenir", "sans-serif"].join(","),
        textTransform: "uppercase",
        letterSpacing: "1px",
      },
      button: {},
      caption: {
        fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
        fontSize: "10px",
        fontWeight: 500,
      },
      overline: {},
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  })
);

const App = () => {
  return (
    <AuthProvider>
      <ApiProvider>
        <Provider store={store}>
          <Router>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div className="App">
                    <UserProvider>
                      <RootComponent />
                    </UserProvider>
                  </div>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </Router>
        </Provider>
      </ApiProvider>
    </AuthProvider>
  );
};

export default App;
