import React, { FunctionComponent, useEffect } from "react";
import { TextField } from "@mui/material";
import { gql, useLazyQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";

type Link = {
  href: string;
  rel: string;
  method: string;
};

type Listing = {
  id: string;
  listingExpirationDate: string;
  listPrice?: number;
  link: Link;
  address?: string;
  mlsId?: string;
  status?: string;
};

type Sale = {
  id: string;
  closingDate?: string;
  escrowClosingDate?: string;
  link: Link;
  address?: string;
  status?: string;
};

const GET_LISTINGS = gql`
  query GetSkySlopeListings($agentGuid: String) {
    getSkySlopeListings(agentGuid: $agentGuid) {
      id
      listingExpirationDate
      listPrice
      link {
        href
        rel
        method
      }
      address
      mlsId
      status
    }
  }
`;

const GET_SALES = gql`
  query GetSkySlopeSales($agentGuid: String) {
    getSkySlopeSales(agentGuid: $agentGuid) {
      id
      closingDate
      address
      link {
        href
        rel
        method
      }
      escrowClosingDate
      status
    }
  }
`;

const SkySlopeTransactionAutocomplete: FunctionComponent<{
  transactionType: string;
  onTransactionSelected: (transaction: Listing | Sale) => any;
}> = ({ transactionType, onTransactionSelected }) => {
  const [getListings, { data: listingsData, loading: listingsLoading }] =
    useLazyQuery(GET_LISTINGS);
  const [getSales, { data: salesData, loading: salesLoading }] =
    useLazyQuery(GET_SALES);

  useEffect(() => {
    if (transactionType === "Listing") {
      getListings();
    } else {
      getSales();
    }
  }, [getListings, getSales, transactionType]);

  return (
    <Autocomplete
      fullWidth
      loading={listingsLoading || salesLoading}
      onChange={(event, transaction) => {
        onTransactionSelected(transaction as Listing | Sale);
      }}
      options={
        (transactionType === "Listing"
          ? listingsData?.getSkySlopeListings
          : salesData?.getSkySlopeSales) || []
      }
      getOptionLabel={(option: Listing | Sale) => option.address ?? option.id}
      renderInput={(params) => (
        <TextField {...params} label={transactionType} variant="standard" />
      )}
    />
  );
};
export default SkySlopeTransactionAutocomplete;
