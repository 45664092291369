import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, Typography } from "@mui/material";
import { CircleCheckIcon } from "../icons";

export const LinearProgressWithLabel = ({
  percent,
  reportsProgressBar,
}: {
  percent: number;
  reportsProgressBar?: boolean;
}) => {
  const percentCompleted = Math.round(percent * 100);
  return (
    <Box display="flex" alignItems="center" style={{ margin: "10px 0" }}>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          value={percent < 1 ? percent * 100 : 100} // prevents partial fill for > 100%
        />
      </Box>
      {!reportsProgressBar && (
        <Box
          minWidth={25}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {percentCompleted < 100 ? (
            <Typography
              variant="body2"
              color="textSecondary"
            >{`${percentCompleted}%`}</Typography>
          ) : (
            <CircleCheckIcon size="16px" style={{ color: "#0B8D9B" }} />
          )}
        </Box>
      )}
    </Box>
  );
};
