import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import Finish from "../pages/Finish";
import AppNotification from "../components/AppNotification";
import { AlertProvider } from "../context/alert";
import { Backdrop, CircularProgress, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import UnsubscribePage from "../pages/Unsubscribe";
import { fetchAppData } from "../actions/appData";
import { AppDataState } from "../reducers/appDataState";
import { Feature } from "../reducers/features";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import authenticationRoutes from "../routes/AuthenticationRoutes";
import teamMemberRoutes from "../routes/TeamMemberRoutes";
import workflowRoutes from "../routes/WorkflowRoutes";
import internalAdminRoutes from "../routes/InternalAdminRoutes";
import clientPortalRoutes from "../routes/ClientPortalRoutes";
import { ApiClient, useApi } from "../context/api";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingBackdrop: { backgroundColor: theme.palette.background.default },
  })
);

type RootProps = {
  fetchAppData: (currentUser: any, apolloClient: ApiClient) => Promise<void>;
  appDataState: AppDataState;
  features: Feature;
};

function Root({ fetchAppData, appDataState, features }: RootProps) {
  const location = useLocation();
  const { loading: authLoading, authToken, isImpersonating } = useAuth();
  const { currentUser } = useUser();
  const { apolloClient } = useApi();
  const classes = useStyles();

  useEffect(() => {
    fetchAppData(currentUser, apolloClient);
  }, [fetchAppData, currentUser, isImpersonating, apolloClient]);

  useEffect(() => {
    const w = window as any;
    const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
    if (currentUser && !isImpersonating && w.Intercom && intercomAppId) {
      w.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: intercomAppId,
        name: currentUser.name,
        email: currentUser.email,
        created_at: moment(currentUser.createdAt).unix(),
      });
    }
  }, [currentUser, isImpersonating]);

  useEffect(() => {
    const w = window as any;
    if (w.Intercom) {
      w.Intercom("update");
    }
  }, [location]);

  const RootPathRedirect = () => {
    const { currentUser } = useUser();
    if (!currentUser) {
      return <Navigate to="/login" />;
    } else if (currentUser.internalAdmin) {
      return <Navigate to="/admin" />;
    } else if (currentUser.teamMember) {
      return <Navigate to="/dashboard" />;
    } else {
      return <Navigate to="/portal" />;
    }
  };

  const NotFound = () => {
    const { currentUser } = useUser();

    const FourOhFour = () => {
      // Some day, perhaps, this can show a cute 404 page
      return <Navigate to="/" />;
    };

    if (!currentUser) {
      return <Navigate to="/login" />;
    } else {
      return <FourOhFour />;
    }
  };

  const ImpersonatingAlert = () => {
    const { isImpersonating } = useAuth();
    const { handleStopImpersonate } = useUser();

    if (isImpersonating) {
      return (
        <Alert
          severity="success"
          action={<Button onClick={handleStopImpersonate}>Stop</Button>}
          style={{
            position: "absolute",
            zIndex: 9999,
            bottom: "15px",
            left: "50%",
            transform: "translate(-50%, 0)",
          }}
        >
          You are impersonating {currentUser?.email}
        </Alert>
      );
    } else {
      return <></>;
    }
  };

  const isDoneLoading = useCallback(() => {
    if (authLoading) return false;
    else if (authToken && currentUser) return appDataState === "LOADED";
    else if (!authToken && !currentUser) return true;
    else return false;
  }, [authLoading, authToken, currentUser, appDataState]);

  return isDoneLoading() ? (
    <>
      <AppNotification></AppNotification>
      <AlertProvider>
        <Routes>
          <Route key="/" path="/" element={<RootPathRedirect />} />
          <Route key="/finish" path="/finish" element={<Finish />} />
          <Route
            key="/unsubscribe"
            path="/unsubscribe"
            element={<UnsubscribePage />}
          />
          {authenticationRoutes(currentUser)}
          {teamMemberRoutes(currentUser, features)}
          {workflowRoutes(currentUser, features)}
          {clientPortalRoutes(currentUser)}
          {internalAdminRoutes(currentUser)}
          <Route key="*" path="*" element={<NotFound />} />
        </Routes>
      </AlertProvider>
      <ImpersonatingAlert />
    </>
  ) : (
    <Backdrop open={true} className={classes.loadingBackdrop}>
      <CircularProgress />
    </Backdrop>
  );
}

const mapStateToProps = ({
  appDataState,
  features,
}: {
  appDataState: AppDataState;
  features: Feature;
}) => {
  return { appDataState, features };
};

export default connect(mapStateToProps, { fetchAppData })(Root);
