import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  Theme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useDropzone } from "react-dropzone";
import { useAuth } from "../../context/auth";
import { gql, useLazyQuery } from "@apollo/client";
import axios, { AxiosResponse } from "axios";
import { Deal } from "../../models";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { useAlert } from "../../context/alert";
import { Tab, TabGroup } from "../Tabs";
import { CloudUploadIcon } from "../../icons";
import clsx from "clsx";

const GET_LISTING_PHOTO_URLS = gql`
  query GetPhotoUrls($mlsKey: MlsKey!, $listingId: String!) {
    getMlsListing(mlsKey: $mlsKey, listingId: $listingId) {
      photos
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    propertyPhoto: {
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      minHeight: "85px",
      cursor: "pointer",
    },
    img: {
      width: "100%",
    },
    placeholderImg: {
      width: "150px",
      paddingTop: "22px",
    },
    dndEmpty: {
      color: theme.palette.grey[700],
      padding: "24px 0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      width: "500px",
      height: "300px",
      border: "3px dashed #e0e0e0",
      overflow: "hidden",
      cursor: "pointer",
    },
    activeDnd: {
      color: "#0B8D9B",
      border: "3px dashed #0B8D9B",
    },
  })
);

type PropertyPhotoProps = {
  deal: Deal;
  styles?: any;
};

const PropertyPhoto: FunctionComponent<PropertyPhotoProps> = ({
  deal,
  styles,
}) => {
  const classes = useStyles();

  const [pickerOpen, setPickerOpen] = useState(false);
  const [photoUrl, setPhotoUrl] = useState<string | null>(
    deal.photoUrl || null
  );

  useEffect(() => {
    if (deal.photoUrl) {
      setPhotoUrl(deal.photoUrl);
    }
  }, [deal.photoUrl]);

  return (
    <div
      className={classes.propertyPhoto}
      style={styles}
      onClick={() => setPickerOpen(true)}
    >
      <PhotoPicker
        deal={deal}
        open={pickerOpen}
        onClose={(url?: string) => {
          if (url?.length) setPhotoUrl(url);
          setPickerOpen(false);
        }}
      />
      {photoUrl ? (
        <img className={classes.img} alt={deal.name} src={photoUrl} />
      ) : (
        <img
          src="/images/deal-placeholder-photo.png"
          className={classes.placeholderImg}
          alt="Placeholder"
        />
      )}
    </div>
  );
};

export default PropertyPhoto;

const PhotoPicker = ({
  deal,
  open,
  onClose,
}: {
  deal: Deal;
  open: boolean;
  onClose: (photoUrl?: string) => void;
}) => {
  const { authToken } = useAuth();
  const { showError } = useAlert();
  const classes = useStyles();

  const [uploading, setUploading] = useState(false);
  const [photos, setPhotos] = useState<{ data?: string; url: string }[]>([]);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number | null>(
    null
  );
  const [currentPhotoData, setCurrentPhotoData] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState<"upload" | "listing">("upload");
  const [file, setFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<any>(null);

  const [getListingPhotoUrls, { data: listingPhotoUrlsData }] = useLazyQuery(
    GET_LISTING_PHOTO_URLS
  );

  const close = useCallback(
    (photoUrl?: string) => {
      onClose(photoUrl);
      setPhotos([]);
      setCurrentPhotoIndex(null);
      setCurrentPhotoData(null);
      setCurrentTab("upload");
      setFile(null);
      setFileData(null);
    },
    [onClose]
  );

  /**
   * Get the photo urls for the current listing on open
   */
  useEffect(() => {
    if (open && deal.mlsKey && deal.mlsId) {
      getListingPhotoUrls({
        variables: { mlsKey: deal.mlsKey, listingId: deal.mlsId },
      });
    }
  }, [getListingPhotoUrls, open, deal]);

  /**
   * Process results from getting the listing's photo urls
   */
  useEffect(() => {
    if (listingPhotoUrlsData?.getMlsListing?.photos) {
      setPhotos(
        listingPhotoUrlsData?.getMlsListing?.photos.map((url: string) => ({
          url,
        }))
      );

      // If there are any listing photos, show the first one
      if (listingPhotoUrlsData?.getMlsListing?.photos.length) {
        setCurrentPhotoIndex(0);
      }
    }
  }, [listingPhotoUrlsData]);

  /**
   * Get the current listing photo's data on currentPhotoIndex change
   */
  useEffect(() => {
    if (currentPhotoIndex == null) {
      setCurrentPhotoData(null);
    } else if (currentPhotoIndex < 0) {
      setCurrentPhotoIndex(photos.length + currentPhotoIndex);
    } else if (currentPhotoIndex >= photos.length) {
      setCurrentPhotoIndex(currentPhotoIndex - photos.length);
    } else {
      const currentPhoto = photos[currentPhotoIndex];
      if (currentPhoto.data) {
        setCurrentPhotoData(currentPhoto.data);
      } else {
        setCurrentPhotoData(null);
        axios
          .get<any, AxiosResponse<any>>(currentPhoto.url, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            responseType: "arraybuffer",
          })
          .then((res) => {
            const data = Buffer.from(res.data).toString("base64");
            setPhotos((photos) =>
              photos.map((photo, index) =>
                index === currentPhotoIndex ? { ...photo, data } : photo
              )
            );
            setCurrentPhotoData(data);
          });
      }
    }
  }, [currentPhotoIndex, photos, authToken]);

  const handleDrop = (files: File[]) => {
    if (!files.length) {
      return;
    }

    setFile(files[0]);

    const reader = new FileReader();
    reader.onload = (e) => {
      setFileData(e.target?.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const dropzone = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    noKeyboard: true,
  });

  const renderDropZone = useCallback(() => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          {...dropzone.getRootProps()}
          className={clsx(classes.dndEmpty, {
            [classes.activeDnd]: dropzone.isDragActive,
          })}
          onClick={dropzone.open}
        >
          <input {...dropzone.getInputProps()} />
          {fileData ? (
            <img alt="Selected file" src={fileData} />
          ) : (
            <>
              <CloudUploadIcon size="96px" />
              <p style={{ marginTop: "0" }}>Click or drag &amp; drop a photo</p>
            </>
          )}
        </div>
      </div>
    );
  }, [dropzone, fileData, classes]);

  const renderListingPhoto = useCallback(() => {
    return (
      <div
        style={{
          width: "500px",
          height: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {currentPhotoData ? (
          <img
            alt="The property"
            src={`data:image/jpeg;base64,${currentPhotoData}`}
            height="300px"
          />
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }, [currentPhotoData]);

  const uploadPhoto = useCallback(
    (formData: FormData) => {
      setUploading(true);
      fetch(`${process.env.REACT_APP_API_URL}/propertyPhoto`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(async (res) => res.json())
        .then((res) => close(res.photoUrl))
        .catch(() => showError("There was a problem uploading this photo"))
        .finally(() => setUploading(false));
    },
    [authToken, close, showError]
  );

  const handleUploadLocalFile = useCallback(() => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("dealId", deal.id);
    formData.append("propertyPhoto", file);
    uploadPhoto(formData);
  }, [file, deal.id, uploadPhoto]);

  const handleSelectListingPhoto = useCallback(async () => {
    if (!currentPhotoData) {
      return;
    }

    const formData = new FormData();
    formData.append("dealId", deal.id);
    formData.append(
      "propertyPhoto",
      await (await fetch(`data:image/jpeg;base64,${currentPhotoData}`)).blob()
    );
    uploadPhoto(formData);
  }, [currentPhotoData, deal.id, uploadPhoto]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => close()}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>Select a photo for the deal</DialogTitle>
      <DialogContent>
        {photos.length ? (
          <TabGroup
            value={currentTab}
            style={{ marginBottom: "16px" }}
            variant="scrollable"
            scrollButtons
          >
            <Tab
              label="Upload"
              value="upload"
              onClick={() => setCurrentTab("upload")}
            />
            <Tab
              label="From Listing"
              value="listing"
              onClick={() => setCurrentTab("listing")}
            />
          </TabGroup>
        ) : (
          <></>
        )}

        {currentTab === "upload" && renderDropZone()}
        {currentTab === "listing" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              onClick={() =>
                setCurrentPhotoIndex(
                  (currentPhotoIndex) => (currentPhotoIndex ?? 1) - 1
                )
              }
              disabled={currentPhotoIndex == null}
              size="large"
            >
              <ArrowLeft />
            </IconButton>
            {renderListingPhoto()}
            <IconButton
              onClick={() =>
                setCurrentPhotoIndex(
                  (currentPhotoIndex) => (currentPhotoIndex ?? 0) + 1
                )
              }
              disabled={currentPhotoIndex == null}
              size="large"
            >
              <ArrowRight />
            </IconButton>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {uploading ? (
          <CircularProgress />
        ) : (
          <>
            <SecondaryButton onClick={close}>Cancel</SecondaryButton>
            {currentTab === "upload" && (
              <PrimaryButton onClick={handleUploadLocalFile}>
                Use this photo
              </PrimaryButton>
            )}
            {currentTab === "listing" && (
              <PrimaryButton onClick={handleSelectListingPhoto}>
                Use this photo
              </PrimaryButton>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
