import React, { FunctionComponent } from "react";
import { Box, IconButton, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ChevronLeftIcon } from "../icons";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitleWrapper: {
      height: "100%",
      marginBottom: "16px",
      paddingRight: "1em",
      paddingLeft: "1em",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    left: {
      display: "flex",
      alignItems: "center",
    },
    right: {},
    title: {
      fontSize: "20px",
      margin: 0,
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
    },
    backButton: {
      display: "flex",
    },
  })
);

type StageTitleProps = {
  title?: string | JSX.Element;
  backUrl?: string;
  actions?: JSX.Element;
};

const StagesMobileTitle: FunctionComponent<StageTitleProps> = ({
  title,
  backUrl,
  actions,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (backUrl) {
      navigate(backUrl);
    }
  };

  return (
    <Box className={classes.pageTitleWrapper}>
      <div className={classes.left}>
        {backUrl && (
          <IconButton
            className={classes.backButton}
            onClick={handleBackClick}
            size="large"
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        <div>
          <h4 className={classes.title}>{title}</h4>
        </div>
      </div>

      <div className={classes.right}>{actions}</div>
    </Box>
  );
};

export default StagesMobileTitle;
