import React, { useState, Dispatch } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import NotesList from "../notes/List";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect } from "react";

const StyledAccordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const StyledAccordionSummary = withStyles({
  root: {
    padding: 0,
    marginBottom: -1,
    minHeight: 0,
    "&$expanded": {
      minHeight: 0,
    },
  },
  content: {
    margin: "0",
    display: "flex",
    alignItems: "center",
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(AccordionSummary);

const StyledChevronRightIcon = withStyles({
  root: {
    fontSize: "17px",
    transform: ({ expanded }: any) =>
      expanded === "true" ? "rotate(90deg)" : "rotate(0deg)",
    transition: "all 0.2s ease-in-out",
  },
})(ChevronRightIcon);

interface TaskNotesProps {
  task: any;
  dispatch?: Dispatch<any>;
  dispatchType?: any;
}

const TaskNotes = ({ task, dispatch, dispatchType }: TaskNotesProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [taskNotes, setTaskNotes] = useState<any>([]);

  useEffect(() => {
    if (task.notes?.length > 0) {
      setTaskNotes(task.notes);
    }
  }, [task.notes]);

  const onNoteEdited = (noteToEdit: any) => {
    setTaskNotes(
      taskNotes.map((note: any) => {
        if (noteToEdit.id === note.id) {
          note.body = noteToEdit.body;
        }
        return note;
      })
    );
  };

  const onNoteDeleted = (noteToDelete: any) => {
    if (dispatch && dispatchType) {
      dispatch({
        type: dispatchType.REMOVE_TASK_NOTE,
        note: noteToDelete,
      });
    }

    setTaskNotes(taskNotes.filter((note: any) => noteToDelete.id !== note.id));
  };

  const onAccordionChange = (e: any, expanded: boolean) => {
    setExpanded(expanded);
  };
  return (
    <>
      {taskNotes.length > 0 ? (
        <StyledAccordion elevation={0} onChange={onAccordionChange}>
          <StyledAccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ paddingLeft: "11px", margin: "0" }}
            >
              {taskNotes.length} {`Note${taskNotes.length > 1 ? "s" : ""}`}
            </Typography>
            <StyledChevronRightIcon
              color="primary"
              expanded={expanded ? "true" : "false"}
            />
          </StyledAccordionSummary>
          <AccordionDetails>
            <NotesList
              notes={taskNotes}
              renderedontasks={true}
              onNoteEdited={onNoteEdited}
              onNoteDeleted={onNoteDeleted}
            />
          </AccordionDetails>
        </StyledAccordion>
      ) : (
        <></>
      )}
    </>
  );
};

export default TaskNotes;
