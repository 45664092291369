import React, { useEffect, useState } from "react";
import { List, Paper, Theme, Divider, Typography, Link } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import ContactNameWithAvatar from "../contacts/ContactNameWithAvatar";
import EditNote from "./EditNote";
import moment from "moment";
import { TrashIcon as DeleteIcon, PencilIcon as EditIcon } from "../../icons";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { HoverActionContainer, HoverActions } from "../HoverActions";
import ActionIconButton from "../ActionIconButton";
import { sourceToDomain } from "../../helpers";
import { Note } from "../../models";
import Linkify from "react-linkify";

const NotesList = withStyles({
  root: { padding: "0", width: "100%" },
})(List);

const NotesListItem = withStyles({
  root: {
    width: "auto",
    border: "none",
    boxShadow: "none !important",
    display: "flex",
    margin: ({ renderedontasks }: any) =>
      renderedontasks ? "25px" : "25px 41px",
  },
})(Paper);

const useStyles = makeStyles((theme: Theme) => ({
  noNotes: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "11px",
    color: "#747b88",
  },
}));

const DELETE_NOTE = gql`
  mutation DeleteNote($id: ID!) {
    deleteNote(id: $id)
  }
`;

export const NoteAuthor = ({ note }: { note: any }) => {
  let name = "Unknown";
  let avatarUrl = "";
  if (note.source && note.source !== "user") {
    const domain = sourceToDomain(note.source);
    if (domain) {
      name = note.source;
      avatarUrl = `/images/integrations/avatars/${domain}.png`;
    }
  } else if (note.author) {
    name = note.author.name;
    avatarUrl = note.author.avatarUrl;
  }
  return (
    <ContactNameWithAvatar hideName name={name} avatarUrls={[avatarUrl]} />
  );
};

export default function NoteList({
  notes: initialNotes,
  reportView = false,
  renderedontasks = false,
  onNoteDeleted = () => {},
  onNoteEdited = () => {},
}: {
  notes: Note[];
  reportView?: boolean;
  onNoteDeleted?: (note: Note) => any;
  onNoteEdited?: (note: Note) => any;
  renderedontasks?: boolean;
}) {
  const [notes, setNotes] = useState<any[]>([]);
  const [noteToEdit, setNoteToEdit] = useState(null);
  const [editingNote, setEditingNote] = useState(false);

  const [deleteNote] = useMutation(DELETE_NOTE);

  useEffect(() => setNotes(initialNotes), [initialNotes]);
  const classes = useStyles();

  if (!notes || notes.length === 0) {
    return <p className={classes.noNotes}>No notes to display</p>;
  }

  function formatDateTime(dateTimeString: string): string {
    return new Date(dateTimeString).toLocaleString();
  }

  const componentDecorator = (href: any, text: any, key: any) => (
    <Link href={href} key={key} target="_blank" rel="noreferrer">
      {text}
    </Link>
  );

  const handleDelete = (noteToDelete: any) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (confirmed) {
      deleteNote({
        variables: {
          id: noteToDelete.id,
        },
      })
        .then((response: any) => {
          if (response.data.deleteNote) {
            setNotes((notes) =>
              notes.filter((note) => note.id !== noteToDelete.id)
            );
          } else {
            console.log("There was a problem deleting the note");
          }
        })
        .then(() => onNoteDeleted(noteToDelete))
        .catch((e: Error) =>
          console.log("There was a problem deleting the note:", e.message)
        );
    }
  };

  const handleEdit = (noteToEdit: any) => {
    setEditingNote(true);
    setNoteToEdit(noteToEdit);
  };

  return (
    <>
      <NotesList>
        {notes.map((note: any, index: number) => (
          <HoverActionContainer
            key={note.id}
            style={{
              display: "flex",
              flexDirection: "column",
              width: renderedontasks ? "85%" : "100%",
            }}
          >
            {index > 0 ? <Divider /> : <></>}

            <NotesListItem
              renderedontasks={renderedontasks ? "true" : "false"}
              square
            >
              <div style={{ margin: "0 12px 0 0" }}>
                <NoteAuthor note={note} />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "5px 0 0 0",
                }}
                title={`${note.author?.name || note.source} - ${formatDateTime(
                  note.createdAt
                )}`}
              >
                <Typography component="h4" style={{ fontWeight: 600 }}>
                  {note.author?.name || note.source}
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ fontWeight: "lighter" }}
                >
                  {moment(note.createdAt).calendar()}
                </Typography>
                <Typography variant="body1">
                  {/*
                    // @ts-ignore */}
                  <Linkify componentDecorator={componentDecorator}>
                    {note.body
                      ?.split("\n")
                      .map((line: string, index: number) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                  </Linkify>
                </Typography>
              </div>
            </NotesListItem>
            {!reportView && (
              <HoverActions>
                <ActionIconButton
                  aria-label="edit"
                  title="Edit note"
                  onClick={() => handleEdit(note)}
                  icon={EditIcon}
                  style={{ height: "16px", width: "16px", margin: "0 12px" }}
                />
                <ActionIconButton
                  aria-label="delete"
                  title="Delete note"
                  onClick={() => handleDelete(note)}
                  icon={DeleteIcon}
                  style={{ height: "16px", width: "16px", margin: "0 12px" }}
                />
              </HoverActions>
            )}
          </HoverActionContainer>
        ))}
      </NotesList>
      <EditNote
        open={editingNote}
        note={noteToEdit}
        setOpen={setEditingNote}
        onNoteEdited={onNoteEdited}
      />
    </>
  );
}
