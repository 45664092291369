import React, { useState, useContext, useMemo, useEffect } from "react";
import { useMutation, gql, useLazyQuery, useQuery } from "@apollo/client";
import { CircularProgress, Typography, Stack, Box } from "@mui/material";
import { useAlert } from "../../../../../context/alert";
import FieldHelper, { cleanFields } from "../../../../../helpers/fields";
import DetailCard from "../../../../../components/DetailCard";
import { DealViewActionType, dealViewStore } from "../../context";
import DealDetailCard from "./DealDetailCard";
import ActionIconButton from "../../../../../components/ActionIconButton";
import {
  DragIndicator as DragIndicatorIcon,
  Save,
  Sync,
} from "@mui/icons-material";
import { PencilIcon } from "../../../../../icons";
import { useApi } from "../../../../../context/api";
import { pullMlsListing } from "../../../../../api/mls";
import { findMls } from "../../../../../reducers/mlses";
import {
  Event,
  Field,
  FieldGroup,
  Mls,
  TeamMember,
} from "../../../../../models";
import { connect } from "react-redux";
import {
  GET_DEAL,
  GET_GROUPS_AND_FIELDS,
  UPDATE_DEAL_TYPE_FIELD_GROUP_SORT,
} from "../../../../../api/graphql";
import { ReactSortable } from "react-sortablejs";

const useDealUpdate = (dealId: string) => {
  const { dispatch } = useContext(dealViewStore);
  const [getDeal, { data }] = useLazyQuery(GET_DEAL);
  const handleGetDeal = () => {
    getDeal({
      variables: {
        id: dealId,
      },
    });
  };

  React.useEffect(() => {
    if (!data) {
      return;
    } else if (data?.deal) {
      dispatch({
        type: DealViewActionType.SET_TASKS,
        tasks: data?.deal?.tasks,
      });
    }
  }, [data, dispatch]);

  const [updateDeal] = useMutation(
    gql`
      mutation UpdateDeal($input: UpdateDealInput!) {
        updateDeal(input: $input) {
          id
        }
      }
    `,
    {
      onCompleted: handleGetDeal,
    }
  );

  return {
    updateDeal,
  };
};

type DealDetailGroup = FieldGroup & {
  loadingFromMls?: boolean;
};

const DealDetail = ({
  findMls,
  isLeftPane,
}: {
  findMls: (mlsKey: string) => Mls | null;
  isLeftPane?: boolean;
}) => {
  const { showError, showSuccess } = useAlert();
  const { apolloClient } = useApi();
  const [groups, setGroups] = useState<DealDetailGroup[]>([]);
  const [favoriteFields, setFavoriteFields] = useState<Field[]>([]);
  const [expanded, setExpanded] = useState(true);

  const {
    state: { formFields = [], deal },
    dispatch,
  } = useContext(dealViewStore);

  const [updateDealTypeFieldGroupSort] = useMutation(
    UPDATE_DEAL_TYPE_FIELD_GROUP_SORT
  );

  const setOriginalSystemFields = (fields: Field[]) => {
    let systemFields: any = [];
    let customFields: any = [];
    fields.map((field) => {
      if (field.isCustom) {
        return customFields.push(field);
      } else {
        return systemFields.push(field);
      }
    });

    dispatch({
      type: DealViewActionType.SET_FORM_FIELDS,
      formFields: {
        systemFields: systemFields ?? [],
        customFields: customFields ?? [],
      },
    });
  };

  const { data } = useQuery(GET_GROUPS_AND_FIELDS, {
    variables: {
      tableName: "deal",
      dealTypeId: deal?.dealType?.id || null,
    },
    onCompleted: (res) => {
      const fields = res.getFieldGroups
        .map((group: any) => {
          return group.fields;
        })
        .flat();
      setOriginalSystemFields(fields);
    },
  });

  const favoriteGroup = useMemo(() => {
    return {
      id: "favorites",
      isDefault: false,
      name: "favorites",
      fields: favoriteFields || [],
    };
  }, [favoriteFields]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setGroups(data?.getFieldGroups);
    const favoriteFields: Field[] = [];
    data?.getFieldGroups?.map((group: any) => {
      return group.fields.map((field: any) => {
        if (field.isFavorite) {
          return favoriteFields.push(field);
        } else return null;
      });
    });
    const sortedFields = favoriteFields.sort(
      (a, b) => a.favoriteSort - b.favoriteSort
    );
    setFavoriteFields(sortedFields);
  }, [data, setGroups]);

  const initialGroups = useMemo(() => {
    return groups?.reduce((acc, group) => {
      acc[group.name] = false;
      return acc;
    }, {} as { [key: string]: boolean });
  }, [groups]);

  const [editing, setEditing] = useState(initialGroups);

  const { updateDeal } = useDealUpdate(deal.id);

  const handleUpdateFromMls = (cardName: string) => {
    setGroups((groups) =>
      groups.map((group) => {
        if (group.name === cardName) {
          return { ...group, loadingFromMls: true };
        } else {
          return { ...group, loadingFromMls: false };
        }
      })
    );
    pullMlsListing(apolloClient, deal.mlsKey, deal.mlsId)
      .then((listing) => {
        if (listing) {
          const updateFields: any = {};
          const group = groups.find((group) => group.name === cardName);
          group?.fields.forEach((field: any) => {
            if (listing[field.id as keyof typeof listing] != null) {
              updateFields[field.id] =
                listing[field.id as keyof typeof listing];
            }
          });
          dispatch({
            type: DealViewActionType.SET_DEAL,
            deal: { ...deal, ...updateFields },
          });
          showSuccess(
            "We found your listing! We filled in some details in the form."
          );
        } else {
          showError(
            "We couldn't find that listing. Is the Listing ID correct?"
          );
        }
      })
      .catch((err) =>
        showError(
          "Sorry, we had some trouble finding your listing. You can try again later."
        )
      )
      .finally(() =>
        setGroups((groups) =>
          groups.map((group) => {
            if (group.name === cardName) {
              return { ...group, loadingFromMls: false };
            } else {
              return { ...group, loadingFromMls: false };
            }
          })
        )
      );
  };

  type Participant = TeamMember & { __typename?: string };

  const handleSave = () => {
    if (deal && formFields.systemFields) {
      const formUpdates: any = { id: deal.id };
      formFields.systemFields.forEach((field: any) => {
        // @ts-ignore
        formUpdates[field.id] = deal[field.id];
        /**
         * If the field is a date field and it includes time, we'll also
         * look to see if we have a full datetime field and send that as well.
         */
        if (
          field.id.endsWith("Date") &&
          (field.settings.includeTime || field.settings.eventType)
        ) {
          const dateTimeKey = field.id.replace("Date", "At");
          if (dateTimeKey) {
            formUpdates[dateTimeKey] = deal[dateTimeKey] || null;
          }
        }
        if (
          formUpdates[field.id] === undefined ||
          formUpdates[field.id] === null
        ) {
          formUpdates[field.id] = FieldHelper.getDefaultValue(field);
        }
      });

      if (formFields.customFields.length > 0) {
        const customFieldUpdates: any[] = [];

        formFields.customFields.forEach((field: any) => {
          // field could either be a CustomField or CustomFieldValue, we'll look for
          // a CustomFieldValue
          const isCustomFieldValue = field.__typename === "CustomFieldValue";
          let customFieldValue;
          if (isCustomFieldValue) {
            customFieldValue = field;
          } else {
            if (deal && deal.customFields) {
              customFieldValue = deal.customFields.find(
                (cfv: any) => cfv.customFieldId === field.id
              );
            }
          }

          let cfv: any = {};
          if (customFieldValue) {
            cfv.id = customFieldValue.id;
            cfv.customFieldId = customFieldValue.customFieldId;
            cfv.value = FieldHelper.getCustomFieldValue(
              field,
              customFieldValue
            );
          } else {
            cfv.customFieldId = field.id;
          }
          if (cfv.value === undefined) {
            cfv.value = FieldHelper.getDefaultValue(field);
          }

          customFieldUpdates.push(cfv);
        });
        formUpdates.customFields = customFieldUpdates;
      }
      if (deal.events) {
        formUpdates.events = deal.events.map((event: Event) => ({
          ...event,
          participants: event?.participants?.map((participant: Participant) => {
            if (participant?.__typename === "TeamMember") {
              return {
                teamMemberId: participant.id,
              };
            } else if (participant?.__typename === "Contact") {
              return {
                contactId: participant.id,
              };
            } else {
              return participant;
            }
          }),
        }));
      }

      const input = Object.assign({}, cleanFields(formUpdates));
      updateDeal({
        variables: {
          input,
        },
      })
        .then((res) => {
          showSuccess("Deal saved");
        })
        .catch((err) => {
          showError("An error occurred when saving the deal");
        });
    }
  };

  const dealMls = useMemo(() => findMls(deal.mlsKey), [deal, findMls]);

  const SyncProgress = () => {
    return <CircularProgress size="24px" style={{ margin: "4px 12px 0 0" }} />;
  };

  const LoadMlsButton = ({ cardName }: { cardName: string }) => {
    const group = groups.find((group) => group.name === cardName);
    if (group?.loadingFromMls) {
      return <SyncProgress />;
    } else if (dealMls?.connected && deal.mlsId) {
      return (
        <ActionIconButton
          onClick={() => handleUpdateFromMls(cardName)}
          icon={Sync}
          alt="Sync address from MLS"
          title="Sync from MLS"
        />
      );
    } else {
      return <></>;
    }
  };

  const handleSetList = (list: any[], sortable: any) => {
    const oldGroupIds = groups.map((group) => group.id);
    const newGroupIds = list.map((group) => group.id);
    const needsUpdate = oldGroupIds.some(
      (id, index) => id !== newGroupIds[index]
    );
    if (!needsUpdate) {
      return;
    }
    let input = {
      dealTypeId: deal.dealType?.id,
      fieldGroupIds: newGroupIds,
    };

    updateDealTypeFieldGroupSort({
      variables: {
        input,
      },
      refetchQueries: ["GetGroupsAndFields"],
    });

    setGroups(list);
  };

  const leftPaneRenderTypes = useMemo(() => {
    return favoriteFields?.map((field: Field) => {
      return field.id;
    });
  }, [favoriteFields]);

  const isFavoriteFields = favoriteFields.length > 0;
  return (
    <ReactSortable
      list={groups || []}
      setList={handleSetList}
      animation={200}
      swapThreshold={0.7}
      group={{
        name: "fieldGroups",
        put: ["fieldGroups"],
      }}
      on
      dragoverBubble
      handle=".card-drag-handle"
    >
      {isLeftPane ? (
        <>
          <DetailCard
            collapsible
            title={<Typography variant="h5">Favorites</Typography>}
            divider={false}
            className="favorites-card"
            onChange={() => setExpanded(!expanded)}
            action={
              isFavoriteFields && expanded ? (
                !editing["favorites"] ? (
                  <ActionIconButton
                    onClick={() => setEditing({ ...editing, favorites: true })}
                    icon={PencilIcon}
                  />
                ) : (
                  <div style={{ display: "flex" }}>
                    <ActionIconButton
                      onClick={() => {
                        handleSave();
                        setEditing({ ...editing, favorites: false });
                      }}
                      icon={Save}
                    />
                  </div>
                )
              ) : null
            }
          >
            {!isFavoriteFields && (
              <Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  textAlign="center"
                >
                  Drag fields here to set them as a favorites
                </Typography>
              </Box>
            )}
            <DealDetailCard
              deal={deal}
              group={favoriteGroup}
              fields={favoriteFields || []}
              renderTypes={leftPaneRenderTypes}
              editing={editing["favorites"]}
              setGroups={setGroups}
              isLeftPane
              favoriteFields={favoriteFields}
              setFavoriteFields={setFavoriteFields}
            />
          </DetailCard>
        </>
      ) : (
        groups.map((group) => {
          const renderTypes = group.fields.map((field: Field) => {
            return field.id;
          });
          return (
            <DetailCard
              collapsible={false}
              title={
                <Stack
                  direction="row"
                  sx={{
                    ".card-drag-handle": {
                      visibility: "hidden",
                      cursor: "grab",
                    },
                    ":hover > .card-drag-handle": {
                      visibility: "visible",
                      cursor: "grab",
                    },
                  }}
                  spacing={1}
                >
                  <DragIndicatorIcon
                    className="card-drag-handle"
                    fontSize="small"
                    style={{
                      color: "#6b7280",
                    }}
                  />
                  <Typography variant="h5">{group.name}</Typography>
                </Stack>
              }
              action={
                !editing[group.name as any] ? (
                  <ActionIconButton
                    onClick={() =>
                      setEditing({ ...editing, [group.name]: true })
                    }
                    icon={PencilIcon}
                  />
                ) : (
                  <div style={{ display: "flex" }}>
                    <LoadMlsButton cardName={group.name} />
                    <ActionIconButton
                      onClick={() => {
                        handleSave();
                        setEditing({ ...editing, [group.name]: false });
                      }}
                      icon={Save}
                    />
                  </div>
                )
              }
            >
              <DealDetailCard
                deal={deal}
                group={group}
                fields={group.fields}
                renderTypes={group.name !== "customFields" ? renderTypes : []}
                editing={editing[group.name]}
                setGroups={setGroups}
              />
            </DetailCard>
          );
        })
      )}
    </ReactSortable>
  );
};

const mapStateToProps = ({ mlses }: { mlses: Mls[] }) => ({
  findMls: findMls({ mlses }),
});
export default connect(mapStateToProps)(DealDetail);
