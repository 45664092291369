import React, { FunctionComponent, useState, useEffect } from "react";

import SplitLayout from "../../layouts/SplitLayout";
import LeftPane from "../../components/LeftPane";
import SettingsSubNav from "../../components/SettingsSubNav";
import SettingsSelect from "../../components/SettingsSelectMobile";
import DetailPane from "../../components/DetailPane";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle";
import { Add as AddIcon } from "@mui/icons-material";
import { PrimaryButton } from "../../components/buttons";
import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link, useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { TrashIcon as DeleteIcon } from "../../icons";
import { PencilIcon } from "../../icons";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import clsx from "clsx";
import moment from "moment";
import { useAlert } from "../../context/alert";
import { useUser } from "../../context/user";

const useStyles = makeStyles((theme) => ({
  enrolledLink: {
    textDecoration: "none",
    color: theme.palette.common.black,
    "&:visited": {
      color: theme.palette.common.black,
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  statusIndicator: {
    color: "#E8E2E1",
  },
  on: {
    color: "#7BDA5E",
  },
}));

type WorkflowListProps = {};
const WorkflowListPage: FunctionComponent<
  WorkflowListProps
> = (): JSX.Element => {
  const { hasPermission } = useUser();
  const classes = useStyles();
  const { showSuccess, showError } = useAlert();
  const [workflows, setWorkflows] = useState<Array<any> | undefined>();
  const { data, loading } = useQuery(gql`
    query GetWorkflows {
      getWorkflows {
        id
        name
        enabled
        activeEnrolleeCount
        createdAt
        updatedAt
      }
    }
  `);

  const [deleteWorkflow] = useMutation(gql`
    mutation DeleteWorkflow($id: ID!) {
      deleteWorkflow(id: $id)
    }
  `);

  const navigate = useNavigate();

  useEffect(() => {
    if (data?.getWorkflows) {
      setWorkflows(data.getWorkflows);
    }
  }, [data]);

  const canEditWorkflows = () => hasPermission("settings", "limited");

  const handleAddClick = () => {
    navigate("/settings/workflows/new");
  };

  const handleDelete = (e: any, workflow: any) => {
    if (
      !window.confirm(`Do you want to delete the workflow "${workflow.name}"?`)
    ) {
      return;
    }
    deleteWorkflow({ variables: { id: workflow.id } })
      .then(() => {
        setWorkflows(workflows?.filter((w: any) => w.id !== workflow.id));
        showSuccess(`The workflow "${workflow.name}" was deleted`);
      })
      .catch(() => {
        showError("An error occurred when deleting the workflow");
      });
  };

  return (
    <SplitLayout loading={loading}>
      <LeftPane width={224}>
        <SettingsSubNav />
        <SettingsSelect label="" defaultValue="/settings/workflows" />
      </LeftPane>
      <DetailPane>
        <PageTitle
          title={"Workflows"}
          subtitle={
            "Create workflows to automate tasks and emails for leads, and past clients"
          }
          actions={
            <PrimaryButton
              height="short"
              onClick={handleAddClick}
              disabled={!canEditWorkflows()}
            >
              <AddIcon
                style={{
                  width: "13px",
                  height: "13px",
                  marginRight: "4px",
                }}
              />
              <span>New Workflow</span>
            </PrimaryButton>
          }
        />
        <Paper>
          {workflows?.length === 0 && (
            <Box p={4} textAlign="center">
              <Typography>
                You don't have any workflows.{" "}
                <Link to={`/settings/workflows/new`}>Create a workflow</Link> to
                get started.
              </Typography>
            </Box>
          )}

          {workflows && workflows.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell>Enrolled</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workflows.map((workflow: any) => (
                    <TableRow key={workflow.id}>
                      <TableCell>{workflow.name}</TableCell>
                      <TableCell>
                        {moment(workflow.createdAt).format("MMM D, YYYY")}
                      </TableCell>
                      <TableCell>
                        <Link
                          className={classes.enrolledLink}
                          to={`/settings/workflows/${workflow.id}/enrollees`}
                        >
                          {workflow.activeEnrolleeCount}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FiberManualRecordIcon
                            className={clsx({
                              [classes.statusIndicator]: true,
                              [classes.on]: workflow.enabled,
                            })}
                          />
                          {workflow.enabled ? "On" : "Off"}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          className="table-row-action"
                          component={Link}
                          to={`/settings/workflows/${workflow.id}`}
                          size="large"
                        >
                          <PencilIcon size="20px" />
                        </IconButton>
                        <IconButton
                          className="table-row-action"
                          onClick={(e) => handleDelete(e, workflow)}
                          disabled={!canEditWorkflows()}
                          size="large"
                        >
                          <DeleteIcon size="20px" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </DetailPane>
    </SplitLayout>
  );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(WorkflowListPage);
