import { gql } from "@apollo/client";
import { ApiClient } from "../context/api";
import { STAGES } from "../reducers/stages";
import {
  StageSendableInput,
  FRAGEMNT_STAGE_SENDABLE_FIELDS,
} from "./stageEmails";
const CREATE_STAGE_SMS = gql`
  mutation CreateStageSMS($input: CreateStageSmsInput!) {
    createStageSms(input: $input) {
      id
      smsTemplateId
      sort
      ...stageSendableFields
    }
  }
  ${FRAGEMNT_STAGE_SENDABLE_FIELDS}
`;

const UPDATE_STAGE_SMS = gql`
  mutation UpdateStageSms($input: UpdateStageSmsInput!) {
    updateStageSms(input: $input) {
      id
      smsTemplateId
      sort
      ...stageSendableFields
    }
  }
  ${FRAGEMNT_STAGE_SENDABLE_FIELDS}
`;

const DELETE_STAGE_SMS = gql`
  mutation DeleteStageSms($id: ID!) {
    deleteStageSms(id: $id)
  }
`;

export type CreateStageSmsInput = {
  stageId: string;
  smsTemplateId: string;
} & StageSendableInput;

export type UpdateStageSmsInput = {
  id: string;
  smsTemplateId?: string;
} & StageSendableInput;

export const createStageSms =
  (stageSms: CreateStageSmsInput, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: CREATE_STAGE_SMS,
      variables: { input: { ...stageSms } },
    });
    dispatch({
      type: STAGES.ADD_SMS,
      stageSms: response.data.createStageSms,
      stageId: stageSms.stageId,
    });
  };

export const deleteStageSms =
  (id: string, stageId: string, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    await apolloClient.mutate({
      mutation: DELETE_STAGE_SMS,
      variables: { id },
    });
    dispatch({ type: STAGES.REMOVE_SMS, id, stageId });
  };

export const updateStageSms =
  (stageSms: UpdateStageSmsInput, stageId: string, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_STAGE_SMS,
      variables: {
        input: stageSms,
      },
    });

    const updatedStageSms = response.data.updateStageSms;
    dispatch({
      type: STAGES.UPDATE_SMS,
      stageSms: updatedStageSms,
      stageId,
    });
  };
