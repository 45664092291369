import React, {
  useState,
  FunctionComponent,
  ChangeEvent,
  useEffect,
} from "react";
import { TextField, Grid } from "@mui/material";

interface Address {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

type AddressFieldProps = {
  value?: Address;
  onChange?: (address: Address) => void;
};

const AddressField: FunctionComponent<AddressFieldProps> = ({
  value,
  onChange,
}) => {
  const [address, setAddress] = useState<Address>({
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
  });
  useEffect(() => {
    if (value) {
      setAddress({
        street: value.street || "",
        street2: value.street2 || "",
        city: value.city || "",
        state: value.state || "",
        zip: value.zip || "",
      });
    }
  }, [value]);

  const handleChange = (e: ChangeEvent<any>) => {
    const value = { ...address, [e.target.name]: e.target.value };
    setAddress(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          name="street"
          label="Address"
          fullWidth
          value={address.street || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          name="street2"
          label="Address Line 2"
          fullWidth
          value={address.street2 || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="standard"
          name="city"
          label="City"
          fullWidth
          value={address.city || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          variant="standard"
          name="state"
          label="State"
          fullWidth
          value={address.state || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          variant="standard"
          name="zip"
          label="Zip"
          fullWidth
          value={address.zip || ""}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default AddressField;
