import React from "react";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

export default function TimezoneSelect(props: any) {
  const handleChange = (e: any, value: string | null) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };
  return (
    <Autocomplete
      value={props.value}
      style={props.style}
      className={props.className}
      onChange={handleChange}
      options={moment.tz.names()}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label="Timezone"
          name={props.name || "timezone"}
          variant="standard"
        />
      )}
    ></Autocomplete>
  );
}
