import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Popover,
  Box,
  Typography,
} from "@mui/material";
import { useApolloClient, useQuery } from "@apollo/client";
import { SEND_TEST_EMAIL, GET_DEALS } from "../../api/graphql";
import { OutlineButton, PrimaryButton } from "../buttons";
import { useUser } from "../../context/user";
import { Alert } from "@mui/material";

interface SendEmailModalProps {
  emailTemplate: any;
}

export const SendTestEmailModal = ({ emailTemplate }: SendEmailModalProps) => {
  const apollo = useApolloClient();
  const { currentUser } = useUser();
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [deals, setDeals] = useState<Array<any>>([]);
  const [selectedDeal, setSelectedDeal] = useState("");
  const [anchorPosition, setAnchorPosition] = useState<any>(null);

  useQuery(GET_DEALS, {
    onCompleted: (data) => setDeals(data.getDeals.deals),
  });

  const handleSendEmail = () => {
    const params: any = {
      emailTemplateId: emailTemplate.id,
      dealId: selectedDeal,
      recipients: { contactId: currentUser.id },
      subject: emailTemplate.subject,
      body: {
        html: emailTemplate.html,
        plaintext: emailTemplate.plaintext,
      },
    };
    apollo
      .mutate({
        mutation: SEND_TEST_EMAIL,
        variables: {
          input: params,
        },
      })
      .then(() => {
        setEmailSent(true);
      })
      .catch(() => {
        setEmailError(true);
      })
      .finally(() => {
        setTimeout(function () {
          setAnchorPosition(null);
          setEmailSent(false);
          setEmailError(false);
        }, 1000 * 2);
      });
  };

  const handleClick = (e: any) => {
    const { top, left } = e.currentTarget.getBoundingClientRect();
    setAnchorPosition({ top, left });
  };

  return (
    <>
      <OutlineButton onClick={handleClick}>Send Test Email</OutlineButton>
      <Popover
        open={Boolean(anchorPosition)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        onClose={() => {
          setAnchorPosition(null);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="Send a Test Email"
        aria-describedby="A version of this email template will be sent to your email
      address"
      >
        {emailError && (
          <Alert severity="error">
            An error occurred when sending the test email
          </Alert>
        )}

        {emailSent && (
          <Alert severity="success">
            A test email was sent to {currentUser.email}
          </Alert>
        )}

        {!emailSent && !emailError && (
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  We'll send a test email to you using the following deal:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="select-a-deal">Select Deal</InputLabel>
                  <Select
                    labelId="select-a-deal"
                    id="select-a-deal"
                    label="Select a Deal"
                    value={selectedDeal}
                    onChange={(e: any) => setSelectedDeal(e.target.value)}
                  >
                    {deals.map((deal: any) => (
                      <MenuItem key={deal.id} value={deal.id}>
                        {deal.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <PrimaryButton
                  fullWidth
                  disabled={!selectedDeal}
                  onClick={() => handleSendEmail()}
                >
                  Send
                </PrimaryButton>
              </Grid>
            </Grid>
          </Box>
        )}
      </Popover>
    </>
  );
};
