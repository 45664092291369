import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  Link,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_CALENDAR_EVENTS } from "../../api/graphql";
import { Box } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";

interface EventListProps {
  beforeDate: Date;
  limitHeight?: Boolean;
}

interface CalendarEvent {
  id: string;
  name: string;
  secondaryText: string;
  start: Date;
  title: string;
  url: string;
  eventType: string;
  allDay: boolean;
}

const EventList = ({ beforeDate, limitHeight = false }: EventListProps) => {
  const [events, setEvents] = useState<Array<CalendarEvent>>([]);
  const [dateRange, setDateRange] = useState<any>({
    start: new Date(),
    end: beforeDate,
  });

  const { refetch } = useQuery(GET_CALENDAR_EVENTS, {
    variables: {
      start: dateRange.start,
      end: dateRange.end,
    },
    onCompleted: (res) => setEvents(res.getCalendarEvents),
  });

  const renderEvents = events
    .sort((a: any, b: any) => {
      return ((new Date(a.start).getTime() as any) -
        new Date(b.start).getTime()) as any;
    })
    .filter((calEvent: CalendarEvent) => !calEvent.eventType.includes("task"));

  useEffect(() => {
    setDateRange((dateRange: any) => ({ ...dateRange, end: beforeDate }));
    refetch();
  }, [refetch, beforeDate]);

  const SecondaryListItem = ({ event }: { event: any }) => {
    const start = event.allDay ? moment.utc(event.start) : moment(event.start);
    const format = event.allDay ? "ddd" : "ddd h:mma";
    return (
      <>
        <Typography sx={{ fontSize: "12px" }}>
          {start.format(format)}
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>
          {event.url ? (
            <Link to={event.url} component={RouterLink}>
              {event.secondaryText}
            </Link>
          ) : (
            event.secondaryText
          )}
        </Typography>
      </>
    );
  };

  return (
    <div
      style={{
        maxHeight: limitHeight ? "500px" : "unset",
        width: "100%",
        display: "flex",
        overflow: "auto",
      }}
    >
      {renderEvents.length > 0 ? (
        <List style={{ width: "100%", padding: "0 20px" }}>
          {renderEvents.map((calEvent: CalendarEvent, i: any) => {
            const eventStartMoment = calEvent.allDay
              ? moment.utc(calEvent.start)
              : moment(calEvent.start);
            return (
              <React.Fragment key={i}>
                <ListItem key={calEvent.id}>
                  <ListItemIcon>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="h4">
                        {eventStartMoment.format("DD")}
                      </Typography>
                      <Typography>{eventStartMoment.format("MMM")}</Typography>
                    </Box>
                  </ListItemIcon>
                  <ListItemText
                    secondary={<SecondaryListItem event={calEvent} />}
                  >
                    <Typography variant="body2" sx={{ fontSize: "14px" }}>
                      {calEvent.title}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      ) : (
        <Box
          sx={{
            color: "#747b88",
            fontSize: "11px",
            textAlign: "center",
            padding: "11px",
            width: "100%",
          }}
          p={4}
        >
          <Typography>You don't have any events</Typography>
        </Box>
      )}
    </div>
  );
};

export default EventList;
