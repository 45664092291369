import React, { ChangeEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import { useCombineContactAndTeamMembers } from "../hooks/useCombineContactAndTeamMembers";

import { Deal, TeamMember } from "../models";

type TeamMemberSelectMultipleProps = {
  onChange: (event: ChangeEvent<any>, teamMembers: any) => void;
  value: any[];
  variant?: any;
  deal?: Deal;
  style?: any;
  label?: string;
  limitTags?: number;
  placeholder?: string;
  teamMembers?: TeamMember[];
};

export default function TeamMemberSelectMultiple({
  onChange,
  value,
  deal,
  variant = "standard",
  teamMembers: teamMembersProp,
  placeholder = "Search for a team member &hellip;",
}: TeamMemberSelectMultipleProps) {
  const { combinedOptions } = useCombineContactAndTeamMembers(
    deal,
    teamMembersProp
  );

  const handleChange = (e: ChangeEvent<any>, value: any) => {
    e.preventDefault();
    onChange(e, value);
  };

  return (
    <Autocomplete
      multiple
      id="selected-teammember-autocomplete"
      options={combinedOptions || []}
      value={value}
      onChange={handleChange}
      getOptionLabel={(option: any) => option.name || ""}
      renderTags={() => null}
      disableCloseOnSelect
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      renderOption={(props, option: any, { selected }) => {
        const contactRole =
          option?.__typename === "Contact" ? deal?.dealType?.clientNoun : null;
        return (
          <Box component="li" {...props}>
            <FormControlLabel
              control={<Checkbox checked={selected} />}
              label={option.name}
            />
            {contactRole && (
              <Chip
                variant="outlined"
                size="small"
                label={<Typography variant="body2">{contactRole}</Typography>}
              />
            )}
            {option.role && (
              <Chip
                variant="outlined"
                size="small"
                label={<Typography variant="body2">{option.role}</Typography>}
              />
            )}
          </Box>
        );
      }}
      renderInput={(params: any) => (
        <TextField {...params} variant={variant} placeholder={placeholder} />
      )}
    />
  );
}
