import React, { useReducer, useState, useRef, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Paper, Theme, Tooltip, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { GET_CALENDAR_EVENTS, GET_CALENDAR_SETTINGS } from "../../api/graphql";
import CalendarFilter from "./CalendarFilter";
import "./Calendar.css";
import { Cake, Home } from "@mui/icons-material";
import "./Calendar.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: "16px 20px",
      height: "100%",
    },
  })
);

interface CalendarProps {
  calendarView: string;
}

const Calendar = ({ calendarView }: CalendarProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterOptions, setFilterOptions] = useState<any>([]);
  const [currentDates, setCurrentDates] = useState<any>({
    start: moment().startOf("month").toDate(),
    end: moment().endOf("month").add(7, "days").toDate(),
  });
  const calendarRef = useRef<any>();
  const noEvents: any = [];

  interface CalendarEvent {
    title: string;
    start: Date;
    allDay: boolean;
    backgroundColor: string;
  }

  const fullCalendarEventProps = ({ eventType }: { eventType: string }) => {
    const [tableName] = eventType.split(".");
    const eventColors: any = {
      contact: "#5759FF",
      deal: "#351665",
      task: "#0B8D9B",
    };
    return {
      backgroundColor: eventColors[tableName] || "#1A0B31",
      borderColor: eventColors[tableName] || "#1A0B31",
      fontSize: "2rem",
    };
  };

  const calendarReducer = (state: Array<CalendarEvent>, action: any) => {
    switch (action.type) {
      case "SET_EVENTS":
        return action.events.reduce((acc: any, event: any) => {
          acc.push({
            title: event.name,
            start: event.start,
            url: event.url,
            allDay: event.allDay,
            type: event.eventType,
            ...fullCalendarEventProps(event),
          });
          return acc;
        }, []);
      case "CLEAR_CALENDAR":
        return noEvents;
      default:
        return state;
    }
  };

  const [calendarEvents, dispatch] = useReducer(calendarReducer, noEvents);

  const dispatchEvents = (res: any) => {
    res.getCalendarEvents.map((event: any) => {
      if (event.allDay) {
        /**
         * If we get an all day event where the date is UTC midnight, we need to convert
         * it to midnight local time, otherwise FullCalendar will shift it to the wrong
         * day.
         *
         * TODO: We should make the API save dates w/ the proper midnight timezone
         */
        const parts = event.start.split("T");
        if (parts[1] === "00:00:00.000Z") {
          const [y, m, d] = parts[0]
            .split("-")
            .map((part: string) => Number.parseInt(part, 10));
          const midnightLocal = new Date(y, m - 1, d);
          midnightLocal.setHours(0, 0, 0, 0);
          event.start = midnightLocal.toISOString();
        }
      }
      return event;
    });

    dispatch({
      type: "SET_EVENTS",
      events: res.getCalendarEvents,
    });
  };

  const { refetch } = useQuery(GET_CALENDAR_EVENTS, {
    variables: {
      start: currentDates.start,
      end: currentDates.end,
    },
    onCompleted: dispatchEvents,
  });

  useQuery(GET_CALENDAR_SETTINGS, {
    onCompleted: (res) => {
      setFilterOptions(
        res.getCalendarPreferences.map((pref: any) => {
          return {
            ...pref,
            enabled: true,
          };
        })
      );
    },
  });

  const openFilterMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleNextClick = (event: any) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();

    setCurrentDates({
      start: calendarApi.getDate(),
      end: moment(calendarApi.getDate()).endOf("month").add(7, "days").toDate(),
    });

    refetch();
  };

  const handlePrevClick = (event: any) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();

    setCurrentDates({
      start: calendarApi.getDate(),
      end: moment(calendarApi.getDate()).endOf("month").toDate(),
    });

    refetch();
  };

  const handleTodayClick = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    setCurrentDates({
      start: moment(calendarApi.getDate()).startOf("month").toDate(),
      end: moment(calendarApi.getDate()).endOf("month").toDate(),
    });

    refetch();
  };

  const handleViewChange = (calendarView: any) => {
    const calendarApi = calendarRef.current.getApi();
    setCurrentDates({
      start: moment().startOf("month").toDate(),
      end: moment().endOf("month").add(7, "days").toDate(),
    });
    calendarApi.today();
    calendarApi.changeView(calendarView);
  };

  useEffect(() => {
    handleViewChange(calendarView);
  }, [calendarView]);

  const renderedCalendarEvents = calendarEvents.reduce(
    (acc: any, calEvent: any) => {
      filterOptions.forEach((option: any) => {
        if (option.enabled) {
          if (option.id === calEvent.type) {
            acc.push(calEvent);
          }
        }
      });
      return acc;
    },
    []
  );

  function renderInnerContent(innerProps: any) {
    const event = innerProps.event;
    const [, eventType] = event.extendedProps.type.split(".");
    let icon = <></>;
    if (eventType === "birthday") {
      icon = <Cake fontSize="inherit" />;
    } else if (eventType === "homeAnniversaryDate") {
      icon = <Home fontSize="inherit" />;
    }
    return (
      <div className="fc-event-main-frame" style={{ width: "100%" }}>
        {innerProps.timeText && (
          <div className="fc-event-time">{innerProps.timeText}</div>
        )}
        <div className="fc-event-title-container">
          <div
            className="fc-event-title fc-sticky"
            style={{ display: "flex", alignItems: "center" }}
          >
            {icon && <>{icon}&nbsp;</>}
            {innerProps.event.title || <>&nbsp;</>}
          </div>
        </div>
      </div>
    );
  }

  const handleEventContent = (arg: any) => {
    const parts: string[] = arg.event.title
      .split("-")
      .map((s: string) => s.trim());
    return (
      <Tooltip
        arrow
        title={
          <>
            <Typography color="inherit" variant="h6">
              {parts[0]}
            </Typography>
            {parts.length > 1 && <Typography>{parts[1]}</Typography>}
          </>
        }
      >
        {renderInnerContent(arg)}
      </Tooltip>
    );
  };

  return (
    <Paper className={classes.card}>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView={calendarView}
        events={renderedCalendarEvents}
        eventContent={handleEventContent}
        customButtons={{
          filterButton: {
            text: "Filter",
            click: (event) => {
              openFilterMenu(event);
            },
          },
          nextButton: {
            text: "Next",
            icon: "chevron-right",
            click: (event) => {
              handleNextClick(event);
            },
          },
          prevButton: {
            text: "Next",
            icon: "chevron-left",
            click: (event) => {
              handlePrevClick(event);
            },
          },
          todayButton: {
            text: "Today",
            click: () => {
              handleTodayClick();
            },
          },
        }}
        buttonText={{
          today: "Today",
          month: "Month",
          week: "Week",
          day: "Day",
          list: "List",
        }}
        headerToolbar={{
          left: "filterButton",
          center: "title",
          right:
            "todayButton dayGridMonth dayGridWeek dayGridDay prevButton,nextButton",
        }}
        height="100%"
      />
      <CalendarFilter
        anchorEl={anchorEl}
        handleClose={closeFilterMenu}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
      />
    </Paper>
  );
};

export default Calendar;
