import React, { FunctionComponent, useState } from "react";
import { TagsIcon } from "../../icons";
import { PrimaryButton, SecondaryButton } from "../buttons";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { gql, useMutation } from "@apollo/client";

import { FormControl, Grid, Select, Typography } from "@mui/material";
import { useAlert } from "../../context/alert";
import { ApiClient, useApi } from "../../context/api";
import { refreshContactTags } from "../../actions/contactTags";
import TagAutocomplete from "./TagAutocomplete";
import { Tag } from "../../models";
import { connect } from "react-redux";

type ContactBatchManageTagsButtonProps = {
  selectedContacts: any;
  onSubmitted?: () => void;
  refreshContactTags: (apolloClient: ApiClient) => Promise<void>;
};

const BatchManageTagsButton: FunctionComponent<
  ContactBatchManageTagsButtonProps
> = ({ selectedContacts, onSubmitted, refreshContactTags }) => {
  const { showSuccess, showError } = useAlert();
  const { apolloClient } = useApi();
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("add");
  const [tags, setTags] = useState<Array<Tag>>([]);

  const [batchTagContacts, { loading: tagging }] = useMutation(gql`
    mutation BatchTagContacts($input: BatchTagContactsInput!) {
      batchTagContacts(input: $input)
    }
  `);

  const [batchUntagContacts, { loading: untagging }] = useMutation(gql`
    mutation BatchUntagContacts($input: BatchTagContactsInput!) {
      batchUntagContacts(input: $input)
    }
  `);

  const handleSubmit = () => {
    const input = {
      contacts: Object.keys(selectedContacts),
      tags,
    };
    let promise: Promise<any> | undefined;
    if (action === "add") {
      promise = batchTagContacts({ variables: { input } });
      promise
        .then(() => {
          showSuccess("Tags have been added to your contacts");
        })
        .then(() => refreshContactTags(apolloClient));
    } else if (action === "remove") {
      promise = batchUntagContacts({ variables: { input } }).then(() => {});
    }

    if (!promise) {
      return;
    }

    promise
      .then(() => {
        const verb = action === "add" ? "added" : "removed";
        showSuccess(`Tags have been ${verb} from your contacts`);
        if (onSubmitted) {
          onSubmitted();
        }
      })
      .catch(() => {
        const verb = action === "add" ? "adding" : "removing";
        showError(`An error occurred when ${verb} the tags`);
      })
      .finally(() => {
        setOpen(false);
      });
  };

  return (
    <>
      <SecondaryButton
        height="short"
        onClick={() => setOpen(true)}
        disabled={open}
      >
        <TagsIcon size="12px" style={{ marginRight: "6px" }} />
        <span>Manage Tags</span>
      </SecondaryButton>
      <Dialog
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">Manage Tags</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <FormControl>
                <Select
                  native
                  value={action}
                  onChange={(e) => setAction(e.target.value as string)}
                  name="age"
                  inputProps={{
                    id: "action",
                  }}
                >
                  <option value="add">Add</option>
                  <option value="remove">Remove</option>
                </Select>
              </FormControl>
              <Typography>
                &nbsp;the following tags {action === "add" ? "to" : "from"}{" "}
                {Object.keys(selectedContacts).length}{" "}
                {Object.keys(selectedContacts).length === 1
                  ? "contact"
                  : "contacts"}
                {": "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TagAutocomplete
                freeSolo={action === "add"}
                autoFocus
                value={tags}
                onChange={(tags) => {
                  setTags(tags);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            onClick={() => setOpen(false)}
            disabled={tagging || untagging}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSubmit}
            disabled={untagging || tagging || tags.length === 0}
          >
            {action === "add" ? "Add Tags" : "Remove Tags"}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(null, { refreshContactTags })(BatchManageTagsButton);
