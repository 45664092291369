import { useState } from "react";
import { useMutation } from "@apollo/client";
import { EDIT_NOTE, DELETE_NOTE, CREATE_NOTE } from "../api/graphql";

export const useCreateNote = () => {
  const [noteBody, setNoteBody] = useState("");

  const [editNote] = useMutation(EDIT_NOTE);

  const [deleteNote] = useMutation(DELETE_NOTE);

  const [createNote] = useMutation(CREATE_NOTE);

  return {
    noteBody,
    setNoteBody,
    editNote,
    deleteNote,
    createNote,
  };
};
