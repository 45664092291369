import React, { FunctionComponent, useState } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { gql, useMutation } from "@apollo/client";
import { Deal } from "../../models";
import { useAlert } from "../../context/alert";

const COPY_DEAL = gql`
  mutation CopyDeal($input: CopyDealInput!) {
    copyDeal(input: $input) {
      id
    }
  }
`;

type CopyDialogProps = {
  open: boolean;
  deal: Deal;
  onClose: (e: React.MouseEvent) => void;
  onSubmit: (deal: any) => any;
};

export const CopyDialog: FunctionComponent<CopyDialogProps> = ({
  open,
  onClose,
  onSubmit,
  deal,
}) => {
  const [copyDeal] = useMutation(COPY_DEAL);
  const { showError, showSuccess } = useAlert();
  const [itemsToCopy, setItemsToCopy] = useState([
    {
      key: "teamMembers",
      label: "Team",
      copy: true,
    },
    {
      key: "notes",
      label: "Notes",
      copy: true,
    },
    {
      key: "tasks",
      label: "Tasks",
      copy: true,
    },
    {
      key: "documents",
      label: "Documents",
      copy: true,
    },
  ]);

  const handleCopyClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const input = {
      id: deal.id,
      ...itemsToCopy.reduce((acc, item) => {
        acc[item.key] = item.copy;
        return acc;
      }, {} as Record<string, boolean>),
    };
    copyDeal({
      variables: {
        input,
      },
    })
      .then((res) => {
        showSuccess("Deal copied successfully");
        onSubmit(res.data.copyDeal);
      })
      .catch((err) => {
        showError(
          "An error occurred while copying the deal. Please try again."
        );
      });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          minWidth: "400px",
        },
      }}
    >
      <form>
        <DialogTitle>Copy This Deal?</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Select the items you would like to copy to the new deal.
          </Typography>
          <FormGroup>
            {itemsToCopy.map((item) => (
              <FormControlLabel
                label={item.label}
                control={
                  <Checkbox
                    checked={item.copy}
                    onChange={(e, checked) => {
                      const newItemsToCopy = itemsToCopy.map((i) => {
                        if (i.key === item.key) {
                          return {
                            ...i,
                            copy: checked,
                          };
                        }
                        return i;
                      });
                      setItemsToCopy(newItemsToCopy);
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            autoFocus
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              onClose(e);
            }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            color="primary"
            onClick={handleCopyClick}
            variant="contained"
          >
            Copy
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
