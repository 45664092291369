import React from "react";
import "./HoverActions.scss";

type HoverActionContainerProps = {
  children?: any;
  className?: string;
  style?: any;
};

function makeClasses(...classes: any[]) {
  return classes.filter((className: string) => !!className).join(" ");
}

export function HoverActionContainer({
  children,
  className,
  style,
}: HoverActionContainerProps) {
  return (
    <div
      style={style}
      className={makeClasses("HoverActionContainer", className)}
    >
      {children}
    </div>
  );
}

type HoverActionsProps = {
  children?: any;
  className?: string;
  style?: any;
};

export function HoverActions({
  children,
  className,
  style,
}: HoverActionsProps) {
  return (
    <div style={style} className={makeClasses("HoverActions", className)}>
      {children}
    </div>
  );
}
