import React, { useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { TeamMember } from "../models";
import { TEAM_MEMBER_FIELDS, GET_TEAM_MEMBERS } from "../api/graphql";

export const GET_TEAM_MEMBERS_WITH_ACCESS_TO_DEAL = gql`
  query GetTeamMembers($dealId: ID!) {
    deal(id: $dealId) {
      teamMembersWithAccess {
        ...TeamMemberFields
      }
    }
  }
  ${TEAM_MEMBER_FIELDS}
`;

export const GET_TEAM_MEMBERS_WITH_ACCESS_TO_CONTACT = gql`
  query GetTeamMembers($contactId: ID!) {
    getContact(id: $contactId) {
      teamMembersWithAccess {
        ...TeamMemberFields
      }
    }
  }
  ${TEAM_MEMBER_FIELDS}
`;

export const useGetTeamMembers = (
  dealId?: string,
  contactId?: string,
  dialogOpen?: boolean
) => {
  const [selectedAgents, setSelectedAgents] = useState<TeamMember[]>([]);
  const [teamMembers, setTeamMembers] = useState<TeamMember[] | undefined>(
    dealId || contactId ? [] : undefined
  );

  const handleAgentSelect = (
    e: React.ChangeEvent,
    selectedAgents: TeamMember[]
  ) => {
    setSelectedAgents(selectedAgents);
  };

  const [getTeamMembersWithAccessToDeal] = useLazyQuery(
    GET_TEAM_MEMBERS_WITH_ACCESS_TO_DEAL,
    {
      variables: { dealId },
      onCompleted: (data) => {
        if (data?.deal?.teamMembersWithAccess) {
          setTeamMembers(data.deal.teamMembersWithAccess);
        }
      },
    }
  );

  const [getTeamMembers] = useLazyQuery(GET_TEAM_MEMBERS, {
    onCompleted: (data) => {
      if (data?.getTeamMembers) {
        setTeamMembers(data.getTeamMembers);
      }
    },
  });

  const [getTeamMembersWithAccessToContact] = useLazyQuery(
    GET_TEAM_MEMBERS_WITH_ACCESS_TO_CONTACT,
    {
      variables: { contactId },
      onCompleted: (data) => {
        if (data?.getContact?.teamMembersWithAccess) {
          setTeamMembers(data.getContact.teamMembersWithAccess);
        }
      },
    }
  );

  useEffect(() => {
    if (!dialogOpen) {
      setSelectedAgents([]);
    }
  }, [dialogOpen]);

  useEffect(() => {
    if (!dealId && !contactId) {
      getTeamMembers();
    } else if (dealId) {
      getTeamMembersWithAccessToDeal();
    } else if (contactId) {
      getTeamMembersWithAccessToContact();
    }
  }, [
    dealId,
    contactId,
    getTeamMembers,
    getTeamMembersWithAccessToDeal,
    getTeamMembersWithAccessToContact,
  ]);
  return {
    selectedAgents,
    handleAgentSelect,
    teamMembers,
    setSelectedAgents,
  };
};
