import React, { useReducer, useState, useEffect, useMemo } from "react";
import SplitLayout from "../../../layouts/SplitLayout";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Link,
  Typography,
  Box,
  Avatar,
  Theme,
  ListItem,
  ListItemText,
  List,
  ListItemAvatar,
  Menu,
  MenuItem,
  Divider,
  Alert,
  AlertTitle,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Sms as SmsIcon, MoreHoriz } from "@mui/icons-material";
import { EmailIcon, NoteIcon, TaskIcon } from "../../../icons";
import AssignTeamMemberDialog from "../../../components/deals/AssignTeamMemberDialog";
import CreateNote from "../../../components/notes/CreateNote";
import CreateTask from "../../../components/tasks/CreateDialog";
import LeftPane from "../../../components/LeftPane";
import EditButton from "../../../components/subject-pane/EditButton";
import SaveButton from "../../../components/subject-pane/SaveButton";
import SubjectData from "../../../components/subject-pane/SubjectData";
import { cleanFields } from "../../../helpers/fields";
import { useAlert } from "../../../context/alert";
import moment from "moment";
import ActionIconButton from "../../../components/ActionIconButton";
import PropertyPhoto from "../../../components/deals/PropertyPhoto";
import ContactSelect from "../../../components/ContactSelect";

import {
  FRAGMENT_DEAL_CUSTOM_FIELDS,
  FRAGMENT_DEAL_FIELDS,
  FRAGMENT_DEAL_TEAM_MEMBERS,
  FRAGMENT_TASK_FIELDS,
} from "../../../api/graphql";
import { connect } from "react-redux";
import { Role, Integration, DealType } from "../../../models";
import UserInviteDialog from "../../../components/users/UserInviteDialog";
import ComposeEmailDialog from "../../../components/emails/ComposeEmailDialog";
import DotloopCreateLoopDialog from "../../../components/integrations/DotloopCreateLoopDialog";
import DealReportDialog from "../../../components/deals/DealReportDialog";
import TabsView from "./tabs";
import {
  DealViewActionType,
  DealViewState,
  Provider,
  reducer,
} from "./context";
import DealTeamMemberWithPopover from "../../../components/deals/DealTeamMemberWithPopover";
import DocuSignCreateRoomDialog from "../../../components/integrations/DocuSignCreateRoomDialog";
import DealStage from "./DealStage";
import { getStreetString, getCityStateString } from "../../../helpers/address";
import EditDealTypeModal from "../../../components/EditDealTypeModal";
import { ArchiveDialog } from "../../../components/deals/ArchiveDialog";
import SkySlopeCreateTransactionDialog from "../../../components/integrations/SkySlopeCreateTransactionDialog";
import Connections from "./Connections";
import { useAuth } from "../../../context/auth";
import download from "downloadjs";
import { CopyDialog } from "../../../components/deals/CopyDialog";
import DealDetailsContainer from "./tabs/details/DealDetailsContainer";

const GET_DEAL = gql`
  query GetDeal($id: ID!) {
    deal(id: $id) {
      ...DealFields
      ...DealCustomFields
      ...DealTeamMembers
      skyslopeSaleId
      skyslopeListingId
      dealType {
        id
        name
        clientNoun
        dealOwnerRole {
          id
          name
        }
      }
      stage {
        id
        name
      }
      contacts {
        id
        firstName
        lastName
        name
        email
        phone
        avatarUrl
        userStatus
      }
      notes {
        id
        body
        createdAt
        source
        author {
          id
          name
          avatarUrl
        }
      }
      tasks {
        ...TaskFields
      }
      config {
        clientTimelineView
      }
      dotloopLoopUrl
      docusignRoomUrl
      skyslopeUrl
      folders {
        id
        name
        documents {
          id
          name
        }
      }
      documents(excludeInFolders: true) {
        id
        name
      }
    }
  }
  ${FRAGMENT_DEAL_FIELDS}
  ${FRAGMENT_DEAL_CUSTOM_FIELDS}
  ${FRAGMENT_DEAL_TEAM_MEMBERS}
  ${FRAGMENT_TASK_FIELDS}
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noAddress: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "11px 0",
      color: "#747b88",
      fontSize: "12px",
    },
    editButton: {
      opacity: 0,
      transition: "all 0.1s ease-in-out",
    },
    editableContainer: {
      "&:hover": {
        "& $editButton": {
          opacity: 1,
        },
      },
    },
    teamMember: {
      width: "calc(100% - 75px)",
      overflow: "hidden",
      margin: "0 0 0 25px",
    },
    danger: {
      "&:hover": {
        color: theme.palette.error.main,
      },
    },
    noDealContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

const initialState: DealViewState = {
  deal: { id: "", contacts: [], notes: [], tasks: [], folders: [] },
  editingDealTypeProps: {
    open: false,
  },
  notes: [],
  tasks: [],
  addedIntegrations: {},
  addingTeamMemberProps: {
    open: false,
  },
  editing: false,
  copyDialog: false,
  editContacts: false,
  inviteDialog: false,
  dotloopDialog: false,
  docusignDialog: false,
  skyslopeDialog: false,
};

export type ConnectionIdType = "SkySlope" | "Dotloop" | "DocuSign";

export type ConnectionType = {
  id: ConnectionIdType;
  href: string;
  canUnlink: boolean;
  canResync: boolean;
};

const View = ({
  roles,
  availableIntegrations,
  dealTypes,
}: {
  roles: Role[];
  availableIntegrations: Integration[];
  dealTypes: DealType[];
}) => {
  const { id } = useParams<any>();
  const navigate = useNavigate();
  const classes = useStyles();
  const { showSuccess, showError } = useAlert();
  const { authToken } = useAuth();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [contacts, setContacts] = useState<any>([]);
  const [configureReportDialogOpen, setConfigureReportDialogOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [archiveOpen, setArchiveOpen] = useState(false);

  const [updateDeal] = useMutation(
    gql`
      mutation UpdateDeal($input: UpdateDealInput!) {
        updateDeal(input: $input) {
          id
          dealType {
            id
            name
            clientNoun
            dealOwnerRole {
              id
              name
            }
          }
          stageId
          stage {
            id
            name
          }
        }
      }
    `
  );

  const [unlinkDotloopLoop] = useMutation(
    gql`
      mutation UnlinkDotloopLoop($input: UnlinkDotloopLoopInput!) {
        unlinkDotloopLoop(input: $input) {
          id
        }
      }
    `
  );

  const [unlinkDocuSignRoom] = useMutation(
    gql`
      mutation UnlinkDocuSignRoom($input: UnlinkDocuSignRoomInput!) {
        unlinkDocuSignRoom(input: $input) {
          id
        }
      }
    `
  );

  const [unlinkSkySlope] = useMutation(
    gql`
      mutation UnlinkSkySlope($input: UnlinkSkySlopeInput!) {
        unlinkSkySlope(input: $input) {
          id
        }
      }
    `
  );

  const [syncSkySlopeSale] = useMutation(
    gql`
      mutation LinkSkySlopeSale($input: LinkSkySlopeSaleInput!) {
        linkSkySlopeSale(input: $input) {
          id
          url
        }
      }
    `
  );

  const [syncSkySlopeListing] = useMutation(
    gql`
      mutation LinkSkySlopeListing($input: LinkSkySlopeListingInput!) {
        linkSkySlopeListing(input: $input) {
          id
          url
        }
      }
    `
  );

  const [syncDotloop] = useMutation(
    gql`
      mutation linkDotloopLoop($input: LinkDotloopLoopInput!) {
        linkDotloopLoop(input: $input) {
          id
          loopUrl
        }
      }
    `
  );

  const [syncDocuSignRoom] = useMutation(
    gql`
      mutation LinkDocuSignRoom($input: LinkDocuSignRoomInput!) {
        linkDocuSignRoom(input: $input) {
          url
        }
      }
    `
  );

  const { data, loading, refetch } = useQuery(GET_DEAL, { variables: { id } });
  const handleAssignRoleClick = (role: any, teamMember: any) => {
    dispatch({
      type: DealViewActionType.OPEN_ADD_TEAM_MEMBER_DIALOG,
      role: role,
      deal: state.deal,
      teamMember: teamMember,
    });
  };

  const handleAssignTeamMemberDialogClose = () => {
    dispatch({
      type: DealViewActionType.CLOSE_ADD_TEAM_MEMBER_DIALOG,
    });
  };

  const handleAssignTeamMemberDialogSave = (dealTeamMember: any) => {
    if (
      dealTeamMember === null &&
      state.addingTeamMemberProps.defaultTeamMember
    ) {
      // If we had a default team member but the onSave came back null then
      // a team member was removed.
      dispatch({
        type: DealViewActionType.REMOVE_DEAL_TEAM_MEMBER,
        teamMemberId: state.addingTeamMemberProps.defaultTeamMember.id,
        roleId: state.addingTeamMemberProps.role.id,
      });
    } else if (dealTeamMember) {
      dispatch({
        type: DealViewActionType.SET_DEAL_TEAM_MEMBER,
        dealTeamMember,
      });
    }
    handleAssignTeamMemberDialogClose(); // Close the dialog.
  };

  const TeamMemberByRole = ({ role }: { role: Role }) => {
    const dealTeamMember = useMemo(
      () =>
        state.deal?.dealTeamMembers?.find(
          (dtm: any) => dtm.role.id === role.id
        ),
      [role]
    );
    const teamMember = useMemo(
      () => dealTeamMember?.teamMember,
      [dealTeamMember]
    );

    const teamMemberContent = () => {
      if (!teamMember) {
        return <>-</>;
      } else {
        return (
          <DealTeamMemberWithPopover
            sx={{ marginTop: "4px" }}
            dealTeamMember={dealTeamMember}
            avatarStyle={{
              width: "24px",
              height: "24px",
              margin: "2px 8px 0 0",
            }}
          />
        );
      }
    };

    return (
      <div
        className={classes.editableContainer}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <SubjectData
          key={role.id}
          label={role.name}
          value={teamMemberContent()}
          className={classes.teamMember}
        />
        <div style={{ flexGrow: 1 }}></div>
        <EditButton
          onClick={() => handleAssignRoleClick(role, teamMember)}
          className={classes.editButton}
        />
      </div>
    );
  };

  const handleOpenCopyDialog = () => {
    handleActionMenuClose();
    dispatch({
      type: DealViewActionType.SHOW_COPY_DIALOG,
    });
  };

  const handleCopyClose = () => {
    dispatch({
      type: DealViewActionType.HIDE_COPY_DIALOG,
    });
  };

  const handleOpenEditDealType = () => {
    handleActionMenuClose();
    dispatch({
      type: DealViewActionType.OPEN_EDIT_DEAL_TYPE_DIALOG,
      dealTypes: dealTypes,
    });
  };

  const handleDealCopied = (copiedDeal: any) => {
    handleCopyClose();
    const url = `/deals/${copiedDeal.id}/details`;
    navigate(url);
  };

  const handleOpenArchiveDialog = () => {
    handleActionMenuClose();
    setArchiveOpen(true);
  };

  const handleCloseEditDealType = () => {
    dispatch({
      type: DealViewActionType.CLOSE_EDIT_DEAL_TYPE_DIALOG,
    });
  };

  function handleTaskCreated(task: any) {
    dispatch({ type: DealViewActionType.ADD_TASK, task: task });
    handleUpdate();
  }

  function handleNoteCreated(note: any) {
    dispatch({ type: DealViewActionType.ADD_NOTE, note: note });
  }

  const handleEditingContacts = () => {
    dispatch({
      type: DealViewActionType.SET_EDIT_CONTACTS,
      editContacts: !state.editContacts,
    });
  };

  const handleUpdateDeal = () => {
    const formUpdates: any = {
      id: state.deal.id,
      contacts: contacts,
    };
    callUpdateDeal(formUpdates);
  };

  const callUpdateDeal = (formUpdates: any) => {
    const input = Object.assign({}, cleanFields(formUpdates));
    updateDeal({
      variables: {
        input,
      },
    })
      .then((res) => {
        dispatch({
          type: DealViewActionType.SET_DEAL,
          deal: {
            ...data.deal,
            stage: res.data.updateDeal.stage,
            dealType: res.data.updateDeal.dealType,
          },
        });
        showSuccess("Deal saved");
        refetch();
      })
      .catch((err) => {
        showError("An error occurred when saving the deal");
      });
  };

  function DealContacts(): JSX.Element {
    let label = state.deal?.dealType?.clientNoun;
    if (contacts.length > 1) {
      label += "s";
    }
    return (
      <SubjectData
        label={!state?.editContacts ? label : ""}
        value={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.editableContainer}
          >
            <List
              style={{
                padding: 0,
                width: "calc(100% - 50px)",
                overflow: "hidden",
              }}
            >
              {!state.editContacts &&
                contacts.map((contact: any) => (
                  <ListItem key={contact.id} style={{ paddingLeft: 0 }}>
                    <ListItemAvatar style={{ minWidth: 0 }}>
                      <Avatar
                        src={contact.avatarUrl}
                        style={{
                          width: "24px",
                          height: "24px",
                          margin: "2px 8px 0 0",
                        }}
                      ></Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Link
                        component={RouterLink}
                        to={`/contacts/${contact.id}`}
                      >
                        {contact.name}
                      </Link>
                    </ListItemText>
                  </ListItem>
                ))}
              {state.editContacts && (
                <ContactSelect
                  asIndividuals={true}
                  multiple={true}
                  label={label}
                  hideName={true}
                  onChange={(event, contacts) => setContacts(contacts)}
                  value={contacts}
                />
              )}
            </List>
            {!state.editContacts ? (
              <EditButton
                onClick={() => handleEditingContacts()}
                className={classes.editButton}
              />
            ) : (
              <SaveButton
                onClick={() => {
                  handleEditingContacts();
                  handleUpdateDeal();
                }}
                style={{ paddingTop: "15px" }}
              />
            )}
          </Box>
        }
      />
    );
  }

  function formatDate(date: string) {
    return moment(date, "YYYY-MM-DD").format("ll");
  }

  const handleUpdate = () => {
    refetch().then((res) => {
      dispatch({
        type: DealViewActionType.SET_EDITING,
        editing: false,
      });
    });
  };

  const handleInviteClose = () => {
    dispatch({ type: DealViewActionType.HIDE_INVITE_DIALOG });
    handleUpdate();
  };

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleInviteOpen = () => {
    handleActionMenuClose();
    dispatch({ type: DealViewActionType.SHOW_INVITE_DIALOG });
  };

  const handleDotloopOpen = () => {
    handleActionMenuClose();
    dispatch({ type: DealViewActionType.SHOW_DOTLOOP_DIALOG });
  };

  const handleDotloopClose = () => {
    dispatch({ type: DealViewActionType.HIDE_DOTLOOP_DIALOG });
  };

  const handleSkySlopeOpen = () => {
    handleActionMenuClose();
    dispatch({ type: DealViewActionType.SHOW_SKYSLOPE_DIALOG });
  };

  const handleSkySlopeClose = () => {
    dispatch({ type: DealViewActionType.HIDE_SKYSLOPE_DIALOG });
  };

  const handleDotloopLoopCreated = (
    loop: { loopUrl: string },
    mode: "create" | "link"
  ) => {
    dispatch({
      type: DealViewActionType.SET_DEAL,
      deal: Object.assign({}, state.deal, {
        dotloopLoopUrl: loop.loopUrl,
      }),
    });
    if (mode === "link") {
      // This deal was linked to a Dotloop loop and we might have synced
      // it's deal details. We'll refetch the deal to get any updates
      refetch();
    }
  };

  const handleSkySlopeTransactionCreated = (
    transaction: { type: "Listing" | "Purchase"; id: string; url: string },
    mode: "create" | "link"
  ) => {
    const input =
      transaction.type === "Purchase"
        ? {
            skyslopeSaleId: transaction.id,
            skyslopeUrl: transaction.url,
          }
        : {
            skyslopeListingId: transaction.id,
            skyslopeUrl: transaction.url,
          };
    dispatch({
      type: DealViewActionType.SET_DEAL,
      deal: Object.assign({}, state.deal, input),
    });
    if (mode === "link") {
      // This deal was linked to a SkySlope transaction and we might have synced
      // it's deal details. We'll refetch the deal to get any updates
      refetch();
    }
  };

  const handleDocuSignOpen = () => {
    handleActionMenuClose();
    dispatch({ type: DealViewActionType.SHOW_DOCUSIGN_DIALOG });
  };

  const handleDocuSignClose = () => {
    handleActionMenuClose();
    dispatch({ type: DealViewActionType.HIDE_DOCUSIGN_DIALOG });
  };

  const handleArchiveDialogSubmit = () => {
    refetch()
      .then(() => {})
      .finally(() => {
        setArchiveOpen(false);
      });
  };

  const handleDocuSignRoomCreated = (
    room: { url: string },
    mode: "create" | "link"
  ) => {
    dispatch({
      type: DealViewActionType.SET_DEAL,
      deal: Object.assign({}, state.deal, {
        docusignRoomUrl: room.url,
      }),
    });
    if (mode === "link") {
      // This deal was linked to a DocuSign room and we might have synced
      // it's deal details. We'll refetch the deal to get any updates
      refetch();
    }
  };

  useEffect(() => {
    if (!archiveOpen) {
      refetch();
    }
  }, [archiveOpen, refetch]);

  useEffect(() => {
    if (data?.deal) {
      dispatch({ type: DealViewActionType.SET_DEAL, deal: data.deal });
      dispatch({ type: DealViewActionType.SET_TASKS, tasks: data.deal.tasks });
      dispatch({
        type: DealViewActionType.SET_NOTES,
        notes: data.deal.notes,
      });
      setContacts(data?.deal.contacts);
    }
    if (availableIntegrations.length > 0) {
      const addedIntegrations: { [key: string]: boolean } = {};
      for (const integration of availableIntegrations) {
        addedIntegrations[integration.id] = integration.added;
      }

      dispatch({
        type: DealViewActionType.SET_ADDED_INTEGRATIONS,
        integrations: addedIntegrations,
      });
    }
  }, [data, availableIntegrations]);

  const handleUnlinkDotloop = () => {
    handleActionMenuClose();
    if (
      window.confirm(
        "Are you sure you want to unlink this deal from it's current loop? The loop will still remain in Dotloop."
      )
    ) {
      unlinkDotloopLoop({ variables: { input: { id: state.deal.id } } })
        .then(() => {
          showSuccess("The Dotloop loop was unlinked succesfully");
          dispatch({
            type: DealViewActionType.UNLINK_DOTLOOP,
          });
        })
        .catch(() => {
          showError("An error occurred when unlinking the loop");
        });
    }
  };

  const handleUnlinkDocuSignRoom = () => {
    handleActionMenuClose();
    if (
      window.confirm(
        "Are you sure you want to unlink this deal from its current DocuSign Room? The room will still remain in DocuSign."
      )
    ) {
      unlinkDocuSignRoom({ variables: { input: { id: state.deal.id } } })
        .then(() => {
          showSuccess("The DocuSign Room was unlinked succesfully");
          dispatch({
            type: DealViewActionType.UNLINK_DOCUSIGN_ROOM,
          });
        })
        .catch(() => {
          showError("An error occurred when unlinking the room");
        });
    }
  };

  const handleUnlinkSkySlope = () => {
    handleActionMenuClose();
    if (
      window.confirm(
        "Are you sure you want to unlink this deal from it's current transaction? The transaction will still remain in SkySlope."
      )
    ) {
      unlinkSkySlope({ variables: { input: { id: state.deal.id } } })
        .then(() => {
          showSuccess("The SkySlope transaction was unlinked succesfully");
          dispatch({
            type: DealViewActionType.UNLINK_SKYSLOPE,
          });
          refetch();
        })
        .catch(() => {
          showError("An error occurred when unlinking the transaction");
        });
    }
  };

  const handleSyncSkySlope = () => {
    let promise: Promise<{ id: string; url: string }> | undefined;
    const transactionType = state.deal?.skyslopeSaleId ? "Purchase" : "Listing";

    promise =
      transactionType === "Purchase"
        ? syncSkySlopeSale({
            variables: {
              input: {
                dealId: state.deal.id,
                skyslopeSaleId: state.deal.skyslopeSaleId,
              },
            },
          }).then((res) => res.data.linkSkySlopeSale)
        : syncSkySlopeListing({
            variables: {
              input: {
                dealId: state.deal.id,
                skyslopeListingId: state.deal.skyslopeListingId,
              },
            },
          }).then((res) => res.data.linkSkySlopeListing);

    promise
      ?.then(({ id, url }) => {
        handleSkySlopeTransactionCreated(
          { type: transactionType, id, url },
          "link"
        );
        showSuccess(`${transactionType} was synced successfully`);
      })
      .catch((err) => {
        showError(`Error syncing ${transactionType}`);
      });
  };

  const handleSyncDotloop = () => {
    syncDotloop({
      variables: {
        dealId: state.deal.id,
        profileId: Number.parseInt(state.deal?.dotloopProfileId, 10),
        loopId: state.deal?.dotloopLoopId,
        syncLoop: true,
      },
    })
      .then((res) => {
        handleDotloopLoopCreated(res.data.linkDotloopLoop, "link");
        showSuccess(`Loop was synced successfully`);
      })
      .catch(() => {
        showError(`Error syncing Loop`);
      });
  };

  const handleSyncDocuSign = () => {
    syncDocuSignRoom({
      variables: {
        dealId: state.deal.id,
        accountId: state.deal?.docusignAccountId,
        roomId: state.deal?.docusignRoomId,
        syncRoom: true,
      },
    })
      .then((res) => {
        handleDocuSignRoomCreated(res.data.linkDocuSignRoom, "link");
        showSuccess(`Room was synced successfully`);
      })
      .catch(() => {
        showError(`Error syncing Room`);
      });
  };

  const unlinkHandlers = {
    SkySlope: handleUnlinkSkySlope,
    Dotloop: handleUnlinkDotloop,
    DocuSign: handleUnlinkDocuSignRoom,
  };

  const handleUnlink = (connectionId: ConnectionIdType) => {
    handleConnectionAction(unlinkHandlers[connectionId], connectionId);
  };

  const resyncHandlers = {
    SkySlope: handleSyncSkySlope,
    Dotloop: handleSyncDotloop,
    DocuSign: handleSyncDocuSign,
  };

  const handleResync = (connectionId: ConnectionIdType) => {
    handleConnectionAction(resyncHandlers[connectionId], connectionId);
  };

  const handleConnectionAction = (
    handler: any,
    connectionId: ConnectionIdType
  ) => {
    if (!handler) {
      throw new Error(`Connection id: ${connectionId}, does not exist`);
    } else {
      handler();
    }
  };

  const handleBackupExport = () => {
    const formData = new FormData();
    fetch(`${process.env.REACT_APP_API_URL}/backupDeal/${state.deal.id}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        const root =
          state.deal?.address && getStreetString(state.deal?.address).length
            ? state.deal?.address.street
            : new Date().toDateString();
        const filename = root.replace(/[, ]+/g, "_");

        download(blob, filename);
        showSuccess("Your deal was exported successfully");
      })
      .catch((err) => {
        console.log("Error: ", err);
        showError("An error occurred when exporting your deal");
      })
      .finally(() => {});
  };

  const connections = useMemo(() => {
    const connections: Array<ConnectionType> = [];
    if (state.deal?.docusignRoomUrl && state.addedIntegrations.docusign) {
      connections.push({
        id: "DocuSign",
        href: state.deal?.docusignRoomUrl,
        canUnlink: true,
        canResync: true,
      });
    }
    if (state.deal?.dotloopLoopUrl && state.addedIntegrations.dotloop) {
      connections.push({
        id: "Dotloop",
        href: state.deal?.dotloopLoopUrl,
        canUnlink: true,
        canResync: true,
      });
    }
    if (state?.deal?.skyslopeUrl && state.addedIntegrations.skyslope) {
      connections.push({
        id: "SkySlope",
        href: state.deal?.skyslopeUrl,
        canUnlink: true,
        canResync: true,
      });
    }

    return connections;
  }, [state.deal, state.addedIntegrations]);

  const menuItems: Array<JSX.Element> = [];
  const dangerItems: Array<JSX.Element> = [
    <MenuItem className={classes.danger} onClick={handleOpenEditDealType}>
      Change Deal Type
    </MenuItem>,
    <MenuItem className={classes.danger} onClick={handleOpenArchiveDialog}>
      {state.deal.archived ? "Unarchive" : "Archive"}
    </MenuItem>,
  ];

  if (!state.deal?.dotloopLoopUrl && state.addedIntegrations.dotloop) {
    menuItems.push(
      <MenuItem onClick={handleDotloopOpen} key="create-in-dotloop">
        Create Loop
      </MenuItem>
    );
  }

  if (state.addedIntegrations.skyslope && !state.deal?.skyslopeUrl) {
    menuItems.push(
      <MenuItem onClick={handleSkySlopeOpen} key="create-in-skyslope">
        Send To SkySlope
      </MenuItem>
    );
  }

  if (!state.deal?.docusignRoomUrl && state.addedIntegrations.docusign) {
    menuItems.push(
      <MenuItem onClick={handleDocuSignOpen} key="create-in-docusign">
        Create DocuSign Room
      </MenuItem>
    );
  }

  if (
    state.deal?.contacts?.find(
      (contact: any) => contact.userStatus === "none"
    ) !== undefined
  ) {
    menuItems.push(
      <MenuItem onClick={handleInviteOpen} key="invite-contacts">
        Invite Contacts to Client Portal
      </MenuItem>
    );
  }

  menuItems.push(
    <MenuItem
      onClick={() => {
        handleActionMenuClose();
        setConfigureReportDialogOpen(true);
      }}
      key="create-report"
    >
      <span>Create Report</span>
    </MenuItem>
  );

  menuItems.push(
    <MenuItem
      onClick={() => {
        handleActionMenuClose();
        handleBackupExport();
      }}
      key="create-backup-csv"
    >
      <span>Export</span>
    </MenuItem>
  );

  menuItems.push(<MenuItem onClick={handleOpenCopyDialog}>Copy</MenuItem>);

  if (dangerItems.length > 0) {
    menuItems.push(
      <div>
        <Divider />
        <ListItem>
          <Typography color="textSecondary" style={{ fontSize: 10 }}>
            Danger Zone
          </Typography>
        </ListItem>
      </div>
    );
    menuItems.push(...dangerItems);
  }

  const isClosed = useMemo(
    () => state.deal.dealState === "closed",
    [state.deal.dealState]
  );

  const isArchived = useMemo(() => state.deal.archived, [state.deal.archived]);

  const dealStateParse = state.deal.dealState
    ?.replace(/_+/g, " ")
    .split(" ")
    .map((s: string) => {
      return s[0].toUpperCase() + s.slice(1);
    })
    .join(" ");

  const archivedAndClosed =
    isClosed && isArchived && `Deal Archived and Closed`;

  const buildTitle = () => {
    if (archivedAndClosed) {
      return archivedAndClosed;
    } else if (isClosed && !isArchived) {
      return "Deal Closed";
    } else if (isArchived && !isClosed) {
      return `Deal Archived - ${dealStateParse}`;
    } else {
      return "This deal is Archived";
    }
  };

  const shouldShowRole = (role: Role) => {
    const dealTypeId = state.deal?.dealType?.id ?? "";
    const isRoleOnDealType =
      role?.dealTypes?.some((dealType) => dealType.id === dealTypeId) ?? false;
    const hasRoleAssignedOnDeal =
      state.deal.dealTeamMembers &&
      state.deal.dealTeamMembers.some(
        (dtm: any) => dtm.role.id === role.id && dtm.teamMember
      );
    return isRoleOnDealType || hasRoleAssignedOnDeal;
  };

  if (!state.deal) {
    return <></>;
  }

  return (
    <SplitLayout loading={loading}>
      {!loading && data.deal === null ? (
        <div className={classes.noDealContainer}>
          <div style={{ textAlign: "center" }}>
            <h1>Deal not found</h1>
            <p>It doesn't look like this deal exists on your account.</p>
            <Link component={RouterLink} to={`/deals/`}>
              Return to deals view
            </Link>
          </div>
        </div>
      ) : (
        !loading &&
        state.deal.id &&
        state.deal.address && (
          <>
            <Box>
              <LeftPane>
                <PropertyPhoto deal={state.deal} />
                {getStreetString(state.deal.address).length ? (
                  <Box textAlign="center" style={{ margin: "9px auto" }}>
                    <Typography variant="h5">
                      {state.deal?.address.street}
                    </Typography>
                    {state.deal?.address?.street2 && (
                      <Typography variant="h5">
                        {state.deal?.address?.street2}
                      </Typography>
                    )}
                    <Typography variant="subtitle2">
                      {getCityStateString(state.deal.address)}
                    </Typography>
                  </Box>
                ) : (
                  <p className={classes.noAddress}>No address to display</p>
                )}

                {(isArchived || isClosed) && (
                  <Box m={1}>
                    <Alert
                      sx={{
                        justifyContent: "center",
                        width: "90%",
                        textAlign: "center",
                        margin: "auto",
                        "&.MuiAlert-root > *": {
                          marginRight: "10px",
                        },
                        "&.MuiAlert-root > .MuiAlert-message": {
                          overflowWrap: "break-word",
                        },
                      }}
                      severity={isClosed ? "success" : "warning"}
                    >
                      <AlertTitle>
                        {state.deal.dealState ? (
                          <Typography variant="h6">
                            {buildTitle()}
                            {`${
                              isClosed && state.deal.closedDate
                                ? ` on ${formatDate(state.deal.closedDate)}`
                                : ""
                            }`}
                          </Typography>
                        ) : (
                          `This deal is Archived`
                        )}
                      </AlertTitle>
                      <Typography variant="body1">
                        {state.deal.archivedReason &&
                          `${state.deal.archivedReason}`}
                      </Typography>
                    </Alert>
                  </Box>
                )}

                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  style={{ marginBottom: "14px", marginTop: "20px" }}
                >
                  <ComposeEmailDialog
                    recipients={state.deal?.contacts ?? []}
                    deal={state.deal}
                  >
                    <ActionIconButton icon={EmailIcon} label="Email" />
                  </ComposeEmailDialog>

                  <ActionIconButton
                    icon={SmsIcon}
                    label="SMS"
                    style={{ display: "none" }}
                  />

                  <CreateTask
                    deal={state.deal}
                    onTaskCreated={handleTaskCreated}
                    assignToCurrentUser={true}
                  >
                    <ActionIconButton icon={TaskIcon} label="Task" />
                  </CreateTask>

                  <CreateNote
                    dealId={state.deal?.id}
                    onNoteCreated={handleNoteCreated}
                  >
                    <ActionIconButton icon={NoteIcon} label="Note" />
                  </CreateNote>

                  {menuItems.length > 0 && (
                    <>
                      <ActionIconButton
                        onClick={(e: any) => handleActionsClick(e)}
                        icon={MoreHoriz}
                        label="Actions"
                      />
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={menuOpen}
                        onClose={handleActionMenuClose}
                      >
                        {menuItems.map((menuItem) => menuItem)}
                      </Menu>
                    </>
                  )}
                </Grid>
                <SkySlopeCreateTransactionDialog
                  deal={state.deal}
                  open={state.skyslopeDialog}
                  onClose={handleSkySlopeClose}
                  transactionType={
                    state.deal.closeDate ? "Purchase" : "Listing"
                  }
                  onTransactionCreated={handleSkySlopeTransactionCreated}
                />
                <DotloopCreateLoopDialog
                  deal={state.deal}
                  open={state.dotloopDialog}
                  onClose={handleDotloopClose}
                  onLoopCreated={handleDotloopLoopCreated}
                />
                <DocuSignCreateRoomDialog
                  deal={state.deal}
                  open={state.docusignDialog}
                  onClose={handleDocuSignClose}
                  onRoomCreated={handleDocuSignRoomCreated}
                />
                <DealReportDialog
                  open={configureReportDialogOpen}
                  onClose={() => setConfigureReportDialogOpen(false)}
                  deal={state.deal}
                />
                <DealStage
                  deal={state.deal}
                  onUpdated={() => refetch({ id: state.deal.id })}
                />
                <DealContacts />
                <Provider value={{ state, dispatch }}>
                  <DealDetailsContainer isLeftPane />
                </Provider>

                <AssignTeamMemberDialog
                  {...state.addingTeamMemberProps}
                  onClose={handleAssignTeamMemberDialogClose}
                  onSave={handleAssignTeamMemberDialogSave}
                />

                {connections.length > 0 && (
                  <>
                    <Divider />
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ margin: "15px 25px" }}
                    >
                      Connections
                    </Typography>
                    <Box sx={{ margin: "0px 25px 18px" }}>
                      <Connections
                        connections={connections}
                        onUnlink={handleUnlink}
                        onResync={handleResync}
                      />
                    </Box>
                  </>
                )}
                <Divider />
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{ margin: "15px 25px" }}
                >
                  Assigned Team
                </Typography>
                {roles.filter(shouldShowRole).map((role: any) => (
                  <TeamMemberByRole key={role.id} role={role} />
                ))}
              </LeftPane>
            </Box>

            <Provider value={{ state, dispatch }}>
              <TabsView />
            </Provider>

            {/*
            <Routes>
              <Route
                element={
                  <Provider value={{ state, dispatch }}>
                    <TabsView />
                  </Provider>
                }
              />
            </Routes>
            */}

            <CopyDialog
              open={state.copyDialog}
              onClose={handleCopyClose}
              onSubmit={handleDealCopied}
              deal={state.deal}
            />

            <UserInviteDialog
              open={state.inviteDialog}
              invitees={state.deal?.contacts?.filter(
                (contact: any) => contact.userStatus === "none"
              )}
              socialProofUsers={state.deal?.contacts?.filter(
                (contact: any) => contact.userStatus !== "none"
              )}
              onClose={handleInviteClose}
            />

            <EditDealTypeModal
              {...state.editingDealTypeProps}
              updateDeal={callUpdateDeal}
              onClose={handleCloseEditDealType}
              onSave={handleAssignTeamMemberDialogSave}
            />
            <ArchiveDialog
              open={archiveOpen}
              onClose={() => setArchiveOpen(false)}
              onSubmit={handleArchiveDialogSubmit}
              deal={state.deal}
            />
          </>
        )
      )}
    </SplitLayout>
  );
};

const mapStateToProps = ({
  roles,
  availableIntegrations,
  dealTypes,
}: {
  roles: Role[];
  availableIntegrations: Integration[];
  dealTypes: DealType[];
}) => {
  return { roles, availableIntegrations, dealTypes };
};

export default connect(mapStateToProps)(View);
