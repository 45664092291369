import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Avatar,
  Typography,
  Link,
  Theme,
  Select,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { Send } from "@mui/icons-material";
import { io } from "socket.io-client";
import { Skeleton } from "@mui/material";
import { useAuth } from "../../context/auth";
import { useUser } from "../../context/user";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { useAlert } from "../../context/alert";
import { SmsTemplate, Deal } from "../../models/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      position: "sticky",
      bottom: "8px",
      background: "white",
      marginBottom: "8px",
    },
  })
);

type SmsThreadViewProps = {
  smsThread?: any;
  recipients?: any;
  inDialog?: boolean;
  showRecipients?: boolean;
  contactDeals?: Deal[];
};

const SmsThreadView: FunctionComponent<SmsThreadViewProps> = ({
  smsThread: _smsThread,
  recipients,
  inDialog = false,
  showRecipients = true,
  contactDeals,
}) => {
  const apollo = useApolloClient();
  const classes = useStyles();
  const { showError } = useAlert();
  const messageDiv = useRef<HTMLDivElement | null>(null);
  const threadEndRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [messageText, setMessageText] = useState("");
  const { authToken } = useAuth();
  const { currentUser } = useUser();
  const [smsThread, setSmsThread] = useState<any | null>(null);
  const [smsTemplates, setSmsTemplates] = useState<SmsTemplate[] | undefined>(
    undefined
  );
  const [selectedSmsTemplate, setSelectedSmsTemplate] = useState<
    SmsTemplate | undefined
  >(undefined);
  const [selectedContactDeal, setSelectedContactDeal] = useState<
    Deal | undefined
  >(contactDeals?.length === 1 ? contactDeals[0] : undefined);
  const [getSmsThread, { refetch, data, loading }] = useLazyQuery(gql`
    query GetSmsThread($id: ID!) {
      getSmsThread(id: $id) {
        id
        participants {
          id
          name
          url
          avatarUrl
        }
        messages {
          id
          messageDate
          messageText
          from {
            id
            name
            avatarUrl
          }
          media {
            id
            filename
            contentType
            url
          }
        }
        updatedAt
      }
    }
  `);
  const [sendSms, { loading: sending }] = useMutation(gql`
    mutation SendSms($input: SendSmsInput!) {
      sendSms(input: $input) {
        id
        smsThreadId
      }
    }
  `);

  const GET_SMS_TEMPLATES = gql`
    query {
      getSmsTemplates {
        id
        name
        body
      }
    }
  `;

  const handleCompleted = (res: any) => {
    setSmsTemplates(res.getSmsTemplates);
  };

  const [getSmsTemplates] = useLazyQuery(GET_SMS_TEMPLATES, {
    onCompleted: handleCompleted,
  });

  useEffect(() => {
    getSmsTemplates();
  }, [getSmsTemplates]);

  const handleKeyDown = (e: React.KeyboardEvent<any>) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const cleanRecipients = (values: any[]) =>
    values.map((value: any) => {
      if (value.__typename === "Contact") {
        return { contactId: value.id };
      } else if (value.__typename === "TeamMember") {
        return { teamMemberId: value.id };
      } else {
        return { phone: value };
      }
    });

  const handleSend = () => {
    const input: any = {
      messageText,
    };
    if (smsThread) {
      input.smsThreadId = smsThread.id;
    } else if (recipients) {
      input.recipients = cleanRecipients(recipients);
    }

    sendSms({
      variables: { input },
    })
      .then((res) => {
        setMessageText("");
        getSmsThread({
          variables: {
            id: smsThread.id,
          },
        });
      })
      .catch((err) => {
        showError("An error occurred when sending the messsage");
      });
  };

  useEffect(() => {
    if (_smsThread) {
      getSmsThread({
        variables: {
          id: _smsThread.id,
        },
      });
    } else {
      setSmsThread(null);
    }
  }, [_smsThread, getSmsThread]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setSmsThread(data.getSmsThread);
    setTimeout(() => {
      // TODO: Should the be a useLayoutEffect thing?
      if (messageDiv.current) {
        messageDiv.current.scrollTop = messageDiv.current.scrollHeight;
      }
    });
  }, [data, getSmsThread]);

  useEffect(() => {
    if (!smsThread || !refetch) {
      return;
    }
    const socketUrl = process.env.REACT_APP_API_URL;
    if (!socketUrl) {
      return;
    }
    const socket = io(socketUrl, {
      path: "/io/",
      auth: {
        token: authToken,
      },
      transports: ["websocket"],
    });
    const cb = () => {
      if (refetch) {
        refetch();
      }
    };
    socket.on(`smsThread:${smsThread.id}:newMessage`, cb);
    return () => {
      socket.disconnect();
    };
  }, [smsThread, refetch, authToken]);

  const participantName = (participant: {
    id: string;
    name: string;
    url?: string;
  }) => {
    if (participant.id === currentUser.teamMember?.id) {
      return "You";
    }
    if (participant.url) {
      return (
        <Link component={RouterLink} to={participant.url}>
          {participant.name}
        </Link>
      );
    }
    return participant.name;
  };

  const scrollToBottom = () => {
    threadEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [smsThread]);

  const handleTemplateChange = (event: any) => {
    const selectedTemplate = smsTemplates?.find(
      (template: SmsTemplate) => template.id === event.target.value
    );
    setSelectedSmsTemplate(selectedTemplate);
    setMessageText(selectedTemplate?.body || "");
  };

  const handleContactDealChange = (event: any) => {
    setSelectedContactDeal(
      contactDeals?.find((deal: Deal) => deal.id === event.target.value)
    );
  };

  const renderPreview = useCallback(() => {
    const query = gql`
      query renderSmsPreview($input: SmsPreviewInput!) {
        renderSmsPreview(input: $input)
      }
    `;

    const input: any = {
      smsTemplateId: selectedSmsTemplate?.id,
    };

    if (recipients?.length) {
      input.contactId = recipients[0].id;
    }
    if (selectedContactDeal) {
      input.dealId = selectedContactDeal.id;
    }
    apollo
      .query({
        query,
        variables: { input },
      })
      .then((res) => {
        setMessageText(res.data.renderSmsPreview);
      });
  }, [selectedContactDeal, selectedSmsTemplate, apollo, recipients]);

  useEffect(() => {
    if (selectedSmsTemplate) {
      renderPreview();
    }
  }, [selectedSmsTemplate, selectedContactDeal, renderPreview]);

  return (
    <>
      {smsThread && showRecipients && (
        <div
          style={{
            display: "flex",
            marginBottom: "12x",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 0,
            zIndex: 99,
            background: "#F5F5F5",
            padding: "12px",
          }}
        >
          <div style={{ display: "flex" }}>
            {smsThread.participants?.map((participant: any, index: number) => (
              <div
                key={participant.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "6px",
                }}
              >
                <Avatar
                  component="span"
                  alt={participant.name}
                  src={participant.avatarUrl}
                  style={{
                    height: "18px",
                    width: "18px",
                    marginRight: "4px",
                  }}
                />
                <Typography>{participantName(participant)} </Typography>
                {index + 1 < smsThread.participants?.length && <span>,</span>}
              </div>
            ))}
          </div>
          <Typography color="textSecondary">
            {moment(smsThread?.updatedAt).calendar()}
          </Typography>
        </div>
      )}
      <Paper style={{ height: "100%" }} elevation={inDialog ? 0 : undefined}>
        <Box
          p={inDialog ? 0 : 4}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <div
            ref={messageDiv}
            style={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              overflowY: "scroll",
            }}
          >
            {smsThread?.messages.map((message: any) => (
              <Box
                key={message.id}
                mb={2}
                p={2}
                borderRadius="4px"
                style={{
                  border: "1px solid #E8E2E1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    component="span"
                    alt={message.from.name}
                    src={message.from.avatarUrl}
                    style={{ marginRight: "12px" }}
                  />
                  <Typography component="span">
                    {message.messageText}
                    {message.media.map((media: any) => {
                      return (
                        <img
                          alt={media.filename}
                          src={media.url}
                          style={{ width: "25%", height: "auto" }}
                        ></img>
                      );
                    })}
                  </Typography>
                </span>
                <Typography
                  color="textSecondary"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {moment(message.messageDate).fromNow()}
                </Typography>
              </Box>
            ))}
            <div ref={threadEndRef} />
            {smsThread?.messages.length > 0 && loading && <Skeleton />}
          </div>
          <Grid container spacing={2} className={classes.inputContainer}>
            <Grid item xs={12}>
              <TextField
                onKeyDown={handleKeyDown}
                autoFocus
                fullWidth
                variant="outlined"
                value={messageText}
                onChange={(e) => {
                  setMessageText(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={sending || messageText.length === 0}
                        onClick={() => {
                          handleSend();
                        }}
                        size="large"
                      >
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              {smsTemplates && smsTemplates.length > 0 && (
                <Box style={{ marginBottom: "8px" }}>
                  <StyledSelect
                    native
                    variant="outlined"
                    onChange={handleTemplateChange}
                  >
                    <option value="">Use SMS Template</option>
                    {smsTemplates.map((template) => (
                      <option key={template.id} value={template.id}>
                        {template.name}
                      </option>
                    ))}
                  </StyledSelect>
                </Box>
              )}
              {contactDeals && selectedSmsTemplate && (
                <Box style={{ marginLeft: "8px", marginBottom: "8px" }}>
                  <StyledSelect
                    native
                    variant="outlined"
                    onChange={handleContactDealChange}
                    labelId="deal"
                    disabled={contactDeals.length < 2}
                    value={selectedContactDeal?.id ?? ""}
                  >
                    <option value="">Use Deal</option>
                    {contactDeals.map((deal) => (
                      <option key={deal.id} value={deal.id}>
                        {deal.name || deal.dealType?.name}
                      </option>
                    ))}
                  </StyledSelect>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};
export default SmsThreadView;

const StyledSelect = withStyles(() =>
  createStyles({
    root: {
      height: "32px",
      padding: "0 16px",
      fontSize: "12px",
    },
  })
)(Select);
