import React, {
  useState,
  FunctionComponent,
  useEffect,
  useCallback,
} from "react";
import MaterialTable from "@material-table/core";
import { CsvBuilder } from "filefy";
import TableIcons from "../TableIcons";
import ContactNameWithAvatar from "../contacts/ContactNameWithAvatar";
import { Tooltip } from "@mui/material";
import { PrimaryButton } from "../buttons";

type ReportDataTableProps = {
  data: any;
  dataKey: string;
};

const ReportDataTable: FunctionComponent<ReportDataTableProps> = ({
  data,
  dataKey,
}) => {
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const renderName = (rowData: any): JSX.Element => {
    return (
      <ContactNameWithAvatar
        name={rowData.name}
        avatarUrls={[rowData.avatarUrl]}
        avatarStyle={{ marginRight: "20px" }}
      />
    );
  };

  useEffect(() => {
    if (!data || !dataKey) {
      return;
    }

    const columns = [];
    if (data.datasets.length > 0) {
      columns.push({
        key: "name",
        visible: true,
        title: "Name",
        field: "name",
        width: 250,
        render: renderName,
      });
    }
    columns.push(
      ...data.labels.map((label: string, index: number) => ({
        key: `d${index}`,
        title: (
          <div style={{ textAlign: "center", width: "100%" }}>{label}</div>
        ),
        visible: data.datasets.length > 1,
        width: 100,
        render: (rowData: any) => {
          const data = rowData[`d${index}`] || {};
          const dealCount: number = data.dealCount || 0;

          if (dataKey === "dealCount") {
            return (
              <div style={{ textAlign: "center" }}>
                {dealCount.toLocaleString()}
              </div>
            );
          }
          // Assume we're showing currency
          const value = data[dataKey] || 0;

          return (
            <div style={{ textAlign: "center" }}>
              <Tooltip title={"Deal count"}>
                <span>{dealCount.toLocaleString()}</span>
              </Tooltip>
              <br />
              {value.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          );
        },
      }))
    );

    setColumns(columns);

    const rows = data.datasets.map((dataset: any) => {
      const row: any = { name: dataset.label };
      for (let i = 0; i < dataset.data.length; i++) {
        row[`d${i}`] = dataset.data[i];
      }
      return row;
    });
    setRows(rows);
  }, [data, dataKey]);

  const exportCsv = useCallback(() => {
    const builder = new CsvBuilder("report.csv");
    builder
      .setDelimeter(",")
      .setColumns(["Name", ...data.labels.map((label: string) => label)])
      .addRows(
        rows.map((row) => {
          return [
            row.name,
            ...data.labels.map(
              (label: string, index: number) => row[`d${index}`][dataKey]
            ),
          ];
        })
      )
      .exportFile();
  }, [rows, data.labels, dataKey]);

  if (!data) {
    return <></>;
  }
  /*
  return (
    <TableContainer component={Paper} style={{ width: "100%" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                position: "absolute",
                width: "200px",
              }}
            ></TableCell>
            {data.labels.map((label: string) => (
              <TableCell
                style={{
                  position: "absolute",
                  width: "200px",
                }}
              >
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {data.datasets.map((dataset: any) => (
          <TableRow>
            <TableCell>{dataset.label}</TableCell>
            {dataset.data.map((value: number) => (
              <TableCell>{value}</TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
*/
  return (
    <MaterialTable
      columns={columns}
      data={rows}
      icons={TableIcons}
      actions={[
        {
          icon: () => (
            <PrimaryButton height="full">Export as CSV</PrimaryButton>
          ),
          position: "toolbar",
          onClick: exportCsv,
        },
      ]}
      options={{
        fixedColumns: {
          left: columns[0]?.key === "name" ? 1 : 0,
        },
        selection: false,
        showTitle: false,
        search: false,
        emptyRowsWhenPaging: false,
        columnsButton: false,
        paging: false,
        toolbar: true,
        sorting: false,
      }}
      style={{
        width: "100%",
      }}
    ></MaterialTable>
  );
};

export default ReportDataTable;
