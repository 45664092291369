import React from "react";
import { styled } from "@mui/material/styles";
import { Select, SelectProps, FormControl, MenuItem } from "@mui/material";

const StyledLabel = styled("div")(({ theme }) => ({
  marginRight: "8px",
  color: theme.palette.grey[600],
  fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
  display: "flex",
  alignItems: "center",
}));

const StyledValue = styled("div")(({ theme }) => ({
  color: theme.palette.grey[900],
  fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
  display: "flex",
  alignItems: "center",
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  backgroundColor: "#ffffff",
  fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
  fontSize: "14px",
  paddingTop: "4px",

  "&:hover": {
    borderColor: theme.palette.grey[300],

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[300],
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grey[300],
  },

  "& .MuiSelect-select:focus": {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
}));

type LabeledSelectProps = {
  label?: string;
  options: { value: string; label: string }[];
  selectProps: SelectProps;
};

const LabeledSelect = ({ label, options, selectProps }: LabeledSelectProps) => {
  const renderValue = (value: any) => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <StyledLabel>{label}</StyledLabel>
        <StyledValue>
          {options.find((option) => option.value === value)?.label}
        </StyledValue>
      </div>
    );
  };

  return (
    <FormControl variant="outlined">
      <StyledSelect {...selectProps} renderValue={renderValue}>
        {options.map((option, index: number) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default LabeledSelect;
