import React, { cloneElement, useState, useEffect, useRef } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Theme,
  Typography,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useApolloClient } from "@apollo/client";
import { CREATE_SMS_TEMPLATE, UPDATE_SMS_TEMPLATE } from "../../api/graphql";
import { SecondaryButton, PrimaryButton } from "../buttons";
import { useAlert } from "../../context/alert";
import MergeFieldAutocomplete from "./MergeFieldAutocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {
      flexGrow: 1,
    },
    dialogPaper: {
      minHeight: "300px",
      maxHeight: "500px",
      maxWidth: "800px",
    },
    fieldLink: {
      fontSize: "small",
      display: "inline-block",
      marginRight: 15,
      textDecoration: "dotted",
    },
    tableHeader: {
      fontFamily: ["AvenirNext-Medium", "Avenir", "sans-serif"].join(","),
      fontSize: "14px",
      color: "rgb(107, 114, 128)",
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      borderRadius: "5px",
    },
    cancelButton: {
      width: "110px",
      [theme.breakpoints.down("sm")]: {
        width: "80px",
      },
    },
    saveTemplateButton: {
      width: "110px",
      marginLeft: "5px",
      [theme.breakpoints.down("sm")]: {
        width: "88px",
        padding: "8px",
      },
    },
  })
);

type AddTemplateDialogProps = {
  template?: any;
  onTemplateSaved?: Function;
  children: any;
  editable?: boolean;
};

export default function SmsTemplateDialog({
  template,
  onTemplateSaved,
  children,
  editable = true,
}: AddTemplateDialogProps) {
  const defaultSmsTemplate = {
    id: "",
    name: "",
    body: "",
  };

  const textAreaRef = useRef<HTMLInputElement | null>(null);
  const classes = useStyles();
  const apollo = useApolloClient();
  const [open, setOpen] = useState<boolean>(false);
  const [smsTemplate, setSmsTemplate] = useState(defaultSmsTemplate);
  const [selectionStart, setSelectionStart] = useState<number | null>(null);

  const { showSuccess, showError } = useAlert();

  function handleClose(e: any) {
    e.stopPropagation();
    setOpen(false);
    setSmsTemplate(defaultSmsTemplate);
  }

  const handleSave = async (e: any) => {
    e.stopPropagation();
    if (!editable) {
      return;
    }

    const input: { id?: string; name: string; body: string } = {
      name: smsTemplate.name,
      body: smsTemplate.body,
    };
    if (smsTemplate.id) {
      input.id = smsTemplate.id;
    }

    try {
      let res: any = await apollo.mutate({
        mutation: input.id ? UPDATE_SMS_TEMPLATE : CREATE_SMS_TEMPLATE,
        variables: {
          input,
        },
      });

      res = input.id ? res.data.updateSmsTemplate : res.data.createSmsTemplate;

      showSuccess(`The template "${smsTemplate.name}" was saved successfully`);
      setOpen(false);
      onTemplateSaved && onTemplateSaved(res);
    } catch (e) {
      showError("An error occurred when saving the sms template");
    }
  };

  const handleMergeField = (mergeField: any) => {
    if (selectionStart || selectionStart === 0) {
      const cursorPosition = selectionStart;
      const beforeText = smsTemplate.body.substring(0, cursorPosition);
      const afterText = smsTemplate.body.substring(
        cursorPosition,
        smsTemplate.body.length
      );
      setSmsTemplate((smsTemplate) =>
        Object.assign({}, smsTemplate, {
          body: `${beforeText}${mergeField.placeholder} ${afterText}`,
        })
      );
    } else {
      setSmsTemplate((smsTemplate) =>
        Object.assign({}, smsTemplate, {
          body: `${smsTemplate.body} ${mergeField.placeholder} `,
        })
      );
    }
    textAreaRef.current?.focus();
  };

  const updateSelection = () => {
    setSelectionStart(textAreaRef.current?.selectionStart || null);
  };

  useEffect(() => {
    if (template) {
      setSmsTemplate(template);
    }
  }, [template]);

  return (
    <>
      {cloneElement(children as any, {
        onClick: (e) => {
          if (children.key === "newtemplate") {
            setSmsTemplate(defaultSmsTemplate);
          }
          e.stopPropagation();
          setOpen(true);
        },
      })}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth={true}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Sms Template</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                autoFocus={!smsTemplate?.id}
                disabled={!editable}
                fullWidth
                label="Name"
                value={smsTemplate.name}
                onChange={(e) =>
                  setSmsTemplate(
                    Object.assign({}, smsTemplate, { name: e.target.value })
                  )
                }
              />
            </Grid>
            <Grid item xs={12} className={classes.tabContainer}>
              <TextField
                inputRef={(r) => (textAreaRef.current = r)}
                disabled={!editable}
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                value={smsTemplate.body}
                onSelect={updateSelection}
                onChange={(e) => {
                  setSmsTemplate(
                    Object.assign({}, smsTemplate, { body: e.target.value })
                  );
                }}
              />
            </Grid>
            {editable && (
              <Grid item xs={12}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    style={{ whiteSpace: "nowrap", marginRight: "12px" }}
                  >
                    Insert a field:
                  </Typography>
                  <MergeFieldAutocomplete onSelect={handleMergeField} />
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            onClick={handleClose}
            className={classes.cancelButton}
          >
            Cancel
          </SecondaryButton>

          {editable && (
            <PrimaryButton
              onClick={handleSave}
              className={classes.saveTemplateButton}
            >
              Save
            </PrimaryButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
