import React, { useState, useEffect, useMemo } from "react";
import { SelectChangeEvent } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_TEAM_MEMBERS } from "../api/graphql";
import { TeamMember } from "../models";
import LabeledSelect from "./LabeledSelect";

type TeamMemberLabeledSelectProps = {
  value?: Partial<TeamMember> | string;
  onChange: (teamMember: any) => void;
  onlyUsers?: boolean;
};

export default function TeamMemberLabeledSelect({
  onlyUsers,
  onChange,
  value: _value,
}: TeamMemberLabeledSelectProps) {
  const [teamMembers, setTeamMembers] = useState<Array<any>>([]);
  const { data } = useQuery(GET_TEAM_MEMBERS);

  const handleChange = (e: SelectChangeEvent<any>) => {
    const teamMember = teamMembers.find((tm) => tm.id === e.target.value);
    onChange(teamMember);
  };

  const options = useMemo(() => {
    return teamMembers.map((teamMember: TeamMember) => {
      return {
        value: teamMember.id,
        label: teamMember.name ?? "",
      };
    });
  }, [teamMembers]);

  const value = useMemo(() => {
    if (_value && typeof _value === "string") {
      return _value;
    }
    if (_value && typeof _value === "object") {
      return _value.id;
    }
    return undefined;
  }, [_value]);

  useEffect(() => {
    if (data?.getTeamMembers) {
      const teamMembers = data.getTeamMembers.filter(
        (tm: any) => !tm.preferred && (onlyUsers ? tm.user : true)
      );
      setTeamMembers(teamMembers);
    }
  }, [data, onlyUsers]);

  return (
    <LabeledSelect
      selectProps={{
        value,
        onChange: handleChange,
      }}
      label="Team Member:"
      options={options}
    ></LabeledSelect>
  );
}
