import React from "react";
import { Tabs, Tab as MuiTab, Theme, Badge } from "@mui/material";
import { TabProps } from "@mui/material/Tab";

import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

const TabBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      transform: "scale(1) translate(125%, 0)",
      fontWeight: "bold",
    },
  })
)(Badge);

export const TabGroup = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "36px",
    },
    indicator: { backgroundColor: theme.palette.electricIndigo.main },
    scrollable: { margin: "0 -40px" },
    scrollButtons: {
      backgroundColor: theme.palette.background.default,
      opacity: 1,
      zIndex: 1,
      transition: "visibility 0.2s, opacity 0.2s linear",

      "&.Mui-disabled": {
        visibility: "hidden",
        opacity: 0,
        transition: "visibility 0.2s, opacity 0.2s linear",
      },
    },
  })
)(Tabs);

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "20px",
      minHeight: "36px",
      textTransform: "none",
      fontFamily: theme.typography.body2.fontFamily,
      fontSize: theme.typography.h6.fontSize,
      // color: theme.palette.grey[900],
      "&:hover": {
        color: theme.palette.secondary.main,
      },
      padding: "6px 8px",
      overflow: "visible",
      margin: "0 28px 0 0",
    },
    selected: {
      color: `${theme.palette.electricIndigo.main} !important`,
    },
  })
)(MuiTab);

export const Tab = ({
  badgeContent = 0,
  badgeMax: max = 99,
  badgeColor: color = "primary",
  label,
  ...tabProps
}: {
  badgeContent?: number;
  badgeMax?: number;
  badgeColor?: "default" | "primary" | "secondary" | "error";
} & TabProps) => {
  return (
    <StyledTab
      label={<TabBadge {...{ color, max, badgeContent }}>{label}</TabBadge>}
      {...tabProps}
    />
  );
};
