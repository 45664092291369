import React from "react";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailPane: {
      flexGrow: 1,
      width: "100%",
      height: "100%",
      [theme.breakpoints.up("md")]: {
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("lg")]: {
        marginTop: theme.spacing(1),
      },
    },
    box: {
      minHeight: "100%",
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.down("md")]: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
    },
  })
);

export default function DetailPane({
  children = [],
  className = "",
  onScroll,
}: {
  children?: any;
  className?: string;
  onScroll?: any;
}) {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.detailPane)} onScroll={onScroll}>
      <Box paddingTop={4} className={classes.box}>
        {children}
      </Box>
    </div>
  );
}
