import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Feature } from "../reducers/features";
import TeamLayout from "../layouts/TeamLayout";
import {
  Grid,
  Avatar,
  Typography,
  Theme,
  IconButton,
  Stack,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { gql, useLazyQuery } from "@apollo/client";
import { useUser } from "../context/user";
import DetailCard from "../components/DetailCard";
import SearchField from "../components/SearchField";
import TaskList, { TaskListFilter } from "../components/tasks/List";
import ClosingsTable from "../components/deals/ClosingsTable";
import LabeledSelect from "../components/LabeledSelect";
// import Pipelines from "../components/dashboard/Pipelines";
import EventList from "../components/calendar/EventList";
import DashboardItems from "../components/dashboard/Items";
import "./TaskListAccordion.scss";
import { Task } from "../models";
import { ToggleButton, ToggleButtonGroup } from "../components/ToggleButtons";
import { Box } from "@mui/system";
import { TASK_LIST_FIELDS } from "../api/graphql";
import DashboardMenu, { DashboardMenuValue } from "../components/DashboardMenu";
import { OutlineButton } from "../components/buttons";
import { Add as AddIcon } from "@mui/icons-material";
import TaskCreateDialog from "../components/tasks/CreateDialog";
import { useAlert } from "../context/alert";

export const GET_DATA = gql`
  ${TASK_LIST_FIELDS}
  query dashboardData(
    $teamMemberId: ID
    $completed: Boolean
    $beforeDate: Date
    $afterDate: Date
  ) {
    getTasks(
      completed: $completed
      dueBefore: $beforeDate
      teamMemberId: $teamMemberId
    ) {
      ...TaskListFields
    }
    me {
      teamMember {
        tasks(completed: $completed, dueBefore: $beforeDate) {
          ...TaskListFields
        }
        deals(closingBefore: $beforeDate, closingAfter: $afterDate) {
          id
          name
          closeDate
          dealType {
            clientNoun
          }
          address {
            street
            street2
            city
            state
            zip
          }
          contacts {
            name
            avatarUrl
          }
          taskCompletionPercent
        }
      }
    }
    getPipelineData {
      dealType {
        id
        name
        clientNoun
      }
      pipeline {
        date
        count
        amount
        weeklyVariation
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: "20px",
    },
    welcomeHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    welcomeSubtitle: {
      fontSize: "20px",
      color: theme.palette.grey[600],
    },
    headerAvatar: {
      width: "68px",
      height: "68px",
      marginRight: "20px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    noTasks: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "11px",
      color: "#747b88",
      fontSize: "12px",
    },
    timeFrame: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    timeFrameSM: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
        flexDirection: "row",
        flexStart: "end",
      },
    },
    taskList: {
      [theme.breakpoints.up("sm")]: {
        overFlow: "auto",
      },
    },
  })
);

function daysFromToday(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}

function DashboardPage({ features }: { features: any }) {
  const classes = useStyles();
  const { currentUser } = useUser();
  const { showSuccess } = useAlert();
  const [beforeDate, setBeforeDate] = useState<Date>(daysFromToday(30));
  const [view, setView] = useState("me");
  const [timeframe, setTimeframe] = useState("thisMonth");
  const [open, setOpen] = useState(false);
  const [addingTask, setAddingTask] = useState(false);

  const [closingSearchTerm, setClosingSearchTerm] = useState<
    string | undefined
  >();

  const [taskFilters, setTaskFilters] = useState<{
    term: string;
    status: TaskListFilter;
  }>({
    term: "",
    status: "upcoming",
  });

  const [tasks, setTasks] = useState<Task[]>([]);
  const [getData, { data }] = useLazyQuery(GET_DATA);

  const deals = useCallback(() => {
    return data?.me?.teamMember?.deals || [];
  }, [data]);

  useEffect(() => {
    setTasks(data?.getTasks ?? []);
  }, [data]);

  const handleTaskUpdated = useCallback(
    (updatedTask: Task) => {
      setTasks((tasks) =>
        tasks.map((task: Task) =>
          task.id === updatedTask.id
            ? { ...task, ...updatedTask, disabled: false }
            : task
        )
      );
    },
    [setTasks]
  );

  const handleTaskDeleted = useCallback(
    (updatedTask: Task) => {
      setTasks((tasks) =>
        tasks.filter((task: Task) => task.id !== updatedTask.id)
      );
    },
    [setTasks]
  );

  /*
  const pipelineData = useCallback(() => {
    return data?.getPipelineData;
  }, [data]);
  */

  useEffect(() => {
    if (currentUser && currentUser.teamMember) {
      getData({
        variables: {
          view,
          teamMemberId: view === "me" ? currentUser.teamMember.id : undefined,
          completed: false,
          beforeDate: beforeDate,
          afterDate: new Date(),
        },
      });
    }
  }, [currentUser, getData, beforeDate, view]);

  const overdueTaskCount = useMemo(() => {
    const count = tasks.filter((task) => task.pastDue).length;
    const formatted = Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(count);
    return { count, formatted };
  }, [tasks]);

  const onTimeFrameSelected = (event: any) => {
    setTimeframe(event.target.value);
    switch (event.target.value) {
      case "today":
        setBeforeDate(daysFromToday(0));
        return;
      case "thisWeek":
        setBeforeDate(daysFromToday(7));
        return;
      case "thisMonth":
        setBeforeDate(daysFromToday(30));
        return;
      default:
        return;
    }
  };

  const handleDashboardChange = (value: DashboardMenuValue) => {
    setView(value.view);
    if (value.timeframe !== timeframe) {
      setTimeframe(value.timeframe);
      onTimeFrameSelected(value.timeframe);
    }
  };

  const handleTaskAddSave = (task: any) => {
    setAddingTask(false);
    showSuccess("Task Created Successfully");
    setTasks([...tasks, task]);
  };

  return (
    <TeamLayout>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} className={classes.welcomeHeader}>
          <Avatar
            src={currentUser?.avatarUrl}
            className={classes.headerAvatar}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h2">
              Welcome back {currentUser?.firstName}!
            </Typography>
            <Typography variant="subtitle1" className={classes.welcomeSubtitle}>
              Here's everything coming up.
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }}></div>

          <div className={classes.timeFrame}>
            <Box display="flex">
              {features["Dashboard Items"] && (
                <OutlineButton
                  disabled={!features["Dashboard Items"]}
                  style={{
                    background: "#FFFFFF",
                    marginRight: "10px",
                    fontSize: "14px",
                    height: "36px",
                  }}
                  onClick={() => setOpen(!open)}
                >
                  <AddIcon
                    style={{
                      width: "13px",
                      height: "13px",
                      marginRight: "10px",
                    }}
                  />
                  Add Tile
                </OutlineButton>
              )}

              <DashboardMenu
                allowTeamView={features["Dashboard Items"]}
                value={{
                  view,
                  timeframe,
                }}
                onChange={handleDashboardChange}
              />
            </Box>
            {/*}
              <LabeledSelect
                label="Show:"
                selectProps={{
                  onChange: onTimeFrameSelected,
                  defaultValue: "thisWeek",
                  style: { width: "180px" },
                }}
                options={[
                  { value: "today", label: "Today" },
                  { value: "thisWeek", label: "This Week" },
                  { value: "thisMonth", label: "This Month" },
                ]}
              />
              */}
          </div>

          <div className={classes.timeFrame}></div>
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <div className={classes.timeFrameSM}>
            <LabeledSelect
              label="Show:"
              selectProps={{
                onChange: onTimeFrameSelected,
                defaultValue: "thisWeek",
                style: { width: "180px" },
              }}
              options={[
                { value: "today", label: "Today" },
                { value: "thisWeek", label: "This Week" },
                { value: "thisMonth", label: "This Month" },
              ]}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <DashboardItems
            allowCustomization={features["Dashboard Items"]}
            timeframe={timeframe}
            view={view}
            open={open}
            setOpen={setOpen}
          />
          {/*
          <Pipelines data={pipelineData()} />
            */}
        </Grid>
        <Grid item xs={12} lg={9} xl={9}>
          <DetailCard
            title={
              <Stack direction="row" alignItems="center">
                <Typography variant="h5">Tasks</Typography>
                <IconButton size="small" onClick={() => setAddingTask(!open)}>
                  <AddIcon
                    fontSize="small"
                    sx={{ width: "14px", height: "14px" }}
                  />
                </IconButton>
              </Stack>
            }
            action={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <ToggleButtonGroup
                  exclusive
                  sx={{ marginRight: "11px" }}
                  value={taskFilters.status}
                  onChange={(event, status) => {
                    setTaskFilters((taskFilters) => ({
                      ...taskFilters,
                      status,
                    }));
                  }}
                >
                  <ToggleButton value="upcoming">Upcoming</ToggleButton>
                  <ToggleButton
                    value="overdue"
                    sx={{
                      "& > p": {
                        fontWeight: "700",
                        lineHeight: "1",
                        padding: "0 1px",
                        fontSize: "12px",
                        ...(taskFilters.status !== "overdue" && {
                          color: "#FF576D",
                          border: "2px solid #FF576D",
                          borderRadius: "5px",
                        }),
                      },
                    }}
                  >
                    Overdue
                    {overdueTaskCount.count > 0 && (
                      <>
                        &nbsp;
                        {taskFilters.status !== "overdue" ? (
                          <Typography>{overdueTaskCount.formatted}</Typography>
                        ) : (
                          <Typography>
                            ({overdueTaskCount.formatted})
                          </Typography>
                        )}
                      </>
                    )}
                  </ToggleButton>
                </ToggleButtonGroup>

                <SearchField
                  onChange={(term: string) =>
                    setTaskFilters((taskFilters) => ({ ...taskFilters, term }))
                  }
                />
              </Box>
            }
            collapsible={false}
            style={{
              height: "100%",
            }}
            className="TaskListAccordion"
          >
            <TaskList
              tasks={tasks}
              setTasks={setTasks}
              secondaryText={
                view === "team" ? "teamMember" : "contactAndAddress"
              }
              searchTerm={taskFilters.term}
              filter={taskFilters.status}
              emptyStateText="You don't have any tasks due in the selected time range."
              onTaskUpdated={handleTaskUpdated}
              onTaskDeleted={handleTaskDeleted}
              taskLinksEnabled={true}
              limitHeight
            />
            <TaskCreateDialog
              open={addingTask}
              onClose={() => setAddingTask(false)}
              onTaskCreated={handleTaskAddSave}
              assignToCurrentUser={true}
            />
          </DetailCard>
        </Grid>
        <Grid item xs={12} lg={3} xl={3}>
          <DetailCard
            title={<Typography variant="h5">Events</Typography>}
            collapsible={false}
            style={{
              height: "100%",
            }}
            className="TaskListAccordion"
          >
            <EventList limitHeight beforeDate={beforeDate} />
          </DetailCard>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DetailCard
            title={<Typography variant="h5">Closings</Typography>}
            action={
              <SearchField
                onChange={(searchTerm: string) =>
                  setClosingSearchTerm(searchTerm)
                }
              />
            }
          >
            {deals().length > 0 ? (
              <ClosingsTable
                closings={deals()}
                searchTerm={closingSearchTerm}
              />
            ) : (
              <p className={classes.noTasks}>
                You don&apos;t have any closings in the selected time range
              </p>
            )}
          </DetailCard>
        </Grid>
      </Grid>
    </TeamLayout>
  );
}

const mapStateToProps = ({ features }: { [key: string]: Feature }) => {
  return { features };
};
export default connect(mapStateToProps)(DashboardPage);
