import React, { useState, FunctionComponent, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  Paper,
} from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../buttons";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { useAlert } from "../../context/alert";
import ActionIconButton from "../ActionIconButton";
import { Close as CloseIcon, SyncAlt } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import ContactNameWithAvatar from "../contacts/ContactNameWithAvatar";

type DocuSignRoomsAccount = {
  id: string;
  name: string;
};

type WizardStep = {
  name: JSX.Element | string;
  cta?: string;
  secondaryText?: string;
};

type DocuSignEnvelopeStatus = "sent" | "created";

const USE_DOCUSIGN_TEMPLATE = gql`
  mutation UseDocuSignTemplate($input: UseDocuSignTemplateInput!) {
    useDocuSignTemplate(input: $input)
  }
`;

const initialSteps: WizardStep[] = [
  {
    name: "Pick a Template",
    secondaryText:
      "We'll help you streamline sending your DocuSign envelopes for this deal",
  },
  {
    name: "Set Recipients",
    secondaryText: "Choose who will recieve this envelope",
  },
  {
    name: "Review",
  },
];

const DocuSignCreateEnvelopeDialog: FunctionComponent<{
  deal: any;
  open: boolean;
  onClose: () => any;
  onEnvelopeCreated: () => any;
}> = ({ deal, open, onClose, onEnvelopeCreated }) => {
  const [status, setStatus] = useState<DocuSignEnvelopeStatus>("created");
  const [steps, setSteps] = useState<Array<WizardStep>>(initialSteps);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { showSuccess, showError } = useAlert();
  const [accounts, setAccounts] = useState<Array<DocuSignRoomsAccount>>();
  const [templates, setTemplates] = useState<Array<any>>();
  const [template, setTemplate] = useState<any>();
  const [accountId, setAccountId] = useState<string>();
  const [templateId, setTemplateId] = useState<string>();
  const [templateRoles, setTemplateRoles] = useState<any>({});
  const [getDocuSignAccounts, { data, loading }] = useLazyQuery(gql`
    query GetDocuSignAccounts {
      getDocuSignAccounts {
        id
        name
        isDefault
      }
      getCustomFields(tableName: "deal") {
        id
        type
        label
      }
      getSystemFields(tableName: "deal") {
        id
        type
        label
      }
    }
  `);

  const [
    getDocuSignTemplates,
    { data: templatesData, loading: loadingTemplates },
  ] = useLazyQuery(gql`
    query GetDocuSignTemplates($accountId: String!) {
      getDocuSignTemplates(accountId: $accountId) {
        id
        name
      }
    }
  `);

  const [
    getDocuSignTemplate,
    { data: templateData, loading: loadingTemplate },
  ] = useLazyQuery(gql`
    query GetDocuSignTemplate($accountId: String!, $id: String!) {
      getDocuSignTemplate(accountId: $accountId, id: $id) {
        name
        documents {
          documentId
          name
          tabs {
            prefillTabs {
              textTabs {
                tabId
                tabLabel
              }
            }
          }
        }
        recipients {
          signers {
            roleName
          }
        }
      }
    }
  `);

  const [sendDocuSignTemplate, { loading: submitting }] = useMutation(
    USE_DOCUSIGN_TEMPLATE
  );

  const resetState = () => {
    setAccountId(undefined);
    setTemplateId(undefined);
    setTemplate(undefined);
    setTemplateRoles({});
    setCurrentStepIndex(0);
  };

  const handleSubmit = async (e: any) => {
    const isLastStep = currentStepIndex + 1 === steps.length;
    if (!isLastStep) {
      // Show the next step
      setCurrentStepIndex((step) => step + 1);
      return;
    }

    const _templateRoles: {
      roleName: string;
      contactId?: string;
      teamMemberId?: string;
    }[] = [];

    for (const roleName in templateRoles) {
      _templateRoles.push({
        roleName,
        contactId: templateRoles[roleName]?.contactId || undefined,
        teamMemberId: templateRoles[roleName]?.teamMemberId || undefined,
      });
    }

    const documents: any = [];
    for (const document of template.documents) {
      const clean: any = { documentId: document.documentId, tabs: {} };
      if (document.tabs?.prefillTabs) {
        clean.tabs.prefillTabs = {};
        for (const key of ["textTabs"]) {
          clean.tabs.prefillTabs[key] = [];
          const tabs = document.tabs?.prefillTabs[key] ?? [];
          clean.tabs.prefillTabs[key] = tabs.map((tab: any) => {
            let mapping: {
              type: "value" | "field";
              columnName?: string;
              value?: string;
            };
            if (tab.mapping?.type === "value") {
              mapping = {
                type: "value",
                value: tab.mapping.value ?? "",
              };
            } else if (tab.mapping?.type === "field") {
              mapping = {
                type: "field",
                columnName: tab.mapping?.field?.id ?? null,
              };
            } else {
              mapping = {
                type: "value",
                value: "",
              };
            }
            return {
              tabLabel: tab.tabLabel,
              mapping,
            };
          });
        }
      }
      documents.push(clean);
    }

    const input = {
      dealId: deal.id,
      accountId,
      templateId,
      templateRoles: _templateRoles,
      documents,
      status,
    };

    sendDocuSignTemplate({ variables: { input } })
      .then(() => {
        const verb = status === "created" ? "saved as a draft" : "sent";
        showSuccess(`The DocuSign envelope was ${verb}`);
        resetState();
      })
      .catch(() => {
        showError("An error occurred when sending the DocuSign envelope");
      })
      .finally(() => {
        onEnvelopeCreated();
      });
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      // If the dialog is opened, fetch the DocuSign accounts
      getDocuSignAccounts();
    }
  }, [open, getDocuSignAccounts]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const accounts = data.getDocuSignAccounts ?? [];
    setAccounts(accounts);
    if (accounts.length === 0) {
      setAccountId(undefined);
    } else {
      setAccountId(accounts.find((account: any) => account.isDefault)?.id);
    }
  }, [data]);

  useEffect(() => {
    if (!accountId) {
      return;
    }
    getDocuSignTemplates({ variables: { accountId } });
  }, [accountId, getDocuSignTemplates]);

  useEffect(() => {
    setTemplates(templatesData?.getDocuSignTemplates ?? []);
  }, [templatesData]);

  useEffect(() => {
    if (!accountId || !templateId) {
      return;
    }
    getDocuSignTemplate({
      variables: {
        accountId,
        id: templateId,
      },
    });
  }, [accountId, templateId, getDocuSignTemplate]);

  useEffect(() => {
    const template = templateData?.getDocuSignTemplate;
    if (!template || !data) {
      return;
    }

    const normalize = (label: string) =>
      label.replace(/[^0-9a-z]/gi, "").toLowerCase();

    const fieldMap: { [key: string]: any } = {};
    const fields = [...data.getSystemFields];
    for (const field of fields) {
      const key = normalize(field.label);
      if (!fieldMap[key]) {
        fieldMap[key] = field;
      }
    }

    const documentSteps: WizardStep[] = [];
    for (const document of templateData?.getDocuSignTemplate.documents) {
      const tabs = document.tabs?.prefillTabs?.textTabs ?? [];
      if (tabs.length === 0) {
        continue;
      }
      documentSteps.push({
        name: (
          <>
            Pre-fill <em>{document.name}</em>
          </>
        ),
        cta: "Pre-fill Document",
        secondaryText:
          "You can map the following pre-fill tabs on this document to this deal's values or you can enter values manually",
      });
      for (const tab of tabs) {
        const field = fieldMap[normalize(tab.tabLabel)] ?? null;
        tab.mapping = {
          type: "field",
          field,
          value: "",
        };
      }
    }

    const steps = [...initialSteps];
    steps.splice(2, 0, ...documentSteps);
    setSteps(steps);
    setTemplate(templateData?.getDocuSignTemplate);
  }, [data, templateData]);

  const updateMapping = (
    documentIndex: number,
    tabIndex: number,
    partialMapping: {
      type?: string;
      field?: any;
      value?: string;
    }
  ) => {
    setTemplate((template: any) => {
      const copy = Object.assign({}, template);
      const tab =
        copy.documents[documentIndex]?.tabs?.prefillTabs?.textTabs[tabIndex];
      if (tab) {
        tab.mapping = { ...tab.mapping, ...partialMapping };
      }
      return copy;
    });
  };

  const nextStepCta = () => {
    const nextStep = steps[currentStepIndex + 1];
    if (nextStep) {
      return nextStep.cta ?? nextStep.name ?? "Next";
    } else {
      return status === "created" ? "Create Draft Envelope" : "Send Envelope";
    }
  };

  const isStepValid = () => {
    if (loading || loadingTemplates || loadingTemplate || submitting) {
      // If we're loading data from DocuSign, we'll make the user wait.
      return false;
    }
    if (currentStepIndex === 0) {
      // The user needs to have selected a template.
      return template;
    } else if (currentStepIndex === 1) {
      // The user needs to have added a recipient
      return Object.values(templateRoles).filter((value) => !!value).length > 0;
    }
    return true;
  };

  const recipientOptions = deal.contacts.map((contact: any) => ({
    contactId: contact.id,
    name: contact.name,
    avatarUrl: contact.avatarUrl,
    title: deal.dealType.clientNoun,
  }));
  recipientOptions.push(
    ...(deal?.dealTeamMembers.map((dealTeamMember: any) => ({
      teamMemberId: dealTeamMember.teamMember.id,
      name: dealTeamMember.teamMember.name,
      avatarUrl: dealTeamMember.teamMember.avatarUrl,
      title: dealTeamMember.role.name,
    })) ?? [])
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">
            {steps[currentStepIndex]?.name ?? "DocuSign"}
          </Typography>
          <ActionIconButton
            icon={CloseIcon}
            style={{ margin: "4px -5px" }}
            buttonStyle={{ width: "22px", height: "22px" }}
            onClick={handleClose}
            aria-label="close"
          />
        </Box>
        <Typography variant="body2" color="textSecondary">
          {steps[currentStepIndex]?.secondaryText}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {currentStepIndex === 0 && (
          <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="docusignAccount">Account</InputLabel>
                <Select
                  native
                  label="Account"
                  inputProps={{ id: "docusignAccount" }}
                  value={accountId ?? ""}
                  onChange={(e) => {
                    setAccountId(e.target.value as string);
                  }}
                >
                  {accounts?.map((account) => (
                    <option key={account.id} value={account.id}>
                      {account.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="docusignTemplate">Template</InputLabel>
                <Select
                  native
                  label="Template"
                  inputProps={{ id: "docusignTemplate" }}
                  value={templateId ?? ""}
                  onChange={(e) => {
                    setTemplateId(e.target.value as string);
                  }}
                >
                  <option></option>
                  {templates?.map((template) => (
                    <option key={template.id} value={template.id}>
                      {template.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

        {currentStepIndex === 1 && (
          <Grid container spacing={2}>
            {template?.recipients?.signers?.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Signers</Typography>
                </Grid>
                {template?.recipients?.signers.map(
                  (signer: any, index: number) => (
                    <Grid item xs={12} key={index}>
                      <Autocomplete
                        id={`signer${index}`}
                        value={templateRoles[signer.roleName] ?? null}
                        options={recipientOptions}
                        onChange={(e, value) => {
                          setTemplateRoles((templateRoles: any) =>
                            Object.assign({}, templateRoles, {
                              [signer.roleName]: value,
                            })
                          );
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props: any, option: any) => (
                          <Box component="li" {...props}>
                            <ContactNameWithAvatar
                              name={option.name}
                              title={option.title}
                              avatarUrls={[option.avatarUrl]}
                            />
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={signer.roleName}
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                  )
                )}
              </>
            )}
          </Grid>
        )}

        {template?.documents?.map((document: any, documentIndex: number) => (
          <React.Fragment key={`document${documentIndex}`}>
            {currentStepIndex === 2 + documentIndex && (
              <TableContainer component={Paper}>
                <Table>
                  {document.tabs?.prefillTabs?.textTabs?.map(
                    (tab: any, tabIndex: number) => (
                      <TableRow key={`document${documentIndex}tab${tabIndex}`}>
                        <TableCell>
                          <Typography>{tab.tabLabel}</Typography>
                        </TableCell>
                        <TableCell>
                          <SyncAlt />
                        </TableCell>
                        <TableCell>
                          <Select
                            native
                            fullWidth
                            value={tab.mapping?.type ?? "field"}
                            onChange={(e) => {
                              updateMapping(documentIndex, tabIndex, {
                                type: (e.target.value as string) ?? "",
                              });
                            }}
                          >
                            <option value="field">Field</option>
                            <option value="value">Value</option>
                          </Select>
                        </TableCell>
                        <TableCell>
                          {tab.mapping?.type === "field" && (
                            <Autocomplete
                              fullWidth
                              id={`tab${documentIndex}${tabIndex}`}
                              value={tab.mapping?.field || null}
                              options={data?.getSystemFields ?? []}
                              onChange={(e, field) => {
                                updateMapping(documentIndex, tabIndex, {
                                  field,
                                });
                              }}
                              getOptionLabel={(option: any) => option.label}
                              renderInput={(params) => (
                                <TextField {...params} variant="standard" />
                              )}
                            />
                          )}
                          {tab.mapping?.type === "value" && (
                            <TextField
                              variant="standard"
                              fullWidth
                              autoFocus
                              value={tab.mapping.value}
                              onChange={(e) =>
                                updateMapping(documentIndex, tabIndex, {
                                  value: e.target.value ?? "",
                                })
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </Table>
              </TableContainer>
            )}
          </React.Fragment>
        ))}
        {currentStepIndex === steps.length - 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel>Envelope Status</FormLabel>
                <RadioGroup
                  aria-label="envelope status"
                  name="envelopeStatus"
                  value={status}
                  onChange={(e, value: string) => {
                    setStatus(value as DocuSignEnvelopeStatus);
                  }}
                >
                  <FormControlLabel
                    value="created"
                    control={<Radio />}
                    label="Save as a Draft"
                  />
                  <FormControlLabel
                    value="sent"
                    control={<Radio />}
                    label="Send Immediately"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          fullWidth
          onClick={() => setCurrentStepIndex((index) => index - 1)}
          disabled={currentStepIndex === 0}
        >
          &laquo;&nbsp;Back
        </SecondaryButton>
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={!isStepValid()}
          onClick={handleSubmit}
        >
          {nextStepCta()}
          {!!steps[currentStepIndex + 1] && <span>&nbsp;&raquo;</span>}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
export default DocuSignCreateEnvelopeDialog;
