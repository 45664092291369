import React, { useState, FunctionComponent, useRef } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Box,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Skeleton,
  SelectChangeEvent,
  Paper,
  Alert,
  AlertTitle,
  List,
  ListItem,
} from "@mui/material";

import { PrimaryButton } from "../buttons";
import CreateFromAutocomplete from "./CreateFromAutocomplete";
import { DealType } from "../../models";
import { useCreateFromDotloop } from "./useCreateFromDotloop";
import { useCreateFromSkySlope } from "./useCreateFromSkySlope";
import StageForm from "../stages/StageForm";
import { cleanCustomFields, cleanDateValues } from "../../helpers/fields";
import { gql, useMutation } from "@apollo/client";
import { useAlert } from "../../context/alert";

type IntegrationType = "dotloop" | "skyslope" | "docusign";
const CreateFromIntegration: FunctionComponent<{
  open: boolean;
  dealTypes: DealType[];
  currentIntegrations: IntegrationType[];
  onDealCreated: Function;
  onClose: (e: React.ChangeEvent) => void;
  integrationType: IntegrationType | null;
  setIntegrationType: (value: IntegrationType) => void;
}> = ({
  dealTypes,
  onDealCreated,
  currentIntegrations,
  onClose,
  integrationType,
  setIntegrationType,
}) => {
  const [updateDeal] = useMutation(gql`
    mutation UpdateDeal($input: UpdateDealInput!) {
      updateDeal(input: $input) {
        id
      }
    }
  `);

  const [deal, setDeal] = useState<any>(null);
  const stageFormData = useRef<any>({
    customFields: [],
  });

  const [selectedDealType, setSelectedDealType] = useState<
    DealType | undefined
  >();
  const [selectedStage, setSelectedStage] = useState<any>(null);
  const [importLoading, setImportLoading] = useState(false);

  const { showError } = useAlert();

  const {
    profileId,
    profiles,
    loading: dotLoopLoading,
    setProfileId,
    // selectedLoop,
    setSelectedLoop,
    dotLoopSubmit,
    importDeal: dotloopImportDeal,
  } = useCreateFromDotloop(integrationType === "dotloop");

  const {
    offices,
    skySlopeLoading,
    selectedOfficeId,
    setSelectedOfficeId,
    agents,
    selectedAgent,
    setSelectedAgent,
    selectedTransactionType,
    setSelectedTransactionType,
    skySlopeTransactions,
    setSelectedTransaction,
    skySlopeSubmit,
    importDeal: skyslopeImportDeal,
  } = useCreateFromSkySlope(integrationType === "skyslope");

  // const {} = useCreateFromDocusign(integrationType === "Docusign");

  const loading = dotLoopLoading || skySlopeLoading || importLoading;

  const importFuncs = {
    dotloop: dotloopImportDeal,
    skyslope: skyslopeImportDeal,
    docusign: () => Promise.reject("Not implemented"),
  };

  const handleImport = async () => {
    if (integrationType === null) {
      return;
    }

    if (!deal) {
      /**
       * If we don't have a deal, we'll run the import function
       */
      const importDeal = importFuncs[integrationType];
      if (!importDeal) {
        return;
      }
      setImportLoading(true);
      const deal = await importDeal();
      setImportLoading(false);
      setDeal(deal);
      setSelectedDealType(dealTypes.find((dt) => dt.id === deal.dealType.id));
    } else {
      /**
       * If we have a deal, we'll create the deal, we're really submitting the
       * stage form
       */
      let formData = cleanDateValues(stageFormData.current);
      formData = cleanCustomFields(formData);
      const formUpdates: any = {
        id: deal.id,
        stageId: selectedStage.id,
        ...formData,
        mlsKey: formData.mlsKey === "" ? null : formData.mlsKey,
      };
      updateDeal({ variables: { input: formUpdates } })
        .then((res) => {
          onDealCreated(deal);
        })
        .catch((err) => {
          showError("An error occured when putting the deal on a stage");
          console.error(err);
        });
    }
  };

  const handleStageChange = (e: SelectChangeEvent) => {
    const stageId = e.target.value as string;
    if (stageId === "") {
      setSelectedStage(null);
      return;
    }
    const stage = selectedDealType?.stages.find((s) => s.id === stageId);
    setSelectedStage(stage);
  };

  const canSubmit =
    (integrationType === "skyslope" && skySlopeSubmit && !deal) ||
    (integrationType === "dotloop" && dotLoopSubmit && !deal) ||
    (selectedStage && deal);

  return (
    <>
      <Box
        p={4}
        style={{
          background: "#F5F5F5",
          height: "100%",
          overflow: "auto",
        }}
      >
        {loading && (
          <Box textAlign="center" mb={2}>
            <CircularProgress size="24px" />
          </Box>
        )}
        {!loading && !deal && (
          <Paper
            style={{
              background: "#FFFFFF",
            }}
          >
            <Box p={2}>
              <Grid container spacing={2}>
                {!integrationType && (
                  <Box
                    p={2}
                    style={{
                      margin: "auto",
                    }}
                  >
                    <FormLabel>
                      Where do you want to import a deal from?
                    </FormLabel>
                    <List
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      {currentIntegrations.map((integration) => {
                        return (
                          <Paper>
                            <ListItem
                              onClick={() => setIntegrationType(integration)}
                              sx={{
                                cursor: "pointer",
                                marginBottom: "8px",
                                justifyContent: "center",
                                "&:hover": {
                                  background: "#f4f5f7",
                                },
                              }}
                              key={integration}
                            >
                              <img
                                alt={integration + "logo"}
                                src={`/images/integrations/${integration}${
                                  integration === "skyslope" ? ".svg" : ".png"
                                }`}
                                style={{
                                  marginBottom: "5px",
                                  maxHeight: "30px",
                                }}
                              />
                            </ListItem>
                          </Paper>
                        );
                      })}
                    </List>
                  </Box>
                )}
                {deal == null && (
                  <>
                    {integrationType === "dotloop" && (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="dotloopProfile">
                              Dotloop Profile
                            </InputLabel>
                            <Select
                              native
                              label="Profile"
                              inputProps={{ id: "dotloopProfile" }}
                              value={profileId}
                              onChange={(e) => {
                                setProfileId(e.target.value as string);
                              }}
                            >
                              {profiles.map((profile) => (
                                <option key={profile.id} value={profile.id}>
                                  {profile.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <CreateFromAutocomplete
                            profileId={profileId}
                            onSelected={(loop) => setSelectedLoop(loop)}
                            label="Loop"
                          />
                        </Grid>
                      </>
                    )}
                    {integrationType === "skyslope" && (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="skyslopeOffice">
                              Offices
                            </InputLabel>
                            <Select
                              native
                              label="Office"
                              inputProps={{ id: "skyslopeOffice" }}
                              value={selectedOfficeId ?? ""}
                              onChange={(e) => {
                                setSelectedOfficeId(e.target.value as string);
                              }}
                            >
                              {offices?.map((office) => (
                                <option key={office.id} value={office.id}>
                                  {office.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="skyslopeAgent">
                              Agent
                            </InputLabel>
                            {loading ? (
                              <Skeleton variant="rectangular" />
                            ) : (
                              <Select
                                label="Agent"
                                native
                                inputProps={{ id: "skyslopeAgent" }}
                                value={selectedAgent?.id ?? ""}
                                onChange={(e) => {
                                  setSelectedAgent(
                                    agents?.find((a) => a.id === e.target.value)
                                  );
                                }}
                              >
                                <option></option>
                                {agents?.map((agent) => (
                                  <option key={agent.id} value={agent.id}>
                                    {agent.firstName} {agent.lastName}
                                  </option>
                                ))}
                              </Select>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="standard">
                            <FormLabel>
                              Which type of SkySlope Transaction?
                            </FormLabel>
                            <RadioGroup
                              aria-label="skySlopeTransactionType"
                              name="skySlopeTransactionType"
                              value={selectedTransactionType ?? ""}
                              onChange={(e: any) => {
                                setSelectedTransactionType(e.target.value);
                              }}
                            >
                              <FormControlLabel
                                value="Purchase"
                                control={<Radio size="small" />}
                                label="Purchase"
                              />
                              <FormControlLabel
                                value="Listing"
                                control={<Radio size="small" />}
                                label="Listing"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <CreateFromAutocomplete
                              optionData={skySlopeTransactions || []}
                              onSelected={(transaction) =>
                                setSelectedTransaction(transaction)
                              }
                              label={selectedTransactionType}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          </Paper>
        )}
        {deal && (
          <>
            {!selectedStage && (
              <Grid item xs={12}>
                <Alert variant="outlined">
                  <AlertTitle>{deal.street ?? "Deal"} Imported</AlertTitle>A
                  stage has not been set. Selecting a stage below will send
                  triggered communication and assign tasks.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper style={{ marginBottom: "12px", marginTop: "12px" }}>
                <Box p={2}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="select-stage">
                      Choose a Stage for this Deal
                    </InputLabel>
                    <Select
                      label="Stage"
                      native
                      required
                      inputProps={{ id: "select-stage" }}
                      value={selectedStage?.id ?? ""}
                      onChange={handleStageChange}
                    >
                      <option></option>
                      {selectedDealType?.stages.map((stage: any) => (
                        <option key={stage.id} value={stage.id}>
                          {stage.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Paper>
              {selectedStage && (
                <StageForm
                  stage={selectedStage}
                  deal={deal}
                  onChange={(data) => {
                    stageFormData.current = data;
                  }}
                />
              )}
            </Grid>
          </>
        )}
      </Box>
      <Box
        p={2}
        style={{ background: "#F5F5F5", marginRight: "15px" }}
        display="flex"
        justifyContent="right"
      >
        {integrationType && (
          <Grid container xs={6}>
            <PrimaryButton
              fullWidth
              type="submit"
              disabled={!canSubmit}
              onClick={handleImport}
            >
              {deal ? "Set Stage" : "Import Deal"}
            </PrimaryButton>
          </Grid>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = ({ dealTypes }: { dealTypes: DealType[] }) => {
  return { dealTypes };
};
export default connect(mapStateToProps)(CreateFromIntegration);
