import React from "react";
import { Button, Theme } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";

type ButtonHeight = "full" | "short";

type ButtonProps = {
  children: any;
  height: ButtonHeight;
  disabled?: boolean;
};

export function PrimaryButton({
  children,
  height = "full",
  ...props
}: ButtonProps & any) {
  const heightMap = {
    full: "32px",
    short: "24px",
  };

  const StyledButton = withStyles({
    root: {
      textTransform: "unset",
      fontSize: "12px",
      fontWeight: 600,
      height: heightMap[height as ButtonHeight],
      padding: "0 16px",
      minWidth: "32px",
    },
  })(Button);

  return (
    <StyledButton
      variant="contained"
      color="primary"
      disableElevation
      {...props}
    >
      {children}
    </StyledButton>
  );
}

export function SecondaryButton({ children, ...props }: any) {
  const StyledButton = withStyles({
    root: {
      textTransform: "unset",
      fontSize: "12px",
      fontWeight: 600,
    },
  })(Button);

  return (
    <StyledButton color="secondary" {...props}>
      {children}
    </StyledButton>
  );
}

export function OutlineButton({ children, height = "full", ...props }: any) {
  const heightMap = {
    full: "32px",
    short: "24px",
  };
  const StyledButton = withStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: "unset",
        fontSize: "12px",
        fontWeight: 500,
        height: heightMap[height as ButtonHeight],
        border: `1px solid ${theme.palette.grey[300]}`,
      },
    })
  )(Button);

  return (
    <StyledButton variant="outlined" {...props} title="foo">
      {children}
    </StyledButton>
  );
}

export function WhiteButton({ children, height = "full", ...props }: any) {
  const heightMap = {
    full: "32px",
    short: "24px",
  };
  const StyledButton = withStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: "unset",
        fontSize: "12px",
        fontWeight: 600,
        height: heightMap[height as ButtonHeight],
        backgroundColor: theme.palette.grey[300],
        border: "unset",
        color: theme.palette.secondary.main,
        "&:hover": {
          backgroundColor: theme.palette.grey[400],
        },
      },
    })
  )(Button);

  return (
    <StyledButton variant="outlined" {...props}>
      {children}
    </StyledButton>
  );
}
