import { Stage } from "../models";

export const STAGES = {
  SET: "STAGES/set",
  ADD: "STAGES/add",
  REMOVE: "STAGES/remove",
  UPDATE: "STAGES/update",
  ADD_EMAIL: "STAGES/add_email",
  REMOVE_EMAIL: "STAGES/remove_email",
  UPDATE_EMAIL: "STAGES/update_email",
  ADD_SMS: "STAGES/add_sms",
  REMOVE_SMS: "STAGES/remove_sms",
  UPDATE_SMS: "STAGES/update_sms",
  ADD_TASK: "STAGES/add_task",
  REMOVE_TASK: "STAGES/remove_task",
  UPDATE_TASK: "STAGES/update_task",
  ADD_FIELD: "STAGES/add_field",
  REMOVE_FIELD: "STAGES/remove_field",
  UPDATE_FIELD: "STAGES/update_field",
  COPY_STAGE: "STAGES/copy_stage",
};

const initialState: Stage[] = [];

export default function stagesReducer(state = initialState, action: any) {
  switch (action.type) {
    case STAGES.SET:
      return [...action.stages];
    case STAGES.ADD:
      return [...state, action.stage];
    case STAGES.COPY_STAGE:
      return [...state, action.stage];
    case STAGES.REMOVE:
      return state.filter((stage: Stage) => stage.id !== action.stage.id);
    case STAGES.UPDATE:
      return state.map((stage) => {
        if (stage.id === action.stage.id) {
          return { ...stage, ...action.stage };
        } else {
          return stage;
        }
      });
    case STAGES.ADD_EMAIL:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return { ...stage, emails: [...stage.emails, action.stageEmail] };
        } else {
          return stage;
        }
      });
    case STAGES.REMOVE_EMAIL:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return {
            ...stage,
            emails: stage.emails.filter((email: any) => email.id !== action.id),
          };
        } else {
          return stage;
        }
      });
    case STAGES.UPDATE_EMAIL:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return {
            ...stage,
            emails: stage.emails
              .map((email) => {
                if (email.id === action.stageEmail.id) {
                  return { ...action.stageEmail };
                } else {
                  return email;
                }
              })
              .sort((a, b) => a.sort - b.sort),
          };
        } else {
          return stage;
        }
      });
    case STAGES.ADD_SMS:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return { ...stage, sms: [...stage.sms, action.stageSms] };
        } else {
          return stage;
        }
      });
    case STAGES.REMOVE_SMS:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return {
            ...stage,
            sms: stage.sms.filter((sms: any) => sms.id !== action.id),
          };
        } else {
          return stage;
        }
      });
    case STAGES.UPDATE_SMS:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return {
            ...stage,
            sms: stage.sms
              .map((sms) => {
                if (sms.id === action.stageSms.id) {
                  return { ...action.stageSms };
                } else {
                  return sms;
                }
              })
              .sort((a, b) => a.sort - b.sort),
          };
        } else {
          return stage;
        }
      });
    case STAGES.ADD_TASK:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return { ...stage, tasks: [...stage.tasks, action.stageTask] };
        } else {
          return stage;
        }
      });
    case STAGES.REMOVE_TASK:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return {
            ...stage,
            tasks: stage.tasks.filter((task: any) => task.id !== action.id),
          };
        } else {
          return stage;
        }
      });
    case STAGES.UPDATE_TASK:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return {
            ...stage,
            tasks: stage.tasks
              .map((task) => {
                if (task.id === action.stageTask.id) {
                  return { ...action.stageTask };
                } else {
                  return task;
                }
              })
              .sort((a, b) => a.sort - b.sort),
          };
        } else {
          return stage;
        }
      });
    case STAGES.ADD_FIELD:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return { ...stage, fields: [...stage.fields, action.stageField] };
        } else {
          return stage;
        }
      });
    case STAGES.REMOVE_FIELD:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return {
            ...stage,
            fields: stage.fields.filter((field: any) => field.id !== action.id),
          };
        } else {
          return stage;
        }
      });
    case STAGES.UPDATE_FIELD:
      return state.map((stage) => {
        if (stage.id === action.stageId) {
          return {
            ...stage,
            fields: stage.fields
              .map((field) => {
                if (field.id === action.stageField.id) {
                  return { ...action.stageField };
                } else {
                  return field;
                }
              })
              .sort((a, b) => a.sort - b.sort),
          };
        } else {
          return stage;
        }
      });
    default:
      return state;
  }
}
