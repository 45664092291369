import { Box, Link, Alert } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const NylasSendAlert = () => {
  return (
    <Alert
      icon={false}
      severity="warning"
      sx={{ marginRight: 1, marginLeft: 1 }}
    >
      <Box component="span">
        Please&nbsp;
        <Link to="/settings/email" component={RouterLink}>
          re-link your email
        </Link>
        &nbsp; to avoid sending interruption
      </Box>
    </Alert>
  );
};
