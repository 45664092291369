import { gql } from "@apollo/client";
import { ApiClient } from "../context/api";
import { DealType, Stage } from "../models";
import { STAGES } from "../reducers/stages";

const CREATE_STAGE = gql`
  mutation CreateStage($input: CreateStageInput!) {
    createStage(input: $input) {
      id
      name
      dealType {
        id
        name
        clientNoun
        dealOwnerRole {
          id
        }
      }
      sort
      dealState
      emails {
        id
        toDealContacts
        toRoleId
        fromRoleId
        emailTemplateId
        hasSendDate
        sendDateDays
        sendDatePreposition
        sendDateTargetColumnName
        sendDateTargetCustomFieldId
      }
      tasks {
        id
        name
        toDealContacts
        toRoleId
        hasDueDate
        dueDateDays
        dueDatePreposition
        dueDateTargetColumnName
        dueDateTargetCustomFieldId
      }
      fields {
        id
        customFieldId
        columnName
        field {
          label
        }
      }
    }
  }
`;

const DELETE_STAGE = gql`
  mutation DeleteStage($id: ID!) {
    deleteStage(id: $id)
  }
`;

const UPDATE_STAGE = gql`
  mutation UpdateStage($input: UpdateStageInput!) {
    updateStage(input: $input) {
      id
      name
      sort
      dealState
      dealType {
        id
        name
        clientNoun
        dealOwnerRole {
          id
        }
      }
    }
  }
`;

export type CreateStageInput = {
  name: string;
  dealTypeId: string;
  dealState: string;
};

export type UpdateStageInput = {
  id: string;
  sort: number;
  dealState: string;
  name: string;
};

export const createStage =
  (stage: CreateStageInput, apolloClient: ApiClient) =>
  async (dispatch: any, getState: () => any) => {
    const dealType: DealType = getState().dealTypes.find(
      (dealType: DealType) => dealType.id === stage.dealTypeId
    );

    const response = await apolloClient.mutate({
      mutation: CREATE_STAGE,
      variables: { input: { ...stage, sort: dealType.stages.length + 1 } },
    });

    dispatch({ type: STAGES.ADD, stage: response.data.createStage });
  };

export const deleteStage =
  (stage: Stage, apolloClient: ApiClient) => async (dispatch: any) => {
    await apolloClient.mutate({
      mutation: DELETE_STAGE,
      variables: {
        id: stage.id,
      },
    });

    dispatch({ type: STAGES.REMOVE, stage });
  };

export const copyStageAction = (copyStage: Stage) => async (dispatch: any) => {
  dispatch({ type: STAGES.COPY_STAGE, stage: copyStage });
};

export const updateStage =
  (stage: UpdateStageInput, apolloClient: ApiClient) =>
  async (dispatch: any) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_STAGE,
      variables: {
        input: stage,
      },
    });

    const updatedStage = response.data.updateStage;

    dispatch({ type: STAGES.UPDATE, stage: updatedStage });
  };
