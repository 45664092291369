import React from "react";
import { IconButton, Theme, SvgIconTypeMap, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import clsx from "clsx";

const StyledIconButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[100],
      width: "32px",
      height: "32px",
      marginLeft: "9px",
      marginRight: "9px",
      padding: "0",
    },
  })
)(IconButton);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: "18px",
      height: "18px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    label: {
      fontSize: "10px",
      marginTop: "2px",
    },
  })
);

type ActionIconButtonProps = {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  label?: string;
  style?: any;
  buttonStyle?: any;
  className?: string;
};

const ActionIconButton = React.forwardRef(
  (
    {
      icon: Icon,
      label,
      style,
      className,
      buttonStyle,
      ...rest
    }: ActionIconButtonProps & any,
    ref
  ) => {
    const classes = useStyles();

    return (
      <div className={clsx(classes.container, className)} style={style}>
        <StyledIconButton ref={ref} style={buttonStyle} {...rest}>
          <Icon className={classes.icon} />
        </StyledIconButton>

        {label && (
          <Typography variant="caption" className={classes.label}>
            {label}
          </Typography>
        )}
      </div>
    );
  }
);

export default ActionIconButton;
