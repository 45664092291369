import React, { useState, cloneElement } from "react";
import { SecondaryButton, PrimaryButton } from "../../components/buttons";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { connect } from "react-redux";
import { createStage, CreateStageInput } from "../../actions/stages";
import { ApiClient, useApi } from "../../context/api";

type CreateDialogProps = {
  createStage: (
    stage: CreateStageInput,
    apolloClient: ApiClient
  ) => Promise<any>;
  dealTypeId: string;
  children: any;
};

const CreateDialog = ({
  createStage,
  dealTypeId,
  children,
}: CreateDialogProps) => {
  const { apolloClient } = useApi();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<any>([]);

  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  function handleCreate() {
    createStage(
      {
        dealTypeId: dealTypeId,
        name,
        dealState: "closed",
      },
      apolloClient
    )
      .then((res) => {
        setName("");
        setOpen(false);
      })
      .catch(() => {});
  }

  return (
    <>
      {cloneElement(children, { onClick: handleOpen })}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a Stage</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Name your stage and click “Add Stage” below. Stages will show up on
            your Deals page.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                onChange={(e: any) => setName(e.target.value)}
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose} color="primary">
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={handleCreate} color="primary">
            Add Stage
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(null, { createStage })(CreateDialog);
