import React, { ChangeEvent, useState, Dispatch, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  Link,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK, DELETE_TASK } from "../../api/graphql";
import { useAlert } from "../../context/alert";
import clsx from "clsx";
import TaskCreateDialog from "./CreateDialog";
import CreateNote from "../notes/CreateNote";
import TaskNotes from "./TaskNotes";
import { MoreVert } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { CheckboxUncheckedIcon, CheckboxCheckedIcon } from "../../icons";
import { Deal, Task } from "../../models";
import { getFullAddress } from "../../helpers/address";
import { makeMoment } from "../../helpers";
import { Stack } from "@mui/system";

interface TaskListProps {
  tasks: Task[];
  deal?: Deal;
  secondaryText?: "teamMember" | "contactAndAddress" | "none";
  editable?: boolean;
  reportView?: boolean;
  searchTerm?: string | undefined;
  emptyStateText?: string | undefined;
  filter?: TaskListFilter;
  delayCompletedUpdates?: boolean;
  onTaskUpdated?: (task: Task) => void;
  onTaskDeleted?: (task: Task) => void;
  taskLinksEnabled?: boolean;
  limitHeight?: boolean;
  setTasks?: any;
  dispatch?: Dispatch<any>;
  dispatchType?: any;
}

export type TaskListFilter =
  | undefined
  | "none"
  | "active"
  | "upcoming"
  | "completed"
  | "unstarted"
  | "started"
  | "overdue";

const useStyles = makeStyles((theme: Theme) => ({
  noTasks: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    padding: "11px",
    color: "#747b88",
    textAlign: "center",
  },
  taskList: {
    width: "100%",
    padding: "0",
    height: (props: any) => (props.limitHeight ? "498px" : "unset"),
    overflowY: "scroll",
  },
  taskListItemText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "11px",
  },
  taskListItem: {
    borderLeft: "4px solid #fff",
    display: "grid",
    gridTemplateColumns: "1fr 12fr 1fr",
  },
  taskListItemPastDue: {
    borderLeft: "4px solid #cf4440",
  },
  taskListBorderBottom: {
    borderBottom: "1px solid #e5e7eb",
  },
  taskTeamMember: {
    color: "#747b88",
    fontSize: "13px",
  },
  taskDueDate: {
    fontSize: "12px",
    textDecoration: "none",
  },
  editable: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  taskDueDateUpcoming: {
    color: theme.palette.grey[700],
  },
  taskDueLaterToday: {
    color: theme.palette.success.main,
  },
  taskDueDatePastDue: {
    color: "#cf4440",
  },
  taskName: {
    color: "#161e2e",
    fontSize: "13px",
    fontWeight: 600,
    marginBottom: "4px",
    textDecoration: "none",
  },
  taskNameCompleted: {
    textDecoration: "line-through",
  },
  link: {
    fontSize: "13px",
    color: "#747b88",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function TaskList({
  tasks,
  deal,
  secondaryText = "teamMember",
  editable = true,
  reportView = false,
  searchTerm,
  emptyStateText,
  filter = "none",
  delayCompletedUpdates = true,
  onTaskUpdated = () => {},
  onTaskDeleted = () => {},
  taskLinksEnabled = false,
  limitHeight = false,
  setTasks,
  dispatch,
  dispatchType,
}: TaskListProps): JSX.Element {
  const classes = useStyles({ limitHeight });
  const [menuAnchor, setMenuAnchor] = useState<any>(null);
  const [editing, setEditing] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);

  const { showError, showSuccess } = useAlert();

  const [updateTask] = useMutation(UPDATE_TASK);
  const [deleteTask] = useMutation(DELETE_TASK);

  const handleEditTask = (e: any, task: any) => {
    e.preventDefault();
    setSelectedTask(task);
    setEditing(true);
  };

  const handleTaskEditClose = () => {
    setSelectedTask(null);
    setEditing(false);
  };

  const handleTaskEditSave = (task: any) => {
    setSelectedTask(null);
    setEditing(false);
    onTaskUpdated(task);
  };

  const changeCompleted = (task: any, completed: boolean) => {
    const variables = {
      input: {
        id: task.id,
        completed: completed,
      },
    };
    updateTask({ variables })
      .then((res) => {
        onTaskUpdated(res.data.updateTask);
      })
      .catch(() => {
        showError("An error occurred updating the task.");
      });
  };

  const handleCompletedChange = (
    e: ChangeEvent,
    task: any,
    completed: boolean
  ) => {
    onTaskUpdated({ ...task, checked: completed, disabled: true });
    const delay = delayCompletedUpdates ? 1000 : 0;
    setTimeout(() => changeCompleted(task, completed), delay);
  };

  const handleMenuClick = (e: any, task: any) => {
    setSelectedTask(task);
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleEditClick = () => {
    setMenuAnchor(null);
    setEditing(true);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    if (!selectedTask) {
      return;
    }
    deleteTask({
      variables: {
        id: selectedTask.id,
      },
    })
      .then(() => {
        onTaskDeleted(selectedTask);
        showSuccess(`Task "${selectedTask.name}" was deleted`);
      })
      .catch(() => {
        showError("An error occurred when deleting the task");
      });
  };

  const TaskDueDate = ({ task }: { task: any }): JSX.Element => {
    let dueAt = task.dueAt ? makeMoment(task.dueAt) : null;
    let format = "MMMM D";
    if (!task.allDay) {
      format += ", LT";
    }
    if (task.completed) {
      return (
        <span className={classes.taskDueDate}>
          Completed {moment(task.completedAt).format(format)}
        </span>
      );
    } else if (dueAt && dueAt.isValid()) {
      const dueLaterToday = dueAt.isSame(moment(), "day") && !task.pastDue;
      return (
        <span
          className={clsx({
            [classes.taskDueDate]: true,
            [classes.taskDueLaterToday]: dueLaterToday,
            [classes.taskDueDateUpcoming]: !dueLaterToday && !task.pastDue,
            [classes.taskDueDatePastDue]: task.pastDue,
          })}
        >
          Due {dueAt.format(format)}
        </span>
      );
    } else if (task.dueAtPreview) {
      const previewArray = task.dueAtPreview.split(" ");

      const prefix =
        previewArray[1] === "Days" || previewArray[1] === "Day"
          ? "Due "
          : "Due on ";

      return (
        <span
          className={clsx({
            [classes.taskDueDate]: true,
            [classes.taskDueDateUpcoming]: true,
          })}
        >
          {prefix}
          {task.dueAtPreview}
        </span>
      );
    } else if (editable) {
      return (
        <Link
          href="#"
          color="secondary"
          onClick={(e: any) => handleEditTask(e, task)}
          className={clsx({
            [classes.taskDueDate]: true,
            [classes.editable]: true,
          })}
        >
          Set a Due Date
        </Link>
      );
    } else {
      return <></>;
    }
  };

  const filteredTasks = useMemo(() => {
    let filtered: Task[] = [...tasks];
    if (searchTerm) {
      const lowerCaseTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((task) => {
        // Match address
        let addressMatch = false;
        if (task.deal?.address?.street) {
          addressMatch = getFullAddress(task.deal.address)
            .toLowerCase()
            .includes(lowerCaseTerm);
        }

        // Match contact name
        let contactMatch = false;
        if (task.contact?.name) {
          contactMatch = task.contact.name
            .toLowerCase()
            .includes(lowerCaseTerm);
        } else if (task.deal?.contacts?.length) {
          contactMatch = task.deal.contacts.some((contact) =>
            contact?.name?.toLowerCase().includes(lowerCaseTerm)
          );
        }

        // Match team member
        let teamMemberMatch = false;
        if (task.teamMember) {
          teamMemberMatch =
            task.teamMember.name?.toLowerCase().includes(lowerCaseTerm) ??
            false;
        }
        return (
          task.name?.toLowerCase().includes(lowerCaseTerm) ||
          contactMatch ||
          teamMemberMatch ||
          addressMatch
        );
      });
    }

    const filters = {
      none: () => true,
      active: (task: Task) => !task.completed,
      upcoming: (task: Task) => !task.completed && !task.pastDue,
      completed: (task: Task) => task.completed,
      unstarted: (task: Task) => !task.completed && !task.inProgress,
      overdue: (task: Task) => !task.completed && task.pastDue,
      started: (task: Task) => !task.completed && task.inProgress,
    };
    const filterFn = filters[filter];
    if (filterFn) {
      filtered = filtered.filter(filterFn);
    }
    return filtered;
  }, [filter, searchTerm, tasks]);

  const renderTeamMember = (task: any) => {
    let name = "";

    if (task.assignedToType === "deal_contacts") {
      if (task.contacts) {
        name = task.contacts.map((c: any) => c.name).join(", ");
      } else {
        name = "Client";
      }
    } else if (task.role || task.teamMember) {
      if (task.role?.name) {
        // The task is assigned to a role or to a team member
        name += task.role.name;
        name += " - ";
      }
      if (task.teamMember?.name) {
        name += task.teamMember.name;
      }
    }

    return (
      <>
        {name && (
          <Link
            href="#"
            color="secondary"
            sx={{
              fontSize: "13px",
              color: "#747b88",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={(e: any) => handleEditTask(e, task)}
          >
            <Typography variant="subtitle1">{name}</Typography>
          </Link>
        )}
        {!name && (
          <Link
            href="#"
            color="secondary"
            onClick={(e: any) => handleEditTask(e, task)}
            sx={{
              fontSize: "13px",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Assign Task
          </Link>
        )}
      </>
    );
  };

  // TODO: fix bug; make this work better if a task is not assigned to a deal
  const renderContactAndAddress = (task: any) => {
    if (!task.deal && !task.contact) {
      return null;
    }
    const showAddress = !!task.deal?.address?.street;
    let contact = task.contact;
    if (!contact && task.deal?.contacts.length) {
      // Get the contact from the deal
      contact = task.deal.contacts[0];
    }
    return (
      <Typography variant="subtitle1">
        {contact && (
          <Link
            className={classes.link}
            component={RouterLink}
            to={`/contacts/${contact.id}`}
            underline="none"
          >
            {contact.name}
          </Link>
        )}
        {task.deal && (
          <>
            {showAddress && (
              <>
                {contact && <span>&nbsp;-&nbsp;</span>}
                <Link
                  component={RouterLink}
                  to={`/deals/${task.deal.id}`}
                  className={classes.link}
                >
                  {getFullAddress(task.deal.address)}
                </Link>
              </>
            )}
            {contact && !showAddress && task.deal.dealType.name && (
              <>
                &nbsp;
                <Link
                  component={RouterLink}
                  to={`/deals/${task.deal.id}`}
                  className={classes.link}
                >
                  ({task.deal.dealType.name})
                </Link>
              </>
            )}
          </>
        )}
      </Typography>
    );
  };

  const showTaskAsCompleted = (task: any) => {
    if (task.checked === undefined) return task.completed;
    else return task.checked;
  };

  const handleNoteCreated = (newNote: any) => {
    if (dispatch && dispatchType) {
      dispatch({
        type: dispatchType.ADD_TASK_NOTE,
        note: newNote,
      });
    } else if (setTasks) {
      setTasks(
        tasks.map((task) => {
          if (task.id === newNote.task.id) {
            task.notes =
              task.notes == null ? [newNote] : [...task.notes, newNote];
          }
          return task;
        })
      );
    }

    setMenuAnchor(null);
  };

  const handleStartClick = () => {
    setMenuAnchor(null);
    const variables = {
      input: {
        id: selectedTask.id,
        inProgress: true,
      },
    };
    updateTask({ variables })
      .then((res) => {
        onTaskUpdated(res.data.updateTask);
      })
      .catch(() => {
        showError("An error occurred updating the task.");
      });
  };

  return (
    <>
      {filteredTasks.length === 0 ? (
        <div style={{ width: "100%", height: "100%", display: "flex" }}>
          <p className={classes.noTasks}>
            {emptyStateText || "This deal doesn't have any tasks."}
          </p>
        </div>
      ) : (
        <List className={classes.taskList}>
          {filteredTasks.map((task: any, index: number) => (
            <ListItem
              key={task.id}
              className={clsx({
                [classes.taskListItem]: true,
                [classes.taskListBorderBottom]: true,
                [classes.taskListItemPastDue]: task.pastDue,
              })}
            >
              <ListItemIcon style={{ justifyContent: "flex-end" }}>
                <Checkbox
                  icon={<CheckboxUncheckedIcon style={{ color: "#00BC8E" }} />}
                  checkedIcon={
                    <CheckboxCheckedIcon style={{ color: "#00BC8E" }} />
                  }
                  edge="start"
                  checked={showTaskAsCompleted(task)}
                  onChange={(e, checked) =>
                    handleCompletedChange(e, task, checked)
                  }
                  disabled={task.disabled || reportView}
                />
              </ListItemIcon>
              <div
                className={classes.taskListItemText}
                style={{
                  padding: task?.notes?.length > 0 ? "11px 11px 4px" : "11px",
                }}
              >
                <div>
                  <div
                    className={clsx({
                      [classes.taskName]: true,
                      [classes.taskNameCompleted]: showTaskAsCompleted(task),
                    })}
                  >
                    {taskLinksEnabled && (task.deal?.id || task.contact?.id) ? (
                      <Link
                        component={RouterLink}
                        to={
                          task.deal
                            ? `/deals/${task.deal.id}/tasks`
                            : task.contact && `/contacts/${task.contact.id}`
                        }
                        className={clsx({
                          [classes.taskName]: true,
                          [classes.editable]: true,
                        })}
                      >
                        {task.name}
                      </Link>
                    ) : (
                      task.name
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    {secondaryText === "teamMember" && renderTeamMember(task)}
                    {(secondaryText === "teamMember" ||
                      secondaryText === "contactAndAddress") &&
                      renderContactAndAddress(task)}
                  </div>
                </div>
                <Stack spacing={1} direction="column" alignItems="end">
                  <TaskDueDate task={task} />
                  {task.inProgress && (
                    <Chip
                      sx={{ background: " #5759FF", color: "white" }}
                      size="small"
                      label="Started"
                    />
                  )}
                </Stack>
              </div>
              {editable && (
                <IconButton
                  edge="end"
                  aria-label="Show task menu"
                  onClick={(e) => handleMenuClick(e, task)}
                  size="large"
                >
                  <MoreVert />
                </IconButton>
              )}
              <div style={{ gridArea: "2 / 2 / 3 / 4" }}>
                <TaskNotes
                  task={task}
                  dispatch={dispatch}
                  dispatchType={dispatchType}
                />
              </div>
            </ListItem>
          ))}
        </List>
      )}

      {editable && (
        <Menu
          id="task-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          {!selectedTask?.inProgress && (
            <MenuItem onClick={handleStartClick}>Start</MenuItem>
          )}
          <CreateNote
            taskId={selectedTask?.id}
            onNoteCreated={handleNoteCreated}
            childOnClick={handleMenuClose}
          >
            <MenuItem>Add Note</MenuItem>
          </CreateNote>
          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
          <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
        </Menu>
      )}
      <TaskCreateDialog
        open={editing}
        task={selectedTask}
        onClose={handleTaskEditClose}
        onTaskCreated={handleTaskEditSave}
        deal={deal}
      />
    </>
  );
}
