import React, { FunctionComponent, useState } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  Theme,
  Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import TwitterIcon from "@mui/icons-material/Twitter";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Linkify from "react-linkify";

const GET_TWEETS = gql`
  query getTweets($username: String!) {
    getTweets(username: $username) {
      tweets {
        text
        createdAt
        id
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    socialIcon: {
      color: theme.palette.grey["500"],
      fontSize: "16px",
    },
    tweet: {
      "& a": {
        color: theme.palette.secondary.main,
        transition: theme.transitions.create(["color"]),
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
    timestamp: {
      color: theme.palette.grey["500"],
    },
    openIcon: {
      fontSize: "18px",
      marginLeft: "10px",
      opacity: 0,
      transition: theme.transitions.create(["opacity"]),
    },
    noTweets: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      padding: "11px",
      color: "#747b88",
      textAlign: "center",
    },
    socialContainer: {
      display: "flex",
      alignItems: "center",
      "&:hover": {
        "& $openIcon": {
          opacity: 1,
        },
      },
    },
    socialContentContainer: {
      paddingLeft: "10px",
    },
  })
);

type SocialPostsProps = {
  contact: any;
};

type SocialPost = {
  id: string;
  text: string;
  createdAt?: string;
};

const SocialPosts: FunctionComponent<SocialPostsProps> = ({ contact }) => {
  const classes = useStyles();

  const [socialPosts, setSocialPosts] = useState<SocialPost[] | []>([]);

  useQuery(GET_TWEETS, {
    variables: {
      username: contact.socialProfiles.twitter,
    },
    onCompleted: (res: any) => setSocialPosts(res.getTweets?.tweets ?? []),
  });

  const componentDecorator = (href: any, text: any, key: any) => (
    <a href={href} key={key} target="_blank" rel="noreferrer">
      {text}
    </a>
  );

  return (
    <Grid item xs={12}>
      {socialPosts.length > 0 ? (
        <TableContainer>
          <Table>
            <TableBody>
              {socialPosts.map((post: SocialPost, i: number) => (
                <TableRow key={i}>
                  <TableCell>
                    <Box className={classes.socialContainer}>
                      <Box className={classes.socialContentContainer}>
                        <TwitterIcon className={classes.socialIcon} />
                        {/*
                        // @ts-ignore */}
                        <Linkify componentDecorator={componentDecorator}>
                          <Typography className={classes.tweet}>
                            {post.text}
                          </Typography>
                        </Linkify>
                        <Typography
                          variant="subtitle1"
                          className={classes.timestamp}
                        >
                          {moment(post.createdAt)
                            .format("MM/D/YYYY, h:mm a")
                            .toString()}
                        </Typography>
                      </Box>
                      <Link
                        href={`https://twitter.com/${contact.socialProfiles.twitter}/status/${post.id}`}
                        target="_blank"
                        rel="noopener"
                      >
                        <OpenInNewIcon className={classes.openIcon} />
                      </Link>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div style={{ width: "100%", height: "100%", display: "flex" }}>
          <p className={classes.noTweets}>{"No public tweets were found"}</p>
        </div>
      )}
    </Grid>
  );
};
export default SocialPosts;
