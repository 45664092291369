import React from "react";
import { Menu, MenuItem, Checkbox } from "@mui/material";

interface CalendarFilterProps {
  anchorEl: any;
  handleClose: any;
  filterOptions: any;
  setFilterOptions: any;
}

const CalendarFilter = ({
  anchorEl,
  handleClose,
  filterOptions,
  setFilterOptions,
}: CalendarFilterProps) => {
  const handleCheck = (option: any) => {
    const updatedPreferences = filterOptions.map((opt: any) => {
      if (option.id === opt.id) {
        opt.enabled = !opt.enabled;
      }
      return opt;
    });
    setFilterOptions(updatedPreferences);
  };

  return (
    <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
      {filterOptions
        .filter((option: any) => option.showOnCalendar)
        .map((option: any) => {
          return (
            <MenuItem
              key={option.id}
              onClick={() => {
                handleCheck(option);
              }}
              style={{ paddingLeft: "0", fontSize: "12px" }}
            >
              <Checkbox checked={option.enabled} size="small" />
              {option.label}
            </MenuItem>
          );
        })}
    </Menu>
  );
};

export default CalendarFilter;
